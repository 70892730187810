import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { Helmet } from 'react-helmet';
import withRouter from '../../utils/withRouter';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import './OrbTeacherContactPreferencesPage.scss';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import OrbTeacherContactPreferencesForm from '../../components/OrbTeacherContactPreferencesForm/OrbTeacherContactPreferencesForm.jsx';
import { setUserPreferencesRequest, closeUserPreferences } from '../../redux/actions/orbUserPreferences';

class OrbTeacherContactPreferencesPage extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  componentDidUpdate() {
    const { userPreferences } = this.props;
    const { submitted } = this.state;
    if (userPreferences.success && submitted) {
      this.close();
    }
  }

  changeUserPrefences(preferences) {
    const {
      setUserPreferences,
      orgId,
      userId,
      userPreferences: { data }
    } = this.props;

    const isEqual = Object.keys(data.contact_preferences).length
      ? Object.keys(data.contact_preferences).every(key => data.contact_preferences[key] === preferences[key])
      : false;
    if (!isEqual) {
      setUserPreferences(orgId, userId, preferences);
      this.setState({ submitted: true });
    } else {
      this.close();
    }
  }

  close() {
    const { history, closePreferences } = this.props;
    closePreferences();
    history.push('/teacherHome/library');
  }

  render() {
    const {
      userPreferences: { data },
      localizedContent: { orbTeacherContactPreferences: i18n }
    } = this.props;

    if (!data || !data.contact_preferences) return null;

    return (
      <div>
        <Helmet title={i18n.contact_preferences} />
        <div className="orb_contact_preferences opened">
          <div className="header">
            <div className="h1">
              <div className="container">
                <span className="icon">
                  <SVGIcon glyph={GLYPHS.ICON_USER} />
                </span>
                <h1>{i18n.contact_preferences}</h1>
              </div>
            </div>
            <div className="h2">
              <div className="container">
                <p>{i18n.update_your_preferences}</p>
              </div>
            </div>
          </div>
          <div className="container">
            <OrbTeacherContactPreferencesForm
              data={data.contact_preferences}
              onSubmit={values => this.changeUserPrefences(values)}
            />
          </div>
        </div>
      </div>
    );
  }
}

OrbTeacherContactPreferencesPage.propTypes = {
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userPreferences: PropTypes.object.isRequired,
  setUserPreferences: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  closePreferences: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('orbTeacherContactPreferences'),
  connect(
    state => ({
      orgId: state.identity.currentOrganisationId,
      userId: state.identity.userId,
      userPreferences: state.orbUserPreferences
    }),
    dispatch => ({
      setUserPreferences: (orgId, userId, preferences) =>
        dispatch(setUserPreferencesRequest(orgId, userId, preferences)),
      closePreferences: () => dispatch(closeUserPreferences())
    })
  )
)(OrbTeacherContactPreferencesPage);
