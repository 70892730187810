import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import ClassCode from './ClassCode';
import Link from '../Link/Link.js';
import styles from './ClassCodeHeader.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

function ClassCodeHeader({
  displayJoiningCode,
  isClassCodeEnable,
  openEditPanelOnClick,
  localizedContent: { classCodeComponent }
}) {
  return (
    <div className="grid">
      <div className="row">
        <div className="col">
          <div className={styles.classCode}>
            <p className={styles.classCodeHeader}>{classCodeComponent.class_code}</p>
            {displayJoiningCode && isClassCodeEnable ? (
              <ClassCode classJoiningCode={displayJoiningCode} isClassCodeEditable={false} isToggleEnable={false} />
            ) : (
              <p>
                {classCodeComponent.class_code_off}
                <Link underlined className={styles.classCodeLink} onClick={openEditPanelOnClick}>
                  {classCodeComponent.turn_on}
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ClassCodeHeader.propTypes = {
  displayJoiningCode: PropTypes.object,
  isClassCodeEnable: PropTypes.bool,
  openEditPanelOnClick: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('classCodeComponent'),
  connect((state, { orgId, classroomId }) => {
    const currentClass = state.classrooms.data[classroomId] || {};
    const { customId: customOrgId } = state.organisations.data[orgId];
    const { code: classJoiningCode, enabled: isClassCodeEnable } = currentClass?.joiningCode || {};
    const displayJoiningCode = {
      customOrgId,
      classJoiningCode
    };
    return {
      displayJoiningCode,
      isClassCodeEnable
    };
  })
)(ClassCodeHeader);
