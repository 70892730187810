import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import compose from '../../utils/compose/compose.js';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styles from './ClassReportSortTable.scss';
import StudentDetails from '../StudentDetails/StudentDetails.js';
import { getOrbStudentReportLink } from '../../utils/orb';
import Link from '../Link/Link';
import RelativeDate from '../RelativeDate/RelativeDate';
import { featureIsEnabled } from '../../globals/envSettings';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';
import { storeSavedSettings } from '../../redux/reducers/savedSettings';
import actions from '../../redux/actions';

class ClassReportSortTable extends Component {
  static rowAccessKey = studentProp => {
    switch (studentProp) {
      case 'lastBookTitle':
        return { paramParent: 'lastBook', paramChild: 'title' };
      case 'lastBookOxfordLevel':
        return { paramParent: 'lastBook', paramChild: 'level' };
      case 'lastBookDate':
        return { paramParent: 'lastBook', paramChild: 'opened_at' };
      case 'lastQuizTitle':
        return { paramParent: 'lastQuiz', paramChild: 'title' };
      case 'lastQuizOxfordLevel':
        return { paramParent: 'lastQuiz', paramChild: 'level' };
      case 'lastQuizDate':
        return { paramParent: 'lastQuiz', paramChild: 'submitted_at' };
      case 'lastQuizScore':
        return { paramParent: 'lastQuiz', paramChild: 'score' };
      default:
        return {};
    }
  };

  mapOxfordLevelId = {
    0: '1',
    1: '1+'
  };

  sortTypes = {
    up: {
      fn: (a, b) => {
        if (featureIsEnabled('orb-class-report-overview') && this.getStudentPropByColumn().startsWith('last')) {
          const accessKeys = ClassReportSortTable.rowAccessKey(this.getStudentPropByColumn());
          if (a[accessKeys.paramParent][accessKeys.paramChild] && b[accessKeys.paramParent][accessKeys.paramChild]) {
            return a[accessKeys.paramParent][accessKeys.paramChild]
              .toString()
              .localeCompare(b[accessKeys.paramParent][accessKeys.paramChild].toString(), undefined, {
                numeric: true,
                sensitivity: 'base'
              });
          }
          return b[accessKeys.paramParent][accessKeys.paramChild] ? 1 : -1;
        }

        return a[this.getStudentPropByColumn()]
          .toString()
          .localeCompare(b[this.getStudentPropByColumn()].toString(), undefined, {
            numeric: true,
            sensitivity: 'base'
          });
      }
    },
    down: {
      fn: (a, b) => {
        if (featureIsEnabled('orb-class-report-overview') && this.getStudentPropByColumn().startsWith('last')) {
          const accessKeys = ClassReportSortTable.rowAccessKey(this.getStudentPropByColumn());
          if (a[accessKeys.paramParent][accessKeys.paramChild] && b[accessKeys.paramParent][accessKeys.paramChild]) {
            return b[accessKeys.paramParent][accessKeys.paramChild]
              .toString()
              .localeCompare(a[accessKeys.paramParent][accessKeys.paramChild].toString(), undefined, {
                numeric: true,
                sensitivity: 'base'
              });
          }
          return b[accessKeys.paramParent][accessKeys.paramChild] ? 1 : -1;
        }

        return b[this.getStudentPropByColumn()]
          .toString()
          .localeCompare(a[this.getStudentPropByColumn()].toString(), undefined, {
            numeric: true,
            sensitivity: 'base'
          });
      }
    },
    default: {
      fn: a => a
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      currentSort: 'default',
      sortColumn: '',
      showDatePicker: false,
      minDate: null,
      maxDate: new Date()
    };
  }

  getStudentPropByColumn = () => {
    const { sortColumn } = this.state;

    switch (sortColumn) {
      case 'students_heading':
        return 'name';
      case 'oxford_level':
        return 'oxfordLevel';
      case 'last_activity_heading_title':
        return 'lastBookTitle';
      case 'last_activity_heading_oxfordLevel':
        return 'lastBookOxfordLevel';
      case 'last_activity_heading_date':
        return 'lastBookDate';
      case 'last_quiz_title':
        return 'lastQuizTitle';
      case 'last_quiz_oxfordLevel':
        return 'lastQuizOxfordLevel';
      case 'last_quiz_date':
        return 'lastQuizDate';
      case 'last_quiz_score':
        return 'lastQuizScore';
      case 'quizzes_scored':
      case 'quizzes_scored_60':
        return 'quizzes';
      case 'average_score':
        return 'averageScore';
      case 'eBooks_completed':
        return 'eBooksCompleted';
      case 'logins':
        return 'logins';
      case 'badges_earned':
        return 'badges';
      default:
        return '';
    }
  };

  getOptionForButton = item => {
    const { currentSort, sortColumn } = this.state;

    if (sortColumn === item) {
      if (currentSort === 'up')
        return <SVGIcon className={styles.sortableCellIcons} glyph={GLYPHS.ICON_SORT_ASCENDING} />;
      if (currentSort === 'down')
        return <SVGIcon className={styles.sortableCellIcons} glyph={GLYPHS.ICON_SORT_DESCENDING} />;
    }
    return <SVGIcon className={styles.sortableCellIcons} glyph={GLYPHS.ICON_SORT_OPTIONS} />;
  };

  onSortChange = item => {
    const { currentSort, sortColumn } = this.state;
    let nextSort;

    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'down';

    if (item !== sortColumn) {
      nextSort = 'down';
    }

    this.setState({
      currentSort: nextSort,
      sortColumn: item
    });
  };

  showDatePicker = () => {
    const {
      localizedContent: { classReportEngagementTable: i18n },
      startDate,
      endDate
    } = this.props;
    const { showDatePicker, minDate, maxDate } = this.state;

    const selector = (
      <button type="button" onClick={() => this.handleOpenDatePicker()}>
        {i18n.change_date_range}
      </button>
    );

    const datePicker = (
      <DatePicker
        onChange={this.handleChangeDatePicker}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        onClickOutside={this.handleOpenDatePicker}
        calendarClassName={styles.reactDatePickerAbsolute}
        minDate={minDate}
        maxDate={maxDate}
      />
    );

    return [' | ', selector, showDatePicker ? datePicker : null];
  };

  handleOpenDatePicker = () => {
    const { showDatePicker } = this.state;
    this.setState({ showDatePicker: !showDatePicker });
  };

  handleChangeDatePicker = dates => {
    const {
      storeSavedSettingsAction,
      getClassReport,
      org: { id: orgId, academicYear, locationId },
      classroom: { id: classroomId }
    } = this.props;
    const [startDate, endDate] = dates;

    storeSavedSettingsAction({ classReport: { startDate, endDate } });

    if (endDate) {
      storeSavedSettingsAction({ classReport: { dateIsSelected: true, userChangedDate: true } });
      this.handleOpenDatePicker();

      const clientStartDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * -1 * 60 * 1000);
      const clientEndDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * -1 * 60 * 1000);
      clientStartDate.setUTCHours(0, 0, 0, 0);
      clientEndDate.setUTCHours(23, 59, 59, 999);

      getClassReport(
        orgId,
        classroomId,
        locationId,
        academicYear,
        clientStartDate.toISOString().slice(0, -1),
        clientEndDate.toISOString().slice(0, -1)
      );
    }

    if (startDate && !endDate) {
      storeSavedSettingsAction({ classReport: { dateIsSelected: false } });

      const dateDaysInterval = 365;
      const minDate = new Date(startDate).setDate(new Date(startDate).getDate() - dateDaysInterval);
      const maxDate = new Date(startDate).setDate(new Date(startDate).getDate() + dateDaysInterval);

      this.setState({ minDate, maxDate: maxDate > new Date() ? new Date() : maxDate });
    }
  };

  render() {
    const {
      headings,
      data,
      getOptionNameByValue,
      getYearGroupOptions,
      onNavigateToOrg,
      classroom,
      org,
      userChangedDate,
      startDate,
      endDate
    } = this.props;
    const { currentSort } = this.state;

    let currentDateSelected = '';
    if (startDate) {
      currentDateSelected += `${startDate.toLocaleDateString()} - `;
    }

    if (endDate) {
      currentDateSelected += endDate.toLocaleDateString();
    }
    const dateLabel = userChangedDate ? currentDateSelected : null;

    return (
      <div>
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                {headings.map(heading => (
                  <th
                    key={heading.title}
                    className={styles.stickyHeaderTableCell}
                    {...(heading.children ? { colSpan: Object.keys(heading.children).length } : { rowSpan: 2 })}
                  >
                    <span>
                      {!heading.children ? (
                        <button type="button" onClick={() => this.onSortChange(heading.name)}>
                          {`${heading.title} `}
                          {this.getOptionForButton(heading.name)}
                        </button>
                      ) : (
                        dateLabel || heading.title
                      )}

                      {featureIsEnabled('orb-class-report-date-selector') && heading.name === 'last_7_days'
                        ? this.showDatePicker()
                        : null}
                    </span>
                  </th>
                ))}
              </tr>
              <tr>
                {headings
                  .filter(item => 'children' in item)
                  .map(elem =>
                    elem.children.map(heading => (
                      <th key={heading.title} className={styles.stickyHeaderSecondRowTableCell}>
                        <span>
                          <button type="button" onClick={() => this.onSortChange(heading.name)}>
                            {`${heading.title} `}
                            {this.getOptionForButton(heading.name)}
                          </button>
                        </span>
                      </th>
                    ))
                  )}
              </tr>
            </thead>
            <tbody>
              {[...data].sort(this.sortTypes[currentSort].fn).map(student => (
                <tr key={student.id}>
                  <td className={styles.stickyBodyTableCell}>
                    <StudentDetails
                      key={`sd-${student.id}`}
                      name={student.name}
                      nameLink={getOrbStudentReportLink(org.id, classroom.id, student.id)}
                      year={getOptionNameByValue(getYearGroupOptions(org.curriculumType), student.yearGroup)}
                      error={
                        typeof student.yearGroup === 'undefined' ? (
                          <span className={styles.errorMessage}>
                            Please assign a{' '}
                            <Link role="button" onClick={onNavigateToOrg}>
                              year group
                            </Link>
                          </span>
                        ) : null
                      }
                      notesLink={student.notes ? '/dev/components/new/StudentDetails' : null}
                    />
                  </td>
                  {featureIsEnabled('orb-class-report-overview') && (
                    <td>
                      {this.mapOxfordLevelId[student.oxfordLevel]
                        ? this.mapOxfordLevelId[student.oxfordLevel]
                        : student.oxfordLevel}
                    </td>
                  )}
                  {featureIsEnabled('orb-class-report-overview') && typeof student.lastBook === 'string' && (
                    <>
                      <td>
                        <i>{student.lastBook}</i>
                      </td>
                      <td>
                        <span aria-label={student.lastBook}>&#8212;</span>
                      </td>
                      <td>
                        <span aria-label={student.lastBook}>&#8212;</span>
                      </td>
                    </>
                  )}
                  {featureIsEnabled('orb-class-report-overview') && typeof student.lastBook !== 'string' && (
                    <>
                      <td>{student.lastBookTitle}</td>
                      <td>{student.lastBookOxfordLevel}</td>
                      <td>
                        <RelativeDate date={student.lastBookDate} />
                      </td>
                    </>
                  )}
                  {featureIsEnabled('orb-class-report-overview-ui-without-avallain-data') && (
                    <td>{student.lastBookDate === '-' ? <>-</> : <RelativeDate date={student.lastBookDate} />}</td>
                  )}
                  {featureIsEnabled('orb-class-report-overview') && typeof student.lastQuiz === 'string' && (
                    <>
                      <td>
                        <i>{student.lastQuiz}</i>
                      </td>
                      <td>
                        <span aria-label={student.lastQuiz}>&#8212;</span>
                      </td>
                      <td>
                        <span aria-label={student.lastQuiz}>&#8212;</span>
                      </td>
                      <td>
                        <span aria-label={student.lastQuiz}>&#8212;</span>
                      </td>
                    </>
                  )}
                  {featureIsEnabled('orb-class-report-overview') && typeof student.lastQuiz !== 'string' && (
                    <>
                      <td>{student.lastQuizTitle}</td>
                      <td>{student.lastQuizOxfordLevel}</td>
                      <td>
                        <RelativeDate date={student.lastQuizDate} />
                      </td>
                      <td>
                        {student.lastQuizScore}
                        <sup>%</sup>
                      </td>
                    </>
                  )}
                  {featureIsEnabled('orb-class-report-overview') && (
                    <td>
                      <div>{student.quizzes}</div>
                      <div>
                        <i className={styles.nowrap}>(of {student.quizzesAttended} taken)</i>
                      </div>
                    </td>
                  )}
                  <td>
                    {student.averageScore}
                    <sup>%</sup>
                  </td>
                  <td>{student.eBooksCompleted}</td>
                  <td>{student.logins}</td>
                  <td>{student.badges}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

ClassReportSortTable.propTypes = {
  headings: PropTypes.array,
  data: PropTypes.array,
  getOptionNameByValue: PropTypes.func,
  getYearGroupOptions: PropTypes.func.isRequired,
  onNavigateToOrg: PropTypes.func.isRequired,
  classroom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }).isRequired,
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
    curriculumType: PropTypes.string.isRequired,
    academicYear: PropTypes.string,
    locationId: PropTypes.string
  }).isRequired,
  content: PropTypes.object,
  localizedContent: PropTypes.object.isRequired,
  storeSavedSettingsAction: PropTypes.func,
  dateIsSelected: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  userChangedDate: PropTypes.bool,
  getClassReport: PropTypes.func
};

export default compose(
  withLocalizedContent('classReportEngagementTable'),
  connect(
    state => {
      const classReportSettings = state.savedSettings.settings.classReport;

      return {
        dateIsSelected: classReportSettings.dateIsSelected,
        startDate: classReportSettings.startDate,
        endDate: classReportSettings.endDate,
        userChangedDate: classReportSettings.userChangedDate
      };
    },
    dispatch => ({
      storeSavedSettingsAction: value => {
        dispatch(storeSavedSettings(value));
      },
      getClassReport: (orgId, classId, locationId, academicYear, startDate, endDate) => {
        const dispatchAction = featureIsEnabled('mat-report-change-for-reducers')
          ? actions.gradebookGroupClassReportRequestV2
          : actions.gradebookGroupClassReportRequest;
        dispatch(dispatchAction(orgId, classId, locationId, academicYear, startDate, endDate));
      }
    })
  )
)(ClassReportSortTable);
