import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import TextInput from '../../../../components/TextInput/TextInput';
import ButtonGroup, { ButtonGroupType } from '../../../../components/ButtonGroup/ButtonGroup';
import RadioInput from '../../../../components/RadioInput/RadioInput';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import Table, { columnTypes } from '../../../../components/Table/Table';
import tableStyles from '../../../../components/Table/Table.scss';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import styles from './ProductGroupSearchPage.scss';

import { searchProductGroup, resetProductGroup } from '../../../../redux/actions/activationCodeDashboard';

function ProductGroupSearchArea({
  localizedContent: { productGroupSearchPage: content },
  searchProductString,
  resetProductSearch,
  productLookupData,
  productLookupError
}) {
  const paginationOptions = [
    { value: 10, text: 10 },
    { value: 20, text: 20 },
    { value: 30, text: 30 }
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('product_gr_name');

  const getAnotherPage = page => {
    setCurrentPage(page);
  };

  const columns = [
    {
      heading: content.product_id_th,
      type: columnTypes.TEXT,
      width: '40%'
    },
    {
      heading: content.product_group_th,
      type: columnTypes.TEXT,
      width: '40%'
    },
    {
      heading: content.actions_th,
      type: columnTypes.TEXT,
      aligned: 'center',
      width: '10%'
    }
  ];

  const getDropdownValue = () => {
    const dropdown = document.querySelector('#resultsPerPage');
    let dropdownValue = 10;
    if (dropdown) {
      dropdownValue = dropdown.value;
    }
    return dropdownValue;
  };

  const getCurrentRows = currentData =>
    currentData.map(product => ({
      id: `row_${product.productGroupId}`,
      cells: [
        <div key={`prod_gr_id_${product.productGroupId}`}>{product.productGroupId}</div>,
        <div key={`prod_gr_name_${product.productGroupId}`} className={styles.productName}>
          {product.productGroupName}
        </div>,
        <div key={`prod_gr_actions_${product.productGroupId}`} className={styles.actions}>
          <a
            href={`/dashboard_eps/product_groups/${product.productGroupId}/delete`}
            key={`link_delete_${product.productGroupId}`}
          >
            <Button type={buttonTypes.CLOSE} />
          </a>
          <a
            href={`/dashboard_eps/product_groups/${product.productGroupId}/edit`}
            key={`link_edit_${product.productGroupId}`}
          >
            <Button type={buttonTypes.PENCIL_REVIEW} />
          </a>
        </div>
      ]
    }));

  const renderProductsTable = (data, total) => {
    const dropdownValue = getDropdownValue();
    const resultsPerPage = parseInt(dropdownValue, 10);
    const pageIndex = currentPage - 1;
    const lowerLimit = pageIndex * resultsPerPage;
    const upperLimit = pageIndex * resultsPerPage + resultsPerPage;
    const currentData = data.productGroups
      .sort((a, b) => a.productGroupId.localeCompare(b.productGroupId))
      .slice(lowerLimit, upperLimit);

    const numberOfPages = Math.ceil(data.productGroups.length / resultsPerPage);
    const rows = getCurrentRows(currentData);
    const results = total === 1 ? content.one_result : content.multiple_results.replace('{records}', total);

    return (
      <div>
        <div>{total > 0 ? content.total_results.replace('{records}', results) : ''}</div>
        <Table columns={columns} rows={rows} customClass={tableStyles.leftAligned} />
        <div>{total === 0 ? content.no_records : ''}</div>
        <div className={styles.paginationContainer}>
          <PaginationButtons
            idPrefix="product_grp_pagination"
            value={currentPage}
            numberOfPages={numberOfPages}
            onClick={e => {
              getAnotherPage(e);
            }}
            paginationCenter
          />
        </div>
      </div>
    );
  };

  const renderTable = data => {
    if (data?.productGroups) {
      const total = data.totalProductGroups;
      return renderProductsTable(data, total);
    }

    if (productLookupError) {
      return <div>{content.error}</div>;
    }

    return <div />;
  };

  const search = async (string, filterValue) => {
    searchProductString(string, filterValue);
  };

  const searchFromButton = async () => {
    const input = document.querySelector('#lookup_input input');
    setCurrentPage(1);
    search(input.value, filter);
  };

  const resetResults = async () => {
    const input = document.querySelector('#lookup_input input');
    input.value = '';
    setCurrentPage(1);
    resetProductSearch();
  };

  const changedPaginationHandler = async () => {};

  return (
    <div>
      <div className={styles.lookupContainer} id="lookup_input">
        <TextInput label={content.label_pg} disableAutoComplete id={styles.lookup_input} name="lookup_input" />
        <Dropdown
          id="resultsPerPage"
          label={content.label_pg_rpp}
          options={paginationOptions.map(option => ({ value: option.value, text: option.text }))}
          onChange={changedPaginationHandler}
          name="look_dropdown"
        />
        <div className={styles.cardRowRadio}>
          {/* <span>{content.status}</span> */}
          <ButtonGroup id="availability" style={{ flex: 1 }} groupType={ButtonGroupType.HORIZONTAL_GROUP}>
            <RadioInput
              onChange={e => {
                if (e) {
                  setFilter('product_gr_name');
                }
              }}
              name="radioButtonDefault"
              value={1}
              checked={filter === 'product_gr_name'}
              label={content.filter_product_gr_name}
            />
            <RadioInput
              onChange={e => {
                if (e) {
                  setFilter('product_name');
                }
              }}
              name="radioButtonDefault"
              value={2}
              checked={filter === 'product_name'}
              label={content.filter_product_name}
            />
            <RadioInput
              onChange={e => {
                if (e) {
                  setFilter('product_id');
                }
              }}
              name="radioButtonDefault"
              value={3}
              label={content.filter_product_id}
              checked={filter === 'product_id'}
            />
            <RadioInput
              onChange={e => {
                if (e) {
                  setFilter('external_id');
                }
              }}
              name="radioButtonDefault"
              value={4}
              label={content.filter_external_id}
              checked={filter === 'external_id'}
            />
          </ButtonGroup>
        </div>
        <Button onClick={searchFromButton} type={buttonTypes.PRIMARY} text={content.search_button_text} />
        <Button onClick={resetResults} type={buttonTypes.PRIMARY} text={content.reset_button_text} />
      </div>
      <div className={styles.productGroupsResults}>{renderTable(productLookupData)}</div>
    </div>
  );
}

ProductGroupSearchArea.propTypes = {
  localizedContent: PropTypes.object,
  searchProductString: PropTypes.func,
  resetProductSearch: PropTypes.func,
  productLookupData: PropTypes.object,
  productLookupError: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    activationCodeDashboard: { productLookupData, productLookupError }
  } = state;
  return { productLookupData, productLookupError };
};

export default compose(
  withLocalizedContent('productGroupSearchPage'),
  connect(mapStateToProps, dispatch => ({
    searchProductString: (id, filter) => {
      dispatch(searchProductGroup({ id, filter }));
    },
    resetProductSearch: () => {
      dispatch(resetProductGroup());
    }
  }))
)(ProductGroupSearchArea);
