import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import { Checkbox } from '@oup/shared-front-end';
import { GLYPHS } from '../SVGIcon/SVGIcon.js';
import { buttonTypes } from '../Button/Button.js';
import { sanitizeUrl } from '../../utils/url.js';

import styles from './ProductFinder.scss';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail.js';
import ContentWithButton from '../ContentWithButton/ContentWithButton.js';
import MaterialRepresentation from '../MaterialRepresentation/MaterialRepresentation.js';
import { HubLayoutConstants } from '../../globals/hubConstants';

function ProductFinderProduct({
  onClick,
  product,
  productFinderContent,
  selectedProducts = [],
  selectionLimitReached = false
}) {
  const { productid: productId } = product;

  const { TARGET_USERTYPE } = HubLayoutConstants;
  const teacherTagText =
    product.target_usertype === TARGET_USERTYPE.TEACHER ? productFinderContent.teacher_material : '';

  const isProductSelected = !!selectedProducts.filter(selectedProduct => selectedProduct.productid === productId)
    .length;

  const toggleProduct = selectedProduct => {
    onClick(selectedProduct, isProductSelected);
  };
  return (
    <li key={productId} className={styles.product}>
      <div className={styles.checkboxContainer}>
        <Checkbox
          label="Select product"
          hideLabel
          checked={isProductSelected}
          onChange={() => toggleProduct(product)}
          // only disabled if its not already selected and the limit has been reached
          disabled={!isProductSelected && selectionLimitReached}
        />
      </div>
      <ContentWithButton
        buttonText={product.title}
        buttonGlyph={GLYPHS.ICON_RIGHT_THICK}
        buttonOnClick={null}
        buttonType={buttonTypes.PRIMARY}
        buttonIconOnly
        buttonContainerClassName={styles.contentWithButtonContainer}
        buttonTestHook="PRODUCT_FINDER_SELECT_PRODUCT_BUTTON"
      >
        <MaterialRepresentation
          thumbnailSize={thumbnailSizes.TABLE}
          imageSrc={sanitizeUrl(product.cover)}
          name={product.title}
          subtext={product.subtitle}
          teacherTagText={teacherTagText}
        />
      </ContentWithButton>
    </li>
  );
}

ProductFinderProduct.propTypes = {
  product: PropTypes.object,
  productFinderContent: PropTypes.object,
  onClick: PropTypes.func,
  selectedProducts: PropTypes.array,
  selectionLimitReached: PropTypes.bool
};

export default ProductFinderProduct;
