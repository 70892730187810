const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const onKeyDownHandler = callback => event => {
  if (event.key === 'Enter' || event.key === ' ') {
    callback(event);
  }
};

const displayHeaderOrFooter = (locationHook, setDisplay) => {
  let urlRegex = /^\/launch\/(teacher|student)\/.+$/.test(locationHook.pathname);
  setDisplay(!urlRegex);

  const unlisten = () => {
    urlRegex = /^\/launch\/(teacher|student)\/.+$/.test(locationHook.pathname);
    setDisplay(!urlRegex);
  };

  return unlisten;
};

export { FOCUSABLE_ELEMENTS, onKeyDownHandler, displayHeaderOrFooter };
