/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import compose from '../../utils/compose/compose.js';
import { isAndroid, isIos } from '../../utils/device';
import withLocalizedContent from '../../language/withLocalizedContent';
import './OrbTeacherAppDownload.scss';

class OrbTeacherAppDownload extends React.Component {
  static propTypes = {
    localizedContent: PropTypes.object.isRequired,
    onDownloadClicked: PropTypes.func.isRequired
  };

  platformMessage() {
    if (isAndroid()) {
      return '(Android)';
    } else if (isIos()) {
      return '(IOS)';
    }
    return '(Windows and Mac)';
  }

  subtitle() {
    const {
      localizedContent: { orbOfflineAppPage: i18n }
    } = this.props;

    if (isAndroid()) {
      return i18n.subtitle_android;
    } else if (isIos()) {
      return i18n.subtitle_ios;
    }
    return i18n.subtitle;
  }

  downloadMessage() {
    const {
      localizedContent: { orbOfflineAppPage: i18n }
    } = this.props;

    if (isAndroid()) {
      return (
        <div>
          <div className="icon-android" />
          <span dangerouslySetInnerHTML={{ __html: i18n.download_message_android }} />
        </div>
      );
    } else if (isIos()) {
      return (
        <div>
          <div className="icon-android" />
          <span dangerouslySetInnerHTML={{ __html: i18n.download_message_ios }} />
        </div>
      );
    }

    return (
      <div>
        <div className="icon" />
        <span dangerouslySetInnerHTML={{ __html: i18n.download_message }} />
      </div>
    );
  }

  extraMessages() {
    const {
      localizedContent: { orbOfflineAppPage: i18n }
    } = this.props;

    if (isAndroid()) {
      return [i18n.extra_message_android1, i18n.extra_message_android2];
    } else if (isIos()) {
      return [i18n.extra_message_ios1, i18n.extra_message_ios2];
    }
    return [i18n.extra_message1, i18n.extra_message2, i18n.extra_message3];
  }

  render() {
    const {
      localizedContent: { orbOfflineAppPage: i18n },
      onDownloadClicked
    } = this.props;

    return (
      <div>
        <Helmet title={i18n.title} />

        <div className={`teacher-app-download ${isAndroid() ? 'android' : ''}`}>
          <div className="first-section">
            <div className="container">
              <div className="title">
                <h1>{i18n.title}</h1> <span className="platform">{this.platformMessage()}</span>
              </div>
              <div className="subtitle">
                <h3>{this.subtitle()}</h3>
              </div>
              <button className="btn btn-default download-button" onClick={() => onDownloadClicked()}>
                {this.downloadMessage()}
              </button>
            </div>
          </div>
          <div className="second-section">
            <div className="container">
              <div className="instructions">
                <div className="info" />
                <ul>
                  {this.extraMessages().map((message, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: message }} />
                  ))}
                </ul>
              </div>
              <div className="flying-books" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withLocalizedContent('orbOfflineAppPage', 'sharedTerms'))(OrbTeacherAppDownload);
