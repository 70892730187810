import React from 'react';
import PropTypes from 'prop-types';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './LtiLicenceCreditsForm.scss';
import { isLocal } from '../../globals/envSettings';

const addFallbackCover = ev => {
  if (isLocal()) {
    return;
  }
  ev.target.src = '/media/feedback/book-cover.png';
};

function LtiLicenceProductItem({ product }) {
  return (
    <div className={styles.productContainer}>
      <div className={styles.productThumbnailContainer}>
        <img className={styles.productThumbnail} src={product.cover} alt="" onError={addFallbackCover} />
      </div>
      <div className={styles.productDataContainer}>
        <div>{product.title}</div>
        <div>{product.isbn}</div>
      </div>
    </div>
  );
}

LtiLicenceProductItem.propTypes = {
  product: PropTypes.object
};

export default compose(withLocalizedContent('ltiLicenceCreditsForm'))(LtiLicenceProductItem);
