import PropTypes from 'prop-types';
import React from 'react';
import ContentWithSVGIcon from '../../../components/ContentWithSVGIcon/ContentWithSVGIcon';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer';
import { sanitizeUrl } from '../../../utils/url.js';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import content from '../../../utils/cmsContent';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';
import { isHubMode } from '../../../utils/platform.js';

const getIconHeading = (status, removedCount, returnedCount, cmsContent) => {
  switch (status) {
    case 'SUCCESS':
      return {
        title: cmsContent.confirmation_success_title
          .replace('{removedCount}', removedCount)
          .replace('{s}', removedCount === 1 ? '' : 's'),
        subtitle: cmsContent.confirmation_success_subtitle
          .replace('{returnedCount}', returnedCount)
          .replace('{s}', returnedCount === 1 ? '' : 's'),
        type: iconHeadingTypes.VALID,
        illustrationSrc: HubIllustrationConstants.SUCCESS,
        illustrationAltText: HubIllustrationAltText.SUCCESS
      };
    case 'WARNING':
      return {
        title: cmsContent.confirmation_warning_title,
        subtitle: cmsContent.confirmation_success_subtitle
          .replace('{returnedCount}', returnedCount)
          .replace('{s}', returnedCount === 1 ? '' : 's'),
        type: iconHeadingTypes.WARNING,
        illustrationSrc: HubIllustrationConstants.WARNING,
        illustrationAltText: HubIllustrationAltText.WARNING
      };
    case 'ERROR':
    default:
      return {
        title: cmsContent.confirmation_error_title,
        subtitle: cmsContent.confirmation_error_subtitle,
        type: iconHeadingTypes.ERROR,
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
  }
};

function RemoveLearningMaterialConfirmation({
  resultStatus,
  removedCount,
  returnedCount,
  results,
  products,
  closePanel,
  onComplete
}) {
  const CMS = content.removeLearningMaterialPanel || {};

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text="Close" action={closePanel} />
            </div>
            {isHubMode() ? (
              <PopoutPanelIllustrationHeading {...getIconHeading(resultStatus, removedCount, returnedCount, CMS)} />
            ) : (
              <PopoutPanelIconHeading {...getIconHeading(resultStatus, removedCount, returnedCount, CMS)} />
            )}
          </div>
        }
        footerContent={<PopoutActionFooter primaryButtonText="Done" primaryButtonAction={onComplete} />}
      >
        {resultStatus &&
          resultStatus !== 'ERROR' &&
          results.length > 0 &&
          results.map(assignment => {
            const product = products[assignment.productId];
            let glyph = GLYPHS.ICON_CHECK_CIRCLE;
            let statusText = '';
            let statusColor = 'DEFAULT';

            if (assignment.errorMessages) {
              glyph = GLYPHS.ICON_WARNING_CIRCLE;
              statusText = CMS.confirmation_material_warning;
              statusColor = 'WARNING';
            } else if (assignment.activationCodeCountDetails) {
              statusText = CMS.confirmation_material_success;
              statusColor = 'SUCCESS';
            }

            return (
              <div className="pad2" style={{ borderBottom: '1px solid #d9d9d9' }} key={assignment.licenseId}>
                <ContentWithSVGIcon glyph={glyph}>
                  <MaterialRepresentation
                    thumbnailSize={thumbnailSizes.TABLE}
                    imageSrc={sanitizeUrl(product.coverImage)}
                    name={product.title}
                    subtext={
                      isHubMode()
                        ? `${product.subtitle || ''}`
                        : `${product.cefrLevel || ''} ${
                            product.cefrLevel && product.subtitle ? String.fromCharCode(183) : ''
                          } ${product.subtitle || ''}`
                    }
                    statusText={statusText}
                    statusColor={statusColor}
                  />
                </ContentWithSVGIcon>
              </div>
            );
          })}

        {(!resultStatus || resultStatus === 'ERROR') &&
          results.map(({ productId }) => {
            const product = products[productId];
            return (
              <div className="pad2" style={{ borderBottom: '1px solid #d9d9d9' }} key={productId}>
                <ContentWithSVGIcon glyph={GLYPHS.ICON_WARNING_CIRCLE}>
                  <MaterialRepresentation
                    thumbnailSize={thumbnailSizes.TABLE}
                    imageSrc={sanitizeUrl(product.coverImage)}
                    name={product.title}
                    subtext={`${product.cefrLevel || ''} ${
                      product.cefrLevel && product.subtitle ? String.fromCharCode(183) : ''
                    } ${product.subtitle || ''}`}
                    statusText={CMS.confirmation_material_warning}
                    statusColor="WARNING"
                  />
                </ContentWithSVGIcon>
              </div>
            );
          })}
      </ScrollContainer>
    </form>
  );
}

RemoveLearningMaterialConfirmation.propTypes = {
  resultStatus: PropTypes.string.isRequired,
  removedCount: PropTypes.number,
  returnedCount: PropTypes.number,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.object.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default RemoveLearningMaterialConfirmation;
