import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextLink } from '@oup/shared-front-end';

import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import styles from './PopoutActionFooter.scss';

function PopoutActionFooter({
  // Primary button props
  primaryButtonAction,
  primaryButtonText,
  primaryButtonDisabled,

  // Secondary Link/Button props
  secondaryButtonText,
  secondaryButtonIcon,
  secondaryButtonAction,
  secondaryButtonTo,
  secondaryButtonDisabled,

  // additional Secondary Link/Button props
  tertaryButtonText,
  tertaryButtonAction,
  tertaryButtonDisabled,

  // To layout the buttons in the bottom corners instead (Eg "Back" & "Next")
  wizardButtons,
  withIcon = true,
  showActionTextAndIcon = true,

  // Toggle Switch props
  toggleSwitchId,
  toggleSwitchLabel,
  toggleSwitchValue,
  toggleSwitchOnChange,

  primaryButtonTestHook = '',

  // Optionally supply custom content
  children
}) {
  return (
    <div className={styles.popoutActionFooter}>
      {children}

      {toggleSwitchLabel && (
        <div className={styles.toggleContainer}>
          <ToggleSwitch
            id={toggleSwitchId}
            label={toggleSwitchLabel}
            value={toggleSwitchValue}
            onChange={toggleSwitchOnChange}
          />
        </div>
      )}

      {showActionTextAndIcon && secondaryButtonText && !wizardButtons && <h4>Actions:</h4>}

      <div className={wizardButtons ? styles.wizardButtons : styles.stackedButtons}>
        {secondaryButtonText && wizardButtons && withIcon && (
          <LinkWithIcon
            id="PopoutActionFooterSecondaryLink"
            to={secondaryButtonTo}
            text={secondaryButtonText}
            action={secondaryButtonAction}
            glyph={GLYPHS.ICON_LEFT}
            isLhs
          />
        )}

        {secondaryButtonText && wizardButtons && !withIcon && (
          <TextLink to={secondaryButtonTo} onClick={secondaryButtonAction}>
            {secondaryButtonText}
          </TextLink>
        )}

        {secondaryButtonText && !wizardButtons && (
          <Button
            dataAttributes={{ testid: 'POPOUT_ACTION_FOOTER_SECONDARY_LINK' }}
            id="PopoutActionFooterSecondaryLink"
            text={secondaryButtonText}
            variant="outline"
            onClick={secondaryButtonAction}
            disabled={secondaryButtonDisabled}
            ariaAttributes={{ label: secondaryButtonText }}
            icon={{ component: secondaryButtonIcon }}
          />
        )}

        {tertaryButtonText && !wizardButtons && (
          <Button
            dataAttributes={{ testid: 'POPOUT_ACTION_FOOTER_TERTARY_LINK' }}
            id="PopoutActionFooterTertaryLink"
            text={tertaryButtonText}
            variant="outline"
            onClick={tertaryButtonAction}
            disabled={tertaryButtonDisabled}
            ariaAttributes={{ label: tertaryButtonText }}
          />
        )}

        {primaryButtonText && (
          <Button
            dataAttributes={{ testid: primaryButtonTestHook }}
            id="PopoutActionFooterPrimaryLink"
            text={primaryButtonText}
            onClick={primaryButtonAction}
            disabled={primaryButtonDisabled}
            ariaAttributes={{ label: primaryButtonText }}
          />
        )}
      </div>
    </div>
  );
}

PopoutActionFooter.propTypes = {
  // Primary button props
  primaryButtonAction: PropTypes.func,
  primaryButtonText: PropTypes.string,
  primaryButtonDisabled: PropTypes.bool,

  // Secondary Link/Button props
  secondaryButtonText: PropTypes.string,
  secondaryButtonIcon: PropTypes.any,
  secondaryButtonAction: PropTypes.func,
  secondaryButtonTo: PropTypes.string,
  secondaryButtonDisabled: PropTypes.bool,

  // additonal Secondary Link/Button props
  tertaryButtonText: PropTypes.string,
  tertaryButtonAction: PropTypes.func,
  tertaryButtonDisabled: PropTypes.bool,

  wizardButtons: PropTypes.bool,
  withIcon: PropTypes.bool,
  showActionTextAndIcon: PropTypes.bool,

  // Toggle Switch props
  toggleSwitchId: PropTypes.string,
  toggleSwitchLabel: PropTypes.string,
  toggleSwitchValue: PropTypes.bool,
  toggleSwitchOnChange: PropTypes.func,

  primaryButtonTestHook: PropTypes.string,

  // Optionally supply custom content (it will be placed above all buttons etc)
  children: PropTypes.any
};

export default PopoutActionFooter;
