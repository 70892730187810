import React from 'react';
import PropTypes from 'prop-types';
import compose from '../../utils/compose/compose.js';

import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './SEBSignedIn.scss';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

function SEBSignedIn({ localizedContent: { sebSignedIn: content } }) {
  return (
    <div className={styles.sebSignedInContainer}>
      <div className={styles.container}>
        <span />
        <span className={styles.tab}>{content.downloads}</span>
        <span />
      </div>
      <div className={styles.element_container}>
        <div className={styles.element}>
          <div className={styles.element_title}>
            <div className={styles.zip_container}>
              <SVGIcon glyph={GLYPHS.ICON_ZIP} className={styles.zip} />
            </div>
            <div className={styles.text}>
              <p>{content.windows_title}</p>
              <p>(.zip 17.8 MB) ca481fe781537ce8cb6e829db6401a3454eade07</p>
            </div>
          </div>
          <div className={styles.download_container}>
            <a
              className={styles.download_link_container}
              href="https://dev.go.oup.com/assessment-master/seb/download"
              aria-label="download"
            >
              <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD_WITH_BACKGROUND} className={styles.download} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

SEBSignedIn.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('sebSignedIn'))(SEBSignedIn);
