import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '../Link/Link';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './StudentDetails.scss';
import { isLocal } from '../../globals/envSettings';

function StudentDetails({ name, nameLink = null, year = null, notesLink = null, error = null }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles.parentDiv}>
        {nameLink ? (
          <Link
            role="button"
            tabIndex="0"
            to={nameLink}
            onClick={event => {
              event.preventDefault();
              if (!isLocal()) {
                window.newrelic.interaction().actionText('Open student progress');
              }
              navigate(event.currentTarget.pathname);
            }}
          >
            {name}
          </Link>
        ) : (
          <p>{name}</p>
        )}
        <div className={styles.childDiv}>
          {error ? (
            <div className={styles.parentDiv}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} className={styles.iconFormat} />
              {error}
            </div>
          ) : (
            <p className={styles.yearFormat}>{year}</p>
          )}
          {notesLink ? (
            <Link role="button" tabIndex="0" className={styles.notesFormat} to={notesLink}>
              -<br />-
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}

StudentDetails.propTypes = {
  // The name of the student.
  name: PropTypes.string.isRequired,
  // The current year the student is in.
  year: PropTypes.string,
  // The link triggered when a user clicks on the student's name.
  nameLink: PropTypes.string,
  // The link triggered when a user clicks on the notes icon.
  notesLink: PropTypes.string,
  // The error that should be shown
  error: PropTypes.node
};

export default StudentDetails;
