import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ORG_REGISTRATION_CONTEXT, ORG_REGISTRATION_STATUS } from '@oup/shared-node-browser/constants';
import { projectedSize } from '@oup/shared-node-browser/org';
import compose from '../utils/compose/compose.js';
// Redux
import actions from '../redux/actions';
import { closeEditMyAccountModal } from '../redux/actions/hubUi';
import { switchOrg } from '../redux/reducers/identity.reducer';
// Services
import { orgRoles } from '../globals/orgRoles';
import appSettings from '../globals/appSettings.js';
import withLocalizedContent from '../language/withLocalizedContent';
import { sanitizeUrl, prepareTargetUrl } from '../utils/url';
import { isHubMode, optInLti, getCurrentPlatform } from '../utils/platform';
import { featureIsEnabled } from '../globals/envSettings';
// Constants
import userRoles from '../globals/userRoles';
import { HubLayoutConstants } from '../globals/hubConstants';
// Components
import { ErrorStatus, PopoutPanelIconHeading, RegisterOrg, LegacyRegisterOrgSuccess } from '../components';
import MyProfileEditPanel from '../panels/MyProfileEditPanel';
import { types as popoutPanelIconHeadingTypes } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
// Style
import styles from './RegisterOrgPage.scss';

export const registerOrgFormFields = {
  NAME: 'name',
  COUNTRY_CODE: 'countryCode',
  PRIMARY_EMAIL: 'primaryEmail',
  PROJECTED_SIZE: 'projectedSize',
  IS_LMS_LTI_TOOL: 'isLmsLtiTool'
};

function RegisterOrgPage({
  localizedContent: { registerOrgPage: content, countryCodes: countryCodesContent },
  orgId,
  orgSwitch,
  hubEditAccountModalOpen,
  hubCloseEditMyAccountModal,
  clearFailure,
  registerOrgAction,
  submitting,
  success,
  failure
}) {
  const formStates = {
    EDITING: 'EDITING',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  };

  const location = useLocation();

  const [formState, setFormState] = useState(formStates.EDITING);
  const [formData, setFormData] = useState({
    [registerOrgFormFields.PROJECTED_SIZE]: projectedSize.REGULAR
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (failure) {
      return setFormState(formStates.ERROR);
    }
    if (success) {
      return setFormState(formStates.SUCCESS);
    }
    if (submitting) {
      return setFormState(formStates.SUBMITTING);
    }
    return setFormState(formStates.EDITING);
  }, [failure, success, submitting]);

  useEffect(() => {
    if (featureIsEnabled('hub-self-service') && optInLti() && isHubMode()) {
      setFormData({
        ...formData,
        [registerOrgFormFields.IS_LMS_LTI_TOOL]: false
      });
    }
    if (!optInLti()) {
      setFormData({
        ...formData,
        [registerOrgFormFields.PROJECTED_SIZE]: projectedSize.REGULAR
      });
    }
  }, []);

  const doValidations = (fieldName, fieldValue) => {
    const validators = {
      [registerOrgFormFields.NAME]: value => {
        if (!value || value.length < 3 || value.length > 150 || /[~`!£()@#$%^*+=[\\;,/{}|":<>?]/.test(value)) {
          return { [registerOrgFormFields.NAME]: true };
        }
        return { [registerOrgFormFields.NAME]: false };
      },
      [registerOrgFormFields.COUNTRY_CODE]: value => {
        if (!value || !countryCodesContent[value]) {
          return { [registerOrgFormFields.COUNTRY_CODE]: true };
        }
        return { [registerOrgFormFields.COUNTRY_CODE]: false };
      },
      [registerOrgFormFields.PRIMARY_EMAIL]: value => {
        if (!value || !appSettings.EMAIL_REGEX.test(value)) {
          return { [registerOrgFormFields.PRIMARY_EMAIL]: true };
        }
        return { [registerOrgFormFields.PRIMARY_EMAIL]: false };
      },
      [registerOrgFormFields.PROJECTED_SIZE]: value => {
        if (!projectedSize[value]) {
          return { [registerOrgFormFields.PROJECTED_SIZE]: true };
        }
        return { [registerOrgFormFields.PROJECTED_SIZE]: false };
      }
    };

    return validators[fieldName]?.(fieldValue) || {};
  };

  const onChange = ({ target: { value, name } }) => {
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({ ...errors, ...doValidations(name, value) });
  };

  const onSubmit = () => {
    const payload = {
      ...formData,
      registrationStatus: ORG_REGISTRATION_STATUS.REGISTERED,
      role: orgRoles.SECONDARY_SCHOOL,
      platformCode: getCurrentPlatform()
    };

    registerOrgAction(payload, ORG_REGISTRATION_CONTEXT.CES);
  };

  const goToReturnUrl = () => {
    const redirectTo = prepareTargetUrl();
    const query = new URLSearchParams(location.search);
    const returnUrl = query.get('returnUrl');
    if (redirectTo) {
      window.location = redirectTo;
    } else if (returnUrl) {
      window.location = sanitizeUrl(returnUrl);
    } else if (isHubMode()) {
      orgSwitch(
        orgId,
        userRoles.ORG_ADMIN,
        true,
        `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`
      );
    } else {
      orgSwitch(orgId, userRoles.ORG_ADMIN);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Helmet title={content.title} />
      {(() => {
        switch (formState) {
          case formStates.SUBMITTING:
            return (
              <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} title={content.waiting_message} />
            );
          case formStates.SUCCESS:
            return (
              <LegacyRegisterOrgSuccess
                primaryButtonLabel={content.go_to_org_button}
                onPrimaryButtonClick={goToReturnUrl}
                id={orgId}
                name={formData.name}
                branch={ORG_REGISTRATION_CONTEXT.CES}
              />
            );
          case formStates.ERROR:
            return (
              <ErrorStatus
                title={content.error_title}
                buttonText={content.error_button}
                buttonOnClickHandler={clearFailure}
              />
            );
          default:
            return (
              <>
                <RegisterOrg
                  countries={countryCodesContent}
                  id={orgId}
                  errors={errors}
                  onSubmit={onSubmit}
                  formData={formData}
                  formFields={registerOrgFormFields}
                  onChange={onChange}
                />
                {isHubMode() ? (
                  <MyProfileEditPanel isOpen={hubEditAccountModalOpen} closePanel={hubCloseEditMyAccountModal} />
                ) : null}
              </>
            );
        }
      })()}
    </div>
  );
}

export default compose(
  withLocalizedContent('registerOrgPage', 'countryCodes'),
  connect(
    ({ identity = {}, orgRegistration = {}, hubUi = {} }) => ({
      userId: identity.userId,
      userEmail: identity.email,
      orgId: orgRegistration.orgId,
      submitting: orgRegistration.submitting,
      success: orgRegistration.success,
      failure: orgRegistration.failure,
      hubEditAccountModalOpen: hubUi.editAccountModalOpen
    }),
    dispatch => ({
      clearFailure: () => {
        dispatch(actions.registerOrgClearFailure());
      },
      validate: input => {
        dispatch(actions.validateOrgInput(input));
      },
      change: () => {
        dispatch(actions.changeOrgInput());
      },
      registerOrgAction: (input, branch) => {
        dispatch(actions.registerOrgRequest(input, branch));
      },
      orgSwitch: (orgId, role, returnBack, returnUrl) => {
        dispatch(switchOrg(orgId, role, returnBack, returnUrl));
      },
      hubCloseEditMyAccountModal: () => {
        dispatch(closeEditMyAccountModal());
      }
    })
  )
)(RegisterOrgPage);

RegisterOrgPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  clearFailure: PropTypes.func.isRequired,
  registerOrgAction: PropTypes.func.isRequired,
  orgSwitch: PropTypes.func.isRequired,
  hubEditAccountModalOpen: PropTypes.bool,
  hubCloseEditMyAccountModal: PropTypes.func
};
