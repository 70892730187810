// import signedFetch from '../util/signedFetch.js';
// import { toQueryString } from '../../../../utils/url.js';

function* mockFetch(filters) {
  const mockedResponse = [
    {
      contentCode: 'ELT-GAME-DEFAULT-FIRST',
      template: 'DEFAULT',
      title: 'ELT Game First Edition',
      gitanaId: 'ELT-DEFAULT-GAME-1',
      created: '2025-01-01',
      updated: '2025-01-03 12:44:30'
    },
    {
      contentCode: 'ELT-GAME-DEFAULT-SECOND',
      template: 'DEFAULT',
      title: 'ELT Game Second Edition',
      gitanaId: 'ELT-DEFAULT-GAME-2',
      created: '2025-02-10 10:10:48',
      updated: '2025-02-15 14:33:22'
    },
    {
      contentCode: 'ELT-GAME-NEW',
      template: 'NEW-TEMPLATE',
      title: 'ELT Game New Edition',
      gitanaId: 'ELT-NEW-GAME',
      created: '2025-03-10 09:19:55',
      updated: '2025-03-17 18:15:10'
    }
  ];

  const { contentCode, template, title } = filters;
  if (!contentCode && !template && !title) {
    return yield mockedResponse;
  }

  return yield mockedResponse.filter(
    item =>
      item.contentCode.indexOf(filters?.contentCode) > -1 ||
      item.title.indexOf(filters?.title) > -1 ||
      item.template.indexOf(filters?.template) > -1
  );
}

export default function* getEltGamesApi(filters) {
  // API is under development, in the meantime, use mock data
  return yield mockFetch(filters);

  // const { contentCode, title, template, max } = filters;

  // const queryParams = {
  //   ...(contentCode && { contentCode }),
  //   ...(title && { title }),
  //   ...(template && { template }),
  //   ...(max !== undefined ? { max } : { max: 0 })
  // };

  // const url = `TBD/${toQueryString(queryParams)}`;
  // return yield signedFetch('getEltGames', url, 'GET', null, null, false, true);
}
