import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import compose from '../../../../utils/compose/compose.js';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import { getActivationCodeDetailsForDashboard } from '../../../../redux/actions/activationCodeDashboard';
import ActivationCodeDetailDisplay from './ActivationCodeDetailDisplay';

function ActivationCodeDetailPage({ getActCodeDetails }) {
  const params = useParams();
  const activationCodeId = params.actCode;
  getActCodeDetails(activationCodeId);

  return (
    <PageWrapper>
      <Helmet title="EPS" />
      <ActivationCodeDetailDisplay />
    </PageWrapper>
  );
}

ActivationCodeDetailPage.propTypes = {
  getActCodeDetails: PropTypes.func
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  getActCodeDetails: code => {
    dispatch(getActivationCodeDetailsForDashboard({ code }));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActivationCodeDetailPage);
