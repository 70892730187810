import * as t from '../actionTypes';

const initialState = {
  loading: false,
  success: false,
  error: false,
  deleteItem: null,
  deleteResponse: '',
  isDeleteInProgress: false,
  isDeleteReady: false,
  filters: {},
  results: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GET_CONTENT_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        results: []
      };
    case t.GET_ELT_GAMES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        filters: payload,
        results: []
      };
    case t.GET_EREADER_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        filters: payload,
        results: []
      };
    case t.GET_CONTENT_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        results: payload,
        success: true,
        error: false
      };
    case t.GET_CONTENT_PREVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        results: [],
        success: false,
        error: payload
      };
    case t.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        deleteItem: payload,
        isDeleteInProgress: true
      };
    case t.DELETE_PRODUCT_SUCCESS: {
      const { contentCode, isbn } = state.deleteItem;
      const deletedItem = state.results.find(prd => prd.isbn === isbn && prd.contentCode === contentCode);
      const newResults = deletedItem ? state.results.filter(item => item._id !== deletedItem._id) : state.results;

      return {
        ...state,
        results: newResults,
        isDeleteInProgress: false,
        isDeleteReady: true,
        deleteResponse: payload
      };
    }

    case t.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        isDeleteInProgress: false,
        isDeleteReady: true,
        deleteResponse: payload
      };
    case t.CLEAR_DELETE_STATE:
      return {
        ...state,
        deleteResponse: '',
        isDeleteInProgress: false,
        isDeleteReady: false,
        deleteItem: null
      };
    default:
      return {
        ...state
      };
  }
};
