// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import broadcastLocationChange from '../redux/actions/locationChange';

function locationChangeListener({ children, broadcastLocationChangeAction }) {
  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    broadcastLocationChangeAction(location);
  }, [pathname, search]);

  return children;
}

locationChangeListener.propTypes = {
  broadcastLocationChangeAction: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

const mapDispatchToProps = dispatch => ({
  broadcastLocationChangeAction: location => dispatch(broadcastLocationChange(location))
});

export default connect(null, mapDispatchToProps)(locationChangeListener);
