import wrapDisplayName from './utils/wrapDisplayName';
import setDisplayName from './utils/setDisplayName';
import mapProps from './utils/mapProps';

const withProps = input => {
  const hoc = mapProps(props => ({
    ...props,
    ...(typeof input === 'function' ? input(props) : input)
  }));

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return BaseComponent => setDisplayName(wrapDisplayName(BaseComponent, 'withProps'))(hoc(BaseComponent));
  }

  return hoc;
};

export default withProps;
