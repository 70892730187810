import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { TextLink, Button } from '@oup/shared-front-end';
import { ICON_LEFT_THICK, ICON_RIGHT_THICK } from '@oup/shared-front-end/svg/oup';
import DOMPurify from 'dompurify';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Headers.scss';
import { useScroll } from '../GradebookTable/ScrollContext';
import { featureIsEnabled } from '../../globals/envSettings';
import GradebookTable from '../GradebookTable/GradebookTable';
import { isHubMode } from '../../utils/platform.js';

const ROW_HEIGHT = 58;
function Headers({ levels = [], localizedContent = {}, fixColumn }) {
  const showGradebookUiUpdates = isHubMode() && featureIsEnabled('gradebook-ui-updates');
  const { scrollingElement, scrollElementRef } = useScroll() || {};
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const location = useLocation();

  const updateScrollData = scrollRef => {
    if (!scrollRef) return;

    const newCurrentScrollPosition = scrollRef.scrollLeft;
    const newMaxScrollPosition = scrollRef.scrollWidth - scrollRef.clientWidth;
    const isAtScrollEnd = newCurrentScrollPosition >= newMaxScrollPosition - 1;

    setCurrentScrollPosition(newCurrentScrollPosition);
    setScrollEnd(isAtScrollEnd);
  };

  useEffect(() => {
    if (showGradebookUiUpdates) {
      const scrollRef = scrollElementRef?.current;

      if (scrollRef) {
        updateScrollData(scrollRef);
      }

      const handleScroll = () => {
        updateScrollData(scrollRef);
      };

      scrollRef?.addEventListener('scroll', handleScroll);
      return () => {
        scrollRef?.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, [scrollElementRef?.current]);

  const scroll = direction => {
    if (scrollingElement) {
      scrollingElement(direction);
      const scrollRef = scrollElementRef?.current;
      if (scrollRef && showGradebookUiUpdates) updateScrollData(scrollRef);
    }
  };

  return (
    <GradebookTable.Row>
      <GradebookTable.Header height={ROW_HEIGHT} stickyColumn>
        {fixColumn ? (
          <Button
            variant="outline"
            icon={{ component: <ICON_LEFT_THICK /> }}
            onClick={() => scroll('right')}
            disabled={showGradebookUiUpdates ? currentScrollPosition === 0 : undefined}
          />
        ) : (
          <>&nbsp;</>
        )}
      </GradebookTable.Header>
      <GradebookTable.Header height={ROW_HEIGHT} width={170}>
        {featureIsEnabled('replacing-last-accessed-with-last-opened') ? (
          <div>Last Opened</div>
        ) : (
          <div>Last Accessed</div>
        )}
      </GradebookTable.Header>
      <GradebookTable.Header height={ROW_HEIGHT} colSpan={2}>
        Total
      </GradebookTable.Header>
      {levels.map(({ name, uId, isActivity, locked }) => (
        <GradebookTable.Header height={ROW_HEIGHT} key={uId} colSpan={2} locked={locked}>
          {!isActivity ? (
            <TextLink
              to={`${location.pathname.replace(/\/$/, '')}#${encodeURIComponent(uId)}`}
              component={RouterLink}
              {...(locked && {
                dataAttributes: { tooltip: localizedContent?.locked_node_tooltip_text || '' },
                leftIcon: <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
              })}
            >
              {name}
            </TextLink>
          ) : (
            <span
              {...(locked && {
                className: styles.lockedIcon,
                'data-tooltip': localizedContent?.locked_node_tooltip_text
              })}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }}
            >
              {locked && <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />}
            </span>
          )}
        </GradebookTable.Header>
      ))}
      {fixColumn && (
        <GradebookTable.Header fixColumn={fixColumn}>
          <Button
            variant="outline"
            icon={{ component: <ICON_RIGHT_THICK /> }}
            onClick={() => scroll('left')}
            disabled={showGradebookUiUpdates ? scrollEnd : undefined}
          />
        </GradebookTable.Header>
      )}
    </GradebookTable.Row>
  );
}
Headers.propTypes = {
  levels: PropTypes.array,
  localizedContent: PropTypes.object,
  fixColumn: PropTypes.bool
};

export default Headers;
