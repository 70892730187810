import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import actions from '../../redux/actions';
import { StatusEnum } from '../../redux/sagas/appFunctions/authorisedFunctions/csvUserUpload/csvUserUpload';
import { ErrorStatus, PageWrapper } from '../../components';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import CsvUserUpload from '../../components/CsvUserUpload/CsvUserUpload';
import CsvUserUploadSuccess from '../../components/CsvUserUpload/CsvUserUploadSuccess';

function CsvUserUploadPage({ status, email, localizedContent: { csvUserUploadPage: content }, redirect }) {
  return (
    <PageWrapper>
      <Helmet title={content.pageTitle} />
      {(() => {
        switch (true) {
          case status === StatusEnum.InProgress:
            return <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} />;
          case status === StatusEnum.success:
            return (
              <CsvUserUploadSuccess email={email} content={content} goBackToDashboard={() => redirect('/dashboard')} />
            );
          case status === StatusEnum.failure:
            return (
              <ErrorStatus
                title={content.errorMessage}
                buttonText={content.closeButton}
                buttonOnClickHandler={() => redirect('/dashboard')}
              />
            );
          default:
            return <CsvUserUpload content={content} goBackToDashboard={() => redirect('/dashboard')} />;
        }
      })()}
    </PageWrapper>
  );
}

CsvUserUploadPage.propTypes = {
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('csvUserUploadPage'),
  connect(
    state => pick(state.csvUserUpload, ['status', 'email']),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(actions.csvUserUploadReset());
        props.history.push(path);
      }
    })
  )
)(CsvUserUploadPage);
