import React from 'react';
import PropTypes from 'prop-types';
import { ValidationMessage } from '@oup/shared-front-end';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import styles from './SystemNotificationBanner.scss';

function LegacySystemNotificationComponent({ content, platformNotification, onBannerClose }) {
  return platformNotification.message === content.org_read_only_notification ? (
    <div role="alert" className={styles.platformNotificationTopBanner}>
      <ValidationMessage state="information">{platformNotification.message}</ValidationMessage>
    </div>
  ) : (
    <div className={styles.systemNotificationTopBanner}>
      <div className="grid">
        <div className="row">
          <div className="col">
            <div className={styles.mainStyle}>
              <SVGIcon className={styles.bannerBgIcon} glyph={GLYPHS.ICON_BANNER_BG} />
              <p role="alert" className={styles.textStyle}>
                {platformNotification.message || content.message_for_inner_pages}
              </p>
              <button id="closeBtn" className={styles.close} type="button" onClick={onBannerClose} aria-label="close">
                <SVGIcon glyph={GLYPHS.ICON_CLOSE_BOLD} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LegacySystemNotificationComponent.propTypes = {
  content: PropTypes.object.isRequired,
  platformNotification: PropTypes.object.isRequired,
  onBannerClose: PropTypes.func.isRequired
};

export default LegacySystemNotificationComponent;
