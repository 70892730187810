import dot from 'dot-object';
import { pick } from 'lodash';

import compose from '../utils/compose/compose';
import withHandlers from '../utils/compose/withHandlers';
import withStateHandlers from '../utils/compose/withStateHandlers';

const withFormHandlers = (initialState, context = 'formInputChanges') => Component =>
  compose(
    withStateHandlers(
      { [context]: initialState || {} },
      {
        onChange: prevState => (value, name) => ({
          [context]: dot.object({
            ...dot.dot(prevState[context]),
            [name]: typeof value === 'string' ? value.trimLeft() : value
          })
        }),
        onClearFormChanges: () => () => ({ [context]: {} })
      }
    ),
    withHandlers({
      onBlur: ({ validate, ...props }) => name => {
        if (!validate) {
          throw new Error('Form validator not defined, provide a .validate method to continue');
        }
        validate(pick(props[context], name));
      },
      onBlurWithParams: ({ validate, ...props }) => (...params) => name => {
        if (!validate) {
          throw new Error('Form validator not defined, provide a .validate method to continue');
        }
        validate(...params, pick(props[context], name));
      }
    })
  )(Component);

export default withFormHandlers;
