import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { parse as parseQueryString } from 'query-string';
import { TextLink } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import { createAnchorToBlank } from '../../utils/markdown/markdown';
import { NAME_MAX_LIMIT, USERNAME_MAX_LIMIT, ALLOWED_ONLY_ALPHANUMERIC } from '../../globals/validations';
import interpolateContent from '../../utils/interpolateContent';
import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import styles from '../MyProfileEditForm/MyProfileEditForm.scss';
import {
  socialIdpIsEnabled,
  getGoogleProviderName,
  getMicrosoftProviderName,
  getAppleProviderName,
  featureIsEnabled
} from '../../globals/envSettings';
import appSettings from '../../globals/appSettings.js';
import { getCurrentPlatform, isCesMode, isHubMode, isEltAssessmentMode, isOicMode } from '../../utils/platform';
import LinkSocialAccounts from '../LinkSocialAccounts/LinkSocialAccounts';
import userRoles from '../../globals/userRoles';
import Label from '../Label/Label';
import SelectionList from '../SelectionList/SelectionList';
import Lozenge from '../Lozenge/Lozenge';
import InformationBanner from '../InformationBanner/InformationBanner.js';
import colors from '../../globals/colors';

const UNLINK_SOCIAL_CONFIRM = 'UNLINK_SOCIAL_CONFIRM';
class UserFormBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSupervisedUserSupportLink() {
    const { localizedContent: content } = this.props;
    return (
      <div className={styles.bannerContainer}>
        <InformationBanner>
          <div className={styles.infoContent}>
            <span>{content.username_supervised_user_info_1}</span>
            <TextLink to={content.supervised_user_support_link} target="_blank">
              {content.oxford_id}
            </TextLink>
            <span>{content.username_supervised_user_info_2}</span>
          </div>
        </InformationBanner>
      </div>
    );
  }

  _getUserNameValidationStatus = (isEmailSupported = false) => {
    const {
      localizedContent: content,
      formInputErrors = {},
      userName = '',
      origUserName = '',
      isCheckingUserNameTaken = false,
      isUserNameTaken = false,
      isCheckingEmailTaken = false,
      isEmailTaken = false,
      isCharLimited = false,
      isGeneratingUserName,
      isOupUserEmailInvalid,
      isUserEnrollForm = false,
      isSupervised = false
    } = this.props;
    const isCheckingUserNameEmail = !isEmailSupported && isCheckingEmailTaken;
    const isUserNameEmailTaken = !isEmailSupported && isEmailTaken;
    switch (true) {
      case isCheckingUserNameTaken || isCheckingUserNameEmail || isGeneratingUserName:
        return {
          type: 'waiting',
          isActive: true
        };
      case isSupervised && userName === origUserName:
        return {
          isActive: false
        };
      case isUserNameTaken || isUserNameEmailTaken:
        return {
          isActive: true,
          message: content.username_taken_error
        };
      case isCharLimited && userName.length > USERNAME_MAX_LIMIT:
        return {
          isActive: true,
          message: content.username_max_limit_error
        };
      case isOupUserEmailInvalid:
        return {
          isActive: true,
          message: content.oup_username_error
        };
      case socialIdpIsEnabled(getCurrentPlatform()) &&
        (isCesMode() || isHubMode()) &&
        !appSettings.EMAIL_REGEX.test(userName) &&
        !isUserEnrollForm:
        return {
          isActive: true,
          message: content.my_profile_edit_username_email_invalid
        };
      default:
        return {
          isActive: formInputErrors.userName,
          message: content.username_error
        };
    }
  };

  _getEmailValidationStatus = () => {
    const {
      localizedContent: content,
      formInputErrors = {},
      isCheckingEmailTaken = false,
      isEmailTaken = false
    } = this.props;
    switch (true) {
      case isCheckingEmailTaken:
        return {
          type: 'waiting',
          isActive: true
        };
      case isEmailTaken:
        return {
          isActive: true,
          message: content.email_taken_error
        };
      default:
        return {
          isActive: formInputErrors.email,
          message: content.email_error
        };
    }
  };

  _getNewPasswordValidationStatus = () => {
    const {
      localizedContent: content,
      newPassword = '',
      formInputErrors: { newPassword: formInputNewPasswordError } = {}
    } = this.props;
    if (!newPassword.length && formInputNewPasswordError === false) {
      return {
        type: 'warning',
        isActive: true,
        message: content.new_password_leave_blank_warning
      };
    }
    return {
      isActive: formInputNewPasswordError,
      message: content.new_password_error
    };
  };

  _generateUserName = () => {
    const { firstName, lastName, onGenerateUserName } = this.props;
    onGenerateUserName(firstName, lastName);
  };

  _handlePasswordFocus = name => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ ...this.state, [name]: true });
  };

  _handleUserNameFocus = name => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ ...this.state, [name]: true });
    const { firstName, lastName, userName } = this.props;
    if (firstName && lastName && !userName) {
      this._generateUserName();
    }
  };

  _validatePasswordStatus = () => {
    const { isGeneratingPassword, formInputErrors, localizedContent: content, password } = this.props;
    const { password: passwordFocus } = this.state;
    switch (true) {
      case isGeneratingPassword:
        return {
          type: 'waiting',
          isActive: true
        };
      case passwordFocus && !ALLOWED_ONLY_ALPHANUMERIC.test(password) && password.length > 0:
        return {
          type: 'error',
          isActive: true,
          message: content.password_no_special_characters
        };
      case passwordFocus:
        return {
          type: 'notice',
          isActive: true,
          message: content.password_invalid_error
        };
      case formInputErrors.password:
        return {
          type: 'error',
          isActive: formInputErrors.password,
          message: content.password_empty_error
        };
      case formInputErrors.password !== undefined && !formInputErrors.password:
        return {
          type: 'valid',
          isActive: false
        };
      default:
        return {
          type: null,
          isActive: null
        };
    }
  };

  _handlePasswordBlur = name => {
    const { onBlur } = this.props;
    this.setState({ [name]: false });
    onBlur('password');
  };

  _handleUserNameBlur = name => {
    const { onBlur } = this.props;
    this.setState({ [name]: false });
    onBlur('userName');
    onBlur('email');
  };

  _unlinkSocialCheckPassword = identity => {
    const { goToFormWithData } = this.props;
    const body = { secondaryUserId: identity.user_id, provider: identity.provider };
    const dataObject = { provider: identity.provider, body };
    goToFormWithData(UNLINK_SOCIAL_CONFIRM, dataObject);
  };

  _getCesResetPasswordLink = () => {
    const { localizedContent: content } = this.props;
    const queryString = parseQueryString(window.location.search) || {};
    let resetPasswordLink = content.ces_password_reset_link_url;

    const redirectToTargetUrl = queryString.target_url || '';

    if (redirectToTargetUrl) {
      resetPasswordLink += `?target_url=${redirectToTargetUrl}`;

      const protocol = queryString.protocol || '';
      const providerId = queryString.providerId || '';

      if (protocol) {
        resetPasswordLink += `&protocol=${protocol}`;
      }

      if (providerId) {
        resetPasswordLink += `&providerId=${providerId}`;
      }
    }

    return resetPasswordLink;
  };

  _renderSocial = () => {
    const { userAccount, localizedContent: content } = this.props;
    const microsoftProvider = getMicrosoftProviderName();
    const googleProvider = getGoogleProviderName();
    const appleProvider = getAppleProviderName();
    let windows = { isActive: false };
    let google = { isActive: false };
    let apple = { isActive: false };
    const appleLoginIsEnabled = featureIsEnabled('enable-apple-login');

    if (userAccount && userAccount.identities) {
      for (let i = 0; i < userAccount.identities.length; i += 1) {
        const currentProvider = userAccount.identities[i].provider;

        switch (currentProvider) {
          case googleProvider:
            google = userAccount.identities[i];
            google.isActive = true;
            break;
          case microsoftProvider:
            windows = userAccount.identities[i];
            windows.isActive = true;
            break;
          case appleProvider:
            if (!appleLoginIsEnabled) break;
            apple = userAccount.identities[i];
            apple.isActive = true;
            break;
          default:
        }
      }
    }

    return (
      <div className={styles.socialMainContainer}>
        <div className={styles.linkHeader}>
          <div className={styles.personalInformationHeader}>
            {appleLoginIsEnabled
              ? content.my_profile_edit_form_social_hint_header_with_apple
              : content.my_profile_edit_form_social_hint_header}
          </div>
          <div>
            {appleLoginIsEnabled
              ? content.my_profile_edit_form_social_hint_body_with_apple
              : content.my_profile_edit_form_social_hint_body}
          </div>
        </div>
        {google.isActive || windows.isActive || apple.isActive ? (
          <div className={styles.unlinkMainContainer}>
            <div className={styles.unlinkContainer}>
              {apple.isActive && appleLoginIsEnabled ? (
                <div className={styles.unlinkItem}>
                  <div title={apple.email} className={styles.socialRegistrationButtonWithEmail}>
                    <img alt="microsoft_button" src="../../../static/images/social-icons/apple.svg" />
                    <span>{apple.email}</span>
                  </div>
                  <button
                    type="submit"
                    onClick={() => {
                      this._unlinkSocialCheckPassword(apple);
                    }}
                    className={styles.unlinkLink}
                  >
                    {content.my_profile_edit_social_form_unlink}
                  </button>
                </div>
              ) : null}
              {google.isActive ? (
                <div className={styles.unlinkItem}>
                  <div title={google.email} className={styles.socialRegistrationButtonWithEmail}>
                    <img alt="google_button" src="../../../static/images/social-icons/google.svg" />
                    <span>{google.email}</span>
                  </div>
                  <button
                    type="submit"
                    onClick={() => {
                      this._unlinkSocialCheckPassword(google);
                    }}
                    className={styles.unlinkLink}
                  >
                    {content.my_profile_edit_social_form_unlink}
                  </button>
                </div>
              ) : null}
              {windows.isActive ? (
                <div className={styles.unlinkItem}>
                  <div title={windows.email} className={styles.socialRegistrationButtonWithEmail}>
                    <img alt="microsoft_button" src="../../../static/images/social-icons/microsoft.svg" />
                    <span>{windows.email}</span>
                  </div>
                  <button
                    type="submit"
                    onClick={() => {
                      this._unlinkSocialCheckPassword(windows);
                    }}
                    className={styles.unlinkLink}
                  >
                    {content.my_profile_edit_social_form_unlink}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {!google.isActive || !windows.isActive || !apple.isActive ? (
          <div className={styles.linkMainContainer}>
            {!apple.isActive && appleLoginIsEnabled ? <LinkSocialAccounts localizedContent={content} isApple /> : null}
            {!google.isActive ? <LinkSocialAccounts localizedContent={content} isGoogle /> : null}
            {!windows.isActive ? <LinkSocialAccounts localizedContent={content} isMicrosoft /> : null}
          </div>
        ) : null}
      </div>
    );
  };

  _filteredRolesByPlatformMode(roles) {
    this._filteredRolesByPlatformMode = this._filteredRolesByPlatformMode.bind(this);
    const { roleName } = this.props;

    if (isEltAssessmentMode()) {
      return Object.fromEntries(
        Object.entries(roles).filter(([key]) => key !== userRoles.TEACHER_ADMIN && key !== userRoles.LEARNER)
      );
    }

    if (isOicMode()) {
      return Object.fromEntries(
        Object.entries(roles).filter(([role]) => [userRoles.ORG_ADMIN, userRoles.TEACHER].includes(role))
      );
    }

    if (roleName !== userRoles.LEARNER) {
      return Object.fromEntries(Object.entries(roles).filter(([key]) => key !== userRoles.LEARNER));
    }

    return roles;
  }

  _blockRoleChangeTestTakerAm(roleName) {
    this._blockRoleChangeTestTakerAm = this._blockRoleChangeTestTakerAm.bind(this);
    // eslint-disable-next-line no-unused-expressions
    isEltAssessmentMode() && roleName === userRoles.LEARNER;
  }

  _validateSocialConfirmPassword() {
    const { socialConfirmPassword, formInputErrors, localizedContent: content } = this.props;

    switch (true) {
      case typeof socialConfirmPassword === 'string' && !socialConfirmPassword.length:
        formInputErrors.socialConfirmPassword = true;
        return {
          type: 'error',
          isActive: true,
          message: content.username_error
        };
      default:
        formInputErrors.socialConfirmPassword = false;
        return {
          type: null
        };
    }
  }

  _getNameErrors(name, isFirstName) {
    const { localizedContent: content, isCharLimited } = this.props;
    if (isCharLimited && name && name.length > NAME_MAX_LIMIT) {
      return isFirstName ? content.firstname_max_limit_error : content.lastname_max_limit_error;
    }
    if (name.length === 0) {
      return isFirstName ? content.firstname_error : content.lastname_error;
    }
    return content.name_invalid_error;
  }

  _goToClassSelection() {
    const { userEnrolAddToClassView } = this.props;
    userEnrolAddToClassView();
  }

  render() {
    const {
      localizedContent: content,
      roles = {},
      firstName = '',
      displayName,
      lastName = '',
      origUserName = '',
      userName = '',
      origEmail = '',
      email = '',
      oldPassword = '',
      password = '',
      newPassword = '',
      confirmPassword = '',
      socialConfirmPassword = '',
      roleName = '',
      yearGroup = {},
      newPasswordGuidance = null,
      hidden = [],
      formInputErrors = {},
      validationStatuses = {},
      isGeneratePasswordDisabled = false,
      onChange,
      onGeneratePassword,
      onGenerateUserName,
      showAppropriatePermissionsNotice,
      orgCurriculumType,
      getYearGroupOptions,
      isUserEnrollForm,
      selectedClassIds,
      classrooms,
      removeClassIdAction,
      isSupervised
    } = this.props;

    const handlers = pick(this.props, ['onChange', 'onBlur']);
    const isEmailSupported = origEmail !== origUserName;
    const yearGroups = getYearGroupOptions(orgCurriculumType);
    const resetPasswordLink = isCesMode() ? this._getCesResetPasswordLink() : content.hub_password_reset_link_url;

    return (
      <div>
        {!hidden.includes('firstName') ? (
          <ValidationStatus
            forId="firstName"
            isActive={formInputErrors.firstName}
            message={this._getNameErrors(firstName, true)}
          >
            <TextInput
              {...handlers}
              id="firstName"
              name="firstName"
              label={content.firstname_label}
              placeholder={content.firstname_placeholder}
              value={firstName}
              ariaLabel={content.firstname_ariaLabel}
            />
          </ValidationStatus>
        ) : null}
        {!hidden.includes('lastName') ? (
          <ValidationStatus
            forId="lastName"
            isActive={formInputErrors.lastName}
            message={this._getNameErrors(lastName, false)}
          >
            <TextInput
              {...handlers}
              id="lastName"
              name="lastName"
              label={content.lastname_label}
              placeholder={content.lastname_placeholder}
              value={lastName}
              ariaLabel={content.lastname_ariaLabel}
            />
          </ValidationStatus>
        ) : null}
        {!hidden.includes('userName') ? (
          <div className={styles.iconWrapper}>
            <ValidationStatus
              {...this._getUserNameValidationStatus(isEmailSupported)}
              forId="userName"
              containerClassName={isSupervised ? styles.supervisedUsername : ''}
            >
              {onGenerateUserName ? (
                <div>
                  <p className={styles.usernameLabel}>
                    {interpolateContent(content.username_label, { firstName: displayName })}{' '}
                    <span className={styles.usernameOxfordId}> {content.username_oxford_id}</span>
                  </p>
                  <TextInputWithButton
                    {...handlers}
                    id="userName"
                    name="userName"
                    onBlur={this._handleUserNameBlur}
                    onFocus={this._handleUserNameFocus}
                    placeholder={content.username_placeholder}
                    value={userName}
                    buttonTitle={content.username_generate_button}
                    buttonText={content.username_generate_button}
                    buttonAction={this._generateUserName}
                    buttonDisabled={!firstName || !lastName}
                    buttonType="button"
                  />
                </div>
              ) : (
                <div>
                  {isSupervised ? (
                    <>
                      <p className={styles.usernameLabel}>{content.username_label}</p>
                      <span className={styles.usernameOxfordId}>{content.username_this_is_your_oxford_id}</span>
                    </>
                  ) : (
                    <p className={styles.usernameLabel}>
                      {interpolateContent(content.username_label, { firstName: displayName })}{' '}
                      <span className={styles.usernameOxfordId}> {content.username_oxford_id}</span>
                    </p>
                  )}
                  <TextInput
                    {...(isEmailSupported
                      ? {
                          ...handlers,
                          onBlur: name => {
                            this._handleUserNameBlur(name);
                            if (handlers.onBlur) {
                              handlers.onBlur(name);
                            }
                          }
                        }
                      : {
                          onChange: value => {
                            onChange(value, 'userName');
                            onChange(value, 'email');
                          },
                          onBlur: name => {
                            this._handleUserNameBlur(name);
                          }
                        })}
                    id="userName"
                    name="userName"
                    onFocus={this._handleUserNameFocus}
                    placeholder={content.username_placeholder}
                    value={userName}
                    ariaLabel={content.username_ariaLabel}
                  />

                  {!isSupervised && (
                    <div
                      className={`${styles.privacyEditForm}`}
                      // eslint-disable-next-line react/no-danger

                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: isHubMode()
                          ? createAnchorToBlank(content.username_guidance_hub)
                          : createAnchorToBlank(content.username_guidance)
                      }}
                    />
                  )}
                </div>
              )}
            </ValidationStatus>
            {isSupervised && this.getSupervisedUserSupportLink()}
          </div>
        ) : null}
        {!hidden.includes('email') && isEmailSupported ? (
          <div>
            <ValidationStatus forId="email" {...this._getEmailValidationStatus()}>
              <TextInput
                {...handlers}
                id="email"
                name="email"
                label={content.email_label}
                placeholder={content.email_placeholder}
                value={email}
                ariaLabel={content.email_ariaLabel}
              />
            </ValidationStatus>
            <p className="gin-top1">{content.email_guidance}</p>
          </div>
        ) : null}
        {!hidden.includes('social-unlink-confirm') ? (
          <div>
            <ValidationStatus forId="socialConfirmPassword" {...this._validateSocialConfirmPassword()} message="">
              <TextInput
                {...handlers}
                id="socialConfirmPassword"
                name="socialConfirmPassword"
                type="password"
                onTypeStop={this._validateSocialConfirmPassword}
                label={content.user_form_social_confirm_password_label}
                placeholder={content.old_password_placeholder}
                value={socialConfirmPassword}
                ariaLabel={content.socialPassword_ariaLabel}
              />
            </ValidationStatus>
            <div className={styles.forgottenPasswordLink}>
              <a href={resetPasswordLink}>{content.my_profile_edit_form_forgotten_password}</a>
            </div>
          </div>
        ) : null}
        {!hidden.includes('oldPassword') ? (
          <div className={`${isSupervised ? styles.supervisedOldPassword : ''}`}>
            <p className={styles.oldPasswordLabel}>{content.old_password_label}</p>
            {isSupervised && <span>{content.supervised_user_password_info}</span>}
            <ValidationStatus
              forId="oldPassword"
              isActive={formInputErrors.oldPassword}
              message={content.old_password_error}
            >
              <TextInput
                {...handlers}
                id="oldPassword"
                name="oldPassword"
                type="password"
                placeholder={content.old_password_placeholder}
                value={oldPassword}
                ariaLabel={content.oldPassword_ariaLabel}
              />
            </ValidationStatus>
            {socialIdpIsEnabled(getCurrentPlatform()) && !isSupervised ? (
              <div className={styles.forgottenPasswordLink}>
                <a href={resetPasswordLink}>{content.my_profile_edit_form_forgotten_password}</a>
              </div>
            ) : null}
          </div>
        ) : null}
        {!hidden.includes('password') ? (
          <div>
            <ValidationStatus {...this._validatePasswordStatus()} forId="password">
              {onGeneratePassword ? (
                <TextInputWithButton
                  {...handlers}
                  onBlur={this._handlePasswordBlur}
                  id="password"
                  name="password"
                  type="text"
                  label={content.password_label}
                  placeholder={content.password_placeholder}
                  value={password}
                  onTypeStop={this._validatePasswordStatus}
                  onFocus={this._handlePasswordFocus}
                  buttonDisabled={isGeneratePasswordDisabled}
                  buttonTitle={content.password_generate_button}
                  buttonText={content.password_generate_button}
                  buttonAction={onGeneratePassword}
                  buttonType="button"
                />
              ) : (
                <TextInput
                  {...handlers}
                  id="password"
                  name="password"
                  type="text"
                  onFocus={this._handlePasswordFocus}
                  label={content.password_label}
                  placeholder={content.password_placeholder}
                  value={password}
                  ariaLabel={content.password_ariaLabel}
                />
              )}
            </ValidationStatus>
          </div>
        ) : null}
        {!hidden.includes('newPassword') ? (
          <div>
            <ValidationStatus {...this._getNewPasswordValidationStatus()} forId="newPassword">
              <TextInput
                {...handlers}
                id="newPassword"
                name="newPassword"
                type="password"
                label={content.new_password_label}
                placeholder={content.new_password_placeholder}
                value={newPassword}
                ariaLabel={content.newPassword_ariaLabel}
              />
            </ValidationStatus>
            {newPasswordGuidance || <p className="gin-top1">{content.new_password_guidance}</p>}
            <p className=" gin-top1 gin-left2">
              <span className="pad-right1">
                <b>{content.my_profile_edit_form_password_note}</b>
              </span>
              {content.my_profile_edit_form_password_requirement}
            </p>
            <input type="hidden" name="platform" value={getCurrentPlatform()} />
          </div>
        ) : null}
        {!hidden.includes('confirmPassword') ? (
          <ValidationStatus
            forId="confirmPassword"
            isActive={newPassword.length && confirmPassword.length && confirmPassword !== newPassword}
            message={content.confirm_password_error}
          >
            <TextInput
              {...handlers}
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              label={content.confirm_password_label}
              placeholder={content.confirm_password_placeholder}
              value={confirmPassword}
              ariaLabel={content.confirmPassword_ariaLabel}
            />
          </ValidationStatus>
        ) : null}
        {!hidden.includes('social') ? this._renderSocial() : null}
        {!hidden.includes('roleName') ? (
          <div>
            <ValidationStatus
              forId="roleName"
              isActive={formInputErrors.roleName}
              message={content.role_name_error}
              {...(validationStatuses && validationStatuses.roleName ? validationStatuses.roleName : {})}
            >
              {!this._blockRoleChangeTestTakerAm(roleName) ? (
                <Dropdown
                  {...handlers}
                  id="roleName"
                  name="roleName"
                  label={content.role_name_label}
                  value={roleName}
                  options={[
                    { value: '', text: content.role_name_default_option },
                    ...Object.entries(this._filteredRolesByPlatformMode(roles)).map(([value, text]) => ({
                      text,
                      value
                    }))
                  ]}
                />
              ) : null}
            </ValidationStatus>
          </div>
        ) : null}
        {!hidden.includes('yearGroup') ? (
          <div>
            <ValidationStatus
              forId="yearGroup"
              type={formInputErrors.yearGroup === null || formInputErrors.yearGroup === undefined ? null : 'valid'}
              isActive={
                formInputErrors.yearGroup === null || formInputErrors.yearGroup === undefined
                  ? formInputErrors.yearGroup
                  : !formInputErrors.yearGroup
              }
              message={content.yeargroup_info}
            >
              <Dropdown
                {...handlers}
                id="yearGroup"
                name="yearGroup"
                label={content.yeargroup_label}
                value={Object.keys(yearGroups).find(key => yearGroups[key].value === yearGroup)}
                options={Object.entries(yearGroups).map(([key, { name }]) => ({
                  text: name,
                  value: key
                }))}
                onChange={(key, name) => onChange(yearGroups[key].value, name)}
              />
            </ValidationStatus>
          </div>
        ) : null}

        {featureIsEnabled('add-managed-user-to-class-on-creation') && isUserEnrollForm ? (
          <div>
            <Label text={content.user_enrol_add_to_class} className={styles.addToClassLabel} />
            <SelectionList
              linkText={content.user_enrol_choose_classes_text}
              linkAction={() => this._goToClassSelection()}
            >
              {selectedClassIds && selectedClassIds.length
                ? selectedClassIds.map(id => (
                    <Lozenge
                      key={id}
                      id={id}
                      text={classrooms[id].name}
                      backgroundColor={colors.TEACHER}
                      buttonAction={() => removeClassIdAction(id)}
                    />
                  ))
                : null}
            </SelectionList>
          </div>
        ) : null}
        {showAppropriatePermissionsNotice ? <p className="gin-top1">{content.appropriate_permissions_notice}</p> : null}
      </div>
    );
  }
}

UserFormBody.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  roles: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  origUserName: PropTypes.string,
  userName: PropTypes.string,
  origEmail: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  socialConfirmPassword: PropTypes.string,
  roleName: PropTypes.string,
  newPasswordGuidance: PropTypes.node,
  hidden: PropTypes.arrayOf(PropTypes.string),
  formInputErrors: PropTypes.object,
  validationStatuses: PropTypes.object,
  isCheckingUserNameTaken: PropTypes.bool,
  isUserNameTaken: PropTypes.bool,
  isCheckingEmailTaken: PropTypes.bool,
  isEmailTaken: PropTypes.bool,
  goToForm: PropTypes.func,
  goToFormWithData: PropTypes.func,
  isGeneratePasswordDisabled: PropTypes.bool,
  isGeneratingPassword: PropTypes.bool,
  isCharLimited: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onGeneratePassword: PropTypes.func,
  onGenerateUserName: PropTypes.func,
  showAppropriatePermissionsNotice: PropTypes.bool,
  isGeneratingUserName: PropTypes.func,
  displayName: PropTypes.string,
  isOupUserEmailInvalid: PropTypes.bool,
  isUserEnrollForm: PropTypes.bool,
  orgCurriculumType: PropTypes.string.isRequired,
  getYearGroupOptions: PropTypes.func.isRequired,
  yearGroup: PropTypes.object,
  userAccount: PropTypes.object,
  userEnrolAddToClassView: PropTypes.func,
  selectedClassIds: PropTypes.array,
  classrooms: PropTypes.object,
  removeClassIdAction: PropTypes.func.isRequired,
  isSupervised: PropTypes.bool
};

export default compose(withGetYearGroupOptions)(UserFormBody);
