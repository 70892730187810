import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { keyBy, pick } from 'lodash';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';

import appSettings from '../../globals/appSettings';
import colors from '../../globals/colors';
import { fgClass } from '../../utils/colorClassNames';
import withLocalizedContent from '../../language/withLocalizedContent';
import withLocalizedErrors from '../../language/withLocalizedErrors';

import Dropdown from '../Dropdown/Dropdown';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Modals from '../Modals/Modals';
import PillTabs from '../PillTabs/PillTabs';
import DialsAccordion from '../../routes/development/ORB/DialsAccordion';
import ClassReportTable from '../../routes/development/ORB/ClassReportTable';
import ClassReportEngagementTable from '../ClassReportEngagementTable/ClassReportEngagementTable';
import ChangeLevelsPanel from '../../routes/development/ORB/ChangeLevelsPanel';
import { storeSavedSettings } from '../../redux/reducers/savedSettings';

import { featureIsEnabled } from '../../globals/envSettings';

class ClassReport extends Component {
  static _printReport = () => {
    window.print();
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedStudents: [],
      assignLevel: null,
      modals: {}
    };
  }

  getSelectedStudents() {
    const { results } = this.props;
    const { selectedStudents } = this.state;

    const studentsMap = results?.group?.students ? keyBy(results?.group?.students, 'id') : {};
    return Object.values(pick(studentsMap, selectedStudents)).filter(student => student);
  }

  handleSelectedGroupAssignLevel = () => {
    const { assignLevel, selectedStudents } = this.state;
    const { assignLevel: assignLevelFromProps, org } = this.props;
    if (assignLevel) {
      assignLevelFromProps(selectedStudents, assignLevel, org.id);
    }
  };

  handleSelectedGroupLevelUp = () => {
    const { selectedStudents } = this.state;
    const { levelUp, org } = this.props;
    levelUp(selectedStudents, org.id);
  };

  handleSelectedGroupLevelDown = () => {
    const { selectedStudents } = this.state;
    const { levelDown, org } = this.props;
    levelDown(selectedStudents, org.id);
  };

  handleSelectedGroupRestartLevel = () => {
    const { selectedStudents } = this.state;
    const { resetLevel, org } = this.props;
    resetLevel(selectedStudents, org.id);
  };

  toggleModal = modalId => {
    this.setState(prevState => ({
      modals: { [modalId]: !prevState.modals[modalId] }
    }));
  };

  onStudentSelect = selectedStudents => this.setState({ selectedStudents });

  levelActions = () => {
    const {
      results,
      localizedContent: { classReportComponent: i18n }
    } = this.props;

    const levelActions = [
      {
        label: i18n.assign_level_label,
        description: i18n.assign_level_description,
        id: 'ASSIGN_LEVEL',
        position: 1,
        onConfirm: this.handleSelectedGroupAssignLevel
      },
      {
        label: i18n.restart_level_label,
        description: i18n.restart_level_description,
        id: 'RESTART_LEVEL',
        position: 3,
        onConfirm: this.handleSelectedGroupRestartLevel
      }
    ];

    const selectedStudents = this.getSelectedStudents();

    if (results?.group?.levels) {
      const availableLevels = results?.group?.levels.list;
      const minLevel = availableLevels[0].id;
      const maxLevel = availableLevels[availableLevels.length - 1].id;
      const currentLevelsOfselectedStudents = selectedStudents.map(student =>
        student.levels.find(({ status }) => status)
      );

      if (!currentLevelsOfselectedStudents.every(({ levelId }) => levelId === maxLevel)) {
        levelActions.push({
          label: i18n.move_up_level_label,
          description: i18n.move_up_level_description,
          id: 'LEVEL_UP',
          position: 2,
          onConfirm: this.handleSelectedGroupLevelUp
        });
      }

      if (!currentLevelsOfselectedStudents.every(({ levelId }) => levelId === minLevel)) {
        levelActions.push({
          label: i18n.move_down_level_label,
          description: i18n.move_down_level_description,
          id: 'LEVEL_DOWN',
          position: 0,
          onConfirm: this.handleSelectedGroupLevelDown
        });
      }
    }

    return levelActions;
  };

  renderPrintReportName() {
    const {
      classroom,
      localizedContent: { classReportComponent: i18n }
    } = this.props;

    return (
      <div className="only-print">
        <SVGIcon
          glyph={GLYPHS.ICON_CLASS}
          className={classnames('glyphFormating', {
            [fgClass(colors.CLASSROOM)]: colors.CLASSROOM
          })}
        />
        {i18n.class_report}
        {classroom.name && <h2>{classroom.name}</h2>}
      </div>
    );
  }

  renderDials() {
    const { locationId, org, dialsData, results } = this.props;

    return (
      <div>
        {results.failure ? null : (
          <DialsAccordion
            curriculumLocale={(appSettings.curriculumTypes[org.curriculumType] || {}).curriculumLocale}
            dialsData={dialsData}
            locationId={locationId}
          />
        )}
      </div>
    );
  }

  renderProgressTab() {
    const {
      locationId,
      org,
      classroom,
      results,
      refreshClassReport,
      showClassReportRefreshBtn,
      getLevelPanel,
      onNavigateToOrg,
      levelUp,
      levelDown,
      delayLevelChange,
      resetLevel,
      localizedContent: { classReportComponent: i18n }
    } = this.props;

    return results ? (
      <>
        <ClassReportTable
          curriculumType={org.curriculumType}
          orgId={org.id}
          classroom={classroom.id}
          results={results.group}
          level={results.level}
          levelLoading={results.levelLoading}
          getLevelPanel={getLevelPanel(org.id, classroom.id, locationId)}
          onStudentSelect={this.onStudentSelect}
          currentStudents={results.group?.student}
          onNavigateToOrg={onNavigateToOrg}
          onLevelUpConfirmation={levelUp}
          onLevelDownConfirmation={levelDown}
          onLevelChangeDelay={delayLevelChange}
          onLevelReset={resetLevel}
          onTriggerRefresh={refreshClassReport}
          showClassReportRefreshBtn={showClassReportRefreshBtn}
          content={i18n}
          errors={results.errors}
        />
        <ChangeLevelsPanel
          selectedLearners={this.getSelectedStudents()}
          toggleModal={this.toggleModal}
          actions={this.levelActions()}
        />
      </>
    ) : null;
  }

  renderEngagementTab() {
    const { org, classroom, results, onNavigateToOrg, dialsData, locationId, orgSubscriptions } = this.props;

    return results ? (
      <ClassReportEngagementTable
        org={org}
        classroom={classroom}
        results={results.group}
        onNavigateToOrg={onNavigateToOrg}
        dialsData={dialsData}
        locationId={locationId}
        orgSubscriptions={orgSubscriptions}
      />
    ) : null;
  }

  renderReport() {
    const {
      locationId,
      results,
      getLocalizedErrorMessage,
      localizedContent: { classReportComponent: i18n },
      storeSavedSettingsAction,
      orbClassReportTab
    } = this.props;

    if (results.failure) {
      return (
        <p className="error-paragraph">
          {results.errors ? getLocalizedErrorMessage(results.errors) : i18n.fetch_error_message}
        </p>
      );
    }

    if (!results.success) return null;

    const progressTabName = ['ae', 'cn'].includes(locationId.toLowerCase())
      ? i18n.progress_tab_name_ae
      : i18n.progress_tab_name;

    const tabItems = featureIsEnabled('orb-class-report-overview')
      ? [
          {
            action: () => storeSavedSettingsAction({ orbClassReportTab: 0 }),
            tabText: i18n.engagement_tab_name,
            panelContent: this.renderEngagementTab()
          },
          {
            action: () => storeSavedSettingsAction({ orbClassReportTab: 1 }),
            tabText: progressTabName,
            panelContent: this.renderProgressTab()
          }
        ]
      : [
          {
            action: () => storeSavedSettingsAction({ orbClassReportTab: 0 }),
            tabText: progressTabName,
            panelContent: this.renderProgressTab()
          },
          {
            action: () => storeSavedSettingsAction({ orbClassReportTab: 1 }),
            tabText: i18n.engagement_tab_name,
            panelContent: this.renderEngagementTab()
          }
        ];

    return <PillTabs current={orbClassReportTab} items={tabItems} />;
  }

  renderModals() {
    const {
      results,
      localizedContent: { classReportComponent: i18n, sharedTerms }
    } = this.props;
    const { modals, assignLevel } = this.state;

    return this.levelActions().map((option, $0) => (
      <Modals
        key={$0}
        visibilityChecker={modals[option.id]}
        title={option.label}
        subTitle={option.description}
        bodyHtml={
          results?.group?.levels && option.id === 'ASSIGN_LEVEL'
            ? [
                <Dropdown
                  key="levelSelect"
                  id="level"
                  label={i18n.select_level}
                  options={[{ text: i18n.please_select_level }].concat(
                    results.group.levels.list.map(level => ({
                      value: level.id,
                      text: `${sharedTerms.OXFORD_LEVEL} ${level.name}`
                    }))
                  )}
                  value={assignLevel}
                  onChange={value => this.setState({ assignLevel: value })}
                  labelHidden
                />
              ]
            : []
        }
        cancelButtonText={i18n.cancel}
        confirmButtonText={i18n.confirm}
        successCallback={() => {
          option.onConfirm();
          this.toggleModal(option.id);
        }}
        cancelCallBack={() => this.toggleModal(option.id)}
        closeCallBack={() => this.toggleModal(option.id)}
      />
    ));
  }

  render() {
    const {
      localizedContent: { classReportComponent: i18n }
    } = this.props;
    return (
      <div>
        {featureIsEnabled('orb-class-report-overview') ||
        featureIsEnabled('orb-class-report-overview-ui-without-avallain-data') ? null : (
          <a
            role="link"
            className="print-link"
            onClick={ClassReport._printReport}
            onKeyDown={ClassReport._printReport}
            tabIndex={0}
          >
            {i18n.print_class_report}
          </a>
        )}
        {this.renderDials()}
        {this.renderPrintReportName()}
        {this.renderReport()}
        {this.renderModals()}
      </div>
    );
  }
}

ClassReport.propTypes = {
  locationId: PropTypes.string.isRequired,
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
    curriculumType: PropTypes.string.isRequired
  }).isRequired,
  classroom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }).isRequired,
  dialsData: PropTypes.object,
  results: PropTypes.object,
  refreshClassReport: PropTypes.func.isRequired,
  showClassReportRefreshBtn: PropTypes.bool.isRequired,

  getLevelPanel: PropTypes.func.isRequired,
  assignLevel: PropTypes.func.isRequired,
  levelUp: PropTypes.func.isRequired,
  levelDown: PropTypes.func.isRequired,
  delayLevelChange: PropTypes.func.isRequired,
  resetLevel: PropTypes.func.isRequired,
  onNavigateToOrg: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  getLocalizedErrorMessage: PropTypes.func,
  orbClassReportTab: PropTypes.number,
  storeSavedSettingsAction: PropTypes.func,
  orgSubscriptions: PropTypes.object
};

export default compose(
  withLocalizedContent('classReportComponent', 'sharedTerms'),
  withLocalizedErrors('orb-reporting'),
  connect(
    state => ({
      orbClassReportTab: state.savedSettings.settings.orbClassReportTab,
      orgSubscriptions: state.subscriptions
    }),
    {
      storeSavedSettingsAction: storeSavedSettings
    }
  )
)(ClassReport);
