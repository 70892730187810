import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, TextLink } from '@oup/shared-front-end';
import { OUP_SYMBOL_REBRANDING, ICON_CHECK_CIRCLE, ICON_EDIT } from '@oup/shared-front-end/svg/oup';

import { featureIsEnabled } from '../../globals/envSettings';
import entityListingStyles from '../EntityListing/EntityListing.scss';
import styles from './PlatformEntitlementListing.scss';
import content from '../../utils/cmsContent';

function PlatformEntitlementListing({ items }) {
  const { platformEntitlementListing: CMS } = content;
  const navigate = useNavigate();

  const renderTableRows = () =>
    Object.entries(items).reduce(
      (rows, [id, { productDetails }]) =>
        rows.concat(
          <tr key={id} className={entityListingStyles.bottomBorder}>
            <td>
              <div className={entityListingStyles.entity}>
                <OUP_SYMBOL_REBRANDING alt={productDetails.title} className={styles.ProductIcon} />
                <div className={styles.title}>
                  <TextLink to={productDetails.url}>{productDetails.title}</TextLink>
                </div>
              </div>
            </td>
            <td>
              <div className={entityListingStyles.td}>
                <ICON_CHECK_CIRCLE className={styles.StatusIcon} />
              </div>
            </td>
            {featureIsEnabled('otc-registration') && (
              <td>
                <div className={entityListingStyles.td}>
                  <Button
                    variant="transparent"
                    onClick={() => navigate(productDetails.registrationPath)}
                    icon={{ component: <ICON_EDIT className={styles.StatusIcon} /> }}
                  />
                </div>
              </td>
            )}
          </tr>
        ),
      []
    );

  return (
    <div className="row">
      <div className="col horizantal-scroll-mobile">
        <table className={entityListingStyles.table}>
          <thead className={entityListingStyles.thead}>
            <tr>
              <th scope="col" className={entityListingStyles.control}>
                <div className={entityListingStyles.header}>
                  <div className={entityListingStyles.heading}>{CMS.heading}</div>
                </div>
              </th>
              <th scope="col">
                <div className={entityListingStyles.th}>
                  <div className={entityListingStyles.column}>{CMS.status_column}</div>
                </div>
              </th>
              {featureIsEnabled('otc-registration') && (
                <th scope="col">
                  <div className={entityListingStyles.th}>
                    <div className={entityListingStyles.column}>{CMS.details_column}</div>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
    </div>
  );
}

PlatformEntitlementListing.propTypes = {
  items: PropTypes.object.isRequired
};

export default PlatformEntitlementListing;
