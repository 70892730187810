// IMPORTANT: This older reducer is unusual in that the onBlur actions are
// handled via the enrollUSer.saga.js and the field validation functions are
// defined in a separate fieldValidation.js file.
// Since it's creation we've added "isValid" validation as-you-type (in this ruducer)
import { BULK_UPLOAD } from '@oup/shared-node-browser/constants.js';

// We'll re-use these handy validation functions for the validate-as-you-type:
// TODO: fix dependency cycle
/* eslint-disable import/no-cycle */
import { validateEntry } from '@oup/shared-node-browser/bulkUsersValidation/validateEntry.js';
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateRole
} from '../sagas/appFunctions/authorisedFunctions/organisation/enrolUser/fieldValidation.js';

export const LOAD_ENROL_USER_SAGA = 'enrolUser/LOAD_ENROL_USER_SAGA';
// Input field updates
const SET_FIRSTNAME_VALUE = 'enrolUser/SET_FIRSTNAME_VALUE';
const SET_LASTNAME_VALUE = 'enrolUser/SET_LASTNAME_VALUE';
export const SET_EMAIL_VALUE = 'enrolUser/SET_EMAIL_VALUE';
export const SET_EMAIL_NOTICE = 'enrolUser/SET_EMAIL_NOTICE';
const SET_CONTEXT_VALUE = 'enrolUser/SET_CONTEXT_VALUE';
export const SET_ROLE_VALUE = 'enrolUser/SET_ROLE_VALUE';
export const SET_SIGNIN_CARD_DATA = 'enrolUser/SET_SIGNIN_CARD_DATA';
export const GENERATE_USERNAME_REQUEST = 'enrolUser/GENERATE_USERNAME_REQUEST';
export const GENERATE_USERNAME_SUCCESS = 'enrolUser/GENERATE_USERNAME_SUCCESS';
export const GENERATE_USERNAME_FAILURE = 'enrolUser/GENERATE_USERNAME_FAILURE';
export const CLEAR_GENERATED_USERNAME = 'enrolUser/CLEAR_GENERATED_USERNAME';
export const SET_IS_YOUNG_LEARNER = 'enrolUser/SET_IS_YOUNG_LEARNER';
const SET_GENERIC_FIELD_VALUE = 'enrolUser/SET_GENERIC_FIELD_VALUE';

// Input field onBlurs
export const FIRSTNAME_ON_BLUR = 'enrolUser/FIRSTNAME_ON_BLUR';
export const LASTNAME_ON_BLUR = 'enrolUser/LASTNAME_ON_BLUR';
export const EMAIL_ON_BLUR = 'enrolUser/EMAIL_ON_BLUR';
export const ROLE_ON_BLUR = 'enrolUser/SET_ROLE_ON_BLUR';

// Input field validation
const SET_FIRSTNAME_VALIDATION = 'enrolUser/SET_FIRSTNAME_VALIDATION';
const SET_LASTNAME_VALIDATION = 'enrolUser/SET_LASTNAME_VALIDATION';
const SET_EMAIL_VALIDATION = 'enrolUser/SET_EMAIL_VALIDATION';
const SET_EMAIL_WAITING = 'enrolUser/SET_EMAIL_WAITING';
const SET_USER_EXISTS = 'enrolUser/SET_USER_EXISTS';
const SET_USER_HAS_NAMES_SET = 'enrolUser/SET_USER_HAS_NAMES_SET';
const SET_INVITE_PENDING = 'enrolUser/SET_INVITE_PENDING';
const SET_ROLE_VALIDATION = 'enrolUser/SET_ROLE_VALIDATION';
const SET_FULL_FORM_VALIDATION = 'enrolUser/SET_FULL_FORM_VALIDATION';
const SET_GENERIC_FIELD_VALIDATION = 'enrolUser/SET_GENERIC_FIELD_VALIDATION';
export const SET_CHECK_USERNAME_AVAILABILITY = 'enrolUser/SET_CHECK_USERNAME_AVAILABILITY';
export const SET_CHECK_USERNAME_AVAILABILITY_STATUS = 'enrolUser/SET_CHECK_USERNAME_AVAILABILITY_STATUS';

// Submission and result actions
export const SUBMIT_FORM = 'enrolUser/SUBMIT_FORM';
export const BULK_REVIEWABLE_FORM_SUBMIT = 'enrolUser/BULK_REVIEWABLE_FORM_SUBMIT';
const SHOW_SUBMITTING = 'enrolUser/SHOW_SUBMITTING';
const SHOW_CONFIRMATION = 'enrolUser/SHOW_CONFIRMATION';
export const CHECK_USERNAME_EXISTS = 'enrolUser/CHECK_USERNAME_EXISTS';

export const RESET_ROLE = 'enrolUser/RESET_ROLE';

// Bulk Actions
const SHOW_BULK_USER_FORM = 'enrolUser/SHOW_BULK_USER_FORM';
export const BULK_SUBMIT_FORM = 'enrolUser/BULK_SUBMIT_FORM';
export const CSV_BULK_SUBMIT_FORM = 'enrolUser/CSV_BULK_SUBMIT_FORM';
export const BULK_BY_USER_TYPE_SUBMIT_FORM = 'enrolUser/BULK_BY_USER_TYPE_SUBMIT_FORM';
export const CSV_BULK_REVIEWABLE_SUBMIT_FORM = 'enrolUser/CSV_BULK_REVIEWABLE_SUBMIT_FORM';
const SELECT_FILE = 'enrolUser/SELECT_FILE';
const RESET_FILE = 'enrolUser/RESET_FILE';
const SHOW_ENROL_USER_FORM = 'enrolUser/SHOW_ENROL_USER_FORM';
export const BULK_SHOW_CONFIRMATION = 'enrolUser/BULK_SHOW_CONFIRMATION';
export const SHOW_BULK_ERROR_REVIEW = 'enrolUser/SHOW_BULK_ERROR_REVIEW';
export const SHOW_BULK_INPUT_ERROR_REVIEW = 'enrolUser/SHOW_BULK_INPUT_ERROR_REVIEW';
export const SHOW_BULK_VALIDATION_ERROR_REVIEW = 'enrolUser/SHOW_BULK_VALIDATION_ERROR_REVIEW';
export const SHOW_BULK_DATA_REVIEW = 'enrolUser/SHOW_BULK_DATA_REVIEW';
export const ADD_TO_CLASS_VIEW = 'enrolUser/ADD_TO_CLASS_VIEW';
export const REMOVE_CLASS = 'enrolUser/REMOVE_CLASS';

// Reset the form
export const RESET_FORM = 'enrolUser/RESET_FORM';

export const formStates = {
  INPUTTING: 'INPUTTING',
  BULK_USER_INPUTTING: 'BULK_USER_INPUTTING',
  SUBMITTING: 'SUBMITTING',
  BULK_SUBMITTING: 'BULK_SUBMITTING',
  BULK_BY_USER_TYPE_SUBMITTING: 'BULK_BY_USER_TYPE_SUBMITTING',
  CONFIRMATION: 'CONFIRMATION',
  BULK_CONFIRMATION: 'BULK_CONFIRMATION',
  BULK_ERROR_REVIEWING: 'BULK_ERROR_REVIEWING',
  BULK_DATA_REVIEWING: 'BULK_DATA_REVIEWING',
  BULK_VALIDATION_ERROR_REVIEWING: 'BULK_VALIDATION_ERROR_REVIEWING',
  ADD_TO_CLASS_VIEW: 'ADD_TO_CLASS'
};

export const initialState = {
  formState: formStates.INPUTTING,
  context: null,

  // Input values
  firstNameValue: '',
  firstNameIsValid: null,
  firstNameIsInvalid: null,
  firstNameErrorMessage: '',
  lastNameValue: '',
  lastNameIsValid: null,
  lastNameIsInvalid: null,
  lastNameErrorMessage: '',
  emailValue: '',
  username: '',
  usernameWithoutOrg: '',
  usernameGenerated: false,
  usernameGenerating: false,
  usernameErrorMessage: '',
  searchClassesValue: '',
  classNameValue: '',
  emailIsValid: null,
  emailIsInvalid: null,
  emailIsWaiting: null,
  emailHasNotice: false,
  invitePending: null,
  userExists: null,
  userHasNamesSet: null,
  emailErrorMessage: '',
  roleValue: '',
  signInCardUrl: '',
  roleIsValid: null,
  roleIsInvalid: null,
  roleErrorMessage: '',
  isYoungLearner: false,

  // Bulk Values
  file: null,
  failedEntries: [],
  isFileSelected: false,
  maximumAllowedRecords: BULK_UPLOAD.MAX_ROWS,
  availableCredits: 0,

  groupType: '',
  groupId: '',
  userType: '',

  // Submission result
  failed: false,
  classesOnboardFailed: false,

  // Entries
  entries: []
};

export default function enrolUser(state = initialState, action) {
  // Helper for validate-as-you-type:
  // This re-uses custom validation functions imported from fieldValidation.js.
  // Note that "valid" is not necessarily the opposite of "invalid".
  // We show happy-path "valid" confirmation while typing but only show "invalid" on blur.
  // Hence we leave the isInvalid & errorMessage states as-is until the field becomes explicitly "valid".
  // The isOUPStaff flag is used in cases when an OUP_ADMIN is enrolling an OUP user so their email should be '@oup.com'
  // context is used to distinguish between cases where an OUP_ADMIN is adding staff to an ORG (eg teachers) and OUP Staff (eg OUP_SUPPORT)
  function validateOnChange(propPrefix, value, validatorFunction, isOUPStaff, context, role) {
    const isValid = !validatorFunction(value, isOUPStaff, context, role).invalid;
    return {
      [`${propPrefix}IsValid`]: isValid,
      [`${propPrefix}IsInvalid`]: isValid ? false : state[`${propPrefix}IsInvalid`],
      errorMessage: isValid ? '' : state.errorMessage
    };
  }

  function setEntryValue(entries, id, key, value) {
    return entries.map(entry =>
      entry.temp_id === id
        ? {
            ...entry,
            [key]: value
          }
        : entry
    );
  }

  function setEntryValidation(entries, id, key, value, validationPatten) {
    return entries.map(entry => (entry.temp_id === id ? validateEntry(entry, key, value, validationPatten) : entry));
  }

  function setEntryUsernameValidation(entries, id, isLoading, isAvailable) {
    return entries.map(entry =>
      entry.temp_id === id
        ? {
            ...entry,
            usernameChecking: isLoading,
            validationErrors: {
              ...entry.validationErrors,
              userName: {
                duplicated: !isAvailable
              }
            }
          }
        : entry
    );
  }

  switch (action.type) {
    // Input field updates
    case SET_FIRSTNAME_VALUE:
      return {
        ...state,
        firstNameValue: action.firstNameValue,
        ...validateOnChange('firstName', action.firstNameValue, validateFirstName)
      };

    case SET_LASTNAME_VALUE:
      return {
        ...state,
        lastNameValue: action.lastNameValue,
        ...validateOnChange('lastName', action.lastNameValue, validateLastName)
      };
    case SET_EMAIL_VALUE:
      return {
        ...state,
        emailValue: action.emailValue,
        username: '',
        emailIsWaiting: null,
        emailErrorMessage: '',
        ...validateOnChange(
          'email',
          action.emailValue,
          validateEmail,
          action.isOUPStaff,
          action.context,
          action.roleValue
        )
      };
    case GENERATE_USERNAME_REQUEST:
      return {
        ...state,
        usernameGenerating: true
      };
    case GENERATE_USERNAME_SUCCESS:
      return {
        ...state,
        username: action.usernameObj.username,
        usernameWithoutOrg: action.usernameObj.usernameWithoutOrg,
        usernameGenerated: true,
        usernameGenerating: false,
        usernameErrorMessage: '',
        emailIsInvalid: null,
        emailErrorMessage: ''
      };
    case CLEAR_GENERATED_USERNAME:
      return {
        ...state,
        username: '',
        usernameWithoutOrg: '',
        usernameGenerated: false,
        usernameGenerating: false,
        usernameErrorMessage: '',
        emailIsInvalid: null,
        emailErrorMessage: '',
        emailValue: ''
      };
    case GENERATE_USERNAME_FAILURE:
      return {
        ...state,
        usernameGenerated: false,
        usernameGenerating: false,
        usernameErrorMessage: action.errorMsg
      };
    case SET_CONTEXT_VALUE:
      return {
        ...state,
        context: action.context
      };
    case SET_SIGNIN_CARD_DATA:
      return {
        ...state,
        signInCardUrl: action.signInCardUrl
      };
    case SET_ROLE_VALUE:
      return {
        ...state,
        roleValue: action.roleValue,
        ...validateOnChange('role', action.roleValue, validateRole)
      };
    case SHOW_BULK_USER_FORM:
      return {
        ...state,
        formState: formStates.BULK_USER_INPUTTING
      };
    case SELECT_FILE:
      return {
        ...state,
        file: action.file,
        isFileSelected: action.isFileSelected
      };
    case RESET_FILE:
      return {
        ...state,
        file: null,
        isFileSelected: false
      };
    case SET_IS_YOUNG_LEARNER:
      return {
        ...state,
        isYoungLearner: action.value
      };
    case SET_GENERIC_FIELD_VALUE:
      return {
        ...state,
        entries: setEntryValue(state.entries, action.id, action.key, action.value)
      };
    case SET_GENERIC_FIELD_VALIDATION:
      return {
        ...state,
        entries: setEntryValidation(state.entries, action.id, action.key, action.value, action.validationPatten)
      };
    case SET_CHECK_USERNAME_AVAILABILITY_STATUS:
      return {
        ...state,
        entries: setEntryUsernameValidation(state.entries, action.id, action.isLoading, action.isAvailable)
      };
    // Submission and result actions
    case SHOW_ENROL_USER_FORM:
      return {
        ...state,
        formState: formStates.INPUTTING
      };
    case CSV_BULK_SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.BULK_SUBMITTING
      };
    case BULK_BY_USER_TYPE_SUBMIT_FORM:
      return {
        ...state,
        groupType: action.groupType,
        groupId: action.groupId,
        userType: action.userType,
        formState: formStates.BULK_BY_USER_TYPE_SUBMITTING
      };
    case CSV_BULK_REVIEWABLE_SUBMIT_FORM:
      return {
        ...state,
        orgId: action.orgId,
        orgRole: action.orgRole,
        userTypeToEnrol: action.userTypeToEnrol,
        formState: formStates.BULK_BY_USER_TYPE_SUBMITTING
      };
    case BULK_SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.BULK_SUBMITTING
      };
    case SHOW_BULK_ERROR_REVIEW:
      return {
        ...state,
        formState: formStates.BULK_ERROR_REVIEWING,
        failedEntries: action.failedEntries,
        maximumAllowedRecords: action.maximumAllowedRecords,
        availableCredits: action.availableCredits
      };
    case SHOW_BULK_VALIDATION_ERROR_REVIEW:
      return {
        ...state,
        formState: formStates.BULK_VALIDATION_ERROR_REVIEWING,
        failedEntries: action.failedEntries,
        maximumAllowedRecords: action.maximumAllowedRecords,
        availableCredits: action.availableCredits
      };
    case SHOW_BULK_DATA_REVIEW:
      return {
        ...state,
        formState: formStates.BULK_DATA_REVIEWING,
        entries: action.entries
      };
    case ADD_TO_CLASS_VIEW:
      return {
        ...state,
        formState: formStates.ADD_TO_CLASS_VIEW
      };
    case BULK_REVIEWABLE_FORM_SUBMIT:
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_SUBMITTING:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        failed: action.failed,
        apiError: action.error,
        classesOnboardFailed: action.classesOnboardFailed,
        confirmedUserEmail: state.emailValue
      };
    case BULK_SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.BULK_CONFIRMATION
      };

    // Input field validation
    case SET_FIRSTNAME_VALIDATION:
      return {
        ...state,
        firstNameIsInvalid: action.firstNameIsInvalid,
        firstNameErrorMessage: action.firstNameErrorMessage
      };
    case SET_LASTNAME_VALIDATION:
      return {
        ...state,
        lastNameIsInvalid: action.lastNameIsInvalid,
        lastNameErrorMessage: action.lastNameErrorMessage
      };
    case SET_EMAIL_VALIDATION:
      return {
        ...state,
        emailIsInvalid: action.emailIsInvalid,
        emailErrorMessage: action.emailErrorMessage
      };
    case SET_EMAIL_WAITING:
      return {
        ...state,
        emailIsWaiting: action.isWaiting
      };
    case SET_EMAIL_NOTICE:
      return {
        ...state,
        emailHasNotice: action.emailHasNotice,
        emailErrorMessage: action.emailErrorMessage
      };
    case SET_INVITE_PENDING:
      return {
        ...state,
        invitePending: action.invitePending
      };
    case SET_USER_EXISTS:
      return {
        ...state,
        userExists: action.userExists
      };
    case SET_USER_HAS_NAMES_SET:
      return {
        ...state,
        userHasNamesSet: action.userHasNamesSet
      };
    case SET_ROLE_VALIDATION:
      return {
        ...state,
        roleIsInvalid: action.roleIsInvalid,
        roleErrorMessage: action.roleErrorMessage
      };
    case SET_FULL_FORM_VALIDATION:
      return {
        ...state,
        firstNameIsInvalid: action.firstNameIsInvalid,
        firstNameErrorMessage: action.firstNameErrorMessage,
        lastNameIsInvalid: action.lastNameIsInvalid,
        lastNameErrorMessage: action.lastNameErrorMessage,
        emailIsInvalid: action.emailIsInvalid,
        emailErrorMessage: action.emailErrorMessage,
        roleIsInvalid: action.roleIsInvalid,
        roleErrorMessage: action.roleErrorMessage
      };
    case RESET_ROLE:
      return {
        ...state,
        roleValue: '',
        roleIsValid: null,
        roleIsInvalid: null,
        roleErrorMessage: ''
      };
    // Reset the form
    case RESET_FORM:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

// Input field updates
export const setFirstNameValue = firstNameValue => ({
  type: SET_FIRSTNAME_VALUE,
  firstNameValue
});

export const setLastNameValue = lastNameValue => ({
  type: SET_LASTNAME_VALUE,
  lastNameValue
});

export const setEmailValue = (emailValue, isOUPStaff, context, userExists, roleValue) => ({
  type: SET_EMAIL_VALUE,
  emailValue,
  isOUPStaff,
  context,
  userExists,
  roleValue
});

export const generateUsername = payload => ({
  type: GENERATE_USERNAME_REQUEST,
  payload
});

export const clearGeneratedUsername = () => ({
  type: CLEAR_GENERATED_USERNAME
});
export const generateUsernameSuccess = usernameObj => ({
  type: GENERATE_USERNAME_SUCCESS,
  usernameObj
});

export const generateUsernameFailure = errorMsg => ({
  type: GENERATE_USERNAME_FAILURE,
  errorMsg
});

export const setContextValue = context => ({
  type: SET_CONTEXT_VALUE,
  context
});

export const setSignInCardUrl = signInCardUrl => ({
  type: SET_SIGNIN_CARD_DATA,
  signInCardUrl
});

export const setRoleValue = roleValue => ({
  type: SET_ROLE_VALUE,
  roleValue
});

export const showBulkUserInput = () => ({
  type: SHOW_BULK_USER_FORM
});

export const showErrorReview = (failedEntries = [], maximumAllowedRecords, availableCredits) => ({
  type: SHOW_BULK_ERROR_REVIEW,
  failedEntries,
  maximumAllowedRecords,
  availableCredits
});

export const showValidationErrorReview = (failedEntries = [], maximumAllowedRecords, availableCredits) => ({
  type: SHOW_BULK_VALIDATION_ERROR_REVIEW,
  failedEntries,
  maximumAllowedRecords,
  availableCredits
});

export const showDataReview = (entries = []) => ({
  type: SHOW_BULK_DATA_REVIEW,
  entries
});

export const addToClassView = () => ({
  type: ADD_TO_CLASS_VIEW
});

export const removeClass = id => ({
  type: REMOVE_CLASS,
  id
});

export const bulkSubmitForm = () => ({
  type: BULK_SUBMIT_FORM
});

export const csvBulkSubmitForm = file => ({
  file,
  type: CSV_BULK_SUBMIT_FORM
});
export const bulkByUserTypeSubmitForm = ({ groupType, groupId, userTypeToEnrol: userType }) => ({
  type: BULK_BY_USER_TYPE_SUBMIT_FORM,
  groupType,
  groupId,
  userType
});
export const csvBulkReviewableSubmitForm = ({ orgId, orgRole, userTypeToEnrol, isPlacementCentre }) => ({
  type: CSV_BULK_REVIEWABLE_SUBMIT_FORM,
  orgId,
  orgRole,
  userTypeToEnrol,
  isPlacementCentre
});
export const bulkReviewableFormSubmit = ({ orgId, orgRole, userTypeToEnrol, isPlacementCentre }) => ({
  type: BULK_REVIEWABLE_FORM_SUBMIT,
  orgId,
  orgRole,
  userTypeToEnrol,
  isPlacementCentre
});
export const selectFile = selectedFileData => ({
  type: SELECT_FILE,
  file: selectedFileData,
  isFileSelected: true
});
export const resetFile = () => ({
  type: RESET_FILE
});
export const setIsYoungLearner = value => ({
  type: SET_IS_YOUNG_LEARNER,
  value
});
export const setGenericFieldValue = (id, key, value) => ({
  type: SET_GENERIC_FIELD_VALUE,
  id,
  key,
  value
});
// Input field onBlurs
export const firstnameOnBlur = () => ({
  type: FIRSTNAME_ON_BLUR
});
export const lastnameOnBlur = () => ({
  type: LASTNAME_ON_BLUR
});
export const emailOnBlur = () => ({
  type: EMAIL_ON_BLUR
});
export const roleOnBlur = () => ({
  type: ROLE_ON_BLUR
});

// Input field validation
export const setFirstNameValidation = (firstNameIsInvalid, firstNameErrorMessage) => ({
  type: SET_FIRSTNAME_VALIDATION,
  firstNameIsInvalid,
  firstNameErrorMessage
});
export const setLastNameValidation = (lastNameIsInvalid, lastNameErrorMessage) => ({
  type: SET_LASTNAME_VALIDATION,
  lastNameIsInvalid,
  lastNameErrorMessage
});
export const setEmailValidation = (emailIsInvalid, emailErrorMessage) => ({
  type: SET_EMAIL_VALIDATION,
  emailIsInvalid,
  emailErrorMessage
});
export const setEmailWaiting = isWaiting => ({
  type: SET_EMAIL_WAITING,
  isWaiting
});
export const setEmailNotice = (emailHasNotice, emailErrorMessage) => ({
  type: SET_EMAIL_NOTICE,
  emailHasNotice,
  emailErrorMessage
});
export const setInvitePending = invitePending => ({
  type: SET_INVITE_PENDING,
  invitePending
});
export const setUserExists = userExists => ({
  type: SET_USER_EXISTS,
  userExists
});
export const setUserHasNamesSet = userHasNamesSet => ({
  type: SET_USER_HAS_NAMES_SET,
  userHasNamesSet
});
export const setRoleValidation = (roleIsInvalid, roleErrorMessage) => ({
  type: SET_ROLE_VALIDATION,
  roleIsInvalid,
  roleErrorMessage
});
export const setFullFormValidation = (
  firstNameIsInvalid,
  firstNameErrorMessage,
  lastNameIsInvalid,
  lastNameErrorMessage,
  emailIsInvalid,
  emailErrorMessage,
  roleIsInvalid,
  roleErrorMessage
) => ({
  type: SET_FULL_FORM_VALIDATION,
  firstNameIsInvalid,
  firstNameErrorMessage,
  lastNameIsInvalid,
  lastNameErrorMessage,
  emailIsInvalid,
  emailErrorMessage,
  roleIsInvalid,
  roleErrorMessage
});
export const setGenericFieldValidation = (id, key, value, validationPatten) => ({
  type: SET_GENERIC_FIELD_VALIDATION,
  id,
  key,
  value,
  validationPatten
});

export const setUsernameAvailability = (id, orgId, key, value, validationPattern) => ({
  type: SET_CHECK_USERNAME_AVAILABILITY,
  id,
  orgId,
  key,
  value,
  validationPattern
});

export const setUsernameAvailabilityStatus = (id, isLoading = false, isAvailable = false) => ({
  type: SET_CHECK_USERNAME_AVAILABILITY_STATUS,
  id,
  isLoading,
  isAvailable
});

// Submission and result actions
export const showEnrolUserForm = () => ({
  type: SHOW_ENROL_USER_FORM
});
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const showSubmitting = () => ({
  type: SHOW_SUBMITTING
});
export const showConfirmation = (failed, error, classesOnboardFailed) => ({
  type: SHOW_CONFIRMATION,
  failed,
  error,
  classesOnboardFailed
});
export const bulkShowConfirmation = () => ({
  type: BULK_SHOW_CONFIRMATION
});
export const checkUserName = username => ({
  type: CHECK_USERNAME_EXISTS,
  username
});

// Reset dropdown
export const resetRoleValues = () => ({
  type: RESET_ROLE
});
// Reset the form
export const resetForm = () => ({
  type: RESET_FORM
});
