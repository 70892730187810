import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { roleIsAtLeast } from '@oup/shared-node-browser/user.js';
import { TextLink } from '@oup/shared-front-end';
import EntityRepresentation from '../../../../../../components/EntityRepresentation/EntityRepresentation.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import { SIZES as thumbnailSizes } from '../../../../../../components/Thumbnail/Thumbnail.js';
import colors, { COLOR_FOR_ROLE } from '../../../../../../globals/colors.js';
import userRoles, { validUserRoles as VALID_USER_ROLES } from '../../../../../../globals/userRoles.js';
import content from '../../../../../../utils/cmsContent.js';
import ContentWithSVGIcon from '../../../../../../components/ContentWithSVGIcon/ContentWithSVGIcon';
import SVGIcon, { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants';

import { getDayMonthYearFormat } from '../../../../../../globals/dateFormats';
import downloadSignInCard from '../../../../../../structure/HubOrganizationLayout/Services/downloadSignInCard.js';
import styles from './enrollUser.scss';
import { featureIsEnabled } from '../../../../../../globals/envSettings.js';

const getIconHeading = (
  CMS,
  organisationName,
  invitePending,
  error,
  userExists,
  classesOnboardFailed,
  usernameGenerated,
  roleName
) => {
  if (error) {
    return {
      title: CMS.confirmation_error_title,
      subtitle: CMS.confirmation_error_subtitle,
      illustrationSrc: HubIllustrationConstants.ERROR,
      illustrationAltText: HubIllustrationAltText.ERROR
    };
  }
  if (classesOnboardFailed) {
    return {
      title: CMS.confirmation_partial_success_title_userAdded,
      subtitle: CMS.confirmation_partial_success_subtitle_userAdded.replace('{organisationName}', organisationName),
      illustrationSrc: HubIllustrationConstants.WARNING,
      illustrationAltText: HubIllustrationAltText.WARNING
    };
  }
  if (userExists) {
    return {
      title: CMS.confirmation_error_title,
      subtitle: CMS.confirmation_error_subtitle_userExists.replace('{organisationName}', organisationName),
      illustrationSrc: HubIllustrationConstants.ERROR,
      illustrationAltText: HubIllustrationAltText.ERROR
    };
  }

  if (usernameGenerated) {
    return {
      title: CMS.confirmation_success_title_studentAdded.replace('{organisationName}', organisationName),
      subtitle: CMS.confirmation_success_subtitle_generatedUserAdded_restyled.replace(
        CMS.confirmation_success_subtitle_sign_in_card,
        `<strong>${CMS.confirmation_success_subtitle_sign_in_card}</strong>`
      ),
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  }
  if (roleIsAtLeast(userRoles.TEACHER, roleName)) {
    return {
      title: (invitePending
        ? CMS.confirmation_success_title_reminderSent
        : CMS.confirmation_success_title_userAdded
      ).replace('{organisationName}', organisationName),
      subtitle: invitePending
        ? ''
        : CMS.confirmation_success_subtitle_staffAdded_restyled.replace(
            '{organisationName}',
            `<strong>${organisationName}</strong>`
          ),
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  }
  return {
    title: (invitePending
      ? CMS.confirmation_success_title_reminderSent
      : CMS.confirmation_success_title_userAdded
    ).replace('{organisationName}', organisationName),
    subtitle: invitePending
      ? ''
      : CMS.confirmation_success_subtitle_studentAdded_restyled
          .replace('{organisationName}', `<strong>${organisationName}</strong>`)
          .replace(
            CMS.confirmation_success_subtitle_sign_in_card,
            `<strong>${CMS.confirmation_success_subtitle_sign_in_card}</strong>`
          ),
    illustrationSrc: HubIllustrationConstants.SUCCESS,
    illustrationAltText: HubIllustrationAltText.SUCCESS
  };
};

const getButtonLabelByContext = context => {
  const CMS = content.enrolUserPanel;
  if (context === APP_CONSTANTS.ORG_STUDENTS) return CMS.confirmation_button_download_signin_card;
  return CMS.confirmation_button_addAnother_staff;
};

const getLine3Html = (CMS, organisationName, userTypeToEnrol) => {
  if (featureIsEnabled('opt-main-features') && userTypeToEnrol)
    return CMS.confirmation_entity_added_email_opt
      .replace('{organisationName}', organisationName)
      .replace('{date}', `<strong>${getDayMonthYearFormat(new Date())}</strong>`);

  return CMS.confirmation_entity_added_email.replace('{date}', `<strong>${getDayMonthYearFormat(new Date())}</strong>`);
};

function EnrolUserConfirmationRestyled({
  organisationName,
  name,
  email,
  usernameWithoutOrg,
  roleName,
  invitePending,
  userExists,
  failed,
  apiError,
  closePanel,
  onAddOther,
  onComplete,
  context,
  classesOnboardFailed,
  usernameGenerated,
  signInCardUrl,
  userTypeToEnrol
}) {
  const CMS = content.enrolUserPanel;

  useEffect(() => {
    if (signInCardUrl) {
      downloadSignInCard(signInCardUrl);
    }
  }, [signInCardUrl]);

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
          </div>
          <PopoutPanelIllustrationHeading
            {...getIconHeading(
              CMS,
              organisationName,
              invitePending,
              apiError,
              userExists,
              classesOnboardFailed,
              usernameGenerated || userTypeToEnrol,
              roleName
            )}
          />
          {!roleIsAtLeast(userRoles.TEACHER, roleName) && (
            <div className={styles.linkContainer}>
              <TextLink to={CMS.sign_in_card_administrators_link} target="_blank" rel="noreferrer">
                {CMS.sign_in_card_question}
              </TextLink>
            </div>
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          showActionTextAndIcon={false}
          primaryButtonAction={failed ? downloadSignInCard : onComplete}
          primaryButtonText={failed ? CMS.confirmation_button_download_signin_card : CMS.confirmation_button_done}
          secondaryButtonAction={
            context === APP_CONSTANTS.ORG_STUDENTS ? () => downloadSignInCard(signInCardUrl) : onAddOther
          }
          secondaryButtonIcon={context === APP_CONSTANTS.ORG_STUDENTS ? <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD} /> : null}
          secondaryButtonText={failed ? '' : getButtonLabelByContext(context)}
        />
      }
    >
      <div>
        {!failed ? (
          <div style={{ padding: '1rem' }}>
            <ContentWithSVGIcon>
              {usernameGenerated ? (
                <EntityRepresentation
                  userUpdateSuccess
                  id="enrolUserConfirmationEntity"
                  thumbnailSize={thumbnailSizes.MEDIUM}
                  thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                  thumbnailText={name}
                  line1Text={name}
                  line1Color={colors.BLACK_COLOR}
                  line2Text={usernameWithoutOrg}
                  line3Text={CMS.confirmation_entity_added_username.replace('{organisationName}', organisationName)}
                  specificGlyph={GLYPHS.ICON_CHECK_CIRCLE}
                  status="TEXT"
                />
              ) : (
                <EntityRepresentation
                  id="enrolUserConfirmationEntity"
                  thumbnailSize={thumbnailSizes.MEDIUM}
                  thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                  thumbnailText={name}
                  line1Text={name}
                  line1Color={colors.BLACK_COLOR}
                  line2Text={email}
                  line3Html={getLine3Html(CMS, organisationName, userTypeToEnrol)}
                  specificGlyph={GLYPHS.ICON_INVITED}
                  status="TEXT"
                />
              )}
            </ContentWithSVGIcon>
          </div>
        ) : null}
      </div>
    </ScrollContainer>
  );
}

EnrolUserConfirmationRestyled.propTypes = {
  closePanel: PropTypes.func.isRequired,
  organisationName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  roleName: PropTypes.oneOf(VALID_USER_ROLES).isRequired,
  invitePending: PropTypes.bool,
  userExists: PropTypes.bool,
  failed: PropTypes.bool.isRequired,
  apiError: PropTypes.object,
  onAddOther: PropTypes.func,
  onComplete: PropTypes.func,
  context: PropTypes.string,
  usernameWithoutOrg: PropTypes.string,
  usernameGenerated: PropTypes.bool,
  signInCardUrl: PropTypes.string,
  classesOnboardFailed: PropTypes.bool,
  userTypeToEnrol: PropTypes.string
};

export default EnrolUserConfirmationRestyled;
