import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import colors from '../../../globals/colors';
import Button, { buttonTypes } from '../../../components/Button/Button';
import Card from '../../../components/Card/Card.js';
import FileUploadInput from '../../../components/FileUploadInput/FileUploadInput';
import TextInputLabel from '../../../components/TextInputLabel/TextInputLabel';
import PageHeading from '../../../components/PageHeading/PageHeading';
import styles from './ProductUpload.scss';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { startProductUploadRequest } from '../../../redux/reducers/productUpload.reducer.js';
import { featureIsEnabled } from '../../../globals/envSettings';
import ProductUploadLogs from './ProductUploadLogs';

const supportedFileTypes = ['.xlsx'];

class ProductUpload extends Component {
  constructor(props) {
    super(props);
    const { selectedPlatformCode } = this.props;
    this.state = {
      productUploadFileIsAdded: false,
      packUploadFileIsAdded: false,
      productUpload: {},
      packUpload: {},
      selectedPlatformCode: selectedPlatformCode ?? '',
      supportsMultiplePlatforms: !selectedPlatformCode
    };
  }

  componentDidMount() {
    const { platform } = this.props;
    if (platform) {
      this.setState({
        selectedPlatformCode: platform,
        supportsMultiplePlatforms: false
      });
    }
  }

  _productUploadFileChange = value => {
    this.setState({
      productUploadFileIsAdded: value,
      productUpload: value
    });
  };

  _packUploadFileChange = value => {
    this.setState({
      packUploadFileIsAdded: value,
      packUpload: value
    });
  };

  _onDropDownChange = value => {
    this.setState({
      selectedPlatformCode: value
    });
    if (value === '') {
      this.setState({
        productUploadFileIsAdded: false,
        packUploadFileIsAdded: false,
        productUpload: {},
        packUpload: {}
      });
    }
    if (value !== 'OLB') {
      this.setState({
        packUploadFileIsAdded: false,
        packUpload: {}
      });
    }
  };

  _onSubmit = file => {
    const { startProductUploadRequestAction } = this.props;
    const { packUpload, selectedPlatformCode, productUpload } = this.state;
    const formData = new FormData();
    if (selectedPlatformCode === 'OLB') {
      formData.append('file', productUpload);
      formData.append('file', packUpload);
    } else {
      formData.append('file', file);
    }

    startProductUploadRequestAction(formData, selectedPlatformCode);
  };

  render() {
    const { content } = this.props;
    const {
      productUploadFileIsAdded,
      packUploadFileIsAdded,
      selectedPlatformCode,
      supportsMultiplePlatforms
    } = this.state;
    const isStagingDisabled =
      selectedPlatformCode === 'OLB' ? !productUploadFileIsAdded || !packUploadFileIsAdded : !productUploadFileIsAdded;
    const productCodeOptions = [
      { text: 'Please select', value: '' },
      { text: 'OLB', value: 'OLB' }
    ];

    if (featureIsEnabled('vst-product-upload')) {
      productCodeOptions.push({ text: 'VST', value: 'VST' });
    }
    if (featureIsEnabled('cba-product-upload')) {
      productCodeOptions.push({ text: 'CBA', value: 'CBA' });
    }
    if (featureIsEnabled('insp-product-upload')) {
      productCodeOptions.push({ text: 'INSP', value: 'INSP' });
    }

    productCodeOptions.push({ text: 'ELTCORE', value: 'ELTCORE' });

    return (
      <div>
        <PageHeading title={content.title} />

        <div className={styles.cardContainer}>
          {supportsMultiplePlatforms && (
            <Dropdown
              id="productCodeDropdown"
              name="productCodeDropdown"
              label="Product Code"
              value={selectedPlatformCode}
              onChange={this._onDropDownChange}
              options={productCodeOptions}
            />
          )}

          {selectedPlatformCode !== '' ? (
            <Card headingColor={colors.PROFILE} showHeadingLabel={false}>
              <TextInputLabel id="productUploadInputLabel" label={content.fileInputTextLabel} />
              <FileUploadInput
                id="productUploadInput"
                label={content.fileInputLabel}
                buttonText={content.fileInputButtonLabel}
                supportedFileTypes={supportedFileTypes}
                shouldHaveConfirmation
                selectedPlatformCode={selectedPlatformCode.toLowerCase()}
                handleFile={file => {
                  this._productUploadFileChange(file);
                  if (selectedPlatformCode !== 'OLB') {
                    this._onSubmit(file);
                  }
                }}
              />
            </Card>
          ) : null}

          {selectedPlatformCode === 'OLB' ? (
            <Card headingColor={colors.PROFILE} showHeadingLabel={false}>
              <TextInputLabel id="packUploadInputLabel" label={content.file2InputTextLabel} />
              <FileUploadInput
                id="packUploadInput"
                label={content.fileInputLabel}
                buttonText={content.fileInputButtonLabel}
                supportedFileTypes={supportedFileTypes}
                handleFile={file => this._packUploadFileChange(file)}
              />
            </Card>
          ) : null}
          {selectedPlatformCode === 'OLB' && (
            <>
              <Button
                id="uploadButton"
                type={buttonTypes.PRIMARY}
                text={content.submitButton}
                customClassName={styles.uploadButton}
                onClick={this._onSubmit}
                disabled={isStagingDisabled}
              />
              <ProductUploadLogs content={content} />
            </>
          )}
        </div>
      </div>
    );
  }
}

ProductUpload.propTypes = {
  content: PropTypes.object.isRequired,
  startProductUploadRequestAction: PropTypes.func,
  selectedPlatformCode: PropTypes.string,
  platform: PropTypes.string
};

const mapDispatchToProps = {
  startProductUploadRequestAction: startProductUploadRequest
};

export default connect(null, mapDispatchToProps)(ProductUpload);
