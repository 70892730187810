import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import withRouter from '../utils/withRouter';
import compose from '../utils/compose/compose.js';
import { OrgSubscriptionSuccess, UpdateOrgSubscriptionForm } from '../components';
import ErrorStatus, { ErrorStatusIconTypes } from '../components/ErrorStatus/ErrorStatus';
import { operationTypes } from '../components/OrgSubscriptionSuccess/OrgSubscriptionSuccess';
import PopoutPanelIconHeading, { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';

class UpdateOrgSubscriptionPage extends Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { orgId = '', subscriptionId = '' }
      }
    } = this.props;

    this.state = {
      orgId,
      subscriptionId,
      endDate: ''
    };
  }

  componentWillMount() {
    const { reset } = this.props;
    reset();
  }

  _createChangeHandler = name => value => {
    this.setState({ [name]: value });
  };

  _createBlurHandler = name => () => {
    const { validate, currentStartDate } = this.props;

    const input = pick(this.state, [name]);
    const additionalProps = {
      ...pick(this.state, ['orgId', 'subscriptionId', 'endDate']),
      startDate: currentStartDate
    };

    validate(input, additionalProps);
  };

  _updateOrgSubscription = () => {
    const { updateOrgSubscription } = this.props;
    updateOrgSubscription(pick(this.state, ['orgId', 'subscriptionId', 'endDate']));
  };

  render() {
    const {
      submitting,
      success,
      failure,
      startDate,
      currentStartDate,
      currentEndDate,
      errors,
      orgName,
      productName,
      localizedContent,
      clearFailure,
      history
    } = this.props;
    const { orgId, subscriptionId, endDate } = this.state;

    return (
      <div>
        <Helmet title={localizedContent.page_title} />
        {(() => {
          switch (true) {
            case submitting:
              return (
                <PopoutPanelIconHeading
                  type={types.LOADING}
                  title={localizedContent.updateOrgSubscription.waiting_message}
                />
              );
            case success: {
              return (
                <OrgSubscriptionSuccess
                  localizedContent={localizedContent.updateOrgSubscription}
                  operation={operationTypes.UPDATE}
                  subscriptionId={subscriptionId}
                  orgName={orgName}
                  productName={productName}
                  startDate={startDate}
                  endDate={endDate}
                  backUrl="/dashboard"
                />
              );
            }
            case failure: {
              return errors[2107] ? (
                <ErrorStatus
                  type={ErrorStatusIconTypes.ERROR}
                  title={localizedContent.updateOrgSubscription.archived_org_error_title}
                  subtitle={localizedContent.updateOrgSubscription.archived_org_error_subtitle}
                  buttonText={localizedContent.updateOrgSubscription.archived_org_error_button}
                  buttonOnClickHandler={() => history.push('/dashboard')}
                />
              ) : (
                <ErrorStatus
                  title={localizedContent.updateOrgSubscription.error_title}
                  buttonText={localizedContent.updateOrgSubscription.error_button}
                  subtitle={typeof errors === 'object' ? Object.values(errors)[0] : errors}
                  buttonOnClickHandler={clearFailure}
                />
              );
            }
            default:
              return (
                <UpdateOrgSubscriptionForm
                  localizedContent={localizedContent.updateOrgSubscription}
                  orgId={orgId}
                  orgName={orgName}
                  subscriptionId={subscriptionId}
                  currentStartDate={currentStartDate}
                  currentEndDate={currentEndDate}
                  endDate={endDate}
                  errors={errors}
                  createChangeHandler={this._createChangeHandler}
                  createBlurHandler={this._createBlurHandler}
                  onSubmit={this._updateOrgSubscription}
                />
              );
          }
        })()}
      </div>
    );
  }
}

UpdateOrgSubscriptionPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string,
      subscriptionId: PropTypes.string
    })
  }),
  localizedContent: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  orgName: PropTypes.string,
  productName: PropTypes.string,
  startDate: PropTypes.string,
  currentStartDate: PropTypes.string,
  currentEndDate: PropTypes.string,
  errors: PropTypes.object,
  updateOrgSubscription: PropTypes.func.isRequired,
  clearFailure: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('updateOrgSubscription'),
  connect(
    state =>
      pick(state.orgSubscriptionUpdate, [
        'submitting',
        'success',
        'failure',
        'errors',
        'startDate',
        'currentStartDate',
        'currentEndDate',
        'orgName',
        'productName'
      ]),
    dispatch => ({
      updateOrgSubscription: input => {
        dispatch(actions.updateOrgSubscriptionRequest(input));
      },
      clearFailure: () => {
        dispatch(actions.updateOrgSubscriptionClearFailure());
      },
      reset: () => {
        dispatch(actions.updateOrgSubscriptionReset());
      },
      validate: (input, additionalProps) => {
        dispatch(actions.validateUpdateOrgSubscriptionInput(input, additionalProps));
      }
    })
  )
)(UpdateOrgSubscriptionPage);
