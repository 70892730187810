import PropTypes from 'prop-types';
import React from 'react';

// Redux
import { connect } from 'react-redux';

// Utils
import {
  getHeaderColumnsAndValidationRules,
  placementTestCentreType
} from '@oup/shared-node-browser/bulkUsersValidation/mapping.js';
import { orgRoles } from '@oup/shared-node-browser/org.js';
import { ORG_STUDENTS, ORG_STAFF } from '@oup/shared-node-browser/constants.js';

import { Button } from '@oup/shared-front-end';
import compose from '../../../../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../../../../language/withLocalizedContent.js';
import markdown from '../../../../../../../utils/markdown/markdown';

// Components
import {
  setGenericFieldValue,
  setGenericFieldValidation
} from '../../../../../../../redux/reducers/enrolUser.reducer.js';
import SVGIcon, { GLYPHS } from '../../../../../../../components/SVGIcon/SVGIcon.js';
import styles from './EnrolUserTemplatesAndInstructions.scss';

const templatesPath = '/static/templates';
function EnrolUserTemplatesAndInstructions({ options, localizedContent: { enrolUserBulkPage: content } }) {
  const { orgRole, userTypeToEnrol, isPlacementCentre } = options;
  const { templates, computedOrgType } = getHeaderColumnsAndValidationRules(
    orgRole,
    userTypeToEnrol,
    isPlacementCentre
  );

  const downloadIcon = <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD} />;

  const instructionsMap = {
    [orgRoles.TEST_CENTER]: {
      [ORG_STUDENTS]: content.student_information_bubble_body_text_ote,
      [ORG_STAFF]: content.staff_information_bubble_body_text_ote
    },
    [orgRoles.SECONDARY_SCHOOL]: {
      [ORG_STUDENTS]: content.student_information_bubble_body_text,
      [ORG_STAFF]: content.staff_information_bubble_body_text
    },
    [placementTestCentreType]: {
      [ORG_STUDENTS]: content.student_information_bubble_body_text_opt,
      [ORG_STAFF]: content.staff_information_bubble_body_text_opt
    },
    [orgRoles.OIC_SCHOOL]: {
      [ORG_STAFF]: content.staff_information_bubble_body_text
    }
  }[computedOrgType][userTypeToEnrol];

  // Used to insert new lines into CMS, as cloud CMS will not correctly pass /n
  const preparedBodyText = (instructionsMap || '').replace(/\\n/g, '\n');

  return (
    <div className={styles.bulkUploadInformationBubble}>
      <div className={styles.bubbleArrow} />
      <div className={styles.templateDownloadBox}>
        <h3>{content.download_header_text}</h3>
        <div className={styles.buttonContainer}>
          {Object.entries(templates).map(([key, value]) => {
            const buttonLabel =
              key === 'xls' ? content.downloadXLSTemplate_button_text : content.downloadTemplate_button_text;

            return (
              <a key={key} href={`${templatesPath}/${value}`} download={value}>
                <Button text={buttonLabel} icon={{ component: downloadIcon }} />
              </a>
            );
          })}
        </div>
      </div>

      <div
        /* eslint-disable-next-line react/no-danger */ className={styles.bodyText}
        dangerouslySetInnerHTML={{ __html: markdown(preparedBodyText) }}
      />
    </div>
  );
}

EnrolUserTemplatesAndInstructions.propTypes = {
  localizedContent: PropTypes.object,
  options: PropTypes.object
};

export default compose(
  withLocalizedContent('enrolUserBulkPage'),
  connect(() => ({}), {
    updateEntry: setGenericFieldValue,
    validateEntry: setGenericFieldValidation
  })
)(EnrolUserTemplatesAndInstructions);
