import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Select } from '@oup/shared-front-end';

import useComparePrevious from '../../utils/hooks/useComparePrevious';
import styles from './EditOrgHubForm.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

function EditOrgHubForm({
  name = '',
  countryCode = '',
  primaryEmail = '',
  errors = {},
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  content,
  countries = {},
  disabled = [],
  onSetFormValidState = () => {}
}) {
  const [localErrors, setLocalErrors] = useState({ ...errors });
  const nameHasChanged = useComparePrevious(name);
  const countryCodeHasChanged = useComparePrevious(countryCode);
  const primaryEmailHasChanged = useComparePrevious(primaryEmail);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      onSetFormValidState(false);

      return;
    }

    onSetFormValidState(!Object.values(errors).some(Boolean));
    setLocalErrors({ ...errors });
  }, [errors]);

  useEffect(() => {
    switch (true) {
      case nameHasChanged:
        setLocalErrors({ ...localErrors, name: undefined });
        break;
      case countryCodeHasChanged:
        setLocalErrors({ ...localErrors, countryCode: undefined });
        break;
      case primaryEmailHasChanged:
        setLocalErrors({ ...localErrors, primaryEmail: undefined });
        break;
      default:
        break;
    }
  });

  return (
    <div className={styles.hubForm}>
      <TextInput
        id="name"
        name="name"
        label={content.name_label}
        placeholder={content.name_placeholder}
        value={name}
        state={(() => {
          if (localErrors.name) {
            return 'invalid';
          }
          if (localErrors.name === false) {
            return 'valid';
          }
          return 'default';
        })()}
        validationMessage={localErrors.name ? content.name_error : ''}
        disabled={disabled.includes('name')}
        onChange={({ target: { value } }) => {
          onSetFormValidState(false);
          createChangeHandler('name')(value);
        }}
        onBlur={createBlurHandler('name')}
      />

      <Select
        id="countryCode"
        name="countryCode"
        label={content.country_label}
        value={countryCode}
        state={(() => {
          if (localErrors.countryCode) {
            return 'invalid';
          }
          if (localErrors.countryCode === false) {
            return 'valid';
          }
          return 'default';
        })()}
        validationMessage={localErrors.countryCode ? content.country_error : ''}
        options={[
          { value: '', text: content.country_default_option },
          ...Object.entries(countries).map(([value, text]) => ({ text, value }))
        ]}
        disabled={disabled.includes('countryCode')}
        onChange={({ target: { value } }) => {
          onSetFormValidState(false);
          createChangeHandler('countryCode')(value);
        }}
        onBlur={createBlurHandler('countryCode')}
      />

      <div>
        <TextInput
          id="primaryEmail"
          name="primaryEmail"
          label={content.primary_email_label}
          placeholder={content.primary_email_placeholder}
          subLabel={content.primary_email_description}
          value={primaryEmail}
          state={(() => {
            if (localErrors.primaryEmail) {
              return 'invalid';
            }
            if (localErrors.primaryEmail === false) {
              return 'valid';
            }
            return 'default';
          })()}
          validationMessage={localErrors.primaryEmail ? content.primary_email_error : ''}
          disabled={disabled.includes('primaryEmail')}
          onChange={({ target: { value } }) => {
            onSetFormValidState(false);
            createChangeHandler('primaryEmail')(value);
          }}
          onBlur={createBlurHandler('primaryEmail')}
        />
      </div>
    </div>
  );
}

EditOrgHubForm.propTypes = {
  content: PropTypes.object,
  countries: PropTypes.object,
  name: PropTypes.string,
  countryCode: PropTypes.string,
  primaryEmail: PropTypes.string,
  disabled: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func,
  createBlurHandler: PropTypes.func,
  onSetFormValidState: PropTypes.func
};

export default withLocalizedContent('editOrg', 'curriculumTypeNames')(EditOrgHubForm);
