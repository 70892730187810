import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import compose from '../../../../utils/compose/compose.js';
import qs from 'query-string';
import withRouter from '../../../../utils/withRouter';
import { featureIsEnabled } from '../../../../globals/envSettings';
import MultiSchoolReportDialsLoader from './MultiSchoolReportDialsLoader';
import OrbReportDials from '../../../../components/OrbReportDials/OrbReportDials';
import Link from '../../../../components/Link/Link';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import ExpandableItem from '../../../../components/ExpandableItem/ExpandableItem';
import styles from '../../../../components/SchoolReport/SchoolReport.scss';
import actions from '../../../../redux/actions';
import { getOrbSchoolReportLink } from '../../../../utils/orb';
import mapObject from '../../../../utils/object/mapObject';
import { userRoles } from '../../../../../sharedNodeBrowser/user';
import { orgRoles } from '../../../../../sharedNodeBrowser/org';

function MultiSchoolReportPage(props) {
  const {
    getOrgDetails,
    loadClasses,
    submit,
    dialsData,
    totalOrgs,
    loadedOrgs,
    locationId,
    orgIds,
    sortedOrgs,
    gradebookSchoolDialReport
  } = props;
  const [expandedOrgs, setExpandedOrgs] = useState([]);

  const allReportExpanded = () => orgIds === expandedOrgs;

  const toggleAllReports = () => {
    const stateExpandedOrgs = !allReportExpanded() ? orgIds : [];

    setExpandedOrgs(stateExpandedOrgs);
  };

  const toggleReport = orgId => () => {
    const stateExpandedOrgs = expandedOrgs.includes(orgId)
      ? expandedOrgs.filter(item => item !== orgId)
      : [...expandedOrgs, orgId];

    setExpandedOrgs(stateExpandedOrgs);
  };

  useEffect(() => {
    if (!dialsData && locationId && loadedOrgs === 0) {
      submit(orgIds, locationId);
    }
  }, [locationId]);

  const getSummary = orgId => {
    if (!gradebookSchoolDialReport[orgId]) {
      gradebookSchoolDialReport[orgId] = { attainment: 0, engagement: 0, progress: 0 };
    }

    return gradebookSchoolDialReport[orgId];
  };

  const selectOrg = orgId => () => {
    getOrgDetails(orgId);
    loadClasses(orgId);
  };

  return (
    <div className="gin-top2">
      <div className={styles.schoolReportDialContainer}>
        <div className={styles.title}>Summary</div>
        {!dialsData ? (
          <div className={styles.loadingMultiSchoolLoader}>
            <MultiSchoolReportDialsLoader totalNumberOfSchools={totalOrgs} numberOfLoadedSchools={loadedOrgs} />
          </div>
        ) : (
          <OrbReportDials dialsData={dialsData} locationId={locationId} />
        )}
      </div>
      <div className={styles.toggleSwitchContainer}>
        <ToggleSwitch
          id="schoolReportShowAllClass"
          label="Show all school reports"
          value={allReportExpanded()}
          onChange={() => toggleAllReports()}
        />
      </div>
      {sortedOrgs?.map(({ id, name }) => (
        <ExpandableItem
          key={id}
          expanded={expandedOrgs.includes(id)}
          onToggleExpand={toggleReport(id)}
          label={`${name} report`}
        >
          <OrbReportDials dialsData={getSummary(id)} locationId={locationId} hideDescription enableTextDial />
          {gradebookSchoolDialReport[id] && (
            <Link className={styles.viewMoreLink} to={getOrbSchoolReportLink(id)} onClick={selectOrg(id)}>
              View more
            </Link>
          )}
        </ExpandableItem>
      ))}
    </div>
  );
}

MultiSchoolReportPage.propTypes = {
  loadClasses: PropTypes.func,
  getOrgDetails: PropTypes.func,
  submit: PropTypes.func,
  dialsData: PropTypes.shape({
    attainment: PropTypes.number,
    engagement: PropTypes.number,
    progress: PropTypes.number
  }),
  orgIds: PropTypes.array,
  totalOrgs: PropTypes.number.isRequired,
  loadedOrgs: PropTypes.number.isRequired,
  locationId: PropTypes.string.isRequired,
  sortedOrgs: PropTypes.array,
  gradebookSchoolDialReport: PropTypes.object
};

export default compose(
  withRouter,
  connect(
    (state, { match: { params }, location }) => {
      const matReportSummary = state.matReportSummary;

      const dialsData = matReportSummary.summary;
      const orgsLoaded = !matReportSummary.isLoading && matReportSummary.isSuccess;

      const stateOrgs = state.organisations.data;
      const orgIds = Object.keys(stateOrgs).filter(
        orgId => stateOrgs[orgId].roleName === userRoles.ORG_ADMIN && stateOrgs[orgId].role === orgRoles.PRIMARY_SCHOOL
      );
      const organisations = mapObject(stateOrgs, (key, value) => ({ ...value, id: key })).filter(
        organisation => organisation.roleName === userRoles.ORG_ADMIN && organisation.role === orgRoles.PRIMARY_SCHOOL
      );
      const sortedOrgs = organisations.sort((a, b) => a.name.localeCompare(b.name));

      const loadedOrgs = matReportSummary.loadedOrgs + matReportSummary.failedOrgs;

      // Get LocationId
      const orgId = params.orgId || state.identity.currentOrganisationId;
      const readingBuddyResults = featureIsEnabled('mat-report-change-for-reducers')
        ? state.resultsReadingBuddyReducer.data[orgId]
        : state.resultsReadingBuddy;
      const orgLocationId = readingBuddyResults?.orgDetails?.locationId;
      const { locationId } = qs.parse(location.search);

      const gradebookSchoolDialReport = state.gradebookSchoolDialReport.data;

      return {
        dialsData: orgsLoaded ? dialsData : null,
        orgIds,
        totalOrgs: orgIds.length,
        loadedOrgs,
        locationId: locationId || orgLocationId,
        sortedOrgs,
        gradebookSchoolDialReport
      };
    },
    dispatch => ({
      getOrgDetails: orgId => {
        if (featureIsEnabled('mat-report-change-for-reducers')) {
          dispatch(actions.gradebookClassReportEnvironmentRequestV2(orgId));
        } else {
          dispatch(actions.gradebookClassReportEnvironmentRequest(orgId));
        }
      },
      loadClasses: orgId => {
        dispatch(actions.getClassroomsRequest(orgId));
      },
      submit: (orgIds, locationId) => {
        dispatch(actions.multiSchoolDialReportRequest(orgIds, locationId));
      }
    })
  )
)(MultiSchoolReportPage);
