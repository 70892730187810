import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';

import withRouter from '../../utils/withRouter';
import Button, { buttonTypes } from '../Button/Button';
import withLocalizedContent from '../../language/withLocalizedContent';
import LoadingSpinner, { spinnerSizes } from '../LoadingSpinner/LoadingSpinner';
import styles from './DownloadSigninCardProcess.scss';
import {
  fillBatchId,
  downloadSigninCardProcessPollingTrigger,
  downloadSigninCard,
  downloadErrorDetails
} from '../../redux/reducers/downloadSigninCardProcess.reducer';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon.js';

class DownloadSigninCardProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { setBatchId, batchId, totalRecords, actionPollingTrigger } = this.props;
    setBatchId(batchId, totalRecords);
    actionPollingTrigger();
  }

  _renderComponent = () => {
    const {
      success,
      error,
      polling,
      localizedContent,
      totalRecords,
      actionDownloadCard,
      errorLearners,
      completedRecords,
      actionDownloadErrorDetails
    } = this.props;
    const text = localizedContent.downloadSigninCardProcess.message_text_1
      .replace('{completedLearner}', completedRecords)
      .replace('{totalLearner}', totalRecords);
    switch (true) {
      case success:
        return (
          <div className={styles.mainContainer}>
            <PopoutPanelIconHeading
              title={localizedContent.downloadSigninCardProcess.download_success_text}
              type={types.VALID}
            />
            <h1>
              {text}
              <br />
              {errorLearners.length > 0 &&
                localizedContent.downloadSigninCardProcess.message_text_error
                  .replace('{totalLearner}', totalRecords)
                  .replace('{errorLearners}', errorLearners.length)}
            </h1>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: localizedContent.downloadSigninCardProcess.message_text_3
              }}
            />

            <p>
              <Button
                type={buttonTypes.PRIMARY}
                text={localizedContent.downloadSigninCardProcess.button_text}
                onClick={actionDownloadCard}
              />
            </p>
            <p>
              {errorLearners.length > 0 && (
                <Button
                  type={buttonTypes.PRIMARY}
                  text={localizedContent.downloadSigninCardProcess.button_error_text}
                  onClick={actionDownloadErrorDetails}
                />
              )}
            </p>
          </div>
        );
      case error:
        return (
          <div className={styles.errorContainer}>
            <div className={styles.icon}>
              <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
            </div>
            <p className={styles.errorText}>{localizedContent.downloadSigninCardProcess.error_text}</p>
            <p className={styles.errorSubText}>{localizedContent.downloadSigninCardProcess.error_sub_text}</p>
            <Button
              onClick={() => window.location.reload()}
              type={buttonTypes.PRIMARY}
              text={localizedContent.downloadSigninCardProcess.button_error}
            />
            <p className={styles.suggestionText}>
              {localizedContent.downloadSigninCardProcess.suggestion_text}
              <a
                href={localizedContent.downloadSigninCardProcess.customer_support_team_link}
                target="_blank"
                rel="noreferrer"
              >
                {localizedContent.downloadSigninCardProcess.customer_support_team_text}
              </a>
            </p>
          </div>
        );
      case polling:
      default:
        return (
          <div className={styles.mainContainer}>
            <LoadingSpinner
              text={localizedContent.downloadSigninCardProcess.downloading_text}
              size={spinnerSizes.LARGER}
            />
            <p>
              <h1>{text}</h1>
            </p>
            <p>{localizedContent.downloadSigninCardProcess.message_text_2}</p>
          </div>
        );
    }
  };

  render() {
    const { localizedContent } = this.props;
    return (
      <div>
        <Helmet titleTemplate={localizedContent.downloadSigninCardProcess.page_title} />
        {this._renderComponent()}
      </div>
    );
  }
}

DownloadSigninCardProcess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  totalRecords: PropTypes.string.isRequired,
  completedRecords: PropTypes.number.isRequired,
  batchId: PropTypes.string.isRequired,
  setBatchId: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  polling: PropTypes.bool.isRequired,
  actionPollingTrigger: PropTypes.func.isRequired,
  actionDownloadCard: PropTypes.func.isRequired,
  errorLearners: PropTypes.array,
  actionDownloadErrorDetails: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('downloadSigninCardProcess'),
  withRouter,
  connect(
    (state, props) => ({
      completedRecords: state.downloadSigninCardProcess.completedRecords,
      errorLearners: [
        ...state.downloadSigninCardProcess.errorLearners,
        ...state.downloadSigninCardProcess.archivedLearners
      ],
      totalRecords: props.match.params.records,
      batchId: props.match.params.batchId,
      success: state.downloadSigninCardProcess.success,
      error: state.downloadSigninCardProcess.error,
      polling: state.downloadSigninCardProcess.polling
    }),
    dispatch => ({
      setBatchId: (batchId, totalRecords) => {
        dispatch(fillBatchId(batchId, totalRecords));
      },
      actionPollingTrigger: () => {
        dispatch(downloadSigninCardProcessPollingTrigger());
      },
      actionDownloadCard: () => {
        dispatch(downloadSigninCard());
      },
      actionDownloadErrorDetails: () => {
        dispatch(downloadErrorDetails());
      }
    })
  )
)(DownloadSigninCardProcess);
