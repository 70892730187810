/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import classnames from 'classnames';
import { RESOURCE_TYPE } from '@oup/shared-node-browser/constants';
import DOMPurify from 'dompurify';
import compose from '../../utils/compose/compose.js';
import withBreakpoint from '../../decorators/withBreakpoint';
import Card from '../Card/Card';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import PendingMarkIcon from '../PendingMarkIcon/PendingMarkIcon';
import styles from './GradebookCard.scss';
import tooltipStyles from '../ClassProgress/StudentRow.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import { featureIsEnabled } from '../../globals/envSettings';
import breakpoints from '../../globals/breakpoints';
import Button, { buttonTypes } from '../Button/Button';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import { getFormattedAttemptsLevels } from '../ClassProgress/StudentRow';
import { RETURN_PAGE_TARGET } from '../../globals/appConstants';
import { PLATFORMS } from '../../globals/hubConstants';

const formatFraction = (numerator, denominator, usePercentages) => {
  if (usePercentages) {
    return numeral(numerator / denominator).format('0.[0]%');
  }
  return `${Math.round(numerator)}/${Math.round(denominator)}`;
};

const DAYS_SINCE_THRESHOLD = 14;

const formatDate = date => {
  const dateMoment = moment(date);
  if (dateMoment.isBefore(moment().subtract(DAYS_SINCE_THRESHOLD, 'days'))) {
    return dateMoment.format('Do MMMM YYYY');
  }

  return dateMoment.isValid() ? dateMoment.fromNow() : 'Never';
};

const isHighlightScoreFnc = (rangeValue, useFilterByScore, totalScoreAchieved, totalScoreAvailable, userAttempts) =>
  useFilterByScore && rangeValue && userAttempts !== 0
    ? parseFloat(formatFraction(totalScoreAchieved, totalScoreAvailable, true)) <= parseInt(rangeValue, 10)
    : false;

export const getUidDetails = (hash, uId) => {
  if (!hash || !uId) return {};
  const levelDetails = uId.split('-');
  const activityId = levelDetails[1];
  const bid = levelDetails[0].split(':').slice(1);
  return { bid, activityId };
};

class GradebookCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTooltipOpen: false,
      key: null
    };
  }

  render() {
    const {
      recordWithTotals: {
        studentName,
        studentLink,
        lastAccessed,
        totalLevelsCompleted,
        totalNumberOfActivities: overallTotalNumberOfActivities,
        totalLevelsScore,
        totalLevelsScoreAvailable,
        sumTotalNumberOfAttempts,
        levels,
        id
      },
      classTitle,
      usePercentages = false,
      isActivityLevel = false,
      rangeValue,
      useFilterByScore,
      localizedContent: CONTENT,
      shouldDisableAnswerView = false,
      params,
      products,
      tooltipText,
      pendingMark,
      breakpoint,
      role,
      productType,
      useCompletedOnly,
      handleClickEltcoreReview
    } = this.props;

    const areAllLevelsLocked = levels?.every(level => level.locked === true);
    const { totalAttemptsFormatted, totalLevelsFormatted } = getFormattedAttemptsLevels(
      {
        isActivityLevel,
        levels,
        totalLevelsCompleted,
        totalLevelsScore,
        totalLevelsScoreAvailable,
        sumTotalNumberOfAttempts,
        totalNumberOfActivities: overallTotalNumberOfActivities
      },
      usePercentages
    );

    const isAssessmentProduct = productType === RESOURCE_TYPE.ASSESSMENT.toLowerCase();

    const { isTooltipOpen, key } = this.state;

    return (
      <div className={styles.GradebookCard}>
        <Card headingText={classTitle} showHeadingLabel={false}>
          <div className={styles.GradebookCard__title}>
            <LinkWithIcon text={studentName} glyph={GLYPHS.ICON_RIGHT} to={studentLink} />
          </div>

          <div className={styles.GradebookCard__lastAccessed}>
            <span className={styles.GradebookCard__lastAccessed__label}>{`${CONTENT.last_accessed}: `}</span>
            <span className={styles.GradebookCard__lastAccessed__date}>{formatDate(lastAccessed)}</span>
          </div>

          {areAllLevelsLocked && (
            <div className={styles.lockedMessage}>
              <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
              <span>{CONTENT.locked_node_tooltip_text}</span>
            </div>
          )}

          <div className={styles.GradebookCard__average}>
            <div className={styles.GradebookCard__average__column}>
              <div className={styles.GradebookCard__average__total}>{totalAttemptsFormatted}</div>
              {isActivityLevel && (
                <div
                  className={classnames(
                    styles.GradebookCard__average__totalLabel,
                    styles.GradebookCard__average__totalIsActivityLabel
                  )}
                >
                  {CONTENT.completed}
                </div>
              )}
              <div className={styles.GradebookCard__average__totalLabel}>
                {isActivityLevel ? CONTENT.attempts : CONTENT.completed}
              </div>
            </div>
            <div className={styles.scoreMainContainer}>
              {featureIsEnabled('hub-gradebook-teacher-marked-icons') &&
              pendingMark &&
              roleIsAtLeast(userRoles.TEACHER, role) ? (
                <PendingMarkIcon customClassName={styles.pendingIconContainer} />
              ) : null}
              <div className={styles.GradebookCard__average__column}>
                <div className={styles.GradebookCard__average__total}>{totalLevelsFormatted}</div>
                {isActivityLevel && (
                  <div
                    className={classnames(
                      styles.GradebookCard__average__totalLabel,
                      styles.GradebookCard__average__totalIsActivityLabel
                    )}
                  >
                    {CONTENT.score}
                  </div>
                )}
                <div className={styles.GradebookCard__average__totalLabel}>{CONTENT.score}</div>
              </div>
            </div>
          </div>

          <table className={styles.GradebookCard__table}>
            <thead>
              <tr className={styles.GradebookCard__table__header}>
                <th />
                <th>{CONTENT.completed}</th>
                <th>{CONTENT.score}</th>
              </tr>
            </thead>
            <tbody>
              {levels.map(
                (
                  {
                    levelName, // Unit level
                    totalNumberOfActivities,
                    submittedNumberOfActivities,
                    levelScoreAchieved,
                    levelScoreAvailable,
                    allActivityLevelScoreAvailable,
                    activityName, // Activity level
                    totalNumberOfAttempts,
                    totalScoreAchieved,
                    totalScoreAvailable,
                    allActivityTotalScoreAvailable,
                    uId,
                    attemptId,
                    pendingMark: exercisePendingMark,
                    locked,
                    activityId: activityHashedId,
                    eltAuthorId
                  },
                  $i
                ) => {
                  const name = levelName || activityName;
                  const userAttempts = submittedNumberOfActivities || totalNumberOfAttempts || 0;
                  const isHighlightScore = isHighlightScoreFnc(
                    rangeValue,
                    useFilterByScore,
                    isActivityLevel ? totalScoreAchieved : levelScoreAchieved,
                    isActivityLevel ? totalScoreAvailable : levelScoreAvailable,
                    userAttempts,
                    isActivityLevel
                  );
                  let studentAnswerViewLink = '';
                  if (isActivityLevel) {
                    const { bid, activityId } = getUidDetails(window.location.hash, uId);
                    studentAnswerViewLink = `orgId/${params.orgId}/class/${params.classroomId}/${params.itemId}/${id}/${bid}/${uId}/${activityId}?page=${RETURN_PAGE_TARGET.CLASS_PROGRESS}`;
                  }

                  const denominatorIsActivity = useCompletedOnly ? totalScoreAvailable : allActivityTotalScoreAvailable;
                  const denominator = useCompletedOnly ? levelScoreAvailable : allActivityLevelScoreAvailable;

                  const renderReviewButton = () => {
                    const { orgId, itemId } = params;
                    const gradebookProduct = products?.[itemId];

                    const isEltcoreProduct = gradebookProduct?.platform === PLATFORMS.ELTCORE;
                    if (isEltcoreProduct) {
                      const contextCode = gradebookProduct?.contentCode;

                      const reviewParams = {
                        activityId: activityHashedId,
                        attemptId,
                        contextCode,
                        eltAuthorId,
                        targetUserId: id,
                        targetUserOrgId: orgId
                      };

                      return (
                        <Button
                          disabled={!attemptId && featureIsEnabled('gradebook-first-and-last-answer')}
                          iconOnly
                          onClick={() => handleClickEltcoreReview(reviewParams)}
                          type={buttonTypes.ROUNDED_SMALL}
                        />
                      );
                    }

                    return (
                      <Button
                        disabled={!attemptId && featureIsEnabled('gradebook-first-and-last-answer')}
                        iconOnly
                        to={`/studentAnswerView/${studentAnswerViewLink}&attemptId=${attemptId}`}
                        type={buttonTypes.ROUNDED_SMALL}
                      />
                    );
                  };

                  return (
                    <tr key={`${name}-${$i}`} className={locked ? styles.locked : ''}>
                      <th>
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }} />
                      </th>
                      <td>
                        {isActivityLevel
                          ? totalNumberOfAttempts
                          : formatFraction(submittedNumberOfActivities, totalNumberOfActivities, usePercentages)}
                      </td>
                      <td>
                        {featureIsEnabled('hub-gradebook-teacher-marked-icons') &&
                        exercisePendingMark &&
                        roleIsAtLeast(userRoles.TEACHER, role) &&
                        breakpoint === breakpoints.XXS ? (
                          <PendingMarkIcon customClassName={styles.pendingIconExerciseContainer} />
                        ) : (
                          <span
                            className={classnames(styles.GradebookCard__table__scoreCell, {
                              [styles.GradebookCard__table__filterHighlight]: isHighlightScore
                            })}
                          >
                            {userAttempts === 0
                              ? '-'
                              : formatFraction(
                                  isActivityLevel ? totalScoreAchieved : levelScoreAchieved,
                                  isActivityLevel ? denominatorIsActivity : denominator,
                                  usePercentages
                                )}
                            {featureIsEnabled('olb-gradebook-student-answer-view') &&
                              !isAssessmentProduct &&
                              studentAnswerViewLink &&
                              userAttempts !== 0 && (
                                <div>
                                  {shouldDisableAnswerView ? (
                                    <div
                                      role="button"
                                      aria-hidden="true"
                                      className={tooltipStyles.tooltip}
                                      onClick={() => {
                                        this.setState({
                                          isTooltipOpen: true,
                                          key: `score-${levelName || activityName}`
                                        });
                                      }}
                                      onBlur={() => this.setState({ isTooltipOpen: false, key: null })}
                                      onMouseOut={() => {
                                        this.setState({ isTooltipOpen: false, key: null });
                                      }}
                                    >
                                      <Button iconOnly disabled type={buttonTypes.ROUNDED_SMALL} />
                                      {isTooltipOpen && key === `score-${levelName || activityName}` && (
                                        <div className={tooltipStyles.tooltiptext}>
                                          <span>{tooltipText}</span>
                                          <i />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    renderReviewButton()
                                  )}
                                </div>
                              )}
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Card>
      </div>
    );
  }
}

GradebookCard.propTypes = {
  recordWithTotals: PropTypes.object.isRequired,
  usePercentages: PropTypes.bool,
  breakpoint: PropTypes.string,
  isActivityLevel: PropTypes.bool,
  classTitle: PropTypes.string.isRequired,
  rangeValue: PropTypes.string,
  useFilterByScore: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  params: PropTypes.object,
  products: PropTypes.object,
  shouldDisableAnswerView: PropTypes.bool,
  tooltipText: PropTypes.string,
  pendingMark: PropTypes.bool,
  role: PropTypes.string,
  productType: PropTypes.string,
  useCompletedOnly: PropTypes.bool,
  handleClickEltcoreReview: PropTypes.func
};
export default compose(withBreakpoint)(GradebookCard);
