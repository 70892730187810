import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
// Components
import compose from '../../utils/compose/compose.js';
import Link from '../Link/Link';
import { HubAssessmentFallbackImgSmallCircle as FallbackImage } from '../HubImage';

import HubLicenceStatusItem from '../../structure/HubOrganizationLayout/OrganizationLicences/HubLicenceStatusItem';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
// Redux
import { openRedeemModal } from '../../redux/actions/hubUi';
// Services
import getUserLicencesStatusData from '../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
import getAssessmentHomeLink from '../../structure/HubCourseAssessments/Services/getAssessmentHomeLink';
// Constants
import { licenceStatus as LICENCE_STATUS, HubLayoutConstants } from '../../globals/hubConstants.js';
// Styles
import styles from './AssessmentProductListItem.scss';
import withBreakpoint from '../../decorators/withBreakpoint';

const ASSESSMENT = 'assessments';

const renderFallbackImage = () => <FallbackImage />;

function AssessmentProductListItem({
  assessment = {},
  title = '',
  courseId = '',
  hubContent,
  openRedeemModalAction,
  breakpoint
}) {
  const statusDetails = getUserLicencesStatusData(
    { hasLicence: assessment.hasLicence, expiryDate: assessment.expiryDate },
    hubContent
  );

  const { status: licenceStatus } = statusDetails;

  const { EXPIRED, NO_LICENCE } = LICENCE_STATUS;
  const isLicenseExpired = licenceStatus === EXPIRED || licenceStatus === NO_LICENCE;

  const handleClick = () => {
    if (isLicenseExpired) {
      openRedeemModalAction();
    }
    return true;
  };

  return (
    <div className={styles.assessmentContainer}>
      <Link
        to={isLicenseExpired ? null : getAssessmentHomeLink(courseId, ASSESSMENT, hubContent.assessment)}
        onClick={handleClick}
        className={classnames(styles.assessment, { [styles[licenceStatus]]: licenceStatus })}
      >
        <div className={styles.leftContainer}>
          <div className={styles.imgContainer}>
            {isLicenseExpired && (
              <div className={styles.lockIconContainer}>
                <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
              </div>
            )}
            {renderFallbackImage(breakpoint)}
          </div>

          <div className={styles.textContainer}>
            <div>
              <span>{`${title}`}</span>
            </div>
          </div>
        </div>

        <div className={styles.statusLicenceContainer}>
          <HubLicenceStatusItem
            licencesContext={HubLayoutConstants.LICENCES_CONTEXT.MY_COURSES}
            statusDetails={statusDetails}
            hubContent={hubContent}
            styles={styles}
            breakpoint={breakpoint}
          />
        </div>
        <div className={styles.emptyContainer} />
      </Link>
    </div>
  );
}

const mapDispatchToProps = {
  openRedeemModalAction: openRedeemModal
};

export default compose(connect(null, mapDispatchToProps), withBreakpoint)(AssessmentProductListItem);

AssessmentProductListItem.propTypes = {
  assessment: PropTypes.object,
  title: PropTypes.string,
  courseId: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  openRedeemModalAction: PropTypes.func,
  breakpoint: PropTypes.string.isRequired
};
