import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import markdown from '../../utils/markdown/markdown';
import styles from './BulkUploadInformationBubble.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import downloadAsFile from '../../utils/downloadAsFile';

function BulkUploadInformationBubble({
  headerTitleText,
  downloadTemplateText,
  downloadXLSTemplateText,
  downloadUserXLSTemplatePath,
  downloadUserXLSTemplateName,
  downloadFileName,
  filecontent,
  bodyText
}) {
  const downloadIcon = <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD} />;
  // Used to insert new lines into CMS, as cloud CMS will not correctly pass /n
  const preparedBodyText = (bodyText || '').replace(/\\n/g, '\n');

  return (
    <div className={styles.bulkUploadInformationBubble}>
      <div className={styles.bubbleArrow} />
      {headerTitleText ? (
        <div className={styles.templateDownloadBox}>
          <h3>{headerTitleText}</h3>
          {downloadTemplateText ? (
            <div className={styles.buttonContainer}>
              <div>
                <Button
                  text={downloadTemplateText}
                  icon={{ component: downloadIcon, placement: 'left' }}
                  onClick={() => {
                    downloadAsFile({
                      content: decodeURIComponent(filecontent.replace(/\n/g, '%0D%0A')),
                      fileName: downloadFileName,
                      type: 'text/csv'
                    });
                  }}
                />
              </div>
              {downloadUserXLSTemplatePath ? (
                <div>
                  <a href={downloadUserXLSTemplatePath} download={downloadUserXLSTemplateName}>
                    <Button text={downloadXLSTemplateText} icon={{ component: downloadIcon }} />
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.bodyText} dangerouslySetInnerHTML={{ __html: markdown(preparedBodyText) }} />
    </div>
  );
}

BulkUploadInformationBubble.propTypes = {
  headerTitleText: PropTypes.string,
  downloadTemplateText: PropTypes.string,
  downloadXLSTemplateText: PropTypes.string,
  downloadUserXLSTemplatePath: PropTypes.string,
  downloadUserXLSTemplateName: PropTypes.string,
  downloadFileName: PropTypes.string,
  bodyText: PropTypes.string.isRequired,
  filecontent: PropTypes.string
};

export default BulkUploadInformationBubble;
