import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextLink } from '@oup/shared-front-end';
import { ICON_LEFT } from '@oup/shared-front-end/svg/oup';
import styles from './PopoutNavFooter.scss';

import LegacyButton, { buttonTypes } from '../Button/Button';

function PopoutNavFooter({
  backAction,
  nextAction,
  nextButtonText,
  nextButtonFullWidth,
  backButtonText,
  backType = 'link',
  nextButtonDisabled,
  primaryText
}) {
  const _renderBackButton = () => {
    if (backType === 'link') {
      return (
        <TextLink id="PopoutNavFooterBackLink" onClick={backAction} role="button" tabIndex={0}>
          {backButtonText}
        </TextLink>
      );
    }
    return (
      <Button
        dataAttributes={{ testid: 'POPOUT_NAV_FOOTER_BACK' }}
        text={backButtonText}
        onClick={backAction}
        variant="outline"
        ariaAttributes={{ label: 'Previous page' }}
      />
    );
  };
  return (
    <div className={styles.PopoutNavFooter}>
      {primaryText}
      {backAction && backButtonText ? _renderBackButton() : null}
      {backAction && !backButtonText ? (
        <TextLink
          isLhs
          id="PopoutNavFooterBackLink"
          onClick={backAction}
          role="button"
          tabIndex={0}
          aria-label="Previous page"
          leftIcon={<ICON_LEFT />}
        >
          Back
        </TextLink>
      ) : null}
      {nextButtonFullWidth && nextAction && (
        <LegacyButton
          dataTestId="POPOUT_NAV_FOOTER_NEXT"
          id="PopoutNavFooterNextButton"
          type={buttonTypes.PRIMARY}
          text={nextButtonText}
          onClick={nextAction}
          disabled={nextButtonDisabled}
          aria-label="Next page"
          fullWidth={nextButtonFullWidth}
        />
      )}
      {!nextButtonFullWidth && nextAction && (
        <Button
          dataAttributes={{ testid: 'POPOUT_NAV_FOOTER_NEXT' }}
          id="PopoutNavFooterNextButtonRestyle"
          text={nextButtonText}
          onClick={nextAction}
          disabled={nextButtonDisabled}
          ariaAttributes={{ label: 'Next page' }}
        />
      )}
    </div>
  );
}

PopoutNavFooter.propTypes = {
  backAction: PropTypes.func,
  nextAction: PropTypes.func,
  nextButtonText: PropTypes.string,
  nextButtonDisabled: PropTypes.bool,
  nextButtonFullWidth: PropTypes.bool,
  backButtonText: PropTypes.string,
  backType: PropTypes.oneOf(['link', 'button']),
  primaryText: PropTypes.any
};

export default PopoutNavFooter;
