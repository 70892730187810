import classnames from 'classnames';
import { pick, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { sanitizeUrl } from '../../utils/url';
import Button, { buttonTypes } from '../Button/Button';
import EntityListing from '../EntityListing/EntityListing';
import withExpandableItemState from '../EntityListing/withExpandableItemState';
import ProductDetails from '../ProductDetails/ProductDetails';
import RocketLolly from '../RocketLolly/RocketLolly';
import StatusIcon, { StatusType } from '../StatusIcon/StatusIcon';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './AssignmentListing.scss';

export const AssignmentStatus = {
  PROCESSING: Symbol('PROCESSING'),
  NONE_REQUIRED: Symbol('NONE_REQUIRED'),
  SOME_REQUIRED: Symbol('SOME_REQUIRED'),
  ALL_REQUIRED: Symbol('ALL_REQUIRED')
};

const STATUS_ICON_TYPES = {
  [AssignmentStatus.PROCESSING]: StatusType.PROCESSING,
  [AssignmentStatus.NONE_REQUIRED]: StatusType.ACTIVE,
  [AssignmentStatus.SOME_REQUIRED]: StatusType.SOME_REQUIRED,
  [AssignmentStatus.ALL_REQUIRED]: StatusType.ALL_REQUIRED
};

class AssignmentListing extends Component {
  get _content() {
    const { localizedContent } = this.props;
    return localizedContent.assignmentListingComponent;
  }

  _getStatus = ({ assignmentId, userWithoutLicence }) => {
    const { processingItems = [], entitlement = 0 } = this.props;
    const classStudentsWithoutLicenceCount = userWithoutLicence.length;
    switch (true) {
      case processingItems.includes(assignmentId):
        return AssignmentStatus.PROCESSING;
      case entitlement && !classStudentsWithoutLicenceCount:
        return AssignmentStatus.NONE_REQUIRED;
      case classStudentsWithoutLicenceCount && classStudentsWithoutLicenceCount < entitlement:
        return AssignmentStatus.SOME_REQUIRED;
      case classStudentsWithoutLicenceCount === entitlement:
        return AssignmentStatus.ALL_REQUIRED;
      default:
        return undefined;
    }
  };

  _getStatusCaption = status => {
    switch (status) {
      case AssignmentStatus.NONE_REQUIRED:
        return this._content.status_caption_none_required;
      case AssignmentStatus.SOME_REQUIRED:
        return this._content.status_caption_some_required;
      case AssignmentStatus.ALL_REQUIRED:
        return this._content.status_caption_all_required;
      default:
        return null;
    }
  };

  _renderRepresentation = (status, { title, coverImage = 'N/A', productLaunchUrl }) => {
    const { enableExternalLinks = false } = this.props;

    return (
      <div className={styles.licence}>
        <div className={styles.image}>
          {!/^N\/A$/i.test(coverImage) ? <img src={sanitizeUrl(coverImage)} width="100%" alt={title} /> : null}
        </div>
        <div className={styles.title}>
          {productLaunchUrl && enableExternalLinks ? (
            <a href={sanitizeUrl(productLaunchUrl)} target="_top">
              {title} <SVGIcon className={styles.titleIcon} glyph={GLYPHS.ICON_EXTERNAL} />
            </a>
          ) : (
            title
          )}
        </div>
      </div>
    );
  };

  _renderAction = (id, product) => {
    const { userWithoutLicence, licenceDetails } = product;
    const userWithoutLicenceCount = userWithoutLicence.length;
    const { onAssignLicenceClick, currentUserCanEditClass } = this.props;
    const currentUserCannotAssignLicence = currentUserCanEditClass
      ? licenceDetails.available < userWithoutLicenceCount
      : !currentUserCanEditClass;

    return (
      <Button
        text={this._content.button_assign_licences}
        type={buttonTypes.GHOST_INVERTED}
        glyph={GLYPHS.ICON_PLUS}
        disabled={currentUserCannotAssignLicence}
        onClick={() => onAssignLicenceClick(id, product)}
      />
    );
  };

  _renderItemContent = id => {
    const { items } = this.props;
    const {
      productDetails: { series, author, isbn, cefrLevel, ...productDetails },
      licenceDetails
    } = items[id];

    return (
      <ProductDetails
        {...pick(productDetails, ['title', 'description'])}
        subtitle={cefrLevel}
        listData={[
          {
            label: this._content.details_series_title_text,
            text: series
          },
          {
            label: this._content.details_ISBN_title_text,
            text: isbn
          },
          {
            label: this._content.details_author_title_text,
            text: author
          }
        ]}
      >
        <RocketLolly
          items={[
            {
              text: this._content.licence_usage_started_text,
              value: licenceDetails.redeemed
            },
            {
              text: this._content.licence_usage_not_started_text,
              value: licenceDetails.assigned
            },
            {
              text: this._content.licence_usage_avalible_text,
              value: licenceDetails.available
            }
          ]}
        />
      </ProductDetails>
    );
  };

  _canLicenceAssignmentBeDone = () => {
    const { items } = this.props;

    return Object.values(items).some(item => item.userWithoutLicence.length > 0);
  };

  render() {
    const { items, processingItems = [], heading, onRemoveClick, currentUserCanEditClass, ...props } = this.props;
    return (
      <EntityListing
        id="class-assignments-listing"
        {...pick(props, [
          'selectedItems',
          'expandedItems',
          'enableExternalLinks',
          'selectable',
          'onItemSelect',
          'onItemExpandToggle',
          'selectAllEnabled',
          'localizedContent'
        ])}
        items={mapValues(items, (product, id) => {
          const processing = processingItems.includes(product.assignmentId);
          const status = this._getStatus(product);
          const { productDetails: details, userWithoutLicence } = product;
          const userWithoutLicenceCount = userWithoutLicence.length;

          return {
            [heading]: (
              <div className={classnames({ [styles.processing]: processing })}>
                {this._renderRepresentation(status, details)}
              </div>
            ),
            ...(currentUserCanEditClass && this._canLicenceAssignmentBeDone()
              ? {
                  [this._content.heading_actions]: (
                    <div className={classnames({ [styles.processing]: processing })}>
                      {!!userWithoutLicenceCount && this._renderAction(id, product)}
                    </div>
                  )
                }
              : {}),
            [this._content.heading_status]: STATUS_ICON_TYPES[status] ? (
              <StatusIcon type={STATUS_ICON_TYPES[status]} title={this._getStatusCaption(status)} />
            ) : null,
            ...(onRemoveClick
              ? {
                  [this._content.heading_archive]: (
                    <div
                      className={classnames({
                        [styles.processing]: processing
                      })}
                    >
                      {(product.archiveStatus ? (
                        product.archiveStatus === 'ACTIVE'
                      ) : (
                        true
                      )) ? (
                        <Button
                          type={buttonTypes.NO_BORDER}
                          glyph={GLYPHS.ICON_REMOVE}
                          iconOnly
                          onClick={() => onRemoveClick([id])}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )
                }
              : {})
          };
        })}
        renderItemContent={this._renderItemContent}
        expandable
      />
    );
  }
}

AssignmentListing.propTypes = {
  items: PropTypes.object.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  processingItems: PropTypes.arrayOf(PropTypes.string),
  heading: PropTypes.string.isRequired,
  entitlement: PropTypes.number,
  enableExternalLinks: PropTypes.bool,
  selectable: PropTypes.bool,
  onItemSelect: PropTypes.func,
  selectAllEnabled: PropTypes.bool,
  onAssignLicenceClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  currentUserCanEditClass: PropTypes.bool
};

export default compose(
  withLocalizedContent('assignmentListingComponent', 'productListing'),
  withExpandableItemState
)(AssignmentListing);
