import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import compose from '../../../../utils/compose/compose.js';

import withLocalizedContent from '../../../../language/withLocalizedContent';

import styles from './ContentPreviewPage.scss';

import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../../components/Button/Button';

function ContentPreviewPage({ localizedContent: { contentPreviewPage: content } }) {
  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      <h1>{content.title}</h1>
      <span className={styles.subheading}>{content.subtitle}</span>
      <div className={styles.linkContainer}>
        <Button
          to="/oup/content/resources/lor"
          type={buttonTypes.SECONDARY}
          text={content.lor_resources}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/oup/content/resources/eps"
          type={buttonTypes.SECONDARY}
          text={content.eps_resources}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/oup/content/resources/ereader"
          type={buttonTypes.SECONDARY}
          text={content.ereader_resources}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/oup/content/resources/elt_games"
          type={buttonTypes.SECONDARY}
          text={content.elt_games_resources}
          fullWidth
          customClassName={styles.button}
        />
        {/* {renderActCodeDashboard()}
        {renderProductGroupSearch()}
        {renderUserDashboard()} */}
      </div>
    </PageWrapper>
  );
}

ContentPreviewPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('contentPreviewPage'))(ContentPreviewPage);
