import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditClassroomReview from './EditClassroomReview';
import EditClassroomSubmitting from './EditClassroomSubmitting'; // Ajax spinner
import EditClassroomConfirmation from './EditClassroomConfirmation'; // Summary at the end
import StudentSelectionPanel from '../../panels/StudentSelectionPanel';
import TeacherSelectionPanel from '../../panels/TeacherSelectionPanel';

import ArchiveClassroom from '../MySchool/tabs/ClassroomsTab/panels/ArchiveClassroom/ArchiveClassroom.js';
import { isAdminOrSupport } from '../../redux/selectors/authorization/user';

import { gotoReview, submitStudents, submitTeachers, closeForm } from '../../redux/reducers/classroomEdit.js';

import USER_ROLES from '../../globals/userRoles';
import { CONTEXT_OPTIONS } from '../../views/UserSelectionView';
import { isHubMode } from '../../utils/platform';
import { HubLayoutConstants } from '../../globals/hubConstants';

function EditClassroom({
  orgId,
  classroom,
  classroomId,
  formState,
  cancelArchiveAction,
  closePopoutAction,
  closeFormAction,
  currentTab,
  classroomEditingState,
  initialLimitValue,
  initialLimitToggleValue,
  redirectUrl,
  studentSubmitAction,
  teacherSubmitAction,
  backAction
}) {
  const navigate = useNavigate();
  let content;
  switch (formState) {
    case 'REVIEW_SELECTIONS':
      content = <EditClassroomReview orgId={orgId} classroomId={classroomId} closePopoutAction={closePopoutAction} />;
      break;
    case 'CHOOSE_TEACHERS':
      content = (
        <TeacherSelectionPanel
          orgId={orgId}
          closePopoutAction={closePopoutAction}
          context={CONTEXT_OPTIONS.EDIT_CLASS}
          classroomEditingState={classroomEditingState}
          initialFilter={[USER_ROLES.TEACHER, USER_ROLES.TEACHER_ADMIN, USER_ROLES.ORG_ADMIN]}
          submitAction={teacherSubmitAction}
          backAction={backAction}
        />
      );
      break;
    case 'CHOOSE_STUDENTS':
      content = (
        <StudentSelectionPanel
          orgId={orgId}
          closePopoutAction={closePopoutAction}
          context={CONTEXT_OPTIONS.EDIT_CLASS}
          existingClassroom={classroom}
          classroomEditingState={classroomEditingState}
          initialRoles={[USER_ROLES.LEARNER, USER_ROLES.MANAGED_USER]}
          initialLimitToggleValue={initialLimitToggleValue}
          initialLimitValue={initialLimitValue}
          submitAction={studentSubmitAction}
          backAction={backAction}
        />
      );
      break;
    case 'ARCHIVE_CLASS':
      content = (
        <ArchiveClassroom
          classroomIds={[classroomId]}
          closeArchiveAction={closePopoutAction}
          backButtonAction={cancelArchiveAction}
          onComplete={() => {
            closePopoutAction();
            closeFormAction();
            navigate(redirectUrl);
          }}
        />
      );
      break;
    case 'SUBMITTING':
      content = <EditClassroomSubmitting />;
      break;
    case 'CONFIRMATION':
      content = <EditClassroomConfirmation closePopoutAction={closePopoutAction} currentTab={currentTab} />;
      break;
    default:
      content = (
        <div>
          <p>Error. Missing an EDIT classroom form for `{formState}`.</p>
        </div>
      );
  }

  return content;
}

EditClassroom.propTypes = {
  orgId: PropTypes.string.isRequired,
  classroom: PropTypes.object.isRequired,
  classroomId: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  closePopoutAction: PropTypes.func.isRequired,
  cancelArchiveAction: PropTypes.func.isRequired,
  closeFormAction: PropTypes.func.isRequired,
  currentTab: PropTypes.string,
  classroomEditingState: PropTypes.object.isRequired,
  initialLimitValue: PropTypes.number,
  initialLimitToggleValue: PropTypes.bool,
  redirectUrl: PropTypes.string.isRequired,
  backAction: PropTypes.func.isRequired,
  studentSubmitAction: PropTypes.func.isRequired,
  teacherSubmitAction: PropTypes.func.isRequired
};

export default connect(
  (state, { orgId, classroomId }) => ({
    formState: state.classroomEdit.formState,
    classroomEditingState: state.classroomEdit,
    initialLimitToggleValue: state.classroomEdit.enableStudentLimit,
    initialLimitValue: state.classroomEdit.studentLimit,
    classroom: state.classrooms.data[classroomId] || {},
    ...(isHubMode()
      ? { redirectUrl: HubLayoutConstants.PATH_NAMES.CLASSES_PATH }
      : { redirectUrl: isAdminOrSupport(state) ? `/org/${orgId}/classes` : '/mySchool/classes' })
  }),
  {
    cancelArchiveAction: gotoReview,
    closeFormAction: closeForm,
    studentSubmitAction: submitStudents,
    teacherSubmitAction: submitTeachers,
    backAction: gotoReview
  }
)(EditClassroom);
