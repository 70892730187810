import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { safePanelUnlink } from '../../../../../utils/links/panelLinks.js';

import { resetForm } from '../../../../../redux/reducers/addToProfile.reducer.js';

import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import DataList from '../../../../../components/DataList/DataList.js';

import cmsContent from '../../../../../utils/cmsContent.js';

function AddToProfileErrorReviewing({ resetAction, data }) {
  const navigate = useNavigate();
  const CMS = cmsContent.addToProfile || {};

  const failedDataPairs = data.map(({ row, value, cmsErrorKeys }) => ({
    title:
      row === '0'
        ? CMS.file_format_error_title
        : CMS.row_error_title.replace('{n}', row).replace('{value}', value === undefined ? '' : value),
    value: cmsErrorKeys.map(val => CMS[val]).join(', '),
    status: 'ERROR'
  }));

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      {data.length > 0 ? (
        /* ERROR SCENARIO 1: Errors found in data: */
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink
                  isLhs={false}
                  text={CMS.close_panel_text}
                  action={() => {
                    // Close the Popout panel
                    navigate(safePanelUnlink('addLicences'));
                    // Once its closed then reset the form
                    setTimeout(resetAction, 300);
                  }}
                />
              </div>
              <PopoutPanelIconHeading
                type={iconHeadingTypes.ERROR}
                title={(CMS.bulk_upload_file_errors_text || '').replace('{errorCount}', data.length)}
                subtitle={CMS.bulk_upload_file_errors_body_text}
              />
            </div>
          }
          footerContent={<PopoutNavFooter backAction={resetAction} />}
        >
          <div id="error-list-container" style={{ padding: '1rem' }}>
            <DataList dataPairs={failedDataPairs} />
          </div>
        </ScrollContainer> /* ERROR SCENARIO 2: No error found in data, but error uploading: */
      ) : (
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink
                  isLhs={false}
                  text={CMS.close_panel_text}
                  action={() => {
                    // Close the Popout panel
                    navigate(safePanelUnlink('addCode'));
                    // Once its closed then reset the form
                    setTimeout(resetAction, 300);
                  }}
                />
              </div>

              <PopoutPanelIconHeading
                type={iconHeadingTypes.ERROR}
                title={CMS.error_message_uploadError_intro_text}
                subtitle={CMS.error_message_uploadError_outro_text}
              />
            </div>
          }
          footerContent={<PopoutNavFooter backAction={resetAction} />}
        />
      )}
    </form>
  );
}

AddToProfileErrorReviewing.propTypes = {
  resetAction: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string,
      status: PropTypes.string
    })
  ).isRequired
};

export default connect(
  state => ({
    data: state.addToProfile.failedEntries
  }),
  {
    // Done button
    resetAction: resetForm
  }
)(AddToProfileErrorReviewing);
