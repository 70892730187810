import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import ContentPreview from '../LorContentPage/ContentPreview';

function EltGamesContentPage() {
  return (
    <PageWrapper>
      <Helmet title="ELT Games" />
      <h1>ELT Games Content</h1>
      <ContentPreview isEltGamesContentArea />
    </PageWrapper>
  );
}

export default EltGamesContentPage;
