import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import compose from '../../../../../utils/compose/compose.js';
import {
  formStates,
  resetForm,
  submitForm,
  confirmDetails,
  gotoClassCodeInput
} from '../../../../../redux/reducers/studentRequestToJoinAClass.reducer.js';
import { SidePanel } from '../../../../../components';
import JoinAClassInputting from './JoinAClassInputting.js';
import JoinAClassSubmitting from './JoinAClassSubmitting.js';
import JoinAClassReviewing from './JoinAClassReviewing';
import JoinAClassConfirmation from './JoinAClassConfirmation';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

class JoinAClass extends Component {
  _handleClosePanel = () => {
    const { closePanel, reset } = this.props;
    reset();
    closePanel();
  };

  _handleOnComplete = () => {
    const { onComplete, reset } = this.props;
    reset();
    onComplete();
  };

  _renderContent = () => {
    const {
      formState,
      localizedContent,
      onConfirmReview,
      gotoClassCodeInputAction,
      onSubmitAction,
      context,
      orgClassDetails,
      failedIds
    } = this.props;

    switch (formState) {
      case formStates.INPUTTING:
        return (
          <JoinAClassInputting
            localizedContent={localizedContent}
            closePanel={this._handleClosePanel}
            onNextClick={onSubmitAction}
          />
        );
      case formStates.GET_ORG_DETAILS:
        return (
          <JoinAClassReviewing
            localizedContent={localizedContent}
            closePanel={this._handleClosePanel}
            onBackClick={gotoClassCodeInputAction}
            onSubmitClick={onConfirmReview}
            context={context}
            orgClassDetails={orgClassDetails}
          />
        );

      case formStates.SUBMITTING:
        return (
          <JoinAClassSubmitting
            localizedContent={localizedContent}
            context={context}
            orgClassDetails={orgClassDetails}
          />
        );
      case formStates.CONFIRMATION:
        return (
          <JoinAClassConfirmation
            closePanel={this._handleClosePanel}
            onComplete={this._handleOnComplete}
            orgClassDetails={orgClassDetails}
            failed={failedIds}
          />
        );
      default:
        return (
          <div>
            <p>Error. Missing a form for {formState}.</p>
          </div>
        );
    }
  };

  render() {
    const { id, ariaLabel, orgClassDetails, isOpen } = this.props;
    const isConfirmBeforeClose = !!Object.keys(orgClassDetails).length;
    return (
      <SidePanel
        id={id}
        ariaLabel={ariaLabel}
        confirmBeforeClose={isConfirmBeforeClose}
        isOpen={isOpen}
        onClose={this._handleClosePanel}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

JoinAClass.propTypes = {
  formState: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,
  context: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  onConfirmReview: PropTypes.func.isRequired,
  onSubmitAction: PropTypes.func.isRequired,
  gotoClassCodeInputAction: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  orgClassDetails: PropTypes.object,
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  failedIds: PropTypes.bool
};

export default compose(
  withLocalizedContent('studentRequestToJoinAClass'),
  connect(
    state => {
      const { studentRequestToJoinAClass } = state;

      return {
        ...pick(studentRequestToJoinAClass, ['formState', 'requestFailed', 'failedIds']),
        context: studentRequestToJoinAClass.context,
        orgClassDetails: studentRequestToJoinAClass.orgClassDetails
      };
    },
    {
      reset: resetForm,
      onSubmitAction: submitForm,
      onConfirmReview: confirmDetails,
      gotoClassCodeInputAction: gotoClassCodeInput
    }
  )
)(JoinAClass);
