import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Button } from '@oup/shared-front-end';
import Link from '../Link/Link';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
// Styles
import styles from './HubEmptyStateRestyle.scss';

function HubEmptyStateRestyle({
  iconSrc,
  iconAlt = '',
  title = '',
  bodyText = '',
  additionalText = '',
  btnFilledBase,
  btnOutlineBase,
  btnSecondaryBase,
  buttonTestHook = '',
  link = {}
}) {
  const displayLink = (
    <Link openInNewTab={link?.openInNewTab !== false} to={link?.link} underlined>
      {link?.text}
      {link?.openInNewTab !== false && <SVGIcon glyph={GLYPHS.ICON_LINK} />}
    </Link>
  );

  return (
    <div className={styles.emptyStateContainer}>
      <img src={iconSrc} alt={iconAlt} />
      <h1>{title}</h1>
      <p className={styles.bodyText}>{bodyText}</p>
      <p className={styles.additionalText}>{additionalText}</p>
      {link?.link && !link?.position ? displayLink : null}
      <div className={styles.buttonsContainer}>
        {btnFilledBase && Object.keys(btnFilledBase).length ? (
          <Button
            text={btnFilledBase.text}
            variant="filled"
            icon={{
              component: btnFilledBase.icon ? (
                <SVGIcon
                  glyph={btnFilledBase.icon}
                  className={`${btnFilledBase.isSvgFillWhite ? styles.svgFillWhite : ''}`}
                />
              ) : null
            }}
            onClick={btnFilledBase.action}
            dataAttributes={{
              testid: buttonTestHook
            }}
          />
        ) : null}
        {btnOutlineBase && Object.keys(btnOutlineBase).length ? (
          <Button
            text={btnOutlineBase.text}
            variant={btnOutlineBase.isPrimaryButton ? 'filled' : 'outline'}
            icon={{ component: btnOutlineBase.icon ? <SVGIcon glyph={btnOutlineBase.icon} /> : null }}
            onClick={btnOutlineBase.action}
          />
        ) : null}
        {btnSecondaryBase && Object.keys(btnSecondaryBase).length ? (
          <Button
            text={btnSecondaryBase.text}
            variant={btnSecondaryBase.isPrimaryButton ? 'filled' : 'outline'}
            icon={{ component: btnSecondaryBase.icon ? <SVGIcon glyph={btnSecondaryBase.icon} /> : null }}
            onClick={btnSecondaryBase.action}
          />
        ) : null}
      </div>
      {link?.link && link?.position === 'bottom' ? (
        <div className={styles.bottomLinkContainer}>{displayLink} </div>
      ) : null}
    </div>
  );
}

export default HubEmptyStateRestyle;

HubEmptyStateRestyle.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  iconAlt: PropTypes.string,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  additionalText: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      position: PropTypes.string
    })
  ]),
  btnFilledBase: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.func,
    icon: PropTypes.string,
    isSvgFillWhite: PropTypes.bool
  }),
  btnOutlineBase: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func,
    icon: PropTypes.string,
    isPrimaryButton: PropTypes.bool
  }),
  btnSecondaryBase: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func,
    icon: PropTypes.string,
    isPrimaryButton: PropTypes.bool
  }),
  buttonTestHook: PropTypes.string
};
