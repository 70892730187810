import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '../utils/compose/compose.js';
import { PAGE_SIZE } from '../globals/pagination';
import { searchUserSortOptions } from '../globals/searchFilters';
import UserRoles, { getRoleLabelPlural } from '../globals/userRoles';
import withLocalizedContent from '../language/withLocalizedContent';
import {
  initialiseInstance,
  setFilter,
  setPage,
  setReveal,
  setSort,
  setTerm,
  triggerSearch
} from '../redux/reducers/data/search.reducer';
import { initialiseSelectionView, setLimit, setLimitToggle, updateSelection } from '../redux/reducers/selection';
import UserSelectionView from '../views/UserSelectionView.js';
import getUsersGroupedByRole from '../redux/utils/getUsersGroupedByRole';

const ORDERED_ROLES = [UserRoles.TEACHER, UserRoles.TEACHER_ADMIN, UserRoles.ORG_ADMIN];

class TeacherSelectionPanel extends Component {
  componentDidMount() {
    const {
      initialiseSelectionViewAction,
      initialiseSearch,
      context,
      userType = '',
      classroomEditingState,
      initialFilter,
      orgId
    } = this.props;

    initialiseSelectionViewAction(context, userType, classroomEditingState.selectedTeacherIds || [], initialFilter);

    initialiseSearch('userSelection', true, {
      orgId,
      archived: false,
      active: true,
      invited: true,
      roles: ORDERED_ROLES
    });
  }

  render() {
    const {
      localizedContent,
      authUserId,
      authUserRole,
      backAction,
      existingClassroom = {},
      classroomEditingState,
      closePopoutAction,
      context,
      orgId,
      usersByRole,
      selectedIds,
      setFilterAction,
      setSearchTerm,
      setSortAction,
      sort = '',
      submitAction,
      updateSelectionAction,
      isLoading,
      page,
      totalResults,
      setPageAction,
      filterOptionsSelected
    } = this.props;

    const {
      studentIdList = [],
      teacherIdList = authUserRole === UserRoles.TEACHER ? [].concat(authUserId) : []
    } = existingClassroom;
    const disabledIds = [...studentIdList, ...teacherIdList];
    const classroomName = classroomEditingState.classroomNameValue || existingClassroom.name;
    const initialSelectedIds = classroomEditingState.selectedTeacherIds;
    const filterOptions = ORDERED_ROLES.map(role => ({
      text: getRoleLabelPlural(role),
      value: role
    }));

    return (
      <UserSelectionView
        backAction={backAction}
        classroomName={classroomName}
        closeAction={closePopoutAction}
        context={context}
        disabledIds={disabledIds}
        initialSelectedIds={initialSelectedIds}
        nextAction={submitAction}
        orgId={orgId}
        filterOptionsSelected={filterOptionsSelected}
        usersByRole={usersByRole}
        selectedIds={selectedIds}
        setFilterAction={values => {
          if (values.length) return setFilterAction(values);
          return setFilterAction(filterOptions.map(option => option.value));
        }}
        setSearchTerm={setSearchTerm}
        setSortFilter={setSortAction}
        sortOptions={searchUserSortOptions('classStudentSearch', sort)}
        updateSelectionAction={updateSelectionAction}
        isLoading={isLoading}
        filterOptions={filterOptions}
        initialSelectedValues={ORDERED_ROLES}
        localizedContent={localizedContent.teacherSelectionPanel}
        page={page}
        size={PAGE_SIZE}
        totalResults={totalResults}
        setPageAction={setPageAction}
      />
    );
  }
}

TeacherSelectionPanel.propTypes = {
  authUserId: PropTypes.string.isRequired,
  authUserRole: PropTypes.string.isRequired,
  backAction: PropTypes.func.isRequired,
  existingClassroom: PropTypes.object.isRequired,
  classroomEditingState: PropTypes.object.isRequired,
  closePopoutAction: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  initialFilter: PropTypes.array.isRequired,
  initialiseSearch: PropTypes.func.isRequired,
  initialiseSelectionViewAction: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  usersByRole: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  sort: PropTypes.string,
  submitAction: PropTypes.func.isRequired,
  updateSelectionAction: PropTypes.func.isRequired,
  userType: PropTypes.string,
  isLoading: PropTypes.bool,
  localizedContent: PropTypes.object,
  page: PropTypes.number.isRequired,
  totalResults: PropTypes.number,
  setPageAction: PropTypes.func.isRequired,
  filterOptionsSelected: PropTypes.array
};

export default compose(
  withLocalizedContent('teacherSelectionPanel'),
  connect(
    state => {
      const searchInstance = state.search.userSelection || {};
      const resultIds = searchInstance.ids || [];
      const isLoading = searchInstance.loading;

      return {
        authUserId: state.identity.userId,
        authUserRole: state.identity.role,
        limitToggleValue: state.selection.limitToggleValue,
        limitValue: state.selection.limitValue,
        filterOptionsSelected: state.search?.userSelection?.filters?.roles,
        page: searchInstance.page || 1,
        totalResults: searchInstance.totalResults,
        usersByRole: getUsersGroupedByRole(state, resultIds, ORDERED_ROLES),
        searchTerm: searchInstance.term,
        selectedIds: state.selection.selectedIds || [],
        sort: searchInstance.sort,
        isLoading
      };
    },
    {
      initialiseSearch: initialiseInstance,
      initialiseSelectionViewAction: initialiseSelectionView,
      setFilterAction: value => setFilter('userSelection', 'roles', value),
      setLimitAction: setLimit,
      setLimitToggleAction: setLimitToggle,
      setPageAction: page => setPage('userSelection', page),
      setRevealAction: reveal => setReveal('userSelection', reveal),
      setSearchTerm: term => setTerm('userSelection', term),
      setSortAction: sort => setSort('userSelection', sort[0]),
      triggerSearchAction: triggerSearch.bind(null, 'userSelection'),
      updateSelectionAction: updateSelection
    }
  )
)(TeacherSelectionPanel);
