import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import compose from '../../utils/compose/compose.js';
import withRouter from '../../utils/withRouter';
import Button, { buttonTypes } from '../../components/Button/Button';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';
import authSettings from '../../globals/authSettings';
import { prepareTargetUrl } from '../../utils/url';
import { triggerLogout } from '../../redux/reducers/identity.reducer.js';

import session from '../../utils/session';

class OfflineAppSuccessPage extends React.Component {
  getText = () => {
    const {
      match: { params },
      localizedContent: { offlineAppSuccessPage: content }
    } = this.props;
    const actionType = params.actionType;

    switch (actionType) {
      case 'AUTH_SUCCES':
        return {
          title: content.auth_succes_text,
          button: content.continue_to_app,
          info: content.close_window_text
        };
      case 'AUTH_CANCELLED':
        return {
          title: content.auth_cancelled_text,
          button: content.continue_to_app,
          info: content.close_window_text
        };
      case 'EDIT_ACCOUNT_SUCCES':
        return {
          title: content.edit_account_success_text,
          button: content.sign_out_and_continue_to_app,
          info: content.still_signed_in
        };
      default:
        return {
          title: content.auth_succes_text,
          button: content.continue_to_app,
          info: content.close_window_text
        };
    }
  };

  redirectToApp = () => {
    const { match, handleLogout } = this.props;
    const targetUrl = prepareTargetUrl();
    const offlineAppInitialState = sessionStorage.getItem('offlineAppInitialState');

    if (targetUrl) {
      sessionStorage.setItem('offlineAppActionType', match.params.actionType);
      handleLogout();
    } else if (offlineAppInitialState) {
      sessionStorage.removeItem('offlineAppInitialState');
      session.clear();
      window.location.href = `${authSettings.idpUrl}/continue?state=${offlineAppInitialState}`;
    }
  };

  hideBackToAppButton = () => {
    const { match } = this.props;
    const targetUrl = prepareTargetUrl();
    return match.params.actionType === 'AUTH_CANCELLED' && !targetUrl;
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <div>
            <PopoutPanelIconHeading type={types.VALID} title={this.getText().title} />
            <div className="text-center gin-top2">
              {this.hideBackToAppButton() ? null : (
                <Button type={buttonTypes.PRIMARY} text={this.getText().button} onClick={() => this.redirectToApp()} />
              )}
              <h3>{this.getText().info}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OfflineAppSuccessPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  match: PropTypes.object,
  handleLogout: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('offlineAppSuccessPage'),
  connect(() => {}, {
    handleLogout: triggerLogout
  })
)(OfflineAppSuccessPage);
