import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { orgRoleDescriptions } from '@oup/shared-node-browser/org';

import compose from '../../utils/compose/compose.js';
import withProps from '../../utils/compose/withProps.js';
import branch from '../../utils/compose/branch.js';

import PageHeading from '../PageHeading/PageHeading';
import withLocalizedContent from '../../language/withLocalizedContent';
import withDataRecency from '../../dataRecency/withDataRecency';
import SearchOptions from '../SearchOptions/SearchOptions';
import withSearchInitialiser from '../SearchInitialiser/withSearchInitialiser.js';
import {
  triggerSearch,
  setTerm,
  setPage,
  setSearchBy,
  setSort,
  setFilter
} from '../../redux/reducers/data/search.reducer';
import PaginationButtons from '../PaginationButtons/PaginationButtons';
import RequestComponent from './RequestComponent';
import ResultComponent from './ResultComponent';
import SearchComponent from './SearchComponent';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../Button/Button';
import { standardSortOptions } from '../../globals/searchFilters';
import Checkbox from '../Checkbox/Checkbox';

import styles from './OrganizationsList.scss';

const defaultSearchSource = 'oupOrgs';
const noFilterOption = 'ALL';

function OrganizationsList({
  localizedContent: { organizationsPage: localizedContent = {} },
  orgs = [],
  resultIds,
  loadingResults = false,
  totalResults = 0,
  page = 0,
  requestFailed,
  orgsDataRecency,
  triggerSearchAction,
  setSearchTerm,
  setPageAction,
  searchTerm,
  searchBy,
  setSearchByAction,
  sort,
  filters,
  setSortAction,
  setFilterAction
}) {
  const handleSearch = () => triggerSearchAction(searchBy || defaultSearchSource);
  const handlePageAction = p => setPageAction(searchBy, p);
  const handleSetSearchTermAction = (source, v) => setSearchTerm(source, v);
  const showTruncatedResultsNotice =
    loadingResults === false && totalResults > 0 && searchBy === 'oupOrgsEmail' && /^@\S+\.\w+$/i.test(searchTerm);

  const handleFilter = valueName => {
    setFilterAction(searchBy, 'organizationType', valueName);
  };

  const handleStatusFilter = (valueName, value) => {
    setFilterAction(searchBy, value, valueName);
  };

  const handleSort = k => setSortAction(searchBy || defaultSearchSource, k[0]);

  const filterOptions = Object.keys({ [noFilterOption]: '', ...orgRoleDescriptions }).map(k => ({
    text: orgRoleDescriptions[k] || localizedContent.filter_list_option_all,
    id: `searchFilter-by-${k}`,
    name: `organizationType`,
    value: k,
    checked: filters.organizationType === k,
    onChange: handleFilter
  }));

  const statusFilterOptions = [
    {
      text: localizedContent.filter_status_active,
      id: 'searchFilter-by-active',
      name: 'searchFilter-by-active',
      value: 'active',
      checked: filters.active,
      onChange: handleStatusFilter
    },
    {
      text: localizedContent.filter_status_partially_registered,
      id: 'searchFilter-by-pending',
      name: 'searchFilter-by-pending',
      value: 'partially_registered',
      checked: filters.partially_registered,
      onChange: handleStatusFilter
    },
    {
      text: localizedContent.filter_status_archived,
      id: 'searchFilter-by-archived',
      name: 'searchFilter-by-archived',
      value: 'archived',
      checked: filters.archived,
      onChange: handleStatusFilter
    }
  ];

  const fittersPopup = useRef();
  const hasSingleStatusFilterChecked =
    [filters.active, filters.partially_registered, filters.archived].filter(v => v).length === 1;

  return (
    <div className="pad-top4">
      <div className={classnames('grid justify-content-between', styles.header)}>
        <div className="sm10">
          <PageHeading title={localizedContent.page_title} />
        </div>
        <div className={classnames(styles.actions, 'flex-row align-center justify-content-between')}>
          <SearchOptions
            buttonId="organizations-filterButton"
            buttonText={localizedContent.filter_button}
            buttonGlyph={GLYPHS.ICON_FILTER}
            customClassName="filterButton"
            inputType="radio"
            options={filterOptions}
            ref={fittersPopup}
            a11yHeading={
              <div className="flex-col">
                <span>{localizedContent.filter_list_heading}</span>
                <ol className={classnames(styles.statusFilters, 'flex-row')}>
                  {statusFilterOptions.map(el => (
                    <Checkbox
                      key={el.id}
                      id={el.id}
                      name={el.name}
                      label={el.text}
                      value={el.checked}
                      disabled={el.checked && hasSingleStatusFilterChecked}
                      onChange={() => {
                        fittersPopup.current.togglePopup();
                        el.onChange(!el.checked, el.value);
                      }}
                    />
                  ))}
                </ol>
              </div>
            }
            ariaControls="searchResults"
          />
          <SearchOptions
            buttonId="organizations-sortButton"
            buttonText={localizedContent.sort_button}
            buttonGlyph={GLYPHS.ICON_SORT}
            customClassName="sortButton"
            inputType="radio"
            onChange={handleSort}
            options={standardSortOptions(sort)}
            a11yHeading={localizedContent.sort_list_heading}
            ariaControls="searchResults"
          />
          <Button
            type={buttonTypes.ROUNDED}
            text={localizedContent.action_refresh}
            iconOnly
            glyph={GLYPHS.ICON_REFRESH}
            customClassName={styles.refreshButton}
            onClick={handleSearch}
          />
        </div>
      </div>

      <div className="grid pad-top2 pad-bot4">
        <div className="row gin-bot3">
          <div className="col">
            <SearchComponent
              localizedContent={localizedContent}
              searchTerm={searchTerm}
              setSearchTerm={handleSetSearchTermAction}
              handleSearch={handleSearch}
              searchBy={searchBy}
              setSearchBy={setSearchByAction}
            />
            {loadingResults ? (
              <RequestComponent
                requestFailed={requestFailed}
                localizedContent={localizedContent}
                triggerSearch={handleSearch}
              />
            ) : (
              <>
                <ResultComponent
                  localizedContent={localizedContent}
                  resultIds={resultIds}
                  orgs={orgs}
                  orgsDataRecency={orgsDataRecency}
                  showTruncatedResultsNotice={showTruncatedResultsNotice}
                />
                {totalResults > 10 && (
                  <div className="gin-top2">
                    <PaginationButtons
                      idPrefix="oupOrgsSearch"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / 10)}
                      onClick={handlePageAction}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

OrganizationsList.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgs: PropTypes.array.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  orgsDataRecency: PropTypes.object.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchBy: PropTypes.string.isRequired,
  setSearchByAction: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired
};

const initialOptions = {
  initialFilters: {
    active: true,
    partially_registered: true,
    archived: false,
    organizationType: noFilterOption
  },
  sort: 'date:desc'
};

export default compose(
  withLocalizedContent('organizationsPage'),
  connect(state => ({
    searchTerm: state.search[state.search.instance]?.term
  })),
  withProps(({ searchTerm = '', action = '', location }) => {
    const keepSearchState = location?.state?.keepSearchState || false;
    const isStateKeepable = !!searchTerm && (action === 'POP' || keepSearchState);

    return { keepSearchState: isStateKeepable };
  }),
  branch(
    props => !props.keepSearchState,
    (withDataRecency('orgs'),
    withSearchInitialiser({
      searchSource: 'oupOrgsEmail',
      ...initialOptions
    }),
    withSearchInitialiser({
      searchSource: defaultSearchSource,
      ...initialOptions
    }))
  ),
  connect(
    state => ({
      userRole: state.identity.role,
      orgs: state.organisations.data,
      resultIds: state.search[state.search.instance]?.ids || [],
      loadingResults: state.search[state.search.instance]?.loading,
      requestFailed: state.search[state.search.instance]?.error,
      totalResults: state.search[state.search.instance]?.totalResults,
      sort: state.search[state.search.instance]?.sort,
      filters: state.search[state.search.instance]?.filters,
      page: state.search[state.search.instance]?.page,
      searchTerm: state.search[state.search.instance]?.term,
      searchBy: state.search.instance
    }),
    {
      triggerSearchAction: triggerSearch,
      setSearchByAction: setSearchBy,
      setSearchTerm: setTerm,
      setPageAction: setPage,
      setSortAction: setSort,
      setFilterAction: setFilter
    }
  )
)(OrganizationsList);
