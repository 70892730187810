import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import GradebookTable from '../GradebookTable/GradebookTable';
import { getLevelsCompletedSortKey, getLevelsScoreSortKey } from './Levels';
import { featureIsEnabled } from '../../globals/envSettings';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

function SubHeaders({ levels = [], sortKey, sortDirection, sortOnChange, isActivityLevel = false }) {
  const accessed = featureIsEnabled('replacing-last-accessed-with-last-opened') ? 'lastOpened' : 'lastAccessed';
  const getArrowIcon = direction =>
    direction === 'desc' ? <SVGIcon glyph={GLYPHS.CHEVRON_DOWN_THICK} /> : <SVGIcon glyph={GLYPHS.CHEVRON_UP_THICK} />;
  const droplistIcon = <SVGIcon glyph={GLYPHS.ICON_DROPLIST} />;

  return (
    <GradebookTable.Row>
      <GradebookTable.Header stickyColumn>
        <Button
          id="student_button"
          variant="transparent"
          text="Student"
          icon={{
            component: sortKey === 'studentName' ? getArrowIcon(sortDirection) : droplistIcon,
            placement: 'right'
          }}
          onClick={() => sortOnChange('studentName')}
        />
      </GradebookTable.Header>
      <GradebookTable.Header>
        <Button
          id="date_button"
          variant="transparent"
          text="Date"
          icon={{
            component: sortKey === accessed ? getArrowIcon(sortDirection) : droplistIcon,
            placement: 'right'
          }}
          onClick={() => sortOnChange(accessed)}
        />
      </GradebookTable.Header>
      <GradebookTable.Header key={getLevelsCompletedSortKey('TOTAL')} shaded>
        <Button
          id="completed_button"
          variant="transparent"
          text="Completed"
          icon={{
            component: sortKey === getLevelsCompletedSortKey('TOTAL') ? getArrowIcon(sortDirection) : droplistIcon,
            placement: 'right'
          }}
          onClick={() => sortOnChange(getLevelsCompletedSortKey('TOTAL'))}
        />
      </GradebookTable.Header>
      <GradebookTable.Header key={getLevelsScoreSortKey('TOTAL')}>
        <Button
          id="score_button"
          variant="transparent"
          text="Score"
          icon={{
            component: sortKey === getLevelsScoreSortKey('TOTAL') ? getArrowIcon(sortDirection) : droplistIcon,
            placement: 'right'
          }}
          onClick={() => sortOnChange(getLevelsScoreSortKey('TOTAL'))}
        />
      </GradebookTable.Header>
      {levels.map((level, i) => {
        const completedKey = getLevelsCompletedSortKey(i);
        const scoreKey = getLevelsScoreSortKey(i);

        return [
          <>
            <GradebookTable.Header key={completedKey} shaded locked={level.locked}>
              <Button
                id={`completed_${completedKey}_button`}
                variant="transparent"
                text={isActivityLevel ? 'Attempts' : 'Completed'}
                icon={{
                  component: sortKey === completedKey ? getArrowIcon(sortDirection) : droplistIcon,
                  placement: 'right'
                }}
                onClick={() => sortOnChange(completedKey)}
              />
            </GradebookTable.Header>
            <GradebookTable.Header key={scoreKey} locked={level.locked}>
              <Button
                id={`score_${scoreKey}_button`}
                variant="transparent"
                text="Score"
                icon={{
                  component: sortKey === scoreKey ? getArrowIcon(sortDirection) : droplistIcon,
                  placement: 'right'
                }}
                onClick={() => sortOnChange(scoreKey)}
              />
            </GradebookTable.Header>
          </>
        ];
      })}
    </GradebookTable.Row>
  );
}
SubHeaders.propTypes = {
  levels: PropTypes.array,
  sortKey: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortOnChange: PropTypes.func.isRequired,
  isActivityLevel: PropTypes.bool
};

export default SubHeaders;
