import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../../utils/compose/compose.js';
import TextInput from '../../../../components/TextInput/TextInput';
import ButtonGroup, { ButtonGroupType } from '../../../../components/ButtonGroup/ButtonGroup';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import RadioInput from '../../../../components/RadioInput/RadioInput';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import LookupProducts from '../../../../components/LookupProducts/LookupProducts';
import ActivationCodeSearchResults from './ActivationCodeSearchResults';
import useIteratorPagination from '../../EpsAdminOPS/IteratorPagination/useIteratorPagination';
import IteratorPagination from '../../EpsAdminOPS/IteratorPagination/IteratorPagination';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import colors from '../../../../globals/colors';
import styles from './ActivationCodeSearchArea.scss';

import {
  searchActivationCode,
  resetActivationCodeSearch,
  startActivationCodeSearch,
  resetProductGroup
} from '../../../../redux/actions/activationCodeDashboard';

function ActivationCodeSearchArea({
  localizedContent: { activationCodeSearchAreaForm: content },
  searchActCode,
  activationCodeSearchResults,
  errorOccurred,
  resetForm,
  startSearch,
  searchLoading
}) {
  const [activationCodeFilter, setActivationCodeFilter] = useState(null);
  const [productIdFilter, setProductIdFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [currentPage, setCurrentPage] = useState(1);
  const numberPerPage = 5;
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [goToFirst] = useIteratorPagination();

  const setOverlay = param => {
    setIsOverlayOpen(param);
  };

  const performCodeSearch = query => {
    const filters = {
      activationCodeFilter,
      productIdFilter,
      statusFilter,
      currentPage,
      numberPerPage,
      pagination: query || goToFirst(numberPerPage)
    };
    startSearch();
    searchActCode(filters);
  };

  const performReset = () => {
    setActivationCodeFilter(null);
    setProductIdFilter(null);
    setStatusFilter('ALL');
    setCurrentPage(1);
    resetForm();
    setOverlay(false);
  };

  useEffect(
    () => () => {
      // -- reset on unmount
      performReset();
    },
    []
  );

  const renderPagination = () => {
    if (activationCodeSearchResults) {
      const { data, metadata } = activationCodeSearchResults.success;
      const total = metadata[0].total;
      if (data.length) {
        return (
          <div className={styles.paginationContainer}>
            <IteratorPagination
              paginationCallback={query => performCodeSearch(query)}
              items={data}
              numberPerPage={numberPerPage}
              idField="_id"
              lastPage={Math.ceil(total / numberPerPage)}
            />
          </div>
        );
      }
    }
    return <div />;
  };

  const totalResults = () => {
    if (searchLoading) {
      return <> </>;
    }
    let total = 0;
    if (activationCodeSearchResults) {
      const { metadata } = activationCodeSearchResults.success;
      total = metadata[0].total;
    }
    const results = total === 1 ? content.one_result : content.multiple_results.replace('{records}', total);

    return <div> {total > 0 ? content.total_results.replace('{records}', results) : ''} </div>;
  };

  const renderSearchResult = () => {
    if (searchLoading) {
      return <LoadingSpinner />;
    }

    if (activationCodeSearchResults) {
      const { data } = activationCodeSearchResults.success;

      if (!data.length) {
        return (
          <div>
            <SVGIcon glyph={GLYPHS.ICON_WARNING_TRIANGLE} className={styles.warningIcon} />
            <div className={styles.errorMsg}>{content.no_results}</div>
          </div>
        );
      }

      return (
        <div>
          <ActivationCodeSearchResults results={activationCodeSearchResults} />
        </div>
      );
    }

    if (errorOccurred) {
      return (
        <div>
          <SVGIcon glyph={GLYPHS.ICON_WARNING_TRIANGLE} className={styles.warningIcon} />
          <div className={styles.errorMsg}>{content.err_msg}</div>
        </div>
      );
    }

    return <> </>;
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchForm}>
        <div className={styles.searchCriteriaContainer}>
          <Card headingText={content.enter_criteria} headingTag="h2" showHeadingLabel headingColor={colors.ARCHIVED}>
            <div className={styles.cardRow}>
              <span>{content.activation_code}</span>
              <TextInput
                labelHidden
                disableAutoComplete
                onChange={e => setActivationCodeFilter(e)}
                value={activationCodeFilter || ''}
                id="act_code"
                name="act_code_filter"
                placeholder=""
                label=""
              />
            </div>
            <div className={styles.cardRow}>
              <span>{content.product}</span>
              <LookupProducts
                className={styles.flexDiv}
                productSelected={id => {
                  setProductIdFilter(id);
                }}
                productSelectedValue={productIdFilter}
                isOverlayOpen={isOverlayOpen}
                setIsOverlayOpen={setOverlay}
              />
            </div>

            <div className={styles.cardRowRadio}>
              <span>{content.status}</span>
              <ButtonGroup id="availability" style={{ flex: 1 }} groupType={ButtonGroupType.HORIZONTAL_GROUP}>
                <RadioInput
                  onChange={e => {
                    if (e) {
                      setStatusFilter('AVAILABLE');
                    }
                  }}
                  name="radioButtonDefault"
                  value={1}
                  checked={statusFilter === 'AVAILABLE'}
                  label={content.available}
                />
                <RadioInput
                  onChange={e => {
                    if (e) {
                      setStatusFilter('UNAVAILABLE');
                    }
                  }}
                  name="radioButtonDefault"
                  value={2}
                  checked={statusFilter === 'UNAVAILABLE'}
                  label={content.not_available}
                />
                <RadioInput
                  onChange={e => {
                    if (e) {
                      setStatusFilter('ALL');
                    }
                  }}
                  name="radioButtonDefault"
                  value={3}
                  label={content.all}
                  checked={statusFilter === 'ALL'}
                />
              </ButtonGroup>
            </div>
          </Card>
        </div>
        <div className={styles.searchButtonsContainer}>
          <div>
            <Button type={buttonTypes.PRIMARY} text={content.search} onClick={() => performCodeSearch()} />
            <Button type={buttonTypes.PRIMARY} text={content.reset} onClick={performReset} />
          </div>
        </div>
      </div>
      <div className={styles.resultsArea}>{totalResults()}</div>
      <div className={styles.resultsArea}>{renderSearchResult()}</div>
      <div>{renderPagination()}</div>
    </div>
  );
}

ActivationCodeSearchArea.propTypes = {
  localizedContent: PropTypes.object,
  searchActCode: PropTypes.func,
  activationCodeSearchResults: PropTypes.object,
  errorOccurred: PropTypes.bool,
  resetForm: PropTypes.func,
  startSearch: PropTypes.func,
  searchLoading: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    activationCodeDashboard: { errorOccurred, success, activationCodeSearchResults, searchLoading }
  } = state;
  return { errorOccurred, success, activationCodeSearchResults, searchLoading };
};

const mapDispatchToProps = dispatch => ({
  searchActCode: filters => {
    dispatch(searchActivationCode(filters));
  },
  resetForm: () => {
    dispatch(resetActivationCodeSearch());
    dispatch(resetProductGroup());
  },
  startSearch: () => {
    dispatch(startActivationCodeSearch());
  }
});

export default compose(
  withLocalizedContent('activationCodeSearchAreaForm'),
  connect(mapStateToProps, mapDispatchToProps)
)(ActivationCodeSearchArea);
