import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import TextInput from '../TextInput/TextInput';
import Button, { buttonTypes } from '../Button/Button';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './LookupProducts.scss';
import { lookUpProductForCompletion } from '../../redux/actions/activationCodeDashboard';
import withLocalizedContent from '../../language/withLocalizedContent';

function LookupProducts({
  localizedContent: { lookupProducts: content },
  productSelected,
  lookupProduct,
  productLookupData,
  productLookupError,
  returnProductOrId = false,
  productSelectedValue,
  isOverlayOpen,
  setIsOverlayOpen
}) {
  let productType = null;

  const selectProduct = product => {
    const id = product.productId || product.productGroupId;
    const value = returnProductOrId ? product : id;
    productSelected(value, productType);
    setIsOverlayOpen(false);
    const input = document.querySelector('#look_up');
    input.value = id;
  };

  const renderProductGroupTable = (productGroupsData, total) => (
    <table key="table_pg" className={styles.lookupTable}>
      <tbody>
        {productGroupsData.map((p, index) => (
          <tr
            key={`row_${index}`}
            onClick={() => {
              selectProduct(p);
            }}
          >
            <td>
              <div className={styles.innerTable}>
                <div className={styles.innerRow}>
                  <div className={styles.productName}>{content.product_group_name}</div>
                  <div className={styles.productName}>{p.productGroupName}</div>
                </div>
              </div>
            </td>
            <td>
              <div className={styles.innerTable}>
                <div className={styles.innerRow}>
                  <div>{content.product_group_id}</div>
                  <div>{p.productGroupId}</div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>{total > 1 ? (content.total_results || '').replace('$total', total) : ''}</td>
          <td />
        </tr>
      </tfoot>
    </table>
  );

  const renderProductsTable = (productsData, total) => (
    <table key="table_product" className={styles.lookupTable}>
      <tbody>
        {productsData.map((p, index) => (
          <tr
            key={`row_${index}`}
            onClick={() => {
              selectProduct(p);
            }}
          >
            <td>
              <div className={styles.innerTable}>
                <div className={styles.innerRow}>
                  <div className={styles.productName}>{content.product_name}</div>
                  <div className={styles.productName}>{p.productName}</div>
                </div>
                <div className={styles.innerRow}>
                  <div>{content.product_id}</div>
                  <div>{p.productId}</div>
                </div>
              </div>
            </td>
            <td>
              <div className={styles.innerTable}>
                <div className={styles.innerRow}>
                  <div>{content.unit}</div>
                  <div>{p.organisationalUnit}</div>
                </div>
                <div className={styles.innerRow}>
                  <div>{content.type}</div>
                  <div>{p.registrableType}</div>
                </div>
                <div className={styles.innerRow}>
                  <div>{content.activation_type}</div>
                  <div>{p.activationDetail.activationType}</div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>{total > 1 ? (content.total_results || '').replace('$total', total) : ''}</td>
          <td />
        </tr>
      </tfoot>
    </table>
  );

  const renderTable = data => {
    const results = [];
    const limit = data.limit || 10;
    const products = data.totalProducts;
    const groupProducts = data.totalProductGroups;
    if (data?.productGroups?.length === 0 && data?.products?.length === 0) {
      return <div>{content.no_results}</div>;
    }
    if (productLookupError) {
      return <div>{content.error}</div>;
    }
    if (data?.products?.length) {
      productType = 'product';
      results.push(renderProductsTable(data.products, products));
    }
    if (data?.productGroups?.length) {
      const limitedProductsGroups = data.productGroups.slice(0, limit);
      productType = 'productGroup';
      if (data?.products?.length < limit) {
        const productLength = data.products.length;
        results.push(renderProductGroupTable(limitedProductsGroups.slice(0, -productLength), groupProducts));
      }
      if (!data?.products?.length) {
        return renderProductGroupTable(limitedProductsGroups, groupProducts);
      }
    }

    if (results.length > 0) {
      return <> {results} </>;
    }

    return <LoadingSpinner />;
  };

  const search = async e => {
    if (returnProductOrId) {
      productSelected({ productInput: e });
    } else {
      productSelected(e);
    }
    if (e.length > 3) {
      lookupProduct(e);
      setIsOverlayOpen(true);
    }
  };

  const searchFromButton = async () => {
    const input = document.querySelector('#lookup_input input');
    search(input.value);
  };

  const renderOverlay = () => {
    if (isOverlayOpen) {
      return (
        <PopupOverlay
          id="productLookup"
          ariaLabel={` `}
          visible={isOverlayOpen}
          panelSize="fullWidth"
          customClassName={' '}
          inline
          onTogglePopup={() => {}}
        >
          {renderTable(productLookupData)}
        </PopupOverlay>
      );
    }
    return <> </>;
  };

  return (
    <div>
      <div className={styles.lookupContainer} id="lookup_input">
        <TextInput
          labelHidden
          disableAutoComplete
          onChange={e => search(e)}
          placeholder={content.placeholder}
          value={productSelectedValue || ''}
          label=""
          name="look_up"
          id="look_up"
        />
        <Button onClick={searchFromButton} type={buttonTypes.PRIMARY_THIN} text={content.button_text} />
      </div>
      {renderOverlay()}
    </div>
  );
}

LookupProducts.propTypes = {
  productSelected: PropTypes.func.isRequired,
  lookupProduct: PropTypes.func.isRequired,
  productLookupData: PropTypes.any.isRequired,
  productLookupError: PropTypes.object,
  localizedContent: PropTypes.object.isRequired,
  returnProductOrId: PropTypes.bool,
  productSelectedValue: PropTypes.string,
  isOverlayOpen: PropTypes.bool.isRequired,
  setIsOverlayOpen: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    activationCodeDashboard: { productLookupData, productLookupError }
  } = state;
  return { productLookupData, productLookupError };
};

const mapDispatchToProps = dispatch => ({
  lookupProduct: id => {
    dispatch(lookUpProductForCompletion({ id }));
  }
});

export default compose(
  withLocalizedContent('lookupProducts'),
  connect(mapStateToProps, mapDispatchToProps)
)(LookupProducts);
