import { takeLatest, takeEvery } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import loadPlacementTests from './loadPlacementTests';
import { GET_WIZARD_TEST_CREDITS_REQUEST } from '../../../../reducers/placementTestSessionCreate';
import optGetTestCredits from '../placementOnboardingWizard/optGetTestCredits';
import loadStudentTasks from './loadStudentsTasks';
import loadUserAccessPlacementTest from './loadUserAccessPlacementTest';
import loadStudentActiveTasksCount from './loadStudentsActiveTasksCount';

function* root() {
  yield takeLatest(t.GET_PLACEMENT_TESTS, ({ payload }) => loadPlacementTests(payload));
  yield takeEvery(GET_WIZARD_TEST_CREDITS_REQUEST, ({ payload }) => optGetTestCredits(payload));
  yield takeEvery(t.GET_STUDENT_TASKS, ({ payload }) => loadStudentTasks(payload));
  yield takeEvery(t.USER_ACCESS_PLACEMENT_TEST_SESSION, ({ payload }) => loadUserAccessPlacementTest(payload));
  yield takeEvery(t.GET_STUDENT_ACTIVE_TASKS_COUNT, ({ payload }) => loadStudentActiveTasksCount(payload));
}

export default root;
