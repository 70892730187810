import { PLATFORMS } from '@oup/shared-node-browser/constants';

const getProductActivityCount = product => {
  let activitiesNumber = product.activitiesNo;
  if (product.platform === PLATFORMS.ELTCORE) {
    const recursiveCountSelected = line => {
      const lineChecked = line.isActivity && line.maxMarks > 0 ? 1 : 0;

      if (line.childs && line.childs.length > 0) {
        return lineChecked + line.childs.reduce((acc, child) => acc + recursiveCountSelected(child), 0);
      }
      return lineChecked;
    };

    activitiesNumber = product.ngcProductInformations.reduce(
      (acc, line) => acc + recursiveCountSelected(line, true),
      0
    );
  }

  return activitiesNumber;
};

export default getProductActivityCount;
