import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TextLink } from '@oup/shared-front-end';
import { Link as RouterLink } from 'react-router-dom';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { isHubMode } from '../../../../../../utils/platform';

// Component imports
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import FileUploadInput from '../../../../../../components/FileUploadInput/FileUploadInput.js';

import BulkUploadInformationBubble from '../../../../../../components/BulkUploadInformationBubble/BulkUploadInformationBubble.js';

import {
  bulkSubmitForm,
  selectFile,
  resetFile,
  showLibraryForm
} from '../../../../../../redux/reducers/addToLibrary.reducer.js';

import cmsContent from '../../../../../../utils/cmsContent.js';

function AddToLibraryEditInputting({
  closePanel,
  bulkSubmitFormAction,
  selectFileAction,
  resetFileAction,
  showLibraryFormAction,
  orgName
}) {
  const CMS = cmsContent.addToLibrary || {};
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
            </div>
            <PanelHeading
              title={CMS.bulk_import_access_codes_title_text}
              subtitle={CMS.bulk_import_access_codes_subtitle_text.replace('{orgName}', orgName)}
            />
          </div>
        }
        footerContent={<PopoutNavFooter backAction={showLibraryFormAction} />}
      >
        <div style={{ padding: '2rem 0 4rem' }}>
          <div className="grid">
            <div className="row">
              <div className="col">
                <FileUploadInput
                  id="fileUploadInput"
                  label={CMS.file_upload_label_text}
                  buttonText={CMS.button_select_file}
                  supportedFileTypes={['.txt']}
                  handleFile={selectedFile => {
                    if (selectedFile) {
                      // create a FileReader
                      const reader = new FileReader();
                      // Read the File
                      reader.readAsText(selectedFile);
                      // Once the FileReader has finished reading the file, emit an action with the file data we need
                      reader.onload = () => {
                        selectFileAction({
                          name: selectedFile.name,
                          data: reader.result.replace(/\s+/g, '\n')
                        });
                        bulkSubmitFormAction();
                      };
                    } else {
                      resetFileAction();
                    }
                  }}
                  notSupportedMessage={CMS.not_supported_file_title}
                  notSupportedMessageLine2={CMS.not_supported_file_subtitle}
                  retryMessage={CMS.try_again}
                />
                <BulkUploadInformationBubble bodyText={CMS.bulk_upload_buble_body_text} />
                <p>
                  <TextLink
                    to={
                      isHubMode()
                        ? APP_CONSTANTS.HUB_SUPPORT_IMPORT_ACCESS_CODES_URL
                        : APP_CONSTANTS.IMPORT_ACCESS_CODE_HELP_LINK
                    }
                    component={isHubMode() ? null : RouterLink}
                    labelText="Label"
                    target="_blank"
                  >
                    <b>{CMS.help_import_access_code_sub_heading}</b> {CMS.help_import_access_code_main_heading}
                  </TextLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ScrollContainer>
    </form>
  );
}

AddToLibraryEditInputting.propTypes = {
  closePanel: PropTypes.func.isRequired,
  bulkSubmitFormAction: PropTypes.func.isRequired,
  selectFileAction: PropTypes.func.isRequired,
  resetFileAction: PropTypes.func.isRequired,
  showLibraryFormAction: PropTypes.func.isRequired,
  orgName: PropTypes.string.isRequired
};

export default connect(null, {
  bulkSubmitFormAction: bulkSubmitForm,
  selectFileAction: selectFile,
  resetFileAction: resetFile,
  showLibraryFormAction: showLibraryForm
})(AddToLibraryEditInputting);
