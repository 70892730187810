import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import compose from '../../utils/compose/compose.js';
import PageWrapper from '../PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../Button/Button';
import { featureIsEnabled } from '../../globals/envSettings';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './OrganisationAdmin.scss';

function AdminProductsPage({ localizedContent }) {
  return (
    <PageWrapper>
      <Helmet title={localizedContent.organisationAdmin.helmet_products_title} />
      <h1>{localizedContent.organisationAdmin.products_title}</h1>
      <span className={styles.subheading}>{localizedContent.organisationAdmin.subtitle}</span>
      <div className={styles.linkContainer}>
        <Button
          to="/products/csv-product-structure-upload"
          type={buttonTypes.SECONDARY}
          text={localizedContent.organisationAdmin.upload_a_file_for_product_structure}
          fullWidth
          customClassName={styles.button}
        />
        {featureIsEnabled('oup-data-upload') && (
          <Button
            to="/products/oup-data-upload"
            type={buttonTypes.SECONDARY}
            text={localizedContent.organisationAdmin.stage_products}
            fullWidth
            customClassName={styles.button}
          />
        )}
        {featureIsEnabled('oup-data-upload') && (
          <Button
            to="/products/oup-data-publish"
            type={buttonTypes.SECONDARY}
            text={localizedContent.organisationAdmin.publish_products}
            fullWidth
            customClassName={styles.button}
          />
        )}
        {featureIsEnabled('oup-data-upload') && (
          <Button
            to="/products/oup-data-upload"
            type={buttonTypes.SECONDARY}
            text={localizedContent.organisationAdmin.stage_product_metadata}
            fullWidth
            customClassName={styles.button}
          />
        )}
        {featureIsEnabled('oup-data-upload') && (
          <Button
            to="/products/oup-data-publish"
            type={buttonTypes.SECONDARY}
            text={localizedContent.organisationAdmin.publish_product_metadata}
            fullWidth
            customClassName={styles.button}
          />
        )}
      </div>
    </PageWrapper>
  );
}

AdminProductsPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('organisationAdmin'))(AdminProductsPage);
