import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import compose from '../utils/compose/compose.js';
import withRouter from '../utils/withRouter';
import withSearchHandlers from '../decorators/withSearchHandlers';
import actions from '../redux/actions';
import getManagedUserUploadActivity from '../redux/selectors/getManagedUserUploadActivity';
import ActivityView from '../views/ActivityView';

class ActivityPage extends Component {
  async componentDidMount() {
    const { orgId, onChangeSearchParam, onUpdateSearchResults } = this.props;
    await onChangeSearchParam('filters.orgId', orgId);
    onUpdateSearchResults();
  }

  render() {
    const { totalResults, searchParams } = this.props;
    return (
      <ActivityView
        {...pick(this.props, [
          'searchParams',
          'page',
          'managedUserUploadActivity',
          'isLoading',
          'hasFailed',
          'onChangeSearchParam',
          'onUpdateSearchResults'
        ])}
        numberOfPages={Math.ceil(totalResults / searchParams.size)}
      />
    );
  }
}

ActivityPage.propTypes = {
  orgId: PropTypes.string.isRequired,
  managedUserUploadActivity: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasFailed: PropTypes.bool.isRequired,
  totalResults: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
  onChangeSearchParam: PropTypes.func.isRequired,
  onUpdateSearchResults: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  connect(
    (state, { match: { params } }) => ({
      ...pick(state.activity, ['isLoading', 'hasFailed', 'totalResults']),
      orgId: params.orgId || state.identity.currentOrganisationId,
      managedUserUploadActivity: getManagedUserUploadActivity(state)
    }),
    { search: actions.getActivityRequest }
  ),
  withSearchHandlers({
    types: ['managedUserUpload'],
    sort: 'activityDate:desc'
  })
)(ActivityPage);
