import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ORG_STAFF, ORG_STUDENTS } from '@oup/shared-node-browser/constants.js';

// Component imports
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import FileUploadInput from '../../../../../../components/FileUploadInput/FileUploadInput.js';
import BulkUploadInformationBubble from '../../../../../../components/BulkUploadInformationBubble/BulkUploadInformationBubble.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import Link from '../../../../../../components/Link/Link';
import {
  selectFile,
  resetFile,
  bulkSubmitForm,
  csvBulkSubmitForm,
  bulkByUserTypeSubmitForm,
  csvBulkReviewableSubmitForm,
  showEnrolUserForm
} from '../../../../../../redux/reducers/enrolUser.reducer.js';
import content from '../../../../../../utils/cmsContent.js';
import { isEltAssessmentMode, isOptMode, isOteMode, isOrbMode, isHubMode } from '../../../../../../utils/platform';
import { orgRoles } from '../../../../../../globals/orgRoles.js';
import EnrolUserTemplatesAndInstructions from './EnrolUserTemplatesAndInstructions/EnrolUserTemplatesAndInstructions.js';

function EnrolUserBulkInputting({
  closePanel,
  selectFileAction,
  resetFileAction,
  bulkSubmitFormAction,
  csvBulkSubmitFormAction,
  bulkByUserTypeSubmitFormAction,
  csvBulkReviewableSubmitFormAction,
  showEnrolUserFormAction,
  uploadContext = {},
  administrationRightsSupportLink,
  orgData = {}
}) {
  const CMS = content.enrolUserBulkPage || {};
  const { context = null, groupType = '', groupId = '', userTypeToEnrol = '' } = uploadContext || {};
  const { orgName = '', orgRole = '', isPlacementCentre = false } = orgData || {};
  const isTestCenter = isOteMode() || orgRole === orgRoles.TEST_CENTER;
  const isPrimarySchool = isOrbMode() || orgRole === orgRoles.PRIMARY_SCHOOL;
  const isYoungLearnersEnabled = isHubMode() && featureIsEnabled('young-learners');
  const isOTEReviewUploadFlow =
    featureIsEnabled('ote-users-upload-review-flow') && !isPlacementCentre && orgRole !== orgRoles.PRIMARY_SCHOOL;

  const getStudentInformationBubbleBodyText = () => {
    let studentInformationBubbleBodyText = '';

    switch (true) {
      case isTestCenter:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_ote_old;
        break;
      case isOptMode() || userTypeToEnrol === 'students':
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_opt;
        break;
      case isYoungLearnersEnabled:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_with_under_12_hub;
        break;
      case isHubMode():
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_hub;
        break;
      default:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text;
    }
    return studentInformationBubbleBodyText;
  };

  const getStaffInformationBubbleBodyText = () => {
    let staffInformationBubbleBodyText = '';

    switch (true) {
      case isTestCenter:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_ote;
        break;
      case isOptMode() || userTypeToEnrol === 'staff':
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_opt;
        break;
      case isPrimarySchool:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_orb;
        break;
      case isHubMode():
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_hub;
        break;
      default:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text;
    }

    return staffInformationBubbleBodyText;
  };

  const supportedFileTypes =
    isHubMode() || isOTEReviewUploadFlow ? ['.csv', '.txt', '.xls', '.xlsx'] : ['.csv', '.txt'];

  const _importText = () =>
    context === ORG_STUDENTS ? getStudentInformationBubbleBodyText() : getStaffInformationBubbleBodyText();

  const _headerText = () => (context === ORG_STUDENTS ? CMS.student_pageHeading : CMS.staff_pageHeading);

  const _subheaderText = () => (userTypeToEnrol ? `${CMS.pageSubheadingOpt} <b>${orgName}</b>` : CMS.pageSubheading);

  // Assessment master remove the class column in bulk template glossary file
  const _bulkTemplateText = (contextType, isTestCenterStatus, isPlacementCentreStatus) => {
    let templateText = '';

    if (userTypeToEnrol === 'students') {
      templateText = CMS.download_file_without_role_and_class_template_text;
    } else if (userTypeToEnrol === 'staff') {
      templateText = isPlacementCentreStatus
        ? CMS.download_file_staff_without_username_and_class_template_text
        : CMS.download_file_withoutclass_staff_template_text;
    } else if (isYoungLearnersEnabled) {
      templateText =
        contextType === ORG_STUDENTS
          ? CMS.download_file_with_under_12_template_text
          : CMS.download_file_staff_without_username_template_text;
    } else if (isHubMode()) {
      // IF to be removed after "young-learners" FF is turned on
      templateText =
        contextType === ORG_STUDENTS
          ? CMS.download_file_without_username_template_text
          : CMS.download_file_staff_without_username_template_text;
    } else if (contextType === ORG_STUDENTS) {
      templateText = isTestCenterStatus
        ? CMS.download_file_withoutclass_template_text
        : CMS.download_file_template_text || '';
    } else {
      templateText = isTestCenterStatus
        ? CMS.download_file_withoutclass_staff_template_text
        : CMS.download_file_staff_template_text || '';
    }

    return templateText.replace('\\n', '\n');
  };

  function getTemplateDownloadPath(templateContext) {
    if (userTypeToEnrol === 'students') {
      return CMS.download_learner_without_class_xls_template_path;
    }

    if (userTypeToEnrol === 'staff') {
      return CMS.download_staff_without_class_xls_template_path;
    }

    if (isYoungLearnersEnabled) {
      return templateContext === ORG_STAFF
        ? CMS.download_staff_xls_template_path
        : CMS.download_users_with_under_12_xls_template_path;
    }

    if (isTestCenter) {
      return templateContext === ORG_STAFF
        ? CMS.download_test_center_user_xls_template_path
        : CMS.download_test_taker_xls_template_path;
    }

    if (isHubMode()) {
      return templateContext === ORG_STAFF
        ? CMS.download_staff_xls_template_path
        : CMS.download_users_xls_template_path;
    }

    return templateContext === ORG_STAFF ? CMS.download_user_xls_template_path : CMS.download_learner_xls_template_path;
  }

  function getTemplateName(templateContext) {
    let templateName = '';

    if (templateContext === ORG_STAFF) {
      templateName = CMS.download_user_xls_template_name;

      if (isHubMode()) {
        templateName = CMS.download_staff_xls_template_name;
      }
    } else {
      templateName = CMS.download_learner_xls_template_name;
    }

    return templateName;
  }

  function getFileName(templateContext) {
    if (userTypeToEnrol === 'students') {
      return CMS.download_learner_file_name;
    }

    if (userTypeToEnrol === 'staff') {
      return CMS.download_staff_file_name;
    }

    return templateContext === ORG_STAFF ? CMS.download_staff_file_name : CMS.download_file_name;
  }

  const closePanelHandler = () => {
    showEnrolUserFormAction();
    closePanel();
  };

  const reviewableBulkUploadOptions = {
    ...orgData,
    userTypeToEnrol: context
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanelHandler} />
            </div>
            <PanelHeading title={_headerText()} subtitle={_subheaderText()} />
          </div>
        }
        footerContent={<PopoutNavFooter backAction={showEnrolUserFormAction} />}
      >
        <div style={{ padding: '2rem 0 4rem' }}>
          <div className="grid">
            <div className="row">
              <div className="col">
                <FileUploadInput
                  id="fileUploadInput"
                  label={
                    isHubMode() || isOTEReviewUploadFlow
                      ? CMS.fileUpload_input_csv_or_excel_label
                      : CMS.fileUpload_input_label
                  }
                  buttonText={CMS.fileUpload_button_chooseFile_text}
                  supportedFileTypes={supportedFileTypes}
                  handleFile={selectedFile => {
                    if (selectedFile) {
                      const reader = new FileReader();

                      reader.onload = () => {
                        selectFileAction({
                          name: selectedFile.name,
                          type: selectedFile.type,
                          data: reader.result
                        });

                        if (groupType && groupId && userTypeToEnrol) {
                          return bulkByUserTypeSubmitFormAction(uploadContext);
                        }
                        if (isHubMode() && featureIsEnabled('supervised-users')) {
                          return csvBulkSubmitFormAction();
                        }
                        if (isOTEReviewUploadFlow) {
                          return csvBulkReviewableSubmitFormAction(reviewableBulkUploadOptions);
                        }
                        return bulkSubmitFormAction();
                      };

                      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        reader.readAsArrayBuffer(selectedFile);
                      } else {
                        reader.readAsText(selectedFile);
                      }
                    } else {
                      resetFileAction();
                    }
                  }}
                  notSupportedMessage={CMS.not_supported_file_title}
                  notSupportedMessageLine2={
                    isHubMode() ? CMS.not_supported_file_subtitle_with_excel : CMS.not_supported_file_subtitle
                  }
                  retryMessage={CMS.try_again}
                />
                {isHubMode() ? (
                  <BulkUploadInformationBubble
                    headerTitleText={CMS.download_header_text}
                    downloadTemplateText={CMS.downloadTemplate_button_text}
                    downloadXLSTemplateText={CMS.downloadXLSTemplate_button_text}
                    downloadUserXLSTemplatePath={getTemplateDownloadPath(context)}
                    downloadUserXLSTemplateName={getTemplateName(context)}
                    downloadFileName={getFileName(context)}
                    filecontent={_bulkTemplateText(context, isEltAssessmentMode() || isTestCenter, isPlacementCentre)}
                    bodyText={_importText()}
                  />
                ) : (
                  <div>
                    {isOTEReviewUploadFlow ? (
                      <EnrolUserTemplatesAndInstructions options={reviewableBulkUploadOptions} />
                    ) : (
                      <BulkUploadInformationBubble
                        headerTitleText={CMS.download_header_text}
                        downloadTemplateText={CMS.downloadTemplate_button_text}
                        downloadFileName={getFileName(context)}
                        filecontent={_bulkTemplateText(
                          context,
                          isEltAssessmentMode() || isTestCenter,
                          isPlacementCentre
                        )}
                        bodyText={_importText()}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {isPrimarySchool && (
            <div className="grid">
              <Link to={administrationRightsSupportLink} className="gin-top2" target="_blank" role="button">
                {CMS.view_role_administration_rights}
              </Link>
            </div>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

EnrolUserBulkInputting.propTypes = {
  closePanel: PropTypes.func.isRequired,
  bulkSubmitFormAction: PropTypes.func.isRequired,
  csvBulkSubmitFormAction: PropTypes.func.isRequired,
  bulkByUserTypeSubmitFormAction: PropTypes.func,
  csvBulkReviewableSubmitFormAction: PropTypes.func,
  selectFileAction: PropTypes.func.isRequired,
  resetFileAction: PropTypes.func.isRequired,
  showEnrolUserFormAction: PropTypes.func.isRequired,
  uploadContext: PropTypes.shape({
    context: PropTypes.string.isRequired,
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    userTypeToEnrol: PropTypes.string.isRequired
  }).isRequired,
  administrationRightsSupportLink: PropTypes.string.isRequired,
  orgData: PropTypes.shape({
    orgRole: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    isPlacementCentre: PropTypes.bool.isRequired
  })
};
export default connect(null, {
  selectFileAction: selectFile,
  resetFileAction: resetFile,
  bulkSubmitFormAction: bulkSubmitForm,
  csvBulkSubmitFormAction: csvBulkSubmitForm,
  bulkByUserTypeSubmitFormAction: bulkByUserTypeSubmitForm,
  csvBulkReviewableSubmitFormAction: csvBulkReviewableSubmitForm,
  showEnrolUserFormAction: showEnrolUserForm
})(EnrolUserBulkInputting);
