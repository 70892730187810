/* eslint-disable react/no-danger */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@oup/shared-front-end';
import detectFileDragSupport from '../../utils/detectFileDragSupport';
import LegacyButton, { buttonTypes } from '../Button/Button';
import styles from './FileUploadInput.scss';
import { checkProductClear, checkProductExistsRequest } from '../../redux/actions/productUploadCheck';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ConfirmationBox from '../ConfirmationBox/ConfirmationBox';
import { SOURCES } from '../../globals/contentPreviewConstants.js';

const checkType = (file, supportedFileTypes) => supportedFileTypes.some(extension => file.name.endsWith(extension));

const INITIAL_STATE = {
  // dragEnabled is false on mobile browsers, showing only the standard file input
  dragEnabled: detectFileDragSupport(),
  // fileHovering is true when a file is dragged over the input, changing its style
  fileHovering: false,
  // selectedFile holds the used file while awaiting user confirmation
  selectedFile: null,
  // Error holds any error with the selectedFiles
  error: null,
  errorSubtitle: null,
  isWaitingForConfirmation: false,
  files: null,
  contentAlreadyExists: null,
  isLoadingCheckingRequest: false
};

/** This displayed a large area that a file can be dragged on to for upload */
class FileUploadInput extends Component {
  // Used to disable the dragOver event so that a file isn't opened in the browser window when dropped
  static stopDefault = event => event.preventDefault();

  constructor(props) {
    super(props);
    const { shouldHaveConfirmation } = this.props;
    this.state = {
      ...INITIAL_STATE,
      shouldHaveConfirmation: shouldHaveConfirmation ?? false
    };
  }

  static handleKeyDown = id => event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById(id).click();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { contentAlreadyExists, error, files } = this.state;
    const { contentAlreadyExists: contentAlreadyExistsProp, error: errorProp } = this.props;
    if (
      prevState.contentAlreadyExists &&
      prevProps.contentAlreadyExists &&
      contentAlreadyExists === null &&
      contentAlreadyExistsProp
    ) {
      return;
    }
    if (errorProp && errorProp !== error) {
      /* We make the call instead of updating the error inside the state so that
      the error message will be shown in the correct place rather than inside the file upload component */
      this.handleFiles(files);
    }
    if (contentAlreadyExistsProp !== contentAlreadyExists) {
      this.setState({
        contentAlreadyExists: contentAlreadyExistsProp,
        isWaitingForConfirmation: false
      });
    }
  }

  // On Drag Enter add the `fileHovering` class
  // This needs to be delayed using a `setTimeout` to prevent a Chrome bug which fires dragLeave straight after dragEnter
  onDragEnter = event => {
    event.stopPropagation();
    event.preventDefault();
    setTimeout(() => this.setState({ fileHovering: true }), 0);
  };

  // On Drag Leave remove the `fileHovering` class
  onDragLeave = () => this.setState({ fileHovering: false });

  // This event captures the dropped file
  onDrop = event => {
    event.preventDefault();
    this.setState({ fileHovering: false });

    const file = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const { shouldHaveConfirmation, isWaitingForConfirmation } = this.state;
    const { selectedPlatformCode, checkProductExistsRequestData } = this.props;
    if (selectedPlatformCode === SOURCES.ELTCORE && shouldHaveConfirmation && !isWaitingForConfirmation) {
      checkProductExistsRequestData(formData, selectedPlatformCode);
      this.setState({
        isWaitingForConfirmation: true,
        files: event.dataTransfer.files
      });
    } else {
      this.handleFiles(event.dataTransfer.files);
    }
  };

  // This function handles some possible error states or stores the selected file
  handleFiles = files => {
    const { supportedFileTypes, notSupportedMessage, notSupportedMessageLine2 } = this.props;

    if (files.length > 1) {
      // User selected multiple files
      this.setState({ error: 'Only select one file.', errorSubtitle: null, selectedFile: null });
    } else if (!checkType(files[0], supportedFileTypes)) {
      // Single file but it isn't in the list of supported filetypes
      this.setState({
        error: notSupportedMessage,
        errorSubtitle: notSupportedMessageLine2,
        selectedFile: null
      });
    } else {
      const { handleFile } = this.props;
      // Valid single file
      this.setState({ selectedFile: files[0], error: null, errorSubtitle: null });
      handleFile(files[0]);
    }
  };

  updateConfirmation = isConfirmed => {
    const { files } = this.state;
    const { checkProductClearData } = this.props;
    if (isConfirmed) {
      this.handleFiles(files);
    }
    this.setState(INITIAL_STATE);
    checkProductClearData();
  };

  render() {
    const { externalError } = this.props;
    const { error: stateError } = this.state;

    if (typeof externalError !== 'undefined' && externalError !== null) {
      if (stateError !== externalError.title) {
        this.setState({
          error: externalError.title,
          errorSubtitle: externalError.subtitle
        });
      }
    }

    const {
      id,
      label,
      subLabel,
      buttonText,
      handleFile,
      supportedFileTypes,
      orLabel,
      chooseDifferentMessage,
      retryMessage,
      defaultCustomClass,
      errorCustomClass,
      retry
    } = this.props;
    const { dragEnabled, fileHovering, selectedFile, error, errorSubtitle } = this.state;

    const className = classnames(styles.fileUploadInput, styles[defaultCustomClass], {
      [styles.dragEnabled]: dragEnabled,
      [styles.fileHovering]: fileHovering,
      [styles.fileSelected]: selectedFile
    });

    const renderContent = () => {
      const { isWaitingForConfirmation, contentAlreadyExists, files } = this.state;

      if (isWaitingForConfirmation) {
        return <LoadingSpinner />;
      }

      if (contentAlreadyExists === null) {
        return (
          <>
            {/* Note: IE seems to cope better when "accept" lists file extensions, not mimetypes */}
            <input
              id={id}
              className="a11y-hide"
              type="file"
              accept={supportedFileTypes.join(',')}
              onChange={event => {
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append('file', file);

                const { shouldHaveConfirmation, isWaitingForConfirmation: isWaitingForConfirmationState } = this.state;
                const { selectedPlatformCode, checkProductExistsRequestData } = this.props;
                if (
                  selectedPlatformCode === SOURCES.ELTCORE &&
                  shouldHaveConfirmation &&
                  !isWaitingForConfirmationState
                ) {
                  checkProductExistsRequestData(formData, selectedPlatformCode);
                  this.setState({
                    isWaitingForConfirmation: true,
                    files: event.target.files
                  });
                } else {
                  this.handleFiles(event.target.files);
                }
              }}
            />

            {/* These labels are only show if drag is supported */}
            {dragEnabled && (
              <div>
                <p>{label}</p>
                {subLabel && <p>{subLabel}</p>}
                <p>{orLabel}</p>
              </div>
            )}

            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label htmlFor={id} onKeyDown={FileUploadInput.handleKeyDown(id)}>
              {/* The button has a faked onClick because the surround label handles the click */}
              {id === 'certificateUploadInput' ? (
                <LegacyButton
                  id="fileUploadInput_selectFileBtn"
                  type={buttonTypes.SECONDARY}
                  text={buttonText}
                  onClick={() => {}}
                />
              ) : (
                <Button id="fileUploadInput_selectFileBtn" variant="outline" text={buttonText} />
              )}
            </label>
          </>
        );
      }
      if (contentAlreadyExists) {
        return <ConfirmationBox fileName={files[0]?.name} updateConfirmationFunc={this.updateConfirmation} />;
      }

      return this.updateConfirmation(true);
    };

    return (
      <div
        className={className}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onDragOver={FileUploadInput.stopDefault}
      >
        {/* This is the initial view of the input box */}
        {selectedFile === null && error === null && renderContent()}

        {/* This is the confirmation view of the input */}
        {selectedFile !== null && error === null && (
          <div>
            <p className={styles.label}>{selectedFile.name}</p>
            <button
              id="fileUploadInput_chooseDifferentFileBtn"
              type="button"
              className={styles.backButton}
              onClick={() => {
                this.setState({ selectedFile: null });
                handleFile();
              }}
            >
              {chooseDifferentMessage}
            </button>
          </div>
        )}

        {/* This is the error view of the input */}
        {error !== null && (
          <div className={styles[errorCustomClass]}>
            <p className={styles.label}>{error}</p>
            {errorSubtitle && <p dangerouslySetInnerHTML={{ __html: errorSubtitle }} />}
            <Button
              id="fileUploadInput_retryBtn"
              variant="transparent"
              text={retryMessage}
              onClick={() => {
                if (retry) {
                  retry();
                }
                this.setState({ error: null, errorSubtitle: null, selectedFile: null });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

FileUploadInput.propTypes = {
  /** The id of the input */
  id: PropTypes.string.isRequired,
  /** Label displayed inside the input box */
  label: PropTypes.string.isRequired,
  /** optional text below the label displayed inside the input box */
  subLabel: PropTypes.string,
  /** Action called when the file has been confirmed */
  handleFile: PropTypes.func.isRequired,
  /** Text to be displayed in the Button */
  buttonText: PropTypes.string.isRequired,
  /** Array of MIME type strings that are supported by the component */
  supportedFileTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  /** Optional labels */
  orLabel: PropTypes.string,
  notSupportedMessage: PropTypes.string,
  notSupportedMessageLine2: PropTypes.string,
  chooseDifferentMessage: PropTypes.string,
  retryMessage: PropTypes.string,
  externalError: PropTypes.object,
  error: PropTypes.object,
  retry: PropTypes.func,
  defaultCustomClass: PropTypes.string,
  errorCustomClass: PropTypes.string,
  shouldHaveConfirmation: PropTypes.bool,
  checkProductExistsRequestData: PropTypes.func,
  checkProductClearData: PropTypes.func,
  selectedPlatformCode: PropTypes.string,
  contentAlreadyExists: PropTypes.bool
};

FileUploadInput.defaultProps = {
  supportedFileTypes: ['.csv'],
  orLabel: 'or',
  notSupportedMessage: "That file isn't supported.",
  notSupportedMessageLine2: "That file isn't supported.",
  chooseDifferentMessage: 'Choose a different file',
  retryMessage: 'Retry'
};

const mapStateToProps = ({ productUploadCheckLogs: { contentAlreadyExists, error } }) => ({
  contentAlreadyExists,
  error
});

const mapDispatchToProps = {
  checkProductExistsRequestData: checkProductExistsRequest,
  checkProductClearData: checkProductClear
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadInput);
