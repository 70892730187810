import React from 'react';
import { useNavigate, useLocation, useParams, useMatch } from 'react-router-dom';

/**
 * In React Router v6, the withRouter HOC has been removed,
 * requiring us to use alternative hooks (useNavigate, useLocation, etc). Since we have many
 * class components, we need to maintain React Router functionality in these components
 * by creating this wrapper component that uses hooks and passes the necessary props to the
 * class component.
 * Once all class components are converted to functional components, we can remove this HOC.
 *
 * This is useful for class components or functional components that don't use hooks.
 *
 * @param {React.ComponentType} Component - The component to wrap.
 * @returns {React.ComponentType} - The wrapped component with router props.
 */
const withRouter = Component =>
  function WithRouterComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const match = useMatch(location.pathname);

    const matchObject = match
      ? {
          ...match,
          params,
          isExact: match.pathname === location.pathname,
          path: match.pattern.path,
          url: match.pathname
        }
      : null;

    const historyObject = {
      push: (path, state) => navigate(path, { state }),
      replace: (path, state) => navigate(path, { replace: true, state }),
      go: n => window.history.go(n),
      back: () => window.history.back(),
      forward: () => window.history.forward()
    };

    return <Component {...props} history={historyObject} location={location} params={params} match={matchObject} />;
  };

export default withRouter;
