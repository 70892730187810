// This is the MySchool > Organisations Tab only visible to ORG_ADMIN.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ORG_REGISTRATION_STATUS as registrationStatus } from '@oup/shared-node-browser/constants';

import withRouter from '../../../../utils/withRouter';
import compose from '../../../../utils/compose/compose.js';
import { resetForm } from '../../../../redux/reducers/data/forms.reducer';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import Table, { columnTypes, rowTypes } from '../../../../components/Table/Table';
import { SIZES as thumbnailSizes } from '../../../../components/Thumbnail/Thumbnail';
import ConnectedOrgRepresentation from '../../../../components/OrgRepresentation/ConnectedOrgRepresentation';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel';
import OrgArchivePanel from '../../panels/OrganisationArchive/OrgArchive';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import TimeTag from '../../../../components/TimeTag/TimeTag';
import { standardSortOptions } from '../../../../globals/searchFilters';
import {
  triggerSearch,
  setTerm,
  setSort,
  setPage,
  setFilter,
  setSearchBy
} from '../../../../redux/reducers/data/search.reducer';
import { setFieldValue, resetFields } from '../../../../redux/reducers/data/fields.reducer';
import { roleCanArchiveOrg } from '../../../../globals/userRoles';
import { showClosePanelModal } from '../../../../redux/reducers/organisationPage.reducer';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import StatusIcon, { StatusType } from '../../../../components/StatusIcon/StatusIcon';
import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';

class OrganisationsTab extends Component {
  componentWillReceiveProps(nextProps) {
    const { defaultState, setSearchByAction } = this.props;
    if (defaultState === true && nextProps.searchBy === 'oupOrgsEmail' && !nextProps.loadingResults) {
      setSearchByAction('oupOrgs');
    }
  }

  setSortActionFunc = sort => {
    const { searchBy, setSortAction } = this.props;
    setSortAction(searchBy, sort[0]);
  };

  setPageActionFunc = page => {
    const { searchBy, setPageAction } = this.props;
    setPageAction(searchBy, page);
  };

  setFilterActionFunc = (valueName, value) => {
    const { searchBy, setFilterAction } = this.props;
    setFilterAction(searchBy, valueName, value);
  };

  setSearchTermFunc = value => {
    const { setSearchTerm } = this.props;
    setSearchTerm('oupOrgs', value);
    setSearchTerm('oupOrgsEmail', value);
  };

  triggerSearchActionFunc = () => {
    const { searchBy, triggerSearchAction } = this.props;
    triggerSearchAction(searchBy);
  };

  searchInputOnKeyUp = event => {
    if (event.key === 'Enter') {
      const { searchBy, triggerSearchAction } = this.props;
      triggerSearchAction(searchBy);
    }
  };

  _getOrgStatus(org, isProcessing) {
    const {
      localizedContent: { mySchoolOrganisationTab: content }
    } = this.props;

    switch (true) {
      case isProcessing:
        return {
          type: StatusType.LOADING,
          title: content.processing_text
        };
      case org.archived:
        return {
          type: StatusType.ARCHIVED,
          title: content.archived_text
        };
      case org.registrationStatus === registrationStatus.PARTIALLY_REGISTERED:
        return {
          type: StatusType.PENDING,
          title: content.pending_text
        };
      default:
        return {
          type: StatusType.ACTIVE,
          title: `${content.active_text} ${content.org_text}`
        };
    }
  }

  render() {
    const {
      panelName,
      disabledIds,
      orgs,
      userRole,
      searchTerm,
      sort,
      page,
      filters,
      loadingResults,
      searchFailed,
      resultIds,
      totalResults,
      archiveFormState,
      setArchiveAction,
      history,
      orgsDataRecency,
      showUserEditModal,
      showUserEditModalAction,
      resetFieldsAction,
      resetFormAction,
      localizedContent: { mySchoolOrganisationTab: content, removeLearningMaterialModal: modalContent },
      searchBy,
      setSearchByAction
    } = this.props;

    const filterOptions = [
      {
        text: content.filter_active_organisations,
        id: 'searchFilter-by-active',
        name: 'searchFilter-by-active',
        value: 'active',
        checked: filters.active,
        onChange: this.setFilterActionFunc
      },
      {
        text: content.filter_archived_organisations,
        id: 'searchFilter-by-archived',
        name: 'searchFilter-by-archived',
        value: 'archived',
        checked: filters.archived,
        onChange: this.setFilterActionFunc
      },
      {
        text: content.filter_pending_organisations,
        id: 'searchFilter-by-pending',
        name: 'searchFilter-by-pending',
        value: 'partially_registered',
        checked: filters.partially_registered,
        onChange: this.setFilterActionFunc
      }
    ];

    return (
      <div>
        <ListPageControls
          idPrefix="oupOrgsSearch"
          searchInputLabel={content.search_organisations_label}
          searchInputPlaceholder={
            searchBy === 'oupOrgsEmail' ? content.search_organisations_by_useremail : content.search_organisations_label
          }
          searchInputValue={searchTerm}
          searchInputOnChange={this.setSearchTermFunc}
          searchInputOnSubmit={this.triggerSearchActionFunc}
          searchInputOnKeyUp={this.searchInputOnKeyUp}
          newButtonText={content.button_add_organisation}
          newButtonTo="/org/register"
          filterOptions={filterOptions}
          sortOnChange={this.setSortActionFunc}
          sortOptions={standardSortOptions(sort)}
          ariaControls="searchResults"
          loading={orgsDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={orgsDataRecency.synced}
          loadedMessage={content.loaded_message}
          dropdownProps={{
            id: 'orgSearchBy',
            label: content.search_dropdown_option_label,
            options: [
              { value: 'oupOrgs', text: content.search_by_org },
              { value: 'oupOrgsEmail', text: content.search_by_useremail }
            ],
            value: searchBy,
            onChange: setSearchByAction
          }}
        />

        {loadingResults && (
          <div className="text-center">
            <div style={{ width: '5rem', margin: '2rem auto' }}>
              <SVGIcon glyph={GLYPHS.ICON_LOADING} />
            </div>
          </div>
        )}

        {searchFailed && (
          <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="text-center">
            <div style={{ width: '5rem', margin: '2rem auto 0' }}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
            </div>
            <p className="gin2">{content.search_results_error_text}</p>
            <Button id="search_retry" text="Retry" onClick={this.triggerSearchActionFunc} />
          </div>
        )}

        {!loadingResults && !searchFailed && resultIds.length === 0 && (
          <div className="grid">
            <div className="row">
              <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                <p id="searchResults-orgs-noResults" className="gin-top1 gin-bot1">
                  {content.no_search_results_text}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="grid">
          <div className="row">
            <div
              id="searchResults"
              className="col"
              role="region"
              aria-live="polite"
              aria-atomic="true"
              aria-label="Organisations list"
            >
              {resultIds.length > 0 && (
                <div className="gin-top2">
                  <Table
                    columns={
                      roleCanArchiveOrg(userRole)
                        ? [
                            { heading: content.heading_organisations_text },
                            { heading: content.heading_status_text },
                            { heading: content.heading_created_text },
                            { heading: content.heading_archive_text, type: columnTypes.BUTTON }
                          ]
                        : [
                            { heading: content.heading_organisations_text },
                            { heading: content.heading_status_text },
                            { heading: content.heading_created_text }
                          ]
                    }
                    rows={resultIds.map(id => {
                      const org = orgs[id] || {};
                      const isProcessing = orgsDataRecency.syncing && orgsDataRecency.ids.includes(id);
                      const cells = [
                        <div key={0}>
                          <ConnectedOrgRepresentation
                            id={`searchResults-item-${id}`}
                            entityId={id}
                            linkIdPrefix="linkToOrg"
                            linkTo={`/org/${id}`}
                            thumbnailSize={thumbnailSizes.TABLE}
                            deletedStyle={org.archived}
                          />
                        </div>,

                        <StatusIcon
                          key={1}
                          id={`searchResults-archive-status-${id}`}
                          {...this._getOrgStatus(org, isProcessing)}
                        />,
                        <div key={2} id={`searchResults-date-${id}`}>
                          <TimeTag dateString={org.createdDate} />
                        </div>
                      ];

                      if (roleCanArchiveOrg(userRole)) {
                        cells.push(
                          <Button
                            key={3}
                            id={`searchResults-button-archive-${id}`}
                            text={`${content.button_archive_text}${' '}${org.name}`}
                            title={`${content.button_archive_text}${' '}${org.name}`}
                            iconOnly
                            onClick={() => setArchiveAction(id)}
                            type={buttonTypes.NO_BORDER}
                            glyph={GLYPHS.ICON_REMOVE}
                            disabled={disabledIds.includes(id)}
                            preventDefault={false}
                            to={safePanelLink('archiveOrg')}
                          />
                        );
                      }

                      return {
                        id: `org${id}`,
                        cells,
                        ...(isProcessing ? { type: rowTypes.PROCESSING } : {})
                      };
                    })}
                  />
                </div>
              )}

              {totalResults > 10 && (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="oupOrgsSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={this.setPageActionFunc}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {roleCanArchiveOrg(userRole) && (
          <PopoutPanel
            id="archiveOrgPopout"
            ariaLabel={content.aria_label_archive_organisation}
            isOpen={panelName === 'archiveOrg'}
          >
            <OrgArchivePanel
              closePanel={() => {
                if (archiveFormState === 'CONFIRMATION') {
                  history.push(safePanelUnlink('archiveOrg'));
                  setTimeout(() => {
                    resetFormAction('orgArchive', 'INPUTTING');
                    resetFieldsAction('orgArchive', ['userConcent', 'emailNotify']);
                  }, 300);
                } else {
                  showUserEditModalAction(true);
                }
              }}
            />
          </PopoutPanel>
        )}
        {showUserEditModal && (
          <ConfirmationModal
            title={modalContent.title}
            body={modalContent.body}
            positiveClickText={modalContent.positiveClickText}
            negativeClickText={modalContent.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              showUserEditModalAction(false);
              history.push(safePanelUnlink('archiveOrg'));
              setTimeout(() => {
                resetFormAction('orgArchive', 'INPUTTING');
                resetFieldsAction('orgArchive', ['userConcent', 'emailNotify']);
              }, 300);
            }}
            negativeClick={() => showUserEditModalAction(false)}
          />
        )}
      </div>
    );
  }
}

OrganisationsTab.propTypes = {
  panelName: PropTypes.string,
  disabledIds: PropTypes.array.isRequired,
  orgs: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  searchFailed: PropTypes.bool.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  archiveFormState: PropTypes.string,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setArchiveAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  orgsDataRecency: PropTypes.object.isRequired,
  showUserEditModal: PropTypes.bool,
  showUserEditModalAction: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired,
  resetFieldsAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  setSearchByAction: PropTypes.object.isRequired,
  searchBy: PropTypes.string.isRequired,
  defaultState: PropTypes.bool
};

export default compose(
  withRouter,
  withLocalizedContent('mySchoolOrganisationTab', 'removeLearningMaterialModal'),
  withDataRecency('orgs'),
  withSearchInitialiser({
    searchSource: 'oupOrgs',
    initialFilters: {
      active: true,
      partially_registered: true
    }
  }),
  withSearchInitialiser({
    searchSource: 'oupOrgsEmail',
    initialFilters: {
      active: true,
      partially_registered: true
    }
  }),
  connect(
    state => ({
      userRole: state.identity.role,
      orgs: state.organisations.data,
      disabledIds: state.search[state.search.instance]?.ids?.filter(
        id => state.organisations.data[id] && state.organisations.data[id].archived
      ),
      searchTerm: state.search[state.search.instance]?.term,
      sort: state.search[state.search.instance]?.sort,
      page: state.search[state.search.instance]?.page,
      filters: state.search[state.search.instance]?.filters,
      loadingResults: state.search[state.search.instance]?.loading,
      searchFailed: state.search[state.search.instance]?.error,
      resultIds: state.search[state.search.instance]?.ids || [],
      totalResults: state.search[state.search.instance]?.totalResults,
      showUserEditModal: state.organisationPage.showModal,
      archiveFormState: (state.forms.orgArchive || {}).formState,
      searchBy: state.search.instance,
      defaultState: state.search.defaultState
    }),
    {
      setSearchTerm: setTerm,
      setSortAction: setSort,
      setPageAction: setPage,
      setFilterAction: setFilter,
      setArchiveAction: orgId => setFieldValue('orgArchive', 'orgId', orgId),
      triggerSearchAction: triggerSearch,
      showUserEditModalAction: showClosePanelModal,
      resetFormAction: resetForm,
      resetFieldsAction: resetFields,
      setSearchByAction: setSearchBy
    }
  )
)(OrganisationsTab);
