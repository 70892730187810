import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import compose from '../../../../utils/compose/compose.js';

import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function CourseStagePage({ localizedContent: { courseStagePage: content } }) {
  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      <h1>{content.title}</h1>
    </PageWrapper>
  );
}

CourseStagePage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('courseStagePage'))(CourseStagePage);
