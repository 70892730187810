import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

import { Button } from '@oup/shared-front-end';
import cmsContent from '../../utils/cmsContent.js';
import compose from '../../utils/compose/compose.js';
import { ErrorStatus, PageWrapper, PopoutPanelIconHeading } from '../../components/index.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import actions from '../../redux/actions';
import { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

function ReCAPTCHAExample({ reset, submit, submitting, failure, success, bot, errors, googleReCaptchaProps }) {
  const [token, setToken] = useState('');

  const CMS = cmsContent.reCAPTCHAExample || {};

  const loadReCAPTCHAToken = async () => {
    const tokenResponse = await googleReCaptchaProps.executeRecaptcha('test');
    setToken(tokenResponse);
  };
  const onSubmitHandler = () => {
    submit(token);
  };

  return (
    <PageWrapper>
      <PageHeading title={CMS.page_title} />

      {(() => {
        switch (true) {
          case submitting:
            return <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading} />;
          case bot:
            return (
              <div>
                <div className="pad-bot2 pad-top1">{CMS.bot}</div>
                <Button
                  size="small"
                  text={CMS.reset_button}
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
            );
          case success:
            return (
              <div>
                <div className="pad-bot2 pad-top1">{CMS.not_a_bot}</div>
                <Button
                  size="medium"
                  text={CMS.reset_button}
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
            );
          case failure:
            return (
              <ErrorStatus
                title={CMS.error_title}
                subtitle={errors?.errorMessages}
                buttonText={CMS.reset_button}
                buttonOnClickHandler={() => {
                  reset();
                }}
              />
            );
          default:
            return (
              <div>
                <div className="pad-bot2 pad-top1">
                  <Button size="medium" text={CMS.load_text} onClick={loadReCAPTCHAToken} />
                </div>
                <textarea readOnly value={token} rows={30} cols={100} />
                <p className="pad-top2">{CMS.description}</p>
                <div className="pad-top1">
                  <Button disabled={!token} size="medium" text={CMS.button_text} onClick={onSubmitHandler} />
                </div>
              </div>
            );
        }
      })()}
    </PageWrapper>
  );
}

ReCAPTCHAExample.propTypes = {
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  googleReCaptchaProps: PropTypes.object.isRequired,
  bot: PropTypes.bool.isRequired,
  errors: PropTypes.string.isRequired
};

export default compose(
  withLocalizedContent('reCAPTCHAExample'),
  connect(
    state => {
      const {
        reCAPTCHA: { submitting, success, failure, bot, errors }
      } = state;

      return { submitting, success, failure, bot, errors };
    },
    dispatch => ({
      submit: input => {
        dispatch(actions.reCAPTCHASubmit(input));
      },
      reset: () => {
        dispatch(actions.reCAPTCHAReset());
      }
    })
  )
)(withGoogleReCaptcha(ReCAPTCHAExample));
