import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
// Redux
// Utils
import withLocalizedContent from '../../language/withLocalizedContent';
import getAssessmentParameters from '../../utils/getAssessmentParameters';
import { onlineTestCancelTitle } from '../../utils/onlineTestUtils';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
// Components
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ActionList, { Action } from '../ActionList/ActionList';

function OnlineTestCancelSuccess({
  test,
  assessmentTitle,
  next,
  localizedContent: { hubSetTestOnline: content },
  classroom,
  selectedStudentIds,
  totalNumberOfStudents,
  isCancelPanelFlow = false
}) {
  const parametersToShow = getAssessmentParameters(
    classroom,
    test,
    selectedStudentIds,
    totalNumberOfStudents,
    isCancelPanelFlow
  );

  const testTitle = getTestTitle(test);

  return (
    <ScrollContainer
      footerContent={
        <ActionList>
          <Action primary label={content.done} onClick={() => next()} />
        </ActionList>
      }
    >
      <PopoutPanelIllustrationHeading
        title={
          test &&
          `${onlineTestCancelTitle(assessmentTitle, testTitle, content, parametersToShow)} ${
            content.online_test_has_been_cancelled
          }`
        }
        illustrationSrc={HubIllustrationConstants.SUCCESS}
        illustrationAltText={HubIllustrationAltText.SUCCESS}
      />
    </ScrollContainer>
  );
}

OnlineTestCancelSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  classroom: PropTypes.object,
  totalNumberOfStudents: PropTypes.array,
  selectedStudentIds: PropTypes.array,
  isCancelPanelFlow: PropTypes.bool
};

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(({ onlineTest, classrooms, search }) => {
    const classroom = classrooms ? classrooms.data[onlineTest.classId] : {};
    return {
      classroom: classroom || {},
      classId: onlineTest.classId,
      totalNumberOfStudents: search.userSelection.totalResults || [],
      selectedStudentIds: onlineTest.selectedStudentIds || []
    };
  })
)(OnlineTestCancelSuccess);
