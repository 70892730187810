import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import Button, { buttonTypes } from '../../../components/Button/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import PageHeading from '../../../components/PageHeading/PageHeading';
import styles from '../ProductUpload/ProductUpload.scss';
import { startProductPublishRequest } from '../../../redux/reducers/productPublish.reducer';
import { featureIsEnabled } from '../../../globals/envSettings';

class ProductPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlatformCode: 'noSelection'
    };
  }

  _onSubmit = () => {
    const { startProductPublishRequestAction } = this.props;
    const { selectedPlatformCode } = this.state;

    startProductPublishRequestAction(selectedPlatformCode);
  };

  _onDropDownChange = value => {
    this.setState({
      selectedPlatformCode: value
    });
  };

  render() {
    const { content } = this.props;
    const { selectedPlatformCode } = this.state;
    const publishProductOptions = [
      { text: 'Please select', value: 'noSelection' },
      { text: PLATFORMS.OLB, value: PLATFORMS.OLB }
    ];
    const canPublishContentOnThisEnv = !featureIsEnabled('prevent-publishing-content');

    if (featureIsEnabled('vst-product-upload')) {
      publishProductOptions.push({ text: PLATFORMS.VST, value: PLATFORMS.VST });
    }
    if (featureIsEnabled('cba-product-upload')) {
      publishProductOptions.push({ text: PLATFORMS.CBA, value: PLATFORMS.CBA });
    }
    if (featureIsEnabled('insp-product-upload')) {
      publishProductOptions.push({ text: PLATFORMS.INSP, value: PLATFORMS.INSP });
    }
    if (canPublishContentOnThisEnv) {
      publishProductOptions.push({ text: PLATFORMS.ELTCORE, value: PLATFORMS.ELTCORE });
    }

    return (
      <div>
        <PageHeading title={content.title} />

        <div className={styles.cardContainer}>
          {content.subtitle}
          <Dropdown
            id="productCodeDropdown"
            name="productCodeDropdown"
            label="Product Code"
            onChange={this._onDropDownChange}
            options={publishProductOptions}
          />

          <Button
            id="publishButton"
            type={buttonTypes.PRIMARY}
            text={content.submitButton}
            customClassName={styles.uploadButton}
            disabled={selectedPlatformCode === 'noSelection'}
            onClick={this._onSubmit}
          />
        </div>
      </div>
    );
  }
}

ProductPublish.propTypes = {
  content: PropTypes.object.isRequired,
  startProductPublishRequestAction: PropTypes.func
};

export default connect(null, {
  startProductPublishRequestAction: startProductPublishRequest
})(ProductPublish);
