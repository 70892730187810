export const SET_FIELD_VALUE = 'fields/SET_FIELD_VALUE';
export const RESET_FIELDS = 'fields/RESET_FIELDS';

export const INITIALISE_INSTANCE = 'search/INITIALISE_INSTANCE';
export const SET_ERROR = 'search/SET_ERROR';
export const SET_FILTER = 'search/SET_FILTER';
export const SET_FILTER_OPTIONS = 'search/SET_FILTER_OPTIONS';
export const SET_PAGE = 'search/SET_PAGE';
export const SET_RESULTS = 'search/SET_RESULTS';
export const SET_REVEAL = 'search/SET_REVEAL';
export const SET_SEARCH_TIMESTAMP = 'search/SET_SEARCH_TIMESTAMP';
export const SET_SORT = 'search/SET_SORT';
export const SET_TERM = 'search/SET_TERM';
export const TRIGGER_SEARCH = 'search/TRIGGER_SEARCH';
export const PREPARE_SEARCH = 'search/PREPARE_SEARCH';
export const END_SEARCH = 'search/END_SEARCH';
export const WARM_UP_SEARCH = 'search/WARM_UP_SEARCH';
// new actions created for pagination, filter, sorting, refresh in Front_End
export const SET_PAGE_ORG_STUDENT = 'search/SET_PAGE_ORG_STUDENT';
export const SET_PAGE_ORG_CLASS = 'search/SET_PAGE_ORG_CLASS';
export const SET_SORT_ORG_STUDENT = 'search/SET_SORT_ORG_STUDENT';
export const SET_SORT_ORG_CLASS = 'search/SET_SORT_ORG_CLASS';
export const SET_FILTER_ORG_STUDENT = 'search/SET_FILTER_ORG_STUDENT';
export const SET_RESULTS_ORG_STUDENT = 'search/SET_RESULTS_ORG_STUDENT';
export const SET_RESULTS_PLACEMENT_TEST_STUDENTS = 'search/SET_RESULTS_PLACEMENT_TEST_STUDENTS';
export const SET_FILTER_ORG_CLASS = 'search/SET_FILTER_ORG_CLASS';
export const SET_FILTERS_ORG_CLASS = 'search/SET_FILTERS_ORG_CLASS';
export const SET_RESULTS_CLASS = 'search/SET_RESULTS_CLASS';
export const ADD_RESULTS_ORG_CLASS = 'search/ADD_RESULTS_ORG_CLASS';
export const TRIGGER_SEARCH_ORG_STUDENT = 'search/TRIGGER_SEARCH_ORG_STUDENT';

export const GET_ARTICLES = 'supportSearch/GET_ARTICLES';
export const RESET_SEARCH_FORM = 'supportSearch/RESET_FORM';
export const SET_SEARCH_RESULTS = 'supportSearch/SET_SEARCH_RESULTS';
export const SET_SEARCH_TERM = 'supportSearch/SET_SEARCH_TERM';
export const SUBMIT_FORM = 'supportSearch/SUBMIT_FORM';

export const RESET_FORM = 'forms/RESET_FORM';
export const SET_FIELD_VALIDITY = 'forms/SET_FIELD_VALIDITY';
export const SET_FORM_RESULT = 'forms/SET_FORM_RESULT';
export const SET_FORM_STATE = 'forms/SET_FORM_STATE';

export const GENERATE_ORG_UNIQUE_ID_REQUEST = 'orb/GENERATE_ORG_UNIQUE_ID_REQUEST';
export const GENERATE_ORG_UNIQUE_ID_SUCCESS = 'orb/GENERATE_ORG_UNIQUE_ID_SUCCESS';
export const GENERATE_ORG_UNIQUE_ID_FAILURE = 'orb/GENERATE_ORG_UNIQUE_ID_FAILURE';

export const VALIDATE_ORG_ADMIN_INPUT = 'orb/VALIDATE_ORG_ADMIN_INPUT';
export const VALIDATE_ORG_ADMIN_COMPLETED = 'orb/VALIDATE_ORG_ADMIN_COMPLETED';
export const VALIDATE_ORG_ADMIN_ERROR = 'orb/VALIDATE_ORG_ADMIN_ERROR';
export const STORE_ORG_ADMIN_INPUT_EMAIL_AVAILABILITY = 'orb/STORE_ORG_ADMIN_INPUT_EMAIL_AVAILABILITY';
export const STORE_ORG_ADMIN_INPUT_EMAIL_LOADING = 'orb/STORE_ORG_ADMIN_INPUT_EMAIL_LOADING';
export const GET_PARTIAL_ORG_REQUEST = 'orb/GET_PARTIAL_ORG_REQUEST';
export const GET_PARTIAL_ORG_SUCCESS = 'orb/GET_PARTIAL_ORG_SUCCESS';
export const GET_PARTIAL_ORG_FAILURE = 'orb/GET_PARTIAL_ORG_FAILURE';
export const GET_ORG_REQUEST = 'orb/GET_ORG_REQUEST';
export const GET_ORG_SUCCESS = 'orb/GET_ORG_SUCCESS';
export const GET_ORG_FAILURE = 'orb/GET_ORG_FAILURE';
export const GET_ORG_RESET = 'orb/GET_ORG_RESET';
export const REGISTER_ORG_ADMIN_REQUEST = 'orb/REGISTER_ORG_ADMIN_REQUEST';
export const REGISTER_ORG_ADMIN_SUBMITTING = 'REGISTER_ORG_ADMIN_SUBMITTING';
export const REGISTER_ORG_ADMIN_SUCCESS = 'orb/REGISTER_ORG_ADMIN_SUCCESS';
export const REGISTER_ORG_ADMIN_FAILURE = 'orb/REGISTER_ORG_ADMIN_FAILURE';
export const REGISTER_ORG_ADMIN_FAILURE_CLEAR = 'orb/REGISTER_ORG_ADMIN_FAILURE_CLEAR';
export const REGISTER_ORG_ADMIN_RESET = 'orb/REGISTER_ORG_ADMIN_RESET';

export const VALIDATE_ORG_INPUT = 'orb/VALIDATE_ORG_INPUT';
export const VALIDATE_ORG_COMPLETED = 'orb/VALIDATE_ORG_COMPLETED';
export const VALIDATE_ORG_ERROR = 'orb/VALIDATE_ORG_ERROR';
export const REVIEW_ORG_INPUT = 'orb/REGISTER_ORG_INPUT';
export const REVIEW_ORG_COMPLETED = 'orb/REGISTER_ORG_COMPLETED';
export const REVIEW_ORG_ERROR = 'orb/REGISTER_ORG_ERROR';
export const CHANGE_ORG_INPUT = 'orb/CHANGE_ORG_INPUT';
export const REGISTER_ORG_REQUEST = 'orb/REGISTER_ORG_REQUEST';
export const REGISTER_ORG_SUCCESS = 'orb/REGISTER_ORG_SUCCESS';
export const REGISTER_ORG_FAILURE = 'orb/REGISTER_ORG_FAILURE';
export const REGISTER_ORG_FAILURE_CLEAR = 'orb/REGISTER_ORG_FAILURE_CLEAR';
export const REGISTER_ORG_RESET = 'orb/REGISTER_ORG_RESET';

export const EDIT_ORG_GET_DETAILS_REQUEST = 'orb/EDIT_ORG_GET_DETAILS_REQUEST';
export const EDIT_ORG_GET_DETAILS_SUCCESS = 'orb/EDIT_ORG_GET_DETAILS_SUCCESS';
export const EDIT_ORG_GET_DETAILS_FAILURE = 'orb/EDIT_ORG_GET_DETAILS_FAILURE';
export const EDIT_ORG_VALIDATE_INPUT = 'orb/EDIT_ORG_VALIDATE_INPUT';
export const EDIT_ORG_VALIDATE_COMPLETED = 'orb/EDIT_ORG_VALIDATE_COMPLETED';
export const EDIT_ORG_VALIDATE_ERROR = 'orb/EDIT_ORG_VALIDATE_ERROR';
export const EDIT_ORG_REQUEST = 'orb/EDIT_ORG_REQUEST';
export const EDIT_ORG_SUCCESS = 'orb/EDIT_ORG_SUCCESS';
export const EDIT_ORG_FAILURE = 'orb/EDIT_ORG_FAILURE';
export const EDIT_ORG_FAILURE_CLEAR = 'orb/EDIT_ORG_FAILURE_CLEAR';
export const EDIT_ORG_RESET = 'orb/EDIT_ORG_RESET';

export const PARSE_IMPORT_USERS_SOURCE_REQUEST = 'orb/PARSE_IMPORT_USERS_SOURCE_REQUEST';
export const PARSE_IMPORT_USERS_SOURCE_COMPLETED = 'orb/PARSE_IMPORT_USERS_SOURCE_COMPLETED';
export const PARSE_IMPORT_USERS_SOURCE_FAILURE = 'orb/PARSE_IMPORT_USERS_SOURCE_FAILURE';
export const PARSE_IMPORT_USERS_SOURCE_RETRY = 'orb/PARSE_IMPORT_USERS_SOURCE_RETRY';
export const UPDATE_IMPORT_USERS_REQUEST = 'orb/UPDATE_IMPORT_USERS_REQUEST';
export const STORE_IMPORT_USERS_UPDATES_REQUEST = 'orb/STORE_IMPORT_USERS_UPDATES_REQUEST';
export const DESTROY_IMPORT_USERS_REQUEST = 'orb/DESTROY_IMPORT_USERS_REQUEST';
export const REMOVE_IMPORT_USERS_REQUEST = 'orb/REMOVE_IMPORT_USERS_REQUEST';
export const CHECK_IMPORT_USERS_REQUEST = 'orb/CHECK_IMPORT_USERS_REQUEST';
export const CHECK_IMPORT_USERS_COMPLETED = 'orb/CHECK_IMPORT_USERS_COMPLETED';
export const UPLOAD_IMPORT_USERS_REQUEST = 'orb/UPLOAD_IMPORT_USERS_REQUEST';
export const UPLOAD_IMPORT_USERS_SUCCESS = 'orb/UPLOAD_IMPORT_USERS_SUCCESS';
export const UPLOAD_IMPORT_USERS_FAILURE = 'orb/UPLOAD_IMPORT_USERS_FAILURE';
export const VALIDATE_IMPORT_USERS_REQUEST = 'orb/VALIDATE_IMPORT_USERS_REQUEST';
export const VALIDATE_IMPORT_USERS_COMPLETED = 'orb/VALIDATE_IMPORT_USERS_COMPLETED';
export const VALIDATE_IMPORT_USERS_LIST_COMPLETED = 'orb/VALIDATE_IMPORT_USERS_LIST_COMPLETED';
export const STORE_UNSAVED_USERS = 'orb/STORE_UNSAVED_USERS';

export const LOGIN_MANAGED_USER_REQUEST = 'orb/LOGIN_MANAGED_USER_REQUEST';
export const LOGIN_MANAGED_USER_SUCCESS = 'orb/LOGIN_MANAGED_USER_SUCCESS';
export const LOGIN_MANAGED_USER_FAILURE = 'orb/LOGIN_MANAGED_USER_FAILURE';

export const SET_MANAGED_USER = 'orb/SET_MANAGED_USER';
export const MANAGED_USER_NAVIGATE_DOWNLOAD_CARD = 'orb/MANAGED_USER_NAVIGATE_DOWNLOAD_CARD';

export const VALIDATE_MANAGED_USER_LOGIN_INPUT = 'orb/VALIDATE_MANAGED_USER_LOGIN_INPUT';
export const VALIDATE_MANAGED_USER_LOGIN_COMPLETED = 'orb/VALIDATE_MANAGED_USER_LOGIN_COMPLETED';

export const VALIDATE_CHANGE_MANAGED_USER_PASSWORD_INPUT = 'orb/VALIDATE_CHANGE_MANAGED_USER_PASSWORD_INPUT';
export const VALIDATE_CHANGE_MANAGED_USER_PASSWORD_COMPLETED = 'orb/VALIDATE_CHANGE_MANAGED_USER_PASSWORD_COMPLETED';

export const GENERATE_MANAGED_USER_PASSWORD_REQUEST = 'orb/GENERATE_MANAGED_USER_PASSWORD_REQUEST';
export const GENERATE_MANAGED_USER_PASSWORD_SUCCESS = 'orb/GENERATE_MANAGED_USER_PASSWORD_SUCCESS';
export const GENERATE_MANAGED_USER_PASSWORD_FAILURE = 'orb/GENERATE_MANAGED_USER_PASSWORD_FAILURE';
export const CHANGE_MANAGED_USER_PASSWORD_REQUEST = 'orb/CHANGE_MANAGED_USER_PASSWORD_REQUEST';
export const CHANGE_MANAGED_USER_PASSWORD_SUCCESS = 'orb/CHANGE_MANAGED_USER_PASSWORD_SUCCESS';
export const CHANGE_MANAGED_USER_PASSWORD_FAILURE = 'orb/CHANGE_MANAGED_USER_PASSWORD_FAILURE';
export const CHANGE_MANAGED_USER_PASSWORD_CLEAR_FAILURE = 'orb/CHANGE_MANAGED_USER_PASSWORD_CLEAR_FAILURE';

export const GENERATE_USERNAME_FOR_MANAGED_USER_REQUEST = 'orb/GENERATE_USERNAME_FOR_MANAGED_USER_REQUEST';
export const GENERATE_USERNAME_FOR_MANAGED_USER_SUCCESS = 'orb/GENERATE_USERNAME_FOR_MANAGED_USER_SUCCESS';
export const GENERATE_USERNAME_FOR_MANAGED_USER_FAILURE = 'orb/GENERATE_USERNAME_FOR_MANAGED_USER_FAILURE';

export const MANAGED_USER_NAVIGATE_PROFILE = 'orb/MANAGED_USER_NAVIGATE_PROFILE';
export const MANAGED_USER_NAVIGATE_CHANGE_PASSWORD = 'orb/MANAGED_USER_NAVIGATE_CHANGE_PASSWORD';

export const MANAGED_USER_EDIT_SET_RECORD = 'orb/MANAGED_USER_EDIT_SET_RECORD';
export const MANAGED_USER_EDIT_VALIDATE = 'orb/MANAGED_USER_EDIT_VALIDATE';
export const MANAGED_USER_EDIT_VALIDATE_SET_RESULT = 'orb/MANAGED_USER_EDIT_VALIDATE_SET_RESULT';
export const MANAGED_USER_EDIT_PASSWORD = 'orb/MANAGED_USER_EDIT_PASSWORD';
export const MANAGED_USER_RESET_VALIDATION = 'orb/MANAGED_USER_RESET_VALIDATION';
export const MANAGED_USER_EDIT_SUBMIT = 'orb/MANAGED_USER_EDIT_SUBMIT';
export const MANAGED_USER_EDIT_SUCCESS = 'orb/MANAGED_USER_EDIT_SUCCESS';
export const MANAGED_USER_EDIT_FAILURE = 'orb/MANAGED_USER_EDIT_FAILURE';
export const MANAGED_USER_PASSWORD_FAILURE = 'orb/MANAGED_USER_PASSWORD_FAILURE';
export const MANAGED_USER_YEAR_GROUP_FAILURE = 'orb/MANAGED_USER_YEAR_GROUP_FAILURE';
export const MANAGED_USER_EDIT_RETRY = 'orb/MANAGED_USER_EDIT_RETRY';
export const MANAGED_USER_VALIDATE_USERNAME_GLOBAL = 'orb/MANAGED_USER_VALIDATE_USERNAME_GLOBAL';
export const MANAGED_USER_VALIDATE_USERNAME_GLOBAL_COMPLETE = 'orb/MANAGED_USER_VALIDATE_USERNAME_GLOBAL_COMPLETE';
export const MANAGED_USER_RESET_PASSWORD = 'orb/MANAGED_USER_RESET_PASSWORD';
export const MANAGED_USER_TRIGGER_BACK = 'orb/MANAGED_USER_TRIGGER_BACK';
export const PUT_MANAGED_USER_API = 'orb/PUT_MANAGED_USER_API';
export const SET_MANAGED_USER_EDITED_FIELD_STATE = 'orb/SET_MANAGED_USER_EDITED_FIELD_STATE';
export const SET_MANAGED_USER_EDITED_FORM_STATE = 'orb/SET_MANAGED_USER_EDITED_FORM_STATE';
export const SET_MANAGED_USER_EDITED_CLEAR_FORM_STATE = 'orb/SET_MANAGED_USER_EDITED_CLEAR_FORM_STATE';

export const MANAGED_USER_CHANGE_USERNAME_SET_RECORD = 'orb/MANAGED_USER_CHANGE_USERNAME_SET_RECORD';
export const MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME = 'orb/MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME';
export const MANAGED_USER_CHANGE_USERNAME_SET_VALIDATION_RESULT =
  'orb/MANAGED_USER_CHANGE_USERNAME_SET_VALIDATION_RESULT';
export const MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME_TAKEN =
  'orb/MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME_TAKEN';
export const MANAGED_USER_CHANGE_USERNAME_SET_USERNAME_TAKEN_RESULT =
  'orb/MANAGED_USER_CHANGE_USERNAME_SET_USERNAME_TAKEN_RESULT';
export const MANAGED_USER_CHANGE_USERNAME_SUBMIT = 'orb/MANAGED_USER_CHANGE_USERNAME_SUBMIT';
export const MANAGED_USER_CHANGE_USERNAME_SUCCESS = 'orb/MANAGED_USER_CHANGE_USERNAME_SUCCESS';
export const MANAGED_USER_CHANGE_USERNAME_FAILURE = 'orb/MANAGED_USER_CHANGE_USERNAME_FAILURE';
export const MANAGED_USER_CHANGE_USERNAME_RETRY = 'orb/MANAGED_USER_CHANGE_USERNAME_RETRY';

export const GENERATE_MANAGED_USER_SIGN_IN_CARD_REQUEST = 'orb/GENERATE_MANAGED_USER_SIGN_IN_CARD_REQUEST';
export const GENERATE_MANAGED_USER_SIGN_IN_CARD_SUCCESS = 'orb/GENERATE_MANAGED_USER_SIGN_IN_CARD_SUCCESS';
export const GENERATE_MANAGED_USER_SIGN_IN_CARD_FAILURE = 'orb/GENERATE_MANAGED_USER_SIGN_IN_CARD_FAILURE';

export const CREATE_ORG_SUBSCRIPTION_INPUT = 'orb/CREATE_ORG_SUBSCRIPTION_INPUT';
export const CREATE_ORG_SUBSCRIPTION_VALIDATE = 'orb/CREATE_ORG_SUBSCRIPTION_VALIDATE';
export const CREATE_ORG_SUBSCRIPTION_VALIDATED = 'orb/CREATE_ORG_SUBSCRIPTION_VALIDATED';
export const CREATE_ORG_SUBSCRIPTION_REQUEST = 'orb/CREATE_ORG_SUBSCRIPTION_REQUEST';
export const CREATE_ORG_SUBSCRIPTION_SUCCESS = 'orb/CREATE_ORG_SUBSCRIPTION_SUCCESS';
export const CREATE_ORG_SUBSCRIPTION_FAILURE = 'orb/CREATE_ORG_SUBSCRIPTION_FAILURE';
export const CREATE_ORG_SUBSCRIPTION_RESET = 'orb/CREATE_ORG_SUBSCRIPTION_RESET';
export const CREATE_ORG_SUBSCRIPTION_REPEAT = 'orb/CREATE_ORG_SUBSCRIPTION_REPEAT';

export const UPDATE_ORG_SUBSCRIPTION_REQUEST = 'orb/UPDATE_ORG_SUBSCRIPTION_REQUEST';
export const UPDATE_ORG_SUBSCRIPTION_SUCCESS = 'orb/UPDATE_ORG_SUBSCRIPTION_SUCCESS';
export const UPDATE_ORG_SUBSCRIPTION_FAILURE = 'orb/UPDATE_ORG_SUBSCRIPTION_FAILURE';
export const UPDATE_ORG_SUBSCRIPTION_CLEAR_FAILURE = 'orb/UPDATE_ORG_SUBSCRIPTION_CLEAR_FAILURE';
export const UPDATE_ORG_SUBSCRIPTION_RESET = 'orb/UPDATE_ORG_SUBSCRIPTION_RESET';

export const VALIDATE_CREATE_ORG_SUBSCRIPTION_INPUT = 'orb/VALIDATE_CREATE_ORG_SUBSCRIPTION_INPUT';
export const VALIDATE_CREATE_ORG_SUBSCRIPTION_INPUT_ERROR = 'orb/VALIDATE_CREATE_ORG_SUBSCRIPTION_INPUT_ERROR';
export const VALIDATE_CREATE_ORG_SUBSCRIPTION_COMPLETED = 'orb/VALIDATE_CREATE_ORG_SUBSCRIPTION_COMPLETED';
export const VALIDATE_UPDATE_ORG_SUBSCRIPTION_COMPLETED = 'orb/VALIDATE_UPDATE_ORG_SUBSCRIPTION_COMPLETED';
export const VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT_ERROR = 'orb/VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT_ERROR';
export const VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT = 'orb/VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT';
export const UPDATE_FORM_DATA = 'orb/UPDATE_FORM_DATA';

export const OIC_TEST_CREDITS_ORG_INPUT = 'oic/OIC_TEST_CREDITS_ORG_INPUT';
export const OIC_TEST_CREDITS_ORG_VALIDATE = 'oic/OIC_TEST_CREDITS_ORG_VALIDATE';
export const OIC_TEST_CREDITS_ORG_VALIDATED = 'oic/OIC_TEST_CREDITS_ORG_VALIDATED';
export const OIC_TEST_CREDITS_ORG_STATE = 'oic/OIC_TEST_CREDITS_ORG_STATE';

export const GET_TEST_CREDITS_PRODUCTS = 'oic/GET_TEST_CREDITS_PRODUCTS';

export const GET_OIC_TEST_CREDITS_REQUEST = 'oic/GET_OIC_TEST_CREDITS_REQUEST';
export const GET_OIC_TEST_CREDITS_SUCCESS = 'oic/GET_OIC_TEST_CREDITS_SUCCESS';
export const GET_OIC_TEST_CREDITS_EMPTY_SUCCESS = 'oic/GET_OIC_TEST_CREDITS_EMPTY_SUCCESS';
export const GET_OIC_TEST_CREDITS_FAILURE = 'oic/GET_OIC_TEST_CREDITS_FAILURE';

export const GET_TEST_CREDITS_REQUEST = 'testCredits/GET_TEST_CREDITS_REQUEST';
export const GET_TEST_CREDITS_SUCCESS = 'testCredits/GET_TEST_CREDITS_SUCCESS';
export const GET_TEST_CREDITS_EMPTY_SUCCESS = 'testCredits/GET_TEST_CREDITS_EMPTY_SUCCESS';
export const GET_TEST_CREDITS_FAILURE = 'testCredits/GET_TEST_CREDITS_FAILURE';

export const GET_LICENCE_CREDITS_PRODUCTS_REQUEST = 'licenceCreditsProducts/GET_LICENCE_CREDITS_PRODUCTS_REQUEST';
export const GET_LICENCE_CREDITS_PRODUCTS_SUCCESS = 'licenceCreditsProducts/GET_LICENCE_CREDITS_PRODUCTS_SUCCESS';
export const GET_LICENCE_CREDITS_PRODUCTS_FAILURE = 'licenceCreditsProducts/GET_LICENCE_CREDITS_PRODUCTS_FAILURE';

export const CREATE_OIC_TEST_CREDITS_INPUT = 'oic/CREATE_OIC_TEST_CREDITS_INPUT';
export const CREATE_OIC_TEST_CREDITS_REQUEST = 'oic/CREATE_OIC_TEST_CREDITS_REQUEST';
export const CREATE_OIC_TEST_CREDITS_SUCCESS = 'oic/CREATE_OIC_TEST_CREDITS_SUCCESS';
export const CREATE_OIC_TEST_CREDITS_FAILURE = 'oic/CREATE_OIC_TEST_CREDITS_FAILURE';

export const UPDATE_OIC_TEST_CREDITS_REQUEST = 'oic/UPDATE_OIC_TEST_CREDITS_REQUEST';
export const UPDATE_OIC_TEST_CREDITS_SUCCESS = 'oic/UPDATE_OIC_TEST_CREDITS_SUCCESS';
export const UPDATE_OIC_TEST_CREDITS_FAILURE = 'oic/UPDATE_OIC_TEST_CREDITS_FAILURE';

export const OIC_TEST_CREDITS_RESET = 'oic/OIC_TEST_CREDITS_RESET';

export const OIC_LINK_ORG_TO_JANISON_REQUEST = 'oic/OIC_LINK_ORG_TO_JANISON_REQUEST';
export const OIC_LINK_ORG_TO_JANISON_SUCCESS = 'oic/OIC_LINK_ORG_TO_JANISON_SUCCESS';
export const OIC_LINK_ORG_TO_JANISON_FAILURE = 'oic/OIC_LINK_ORG_TO_JANISON_FAILURE';

export const OPEN_EDIT_ACCOUNT_MODAL = 'OPEN_EDIT_ACCOUNT_MODAL';
export const CLOSE_EDIT_ACCOUNT_MODAL = 'CLOSE_EDIT_ACCOUNT_MODAL';

export const ENTITIES_SYNCED_REQUEST = 'ces/ENTITIES_SYNCED_REQUEST';
export const ENTITIES_SYNCED_SUCCESS = 'ces/ENTITIES_SYNCED_SUCCESS';
export const ENTITIES_SYNCED_TIMEOUT = 'ces/ENTITIES_SYNCED_TIMEOUT';
export const ENTITIES_SYNCED_FAILURE = 'ces/ENTITIES_SYNCED_FAILURE';
export const ENTITIES_SYNCED_RETRY = 'ces/ENTITIES_SYNCED_RETRY';
export const ENTITIES_SYNCED_CANCEL = 'ces/ENTITIES_SYNCED_CANCEL';
export const ENTITIES_SYNCED_FLUSH = 'ces/ENTITIES_SYNCED_FLUSH';

export const STORE_SUBSCRIPTIONS = 'ces/STORE_SUBSCRIPTIONS';

export const GET_PLATFORM_COUNTRY_CODES_REQUEST = 'orb/GET_PLATFORM_COUNTRY_CODES_REQUEST';
export const GET_PLATFORM_COUNTRY_CODES_SUCCESS = 'orb/GET_PLATFORM_COUNTRY_CODES_SUCCESS';

export const GRADEBOOK_ASSIGN_LEVEL = 'gradebook/GRADEBOOK_ASSIGN_LEVEL';
export const GRADEBOOK_LEVEL_UP = 'gradebook/GRADEBOOK_LEVEL_UP';
export const GRADEBOOK_LEVEL_DOWN = 'gradebook/GRADEBOOK_LEVEL_DOWN';
export const GRADEBOOK_RESET_LEVEL = 'gradebook/GRADEBOOK_RESET_LEVEL';
export const GRADEBOOK_DELAY_LEVEL_CHANGE = 'gradebook/GRADEBOOK_DELAY_LEVEL_CHANGE';

export const GRADEBOOK_ASSIGN_SELECTED_PRODUCT = 'gradebook/GRADEBOOK_ASSIGN_SELECTED_PRODUCT';

export const GRADEBOOK_MODIFY_STUDENT_FAILURE = 'gradebook/GRADEBOOK_MODIFY_STUDENT_FAILURE';
export const GRADEBOOK_MODIFY_STUDENT_SUCCESS = 'gradebook/GRADEBOOK_MODIFY_STUDENT_SUCCESS';

export const GRADEBOOK_CLASS_DIAL_REPORT_REQUEST = 'gradebook/GRADEBOOK_CLASS_DIAL_REPORT_REQUEST';
export const GRADEBOOK_CLASS_DIAL_REPORT_SUCCESS = 'gradebook/GRADEBOOK_CLASS_DIAL_REPORT_SUCCESS';
export const GRADEBOOK_CLASS_DIAL_REPORT_FAILURE = 'gradebook/GRADEBOOK_CLASS_DIAL_REPORT_FAILURE';

export const GRADEBOOK_CLASS_DIAL_REPORT_SUCCESS_V2 = 'gradebook/GRADEBOOK_CLASS_DIAL_REPORT_SUCCESS_V2';
export const GRADEBOOK_CLASS_DIAL_REPORT_FAILURE_V2 = 'gradebook/GRADEBOOK_CLASS_DIAL_REPORT_FAILURE_V2';

export const GRADEBOOK_SCHOOL_DIAL_REPORT_REQUEST = 'gradebook/GRADEBOOK_SCHOOL_DIAL_REPORT_REQUEST';
export const GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS = 'gradebook/GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS';
export const GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS_DEPRECATED =
  'gradebook/GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS_DEPRECATED'; // to de deleted after mat-report-change-for-reducers is enabled
export const GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE_DEPRECATED =
  'gradebook/GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE_DEPRECATED'; // to de deleted after mat-report-change-for-reducers is enabled
export const GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE = 'gradebook/GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE';
export const GRADEBOOK_SCHOOL_DIAL_REPORT_LOADING = 'gradebook/GRADEBOOK_SCHOOL_DIAL_REPORT_LOADING';

export const GRADEBOOK_CLASS_REPORT_REQUEST = 'gradebook/GRADEBOOK_CLASS_REPORT_REQUEST';
export const GRADEBOOK_CLASS_REPORT_SUCCESS = 'gradebook/GRADEBOOK_CLASS_REPORT_SUCCESS';
export const GRADEBOOK_CLASS_REPORT_FAILURE = 'gradebook/GRADEBOOK_CLASS_REPORT_FAILURE';
export const GRADEBOOK_CLASS_REPORT_LOADING = 'gradebook/GRADEBOOK_CLASS_REPORT_LOADING';

export const GRADEBOOK_SELECTED_PRODUCT_TYPE = 'gradebook/GRADEBOOK_SELECTED_PRODUCT_TYPE';

export const GRADEBOOK_SET_REPORTING = 'gradebook/GRADEBOOK_SET_REPORTING';

export const GRADED_READERS_RESULTS_REQUEST = 'gradebook/GRADED_READERS_RESULTS_REQUEST';
export const GRADED_READERS_RESULTS_FAILURE = 'gradebook/GRADED_READERS_RESULTS_FAILURE';
export const GRADED_READERS_RESULTS_SUCCESS = 'gradebook/GRADED_READERS_RESULTS_SUCCESS';
export const GRADED_READERS_RESULTS_LOADING = 'gradebook/GRADED_READERS_RESULTS_LOADING';

export const CONTACT_US_CHANGE_PRODUCT_SELECTION = 'ces/CONTACT_US_CHANGE_PRODUCT_SELECTION';
export const CONTACT_US_VALIDATE_INPUT = 'ces/CONTACT_US_VALIDATE_INPUT';
export const CONTACT_US_VALIDATE_COMPLETED = 'ces/CONTACT_US_VALIDATE_COMPLETED';
export const CONTACT_US_REQUEST = 'ces/CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'ces/CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'ces/CONTACT_US_FAILURE';

export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST = 'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST';
export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS = 'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS';
export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE = 'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE';

export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST_V2 = 'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST_V2';
export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS_V2 = 'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS_V2';
export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_V2 = 'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_V2';
export const GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_ADD_ORG_DETAILS =
  'orb/GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_ADD_ORG_DETAILS';

export const GRADEBOOK_GROUP_CLASS_REPORT_REQUEST = 'orb/GRADEBOOK_GROUP_CLASS_REPORT_REQUEST';
export const GRADEBOOK_GROUP_CLASS_REPORT_FAILURE = 'orb/GRADEBOOK_GROUP_CLASS_REPORT_FAILURE';
export const GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS = 'orb/GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS';

export const GRADEBOOK_GROUP_CLASS_REPORT_REQUEST_V2 = 'orb/GRADEBOOK_GROUP_CLASS_REPORT_REQUEST_V2';
export const GRADEBOOK_GROUP_CLASS_REPORT_FAILURE_V2 = 'orb/GRADEBOOK_GROUP_CLASS_REPORT_FAILURE_V2';
export const GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS_V2 = 'orb/GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS_V2';

export const GRADEBOOK_LEVEL_PANEL_REQUEST = 'orb/GRADEBOOK_LEVEL_PANEL_REQUEST';
export const GRADEBOOK_LEVEL_PANEL_ERROR = 'orb/GRADEBOOK_LEVEL_PANEL_ERROR';
export const GRADEBOOK_LEVEL_PANEL_SUCCESS = 'orb/GRADEBOOK_LEVEL_PANEL_SUCCESS';

export const GRADEBOOK_LEVEL_PANEL_REQUEST_V2 = 'orb/GRADEBOOK_LEVEL_PANEL_REQUEST_V2';
export const GRADEBOOK_LEVEL_PANEL_ERROR_V2 = 'orb/GRADEBOOK_LEVEL_PANEL_ERROR_V2';
export const GRADEBOOK_LEVEL_PANEL_SUCCESS_V2 = 'orb/GRADEBOOK_LEVEL_PANEL_SUCCESS_V2';

export const GET_CLASSROOMS_REQUEST = 'orb/GET_CLASSROOMS_REQUEST';
export const GET_CLASSROOMS_SUCCESS = 'orb/GET_CLASSROOMS_SUCCESS';
export const GET_CLASSROOMS_FAILURE = 'orb/GET_CLASSROOMS_FAILURE';

export const GET_CLASSROOMS_REQUEST_V2 = 'orb/GET_CLASSROOMS_REQUEST_V2';
export const GET_CLASSROOMS_SUCCESS_V2 = 'orb/GET_CLASSROOMS_SUCCESS_V2';
export const GET_CLASSROOMS_FAILURE_V2 = 'orb/GET_CLASSROOMS_FAILURE_V2';

export const EDIT_USER_GET_DETAILS_REQUEST = 'ces/EDIT_USER_GET_DETAILS_REQUEST';
export const EDIT_USER_GET_DETAILS_SUCCESS = 'ces/EDIT_USER_GET_DETAILS_SUCCESS';
export const EDIT_USER_GET_DETAILS_FAILURE = 'ces/EDIT_USER_GET_DETAILS_FAILURE';
export const EDIT_USER_CHECKING_USERNAME = 'ces/EDIT_USER_CHECKING_USERNAME';
export const EDIT_USER_CHECKING_USERNAME_COMPLETED = 'ces/EDIT_USER_CHECKING_USERNAME_COMPLETED';
export const EDIT_USER_CHECKING_EMAIL = 'ces/EDIT_USER_CHECKING_EMAIL';
export const EDIT_USER_CHECKING_EMAIL_COMPLETED = 'ces/EDIT_USER_CHECKING_EMAIL_COMPLETED';
export const EDIT_USER_VALIDATE_INPUT = 'ces/EDIT_USER_VALIDATE_INPUT';
export const EDIT_USER_VALIDATE_INPUT_COMPLETED = 'ces/EDIT_USER_VALIDATE_INPUT_COMPLETED';
export const EDIT_USER_VALIDATE_INPUT_ERROR = 'ces/EDIT_USER_VALIDATE_INPUT_ERROR';
export const EDIT_USER_IDENTITY_REQUEST = 'ces/EDIT_USER_IDENTITY_REQUEST';
export const EDIT_USER_AUTH_REQUEST = 'ces/EDIT_USER_AUTH_REQUEST';
export const EDIT_USER_IN_ORG_REQUEST = 'ces/EDIT_USER_IN_ORG_REQUEST';
export const EDIT_USER_SUCCESS = 'ces/EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'ces/EDIT_USER_FAILURE';
export const EDIT_USER_RESET = 'ces/EDIT_USER_RESET';
export const EDIT_USER_ACCEPT_OLB_TERMS = 'ces/EDIT_USER_ACCEPT_OLB_TERMS';
export const EDIT_USER_ACCEPT_OLB_TERMS_SUCCESS = 'ces/EDIT_USER_ACCEPT_OLB_TERMS_SUCCESS';
export const EDIT_USER_UNLINK_SOCIAL_ACCOUNTS = 'ces/EDIT_USER_UNLINK_SOCIAL_ACCOUNTS';
export const EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_PASS_FAILURE = 'ces/EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_PASS_FAILURE';
export const EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_SUBMITTING = 'ces/EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_SUBMITTING';
export const EDIT_USER_INITIAL_CONSENT = 'ces/EDIT_USER_INITIAL_CONSENT';
export const EDIT_USER_GET_SOCIAL_IDENTITIES_REQUEST = 'ces/EDIT_USER_GET_SOCIAL_IDENTITIES_REQUEST';
export const EDIT_USER_GET_SOCIAL_IDENTITIES_SUCCESS = 'ces/EDIT_USER_GET_SOCIAL_IDENTITIES_SUCCESS';

export const RESET_PASSWORD_VALIDATE_INPUT = 'ces/RESET_PASSWORD_VALIDATE_INPUT';
export const RESET_PASSWORD_VALIDATE_INPUT_COMPLETED = 'ces/RESET_PASSWORD_VALIDATE_INPUT_COMPLETED';
export const RESET_PASSWORD_REQUEST = 'ces/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'ces/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'ces/RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_RESET = 'ces/RESET_PASSWORD_RESET';

export const GET_ACTIVITY_REQUEST = 'orb/GET_ACTIVITY_REQUEST';
export const GET_ACTIVITY_SUCCESS = 'orb/GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAILURE = 'orb/GET_ACTIVITY_FAILURE';

export const USER_ENROL_VALIDATE_INPUT = 'ces/USER_ENROL_VALIDATE_INPUT';
export const USER_ENROL_VALIDATE_INPUT_COMPLETED = 'ces/USER_ENROL_VALIDATE_INPUT_COMPLETED';
export const USER_ENROL_VALIDATE_INPUT_SUCCESS = 'ces/USER_ENROL_VALIDATE_INPUT_SUCCESS';
export const USER_ENROL_CHECKING_USERNAME = 'ces/USER_ENROL_CHECKING_USERNAME';
export const USER_ENROL_CHECKING_USERNAME_COMPLETED = 'ces/USER_ENROL_CHECKING_USERNAME_COMPLETED';
export const USER_ENROL_ADD_TO_CLASS_VIEW = 'ces/USER_ENROL_ADD_TO_CLASS_VIEW';
export const USER_ENROL_ADD_TO_CLASS_VIEW_CONFIRMATION = 'ces/USER_ENROL_ADD_TO_CLASS_VIEW_CONFIRMATION';
export const USER_ENROL_REQUEST = 'ces/USER_ENROL_REQUEST';
export const USER_ENROL_SUCCESS = 'ces/USER_ENROL_SUCCESS';
export const USER_ENROL_FAILURE = 'ces/USER_ENROL_FAILURE';
export const USER_ENROL_RESET = 'ces/USER_ENROL_RESET';
export const USER_ENROL_DOWNLOAD_SIGN_IN_CARD = 'ces/USER_ENROL_DOWNLOAD_SIGN_IN_CARD';
export const USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SUCCESS = 'ces/USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SUCCESS';
export const USER_ENROL_DOWNLOAD_SIGN_IN_CARD_FAIL = 'ces/USER_ENROL_DOWNLOAD_SIGN_IN_CARD_FAIL';
export const USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SET_USER_DETAILS =
  'ces/USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SET_USER_DETAILS';

export const RESTORE_STUDENTS_SET = 'ces/RESTORE_STUDENTS_SET';
export const RESTORE_STUDENTS_REQUEST = 'ces/RESTORE_STUDENTS_REQUEST';
export const RESTORE_STUDENTS_SUCCESS = 'ces/SET_RESTORE_STUDENTS_SUCCESS';
export const RESTORE_STUDENTS_ERROR = 'ces/SET_RESTORE_STUDENTS_ERROR';
export const RESTORE_STUDENTS_RESET = 'ces/RESTORE_STUDENTS_RESET';
export const RESTORE_STUDENTS_SUBMITTING = 'ces/RESTORE_STUDENTS_SUBMITTING';

export const STUDENT_REPORT_REQUEST = 'orb/STUDENT_REPORT_REQUEST';
export const STUDENT_REPORT_FAILURE = 'orb/STUDENT_REPORT_FAILURE';

export const STUDENT_ATTAINMENT_REPORT_REQUEST = 'orb/STUDENT_ATTAINMENT_REPORT_REQUEST';
export const STUDENT_ATTAINMENT_REPORT_FAILURE = 'orb/STUDENT_ATTAINMENT_REPORT_FAILURE';
export const STUDENT_ATTAINMENT_REPORT_SUCCESS = 'orb/STUDENT_ATTAINMENT_REPORT_SUCCESS';

export const STUDENT_PROGRESS_REPORT_REQUEST = 'orb/STUDENT_PROGRESS_REPORT_REQUEST';
export const STUDENT_PROGRESS_REPORT_FAILURE = 'orb/STUDENT_PROGRESS_REPORT_FAILURE';
export const STUDENT_PROGRESS_REPORT_SUCCESS = 'orb/STUDENT_PROGRESS_REPORT_SUCCESS';

export const STUDENT_ENGAGEMENT_REPORT_REQUEST = 'orb/STUDENT_ENGAGEMENT_REPORT_REQUEST';
export const STUDENT_ENGAGEMENT_REPORT_FAILURE = 'orb/STUDENT_ENGAGEMENT_REPORT_FAILURE';
export const STUDENT_ENGAGEMENT_REPORT_SUCCESS = 'orb/STUDENT_ENGAGEMENT_REPORT_SUCCESS';

export const STUDENT_PROGRESS_ITEMS_REQUEST = 'orb/STUDENT_PROGRESS_ITEMS_REQUEST';
export const STUDENT_PROGRESS_ITEMS_FAILURE = 'orb/STUDENT_PROGRESS_ITEMS_FAILURE';
export const STUDENT_PROGRESS_ITEMS_SUCCESS = 'orb/STUDENT_PROGRESS_ITEMS_SUCCESS';

export const RESEND_INVITATION_REQUEST = 'ces/RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_SUCCESS = 'ces/RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAILURE = 'ces/RESEND_INVITATION_FAILURE';
export const RESEND_INVITATION_RESET = 'ces/RESEND_INVITATION_RESET';

export const GET_PRODUCTS_REQUEST = 'ces/GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'ces/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'ces/GET_PRODUCTS_FAILURE';

export const GET_ORG_PRODUCTS_REQUEST = 'ces/GET_ORG_PRODUCTS_REQUEST';
export const GET_ORG_PRODUCTS_SUCCESS = 'ces/GET_ORG_PRODUCTS_SUCCESS';

export const GET_USER_LICENCES_REQUEST = 'ces/GET_USER_LICENCES_REQUEST';
export const GET_USER_LICENCES_SUCCESS = 'ces/GET_USER_LICENCES_SUCCESS';
export const GET_USER_LICENCES_FAILURE = 'ces/GET_USER_LICENCES_FAILURE';
export const GET_USER_LICENCES_RESET = 'ces/GET_USER_LICENCES_RESET';

export const GET_USER_LICENCE_REQUEST = 'ces/GET_USER_LICENCE_REQUEST';
export const GET_USER_LICENCE_SUCCESS = 'ces/GET_USER_LICENCE_SUCCESS';
export const GET_USER_LICENCE_FAILURE = 'ces/GET_USER_LICENCE_FAILURE';
export const GET_USER_LICENCE_RESET = 'ces/GET_USER_LICENCE_RESET';

export const SEND_REMOVE_USER_LICENCE_REQUEST = 'ces/SEND_REMOVE_USER_LICENCE_REQUEST';
export const SEND_REMOVE_USER_LICENCE_SUCCESS = 'ces/SEND_REMOVE_USER_LICENCE_SUCCESS';
export const SEND_REMOVE_USER_LICENCE_FAILURE = 'ces/SEND_REMOVE_USER_LICENCE_FAILURE';
export const SEND_REMOVE_USER_LICENCE_RESET = 'ces/SEND_REMOVE_USER_LICENCE_RESET';

export const GET_DEFERRED_EMAILS_REQUEST = 'ces/GET_DEFERRED_EMAILS_REQUEST';
export const GET_DEFERRED_EMAILS_SUCCESS = 'ces/GET_DEFERRED_EMAILS_SUCCESS';
export const GET_DEFERRED_EMAILS_FAILURE = 'ces/GET_DEFERRED_EMAILS_FAILURE';

export const SEND_DEFERRED_EMAILS_REQUEST = 'ces/SEND_DEFERRED_EMAILS_REQUEST';
export const SEND_DEFERRED_EMAILS_SUCCESS = 'ces/SEND_DEFERRED_EMAILS_SUCCESS';
export const SEND_DEFERRED_EMAILS_FAILURE = 'ces/SEND_DEFERRED_EMAILS_FAILURE';
export const SEND_DEFERRED_EMAILS_RESET = 'ces/SEND_DEFERRED_EMAILS_RESET';

export const SEND_OIC_EMAILS_REQUEST = 'ces/SEND_OIC_EMAILS_REQUEST';
export const SEND_OIC_EMAILS_SUCCESS = 'ces/SEND_OIC_EMAILS_SUCCESS';
export const SEND_OIC_EMAILS_FAILURE = 'ces/SEND_OIC_EMAILS_FAILURE';
export const SEND_OIC_EMAILS_RESET = 'ces/SEND_OIC_EMAILS_RESET';

export const RESET_GB_PRODUCTS = 'ces/RESET_GB_PRODUCTS';
export const GET_GB_PRODUCTS_REQUEST = 'ces/GET_GB_PRODUCTS_REQUEST';
export const GET_GB_PRODUCTS_SUCCESS = 'ces/GET_GB_PRODUCTS_SUCCESS';
export const GET_GB_PRODUCTS_FAILURE = 'ces/GET_GB_PRODUCTS_FAILURE';

export const RESET_GET_PRODUCT_SERVICES = 'ces/RESET_GET_PRODUCT_SERVICES';
export const GET_PRODUCT_SERVICES_REQUEST = 'ces/GET_PRODUCT_SERVICES_REQUEST';
export const GET_PRODUCT_SERVICES_SUCCESS = 'ces/GET_PRODUCT_SERVICES_SUCCESS';
export const GET_PRODUCT_SERVICES_FAILURE = 'ces/GET_PRODUCT_SERVICES_FAILURE';

export const GOTO_MESSAGE_POST = 'ces/GOTO_MESSAGE_POST';
export const NAVIGATION_MESSAGE_POST = 'ces/NAVIGATION_MESSAGE_POST';
export const NOTIFICATION_MESSAGE_POST = 'ces/NOTIFICATION_MESSAGE_POST';

export const TEACHER_RESOURCES_REQUEST = 'tr/TEACHER_RESOURCES_REQUEST';
export const TEACHER_RESOURCES_SUCCESS = 'tr/TEACHER_RESOURCES_SUCCESS';
export const TEACHER_RESOURCES_FAILURE = 'tr/TEACHER_RESOURCES_FAILURE';
export const TEACHER_RESOURCE_REQUEST = 'tr/TEACHER_RESOURCE_REQUEST';
export const TEACHER_RESOURCE_SUCCESS = 'tr/TEACHER_RESOURCE_SUCCESS';
export const TEACHER_RESOURCE_FAILURE = 'tr/TEACHER_RESOURCE_FAILURE';
export const TEACHER_OPEN_CONTENT = 'tr/TEACHER_OPEN_CONTENT';
export const TEACHER_CLOSE_CONTENT = 'tr/TEACHER_CLOSE_CONTENT';

export const LOCKED_ACCOUNTS_REFRESH_REQUEST = 'ces/LOCKED_ACCOUNTS_REFRESH_REQUEST';
export const LOCKED_ACCOUNTS_REFRESH_RESPONSE = 'ces/LOCKED_ACCOUNTS_REFRESH_RESPONSE';

export const NGS_LIBRARY_REQUEST = 'ngs/LIBRARY_REQUEST';
export const NGS_LIBRARY_SUCCESS = 'ngs/LIBRARY_SUCCESS';
export const NGS_LIBRARY_FAILURE = 'ngs/LIBRARY_FAILURE';
export const NGS_UPDATE_MY_COURSES_REQUEST = 'ngs/UPDATE_MY_COURSES_REQUEST';
export const NGS_UPDATE_MY_COURSES_SUCCESS = 'ngs/UPDATE_MY_COURSES_SUCCESS';
export const NGS_UPDATE_MY_COURSES_FAILURE = 'ngs/UPDATE_MY_COURSES_FAILURE';

export const NGS_TEACHER_ASSIGNMENTS_REQUEST = 'ngs/TEACHER_ASSIGNMENTS_REQUEST';
export const NGS_TEACHER_ASSIGNMENTS_SUCCESS = 'ngs/TEACHER_ASSIGNMENTS_SUCCESS';
export const NGS_TEACHER_ASSIGNMENTS_FAILURE = 'ngs/TEACHER_ASSIGNMENTS_FAILURE';
export const NGS_ORG_CLASSES_AND_STUDENTS_REQUEST = 'ngs/NGS_ORG_CLASSES_AND_STUDENTS_REQUEST';
export const NGS_TEACHER_UPDATE_ASSIGNMENT_REQUEST = 'ngs/TEACHER_UPDATE_ASSIGNMENT_REQUEST';
export const NGS_TEACHER_DELETE_ASSIGNMENT_REQUEST = 'ngs/TEACHER_DELETE_ASSIGNMENT_REQUEST';
export const NGS_TEACHER_REPORTS_REQUEST = 'ngs/TEACHER_REPORTS_REQUEST';
export const NGS_TEACHER_REPORTS_SUCCESS = 'ngs/TEACHER_REPORTS_SUCCESS';
export const NGS_TEACHER_REPORTS_FAILURE = 'ngs/TEACHER_REPORTS_FAILURE';

export const NGS_STUDENT_HOMEWORK_REQUEST = 'ngs/STUDENT_HOMEWORK_REQUEST';
export const NGS_STUDENT_HOMEWORK_SUCCESS = 'ngs/STUDENT_HOMEWORK_SUCCESS';
export const NGS_STUDENT_HOMEWORK_FAILURE = 'ngs/STUDENT_HOMEWORK_FAILURE';
export const NGS_COURSE_HOMEWORK_REQUEST = 'ngs/COURSE_HOMEWORK_REQUEST';
export const NGS_COURSE_HOMEWORK_SUCCESS = 'ngs/COURSE_HOMEWORK_SUCCESS';
export const NGS_COURSE_HOMEWORK_FAILURE = 'ngs/COURSE_HOMEWORK_FAILURE';
export const NGS_STUDENT_MARKS_REQUEST = 'ngs/STUDENT_MARKS_REQUEST';
export const NGS_STUDENT_MARKS_SUCCESS = 'ngs/STUDENT_MARKS_SUCCESS';
export const NGS_STUDENT_MARKS_FAILURE = 'ngs/STUDENT_MARKS_FAILURE';

export const NGS_LOAD_COURSE = 'ngs/LOAD_COURSE';
export const NGS_UNLOAD_COURSE = 'ngs/UNLOAD_COURSE';
export const NGS_LOAD_COURSE_SUCCESS = 'ngs/LOAD_COURSE_SUCCESS';
export const NGS_LOAD_COURSE_FAILURE = 'ngs/LOAD_COURSE_FAILURE';
export const NGS_COURSE_BOOKS_REQUEST = 'ngs/COURSE_BOOKS_REQUEST';
export const NGS_COURSE_BOOKS_SUCCESS = 'ngs/COURSE_BOOKS_SUCCESS';
export const NGS_COURSE_BOOKS_FAILURE = 'ngs/COURSE_BOOKS_FAILURE';
export const NGS_LOAD_COURSE_BOOK = 'ngs/LOAD_COURSE_BOOK';
export const NGS_UNLOAD_COURSE_BOOK = 'ngs/UNLOAD_COURSE_BOOK';
export const NGS_LOAD_COURSE_BOOK_SUCCESS = 'ngs/LOAD_COURSE_BOOK_SUCCESS';
export const NGS_LOAD_COURSE_BOOK_FAILURE = 'ngs/LOAD_COURSE_BOOK_FAILURE';
export const NGS_LOAD_COURSE_RESOURCE = 'ngs/LOAD_COURSE_RESOURCE';
export const NGS_UNLOAD_COURSE_RESOURCE = 'ngs/UNLOAD_COURSE_RESOURCE';
export const NGS_LOAD_COURSE_RESOURCE_SUCCESS = 'ngs/LOAD_COURSE_RESOURCE_SUCCESS';
export const NGS_LOAD_COURSE_RESOURCE_FAILURE = 'ngs/LOAD_COURSE_RESOURCE_FAILURE';
export const NGS_STORE_RESOURCE_INTERACTIONS = 'ngs/STORE_RESOURCE_INTERACTIONS';
export const NGS_COURSE_STRUCTURE_REQUEST = 'ngs/COURSE_STRUCTURE_REQUEST';
export const NGS_COURSE_STRUCTURE_SUCCESS = 'ngs/COURSE_STRUCTURE_SUCCESS';
export const NGS_COURSE_STRUCTURE_FAILURE = 'ngs/COURSE_STRUCTURE_FAILURE';
export const NGS_COURSE_RESOURCES_REQUEST = 'ngs/COURSE_RESOURCES_REQUEST';
export const NGS_COURSE_RESOURCES_SUCCESS = 'ngs/COURSE_RESOURCES_SUCCESS';
export const NGS_COURSE_RESOURCES_FAILURE = 'ngs/COURSE_RESOURCES_FAILURE';
export const NGS_NEXT_PAGE_COURSE_RESOURCES_REQUEST = 'ngs/NEXT_PAGE_COURSE_RESOURCES_REQUEST';
export const NGS_NEXT_PAGE_COURSE_RESOURCES_SUCCESS = 'ngs/NEXT_PAGE_COURSE_RESOURCES_SUCCESS';
export const NGS_NEXT_PAGE_COURSE_RESOURCES_FAILURE = 'ngs/NEXT_PAGE_COURSE_RESOURCES_FAILURE';

export const NGS_NEXT_PAGE_BASKET_RESOURCES_REQUEST = 'ngs/NEXT_PAGE_BASKET_RESOURCES_REQUEST';
export const NGS_NEXT_PAGE_BASKET_RESOURCES_SUCCESS = 'ngs/NEXT_PAGE_BASKET_RESOURCES_SUCCESS';
export const NGS_NEXT_PAGE_BASKET_RESOURCES_FAILURE = 'ngs/NEXT_PAGE_BASKET_RESOURCES_FAILURE';

export const NGS_COURSE_REPORTS_REQUEST = 'ngs/COURSE_REPORTS_REQUEST';
export const NGS_COURSE_REPORTS_SUCCESS = 'ngs/COURSE_REPORTS_SUCCESS';
export const NGS_COURSE_REPORTS_FAILURE = 'ngs/COURSE_REPORTS_FAILURE';
export const NGS_COURSE_MARKS_REQUEST = 'ngs/COURSE_MARKS_REQUEST';
export const NGS_COURSE_MARKS_SUCCESS = 'ngs/COURSE_MARKS_SUCCESS';
export const NGS_COURSE_MARKS_FAILURE = 'ngs/COURSE_MARKS_FAILURE';
export const NGS_OPEN_CREATE_ASSIGNMENT_FORM = 'ngs/OPEN_CREATE_ASSIGNMENT_FORM';
export const NGS_CREATE_ASSIGNMENT_REQUEST = 'ngs/CREATE_ASSIGNMENT_REQUEST';
export const NGS_CREATE_ASSIGNMENT_SUCCESS = 'ngs/CREATE_ASSIGNMENT_SUCCESS';
export const NGS_CREATE_ASSIGNMENT_FAILURE = 'ngs/CREATE_ASSIGNMENT_FAILURE';

export const NGS_BASKET_RESOURCES_REQUEST = 'ngs/BASKET_RESOURCES_REQUEST';
export const NGS_BASKET_RESOURCES_SUCCESS = 'ngs/BASKET_RESOURCES_SUCCESS';
export const NGS_BASKET_RESOURCES_FAILURE = 'ngs/BASKET_RESOURCES_FAILURE';

export const NGS_RETRIEVE_LTI_CONSUMER_REQUEST = 'ngs/RETRIEVE_LTI_CONSUMER_REQUEST';
export const NGS_RETRIEVE_LTI_CONSUMER_SUCCESS = 'ngs/RETRIEVE_LTI_CONSUMER_SUCCESS';
export const NGS_RETRIEVE_LTI_CONSUMER_FAILURE = 'ngs/RETRIEVE_LTI_CONSUMER_FAILURE';

export const CHECK_ACTIVATION_CODE_REQUEST = 'activationCode/CHECK_ACTIVATION_CODE_REQUEST';
export const CHECK_ACTIVATION_CODE_REQUEST_SUCCESS = 'activationCode/CHECK_ACTIVATION_CODE_REQUEST_SUCCESS';
export const CHECK_ACTIVATION_CODE_REQUEST_FAILURE = 'activationCode/CHECK_ACTIVATION_CODE_REQUEST_FAILURE';
export const RESET_ACTIVATION_CODE = 'activationCode/RESET_ACTIVATION_CODE';
export const SET_ACTIVATION_CODE = 'activationCode/SET_ACTIVATION_CODE';
export const SET_VST_ACTIVATION_CODE = 'activationCode/SET_VST_ACTIVATION_CODE';
export const VALIDATE_ACTIVATION_CODE = 'activationCode/VALIDATE_ACTIVATION_CODE';
export const VALIDATE_VST_ACTIVATION_CODE = 'activationCode/VALIDATE_VST_ACTIVATION_CODE';
export const RESET_ERROR_MESSAGE_CODE = 'activationCode/RESET_ERROR_MESSAGE_CODE';
export const SET_GUEST_EMAIL_ID = 'activationCode/SET_GUEST_EMAIL_ID';
export const VALIDATE_GUEST_EMAIL_ID = 'activationCode/VALIDATE_GUEST_EMAIL_ID';
export const REDEEM_CODE_REQUEST = 'activationCode/REDEEM_CODE_REQUEST';
export const REDEEM_CODE_SUCCESS = 'activationCode/REDEEM_CODE_SUCCESS';
export const REDEEM_CODE_FAILURE = 'activationCode/REDEEM_CODE_FAILURE';

export const VALIDATE_ACCESS_CODE_REQUEST = 'reedeemCodeWizard/VALIDATE_ACCESS_CODE_REQUEST';
export const VALIDATE_ACCESS_CODE_SUCCESS = 'reedeemCodeWizard/VALIDATE_ACCESS_CODE_SUCCESS';
export const VALIDATE_ACCESS_CODE_FAILURE = 'reedeemCodeWizard/VALIDATE_ACCESS_CODE_FAILURE';
export const VALIDATE_ACCESS_CODE_RESET = 'redeemCodeWizard/VALIDATE_ACCESS_CODE_RESET';

// basket
export const NGS_UPDATE_ASSIGNMENT_REQUEST = 'ngs/NGS_UPDATE_ASSIGNMENT_REQUEST';
export const NGS_UPDATE_ASSIGNMENT_SUCCESS = 'ngs/NGS_UPDATE_ASSIGNMENT_SUCCESS';
export const NGS_UPDATE_ASSIGNMENT_FAILURE = 'ngs/NGS_UPDATE_ASSIGNMENT_FAILURE';

export const NGS_REMOVE_ASSIGNMENT_REQUEST = 'ngs/NGS_REMOVE_ASSIGNMENT_REQUEST';
export const NGS_REMOVE_ASSIGNMENT_SUCCESS = 'ngs/NGS_REMOVE_ASSIGNMENT_SUCCESS';
export const NGS_REMOVE_ASSIGNMENT_FAILURE = 'ngs/NGS_REMOVE_ASSIGNMENT_FAILURE';

export const NGS_GET_BASKET_ASSIGNMENTS_REQUEST = 'ngs/NGS_GET_BASKET_ASSIGNMENTS_REQUEST';
export const NGS_GET_BASKET_ASSIGNMENTS_SUCCESS = 'ngs/NGS_GET_BASKET_ASSIGNMENTS_SUCCESS';
export const NGS_GET_BASKET_ASSIGNMENTS_FAILURE = 'ngs/NGS_GET_BASKET_ASSIGNMENTS_FAILURE';

export const NGS_GET_CLASSES_AND_STUDENTS_REQUEST = 'ngs/NGS_GET_CLASSES_AND_STUDENTS_REQUEST';
export const NGS_GET_CLASSES_AND_STUDENTS_SUCCESS = 'ngs/NGS_GET_CLASSES_AND_STUDENTS_SUCCESS';
export const NGS_GET_CLASSES_AND_STUDENTS_FAILURE = 'ngs/NGS_GET_CLASSES_AND_STUDENTS_FAILURE';

export const ORB_USER_PREFERENCES_REQUEST = 'orb/USER_PREFERENCES_REQUEST';
export const ORB_USER_PREFERENCES_SUCCESS = 'orb/USER_PREFERENCES_SUCCESS';
export const ORB_USER_PREFERENCES_FAILURE = 'orb/USER_PREFERENCES_FAILURE';

export const ORB_APP_DOWNLOAD_URL_REQUEST = 'orb/ORB_APP_DOWNLOAD_URL_REQUEST';
export const ORB_APP_DOWNLOAD_URL_SUCCESS = 'orb/ORB_APP_DOWNLOAD_URL_SUCCESS';
export const ORB_APP_DOWNLOAD_URL_FAILURE = 'orb/ORB_APP_DOWNLOAD_URL_FAILURE';

export const SET_ORB_USER_PREFERENCES_REQUEST = 'orb/SET_USER_PREFERENCES_REQUEST';
export const SET_ORB_USER_PREFERENCES_SUCCESS = 'orb/SET_USER_PREFERENCES_SUCCESS';
export const SET_ORB_USER_PREFERENCES_FAILURE = 'orb/SET_USER_PREFERENCES_FAILURE';
export const CLOSE_ORB_USER_PREFERENCES = 'orb/CLOSE_ORB_USER_PREFERENCES';

export const ORB_TEACHER_RESOURCES_REQUEST = 'orb/TEACHER_RESOURCES_REQUEST';
export const ORB_TEACHER_RESOURCES_SUCCESS = 'orb/TEACHER_RESOURCES_SUCCESS';
export const ORB_TEACHER_RESOURCES_FAILURE = 'orb/TEACHER_RESOURCES_FAILURE';

export const ORB_TEACHER_RESOURCES_AUTOCOMPLETE_SUGGESTIONS_REQUEST =
  'orb/TEACHER_RESOURCES_AUTOCOMPLETE_SUGGESTIONS_REQUEST';
export const ORB_TEACHER_RESOURCES_AUTOCOMPLETE_SUGGESTIONS_SUCCESS =
  'orb/TEACHER_RESOURCES_AUTOCOMPLETE_SUGGESTIONS_SUCCESS';
export const ORB_TEACHER_RESOURCES_AUTOCOMPLETE_SUGGESTIONS_FAILURE =
  'orb/TEACHER_RESOURCES_AUTOCOMPLETE_SUGGESTIONS_FAILURE';
export const ORB_GET_NOTIFICATIONS = 'orb/ORB_GET_NOTIFICATIONS';
export const ORB_GET_NOTIFICATIONS_SUCCESS = 'orb/ORB_GET_NOTIFICATIONS_SUCCESS';

export const ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED = 'orb/ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED';
export const ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED_SUCCESS = 'orb/ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED_SUCCESS';

/** ELT Hub Specific Actions */
export const HUB_GET_COURSES = 'hub/HUB_GET_COURSES';
export const HUB_SET_COURSES = 'hub/HUB_SET_COURSES';
export const HUB_GET_COURSES_SUCCESS = 'hub/HUB_GET_COURSES_SUCCESS';
export const HUB_GET_COURSES_FAILURE = 'hub/HUB_GET_COURSES_FAILURE';
export const HUB_GET_FILTERED_COURSE_MATERIALS = 'hub/HUB_GET_FILTERED_COURSE_MATERIALS';
export const HUB_RESET_FILTERS_COURSE_MATERIALS = 'hub/HUB_RESET_FILTERS_COURSE_MATERIALS';
export const HUB_SET_UNFILTERED_COURSE_MATERIALS = 'hub/HUB_SET_UNFILTERED_COURSE_MATERIALS';
export const HUB_GET_FILTERED_PROFILE_COURSE_MATERIALS = 'hub/HUB_GET_FILTERED_PROFILE_COURSE_MATERIALS';
export const HUB_RESET_FILTERS_PROFILE_COURSE_MATERIALS = 'hub/HUB_RESET_FILTERS_PROFILE_COURSE_MATERIALS';
export const HUB_SET_UNFILTERED_PROFILE_COURSE_MATERIALS = 'hub/HUB_SET_UNFILTERED_PROFILE_COURSE_MATERIALS';
export const HUB_SET_SORT_PROFILE_COURSE_MATERIALS = 'hub/HUB_SET_SORT_PROFILE_COURSE_MATERIALS';
export const HUB_OPEN_SIDENAV = 'hub/HUB_OPEN_SIDENAV';
export const HUB_CLOSE_SIDENAV = 'hub/HUB_CLOSE_SIDENAV';
export const HUB_OPEN_REDEEM_MODAL = 'hub/HUB_OPEN_REDEEM_MODAL';
export const HUB_CLOSE_REDEEM_MODAL = 'hub/HUB_CLOSE_REDEEM_MODAL';
export const HUB_OPEN_ENTER_CODE_MODAL = 'hub/HUB_OPEN_ENTER_CODE_MODAL';
export const HUB_CLOSE_ENTER_CODE_MODAL = 'hub/HUB_CLOSE_ENTER_CODE_MODAL';
export const CPT_OPEN_STRUCTURED_CONTENT_PLAYER_MODAL = 'hub/CPT_OPEN_STRUCTURED_CONTENT_PLAYER_MODAL';
export const CPT_CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL = 'hub/CPT_CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL';
export const HUB_OPEN_JOIN_CLASS_WITH_CODE_MODAL = 'hub/HUB_OPEN_JOIN_CLASS_WITH_CODE_MODAL';
export const HUB_OPEN_JOIN_PLACEMENT_TEST_WITH_CODE_MODAL = 'hub/HUB_OPEN_JOIN_PLACEMENT_TEST_WITH_CODE_MODAL';
export const HUB_CLOSE_JOIN_PLACEMENT_TEST_WITH_CODE_MODAL = 'hub/HUB_CLOSE_JOIN_PLACEMENT_TEST_WITH_CODE_MODAL';
export const HUB_CLOSE_JOIN_CLASS_WITH_CODE_MODAL = 'hub/HUB_CLOSE_JOIN_CLASS_WITH_CODE_MODAL';
export const HUB_OPEN_CREATE_CLASS_MODAL = 'hub/HUB_OPEN_CREATE_CLASS_MODAL';
export const HUB_CLOSE_CREATE_CLASS_MODAL = 'hub/HUB_CLOSE_CREATE_CLASS_MODAL';
export const HUB_TOGGLE_ARCHIVE_CLASS_MODAL = 'hub/HUB_TOGGLE_ARCHIVE_CLASS_MODAL';
export const HUB_DOWNLOAD_IS_LOADING = 'hub/HUB_DOWNLOAD_IS_LOADING';
export const HUB_DOWNLOAD_RESOURCE = 'hub/HUB_DOWNLOAD_RESOURCE';
export const HUB_DOWNLOAD_RESOURCE_SUCCESS = 'hub/HUB_DOWNLOAD_RESOURCE_SUCCESS';
export const HUB_DOWNLOAD_RESOURCE_FAILURE = 'hub/HUB_DOWNLOAD_RESOURCE_FAILURE';
export const HUB_DOWNLOAD_REMOVE_ERROR_MODAL = 'hub/HUB_DOWNLOAD_REMOVE_ERROR_MODAL';
export const HUB_DOWNLOAD_RESOURCE_RESET = 'hub/HUB_DOWNLOAD_RESOURCE_RESET';
export const HUB_CHANGE_ACTIVE_VIEW_TEACHER_STUDENT = 'hub/HUB_CHANGE_ACTIVE_VIEW_TEACHER_STUDENT';
export const HUB_OPEN_EDIT_ACCOUNT_MODAL = 'hub/HUB_OPEN_EDIT_ACCOUNT_MODAL';
export const HUB_CLOSE_EDIT_ACCOUNT_MODAL = 'hub/HUB_CLOSE_EDIT_ACCOUNT_MODAL';
export const HUB_SELECT_MY_DASHBOARD_VIEW = 'hub/HUB_SELECT_MY_DASHBOARD_VIEW';
export const HUB_SHOW_SELF_SELECT_ROLE_MODAL = 'hub/HUB_SHOW_SELF_SELECT_ROLE_MODAL';
export const HUB_SHOW_SELF_REGISTERED_USER_MODAL = 'hub/HUB_SHOW_SELF_REGISTERED_USER_MODAL';
export const HUB_OPEN_ADD_TEACHERS_MODAL = 'hub/HUB_OPEN_ADD_TEACHERS_MODAL';
export const HUB_CLOSE_ADD_TEACHERS_MODAL = 'hub/HUB_CLOSE_ADD_TEACHERS_MODAL';
export const HUB_TOGGLE_ADD_STUDENTS_MODAL = 'hub/HUB_TOGGLE_ADD_STUDENTS_MODAL';
export const HUB_TOGGLE_STATUS_STUDENTS_ASSESSMENT_LICENCE_TAB =
  'hub/HUB_TOGGLE_STATUS_STUDENTS_ASSESSMENT_LICENCE_TAB';
export const HUB_HANDLE_PEOPLE_PAGE_SELECTION = 'hub/HUB_HANDLE_PEOPLE_PAGE_SELECTION';
export const HUB_RESET_PAGINATION = 'hub/HUB_RESET_PAGINATION';
export const HUB_OPEN_EDIT_CLASSROOM_MODAL = 'hub/HUB_OPEN_EDIT_CLASSROOM_MODAL';
export const HUB_CLOSE_EDIT_CLASSROOM_MODAL = 'hub/HUB_CLOSE_EDIT_CLASSROOM_MODAL';
export const HUB_OPEN_ADD_LICENSES_MODAL = 'hub/HUB_OPEN_ADD_LICENSES_MODA';
export const HUB_CLOSE_ADD_LICENSES_MODAL = 'hub/HUB_CLOSE_ADD_LICENSES_MODAL';
export const HUB_OPEN_EDIT_ORG_MODAL = 'hub/HUB_OPEN_EDIT_ORG_MODAL';
export const HUB_CLOSE_EDIT_ORG_MODAL = 'hub/HUB_CLOSE_EDIT_ORG_MODAL';
export const HUB_TOGGLE_ORG_USAGE_PANEL = 'hub/HUB_TOGGLE_ORG_USAGE_PANEL';
export const HUB_TOGGLE_MATERIALS_TO_CLASS_MODAL = 'hub/HUB_TOGGLE_MATERIALS_TO_CLASS_MODAL';
export const HUB_CLOSE_MATERIALS_TO_CLASS_MODAL = 'hub/HUB_CLOSE_MATERIALS_TO_CLASS_MODAL';
export const HUB_SET_MATERIALS_CONTEXT = 'hub/HUB_SET_MATERIALS_CONTEXT';
export const HUB_TOGGLE_ORG_MODAL = 'hub/HUB_TOGGLE_ORG_MODAL';
export const HUB_TOGGLE_CLASS_MODAL = 'hub/HUB_TOGGLE_CLASS_MODAL';
export const HUB_OPEN_RECALL_LICENCE_MODAL = 'hub/HUB_OPEN_RECALL_LICENCE_MODAL';
export const HUB_CLOSE_RECALL_LICENCE_MODAL = 'hub/HUB_CLOSE_RECALL_LICENCE_MODAL';
export const HUB_TOGGLE_SET_TEST_MODAL = 'hub/HUB_TOGGLE_SET_TEST_MODAL';
export const HUB_OPEN_ONBOARDING_WIZARD = 'hub/HUB_OPEN_ONBOARDING_WIZARD';
export const HUB_CLOSE_ONBOARDING_WIZARD = 'hub/HUB_CLOSE_ONBOARDING_WIZARD';
export const HUB_OPEN_PLACEMENT_TEST_ONBOARDING_WIZARD = 'hub/HUB_OPEN_PLACEMENT_TEST_ONBOARDING_WIZARD';
export const HUB_OPEN_PLACEMENT_TEST_PANEL = 'hub/HUB_OPEN_PLACEMENT_TEST_PANEL';
export const HUB_OPEN_PLACEMENT_TEST_SESSION_INFORMATION_MODAL =
  'hub/HUB_OPEN_PLACEMENT_TEST_SESSION_INFORMATION_MODAL';
export const HUB_CLOSE_PLACEMENT_TEST_ONBOARDING_WIZARD = 'hub/HUB_CLOSE_PLACEMENT_TEST_ONBOARDING_WIZARD';
export const HUB_CLOSE_PLACEMENT_TEST_PANEL = 'hub/HUB_CLOSE_PLACEMENT_TEST_PANEL';
export const HUB_CLOSE_PLACEMENT_TEST_SESSION_INFORMATION_MODAL =
  'hub/HUB_CLOSE_PLACEMENT_TEST_SESSION_INFORMATION_MODAL';
export const HUB_OPEN_MISSING_LICENSE_MODAL = 'hub/HUB_OPEN_MISSING_LICENSE_MODAL';
export const HUB_CLOSE_MISSING_LICENSE_MODAL = 'hub/HUB_CLOSE_MISSING_LICENSE_MODAL';
export const HUB_OPEN_SELF_REGISTERED_WIZARD = 'hub/HUB_TOGGLE_SELF_REGISTERED_WIZARD';
export const HUB_CLOSE_SELF_REGISTERED_WIZARD = 'hub/HUB_CLOSE_SELF_REGISTERED_WIZARD';
export const HUB_OPEN_DOWNLOAD_PRODUCT_MODAL = 'hub/HUB_OPEN_DOWNLOAD_PRODUCT_MODAL';
export const HUB_CLOSE_DOWNLOAD_PRODUCT_MODAL = 'hub/HUB_CLOSE_DOWNLOAD_PRODUCT_MODAL';

// NGI Content Selector
export const NGI_SET_SELECTED_PRODUCTS = 'ngi/NGI_SET_SELECTED_PRODUCTS';
export const NGI_SET_CONFIRMED_PRODUCTS = 'ngi/NGI_SET_CONFIRMED_PRODUCTS';
export const NGI_SET_LMS_CONFIG = 'ngi/NGI_SET_LMS_CONFIG';
export const NGI_SHOW_PRODUCT_DETAILS_MODAL = 'ngi/NGI_SHOW_PRODUCT_DETAILS_MODAL';
export const NGI_CLOSE_PRODUCT_DETAILS_MODAL = 'ngi/NGI_CLOSE_PRODUCT_DETAILS_MODAL';
export const NGI_FETCH_LINKED_PRODUCTS_REQUEST = 'ngi/NGI_FETCH_LINKED_PRODUCTS_REQUEST';
export const NGI_FETCH_LINKED_PRODUCTS_SUCCESS = 'ngi/NGI_FETCH_LINKED_PRODUCTS_SUCCESS';
export const NGI_FETCH_LINKED_PRODUCTS_FAILURE = 'ngi/NGI_FETCH_LINKED_PRODUCTS_FAILURE';
export const NGI_FETCH_JWT_AND_DEEPLINK_REQUEST = 'ngi/NGI_FETCH_JWT_AND_DEEPLINK_REQUEST';
export const NGI_FETCH_JWT_AND_DEEPLINK_SUCCESS = 'ngi/NGI_FETCH_JWT_AND_DEEPLINK_SUCCESS';
export const NGI_FETCH_JWT_AND_DEEPLINK_FAILURE = 'ngi/NGI_FETCH_JWT_AND_DEEPLINK_FAILURE';
export const NGI_FETCH_REFRESHED_JWT = 'ngi/NGI_FETCH_JWT_REFRESHED';
export const NGI_SET_CONFIRMED_PRODUCTS_BACKUP = 'ngi/NGI_SET_CONFIRMED_PRODUCTS_BACKUP';
export const NGI_SET_OLB_START_END_PAGE = 'ngi/NGI_SET_OLB_START_END_PAGE';

export const NGI_STORE_LINE_ITEMS_REQUEST = 'ngi/NGI_STORE_LINE_ITEMS_REQUEST';
export const NGI_STORE_LINE_ITEMS_SUCCESS = 'ngi/NGI_STORE_LINE_ITEMS_SUCCESS';
export const NGI_STORE_LINE_ITEMS_FAILURE = 'ngi/NGI_STORE_LINE_ITEMS_FAILURE';

export const NGI_GET_CONTENT_SELECTOR_STATUS_REQUEST = 'ngi/NGI_GET_CONTENT_SELECTOR_STATUS_REQUEST';
export const NGI_GET_CONTENT_SELECTOR_STATUS_SUCCESS = 'ngi/NGI_GET_CONTENT_SELECTOR_STATUS_SUCCESS';
export const NGI_GET_CONTENT_SELECTOR_STATUS_FAILURE = 'ngi/NGI_GET_CONTENT_SELECTOR_STATUS_FAILURE';

export const NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_REQUEST = 'ngi/NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_REQUEST';
export const NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_SUCCESS = 'ngi/NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_SUCCESS';
export const NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_FAILURE = 'ngi/NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_FAILURE';

// OPEN PRODUCTS, first used in NGI content selector, so placing them here
export const OPEN_PRODUCTS_FETCH_REQUEST = 'openProducts/OPEN_PRODUCTS_FETCH_REQUEST';
export const OPEN_PRODUCTS_FETCH_SUCCESS = 'openProducts/OPEN_PRODUCTS_FETCH_SUCCESS';
export const OPEN_PRODUCTS_FETCH_FAILURE = 'openProducts/OPEN_PRODUCTS_FETCH_FAILURE';
export const OPEN_PRODUCTS_FILTER = 'openProducts/OPEN_PRODUCTS_FILTER';
export const OPEN_PRODUCTS_SEARCHED_VALUE = 'openProducts/OPEN_PRODUCTS_SEARCHED_VALUE';

export const FIND_PRODUCT_REQUEST = 'product/FIND_PRODUCT_REQUEST';
export const FIND_PRODUCT_SUCCESS = 'product/FIND_PRODUCT_SUCCESS';
export const FIND_PRODUCT_FAILURE = 'product/FIND_PRODUCT_FAILURE';
export const CLEAR_FOUND_PRODUCT = 'product/CLEAR_FOUND_PRODUCT';

export const GET_PRODUCT_CREDITS_REQUEST = 'lti-credits/GET_PRODUCT_CREDITS_REQUEST';
export const GET_PRODUCT_CREDITS_SUCCESS = 'lti-credits/GET_PRODUCT_CREDITS_SUCCESS';
export const GET_PRODUCT_CREDITS_FAILURE = 'lti-credits/GET_PRODUCT_CREDITS_FAILURE';

export const GET_EXISTING_PRODUCTS_REQUEST = 'lti-credits/GET_EXISTING_PRODUCTS_REQUEST';
export const GET_EXISTING_PRODUCTS_SUCCESS = 'lti-credits/GET_EXISTING_PRODUCTS_SUCCESS';
export const GET_EXISTING_PRODUCTS_FAILURE = 'lti-credits/GET_EXISTING_PRODUCTS_FAILURE';

export const ADD_PRODUCT_CREDITS_REQUEST = 'lti-credits/ADD_PRODUCT_CREDITS_REQUEST';
export const ADD_PRODUCT_CREDITS_SUCCESS = 'lti-credits/ADD_PRODUCT_CREDITS_SUCCESS';
export const ADD_PRODUCT_CREDITS_FAILURE = 'lti-credits/ADD_PRODUCT_CREDITS_FAILURE';

export const CLEAR_PRODUCT_CREDITS = 'lti-credits/CLEAR_PRODUCT_CREDITS';

// Result Verification Service Platform
export const RVS_UPLOAD_SUBMIT = 'rvs/RVS_UPLOAD_SUBMIT';
export const RVS_UPLOAD_SUCCESS = 'rvs/RVS_UPLOAD_SUCCESS';
export const RVS_UPLOAD_ERROR = 'rvs/RVS_UPLOAD_ERROR';
export const RVS_UPLOAD_INVALID = 'rvs/RVS_UPLOAD_INVALID';
export const RVS_UPLOAD_RESET = 'rvs/RVS_UPLOAD_RESET';
export const RVS_UPLOAD_SERVICE_UNAVAILABLE = 'rvs/RVS_UPLOAD_SERVICE_UNAVAILABLE';

export const ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_SUCCESS =
  'hub/ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_SUCCESS';
export const ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_ERROR =
  'hub/ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_ERROR';
export const ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_REQUEST =
  'hub/ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_REQUEST';
export const ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST =
  'hub/ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST';
export const ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS =
  'hub/ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS';
export const ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_ERROR =
  'hub/ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_ERROR';
export const ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST =
  'hub/ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST';
export const ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS =
  'hub/ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS';
export const ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_ERROR =
  'hub/ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_ERROR';

export const ONBOARDING_WIZARD_VALIDATE_CLASS_NAME_REQUEST = 'hub/ONBOARDING_WIZARD_VALIDATE_CLASS_NAME_REQUEST';
export const ONBOARDING_WIZARD_VALIDATE_CLASS_NAME_RESULT = 'hub/ONBOARDING_WIZARD_VALIDATE_CLASS_NAME_RESULT';
export const ONBOARDING_WIZARD_SET_CREATE_CLASS_REQUEST = 'hub/ONBOARDING_WIZARD_SET_CREATE_CLASS_REQUEST';
export const ONBOARDING_WIZARD_SET_CREATE_CLASS_SUCCESS = 'hub/ONBOARDING_WIZARD_SET_CREATE_CLASS_SUCCESS';
export const ONBOARDING_WIZARD_SET_CREATE_CLASS_ERROR = 'hub/ONBOARDING_WIZARD_SET_CREATE_CLASS_ERROR';
export const ONBOARDING_WIZARD_GET_CLASS_STUDENTS = 'hub/ONBOARDING_WIZARD_GET_CLASS_STUDENTS';
export const ONBOARDING_WIZARD_GET_CLASS_STUDENTS_SUCCESS = 'hub/ONBOARDING_WIZARD_GET_CLASS_STUDENTS_SUCCESS';
export const ONBOARDING_WIZARD_GET_CLASS_STUDENTS_ERROR = 'hub/ONBOARDING_WIZARD_GET_CLASS_STUDENTS_ERROR';
export const ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_SUCCESS =
  'hub/ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_SUCCESS';
export const ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_ERROR = 'hub/ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_ERROR';
export const ONBOARDING_WIZARD_GET_LEARNING_MATERIALS = 'hub/ONBOARDING_WIZARD_GET_LEARNING_MATERIALS';
export const ONBOARDING_WIZARD_CLEAR_PLACEMENT_TEST_SESSION_UPDATED_STATE_FLAG =
  'hub/ONBOARDING_WIZARD_CLEAR_PLACEMENT_TEST_SESSION_UPDATED_STATE_FLAG';
export const ONBOARDING_WIZARD_CLEAR_STATE = 'hub/ONBOARDING_WIZARD_CLEAR_STATE';

// Assign learning material pack - search material
export const PRODUCT_FINDER_GET_PRODUCTS_SUCCESS = 'hub/PRODUCT_FINDER_GET_PRODUCTS_SUCCESS';
export const PRODUCT_FINDER_GET_PRODUCTS_FAILURE = 'hub/PRODUCT_FINDER_GET_PRODUCTS_FAILURE';
export const PRODUCT_FINDER_GET_PRODUCTS_REQUEST = 'hub/PRODUCT_FINDER_GET_PRODUCTS_REQUEST';
export const PRODUCT_FINDER_SET_COUNTRY_CODE = 'hub/PRODUCT_FINDER_SET_COUNTRY_CODE';
export const PRODUCT_FINDER_SET_SEARCH_VALUE = 'hub/PRODUCT_FINDER_SET_SEARCH_VALUE';
export const PRODUCT_FINDER_SET_PAGE = 'hub/PRODUCT_FINDER_SET_PAGE';
export const PRODUCT_FINDER_SET_FILTERS = 'hub/PRODUCT_FINDER_SET_FILTERS';
export const PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_REQUEST = 'hub/PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_REQUEST';
export const PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_SUCCESS = 'hub/PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_SUCCESS';
export const PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_FAILURE = 'hub/PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_FAILURE';
export const PRODUCT_FINDER_SELECT_PRODUCT = 'hub/PRODUCT_FINDER_SELECT_PRODUCT';
export const PRODUCT_FINDER_SELECT_SINGLE_PRODUCT = 'hub/PRODUCT_FINDER_SELECT_SINGLE_PRODUCT';
export const PRODUCT_FINDER_GET_LICENCES_REQUEST = 'hub/PRODUCT_FINDER_GET_LICENCES_REQUEST';
export const PRODUCT_FINDER_GET_LICENCES_SUCCESS = 'hub/PRODUCT_FINDER_GET_LICENCES_SUCCESS';
export const PRODUCT_FINDER_GET_LICENCES_FAILURE = 'hub/PRODUCT_FINDER_GET_LICENCES_FAILURE';
export const PRODUCT_FINDER_SET_SELECTED_COUNTRY_CODE = 'hub/PRODUCT_FINDER_SET_SELECTED_COUNTRY_CODE';
export const PRODUCT_FINDER_RESET_STATE = 'hub/PRODUCT_FINDER_RESET_STATE';
export const PRODUCT_FINDER_CHANGE_SERIES = 'hub/PRODUCT_FINDER_CHANGE_SERIES';
export const PRODUCT_FINDER_ASSIGN_PRODUCTS_REQUEST = 'hub/PRODUCT_FINDER_ASSIGN_PRODUCTS_REQUEST';
export const PRODUCT_FINDER_ASSIGN_PRODUCTS_SUCCESS = 'hub/PRODUCT_FINDER_ASSIGN_PRODUCTS_SUCCESS';
export const PRODUCT_FINDER_ASSIGN_PRODUCTS_FAILURE = 'hub/PRODUCT_FINDER_ASSIGN_PRODUCTS_FAILURE';
export const PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_REQUEST = 'hub/PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_REQUEST';
export const PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_SUCCESS = 'hub/PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_SUCCESS';
export const PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_FAILURE = 'hub/PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_FAILURE';
export const PRODUCT_FINDER_GET_SERIES_REQUEST = 'hub/PRODUCT_FINDER_GET_SERIES_REQUEST';
export const PRODUCT_FINDER_GET_SERIES_SUCCESS = 'hub/PRODUCT_FINDER_GET_SERIES_SUCCESS';
export const PRODUCT_FINDER_GET_SERIES_FAILURE = 'hub/PRODUCT_FINDER_GET_SERIES_FAILURE';
export const PRODUCT_FINDER_SET_DATA = 'hub/PRODUCT_FINDER_SET_DATA';
export const PRODUCT_FINDER_ADD_LICENCE_DATA = 'hub/PRODUCT_FINDER_ADD_LICENCE_DATA';
export const PRODUCT_FINDER_SET_ASSIGN_LICENCE = 'hub/PRODUCT_FINDER_SET_ASSIGN_LICENCE';
export const PRODUCT_FINDER_SET_ASSIGN_LICENCE_TO_TEACHER = 'hub/PRODUCT_FINDER_SET_ASSIGN_LICENCE_TO_TEACHER';
export const PRODUCT_FINDER_SELECT_MULTIPLE_PRODUCTS = 'hub/PRODUCT_FINDER_SELECT_MULTIPLE_PRODUCTS';
export const PRODUCT_FINDER_CHANGE_SEARCH_TYPE = 'hub/PRODUCT_FINDER_CHANGE_SEARCH_TYPE';
export const PRODUCT_FINDER_CLEAR_SELECTED_PRODUCTS = 'hub/PRODUCT_FINDER_CLEAR_SELECTED_PRODUCTS';
export const PRODUCT_FINDER_REMOVE_SELECTED_PRODUCT = 'hub/PRODUCT_FINDER_REMOVE_SELECTED_PRODUCT';
export const PRODUCT_FINDER_SET_CURRENT_FORM_STATE = 'hub/PRODUCT_FINDER_SET_CURRENT_FORM_STATE';
export const PRODUCT_FINDER_GO_TO_PREVIOUS_FORM_STATE = 'hub/PRODUCT_FINDER_GO_TO_PREVIOUS_FORM_STATE';
export const PRODUCT_FINDER_SET_SEARCH_STATE = 'hub/PRODUCT_FINDER_SET_SEARCH_STATE';
export const PRODUCT_FINDER_CLEAR_SEARCH_STATE = 'hub/PRODUCT_FINDER_CLEAR_SEARCH_STATE';
export const PRODUCT_FINDER_SET_ORG_LICENCES_INITIAL_TOGGLE = 'hub/PRODUCT_FINDER_SET_ORG_LICENCES_INITIAL_TOGGLE';

// Result Verification Service Platform
export const RVS_FORM_VALIDATE_INPUT = 'rvs/RVS_FORM_VALIDATE_INPUT';
export const RVS_FORM_VALIDATE_COMPLETED = 'rvs/RVS_FORM_VALIDATE_COMPLETED';
export const RVS_FORM_SUBMIT = 'rvs/RVS_FORM_SUBMIT';
export const RVS_FORM_RESET = 'rvs/RVS_FORM_RESET';
export const RVS_FORM_SUCCESS = 'rvs/RVS_FORM_SUCCESS';
export const RVS_FORM_FAILURE = 'rvs/RVS_FORM_FAILURE';
export const RVS_FORM_BOT = 'rvs/FORM_BOT';
export const RVS_UNAVAILABLE_API = 'rvs/RVS_UNAVAILABLE_API';

// Assessments
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GET_ASSESSMENTS_SUCCESS = 'GET_ASSESSMENTS_SUCCESS';
export const GET_ASSESSMENTS_FAILURE = 'GET_ASSESSMENTS_FAILURE';
export const ASSESSMENTS_SET_STUDENTS = 'ASSESSMENTS_SET_STUDENTS';
export const ASSESSMENTS_SET_ALL_STUDENTS = 'ASSESSMENTS_SET_ALL_STUDENTS';
export const ASSESSMENTS_SET_INITIAL_STUDENTS = 'ASSESSMENTS_SET_INITIAL_STUDENTS';
export const ASSESSMENTS_SWITCH_TO_PAGE = 'ASSESSMENTS_SWITCH_TO_PAGE';
export const ASSESSMENTS_SET_START_DATE = 'ASSESSMENTS_SET_START_DATE';
export const ASSESSMENTS_TIME_SET = 'ASSESSMENTS_TIME_SET';
export const ASSESSMENTS_SET_END_DATE = 'ASSESSMENTS_SET_END_DATE';
export const ASSESSMENTS_SET_START_HOUR = 'ASSESSMENTS_SET_START_HOUR';
export const ASSESSMENTS_RESET_PANEL = 'ASSESSMENTS_RESET_PANEL';
export const ASSESSMENTS_RESET_STATUS_OPTIONS = 'ASSESSMENTS_RESET_STATUS_OPTIONS';
export const ASSESSMENTS_RESET_FROM_CLASS = 'ASSESSMENTS_RESET_FROM_CLASS';
export const ASSESSMENTS_STUDENT_FORM_RESETED = 'ASSESSMENTS_STUDENT_FORM_RESETED';
export const ASSESSMENTS_SET_ONLINE_TEST_ID = 'ASSESSMENTS_SET_ONLINE_TEST_ID';
export const ASSESSMENTS_SET_ONLINE_TEST_DATA = 'ASSESSMENTS_SET_ONLINE_TEST_DATA';
export const ASSESSMENTS_SET_END_HOUR = 'ASSESSMENTS_SET_END_HOUR';
export const SET_SEND_EMAIL_PREFERENCE = 'SET_SEND_EMAIL_PREFERENCE';
export const ASSESSMENTS_SET_ONLINE_TEST = 'ASSESSMENTS_SET_ONLINE_TEST';
export const ASSESSMENTS_SET_ONLINE_TEST_SUCCESS = 'ASSESSMENTS_SET_ONLINE_TEST_SUCCESS';
export const ASSESSMENTS_SET_ONLINE_TEST_FAILURE = 'ASSESSMENTS_SET_ONLINE_TEST_FAILURE';
export const ASSESSMENTS_SET_HAS_ERRORS = 'ASSESSMENTS_SET_HAS_ERRORS';
export const COURSE_ASSESSMENTS_SET_ASSESSMENT_ID = 'COURSE_ASSESSMENTS_SET_ASSESSMENT_ID';
export const ASSESSMENTS_SET_DURATION = 'ASSESSMENTS_SET_DURATION';
export const ASSESSMENTS_SHOW_SET_DURATION = 'ASSESSMENTS_SHOW_SET_DURATION';

// Adapted tests
export const GET_ADAPTED_TESTS_FOR_ASSESSMENT = 'GET_ADAPTED_TESTS_FOR_ASSESSMENT';
export const GET_ADAPTED_TESTS_FOR_ASSESSMENT_SUCCESS = 'GET_ADAPTED_TESTS_FOR_ASSESSMENT_SUCCESS';
export const GET_ADAPTED_TESTS_FOR_ASSESSMENT_FAILURE = 'GET_ADAPTED_TESTS_FOR_ASSESSMENT_SUCCESS';
export const SET_ADAPTED_TESTS_POLLING = 'SET_ADAPTED_TESTS_POLLING';
export const SET_ADAPTED_TESTS_UPDATED = 'SET_ADAPTED_TESTS_UPDATED';
export const UPDATE_ADAPTED_TEST = 'UPDATE_ADAPTED_TEST';
export const UPDATE_ADAPTED_TEST_FAILURE = 'UPDATE_ADAPTED_TEST_FAILURE';
export const SET_ADAPTED_TESTS_NEED_TO_BE_UPDATED = 'SET_ADAPTED_TESTS_NEED_TO_BE_UPDATED';

// Class assessments
export const GET_CLASS_ASSESSMENTS = 'GET_CLASS_ASSESSMENTS';
export const GET_CLASS_ASSESSMENTS_SUCCESS = 'GET_CLASS_ASSESSMENTS_SUCCESS';
export const GET_CLASS_ASSESSMENTS_FAILURE = 'GET_CLASS_ASSESSMENTS_FAILURE';
// Placement tests
export const GET_PLACEMENT_TESTS = 'hub/GET_PLACEMENT_TESTS';
export const GET_PLACEMENT_TESTS_SUCCESS = 'hub/GET_PLACEMENT_TESTS_SUCCESS';
export const GET_PLACEMENT_TESTS_FAILURE = 'hub/GET_PLACEMENT_TESTS_FAILURE';
export const REFRESH_PLACEMENT_TEST_LIST = 'hub/REFRESH_PLACEMENT_TEST_LIST';
// User tasks
export const GET_STUDENT_TASKS = 'hub/GET_STUDENT_TASKS';
export const GET_STUDENT_TASKS_SUCCESS = 'hub/GET_STUDENT_TASKS_SUCCESS';
export const GET_STUDENT_TASKS_FAILURE = 'hub/GET_STUDENT_TASKS_FAILURE';

// User number of active tasks
export const GET_STUDENT_ACTIVE_TASKS_COUNT = 'hub/GET_STUDENT_ACTIVE_TASKS_COUNT';
export const GET_STUDENT_ACTIVE_TASKS_COUNT_SUCCESS = 'hub/GET_STUDENT_ACTIVE_TASKS_COUNT_SUCCESS';
export const GET_STUDENT_ACTIVE_TASKS_COUNT_FAILURE = 'hub/GET_STUDENT_ACTIVE_TASKS_COUNT_FAILURE';

// User access placement test session
export const USER_ACCESS_PLACEMENT_TEST_SESSION = 'hub/USER_ACCESS_PLACEMENT_TEST_SESSION';
export const USER_ACCESS_PLACEMENT_TEST_SESSION_SUCCESS = 'hub/USER_ACCESS_PLACEMENT_TEST_SESSION_SUCCESS';
export const USER_ACCESS_PLACEMENT_TEST_SESSION_FAILURE = 'hub/USER_ACCESS_PLACEMENT_TEST_SESSION_FAILURE';
export const USER_ACCESS_PLACEMENT_TEST_SESSION_MODAL_CLOSE = 'hub/USER_ACCESS_PLACEMENT_TEST_SESSION_MODAL_CLOSE';

export const OPEN_EDIT_JOINING_CODE = 'hub/OPEN_EDIT_JOINING_CODE';
export const CLOSE_EDIT_JOINING_CODE = 'hub/CLOSE_EDIT_JOINING_CODE';
export const GET_STUDENTS_IN_TEST_SESSION = 'hub/GET_STUDENTS_IN_TEST_SESSION';
export const GET_STUDENTS_IN_TEST_SESSION_SUCCESS = 'hub/GET_STUDENTS_IN_TEST_SESSION_SUCCESS';
export const GET_STUDENTS_IN_TEST_SESSION_FAILURE = 'hub/GET_STUDENTS_IN_TEST_SESSION_FAILURE';
export const SET_SORTING_FOR_STUDENTS = 'hub/SET_SORTING_FOR_STUDENTS';

export const GET_JOINING_CODE_DETAILS = 'hub/GET_JOINING_CODE_DETAILS';
export const GET_JOINING_CODE_DETAILS_SUCCESS = 'hub/GET_JOINING_CODE_DETAILS_SUCCESS';
export const GET_JOINING_CODE_DETAILS_FAILURE = 'hub/GET_JOINING_CODE_DETAILS_FAILURE';

export const SUBMIT_EDIT_JOINING_CODE = 'hub/SUBMIT_EDIT_JOINING_CODE';
export const SUBMIT_EDIT_JOINING_CODE_SUCCESS = 'hub/SUBMIT_EDIT_JOINING_CODE_SUCCESS';
export const SUBMIT_EDIT_JOINING_CODE_FAILURE = 'hub/SUBMIT_EDIT_JOINING_CODE_FAILURE';
export const EDIT_JOINING_CODE_UPDATED = 'hub/EDIT_JOINING_CODE_UPDATED';
export const EDIT_JOINING_CODE_RESET_ERROR = 'hub/EDIT_JOINING_CODE_RESET_ERROR';

export const UPDATE_PLACES_IN_TEST_SESSION = 'hub/UPDATE_PLACES_IN_TEST_SESSION';
export const UPDATE_PLACES_IN_TEST_SESSION_SUCCESS = 'hub/UPDATE_PLACES_IN_TEST_SESSION_SUCCESS';
export const UPDATE_PLACES_IN_TEST_SESSION_FAILURE = 'hub/UPDATE_PLACES_IN_TEST_SESSION_FAILURE';
export const GET_TEST_AVAILABLE_CREDITS_SUCCESS = 'hub/GET_TEST_AVAILABLE_CREDITS_SUCCESS';

export const DOWNLOAD_PLACEMENT_TEST_RESULTS = 'hub/DOWNLOAD_PLACEMENT_TEST_RESULTS';
export const DOWNLOAD_PLACEMENT_TEST_RESULTS_SUCCESS = 'hub/DOWNLOAD_PLACEMENT_TEST_RESULTS_SUCCESS';
export const DOWNLOAD_PLACEMENT_TEST_RESULTS_FAILURE = 'hub/DOWNLOAD_PLACEMENT_TEST_RESULTS_FAILURE';
// Decline Org Invitation
export const DECLINED_ORG_DETAILS_INIT = 'declineOrgInvitation/INIT_ORG_DETAILS';
export const DECLINED_ORG_DETAILS_LOAD_SUCCESS = 'declineOrgInvitation/ORG_DETAILS_LOADED';
export const DECLINED_ORG_DETAILS_LOAD_ERROR = 'declineOrgInvitation/ORG_DETAILS_LOAD_ERROR';
export const DECLINED_ORG_INVITE_UPDATING = 'declineOrgInvitation/DECLINED_ORG_INVITE_UPDATING';
export const DECLINED_ORG_INVITE_UPDATE_SUCCESS = 'declineOrgInvitation/DECLINED_ORG_INVITE_UPDATE_SUCCESS';
export const DECLINED_ORG_INVITE_UPDATE_ERROR = 'declineOrgInvitation/DECLINED_ORG_INVITE_UPDATE_ERROR';

// admin dashboard preesed organisations data
export const PRESEED_ORG = 'preseedData/PRESEED_ORG';
export const PRESEED_ORG_IN_PROGRESS = 'preseedData/PRESEED_ORG_IN_PROGRESS';
export const PRESEED_ORG_SUCCESS = 'preseedData/PRESEED_ORG_SUCCESS';
export const PRESEED_ORG_FAILURE = 'preseedData/PRESEED_ORG_FAILURE';
export const PRESEED_RESET = 'preseedData/PRESEED_RESET';
export const AM_LOCALIZED_CONTENT_SUCCESS = 'amLocalizedContent/AM_LOCALIZED_CONTENT_SUCCESS';
export const AM_LOCALIZED_CONTENT_FAILURE = 'amLocalizedContent/AM_LOCALIZED_CONTENT_FAILURE';

// admin dashboard Csv User upload
export const CSVUSER_DATA_UPLOAD_INIT = 'csvUserUpload/CSVUSER_DATA_UPLOAD_INIT';
export const CSVUSER_DATA_UPLOAD_START_UPLOAD = 'csvUserUpload/CSVUSER_DATA_UPLOAD_START_UPLOAD';
export const CSVUSER_INPUT_UPDATE = 'csvUserUpload/CSVUSER_INPUT_UPDATE';
export const CSVUSER_DATA_UPLOAD_SUCCESS = 'csvUserUpload/CSVUSER_DATA_UPLOAD_SUCCESS';
export const CSVUSER_DATA_UPLOAD_FAILURE = 'csvUserUpload/CSVUSER_DATA_UPLOAD_FAILURE';
export const CSVUSER_DATA_UPLOAD_PARSE_FILE_REQUEST = 'csvUserUpload/PARSE_FILE_REQUEST';
export const CSVUSER_DATA_UPLOAD_PARSE_FILE_COMPLETED = 'csvUserUpload/PARSE_FILE_COMPLETED';
export const CSVUSER_DATA_UPLOAD_PARSE_FILE_FAILURE = 'csvUserUpload/PARSE_FILE_FAILURE';

// admin dashboard Csv Product Structure upload
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_INIT = 'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_DATA_UPLOAD_INIT';
export const CSVPRODUCT_STRUCTURE_INPUT_UPDATE = 'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_INPUT_UPDATE';
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_START_UPLOAD =
  'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_DATA_UPLOAD_START_UPLOAD';
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_SUCCESS =
  'csvProductStructureUpload/CSVPRODUCT_STRUCTUREDATA_UPLOAD_SUCCESS';
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_FAILURE =
  'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_DATA_UPLOAD_FAILURE';
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_REQUEST =
  'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_PARSE_FILE_REQUEST';
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_COMPLETED =
  'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_PARSE_FILE_COMPLETED';
export const CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_FAILURE =
  'csvProductStructureUpload/CSVPRODUCT_STRUCTURE_PARSE_FILE_FAILURE';

// Product Structure Upload Excel
export const CHECK_PRODUCT_EXISTS_REQUEST = 'excelProductStructureUpload/CHECK_PRODUCT_EXISTS_REQUEST';
export const CHECK_PRODUCT_EXISTS_SUCCESS = 'excelProductStructureUpload/CHECK_PRODUCT_EXISTS_SUCCESS';
export const CHECK_PRODUCT_EXISTS_FAILURE = 'excelProductStructureUpload/CHECK_PRODUCT_EXISTS_FAILURE';
export const CHECK_PRODUCT_EXISTS_CLEAR = 'excelProductStructureUpload/CHECK_PRODUCT_EXISTS_CLEAR';

// Course Upload
export const COURSE_UPLOAD_INITIALISE = 'courseUpload/COURSE_UPLOAD_INITIALISE';
export const COURSE_UPLOAD_REQUEST = 'courseUpload/COURSE_UPLOAD_REQUEST';
export const COURSE_UPLOAD_START = 'courseUpload/COURSE_UPLOAD_START';
export const COURSE_UPLOAD_SUCCESS = 'courseUpload/COURSE_UPLOAD_SUCCESS';
export const COURSE_UPLOAD_FAILURE = 'courseUpload/COURSE_UPLOAD_FAILURE';
export const COURSE_UPLOAD_CLEAR = 'courseUpload/COURSE_UPLOAD_CLEAR';

// System notification
export const UPDATE_SYSTEM_NOTIFICATION = 'UPDATE_SYSTEM_NOTIFICATION';

// LMSConfig
export const CREATE_LMS_CONFIG_DEPLOYMENT_REQUEST = 'lmsConfig/CREATE_LMS_CONFIG_DEPLOYMENT_REQUEST';
export const CREATE_LMS_CONFIG_DEPLOYMENT_SUCCESS = 'lmsConfig/CREATE_LMS_CONFIG_DEPLOYMENT_SUCCESS';
export const CREATE_LMS_CONFIG_DEPLOYMENT_FAILURE = 'lmsConfig/CREATE_LMS_CONFIG_DEPLOYMENT_FAILURE';

export const DELETE_LMS_CONFIG_DEPLOYMENT_REQUEST = 'lmsConfig/DELETE_LMS_CONFIG_DEPLOYMENT_REQUEST';
export const DELETE_LMS_CONFIG_DEPLOYMENT_SUCCESS = 'lmsConfig/DELETE_LMS_CONFIG_DEPLOYMENT_SUCCESS';
export const DELETE_LMS_CONFIG_DEPLOYMENT_FAILURE = 'lmsConfige/DELETE_LMS_CONFIG_DEPLOYMENT_FAILURE';

export const LMS_CONFIG_SET_FORM_DATA = 'lmsConfig/LMS_CONFIG_SET_FORM_DATA';
export const LMS_CONFIG_TOGGLE_OXFORD_LTI = 'lmsConfig/LMS_CONFIG_TOGGLE_OXFORD_LTI';
export const LMS_CONFIG_DEPLOYMENT_VALIDATE_INPUT = 'lmsConfig/LMS_CONFIG_DEPLOYMENT_VALIDATE_INPUT';
export const LMS_CONFIG_CHECK_IF_FORM_IS_VALID = 'lmsConfig/LMS_CONFIG_CHECK_IF_FORM_IS_VALID';

export const LMS_CONFIG_TOOL_EDIT = 'lmsConfig/LMS_CONFIG_TOOL_EDIT';
export const LMS_CONFIG_TOOL_ARCHIVE = 'lmsConfig/LMS_CONFIG_TOOL_ARCHIVE';
export const LMS_CONFIG_TOOL_RESET = 'lmsConfig/LMS_CONFIG_TOOL_RESET';

export const GET_LMS_ORG_CONFIG = 'lmsConfig/GET_LMS_ORG_CONFIG';
export const LMS_CONFIG_TOGGLE_ORG_CONFIG_EDIT = 'lmsConfig/LMS_CONFIG_TOGGLE_ORG_CONFIG_EDIT';
export const HUB_TOGGLE_DEPLOYMENT_MODAL = 'lmsConfig/HUB_TOGGLE_DEPLOYMENT_MODAL';

// OfflineContentPlayer (OCP)
export const ADD_OFFLINE_PRODUCTS = 'offlineContentPlayer/ADD_OFFLINE_PRODUCTS';
export const GET_OFFLINE_PRODUCTS = 'offlineContentPlayer/GET_OFFLINE_PRODUCTS';
export const LOAD_OFFLINE_PRODUCTS = 'offlineContentPlayer/LOAD_OFFLINE_PRODUCTS';

export const GET_OFFLINE_PENDING_ACTIONS_REQUEST = 'offlineContentPlayer/GET_OFFLINE_PENDING_ACTIONS_REQUEST';
export const GET_OFFLINE_PENDING_ACTIONS_SUCCESS = 'offlineContentPlayer/GET_OFFLINE_PENDING_ACTIONS_SUCCESS';
export const GET_OFFLINE_PENDING_ACTIONS_FAILURE = 'offlineContentPlayer/GET_OFFLINE_CONTENT_STATUS_FAILURE';

export const CLEAR_OFFLINE_PENDING_ACTIONS_REQUEST = 'offlineContentPlayer/CLEAR_OFFLINE_PENDING_ACTIONS_REQUEST';

export const ADD_OFFLINE_PROFILES = 'offlineContentPlayer/ADD_OFFLINE_PROFILES';
export const DELETE_OFFLINE_PROFILES = 'offlineContentPlayer/DELETE_OFFLINE_PROFILES';
export const GET_OFFLINE_PROFILES = 'offlineContentPlayer/GET_OFFLINE_PROFILES';
export const LOAD_OFFLINE_PROFILES = 'offlineContentPlayer/LOAD_OFFLINE_PROFILES';

export const ADD_OFFLINE_UNITS = 'offlineContentPlayer/ADD_OFFLINE_UNITS';
export const DELETE_OFFLINE_UNITS = 'offlineContentPlayer/DELETE_OFFLINE_UNITS';
export const GET_OFFLINE_UNITS = 'offlineContentPlayer/GET_OFFLINE_UNITS';
export const LOAD_OFFLINE_UNITS = 'offlineContentPlayer/LOAD_OFFLINE_UNITS';
export const UPDATE_DOWNLOAD_OFFLINE_STATUS = 'offlineContentPlayer/UPDATE_DOWNLOAD_OFFLINE_STATUS';

export const GET_PENDING_ACTIONS_TIME = 'offlineContentPlayer/GET_PENDING_ACTIONS_TIME';
export const LOAD_PENDING_ACTIONS_TIME = 'offlineContentPlayer/LOAD_PENDING_ACTIONS_TIME';
export const SET_PENDING_ACTIONS_TIME = 'offlineContentPlayer/SET_PENDING_ACTIONS_TIME';

export const GET_OFFLINE_PLAYER_VERSION = 'offlineContentPlayer/GET_OFFLINE_PLAYER_VERSION';
export const UPDATE_OFFLINE_PLAYER_VERSION = 'offlineContentPlayer/UPDATE_OFFLINE_PLAYER_VERSION';
export const LOAD_OFFLINE_PLAYER_VERSION = 'offlineContentPlayer/LOAD_OFFLINE_PLAYER_VERSION';

export const GET_OFFLINE_LICENSE_REQUEST = 'offlineContentPlayer/GET_OFFLINE_LICENSE_REQUEST';
export const GET_OFFLINE_LICENSE_SUCCESS = 'offlineContentPlayer/GET_OFFLINE_LICENSE_SUCCESS';
export const GET_OFFLINE_LICENSE_FAILURE = 'offlineContentPlayer/GET_OFFLINE_LICENSE_FAILURE';
export const LOAD_OFFLINE_LICENSE_STATUS_TIMER = 'offlineContentPlayer/LOAD_OFFLINE_LICENSE_STATUS_TIMER';
export const GET_OFFLINE_LICENSE_STATUS_TIMER = 'offlineContentPlayer/GET_OFFLINE_LICENSE_STATUS_TIMER';
export const SET_OFFLINE_LICENSE_STATUS_TIMER = 'offlineContentPlayer/SET_OFFLINE_LICENSE_STATUS_TIMER';

export const SET_OFFLINE_DOWNLOAD_PROGRESS = 'offlineContentPlayer/SET_OFFLINE_DOWNLOAD_PROGRESS';
export const REMOVE_OFFLINE_DOWNLOAD_PROGRESS = 'offlineContentPlayer/REMOVE_OFFLINE_DOWNLOAD_PROGRESS';

// StructuredContentPlayer (CPT)
export const SET_CONTENT = 'structuredContentPlayer/SET_CONTENT';
export const SET_CURRENT_CONTENT = 'structuredContentPlayer/SET_CURRENT_CONTENT';
export const OPEN_MOBILE_MENU_MODAL = 'structuredContentPlayer/CPT_OPEN_MOBILE_MENU_MODAL';
export const CLOSE_MOBILE_MENU_MODAL = 'structuredContentPlayer/CPT_CLOSE_MOBILE_MENU_MODAL';
export const TOGGLE_CONTENTS_SUBMENU_MODAL = 'structuredContentPlayer/TOGGLE_CONTENTS_SUBMENU_MODAL';
export const OPEN_CONTENTS_SUBMENU_MODAL = 'structuredContentPlayer/OPEN_CONTENTS_SUBMENU_MODAL';
export const TOGGLE_RESOURCES_SUBMENU_MODAL = 'structuredContentPlayer/TOGGLE_RESOURCES_SUBMENU_MODAL';
export const TOGGLE_PRINT_VIEW_MODAL = 'structuredContentPlayer/TOGGLE_PRINT_VIEW_MODAL';
export const TOGGLE_DICTIONARY_MODAL = 'structuredContentPlayer/TOGGLE_DICTIONARY_MODAL';
export const CLEAR_CURRENT_CONTENT = 'structuredContentPlayer/CLEAR_CURRENT_CONTENT';
export const SET_CURRENT_TITLES = 'structuredContentPlayer/SET_CURRENT_TITLES';
export const GET_CPT_CONTENT_REQUEST = 'structuredContentPlayer/GET_CPT_CONTENT_REQUEST';
export const GET_MOCK_CPT_CONTENT_REQUEST = 'structuredContentPlayer/GET_MOCK_CPT_CONTENT_REQUEST';
export const GET_CPT_CONTENT_SUCCESS = 'structuredContentPlayer/GET_CPT_CONTENT_SUCCESS';
export const GET_CPT_CONTENT_FAILURE = 'structuredContentPlayer/GET_CPT_CONTENT_FAILURE';

export const GET_ELT_GAMES_REQUEST = 'eltGames/GET_ELT_GAMES_REQUEST';

export const GET_EREADER_CONTENT_REQUEST = 'ereaderContentPlayer/GET_EREADER_CONTENT_REQUEST';
export const GET_EREADER_CONTENT_SUCCESS = 'ereaderContentPlayer/GET_EREADER_CONTENT_SUCCESS';
export const GET_EREADER_CONTENT_FAILURE = 'ereaderContentPlayer/GET_EREADER_CONTENT_FAILURE';

export const GET_PLAYER_LAUNCH_REQUEST = 'structuredContentPlayer/GET_PLAYER_LAUNCH_REQUEST';
export const GET_PLAYER_LAUNCH_SUCCESS = 'structuredContentPlayer/GET_PLAYER_LAUNCH_SUCCESS';
export const GET_PLAYER_LAUNCH_FAILURE = 'structuredContentPlayer/GET_PLAYER_LAUNCH_FAILURE';
export const GET_DICTIONARY_DATA_REQUEST = 'structuredContentPlayer/GET_DICTIONARY_DATA_REQUEST';
export const GET_DICTIONARY_DATA_SUCCESS = 'structuredContentPlayer/GET_DICTIONARY_DATA_SUCCESS';
export const GET_DICTIONARY_DATA_FAILURE = 'structuredContentPlayer/GET_DICTIONARY_DATA_FAILURE';
export const SET_MODAL_CLOSE_LINK = 'structuredContentPlayer/SET_MODAL_CLOSE_LINK';
export const SET_BASE_PATH = 'structuredContentPlayer/SET_BASE_PATH';
export const OPEN_STRUCTURED_CONTENT_PLAYER_MODAL = 'structuredContentPlayer/OPEN_STRUCTURED_CONTENT_PLAYER_MODAL';
export const CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL = 'structuredContentPlayer/CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL';
export const SET_MENU_ORIENTATION = 'structuredContentPlayer/SET_MENU_ORIENTATION';
export const SET_MODAL_CONTENT_ID = 'structuredContentPlayer/SET_MODAL_CONTENT_ID';
export const UPDATE_PRODUCT_LINK = 'structuredContentPlayer/UPDATE_PRODUCT_LINK';
export const GET_XAPI_LAUNCH_TOKEN_REQUEST = 'structuredContentPlayer/GET_XAPI_LAUNCH_TOKEN_REQUEST';
export const GET_XAPI_LAUNCH_TOKEN_SUCCESS = 'structuredContentPlayer/GET_XAPI_LAUNCH_TOKEN_SUCCESS';
export const GET_XAPI_LAUNCH_TOKEN_FAILURE = 'structuredContentPlayer/GET_XAPI_LAUNCH_TOKEN_FAILURE';
export const TOGGLE_MOBILE_GAMES_POPUP = 'structuredContentPlayer/TOGGLE_MOBILE_GAMES_POPUP';
export const SET_PREVIEW_ROLE = 'structuredContentPlayer/SET_PREVIEW_ROLE';
export const SET_PRODUCT_VARIANT = 'structuredContentPlayer/SET_PRODUCT_VARIANT';
export const PROGRESS_BAR_NAVIGATION_CHECK = 'structuredContentPlayer/PROGRESS_BAR_NAVIGATION_CHECK';
export const PROGRESS_BAR_NAVIGATION_RESET_TO_DEFAULT =
  'structuredContentPlayer/PROGRESS_BAR_NAVIGATION_RESET_TO_DEFAULT';
export const PROGRESS_BAR_NAVIGATION_ENABLE = 'structuredContentPlayer/PROGRESS_BAR_NAVIGATION_ENABLE';
export const PROGRESS_BAR_NAVIGATION_DISABLE = 'structuredContentPlayer/PROGRESS_BAR_NAVIGATION_DISABLE';
export const TOGGLE_SWITCH_BOOK = 'structuredContentPlayer/TOGGLE_SWITCH_BOOK';
export const TOGGLE_RESOURCE_MODAL = 'structuredContentPlayer/TOGGLE_RESOURCE_MODAL';
export const SET_RESOURCE_MODAL_DATA = 'structuredContentPlayer/SET_RESOURCE_MODAL_DATA';

// Content Preview
export const GET_CONTENT_PREVIEW_REQUEST = 'contentPreview/GET_CONTENT_PREVIEW_REQUEST';
export const GET_CONTENT_PREVIEW_SUCCESS = 'contentPreview/GET_CONTENT_PREVIEW_SUCCESS';
export const GET_CONTENT_PREVIEW_FAILURE = 'contentPreview/GET_CONTENT_PREVIEW_FAILURE';
export const GET_EREADER_PRODUCTS_REQUEST = 'contentPreview/GET_EREADER_PRODUCTS_REQUEST';

// Delete Product
export const DELETE_PRODUCT_REQUEST = 'deleteProduct/DELTE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'deleteProduct/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'deleteProduct/DELETE_PRODUCT_FAILURE';
export const CLEAR_DELETE_STATE = 'deleteProduct/CLEAR_DELETE_STATE';

// Course Preview
export const GET_COURSE_PREVIEW_REQUEST = 'coursePreview/GET_COURSE_PREVIEW_REQUEST';
export const GET_COURSE_PREVIEW_SUCCESS = 'coursePreview/GET_COURSE_PREVIEW_SUCCESS';
export const GET_COURSE_PREVIEW_FAILURE = 'coursePreview/GET_COURSE_PREVIEW_FAILURE';
export const OPEN_COURSE_PREVIEW_MODAL = 'coursePreview/OPEN_COURSE_PREVIEW_MODAL';
export const CLOSE_COURSE_PREVIEW_MODAL = 'coursePreview/CLOSE_COURSE_PREVIEW_MODAL';
export const SET_COURSE_MODAL_CLOSE_LINK = 'coursePreview/SET_COURSE_MODAL_CLOSE_LINK';
export const LOAD_COURSE_CONTENT_REQUEST = 'coursePreview/LOAD_COURSE_CONTENT_REQUEST';
export const LOAD_COURSE_CONTENT_SUCCESS = 'coursePreview/LOAD_COURSE_CONTENT_SUCCESS';
export const LOAD_COURSE_CONTENT_FAILURE = 'coursePreview/LOAD_COURSE_CONTENT_FAILURE';

// Content Publish
export const GET_COURSE_PUBLISH_REQUEST = 'contentPublish/GET_COURSE_PUBLISH_REQUEST';
export const GET_COURSE_PUBLISH_SUCCESS = 'contentPublish/GET_COURSE_PUBLISH_SUCCESS';
export const GET_COURSE_PUBLISH_FAILURE = 'contentPublish/GET_COURSE_PUBLISH_FAILURE';
export const CLEAR_PUBLISHED_COURSE_DATA = 'contentPublish/CLEAR_PUBLISHED_COURSE_DATA';
export const PUBLISH_COURSE_REQUEST = 'contentPublish/PUBLISH_COURSE_REQUEST';
export const PUBLISH_COURSE_START = 'contentPublish/PUBLISH_COURSE_START';
export const PUBLISH_COURSE_SUCCESS = 'contentPublish/PUBLISH_COURSE_SUCCESS';
export const PUBLISH_COURSE_FAILURE = 'contentPublish/PUBLISH_COURSE_FAILURE';

// MAT
export const MULTI_SCHOOL_DIAL_REPORT_REQUEST = 'matReportSummary/MULTI_SCHOOL_DIAL_REPORT_REQUEST';
export const MULTI_SCHOOL_DIAL_REPORT_ALL_ORGS_ARE_LOADED =
  'matReportSummary/MULTI_SCHOOL_DIAL_REPORT_ALL_ORGS_ARE_LOADED';
export const MULTI_SCHOOL_DIAL_REPORT_FAILURE = 'matReportSummary/MULTI_SCHOOL_DIAL_REPORT_FAILURE';
export const MULTI_SCHOOL_DIAL_REPORT_UPDATE_LOADED_ORGS =
  'matReportSummary/MULTI_SCHOOL_DIAL_REPORT_UPDATE_LOADED_ORGS';
export const MULTI_SCHOOL_DIAL_REPORT_UPDATE_SUMMARY = 'matReportSummary/MULTI_SCHOOL_DIAL_REPORT_UPDATE_SUMMARY';
// ActivationCode Dashboard
export const SET_ACT_CODE_FOR_DASHBOARD = 'activationCodeDashboard/SET_ACT_CODE_FOR_DASHBOARD';
export const SET_ALLOWED_USAGE_FOR_DASHBOARD = 'activationCodeDashboard/SET_ALLOWED_USAGE_FOR_DASHBOARD';
export const SET_BATCH_NAME_FOR_DASHBOARD = 'activationCodeDashboard/SET_BATCH_NAME_FOR_DASHBOARD';
export const SEARCH_ACT_CODE_FOR_DASHBOARD = 'activationCodeDashboard/SEARCH_ACT_CODE_FOR_DASHBOARD';
export const SET_ERROR_FOR_ACT_CODE_DASHBOARD = 'activationCodeDashboard/SET_ERROR_FOR_ACT_CODE_DASHBOARD';
export const SET_RESPONSE_FOR_ACT_CODE_DASHBOARD = 'activationCodeDashboard/SET_RESPONSE_FOR_ACT_CODE_DASHBOARD';
export const CREATE_ACT_CODE_FOR_DASHBOARD = 'activationCodeDashboard/CREATE_ACT_CODE_FOR_DASHBOARD';
export const DELETE_ACT_CODE_FOR_DASHBOARD = 'activationCodeDashboard/DELETE_ACT_CODE_FOR_DASHBOARD';
export const GET_ACT_CODE_DETAILS_FOR_DASHBOARD = 'activationCodeDashboard/GET_ACT_CODE_DETAILS_FOR_DASHBOARD';
export const SET_ACT_CODE_DETAILS_FOR_DASHBOARD = 'activationCodeDashboard/SET_ACT_CODE_DETAILS_FOR_DASHBOARD';
export const SET_ACT_CODE_DETAILS_ERROR_FOR_DASHBOARD =
  'activationCodeDashboard/SET_ACT_CODE_DETAILS_ERROR_FOR_DASHBOARD';
export const RESET_SEARCH_ACT_CODES = 'activationCodeDashboard/RESET_SEARCH_ACT_CODES';
export const SEARCH_ACT_CODES_START = 'activationCodeDashboard/SEARCH_ACT_CODES_START';

// Assets page
export const POST_UPLOAD_CONTENT_PACKAGE_REQUEST = 'assetsPage/POST_UPLOAD_CONTENT_PACKAGE_REQUEST';
export const POST_UPLOAD_CONTENT_PACKAGE_START = 'assetsPage/POST_UPLOAD_CONTENT_PACKAGE_START';
export const POST_UPLOAD_CONTENT_PACKAGE_SUCCESS = 'assetsPage/POST_UPLOAD_CONTENT_PACKAGE_SUCCESS';
export const POST_UPLOAD_CONTENT_PACKAGE_FAILURE = 'assetsPage/POST_UPLOAD_CONTENT_PACKAGE_FAILURE';

// Lookup product
export const LOOKUP_PRODUCT_FOR_COMPLETION = 'lookupProduct/LOOKUP_PRODUCT_FOR_COMPLETION';
export const LOOKUP_PRODUCT_SET_DATA = 'lookupProduct/LOOKUP_PRODUCT_SET_DATA';
export const LOOKUP_PRODUCT_SET_ERROR = 'lookupProduct/LOOKUP_PRODUCT_SET_ERROR';

// Searh users
export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';

// Impersonate user
export const IMPERSONATE_USER_REQUEST = 'IMPERSONATE_USER_REQUEST';
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS';
export const IMPERSONATE_USER_FAILURE = 'IMPERSONATE_USER_FAILURE';

// ActivationCodeBatch
export const SEARCH_FOR_PRODUCT_OR_PRODUCTGROUP = 'activationCodeBatch/GET_PRODUCT_OR_PRODUCTGROUP';
export const CREATE_CODE_BATCH_REQUEST = 'CREATE_CODE_BATCH_REQUEST';
export const CREATE_CODE_BATCH_SUCCESS = 'CREATE_CODE_BATCH_SUCCESS';
export const CREATE_CODE_BATCH_FAILURE = 'CREATE_CODE_BATCH_FAILURE';

// Product Group Page
export const SEARCH_PRODUCT_GROUP = 'productGroup/SEARCH_PRODUCT_GROUP';
export const RESET_PRODUCT_GROUP = 'productGroup/RESET_PRODUCT_GROUP';

// Product Create
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const CREATE_PRODUCT_ORG_UNITS = 'CREATE_PRODUCT_ORG_UNITS';
export const CREATE_PRODUCT_ORG_UNITS_SUCCESS = 'CREATE_PRODUCT_ORG_UNITS_SUCCESS';
export const CREATE_PRODUCT_ORG_UNITS_FAILURE = 'CREATE_PRODUCT_ORG_UNITS_FAILURE';
export const CREATE_PRODUCT_GET_PLATFORMS = 'CREATE_PRODUCT_GET_PLATFORMS';
export const CREATE_PRODUCT_PLATFORMS_SUCCESS = 'CREATE_PRODUCT_PLATFORMS_SUCCESS';
export const CREATE_PRODUCT_PLATFORMS_FAILURE = 'CREATE_PRODUCT_PLATFORMS_FAILURE';
export const CREATE_PRODUCT_GET_EXTERNAL_IDS = 'CREATE_PRODUCT_GET_EXTERNAL_IDS';
export const CREATE_PRODUCT_EXTERNAL_IDS_SUCCESS = 'CREATE_PRODUCT_EXTERNAL_IDS_SUCCESS';
export const CREATE_PRODUCT_EXTERNAL_IDS_FAILURE = 'CREATE_PRODUCT_EXTERNAL_IDS_FAILURE';

// Product Search
export const SEARCH_PRODUCT_REQUEST = 'SEARCH_PRODUCT_REQUEST';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const SEARCH_PRODUCT_FAILURE = 'SEARCH_PRODUCT_FAILURE';
export const EMPTY_SEARCH_PRODUCT_RESULTS = 'EMPTY_SEARCH_PRODUCT_RESULTS';

// User Administration Console
export const SEARCH_USER_FOR_CONSOLE = 'userAdminConsole/SEARCH_USER_FOR_CONSOLE';
export const SET_USER_SEARCH_RESULT_FOR_CONSOLE = 'userAdminConsole/SET_USER_SEARCH_RESULT_FOR_CONSOLE';
export const SET_USER_SEARCH_ERROR_FOR_CONSOLE = 'userAdminConsole/SET_USER_SEARCH_ERROR_FOR_CONSOLE';
export const STOP_USER_SEARCH_FOR_CONSOLE = 'userAdminConsole/STOP_USER_SEARCH_FOR_CONSOLE';
export const EMPTY_USER_SEARCH_RESULTS_FOR_CONSOLE = 'userAdminConsole/EMPTY_USER_SEARCH_RESULTS_FOR_CONSOLE';

// Search Users Edit
export const SEARCH_USER_EDIT_REQUEST = 'SEARCH_USER_EDIT_REQUEST';
export const SEARCH_USER_EDIT_SUCCESS = 'SEARCH_USER_EDIT_SUCCESS';
export const SEARCH_USER_EDIT_FAILURE = 'SEARCH_USER_EDIT_FAILURE';

// Act Code Batch Page
export const SEARCH_ACT_CODE_BATCH = 'actCodeBatchSearch/SEARCH_ACT_CODE_BATCH';
export const SET_RESULT_ACT_CODE_BATCH = 'actCodeBatchSearch/SET_RESULT_ACT_CODE_BATCH';
export const SET_ERROR_ACT_CODE_BATCH = 'actCodeBatchSearch/SET_ERROR_ACT_CODE_BATCH';
export const STOP_SEARCH_ACT_CODE_BATCH = 'actCodeBatchSearch/STOP_SEARCH_ACT_CODE_BATCH';
export const EMPTY_SEARCH_ACT_CODE_BATCH_RESULTS = 'actCodeBatchSearch/EMPTY_SEARCH_ACT_CODE_BATCH_RESULTS';
export const DOWNLOAD_ACT_CODE_BATCH_CODES = 'actCodeBatchSearch/DOWNLOAD_ACT_CODE_BATCH_CODES';

// Course contents
export const GET_COURSE_CONTENTS_REQUEST = 'courseContents/GET_COURSE_CONTENTS_REQUEST';
export const GET_COURSE_CONTENTS_SUCCESS = 'courseContents/GET_COURSE_CONTENTS_SUCCESS';
export const GET_COURSE_CONTENTS_FAILURE = 'courseContents/GET_COURSE_CONTENTS_FAILURE';

// Invoice report

export const CREATE_INVOICE_REPORT_INPUT = 'invoiceReport/CREATE_INVOICE_REPORT_INPUT';
export const CREATE_INVOICE_REPORT_REQUEST = 'invoiceReport/CREATE_INVOICE_REPORT_REQUEST';
export const CREATE_INVOICE_REPORT_SUCCESS = 'invoiceReport/CREATE_INVOICE_REPORT_SUCCESS';
export const CREATE_INVOICE_REPORT_FAILURE = 'invoiceReport/CREATE_INVOICE_REPORT_FAILURE';
export const CREATE_INVOICE_REPORT_VALIDATE = 'invoiceReport/CREATE_INVOICE_REPORT_VALIDATE';
export const CREATE_INVOICE_REPORT_VALIDATED = 'invoiceReport/CREATE_INVOICE_REPORT_VALIDATED';

// reCAPTCHA

export const RECAPTCHA_SET_SITE_KEY = 'reCAPTCHA/RECAPTCHA_SET_SITE_KEY';
export const RECAPTCHA_SET_TOKEN = 'reCAPTCHA/RECAPTCHA_SET_TOKEN';
export const RECAPTCHA_SUBMIT = 'reCAPTCHA/RECAPTCHA_SUBMIT';
export const RECAPTCHA_RESET = 'reCAPTCHA/RECAPTCHA_RESET';
export const RECAPTCHA_SUCCESS = 'reCAPTCHA/RECAPTCHA_SUCCESS';
export const RECAPTCHA_BOT = 'reCAPTCHA/RECAPTCHA_BOT';
export const RECAPTCHA_FAILURE = 'reCAPTCHA/RECAPTCHA_FAILURE';

// Supervised user
export const SET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_DATA = 'SET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_DATA';
export const REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_REQUEST = 'REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_REQUEST';
export const REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_SUCCESS = 'REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_SUCCESS';
export const REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_ERROR = 'REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_ERROR';
export const RESET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_STATE =
  'RESET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_STATE';

// Edit current user details with no old password required
export const EDIT_CURRENT_USER_DETAILS_REQUEST = 'EDIT_CURRENT_USER_DETAILS_REQUEST';
export const EDIT_CURRENT_USER_DETAILS_REQUEST_SUCCESS = 'EDIT_CURRENT_USER_DETAILS_REQUEST_SUCCESS';
export const EDIT_CURRENT_USER_DETAILS_REQUEST_ERROR = 'EDIT_CURRENT_USER_DETAILS_REQUEST_ERROR';
export const RESET_EDIT_CURRENT_USER_DETAILS_REQUEST_STATE = 'RESET_EDIT_CURRENT_USER_DETAILS_REQUEST_STATE';
export const IS_EMAIL_TAKEN_REQUEST = 'IS_EMAIL_TAKEN_REQUEST';
export const SET_IS_EMAIL_TAKEN = 'SET_IS_EMAIL_TAKEN';

export const GET_USER_PLATFORM_STATISTICS_SUCCESS = 'GET_USER_PLATFORM_STATISTICS_SUCCESS';

// Inspection Copies
export const GET_SUBSCRIPTION_ID_REQUEST = 'GET_SUBSCRIPTION_ID_REQUEST';
export const GET_SUBSCRIPTION_RESET = 'GET_SUBSCRIPTION_RESET';
export const GET_SUBSCRIPTION_ID_SUCCESS = 'GET_SUBSCRIPTION_ID_SUCCESS';
export const GET_SUBSCRIPTION_ID_FAILURE = 'GET_SUBSCRIPTION_ID_FAILURE';

export const GENERATE_DOWNLOAD_LINKS_REQUEST = 'GENERATE_DOWNLOAD_LINKS_REQUEST';
export const GENERATE_DOWNLOAD_LINKS_SUCCESS = 'GENERATE_DOWNLOAD_LINKS_SUCCESS';
export const GENERATE_DOWNLOAD_LINKS_FAILURE = 'GENERATE_DOWNLOAD_LINKS_FAILURE';

export const DELETE_GUEST_USER_LICENCES = 'DELETE_GUEST_USER_LICENCES';

export const GET_BOOK_TITLE_REQUEST = 'GET_BOOK_TITLE_REQUEST';
export const GET_BOOK_TITLE_SUCCESS = 'GET_BOOK_TITLE_SUCCESS';
export const GET_BOOK_TITLE_FAILURE = 'GET_BOOK_TITLE_FAILURE';

export const CREATE_USER_ACTIVITY_REQUEST = 'CREATE_USER_ACTIVITY_REQUEST';
export const CREATE_USER_ACTIVITY_SUCCESS = 'CREATE_USER_ACTIVITY_SUCCESS';
export const CREATE_USER_ACTIVITY_FAILURE = 'CREATE_USER_ACTIVITY_FAILURE';

// Manage students' access to the product content
export const PRODUCT_LOCKS_RESET_STATE = 'PRODUCT_LOCKS_RESET_STATE';
export const GET_PRODUCT_LOCKS_REQUEST = 'GET_PRODUCT_LOCKS_REQUEST';
export const GET_PRODUCT_LOCKS_SUCCESS = 'GET_PRODUCT_LOCKS_SUCCESS';
export const GET_PRODUCT_LOCKS_ERROR = 'GET_PRODUCT_LOCKS_ERROR';
export const UPDATE_PRODUCT_LOCKS_REQUEST = 'UPDATE_PRODUCT_LOCKS_REQUEST';
export const UPDATE_PRODUCT_LOCKS_SUCCESS = 'UPDATE_PRODUCT_LOCKS_SUCCESS';
export const UPDATE_PRODUCT_LOCKS_ERROR = 'UPDATE_PRODUCT_LOCKS_ERROR';

// Product structure
export const PRODUCT_STRUCTURE_RESET_STATE = 'PRODUCT_STRUCTURE_RESET_STATE';
export const GET_PRODUCT_STRUCTURE_REQUEST = 'GET_PRODUCT_STRUCTURE_REQUEST';
export const GET_PRODUCT_STRUCTURE_SUCCESS = 'GET_PRODUCT_STRUCTURE_SUCCESS';
export const GET_PRODUCT_STRUCTURE_ERROR = 'GET_PRODUCT_STRUCTURE_ERROR';

// School report
export const INITIALISE = 'schoolReport/INITIALISE';
export const GENERATE_SCHOOL_REPORT_INPUT = 'schoolReport/GENERATE_SCHOOL_REPORT_INPUT';
export const GENERATE_SCHOOL_REPORT_REQUEST = 'schoolReport/GENERATE_SCHOOL_REPORT_REQUEST';
export const GENERATE_SCHOOL_REPORT_SUCCESS = 'schoolReport/GENERATE_SCHOOL_REPORT_SUCCESS';
export const GENERATE_SCHOOL_REPORT_FAILURE = 'schoolReport/GENERATE_SCHOOL_REPORT_FAILURE';

// Recently accessed

export const GET_RECENTLY_ACCESSED_REQUEST = 'GET_RECENTLY_ACCESSED_REQUEST';
export const GET_RECENTLY_ACCESSED_SUCCESS = 'GET_RECENTLY_ACCESSED_SUCCESS';
export const GET_RECENTLY_ACCESSED_ERROR = 'GET_RECENTLY_ACCESSED_ERROR';
export const UPDATE_RECENTLY_ACCESSED_REQUEST = 'UPDATE_RECENTLY_ACCESSED_REQUEST';
export const UPDATE_RECENTLY_ACCESSED_SUCCESS = 'UPDATE_RECENTLY_ACCESSED_SUCCESS';
export const UPDATE_RECENTLY_ACCESSED_ERROR = 'UPDATE_RECENTLY_ACCESSED_ERROR';

// Org Usage

export const GET_ORG_USAGE_REQUEST = 'GET_ORG_USAGE_REQUEST';
export const GET_ORG_USAGE_SUCCESS = 'GET_ORG_USAGE_SUCCESS';
export const GET_ORG_USAGE_ERROR = 'GET_ORG_USAGE_ERROR';

// Bulk password reset
export const BULK_PASSWORD_RESET_REQUEST = 'BULK_PASSWORD_RESET_REQUEST';
export const BULK_PASSWORD_RESET_SUCCESS = 'BULK_PASSWORD_RESET_SUCCESS';
export const BULK_PASSWORD_RESET_ERROR = 'BULK_PASSWORD_RESET_ERROR';
export const BULK_PASSWORD_RESET_STATE_RESET = 'BULK_PASSWORD_RESET_STATE_RESET';

// Word of the day

export const WORD_OF_THE_DAY_REQUEST = 'WORD_OF_THE_DAY_REQUEST';
export const WORD_OF_THE_DAY_SUCCESS = 'WORD_OF_THE_DAY_SUCCESS';
export const WORD_OF_THE_DAY_ERROR = 'WORD_OF_THE_DAY_ERROR';
