/* eslint-disable react/jsx-no-bind */
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';

import styles from './OrbTeacherContactPreferencesForm.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

import Button, { buttonTypes } from '../Button/Button';
import MarketingPreferencesConsentCheckbox from '../MarketingPreferencesConsentForm/MarketingPreferencesConsentCheckbox'

class OrbTeacherContactPreferencesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert_level_changes: false,
      advice_and_tips: true,
      news_and_offers: true,
      ...props.data
    };
  }

  onRadioChange = (key, { target }) => {
    this.setState({ [key]: target.value === 'true' });
  };

  onCheckboxChange = (key, checked) => {
    const groupKeys = ['advice_and_tips', 'news_and_offers'];
    groupKeys.map(elem => this.setState({ [elem]: !checked }));
  };

  onSubmitForm = () => {
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  renderRadioSection = section => {
    const value = (!!this.state[section.key]).toString();

    return (
      <ul>
        {section.items.map((item, index) => (
          <li key={index}>
            <input
              type="radio"
              name={section.key}
              id={item.key}
              value={item.key}
              onChange={this.onRadioChange.bind(this, section.key)}
              checked={value === item.key}
            />
            <label htmlFor={item.key}>{item.title}</label>
          </li>
        ))}
      </ul>
    );
  };

  renderCheckboxSection = section => (
    <ul>
      {section.items.map((item, index) => (
        <li key={index}>

          <MarketingPreferencesConsentCheckbox
                useOldCheckboxComponent={true}
                name={item.key}
                id={item.key}
                testId={item.testId}
                update={this.onCheckboxChange.bind(this, item.key)}
                state={(!!(this.state.advice_and_tips && this.state.news_and_offers) === false)}
                className={styles.checkboxLabel}
              />
        </li>
      ))}
    </ul>
  );

  renderSection = section => (
    <div className={styles.section} key={section.key}>
      <h3>{section.title}</h3>
      {section.subTitle && <p dangerouslySetInnerHTML={{ __html: section.subTitle }} />}
      {this[`render${startCase(section.type)}Section`](section)}
      {section.info && (
        <div className={styles.info}>
          <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
          <i dangerouslySetInnerHTML={{ __html: section.info }} />
        </div>
      )}
    </div>
  );

  renderActions() {
    const {
      localizedContent: { orbTeacherContactPreferences: i18n }
    } = this.props;

    return (
      <div className={styles.actions}>
        <Button id="primary-button" type={buttonTypes.PRIMARY} text={i18n.done} onClick={this.onSubmitForm} />
      </div>
    );
  }

  render() {
    const {
      localizedContent: { orbTeacherContactPreferences: i18n }
    } = this.props;

    const options = {
      sections: [
        {
          title: i18n.pupil_progress_alerts,
          subTitle: false,
          type: 'radio',
          key: 'alert_level_changes',
          items: [
            {
              title: i18n.i_want_progress_alerts,
              key: 'true'
            },
            { title: i18n.i_dont_want_progress_alerts, key: 'false' }
          ],
          info: `<a href='https://support.oxfordreadingbuddy.com/teacher-support/levels-reporting/automatic-progression/' target='_blank'>${i18n.find_out_more}</a> ${i18n.about_progress_alerts}`
        },
        {
          title: i18n.marketing_emails,
          subTitle: i18n.privacy_policy_info_markdown,
          type: 'checkbox',
          testId: "marketing_pref",
          items: [
            {
              title: i18n.i_want_advice_and_tips,
              key: 'advice_and_tips'
            }
          ],
          info: false
        }
      ]
    };

    return (
      <div className="form">
        {options.sections.map(this.renderSection)}
        {this.renderActions()}
      </div>
    );
  }
}

OrbTeacherContactPreferencesForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('orbTeacherContactPreferences'))(OrbTeacherContactPreferencesForm);
