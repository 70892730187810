import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import withRouter from '../../../../utils/withRouter';
import compose from '../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import styles from './OicAssessmentsTab.scss';
import markdown from '../../../../utils/markdown/markdown';

function OicAssessmentsTab({ firstName, localizedContent: { oicAssessmentsTab: content = {} }, isOrgLinkedToJanison }) {
  return (
    <div className={classnames('pad-top4', styles.oisaBkgPattern)}>
      <div className={classnames('grid justify-content-between', styles.assessementsTab)}>
        <div className="row">
          <div className="col">
            <h3 className={styles.title}>{(content.heading_title || '').replace('{firstName}', firstName)}</h3>
            <p className={classnames('gin-top2 gin-bot2', styles.description)}>{content.description}</p>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.actionsList} dangerouslySetInnerHTML={{ __html: markdown(content.details) }} />
            <div className="pad-top4">
              <Tooltip
                title={isOrgLinkedToJanison === false ? content.org_not_linked_to_janison_help_text : ''}
                className={styles.tooltip}
              >
                <div className={styles.assessmentsLink}>
                  <Button
                    id="oicJanisonAssessments"
                    type={buttonTypes.ACTION}
                    text={content.assessments_button_label}
                    to="/oisa-assessments/launch-janison"
                    ariaLabel={content.assessments_button}
                    disabled={!isOrgLinkedToJanison}
                    disableExternalBehaviour
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withRouter,
  withLocalizedContent('oicAssessmentsTab'),
  connect(
    state => ({
      firstName: state.identity.firstName
    }),
    {}
  )
)(OicAssessmentsTab);

OicAssessmentsTab.propTypes = {
  firstName: PropTypes.string.isRequired,
  localizedContent: PropTypes.object,
  isOrgLinkedToJanison: PropTypes.bool.isRequired
};
