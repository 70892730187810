import createFactory from './createFactory';
import setDisplayName from './setDisplayName';
import wrapDisplayName from './wrapDisplayName';

const mapProps = propsMapper => BaseComponent => {
  const factory = createFactory(BaseComponent);
  const MapProps = props => factory(propsMapper(props));

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'mapProps'))(MapProps);
  }
  return MapProps;
};

export default mapProps;
