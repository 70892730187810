import PropTypes from 'prop-types';
import { parse as parseQueryString } from 'query-string';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import withRouter from '../../utils/withRouter';
import Button from '../../components/Button/Button';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { setFieldValue } from '../../redux/reducers/data/fields.reducer';
import { setFormState } from '../../redux/reducers/data/forms.reducer';
import ConnectedField from '../CreateOrganisation/formUtil/ConnectedField';
import FormController from '../CreateOrganisation/formUtil/FormController';
import cmsContent from '../../utils/cmsContent';
import { getFieldValue } from '../../redux/sagas/appFunctions/formHandlers/formUtil';
import { validatePassword } from '../../redux/actions/resetPassword';
import ErrorView from '../../views/errors/ErrorView';
import PasswordUpdateView from '../../views/PasswordUpdateView';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';

class ResetPassword extends Component {
  componentWillMount() {
    const { initialize } = this.props;
    initialize();
  }

  render() {
    const {
      location,
      match: {
        params: { token }
      },
      password,
      errors,
      setPassword,
      validate,
      submitFormAction,
      resetFormAction
    } = this.props;
    const CMS = cmsContent.registerPasswordReset || {};
    const queryString = parseQueryString(location.search);
    const firstName = queryString.firstName || '';

    return (
      <div>
        <Helmet title={CMS.tabTitle} />
        <ConnectedField formName="resetPassword" fieldName="token" initialValue={token} />
        <ConnectedField formName="resetPassword" fieldName="platform" initialValue={queryString.platform} />
        <FormController
          formName="resetPassword"
          initialFormState="LOADING"
          formStateMapping={{
            LOADING: (
              <div className="pad-top8 pad-bot8">
                <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_title_text} />
              </div>
            ),
            LOADING_ERROR: (
              <ErrorView title="Unable to validate request" detail="Please try again later" onRetry={resetFormAction} />
            ),
            TOKEN_EXPIRED: (
              <div className="text-center pad-top8 gin-bot8">
                <PopoutPanelIconHeading type={types.ERROR} title={CMS.pageTitle} subtitle={CMS.pageSubtitle} />
                <div className="text-center gin-top2">
                  <Button id="reset-password" text={CMS.button_forgotten_password_text} to="/reset-password" />
                </div>
              </div>
            ),
            INPUTTING: (
              <PasswordUpdateView
                firstName={firstName}
                password={password}
                onPasswordChange={setPassword}
                onPasswordValidate={() => validate(password)}
                errors={errors}
                onSubmit={submitFormAction}
                platform={queryString.platform}
              />
            ),
            SUBMITTING: (
              <div className="pad-top8 pad-bot8">
                <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_title_text} />
              </div>
            ),
            CONFIRMATION: <ResetPasswordConfirmation />
          }}
        />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    })
  }).isRequired,
  initialize: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.object,
  setPassword: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired
};

export default connect(
  state => {
    if (!('resetPassword' in state.forms)) {
      return {};
    }

    const { errors } = state.resetPassword;
    // Map legacy forms pattern state to fully-controlled PasswordUpdateView
    const password = getFieldValue(state, 'resetPassword', 'password');

    return {
      password,
      errors
    };
  },
  dispatch => ({
    initialize: () => dispatch(setFieldValue('resetPassword', 'password', '')),

    // Map legacy forms pattern actions to PasswordUpdateView
    setPassword: password => dispatch(setFieldValue('resetPassword', 'password', password)),
    validate: password => {
      dispatch(validatePassword(password));
    },
    submitFormAction: () => dispatch(setFormState('resetPassword', 'SUBMITTING')),
    resetFormAction: () => dispatch(setFormState('resetPassword', 'LOADING'))
  })
)(withRouter(ResetPassword));
