import React from 'react';
import PropTypes from 'prop-types';
import { TextLink } from '@oup/shared-front-end';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import TextInput from '../TextInput/TextInput.js';
import Tooltip from '../Tooltip/Tooltip';
import Button, { buttonTypes } from '../Button/Button.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import Validation from '../Validation/Validation.js';
import styles from './ClassCode.scss';
import tooltipTitleText from '../Tooltip/TooltipUtils';
import { isHubMode } from '../../utils/platform';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

function ClassCode({
  isClassCodeEditable,
  isToggleEnable,
  classJoiningCode,
  onCodeChange,
  label = 'Copy class code',
  localizedContent: { classCodeComponent: content, hubGlossary: hubContent },
  validationParams
}) {
  const { customOrgId: orgId, classJoiningCode: code } = classJoiningCode;
  const buttonIds = !isClassCodeEditable ? `copyCodeId-${code}` : `copyCodeIdIsEdit-${code}`;

  const updateTheTooltipText = (buttonId, text) => {
    const copyButton = document.getElementById(buttonId);
    copyButton.setAttribute('data-tooltip', text);
    copyButton.setAttribute('aria-label', text);
    copyButton.blur();
  };
  const copyCodeToClipBoard = e => {
    e.preventDefault();
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('value', `${orgId}-${code}`);
    document.body.appendChild(hiddenInput);
    hiddenInput.select();
    const result = document.execCommand('copy');
    document.body.removeChild(hiddenInput);
    updateTheTooltipText(buttonIds, 'Code copied!');
    return result;
  };

  const toolTipText = (
    <>
      <p>{content.copy_share_code}</p>
      <p>{content.your_students_need_to}</p>
      <ul>
        <li>
          {content.go_to_text} <TextLink to={content.hub_url}>www.englishhub.oup.com</TextLink>
        </li>
        <li>{content.register_or_sign_in}</li>
        <li>{content.go_to_my_class}</li>
        <li>{content.select_join_class}</li>
        <li>{content.enter_joining_code}</li>
      </ul>
    </>
  );

  return (
    <div className={styles.classCodeWrapper}>
      <div className={styles.classCode}>
        <div className={styles.mainContainer}>
          <ConditionalWrapper
            condition={isClassCodeEditable}
            // eslint-disable-next-line react/no-unstable-nested-components
            wrapper={children => (
              <Validation
                isValid={validationParams && validationParams.classroomJoiningCodeValidationIsvalid}
                isWaiting={validationParams && validationParams.classroomCodeValidationPending}
                isError={validationParams && validationParams.classroomJoiningCodeValidationIsInvalid}
                message={validationParams && validationParams.classroomJoiningCodeValidationMessage}
                forId="classCodeText"
                noInputErrorTopSpacing
              >
                {children}
              </Validation>
            )}
          >
            <div className={styles.classCodeInputWrap}>
              {isClassCodeEditable ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {isToggleEnable ? (
                    <>
                      <div className={styles.orgIdSection}>{orgId}</div>

                      <TextInput id="classCodeText" value={code} maxLength={30} labelHidden onChange={onCodeChange} />
                    </>
                  ) : (
                    <div style={{ margin: '8px 0 0 0' }}>{content.turn_class_code}</div>
                  )}
                </>
              ) : (
                <p className={styles.classCodeInfo}>
                  <strong> {orgId}</strong>-{code}
                </p>
              )}
              {(isToggleEnable || !isClassCodeEditable) && (
                <div className={styles.copySection} onMouseLeave={() => updateTheTooltipText(buttonIds, label)}>
                  <Button
                    id={buttonIds}
                    iconOnly
                    onClick={copyCodeToClipBoard}
                    type={buttonTypes.COPY_CLIPBOARD}
                    preventDefault={false}
                    title={label}
                  />
                </div>
              )}
              {isClassCodeEditable && (
                <div>
                  <Tooltip
                    title={isHubMode() ? tooltipTitleText(hubContent) : toolTipText}
                    className={styles.largeTooltip}
                  >
                    <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
                  </Tooltip>
                </div>
              )}
            </div>
          </ConditionalWrapper>
        </div>
      </div>
      {isToggleEnable && <p className={styles.helpText}>{content.change_class_code}</p>}
    </div>
  );
}

ClassCode.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  isClassCodeEditable: PropTypes.bool,
  isToggleEnable: PropTypes.bool,
  onCodeChange: PropTypes.func,
  classJoiningCode: PropTypes.object.isRequired,
  validationParams: PropTypes.object,
  label: PropTypes.string
};

export default withLocalizedContent('classCodeComponent', 'hubGlossary')(ClassCode);
