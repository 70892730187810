import PropTypes from 'prop-types';
import React from 'react';

import compose from '../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';

import { SidePanel } from '../../../../components';
import EditUserForm from './EditUserForm';

function EditUserPanel({ isOpen, onCloseAction, userData }) {
  return (
    <SidePanel id="UserEdit" ariaLabel="test title" confirmBeforeClose={false} isOpen={isOpen} onClose={onCloseAction}>
      <EditUserForm userData={userData} onCloseAction={onCloseAction} />
    </SidePanel>
  );
}

EditUserPanel.propTypes = {
  isOpen: PropTypes.bool,
  userData: PropTypes.object,
  onCloseAction: PropTypes.func
};

export default compose(withLocalizedContent('userSearchEditPanel'))(EditUserPanel);
