import { select } from 'redux-saga/effects';

import joinCLass from './joinClassApi.js';

export default function* redeemClassCodeRequest() {
  const { joinCode, joinCodeType } = yield select(state => ({
    joinCode: state.joinClass.classCodeValue,
    joinCodeType: state.joinClass?.classCodeType
  }));

  // Assemble request body to send to API:
  const payload = {
    code: joinCode,
    codeType: joinCodeType
  };

  console.log(
    `[redeemClassCode Saga] Submitting request to enrol user in class with joining code: ${JSON.stringify(payload)}.`
  );
  return yield joinCLass(payload);
}
