import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';

import { validSizes } from '../Thumbnail/Thumbnail';
import ClassRepresentation from './ClassRepresentation';
import { featureIsEnabled } from '../../globals/envSettings';
import userRoles from '../../globals/userRoles.js';
import { orgRoles as OrgRoles } from '../../globals/orgRoles';
import Link from '../Link/Link.js';

// Localized Content
import withLocalizedContent from '../../language/withLocalizedContent';

import styles from './ConnectedClassRepresentation.scss';
import { isCesMode, isHubMode } from '../../utils/platform';

const getTeacherName = (teacherNameList, teacherName) => {
  if (!teacherNameList && teacherName) {
    return teacherName.length === 1 ? `Teacher: ${teacherName[0]}` : `${teacherName.length} teachers`;
  }
  if (teacherNameList && !teacherName) {
    return teacherNameList.length === 1 ? `Teacher: ${teacherNameList[0]}` : `${teacherNameList.length} teachers`;
  }
  return '';
};

const subtextString = (
  amountOfTeachers,
  teacherName,
  teacherNameList,
  amountOfLearners,
  showAmountStudents,
  sharedTerms,
  classCodeComponent,
  userRole,
  joiningCode,
  customOrgId,
  linkTo,
  isPrimarySchool,
  isArchived
) => {
  const teacherLabel = isHubMode() ? getTeacherName(teacherNameList, teacherName) : `1 ${sharedTerms.TEACHER}`;
  let subText = amountOfTeachers === 1 ? teacherLabel : '';
  subText = amountOfTeachers > 1 ? `${amountOfTeachers} ${sharedTerms.TEACHERS}` : subText;
  subText = showAmountStudents ? `${subText} ${subText && ' • '} ${amountOfLearners} ${sharedTerms.STUDENTS}` : subText;

  const classCodeTurnedOff = (
    <p>
      {classCodeComponent.class_code_off}
      <Link underlined className={styles.classCodeLink} to={`${linkTo}#openEditPanel`}>
        {classCodeComponent.turn_on}
      </Link>
    </p>
  );

  const classCode =
    joiningCode && joiningCode.enabled ? (
      <p>
        <span className={styles.classCode}>
          <strong>{customOrgId}</strong>
        </span>{' '}
        -{joiningCode.code}
      </p>
    ) : (
      !isArchived && classCodeTurnedOff
    );
  return featureIsEnabled('class-joining-code') && isCesMode() && userRole === userRoles.ORG_ADMIN && !isPrimarySchool
    ? classCode
    : subText;
};

/** This component consists of a persons initials in a thumbnail, their name and their email. The name can be a link */
function ConnectedClassRepresentation({
  id,
  entityId,
  thumbnailSize,
  linkIdPrefix,
  linkTo,
  subtext,
  deletedStyle,
  // Redux connected props:
  name,
  amountOfTeachers,
  teacherName,
  teacherNameList,
  renderLearnerAmount = false,
  amountOfLearners,
  showAmountStudents = false,
  isSelected = false,
  isNewEntity = false,
  userRole,
  joiningCode,
  localizedContent: { sharedTerms, classCodeComponent },
  customOrgId,
  isPrimarySchool,
  isArchived
}) {
  return (
    <ClassRepresentation
      id={id || `classroomEntity-${entityId}`}
      thumbnailSize={thumbnailSize}
      linkId={linkIdPrefix ? `${linkIdPrefix}-${entityId}` : null}
      linkTo={linkTo}
      name={name}
      subtext={
        subtext ||
        subtextString(
          amountOfTeachers,
          teacherName,
          teacherNameList,
          amountOfLearners,
          showAmountStudents,
          sharedTerms,
          classCodeComponent,
          userRole,
          joiningCode,
          customOrgId,
          linkTo,
          isPrimarySchool,
          isArchived
        )
      }
      statusText={renderLearnerAmount ? `${amountOfLearners} students in class` : undefined}
      deletedStyle={deletedStyle}
      isSelected={isSelected}
      isNewEntity={isNewEntity}
      userRole={userRole}
      joiningCode={joiningCode}
      customOrgId={customOrgId}
      isPrimarySchool={isPrimarySchool}
    />
  );
}

ConnectedClassRepresentation.propTypes = {
  /** Id for this component */
  id: PropTypes.string.isRequired,
  /** Id to look up in store  */
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired,
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes).isRequired,
  /** Context prefix for link IDs */
  linkIdPrefix: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Second line text */
  subtext: PropTypes.string,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,
  // Redux connected props:
  /** Class name */
  name: PropTypes.string.isRequired,
  /** Teacher names will be listed instead when subtext is not specified */
  amountOfTeachers: PropTypes.number.isRequired,
  teacherName: PropTypes.array,
  teacherNameList: PropTypes.array,
  amountOfLearners: PropTypes.number.isRequired,
  /** Class joining code object */
  joiningCode: PropTypes.object,
  /** Flag to determine rendering the amount of students in class */
  renderLearnerAmount: PropTypes.bool,
  showAmountStudents: PropTypes.bool,
  /** Entity is selected */
  isSelected: PropTypes.bool,
  /** Entity is new */
  isNewEntity: PropTypes.bool,
  /** localizedContent shared terms */
  localizedContent: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  customOrgId: PropTypes.string,
  isPrimarySchool: PropTypes.bool,
  isArchived: PropTypes.bool
};

export default compose(
  withLocalizedContent('sharedTerms', 'classCodeComponent'),
  connect((state, { entityId, orgId }) => {
    const classroom = state.classrooms.data[entityId] || {};
    const currentOrgId = orgId || state.identity.currentOrganisationId;
    const { customId: customOrgId } = state.organisations.data[currentOrgId];
    const isPrimarySchool = state.organisations.data[currentOrgId].role === OrgRoles.PRIMARY_SCHOOL;
    return {
      name: classroom.name,
      amountOfTeachers: classroom.amountOfTeachers,
      amountOfLearners: classroom.amountOfLearners,
      teacherName: classroom.teacherName,
      teacherNameList: classroom.teacherNameList,
      joiningCode: classroom.joiningCode,
      customOrgId,
      isPrimarySchool,
      isArchived: classroom.archived
    };
  })
)(ConnectedClassRepresentation);
