import { PLACEMENT_TEST_STATUS, PLACEMENT_TEST_RESULTS_STATUS } from '@oup/shared-node-browser/constants';

const getPlacementTestActionButtons = (
  hubContent,
  {
    viewProgress = null,
    downloadProgress = null,
    viewSessionInfo = null,
    continueSetupSession = null,
    manageSession = null,
    downloadResults = null,
    copyJoiningCode = null,
    accessTest = null,
    downloadReportCard = null
  },
  { testStatus = '', showResultsToStudents = false },
  isMyTasksPage,
  placementTestsContent
) => {
  if (testStatus === PLACEMENT_TEST_STATUS.DRAFT) {
    return [
      {
        title: hubContent.placement_test_session_continue_set_up,
        action: continueSetupSession
      },
      {
        title: hubContent.placement_test_session_view_session_information,
        action: viewSessionInfo
      }
    ];
  }

  if (testStatus === PLACEMENT_TEST_RESULTS_STATUS.ACTIVE) {
    return isMyTasksPage
      ? [
          {
            title: hubContent.placement_test_session_access_test,
            action: accessTest,
            to: '/'
          },
          {
            title: hubContent.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ]
      : [
          {
            title: hubContent.placement_test_session_manage_session,
            action: manageSession
          },
          {
            title: hubContent.placement_test_session_copy_joining_code,
            label: hubContent.copied,
            action: copyJoiningCode
          },
          {
            title: placementTestsContent.placement_test_ellipsis_menu_button_download_progress,
            action: downloadProgress
          },
          {
            title: hubContent.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ];
  }

  if (testStatus === PLACEMENT_TEST_RESULTS_STATUS.UPCOMING) {
    return isMyTasksPage
      ? [
          {
            title: hubContent.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ]
      : [
          {
            title: hubContent.placement_test_session_manage_session,
            action: manageSession
          },
          {
            title: hubContent.placement_test_session_copy_joining_code,
            label: hubContent.copied,
            action: copyJoiningCode
          },
          {
            title: hubContent.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ];
  }

  if (testStatus === PLACEMENT_TEST_RESULTS_STATUS.COMPLETED) {
    if (isMyTasksPage) {
      return showResultsToStudents
        ? [
            {
              title: hubContent.placement_test_session_view_progress,
              action: viewProgress
            },
            {
              title: hubContent.placement_test_session_download_report_card,
              action: downloadReportCard
            },
            {
              title: hubContent.placement_test_session_view_session_information,
              action: viewSessionInfo
            }
          ]
        : [
            {
              title: hubContent.placement_test_session_view_session_information,
              action: viewSessionInfo
            }
          ];
    }
    return [
      {
        title: hubContent.placement_test_session_manage_session,
        action: manageSession
      },
      {
        title: placementTestsContent.placement_test_ellipsis_menu_button_download_results,
        action: downloadResults
      },
      {
        title: hubContent.placement_test_session_view_session_information,
        action: viewSessionInfo
      }
    ];
  }

  if (testStatus === PLACEMENT_TEST_RESULTS_STATUS.MISSED || testStatus === PLACEMENT_TEST_STATUS.MISSING) {
    return [
      {
        title: hubContent.placement_test_session_view_session_information,
        action: viewSessionInfo
      }
    ];
  }

  return [];
};

export default getPlacementTestActionButtons;
