import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../utils/compose/compose.js';

import ResendInvitationOutcome from '../components/ResendInvitationOutcome/ResendInvitationOutcome.js';
import SidePanel from '../components/SidePanel/SidePanel';
import withLocalizedContent from '../language/withLocalizedContent';

function ResendInvitationOutcomePanel({
  localizedContent: { resendInvitationOutcome: content },
  firstName,
  lastName,
  status,
  open,
  onComplete
}) {
  return (
    <SidePanel ariaLabel={content.aria_label_resend_invitation_panel} isOpen={open} onClose={onComplete}>
      <ResendInvitationOutcome firstName={firstName} lastName={lastName} status={status} onDone={onComplete} />
    </SidePanel>
  );
}

ResendInvitationOutcomePanel.propTypes = {
  localizedContent: PropTypes.shape({
    resendInvitationOutcome: PropTypes.object.isRequired
  }).isRequired,
  status: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('resendInvitationOutcome'),
  connect(state => {
    const user = state.people.data[state.resendInvitation.userId] || {};
    return {
      status: state.resendInvitation.status,
      firstName: user.firstname,
      lastName: user.lastname
    };
  })
)(ResendInvitationOutcomePanel);
