import React from 'react';
import PropTypes from 'prop-types';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './LtiLicenceCreditsForm.scss';
import Button from '../Button/Button';
import LtiLicenceProductItem from './LtiLicenceProductItem';

function LtiLicenceProductSearchItem({
  localizedContent: { ltiLicenceCreditsForm: localizedContent },
  product,
  onAdd
}) {
  return (
    <div className={styles.productSearchItem}>
      <LtiLicenceProductItem product={product} />
      <div className={styles.productAction}>
        <Button text={localizedContent.add_to_products_label} onClick={onAdd} />
      </div>
    </div>
  );
}

LtiLicenceProductSearchItem.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  product: PropTypes.object,
  onAdd: PropTypes.func
};

export default compose(withLocalizedContent('ltiLicenceCreditsForm'))(LtiLicenceProductSearchItem);
