import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigationType, useLocation } from 'react-router-dom';

import compose from '../utils/compose/compose.js';
import withLocalizedContent from '../language/withLocalizedContent.js';
import OrganizationsList from '../components/OrganizationsList/OrganizationsList';

function OrganizationsPage({ localizedContent: { organizationsPage: localizedContent = {} } }) {
  const navigationType = useNavigationType();
  const location = useLocation();

  return (
    <>
      <Helmet title={localizedContent.page_title} />
      <OrganizationsList tabName="organizations" action={navigationType} location={location} />
    </>
  );
}

OrganizationsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('organizationsPage'))(OrganizationsPage);
