import React from 'react';
import PropTypes from 'prop-types';
import withRouter from '../../utils/withRouter';
import styles from './LevelJumpToggle.scss';
import Dropdown from '../Dropdown/Dropdown';

class LevelJumpToggle extends React.Component {
  static _getDefaultOption = hierarchy => {
    const lastIndexTilde = hierarchy.lastIndexOf('~') + 1;

    return {
      text: lastIndexTilde ? hierarchy.slice(lastIndexTilde) : 'Overview',
      value: 'default',
      disabled: true
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      filteredOptions: []
    };
  }

  componentDidMount() {
    const { filteredOptions } = this.state;
    const { levels, hierarchy } = this.props;
    if (filteredOptions.length === 0 || filteredOptions === undefined) {
      this._getOptions(levels, hierarchy);
    }
  }

  _getOptions = (rawOptions, hierarchy) => {
    const defaultOption = LevelJumpToggle._getDefaultOption(hierarchy);
    const options = rawOptions.reduce(
      (accumulator, { name, uId }) => (uId ? [...accumulator, { text: name, value: uId }] : accumulator),
      []
    );
    const overviewOption = { text: 'Overview', value: '' };
    const filteredOptions = [overviewOption, defaultOption, ...options];
    if (overviewOption.text === defaultOption.text) filteredOptions.shift();
    this.setState({ filteredOptions });
  };

  _redirectTo = newHash => {
    const { history } = this.props;
    const newUrl = `${window.location.pathname.replace(/\/$/, '')}#${encodeURIComponent(newHash)}`;
    history.push(newUrl);
  };

  render() {
    const { filteredOptions } = this.state;
    return (
      <div className={styles.LevelJumpToggle}>
        <Dropdown
          id="levelJumpDropdown"
          name="levelJumpDropdown"
          label="Show results for"
          value="default"
          options={filteredOptions}
          onChange={this._redirectTo}
        />
      </div>
    );
  }
}
LevelJumpToggle.propTypes = {
  levels: PropTypes.array,
  hierarchy: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(LevelJumpToggle);
