import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import compose from '../../utils/compose/compose.js';
import { MODAL_CLASSES } from '../../globals/cptConstants';
import styles from './ContentPlayerDictionary.scss';
import { EmblemShape, EmblemSize, GlyphIcon } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import PopoutPanel from '../PopoutPanel/PopoutPanel';
import { toggleDictionaryModal, loadDictionaryDataRequest } from '../../redux/actions/structuredContentPlayer';
import dictionaryTypes from './ContentPlayerDictionaryConstants';
import useMediaQuery from '../../utils/mediaQuery';
import { onKeyDownHandler } from '../../services/cptHelperMethods';

function ContentPlayerDictionary({
  cptContent,
  dictionarySearchOrder,
  toggleDictionaryModalContent,
  isDictionaryModalOpen,
  loadDictionaryDataRequestData,
  dictionaryData,
  dictionaryError
}) {
  const inputRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  function getDictionaryFullName(pathCode) {
    const keys = Object.keys(dictionaryTypes);
    let dictionaryFullName = '';
    keys.forEach(key => {
      if (dictionaryTypes[key].pathCode === pathCode) {
        dictionaryFullName = dictionaryTypes[key].fullName;
      }
    });
    return dictionaryFullName;
  }

  function getDictionaryData(word) {
    // Replace word in search input when clicking on a suggestion
    inputRef.current.value = word;
    loadDictionaryDataRequestData({ searchedWord: word, dictionaries: dictionarySearchOrder });
  }

  function handleKeyPress(event) {
    if (event.keyCode === 13 || event.which === 13) {
      getDictionaryData(inputRef.current.value);
      event.stopPropagation();
    }
  }

  function handleFormSubmit(event) {
    event.preventDefault();
  }

  function extractHtmlBetweenArticleTags(inputString) {
    const regex = /<article[^>]*>([\s\S]*?)<\/article>/;
    const match = inputString.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    console.log('Article tags not found or empty.');
    return 'No definition found';
  }

  const renderDictionaryContent = () => {
    if (dictionaryError) {
      return (
        <div className="english">
          <div className="other-result-wrap">
            <div className="search-sub-text">{dictionaryError}</div>
          </div>
        </div>
      );
    }
    if (dictionaryData) {
      import('./Dictionary.scss');
      return (
        <div className={dictionaryData.dictionaryCode}>
          {dictionaryData.isWordFound && (
            <>
              <p>{getDictionaryFullName(dictionaryData.dictionaryCode)}</p>
              <hr />
              <div
                className={styles.dictionaryEntryContent}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: extractHtmlBetweenArticleTags(dictionaryData.entryContent) }}
              />
            </>
          )}

          <div className="other-result-wrap">
            {dictionaryData.isWordFound ? (
              <div className="other-result-title">
                {cptContent.dictionary_other_results.replace('{searchTerm}', dictionaryData.searchTerm)}
              </div>
            ) : (
              <div className="search-sub-text">
                {cptContent.dictionary_no_match_found.replace('{searchTerm}', dictionaryData.searchTerm)}
              </div>
            )}

            <div id="did-you-mean-result" className="did-you-mean-wrap">
              {dictionaryData.suggestions.map((word, index) => (
                <div className="word-wrap" key={index}>
                  <div
                    className="other-word"
                    role="button"
                    tabIndex={0}
                    onClick={() => getDictionaryData(word)}
                    onKeyDown={onKeyDownHandler(() => getDictionaryData(word))}
                  >
                    {word}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return <p aria-hidden>Search for word!</p>;
  };

  return (
    <PopoutPanel
      id="dictionaryModal"
      ariaLabel="DictionaryModal"
      ariaLive="assertive"
      isOpen={isDictionaryModalOpen}
      className={isMobile ? MODAL_CLASSES.CP_MODAL_MENU : MODAL_CLASSES.CP_MODAL_DICTIONARY}
    >
      <div className={styles.dictionaryHeader}>
        <div className={styles.dictionaryTitleWrapper} aria-hidden>
          <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
            <SVGIcon glyph={GlyphIcon.ICON_DICTIONARY_CPT} />
          </div>
          <p className={styles.title}>Dictionary</p>
        </div>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownHandler(toggleDictionaryModalContent)}
          onClick={toggleDictionaryModalContent}
          aria-label={cptContent.close_menu}
          className={classnames(EmblemSize.MEDIUM, styles.cpCloseButton, styles.cpCloseButtonDictionary)}
        >
          <SVGIcon glyph={GlyphIcon.ICON_CLOSE_BOLD} />
        </div>
      </div>
      <div className={styles.dictionaryContainer}>
        <form className={styles.dictionaryForm} onSubmit={handleFormSubmit}>
          <input
            type="text"
            className={styles.searchBar}
            name="dictionaryWord"
            id="dictionaryWord"
            ref={inputRef}
            onKeyDown={onKeyDownHandler(handleKeyPress)}
          />
          <button
            type="button"
            className={styles.searchButton}
            onClick={() => getDictionaryData(inputRef.current.value)}
          >
            Search
          </button>
        </form>
        <div className={styles.dictionaryContent}>{renderDictionaryContent()}</div>
      </div>
    </PopoutPanel>
  );
}

ContentPlayerDictionary.propTypes = {
  cptContent: PropTypes.object,
  dictionarySearchOrder: PropTypes.string,
  toggleDictionaryModalContent: PropTypes.func.isRequired,
  isDictionaryModalOpen: PropTypes.bool.isRequired,
  loadDictionaryDataRequestData: PropTypes.func,
  dictionaryData: PropTypes.object,
  dictionaryError: PropTypes.string
};

const mapStateToProps = ({ structuredContentPlayer: { isDictionaryModalOpen, dictionaryData, dictionaryError } }) => ({
  isDictionaryModalOpen,
  dictionaryData,
  dictionaryError
});

const mapDispatchToProps = {
  toggleDictionaryModalContent: toggleDictionaryModal,
  loadDictionaryDataRequestData: loadDictionaryDataRequest
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerDictionary);
