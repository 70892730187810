import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import colors from '../../globals/colors';
import TextInput from '../TextInput/TextInput';
import Card from '../Card/Card';
import ValidationStatus, { validationStatusCodes } from '../ValidationStatus/ValidationStatus';
import actions from '../../redux/actions/index.js';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { mode as opMode } from '../../redux/reducers/oicTestCredits.reducer.js';

const { ERROR_STATUS, VALID_STATUS, WAITING_STATUS } = validationStatusCodes;

function OicOrganizationForm({
  localizedContent: { oicCreateTestCreditsForm: localizedContent },
  organization,
  input,
  validate,
  reset,
  clearSubscriptions
}) {
  const params = useParams();

  const { mode, formData, errors } = organization;
  const isInputMode = mode === opMode.INPUT;
  const isErrorMode = mode === opMode.ERROR;

  const handleInput = (value, field) => input({ field, value });
  const handleValidate = field => () => validate({ [field]: formData[field] });
  const handleReset = () => {
    reset();
    clearSubscriptions();
  };

  useEffect(() => {
    handleReset();

    return () => handleReset();
  }, []);

  useEffect(() => {
    if (params) {
      handleInput(params.orgId, 'orgId');
      validate(params);
    }
  }, [params]);

  const status = {
    [opMode.LOADING]: WAITING_STATUS,
    [opMode.ERROR]: ERROR_STATUS,
    [opMode.SUCCESS]: VALID_STATUS,
    [opMode.INPUT]: VALID_STATUS
  }[mode];

  const errorMessage = errors.orgId
    ? localizedContent.org_id_error_message
    : localizedContent.org_not_found_error_message;

  return (
    <div className="gin-bot3">
      <Card headingText={localizedContent.org_card_title} headingColor={colors.ORGANIZATION}>
        <ValidationStatus type={status} isActive hideIcon={isInputMode} message={isErrorMode && errorMessage}>
          <TextInputWithButton
            id="orgId"
            label={localizedContent.org_id_label}
            value={formData.orgId}
            name="orgId"
            disabled={mode === mode.LOADING}
            onChange={handleInput}
            onBlur={handleValidate('orgId')}
            buttonIcon={GLYPHS.ICON_TICK}
            buttonAction={handleValidate('orgId')}
          />
        </ValidationStatus>
        {formData.orgName && <TextInput label={localizedContent.org_name_label} value={formData.orgName} readOnly />}
      </Card>
    </div>
  );
}

OicOrganizationForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  input: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  clearSubscriptions: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('oicCreateTestCreditsForm'),
  connect(
    () => ({}),
    dispatch => ({
      input: input => dispatch(actions.oicTestCreditsOrgInput(input)),
      validate: input => dispatch(actions.oicTestCreditsOrgValidate(input)),
      reset: () => dispatch(actions.oicTestCreditsReset()),
      clearSubscriptions: () => dispatch(actions.storeSubscriptions(null))
    })
  )
)(OicOrganizationForm);
