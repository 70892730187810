import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import compose from '../../utils/compose/compose.js';
import {
  MENU_ORIENTATION,
  selfSelectedUserRoles,
  MODAL_CLASSES,
  MODAL_CLOSE_LINK_KEY,
  SESSION_MENU_ORIENTATION,
  role
} from '../../globals/cptConstants';
import userRoles, {
  getSelfSelectedRole,
  roleIsAtLeast,
  isTeacherInOrgOrSelfSelected,
  isOupStaff,
  isELTReviewer
} from '../../globals/userRoles';
import PopoutPanel from '../../components/PopoutPanel/PopoutPanel';
import {
  closeStructuredContentPlayerModal,
  loadCptContentRequest,
  loadMockCptContentRequest,
  openStructuredContentPlayerModal,
  setMenuOrientation,
  setModalBasePath,
  setModalCloseLink,
  setModalContentId,
  setPreviewRole,
  setProductVariant
} from '../../redux/actions/structuredContentPlayer';
import StructuredContentPlayer from '../../components/StructuredContentPlayer/StructuredContentPlayer';
import HubMissingLicenseErrorModal from '../../structure/HubProductLayout/HubMissingLicenseErrorModal/HubMissingLicenseErrorModal';
import AddToProfile from '../MyProfile/tabs/panels/AddToProfile/AddToProfile';
import withLocalizedContent from '../../language/withLocalizedContent';
import { openRedeemModal, closeMissingLicenseModal } from '../../redux/actions/hubUi';
import { isHubMode, isOcpMode, isCesMode } from '../../utils/platform';
import localStorageWithCache from '../../utils/localStorageWithCache';

function StructuredContentPlayerBlankPage({
  isModalOpen,
  modalCloseLink,
  openStructuredContentPlayerModalAction,
  closeStructuredContentPlayerModalAction,
  setModalCloseLinkAction,
  setModalBasePathAction,
  menuOrientation,
  setMenuOrientationAction,
  loadCptContentRequestData,
  loadMockCptContentRequestData,
  setModalContentIdAction,
  previewModeRole,
  redeemModalOpen,
  missingLicenseModalOpen,
  openRedeemModalAction,
  closeMissingLicenseModalAction,
  localizedContent: { hubGlossary: hubContent },
  setPreviewRoleAction,
  setProductVariantAction,
  userRole
}) {
  const params = useParams();
  const { getKey } = localStorageWithCache;
  const localstorageSetting = getKey('user-info');
  let isAtLeastTeacher;
  if (localstorageSetting) {
    const { role: userRoleStored } = JSON.parse(localstorageSetting);
    isAtLeastTeacher = roleIsAtLeast(userRoles.TEACHER, userRoleStored);
  } else if (isOcpMode()) {
    isAtLeastTeacher = true;
  }

  // set Data from external API call
  useEffect(() => {
    let isTeacher;
    const hasAccessInAdminDashboard = isCesMode() && (isOupStaff(userRole) || isELTReviewer(userRole));

    if (hasAccessInAdminDashboard) {
      const previewRole = previewModeRole || window.location.pathname.split('/')[2];
      isTeacher = previewRole === role.TEACHER || previewRole === selfSelectedUserRoles.SELF_TEACHER;
      setPreviewRoleAction(isTeacher ? selfSelectedUserRoles.SELF_TEACHER : selfSelectedUserRoles.SELF_LEARNER);
    } else if (isHubMode()) {
      isTeacher = params.role
        ? params.role === role.TEACHER && isTeacherInOrgOrSelfSelected(userRole)
        : isTeacherInOrgOrSelfSelected(userRole);
    }

    setProductVariantAction(isTeacher ? selfSelectedUserRoles.SELF_TEACHER : selfSelectedUserRoles.SELF_LEARNER);

    if (isOcpMode()) {
      loadMockCptContentRequestData({ contentCode: params.contentCode, role: role.teacher });
    } else {
      loadCptContentRequestData({
        contentCode: params.contentCode,
        role: isTeacher ? role.TEACHER : role.STUDENT
      });
    }

    setModalContentIdAction(params.contentCode);
  }, []);

  // set Data from Local Storage and Session Storage
  useEffect(() => {
    const menuOrientationParam = localStorage.getItem(SESSION_MENU_ORIENTATION) || menuOrientation;
    if (getSelfSelectedRole() === selfSelectedUserRoles.SELF_TEACHER || isAtLeastTeacher) {
      setMenuOrientationAction(menuOrientationParam);
    }

    const modalCloseLinkParam = sessionStorage.getItem(MODAL_CLOSE_LINK_KEY);
    if (modalCloseLinkParam) {
      setModalCloseLinkAction(modalCloseLinkParam);
    }
  }, []);

  // open modal and set the basePath for it
  useEffect(() => {
    openStructuredContentPlayerModalAction();
    setModalBasePathAction(window.location);
  }, []);

  const handleRedeemCodeOnComplete = () => {
    window.location.reload();
  };

  const handleCloseModal = useCallback(() => {
    if (modalCloseLink) {
      setModalCloseLinkAction('');
    } else if (isOcpMode()) {
      window.open('/', '_self');
      window.close();
    } else {
      window.open('/myDashboard', '_self');
      window.close();
    }
    closeStructuredContentPlayerModalAction();
  }, [modalCloseLink]);

  const handleMissingLicenseModalClose = () => {
    window.location.href = '/myDashboard';
  };

  const handleRedeemCallback = () => {
    closeMissingLicenseModalAction();
    openRedeemModalAction();
  };

  const handleCloseRedeemModal = () => {
    window.location.href = '/myDashboard';
  };

  return (
    <div>
      <Helmet title="Content Player" />
      <PopoutPanel
        id="structuredContentPlayer"
        ariaLabel="StructuredContentPlayer"
        ariaLive="assertive"
        isOpen={isModalOpen}
        panelSize="fullWidth"
        className={MODAL_CLASSES.OVERWRITE}
      >
        <StructuredContentPlayer closePanel={handleCloseModal} params={params} />
      </PopoutPanel>
      <PopoutPanel id="redeemCode" ariaLabel="AddCode" isOpen={redeemModalOpen}>
        <AddToProfile
          closePanel={handleCloseRedeemModal}
          onComplete={handleCloseRedeemModal}
          onRedeemed={handleRedeemCodeOnComplete}
        />
      </PopoutPanel>
      <HubMissingLicenseErrorModal
        hubContent={hubContent}
        closeModal={handleMissingLicenseModalClose}
        redeemCallback={handleRedeemCallback}
        isOpen={missingLicenseModalOpen}
      />
    </div>
  );
}

StructuredContentPlayerBlankPage.propTypes = {
  modalCloseLink: PropTypes.string,
  isModalOpen: PropTypes.bool,
  openStructuredContentPlayerModalAction: PropTypes.func,
  closeStructuredContentPlayerModalAction: PropTypes.func,
  setModalCloseLinkAction: PropTypes.func,
  setModalBasePathAction: PropTypes.func,
  setMenuOrientationAction: PropTypes.func,
  menuOrientation: PropTypes.oneOf([MENU_ORIENTATION.LEFT, MENU_ORIENTATION.RIGHT]),
  loadCptContentRequestData: PropTypes.func,
  loadMockCptContentRequestData: PropTypes.func,
  setModalContentIdAction: PropTypes.func,
  previewModeRole: PropTypes.string,
  closeMissingLicenseModalAction: PropTypes.func.isRequired,
  redeemModalOpen: PropTypes.bool,
  missingLicenseModalOpen: PropTypes.bool,
  openRedeemModalAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  setPreviewRoleAction: PropTypes.func,
  setProductVariantAction: PropTypes.func,
  userRole: PropTypes.string
};

const mapStateToProps = ({
  structuredContentPlayer: { modalCloseLink, isModalOpen, menuOrientation, previewModeRole },
  hubUi: { redeemModalOpen, missingLicenseModalOpen },
  identity: { role: userRole }
}) => ({
  modalCloseLink,
  isModalOpen,
  menuOrientation,
  previewModeRole,
  redeemModalOpen,
  missingLicenseModalOpen,
  userRole
});

const mapDispatchToProps = {
  openStructuredContentPlayerModalAction: openStructuredContentPlayerModal,
  closeStructuredContentPlayerModalAction: closeStructuredContentPlayerModal,
  setModalCloseLinkAction: setModalCloseLink,
  setModalBasePathAction: setModalBasePath,
  setMenuOrientationAction: setMenuOrientation,
  loadCptContentRequestData: loadCptContentRequest,
  loadMockCptContentRequestData: loadMockCptContentRequest,
  setModalContentIdAction: setModalContentId,
  openRedeemModalAction: openRedeemModal,
  closeMissingLicenseModalAction: closeMissingLicenseModal,
  setPreviewRoleAction: setPreviewRole,
  setProductVariantAction: setProductVariant
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedContent('hubGlossary')
)(StructuredContentPlayerBlankPage);
