import React from 'react';
import PropTypes from 'prop-types';
import withRouter from '../../utils/withRouter';
import TermsAndConditions from './TermsAndConditions';
import Steps from './Steps';

class StartTips extends React.Component {
  getRenderComponent = () => {
    const { location } = this.props;
    let renderComponent;
    switch (parseInt(location.pathname.split('/')[2], 10)) {
      case 1:
        renderComponent = <Steps step={0} buttonClick={this.navigateToNextStep} />;
        break;
      case 2:
        renderComponent = <Steps step={1} buttonClick={this.navigateToNextStep} />;
        break;
      case 3:
        renderComponent = <Steps step={2} buttonClick={this.navigateToNextStep} />;
        break;
      case 4:
        renderComponent = <Steps step={3} buttonClick={this.navigateToNextStep} />;
        break;
      default:
        renderComponent = <TermsAndConditions buttonClick={this.navigateToNextStep} />;
    }
    return renderComponent;
  };

  navigateToNextStep = () => {
    const {
      history,
      match: {
        params: { step }
      }
    } = this.props;

    const nextStep = step ? parseInt(step, 10) + 1 : 1;

    if (nextStep === 5) {
      // deleting showStartUpPages variable after completing all the steps
      localStorage.removeItem('showStartUpPages');
      history.replace('/');
    } else {
      history.push(`/orb-start-tips/${nextStep}`);
    }
  };

  render() {
    return <div>{this.getRenderComponent()}</div>;
  }
}

StartTips.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(StartTips);
