import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TextInput, Footer, ValidationMessage, Button } from '@oup/shared-front-end';
import { JOINING_CODE_TYPES } from '@oup/shared-node-browser/constants.js';

import PanelHeading from '../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

import { submitForm, setClassCode, validateClassCode } from '../../redux/reducers/joinClass.reducer.js';
import cmsContent from '../../utils/cmsContent.js';
import styles from './JoinClass.scss';

function JoinPlacementTestCodeInput({
  closePanel,
  submitFormAction,
  classCodeValue,
  setClassCodeAction,
  validateClassCodeAction,
  classCodeValueIsValid,
  classCodeValueIsError = null,
  hasSpecialCharError,
  hasInvalidLengthError,
  content,
  userRole
}) {
  const CMS = cmsContent.addToProfile || {};

  const isTeacherUsingStudentCode = () =>
    ['TEACHER', 'TEACHER_ADMIN'].includes(userRole) && classCodeValue.startsWith('S-');
  const isStudentUsingTeacherCode = () => ['USER', 'LEARNER'].includes(userRole) && classCodeValue.startsWith('T-');

  const classCodeValueIsInvalid = classCodeValueIsError || isTeacherUsingStudentCode() || isStudentUsingTeacherCode();

  const displayedErrorMessage = () => {
    if (hasSpecialCharError) {
      return content.join_placement_test_code_input_invalid_characters;
    }
    if (hasInvalidLengthError) {
      return content.invalid_length_error;
    }
    if (isStudentUsingTeacherCode()) {
      return content.student_using_teacher_code_error;
    }
    if (isTeacherUsingStudentCode()) {
      return content.teacher_using_student_code_error;
    }

    return '';
  };

  const isCodeValid = () => {
    if (classCodeValueIsInvalid) {
      return 'invalid';
    }
    if (classCodeValueIsValid) {
      return 'valid';
    }
    return 'default';
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        containerClassName={styles.placementTestCodeInputContainer}
        headerContent={
          <div className={styles.header}>
            <div className={styles.close}>
              <Button
                text={CMS.close_panel_text}
                onClick={closePanel}
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_CLOSE_BOLD} />, placement: 'left' }}
                type="button"
                variant="outline"
              />
            </div>
            <PanelHeading
              title={content.join_placement_test_code_input_title}
              subtitle={content.join_placement_test_code_input_subtitle}
              customClassName={styles.headingStyle}
            />
          </div>
        }
        footerContent={
          <Footer
            primaryButtonAction={submitFormAction}
            secondaryButtonAction={closePanel}
            primaryButtonLabel={content.join_my_session}
            secondaryButtonLabel={content.button_cancel_text}
            primaryButtonAttributes={{ disabled: !classCodeValueIsValid }}
          />
        }
      >
        <div className={styles.placementTestCodeInputContent}>
          <TextInput
            id="placement-test-joining-code"
            type="text"
            placeholder={content.join_placement_test_code_input_placeholder}
            maxLength={36}
            label={content.join_placement_test_code_input_label}
            value={classCodeValue}
            onChange={e => {
              setClassCodeAction(e.target.value, JOINING_CODE_TYPES.PLACEMENT_TEST);
            }}
            onBlur={() => {
              validateClassCodeAction();
            }}
            state={isCodeValid()}
            validationMessage={classCodeValueIsInvalid ? displayedErrorMessage() : ''}
          />

          <div className={styles.validationMessage}>
            <ValidationMessage state="information">
              {content.join_placement_test_code_input_information_box_text}
            </ValidationMessage>
          </div>
        </div>
      </ScrollContainer>
    </form>
  );
}

JoinPlacementTestCodeInput.propTypes = {
  closePanel: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  classCodeValue: PropTypes.string.isRequired,
  setClassCodeAction: PropTypes.func.isRequired,
  validateClassCodeAction: PropTypes.func.isRequired,
  classCodeValueIsValid: PropTypes.bool.isRequired,
  classCodeValueIsError: PropTypes.bool.isRequired,
  hasSpecialCharError: PropTypes.bool.isRequired,
  hasInvalidLengthError: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  userRole: PropTypes.string
};

export default connect(
  state => ({
    file: state.joinClass.file,
    isFileSelected: state.joinClass.isFileSelected,
    classCodeValue: state.joinClass.classCodeValue,
    classCodeValueIsValid: state.joinClass.classCodeValueIsValid,
    classCodeValueIsError: state.joinClass.classCodeValueIsError,
    hasSpecialCharError: state.joinClass.hasSpecialCharError,
    hasInvalidLengthError: state.joinClass.hasInvalidLengthError
  }),
  {
    submitFormAction: submitForm,
    setClassCodeAction: setClassCode,
    validateClassCodeAction: validateClassCode
  }
)(JoinPlacementTestCodeInput);
