import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import compose from '../../../utils/compose/compose.js';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../components/Button/Button';
import withLocalizedContent from '../../../language/withLocalizedContent';
import styles from './EpsAdminOPS.scss';
import { featureIsEnabled } from '../../../globals/envSettings';

function EpsAdminOPS({ localizedContent: { epsAdminOPS: content } }) {
  const renderActCodeDashboard = () => {
    if (featureIsEnabled('enable-eps-admin-console')) {
      return (
        <Button
          to="/dashboard_eps/act_codes"
          type={buttonTypes.SECONDARY}
          text={content.activation_resources}
          fullWidth
          customClassName={styles.button}
        />
      );
    }
    return <div />;
  };

  const renderActCodeBatchesDashboard = () => {
    if (featureIsEnabled('enable-eps-admin-console')) {
      return (
        <Button
          to="/dashboard_eps/act_code_batch"
          type={buttonTypes.SECONDARY}
          text={content.activationCode_batch}
          fullWidth
          customClassName={styles.button}
        />
      );
    }
    return <div />;
  };

  const renderProductGroupSearch = () => {
    if (featureIsEnabled('eps-admin-product-group-search')) {
      return (
        <Button
          to="/dashboard_eps/product_groups"
          type={buttonTypes.SECONDARY}
          text={content.product_groups_resources}
          fullWidth
          customClassName={styles.button}
        />
      );
    }
    return <div />;
  };

  const renderUserDashboard = () => {
    if (featureIsEnabled('eps-admin-user-console')) {
      return (
        <Button
          to="/dashboard_eps/user_dashboard"
          type={buttonTypes.SECONDARY}
          text={content.users}
          fullWidth
          customClassName={styles.button}
        />
      );
    }
    return <div />;
  };

  const renderProductDashboard = () => (
    <Button
      to="/dashboard_eps/product_dashboard"
      type={buttonTypes.SECONDARY}
      text={content.products_resources}
      fullWidth
      customClassName={styles.button}
    />
  );

  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      {renderActCodeDashboard()}
      {renderActCodeBatchesDashboard()}
      {renderProductGroupSearch()}
      {renderUserDashboard()}
      {renderProductDashboard()}
    </PageWrapper>
  );
}

EpsAdminOPS.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('epsAdminOPS'))(EpsAdminOPS);
