import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TextLink } from '@oup/shared-front-end';
import Card from '../components/Card/Card';
import PageHeading from '../components/PageHeading/PageHeading';
import PasswordUpdateForm from '../components/PasswordUpdateForm/PasswordUpdateForm';
import colors from '../globals/colors';
import withLocalizedContent from '../language/withLocalizedContent';
import { getPlatformSupportUrl } from '../globals/envSettings';

function PasswordUpdateView({
  firstName,
  onPasswordChange,
  onPasswordValidate,
  platform,
  localizedContent: { passwordUpdateView: content },
  ...props
}) {
  let supportUrl;
  const platformQuery = platform ? `?platform=${platform}` : '';
  switch (platform) {
    case 'OWL':
      supportUrl = `${getPlatformSupportUrl('owl')}/contact-us/`;
      break;
    case 'INSP':
      supportUrl = `https://global.oup.com/education/contact`;
      break;
    default:
      supportUrl = `/support/contact-us${platformQuery}`;
      break;
  }

  return (
    <div className="pad-top8 pad-bot8">
      <div className="grid">
        <div className="row">
          <div className="col">
            <PageHeading title={`${content.title}, ${firstName}`} subtitle={content.subtitle} />
          </div>
          <div className="col md8">
            <div className="row">
              <div className="col" style={{ margin: '1.25rem 0 0' }}>
                <Card headingText={content.form_heading} headingColor={colors.PROFILE}>
                  <PasswordUpdateForm
                    createChangeHandler={() => onPasswordChange}
                    createBlurHandler={() => onPasswordValidate}
                    {...props}
                  />
                </Card>
              </div>
            </div>
          </div>
          <div className="col md4">
            <div className="row">
              <div className="col" style={{ margin: '1.25rem 0 0' }}>
                <Card headingText={content.support_heading} headingColor={colors.DASHBOARD}>
                  <h2 id="help-and-support-heading">{content.support_body}</h2>
                  <TextLink
                    component={platform === 'OWL' || /^https?:\/\//.test(supportUrl) ? false : RouterLink}
                    to={supportUrl}
                    target="_blank"
                  >
                    {content.support_contact_cta}
                  </TextLink>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PasswordUpdateView.propTypes = {
  firstName: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.object,
  platform: PropTypes.string,
  onPasswordChange: PropTypes.func.isRequired,
  onPasswordValidate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('passwordUpdateView')(PasswordUpdateView);
