import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../../../../utils/compose/compose.js';
import { SidePanel } from '../../../../../../components';
import ClassUserRemoveReview from '../../../../../../components/ClassUserRemoveReview/ClassUserRemoveReview';
import ClassUserConfirmation from '../../../../../../components/ClassUserConfirmation/ClassUserConfirmation';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import { clearForm, formStates, submitForm } from '../../../../../../redux/reducers/removeTeachersFromClassroom';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';

class RemoveTeachers extends React.Component {
  _getConfirmationHeading = () => {
    const {
      localizedContent: { removeTeachersPanel: content },
      requestFailed = false,
      failedIds = [],
      classroomName = '',
      numberOfTeachers = 0
    } = this.props;

    if (requestFailed) {
      return {
        title: content.confirmation_error,
        type: types.ERROR,
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
    }
    if (failedIds.length) {
      return {
        title: (content.confirmation_error_partial || '').replace('{className}', classroomName),
        type: types.WARNING,
        illustrationSrc: HubIllustrationConstants.WARNING,
        illustrationAltText: HubIllustrationAltText.WARNING
      };
    }
    return {
      title: (content.confirmation_success || '')
        .replace('{numberOfTeachers}', numberOfTeachers)
        .replace('{plural}', numberOfTeachers === 1 ? '' : 's')
        .replace('{className}', classroomName),
      type: types.VALID,
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  };

  _handleClosePanel = () => {
    const { closePanel } = this.props;

    closePanel();
  };

  _handleOnComplete = () => {
    const { resetForm, onComplete } = this.props;

    setTimeout(resetForm, 500);
    onComplete();
  };

  _renderContent = () => {
    const {
      formState,
      classroomName,
      teachers,
      failedIds,
      localizedContent: { removeTeachersPanel: content },
      isClassroomEmpty,
      onConfirmClick,
      currentOrganisationLti = false
    } = this.props;

    switch (formState) {
      case formStates.REVIEW_SELECTIONS:
        return (
          <ClassUserRemoveReview
            classroomName={classroomName}
            users={teachers}
            localizedContent={content}
            onSubmitClick={onConfirmClick}
            currentOrganisationLti={currentOrganisationLti}
          />
        );

      case formStates.SUBMITTING:
        return (
          <div className="flex-column align-items-center">
            <PopoutPanelIconHeading
              title={content.loading_page_title_text}
              subtitle={content.loading_page_subtitle_text}
              type={types.LOADING}
            />
          </div>
        );

      case formStates.CONFIRMATION:
        return (
          <ClassUserConfirmation
            localizedContent={content}
            {...this._getConfirmationHeading()}
            infoMessage={isClassroomEmpty ? content.confirmation_warning_noTeachersInClass : null}
            failedIds={failedIds}
            onDoneClick={this._handleOnComplete}
            users={teachers}
          />
        );

      default:
        return (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }
  };

  render() {
    const {
      isOpen,
      localizedContent: { removeTeachersPanel: content }
    } = this.props;

    return (
      <SidePanel
        id="removeTeachersPanel"
        ariaLabel={content.aria_label_remove_teachers_from_class}
        isOpen={isOpen}
        onClose={this._handleClosePanel}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

RemoveTeachers.propTypes = {
  formState: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  teachers: PropTypes.object.isRequired,
  classroomName: PropTypes.string,
  failedIds: PropTypes.array.isRequired,
  submittedIds: PropTypes.array,
  numberOfTeachers: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  notifyByEmail: PropTypes.bool,
  requestFailed: PropTypes.bool,
  isClassroomEmpty: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onNotifyByEmailToggle: PropTypes.func,
  onConfirmClick: PropTypes.func.isRequired,
  currentOrganisationLti: PropTypes.bool
};

export default compose(
  withLocalizedContent('removeTeachersPanel'),
  connect(
    state => {
      const { classrooms, classroomPage, people, removeTeachersFromClassroom } = state;
      const classroom = get(classrooms, ['data', classroomPage.classroomId], {});
      const teachers = pick(people.data, removeTeachersFromClassroom.submittedIds);
      const numberOfRemainingTeachers = get(classroom, ['teacherIdList', 'length'], 0);

      return {
        ...pick(removeTeachersFromClassroom, [
          'formState',
          'notifyWithEmail',
          'requestFailed',
          'failedIds',
          'submitedIds'
        ]),
        currentOrganisationLti: state.identity.currentOrganisationLti,
        teachers,
        classroomName: classroom.name,
        numberOfTeachers: Object.keys(teachers).length,
        isClassroomEmpty: numberOfRemainingTeachers === 0
      };
    },
    {
      resetForm: clearForm,
      onConfirmClick: submitForm
    }
  )
)(RemoveTeachers);
