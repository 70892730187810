import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pick } from 'lodash';

import withRouter from '../../../utils/withRouter';
import compose from '../../../utils/compose/compose.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import actions from '../../../redux/actions';
import { StatusEnum } from '../../../redux/sagas/appFunctions/authorisedFunctions/csvProductStructureUpload/csvProductStructureUpload';
import { ErrorStatus, PageWrapper } from '../../../components';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import CsvProductStructureUpload from '../../../components/CsvProductStructureUpload/CsvProductStructureUpload';
import CsvProductStructureUploadSuccess from '../../../components/CsvProductStructureUpload/CsvProductStructureUploadSuccess';

function CsvProductStructureUploadPage({
  status,
  fileName,
  redirect,
  localizedContent: { csvProductStructureUploadPage: content }
}) {
  return (
    <PageWrapper>
      <Helmet title={content.pageTitle} />
      {(() => {
        switch (true) {
          case status === StatusEnum.InProgress:
            return <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} />;
          case status === StatusEnum.Success:
            return (
              <CsvProductStructureUploadSuccess
                fileName={fileName}
                content={content}
                goBackToDashboard={() => redirect('/products')}
              />
            );
          case status === StatusEnum.Error:
            return (
              <ErrorStatus
                title={content.errorMessage}
                buttonText={content.backButton}
                buttonOnClickHandler={() => redirect('/products')}
              />
            );
          default:
            return (
              <CsvProductStructureUpload
                fileName={fileName}
                content={content}
                goBackToDashboard={() => redirect('/products')}
              />
            );
        }
      })()}
    </PageWrapper>
  );
}

CsvProductStructureUploadPage.propTypes = {
  status: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('csvProductStructureUploadPage'),
  connect(
    state => pick(state.csvProductStructureUpload, ['status', 'fileName']),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(actions.csvProductStructureUploadReset());
        props.history.push(path);
      }
    })
  )
)(CsvProductStructureUploadPage);
