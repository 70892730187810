/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, keys } from 'lodash';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Heading } from '@oup/shared-front-end';
import { courseResourceIsTeacherOnly } from '@oup/shared-node-browser/course.js';
import compose from '../../../utils/compose/compose.js';

// Hoc
import withLocalizedContent from '../../../language/withLocalizedContent';
// Components
import LinkWithIcon from '../../../components/LinkWithIcon/LinkWithIcon';
import Resource from '../../../components/Resource/Resource';
import { hubDownloadResource, changeResourceView } from '../../../redux/actions/hubResource';
import ResourceModal from '../../../components/ResourceModal/ResourceModal';
import ResourceFormHidden from './ResourceFormHidden';
import ResourceSubFolder from './ResourceSubFolder/ResourceSubFolder';
import HubEmptyState from '../../../components/HubEmptyState/HubEmptyState';
import withBreakpoint from '../../../decorators/withBreakpoint';
import breakpoints from '../../../globals/breakpoints';
// Constants
import { ResourceConstants, ErrorModalType, HubLayoutConstants, COURSE_FOLDERS } from '../../../globals/hubConstants';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import colors from '../../../globals/colors';
import styles from './ResourceCategory.scss';
// Services
import getCourseLevel from '../../HubDashboardLayout/Services/getCourseLevel';
import HubProductErrorModal from '../HubProductErrorModal/HubProductErrorModal';
import getHomeLink from '../Services/getHomeLink';
import { getSubfolderTitles, hasResourcesForStudent } from '../Services/filterResources';
import { hasHideableKeywords, hasLockableKeywords } from '../../HubCourseAssessments/Utils/isLockedNode';
import { featureIsEnabled } from '../../../globals/envSettings';

class ResourceCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: '',
      isDownloadable: true,
      isExpiredResourceId: null,
      isNotStartedResourceId: null,
      errorModalType: ErrorModalType.NONE
    };
  }

  componentDidUpdate(prevProps) {
    const { isError, downloadType } = this.props;
    if (prevProps.isError !== isError && downloadType === HubLayoutConstants.DOWNLOAD_TYPES.LOR) {
      this._closeModal();
    }
  }

  getBackButtonTextForResourceHome = resourceFolderName => {
    const { hubContent } = this.props;
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return hubContent.button_back_to_get_started_text;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT)
      return hubContent.button_back_to_professional_development_text;
    if (resourceFolderName === COURSE_FOLDERS.TESTS) return hubContent.button_back_to_tests_text;
    if (resourceFolderName === COURSE_FOLDERS.RESOURCES) return hubContent.button_back_to_resources_text;

    return hubContent.button_back_text;
  };

  isFolderOnlyForTeachers = (subFolderMaterial, resources) => {
    const { viewerType } = this.props;
    if (viewerType === HubLayoutConstants.TARGET_USERTYPE.TEACHER) return false;

    const hasStudentResources = hasResourcesForStudent(subFolderMaterial, resources);

    const subfolderTitles = getSubfolderTitles(subFolderMaterial);
    const hasSubFoldersForStudent = subfolderTitles
      .map(title => hasResourcesForStudent(subFolderMaterial[title], resources))
      .some(item => item === true);

    return !hasStudentResources && !hasSubFoldersForStudent;
  };

  shouldDisplayResource = (resource = {}) => {
    const { viewerType } = this.props;
    return !(viewerType === HubLayoutConstants.TARGET_USERTYPE.STUDENT && courseResourceIsTeacherOnly(resource));
  };

  getHeadingPage = resourceFolderName => {
    const { hubContent } = this.props;

    switch (resourceFolderName) {
      case COURSE_FOLDERS.GET_STARTED:
        return hubContent.get_started_heading;
      case COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT:
        return hubContent.professional_development_heading;
      case COURSE_FOLDERS.TESTS:
        return hubContent.tests_heading;
      default:
        return hubContent.resources;
    }
  };

  _downloadResource = resource => {
    const { downloadResource } = this.props;
    downloadResource({ ...resource }, 'download');
  };

  _openModal = (resourceId, isDownloadable) => {
    const {
      courses,
      match: { params },
      downloadResource
    } = this.props;

    this.setState(() => ({
      resourceId,
      isDownloadable
    }));
    const resources = get(courses, [params.courseId, 'resources']);

    const { PREVIEWABLE_FORMATS } = ResourceConstants;
    if (PREVIEWABLE_FORMATS.includes((resources[resourceId].format || '').toLowerCase())) {
      downloadResource({ ...resources[resourceId] }, 'play');
    }
  };

  _openExpiredModal = isExpiredResourceId => {
    this.setState(() => ({
      isExpiredResourceId,
      errorModalType: ErrorModalType.EXPIRED
    }));
  };

  _closeExpiredModal = () => {
    this.setState(() => ({
      isExpiredResourceId: null,
      errorModalType: ErrorModalType.NONE
    }));
  };

  _openNotStartedModal = isNotStartedResourceId => {
    this.setState(() => ({
      isNotStartedResourceId,
      errorModalType: ErrorModalType.NOT_STARTED
    }));
  };

  _closeNotStartedModal = () => {
    this.setState(() => ({
      isNotStartedResourceId: null,
      errorModalType: ErrorModalType.NONE
    }));
  };

  _closeModal = () => {
    this.setState(() => ({
      resourceId: ''
    }));
  };

  _handleResourceView = userView => {
    const { handleResourceView } = this.props;
    handleResourceView(userView);
  };

  _renderViewButton = () => {
    const { hubContent, viewerType } = this.props;
    return (
      <div className={styles.viewResourcesButton}>
        <button
          type="button"
          className={viewerType === HubLayoutConstants.COURSE_USER_ROLES.TEACHER ? styles.active : styles.inactive}
          onClick={() => this._handleResourceView(HubLayoutConstants.COURSE_USER_ROLES.TEACHER)}
        >
          {hubContent.classwork_resource_teacher_view}
        </button>
        <button
          type="button"
          className={viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT ? styles.active : styles.inactive}
          onClick={() => this._handleResourceView(HubLayoutConstants.COURSE_USER_ROLES.STUDENT)}
        >
          {hubContent.classwork_resource_student_view}
        </button>
      </div>
    );
  };

  _isTeacherOnlyFolder = parentFolderName => {
    if (parentFolderName === COURSE_FOLDERS.GET_STARTED) return true;
    if (parentFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return true;

    return false;
  };

  _renderFolders = (subsubFolders, subFolderMaterial, resources, hasLicence, isExpired, startedLicence) => {
    const {
      props: {
        localizedContent: { hubGlossary: hubContent },
        downloadIsLoading,
        breakpoint,
        resourceIdsDownloading,
        viewerType
      },
      _openModal,
      _downloadResource
    } = this;

    if (subsubFolders) {
      return subsubFolders.reduce((result, folderName) => {
        if (
          viewerType === HubLayoutConstants.TARGET_USERTYPE.STUDENT &&
          !hasResourcesForStudent(subFolderMaterial[folderName], resources)
        ) {
          return result;
        }
        result.push(
          <ResourceSubFolder
            key={folderName}
            id={folderName}
            folderDetails={subFolderMaterial[folderName]}
            resources={resources}
            downloadResource={_downloadResource}
            openModal={_openModal}
            hubContent={hubContent}
            downloadIsLoading={downloadIsLoading}
            hasLicence={hasLicence}
            isExpired={isExpired}
            breakpoint={breakpoint}
            startedLicence={startedLicence}
            openExpiredModal={() => {
              this._openExpiredModal(folderName);
            }}
            openNotStartedModal={() => {
              this._openNotStartedModal(folderName);
            }}
            resourcesView={viewerType}
            resourceIdsDownloading={resourceIdsDownloading}
          />
        );
        return result;
      }, []);
    }

    return null;
  };

  render() {
    const {
      props: {
        courses,
        match: { params },
        localizedContent: { hubGlossary: hubContent },
        downloadIsLoading,
        breakpoint,
        resourceIdsDownloading,
        isPreview
      },
      state: { resourceId, isExpiredResourceId, errorModalType, isNotStartedResourceId, isDownloadable },
      _openModal,
      _closeModal,
      _downloadResource
    } = this;

    const course = get(courses, [params.courseId]);
    const folder = get(params, 'folder');
    const subFolder = get(params, 'subFolder');
    const resources = get(course, ['resources']);
    const resourceIds = keys(resources);
    const resourceFolder = get(course, ['resourceFolders', folder]);
    const courseData = get(course, ['products', 'data']);
    const folderTitle = folder ? folder.charAt(0).toUpperCase() + folder.slice(1) : '';
    const subFolderTitle = get(resourceFolder, [subFolder, 'title'], '');
    const userRole = get(course, 'userRole', 'student');
    const subFolderMaterial = get(resourceFolder, [subFolder], {});
    const subsubFolders = getSubfolderTitles(subFolderMaterial);

    const pageTitle = course
      ? `${get(course, ['title'])} ${getCourseLevel(get(course, ['properties']))} | ${folderTitle}${subFolderTitle &&
          ` | ${subFolderTitle}`}`
      : 'Loading';

    const courseDataValues = courseData && Object.values(courseData);
    const hasLicence = courseDataValues && courseDataValues.filter(product => product.hasLicence).length > 0;
    const isExpired =
      courseDataValues &&
      courseDataValues.every(product => {
        const date = product.expiryDate;
        const productIsExpired = date && moment(date).isValid() && moment(date).isBefore(moment());
        return hasLicence && productIsExpired;
      });
    const startedLicence =
      courseDataValues &&
      courseDataValues.filter(product => {
        const date = product.expiryDate;
        const productIsExpired = date && moment(date).isValid() && moment(date).isBefore(moment());
        const productHasLicence = product.hasLicence;
        return !product.notStartedLicence && !productIsExpired && productHasLicence;
      }).length > 0;

    const showHeading = featureIsEnabled('navigation-changes');
    const headingArgs = {
      text: this.getHeadingPage(folder),
      size: 'small'
    };
    return (
      <div>
        <Helmet title={pageTitle} />
        {showHeading && <Heading {...headingArgs} />}
        {userRole === HubLayoutConstants.TARGET_USERTYPE.TEACHER &&
          !this._isTeacherOnlyFolder(params.folder) &&
          this._renderViewButton()}
        {breakpoint !== breakpoints.XXS && !!resourceIds.length && (
          <div>
            <span className={styles.backButton}>
              <LinkWithIcon
                isLhs
                id="ResourceCategoryBackLink"
                to={getHomeLink(params.courseId, folder, isPreview)}
                text={this.getBackButtonTextForResourceHome(folder)}
                glyph={GLYPHS.ICON_LEFT}
                aria-label="Previous page"
              />
            </span>
            <div className={styles.labelTitleFolder}>{subFolderTitle}</div>
          </div>
        )}

        {!this.isFolderOnlyForTeachers(subFolderMaterial, resources) ? (
          <div>
            <ul>
              {get(resourceFolder, [subFolder, 'resources'], []).map(
                id =>
                  resources[id] &&
                  this.shouldDisplayResource(resources[id]) &&
                  !hasHideableKeywords(resources[id].keywords) && (
                    <Resource
                      key={id}
                      resourceId={id}
                      resource={resources[id]}
                      downloadResource={_downloadResource}
                      openModal={_openModal}
                      hubContent={hubContent}
                      downloadIsLoading={downloadIsLoading}
                      hasLicence={hasLicence}
                      isExpired={isExpired || (resources[id].keywords && hasLockableKeywords(resources[id].keywords))}
                      breakpoint={breakpoint}
                      startedLicence={startedLicence}
                      openExpiredModal={() => {
                        this._openExpiredModal(id);
                      }}
                      openNotStartedModal={() => {
                        this._openNotStartedModal(id);
                      }}
                      resourceIdsDownloading={resourceIdsDownloading}
                    />
                  )
              )}
            </ul>

            <div className={styles.subFoldersSection}>
              {this._renderFolders(subsubFolders, subFolderMaterial, resources, hasLicence, isExpired, startedLicence)}
            </div>
          </div>
        ) : (
          <HubEmptyState
            glyphIcon={GLYPHS.ICON_LOCK_24}
            glyphSize={thumbnailSizes.LARGE}
            glyphBackgroundColour={colors.HUB_BLUE}
            title={hubContent.no_access_material_for_students}
          />
        )}

        <ResourceFormHidden />
        <ResourceModal
          breakpoint={breakpoint}
          resourceId={resourceId}
          closeModal={_closeModal}
          downloadResource={isDownloadable ? _downloadResource : null}
          resource={resourceId ? resources[resourceId] : {}}
          hubContent={hubContent}
          downloadIsLoading={downloadIsLoading}
        />
        <HubProductErrorModal
          isOpen={isExpiredResourceId !== null || isNotStartedResourceId !== null}
          errorModalType={errorModalType}
          hubContent={hubContent}
          glyphIcon={GLYPHS.ICON_LOCK_24}
          glyphSize={thumbnailSizes.SECTION}
          glyphBackgroundColour={isExpiredResourceId !== null ? colors.CLASSROOM : colors.DASHBOARD}
          title={
            isExpiredResourceId !== null
              ? hubContent.resource_expired_licence_modal_title
              : hubContent.resource_not_started_licence_modal_title
          }
          subTitle={
            isExpiredResourceId !== null
              ? hubContent.resource_expired_licence_modal_subtitle
              : hubContent.resource_not_started_licence_modal_subtitle
          }
          showLink={false}
          actionLabel={hubContent.done_button}
          closeExpiredModal={this._closeExpiredModal}
          closeNotStartedModal={this._closeNotStartedModal}
        />
      </div>
    );
  }
}

ResourceCategory.propTypes = {
  breakpoint: PropTypes.string,
  match: PropTypes.object,
  courses: PropTypes.object,
  downloadResource: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  downloadType: PropTypes.string.isRequired,
  hubContent: PropTypes.object,
  handleResourceView: PropTypes.func,
  viewerType: PropTypes.string,
  resourceIdsDownloading: PropTypes.object,
  isPreview: PropTypes.bool
};

ResourceCategory.defaultProps = {
  courses: {},
  match: {}
};

const mapStateToProps = state => ({
  downloadIsLoading: state.hubResourceDownload?.downloadIsLoading,
  resourceIdsDownloading: state.hubResourceDownload?.resourceIdsDownloading,
  currentDownload: state.currentDownload,
  downloadType: state.hubResourceDownload?.downloadType,
  isError: state.hubResourceDownload?.isError,
  viewerType: state.teacherResources.viewerType
});

const mapDispatchToProps = dispatch => ({
  downloadResource: (resource, resourceAction) => {
    dispatch(hubDownloadResource(resource, resourceAction));
  },
  handleResourceView: viewerType => {
    dispatch(changeResourceView(viewerType));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(ResourceCategory);
