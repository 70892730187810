import { put } from 'redux-saga/effects';

import userAccessPlacementTestSession from '../../../apiCalls/hub/accessPlacementTestSession';
import {
  loadUserAccessPlacementTestFailure,
  loadUserAccessPlacementTestSuccess
} from '../../../../actions/placementTests';

function* loadUserAccessPlacementTest(payload) {
  const { orgId, classId } = payload;

  const response = yield userAccessPlacementTestSession({ orgId, classId });
  if (response?.statusCode === 200 && response.redirectUrl) {
    // when request is made successfully we open a new tab for the AM external provider
    window.open(response.redirectUrl, '_blank');
    // and then close the modal that was trying to access this test inside OEH page
    yield put(loadUserAccessPlacementTestSuccess());
  } else {
    yield put(loadUserAccessPlacementTestFailure(payload));
  }
}
export default loadUserAccessPlacementTest;
