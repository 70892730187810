import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import classnames from 'classnames';
import compose from '../../utils/compose/compose.js';
import styles from './ActivityLevelMenu.scss';

import { PRESENTATION_TYPE, TEACHER_VIEW_MODE } from '../../globals/cptConstants';
import { getActivityLink } from '../../globals/urls';
import Link from '../Link/Link';
import { getEnvType } from '../../globals/envSettings';

import {
  setCurrentContent,
  closeMobileMenuModal,
  toggleContentsSubmenuModal
} from '../../redux/actions/structuredContentPlayer';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';
import changeRoleToProductVariant from '../StructuredContentPlayer/structuredContentPlayerUtils';
import { isOcpMode } from '../../utils/platform';

function ActivityLevelMenu({
  activities,
  setCurrentContentData,
  toggleModal,
  closeMobileMenuModalAction,
  modalBasePathLink,
  cptContent,
  productVariant,
  currentActivity,
  offlineUnits,
  productIsbn
}) {
  const viewVersion = changeRoleToProductVariant(productVariant);
  const { userId: offlineUserId } = useParams();

  const environment = getEnvType().toLowerCase();
  const isProd = environment === 'prod';
  const goDomainPrefix = isProd ? '' : `${environment}.`;

  const copyClipboardUrl = `https://${goDomainPrefix}go.oup.com/hub/launch/product/${productIsbn}?&node=`;

  const handleActivitySelect = activityId => {
    if (currentActivity !== activityId) {
      setCurrentContentData(activityId);
    }
    toggleModal();
    closeMobileMenuModalAction();
  };

  const handleKeyDown = (e, activityId) => {
    if (e.key === 'Enter') {
      handleActivitySelect(activityId);
    }
  };

  activities.forEach(activity => {
    const isActivityDownloaded = offlineUnits.some(
      unit => activity.parentId.includes(unit.id) && unit.userId === offlineUserId
    );

    if (isActivityDownloaded) {
      activity.isDownloaded = true;
    }
  });

  const getActivityStyle = activity => {
    if (isOcpMode()) {
      return activity.isClickable && activity.isDownloaded ? '' : styles.menuItemDisabled;
    }
    return activity.isClickable ? '' : styles.menuItemDisabled;
  };

  return (
    <div className={styles.subMenuContainer}>
      {activities.map((activity, activityIndex) => (
        <div
          key={`${activity}_${activityIndex}`}
          className={classnames(
            styles.menuItem,
            getActivityStyle(activity),
            activity.isSelected ? styles.activitySelected : ''
          )}
        >
          <Link
            to={getActivityLink(modalBasePathLink, activity.id)}
            className={classnames(styles.menuItemLink)}
            tabIndex={0}
            onClick={() => handleActivitySelect(activity.id)}
            onKeyDown={e => handleKeyDown(e, activity.id)}
          >
            <p className={styles.textLabel}>{activity.title}</p>
            {activity.type === PRESENTATION_TYPE && activity.isTeacherOnly && (
              <div className={styles.cptLabel}>{cptContent.cpt_label}</div>
            )}
          </Link>
          {viewVersion === TEACHER_VIEW_MODE && !activity.isTeacherOnly && (
            <CopyToClipboardButton
              tabIndex={activity.isClickable ? 0 : -1}
              classCode={`${copyClipboardUrl}${activity?.uId}`}
              label={cptContent.copy_button_label}
              customCopiedLabel={cptContent.copied_link_label}
              customClassname={
                isOcpMode() ? classnames(styles.menuItemDisabled, styles.clipboardContainer) : styles.clipboardContainer
              }
            />
          )}
        </div>
      ))}
    </div>
  );
}

ActivityLevelMenu.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentContentData: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  closeMobileMenuModalAction: PropTypes.func.isRequired,
  modalBasePathLink: PropTypes.object,
  cptContent: PropTypes.object,
  productVariant: PropTypes.string,
  productIsbn: PropTypes.string,
  currentActivity: PropTypes.string,
  offlineUnits: PropTypes.array
};

const mapStateToProps = ({
  structuredContentPlayer: {
    modalBasePathLink,
    productVariant,
    type,
    contentId,
    navigation,
    cptContent: { isbn: productIsbn }
  },
  offlineContentPlayer: { units: offlineUnits }
}) => ({
  modalBasePathLink,
  productVariant,
  type,
  contentId,
  currentActivity: navigation.currentActivity,
  offlineUnits,
  productIsbn
});

const mapDispatchToProps = {
  setCurrentContentData: setCurrentContent,
  closeMobileMenuModalAction: closeMobileMenuModal,
  toggleModal: toggleContentsSubmenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActivityLevelMenu);
