const stickyProgressSettingsBar = (contentEl, progressSettingsContainerEl, setStateCallback) => {
  if (!contentEl.current) {
    setTimeout(() => stickyProgressSettingsBar(contentEl, progressSettingsContainerEl, setStateCallback), 100);
    return;
  }

  const contentRect = contentEl.current.getBoundingClientRect();
  const { top: contentTop, width: contentWidth } = contentRect;
  const siteHeader = document.getElementById('fixedHeader');
  const headerHeight = siteHeader ? siteHeader.getBoundingClientRect().height || 75 : 0;
  const secondaryNavigation = document.getElementById('hubSecondaryNavigationContainer');
  const secondaryNavigationContainerHeight = secondaryNavigation
    ? secondaryNavigation.getBoundingClientRect().height || 73
    : 0;
  const progressSettingsContainerHeight = progressSettingsContainerEl.current.getBoundingClientRect().height || 80;
  const scroll = contentTop - headerHeight - progressSettingsContainerHeight - secondaryNavigationContainerHeight;
  const topProgressSettingsPos = headerHeight + secondaryNavigationContainerHeight;

  setStateCallback({
    scrollPosition: scroll,
    topProgressSettingsPos,
    progressSettingsContainerHeight,
    gradebookContentWidth: contentWidth
  });
};

export default stickyProgressSettingsBar;
