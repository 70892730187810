import PropTypes from 'prop-types';
import React from 'react';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import ControlledForm from '../ControlledForm/ControlledForm';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import ConnectedPersonRepresentation from '../PersonRepresentation/ConnectedPersonRepresentation';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import { GLYPHS } from '../SVGIcon/SVGIcon';

function UserRestoreReviewPane({
  localizedContent: { userRestoreReviewPane: content = {} },
  userIds,
  orgId,
  onClickClose,
  onClickRestore
}) {
  return (
    <PanelScrollContainer
      headingIconType={iconHeadingTypes.WARNING}
      heading={content.review_heading_title}
      subHeading={content.review_subheading_title}
      closeText={content.close_button_text}
      closeAction={onClickClose}
      footer={
        <PopoutActionFooter primaryButtonAction={onClickRestore} primaryButtonText={content.primary_button_text} />
      }
    >
      <ControlledForm>
        <div className="gin-bot2">
          {userIds.map(id => (
            <div className="pad2" style={{ borderBottom: '1px solid #d9d9d9' }} key={id}>
              <ConnectedPersonRepresentation
                thumbnailSize={thumbnailSizes.MEDIUM}
                secondaryThumbnailSize={thumbnailSizes.MEDIUM}
                secondaryThumbnailGlyph={GLYPHS.ICON_ARCHIVED}
                id={`restore-${id}`}
                entityId={id}
                orgId={orgId}
              />
            </div>
          ))}
        </div>
      </ControlledForm>
    </PanelScrollContainer>
  );
}

UserRestoreReviewPane.propTypes = {
  orgId: PropTypes.string.isRequired,
  userIds: PropTypes.array.isRequired,
  localizedContent: PropTypes.object.isRequired,
  onClickRestore: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('userRestoreReviewPane'))(UserRestoreReviewPane);
