import React from 'react';
import PropTypes from 'prop-types';
import { ValidationMessage } from '@oup/shared-front-end';
import classNames from 'classnames';
import { PLATFORMS } from '@oup/shared-node-browser/constants.js';
import { messageType, displayOnTypes } from '../../../globals/appConstants';
import formatMaintenanceMessage from '../../../components/SystemNotification/formatMaintenanceMessage.js';
import { getCurrentPlatform } from '../../../utils/platform';
import styles from './SystemNotificationBanner.scss';

function ReadOnlyNotification({ content }) {
  return (
    <div role="alert" className={styles.platformNotificationTopBanner}>
      <ValidationMessage state="information">{content.org_read_only_notification}</ValidationMessage>
    </div>
  );
}

ReadOnlyNotification.propTypes = {
  content: PropTypes.object.isRequired
};

function PlatformNotification({ content, isAuthenticated, platformNotification, onBannerClose, className }) {
  const placement = isAuthenticated ? displayOnTypes.postLogin : displayOnTypes.homepage;
  const hasEnabledNotificationForPlacement =
    platformNotification?.displayOn && platformNotification?.displayOn[placement];
  const platform = getCurrentPlatform();

  if (!hasEnabledNotificationForPlacement) return null;

  const isDismissable = typeof onBannerClose === 'function';
  const isHub = platform.toUpperCase() === PLATFORMS.HUB;

  const maintenanceMessage = toBeReplaced => {
    if (platformNotification?.messageType !== messageType.MAINTENANCE) return '';

    return formatMaintenanceMessage(
      platformNotification?.messageDetails?.maintenanceStartDate,
      platformNotification?.messageDetails?.maintenanceEndDate,
      toBeReplaced
    );
  };

  const message = {
    [displayOnTypes.homepage]: {
      [messageType.GENERIC]: content.home_generic_message,
      [messageType.MAINTENANCE]: maintenanceMessage(content.home_maintenance_message)
    },
    [displayOnTypes.postLogin]: {
      [messageType.GENERIC]: content.post_login_generic_message,
      [messageType.MAINTENANCE]: maintenanceMessage(content.post_login_maintenance_message)
    }
  }[placement][platformNotification.messageType];

  return (
    <div
      role="alert"
      className={classNames(styles.platformNotificationTopBanner, styles.platformNotificationCustom, className)}
    >
      <div className="grid">
        <div className="row">
          <div className={classNames({ col: !isHub, flex: isHub })}>
            <ValidationMessage state="warning" className={styles.validationMessageComponent}>
              <div className={styles.platformNotificationMessage}>
                <span>{message}</span>
                {isDismissable && (
                  <button type="button" className={styles.dismissButton} onClick={onBannerClose}>
                    ✕
                  </button>
                )}
              </div>
            </ValidationMessage>
          </div>
        </div>
      </div>
    </div>
  );
}

PlatformNotification.propTypes = {
  content: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  platformNotification: PropTypes.object.isRequired,
  onBannerClose: PropTypes.func.isRequired,
  className: PropTypes.object
};

function SystemNotificationComponent({
  readOnlyMode,
  isAuthenticated,
  content,
  platformNotification,
  onBannerClose,
  className
}) {
  return readOnlyMode ? (
    <ReadOnlyNotification content={content} />
  ) : (
    <PlatformNotification
      content={content}
      isAuthenticated={isAuthenticated}
      platformNotification={platformNotification}
      onBannerClose={onBannerClose}
      className={className}
    />
  );
}

SystemNotificationComponent.propTypes = {
  readOnlyMode: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  platformNotification: PropTypes.object.isRequired,
  onBannerClose: PropTypes.func.isRequired,
  className: PropTypes.object
};

export default SystemNotificationComponent;
