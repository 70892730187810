import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from '../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import styles from './EltCoreProductBuilder.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import { isAdminSupportOrContent as isAdminSupportOrContentSelector } from '../../../../redux/selectors/authorization/user';

function EltCoreProductBuilder({ localizedContent: { eltCoreProductBuilder: content }, isAdminSupportOrContent }) {
  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      <h1>{content.title}</h1>
      <span className={styles.subheading}>{content.subtitle}</span>
      <div className={styles.linkContainer}>
        {isAdminSupportOrContent && (
          <Button
            to="/eltcore-builder/uploadProductStructure"
            type={buttonTypes.SECONDARY}
            text={content.upload_a_file_for_product_structure}
            fullWidth
            customClassName={styles.button}
          />
        )}
        <Button
          to="/eltcore-builder/preview"
          type={buttonTypes.SECONDARY}
          text={content.view_products}
          fullWidth
          customClassName={styles.button}
        />
      </div>
    </PageWrapper>
  );
}

EltCoreProductBuilder.propTypes = {
  localizedContent: PropTypes.object,
  isAdminSupportOrContent: PropTypes.bool.isRequired
};

export default compose(
  connect(state => ({
    isAdminSupportOrContent: isAdminSupportOrContentSelector(state)
  })),
  withLocalizedContent('eltCoreProductBuilder')
)(EltCoreProductBuilder);
