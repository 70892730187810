import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Style
import { connect } from 'react-redux';
import { selfDefinedUserTypes } from '@oup/shared-node-browser/user';
import validateEmail from '@oup/shared-node-browser/validateEmail';
import compose from '../../../../../../utils/compose/compose.js';
import { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon';
import styles from './enrollUser.scss';
// Redux
// Redux Action imports
import { showEnrolUserForm } from '../../../../../../redux/reducers/enrolUser.reducer.js';
import {
  triggerSearch,
  setTerm,
  setPage,
  setPageClass,
  initialiseInstance
} from '../../../../../../redux/reducers/data/search.reducer';
import { userEnrolAddToClassViewConfirmation } from '../../../../../../redux/actions/userEnrol';
import { setClassroomNameEdit, closeForm } from '../../../../../../redux/reducers/classroomCreate.js';
// Constants
import enrollUserConstants from './enrolUserConstants';
// Localized Content
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
// Utils
import { isHubMode } from '../../../../../../utils/platform.js';
// Components
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter';
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading';
import Button, { buttonTypes } from '../../../../../../components/Button/Button';
import EnrolUserAddToClassForm from './EnrolUserAddToClassForm';
import EnrolUserClassList from './EnrolUserClassList.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import PanelSearchControl from '../../../../../../components/PanelSearchControl/PanelSearchControl';
import PaginationButtons from '../../../../../../components/PaginationButtons/PaginationButtons.js';
import SubSectionSkeletonLoader from '../../../../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import { setClassroomSelected } from '../../../../../../redux/reducers/organisationPage.reducer.js';
import colors from '../../../../../../globals/colors';
import Badge from '../../../../../../components/Badge/Badge';
import { orgRoles } from '../../../../../../globals/orgRoles';

class EnrolUserAddToClass extends Component {
  constructor() {
    super();
    this.state = {
      showAddClassForm: false
    };
  }

  componentWillMount() {
    const { initialiseSearch, orgId, isTempNewClassAdded } = this.props;
    if (!isTempNewClassAdded)
      initialiseSearch(
        enrollUserConstants.ORG_CLASS,
        true,
        {
          active: true,
          orgId
        },
        undefined,
        undefined,
        undefined,
        true
      );
  }

  toggleClassForm = () => {
    const { showAddClassForm } = this.state;
    const { resetFormAction } = this.props;
    this.setState(
      {
        showAddClassForm: !showAddClassForm
      },
      () => {
        if (!showAddClassForm) resetFormAction();
      }
    );
  };

  editClass = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    const { setClassroomNameEditAction, classrooms } = this.props;
    setClassroomNameEditAction(classrooms[id].name, id);
    this.setState({
      showAddClassForm: true
    });
  };

  renderCreateClassButton = () => {
    const {
      showCreateClassButton,
      localizedContent: { enrolUserPanel: content }
    } = this.props;
    const shouldShowButton = !isHubMode() || (isHubMode() && showCreateClassButton);

    return (
      shouldShowButton && (
        <Button
          type={buttonTypes.ROUNDED}
          fullWidth
          text={content.create_a_new_class_btn}
          onClick={this.toggleClassForm}
          glyph={GLYPHS.ICON_PLUS}
          customClassName={styles.dashedButton}
        />
      )
    );
  };

  render() {
    const {
      localizedContent: { enrolUserPanel: content },
      showEnrolUserFormAction,
      closePanel,
      classNameValue,
      paginatedClass,
      loadingResults,
      searchFailed,
      setPageAction,
      classrooms,
      totalResults,
      page,
      setSearchTerm,
      setSelectionAction,
      selectedIds,
      setClassroomNameEditAction,
      orgId,
      handleTempNewClassAdded,
      isPrimarySchool,
      userEnrolAddToClassViewConfirmationAction,
      userType
    } = this.props;

    const { showAddClassForm } = this.state;

    const classList = Object.keys(paginatedClass);
    const isMaxSelectionExceed = selectedIds.length >= enrollUserConstants.CLASS_LIMIT;
    return (
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
            </div>
            <PanelHeading
              title={content.enrol_user_add_to_class_title}
              subtitle={content.enrol_user_add_to_class_subtitle.replace(
                '{classLimit}',
                enrollUserConstants.CLASS_LIMIT
              )}
            />
            <PanelSearchControl
              placeholder={content.enrol_user_add_to_class_input_placeholder}
              setSearchTerm={setSearchTerm}
            />
          </div>
        }
        footerContent={
          <div>
            {selectedIds.length ? (
              <div id="selectedTotal" className={styles.selectedFooter}>
                <Badge backgroundColor={colors.PRIMARY_BLUE} value={selectedIds.length} />
                <span>
                  &nbsp;{content.selected_text} {isMaxSelectionExceed && content.maximum_text}
                </span>
              </div>
            ) : null}
            <PopoutNavFooter
              nextButtonText={content.confirmation_button_done}
              nextAction={
                isPrimarySchool && userType === selfDefinedUserTypes.CLASS_STUDENT
                  ? userEnrolAddToClassViewConfirmationAction
                  : showEnrolUserFormAction
              }
              backAction={
                isPrimarySchool && userType === selfDefinedUserTypes.CLASS_STUDENT
                  ? userEnrolAddToClassViewConfirmationAction
                  : showEnrolUserFormAction
              }
            />
          </div>
        }
      >
        <div className={styles.enrolUserScrollContainerContent}>
          {!(isPrimarySchool && userType === selfDefinedUserTypes.CLASS_STUDENT) ? (
            <div className={styles.enrollUserCreateClass}>
              {showAddClassForm ? (
                <EnrolUserAddToClassForm
                  toggleClassForm={this.toggleClassForm}
                  classNameValue={classNameValue}
                  content={content}
                  setClassroomNameEditAction={setClassroomNameEditAction}
                  orgId={orgId}
                  isMaxSelectionExceed={isMaxSelectionExceed}
                  handleTempNewClassAdded={handleTempNewClassAdded}
                />
              ) : (
                this.renderCreateClassButton()
              )}
            </div>
          ) : null}

          {loadingResults ? (
            <SubSectionSkeletonLoader
              largeRectWidth="60%"
              smallRectWidth="40%"
              speed={2}
              foregroundColor={colors.COLOR_GREY_DISABLED}
              backgroundColor={colors.COLOR_WHITE}
            />
          ) : null}

          {!loadingResults && !searchFailed && (!classList || classList.length === 0) && (
            <div className="gin2">
              <p> {content.no_class_found}</p>
            </div>
          )}
          {!loadingResults && !searchFailed && classList && classList.length > 0 && (
            <EnrolUserClassList
              classList={classList}
              classrooms={classrooms}
              setSelectionAction={setSelectionAction}
              selectedIds={selectedIds}
              editClass={this.editClass}
              isMaxSelectionExceed={isMaxSelectionExceed}
            />
          )}

          {totalResults > 10 && (
            <div className="gin-top2">
              <PaginationButtons
                idPrefix="orgClassesSearch"
                value={page}
                numberOfPages={Math.ceil(totalResults / 10)}
                onClick={setPageAction}
                aria={{ 'aria-controls': 'searchResults' }}
                paginationCenter
              />
            </div>
          )}
        </div>
      </ScrollContainer>
    );
  }
}

EnrolUserAddToClass.propTypes = {
  closePanel: PropTypes.func.isRequired,
  localizedContent: PropTypes.any,
  showEnrolUserFormAction: PropTypes.any,
  classNameValue: PropTypes.any,
  paginatedClass: PropTypes.object.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  classrooms: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  searchFailed: PropTypes.bool,
  setPageAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSelectionAction: PropTypes.func.isRequired,
  selectedIds: PropTypes.array.isRequired,
  initialiseSearch: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  isTempNewClassAdded: PropTypes.bool.isRequired,
  setClassroomNameEditAction: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired,
  handleTempNewClassAdded: PropTypes.func.isRequired,
  isPrimarySchool: PropTypes.bool.isRequired,
  userEnrolAddToClassViewConfirmationAction: PropTypes.func,
  userType: PropTypes.string.isRequired,
  showCreateClassButton: PropTypes.bool.isRequired
};

export default compose(
  withLocalizedContent('enrolUserPanel'),
  connect(
    (state, { orgId }) => {
      const searchInstance = state.search.orgClasses || {};
      return {
        searchClassesValue: state.enrolUser.searchClassesValue,
        classNameValue: state.enrolUser.classNameValue,
        paginatedClass: searchInstance.paginatedClassList || {},
        filters: searchInstance.filters,
        totalResults: searchInstance.totalResults,
        loadingResults: searchInstance ? searchInstance.loading : false,
        searchFailed: searchInstance.error,
        page: searchInstance.page,
        classrooms: state.classrooms.data,
        selectedIds: state.organisationPage.selectedClassroomIds,
        isPrimarySchool: state.organisations.data[orgId]
          ? state.organisations.data[orgId].role === orgRoles.PRIMARY_SCHOOL
          : false,
        showCreateClassButton: validateEmail(state.enrolUser.emailValue)
      };
    },
    {
      userEnrolAddToClassViewConfirmationAction: userEnrolAddToClassViewConfirmation,
      showEnrolUserFormAction: showEnrolUserForm,
      setPageAction: page =>
        featureIsEnabled('client-side-pagination') ? setPageClass('orgClasses', page) : setPage('orgClasses', page),
      triggerSearchAction: () => triggerSearch('orgClasses'),
      setSearchTerm: term => setTerm('orgClasses', term),
      initialiseSearch: initialiseInstance,
      setSelectionAction: setClassroomSelected,
      // Connect onChange actions
      setClassroomNameEditAction: setClassroomNameEdit,
      resetFormAction: closeForm
    }
  )
)(EnrolUserAddToClass);
