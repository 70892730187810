import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import compose from '../../utils/compose/compose.js';
import { GlyphIcon, EmblemSize, EmblemShape } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ContentPlayerPrintView.scss';
import { closeMobileMenuModal, togglePrintViewModal } from '../../redux/actions/structuredContentPlayer';
import useRestrictedState from './useRestrictedState';
import ContentPlayerFooter from '../ContentPlayerFooter/ContentPlayerFooter';
import { MAX_ZOOM_MULTIPLIER, MIN_ZOOM, WHITE_COLOR } from '../../globals/cptConstants';
import useMediaQuery from '../../utils/mediaQuery';
import { EPS_ASSETS_BASE_ELT_MISC } from '../../../sharedNodeBrowser/constants';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.js';
import { isLocal } from '../../globals/envSettings.js';

const CONTENT_PATH = isLocal()
  ? `http://${window.location.hostname}:${window.location.port}${EPS_ASSETS_BASE_ELT_MISC}`
  : `https://${window.location.hostname}${EPS_ASSETS_BASE_ELT_MISC}`;

function ContentPlayerPrintView({ title, cptContent, toggleModal, pages, spreadImage, closeMobileMenuModalAction }) {
  const [isSpreadSheet, setIsSpreadSheet] = useState(true);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);
  const [isMultiplePages, setIsMultiplePages] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fileExists, setFileExists] = useState(false);
  const [currentPage, setCurrentPage] = useRestrictedState(0);
  const iframeRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const isDisabledLeft = currentPage === 0;
  const isDisabledRight = currentPage === pages.length - 1;

  useEffect(() => {
    const iframe = iframeRef?.current;

    const handleIframeLoad = () => {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
      const img = iframeDoc?.getElementsByTagName('img')[0];

      if (img) {
        const html = iframeDoc.getElementsByTagName('html')[0];
        const body = iframeDoc.getElementsByTagName('body')[0];

        html.style.height = '100%';
        body.style.height = '100%';

        const iframeHeight = iframe.clientHeight;
        const imgHeight = img.naturalHeight;

        const scaleFactor = iframeHeight / imgHeight;

        img.style.transform = `scale(${scaleFactor})`;
        img.style.transformOrigin = '0 0';
        img.style.width = 'auto';
        img.style.height = 'auto';
        img.style.maxWidth = 'none';
        img.style.objectFit = 'contain';
      }
    };

    if (iframe) {
      iframe.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [isIFrameLoaded, isSpreadSheet]);

  useEffect(() => {
    iframeRef.current?.addEventListener('load', () => setIsIFrameLoaded(!isIFrameLoaded));
    return () => {
      iframeRef.current?.removeEventListener('load', () => setIsIFrameLoaded(!isIFrameLoaded));
    };
  }, [iframeRef.current, isSpreadSheet]);

  const handleZoomIn = () => {
    const iframe = iframeRef.current;
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const currentZoom = parseFloat(iframeDoc.body.style.zoom) || 1;
    const maxZoomLimit = MAX_ZOOM_MULTIPLIER * MIN_ZOOM;
    const ZOOM_VALUE = (maxZoomLimit - MIN_ZOOM) / 5;
    iframeDoc.body.style.zoom = (currentZoom <= maxZoomLimit - ZOOM_VALUE
      ? currentZoom + ZOOM_VALUE
      : maxZoomLimit
    ).toString();
  };

  const handleZoomOut = () => {
    const iframe = iframeRef.current;
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const currentZoom = parseFloat(iframeDoc.body.style.zoom) || 1;
    const maxZoomLimit = MAX_ZOOM_MULTIPLIER * MIN_ZOOM;
    const ZOOM_VALUE = (maxZoomLimit - MIN_ZOOM) / 5;
    iframeDoc.body.style.zoom = (currentZoom > MIN_ZOOM + ZOOM_VALUE ? currentZoom - ZOOM_VALUE : MIN_ZOOM).toString();
  };

  const handleZoomReset = () => {
    const iframe = iframeRef.current;
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.body.style.zoom = MIN_ZOOM;
    iframeDoc.body.firstChild.style.margin = '0 auto';
  };

  const handlePortraitView = () => {
    setIsSpreadSheet(false);
    if (isMobile) {
      setIsSpreadSheet(true);
    }
  };

  const handleLandscapeView = () => {
    setIsSpreadSheet(true);
    if (isMobile) {
      setIsSpreadSheet(false);
    }
  };

  const handlePageChange = () => setCurrentPage((currentPage + 1) % pages.length);

  const handleCloseButtonClick = () => {
    closeMobileMenuModalAction();
    toggleModal();
  };

  const renderFooter = () => {
    if (pages.length <= 1) {
      return null;
    }

    return (
      <ContentPlayerFooter
        cptContent={cptContent}
        isDisabledLeft={isDisabledLeft}
        isDisabledRight={isDisabledRight}
        handlePageChange={handlePageChange}
      />
    );
  };

  const detectNumberOfPages = () => {
    const twoPages = pages.length === 2;
    if (twoPages) {
      setIsMultiplePages(true);
      setIsSpreadSheet(true);
    } else {
      setIsSpreadSheet(false);
      setIsMultiplePages(false);
    }
  };

  const checkFile = async () => {
    try {
      const response = await fetch(`${CONTENT_PATH}/${spreadImage}`);
      const responseContainsImage = response.headers.get('Content-Type').startsWith('image/');

      if (response.status === 200 && responseContainsImage) {
        setFileExists(true);
        return true;
      }
      setIsLoading(false);
      return false;
    } catch (err) {
      setIsLoading(false);
      setFileExists(false);
      return false;
    }
  };

  useEffect(() => {
    detectNumberOfPages();
  }, []);

  useEffect(() => {
    (async () => {
      const isSpreadSheetAvailable = await checkFile();
      if (!isSpreadSheetAvailable) {
        handlePortraitView();
      }
    })();
  }, [fileExists]);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const renderLoading = () => <LoadingSpinner customClass={styles.loadingSpinner} customSpinnerColor={WHITE_COLOR} />;

  const renderErrorMessage = () => <h3 className={styles.imageNotFoundMessage}>{cptContent.image_not_found}</h3>;

  return (
    <>
      <div className={styles.cpDesktopView}>
        {isLoading && renderLoading()}
        {!fileExists && isSpreadSheet && !isLoading && renderErrorMessage()}
        {isSpreadSheet && !isLoading && fileExists ? (
          <iframe
            ref={iframeRef}
            src={`${CONTENT_PATH}/${spreadImage}`}
            id="imageIframe"
            className={classnames(styles.imageIframeBig, isLoading ? styles.hidden : '')}
            title="book"
            frameBorder="0"
            align="middle"
            onLoad={handleLoad}
          />
        ) : (
          <div className={styles.contentContainer}>
            <div className={classnames(styles.leftButtonWrapper, isLoading ? styles.hidden : '')}>
              <button
                className={classnames(EmblemSize.MEDIUM, EmblemShape.CIRCLE, `${isDisabledLeft && styles.cpDisabled}`)}
                type="button"
                aria-label={cptContent.previous_title}
                onClick={!isDisabledLeft && handlePageChange ? handlePageChange : undefined}
              >
                <SVGIcon glyph={GlyphIcon.ICON_ARROW_BUTTON_LEFT} />
              </button>
            </div>
            <iframe
              ref={iframeRef}
              src={`${CONTENT_PATH}/${pages[currentPage]}`}
              id="imageIframe"
              className={classnames(styles.imageIframeSmall, isLoading ? styles.hidden : '')}
              title="book"
              frameBorder="0"
              align="middle"
              onLoad={handleLoad}
            />
            <div className={classnames(styles.rightButtonWrapper, isLoading ? styles.hidden : '')}>
              <button
                className={classnames(EmblemSize.MEDIUM, EmblemShape.CIRCLE, `${isDisabledRight && styles.cpDisabled}`)}
                type="button"
                aria-label={cptContent.previous_title}
                onClick={!isDisabledRight && handlePageChange ? handlePageChange : undefined}
              >
                <SVGIcon glyph={GlyphIcon.ICON_ARROW_BUTTON_RIGHT} />
              </button>
            </div>
          </div>
        )}
        <div className={styles.header}>
          <p className={styles.title}>{title}</p>
          <div className={styles.cpCenterWrapper}>
            <button onClick={() => handleZoomIn()} type="button" id="zoomInButton">
              <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                <SVGIcon glyph={GlyphIcon.ICON_ZOOM_IN} />
              </div>
              <p>{cptContent.zoom_in}</p>
            </button>

            <button onClick={() => handleZoomOut()} type="button" id="zoomOutButton">
              <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                <SVGIcon glyph={GlyphIcon.ICON_ZOOM_OUT} />
              </div>
              <p>{cptContent.zoom_out}</p>
            </button>
            <button onClick={() => handleZoomReset()} type="button" id="zoomResetButton">
              <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                <SVGIcon glyph={GlyphIcon.ICON_ZOOM_RESET} />
              </div>
              <p>{cptContent.zoom_reset}</p>
            </button>

            <button onClick={handlePortraitView} type="button" id="portraitButton" className={styles.cpMenuItem}>
              <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon, styles.cpMenuIcon)}>
                <SVGIcon glyph={GlyphIcon.ICON_PORTRAIT} />
              </div>
              <p>{cptContent.one_page} </p>
            </button>

            <button
              onClick={handleLandscapeView}
              disabled={!isMultiplePages}
              type="button"
              id="landscapeButton"
              className={styles.cpMenuItem}
            >
              <div
                className={classnames(
                  EmblemShape.SQUARE,
                  isMultiplePages ? styles.buttonIcon : styles.disabledButton,
                  styles.cpMenuIcon
                )}
              >
                <SVGIcon glyph={GlyphIcon.ICON_LANDSCAPE} />
              </div>
              <p>{cptContent.two_page}</p>
            </button>
          </div>
          <button onClick={toggleModal} type="button" className={styles.printViewCloseButtonContainer}>
            <div className={classnames(styles.emblem, EmblemSize.MEDIUM, styles.cpClose)}>
              <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
            </div>
          </button>
        </div>
      </div>

      <div className={styles.cpModal}>
        <div className={styles.cpModalContent}>
          <header className={styles.header}>
            {isMobile ? null : <p className={styles.title}>{title}</p>}
            <div className={styles.cpCloseWrapper}>
              <div className={styles.mobilePrintButtonsContainer}>
                <div className={styles.mobilePrintButton}>
                  <button onClick={() => handleZoomIn()} type="button" id="zoomInButton">
                    <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                      <SVGIcon glyph={GlyphIcon.ICON_ZOOM_IN} />
                    </div>
                    <p>{cptContent.zoom_in}</p>
                  </button>
                </div>
                <div className={styles.mobilePrintButton}>
                  <button onClick={() => handleZoomOut()} type="button" id="zoomOutButton">
                    <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                      <SVGIcon glyph={GlyphIcon.ICON_ZOOM_OUT} />
                    </div>
                    <p>{cptContent.zoom_out}</p>
                  </button>
                </div>
                <div className={styles.mobilePrintButton}>
                  <button onClick={() => handleZoomReset()} type="button" id="zoomResetButton">
                    <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                      <SVGIcon glyph={GlyphIcon.ICON_ZOOM_RESET} />
                    </div>
                    <p>{cptContent.zoom_reset}</p>
                  </button>
                </div>

                <div className={styles.mobilePrintButton}>
                  <button onClick={handlePortraitView} type="button" id="portraitButton">
                    <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                      <SVGIcon glyph={GlyphIcon.ICON_PORTRAIT} />
                    </div>
                    <p>{cptContent.one_page} </p>
                  </button>
                </div>
                <div className={styles.mobilePrintButton}>
                  <button onClick={handleLandscapeView} type="button" id="landscapeButton">
                    <div className={classnames(EmblemShape.SQUARE, styles.buttonIcon)}>
                      <SVGIcon glyph={GlyphIcon.ICON_LANDSCAPE} />
                    </div>
                    <p>{cptContent.two_page}</p>
                  </button>
                </div>
              </div>

              <button onClick={handleCloseButtonClick} type="button" className={styles.printViewCloseButtonContainer}>
                <div className={classnames(styles.emblem, EmblemSize.MEDIUM, styles.cpCloseButton)}>
                  <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
                </div>
              </button>
            </div>
          </header>
          {isLoading && renderLoading()}
          {!fileExists && !isLoading && renderErrorMessage()}
          {!isSpreadSheet && fileExists ? (
            <div className={classnames(styles.imageContainerMobileSpread, isLoading ? styles.hidden : '')}>
              <img className={styles.bookImage} src={`${CONTENT_PATH}/${spreadImage}`} alt="book" />
            </div>
          ) : (
            <>
              {fileExists && (
                <div className={classnames(styles.imageContainerMobile, isLoading ? styles.hidden : '')}>
                  <img className={styles.bookImage} src={`${CONTENT_PATH}/${pages[currentPage]}`} alt="book" />
                </div>
              )}
              <footer className={styles.cpModalFooter}>{renderFooter()}</footer>
            </>
          )}
        </div>
      </div>
    </>
  );
}

ContentPlayerPrintView.propTypes = {
  title: PropTypes.string.isRequired,
  cptContent: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  pages: PropTypes.objectOf(PropTypes.string).isRequired,
  spreadImage: PropTypes.string,
  closeMobileMenuModalAction: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleModal: togglePrintViewModal,
  closeMobileMenuModalAction: closeMobileMenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerPrintView);
