import { Component } from 'react';
import createFactory from './utils/createFactory';
import setDisplayName from './utils/setDisplayName';
import wrapDisplayName from './utils/wrapDisplayName';
import mapValues from './utils/mapValues';

const withStateHandlers = (initialState, stateUpdaters) => BaseComponent => {
  const factory = createFactory(BaseComponent);

  class WithStateHandlers extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = typeof initialState === 'function' ? initialState(this.props) : initialState;

    stateUpdaters = mapValues(stateUpdaters, handler => (mayBeEvent, ...args) => {
      // Having that functional form of setState can be called async
      // we need to persist SyntheticEvent
      if (mayBeEvent && typeof mayBeEvent.persist === 'function') {
        mayBeEvent.persist();
      }

      this.setState((state, props) => handler(state, props)(mayBeEvent, ...args));
    });

    render() {
      return factory({
        ...this.props,
        ...this.state,
        ...this.stateUpdaters
      });
    }
  }

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'withStateHandlers'))(WithStateHandlers);
  }

  return WithStateHandlers;
};

export default withStateHandlers;
