import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from '../../utils/compose/compose.js';
import {
  setCurrentContent,
  closeMobileMenuModal,
  toggleContentsSubmenuModal,
  toggleMobileGamesErrorPopup,
  toggleResourceModal,
  setResourceModalData
} from '../../redux/actions/structuredContentPlayer.js';
import styles from './ContentLevelMenu.scss';
import SVGIcon from '../SVGIcon/SVGIcon';
import useMediaQuery from '../../utils/mediaQuery.js';
import { onKeyDownHandler } from '../../services/cptHelperMethods.js';

function FileLevelMenu({
  fileName,
  glyph,
  type,
  resourceIdValue,
  subtitleResourceIdValue,
  itemId,
  cptContent,
  toggleMobileGamesError,
  toggleResourceModalAction,
  setResourceModalDataAction
}) {
  const isPDF = type === 'pdf';
  const isGame = type === 'game';
  const isMobile = useMediaQuery('(max-width: 920px)');

  let url = null;

  const resources = {
    resourceId: resourceIdValue,
    subtitleResourceId: subtitleResourceIdValue,
    itemId,
    title: fileName,
    format: 'mp3'
  };

  const transcriptResource = {
    resourceId: subtitleResourceIdValue
  };

  const openPlayModal = () => {
    if (!type) {
      return;
    }
    if (isGame && isMobile) {
      toggleMobileGamesError();
    }

    if (isGame) {
      url = resourceIdValue;
    }

    setResourceModalDataAction({
      type,
      resourceIdValue,
      resources,
      transcriptResource,
      cptContent,
      isGame,
      url
    });

    toggleResourceModalAction();
  };

  if (isPDF) {
    return (
      <div>
        <a href={resourceIdValue} target="_blank" rel="noreferrer" className={styles.menuItem}>
          <div className={styles.iconContainer}>
            <SVGIcon glyph={glyph} className={styles.icon} />
          </div>
          <p>{fileName}</p>
        </a>
      </div>
    );
  }

  return (
    <div className={classnames(styles.subMenuContainer, resourceIdValue ? '' : styles.subMenuContainerDisabled)}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={onKeyDownHandler(openPlayModal)}
        onClick={openPlayModal}
        className={classnames(styles.menuItem, resourceIdValue ? '' : styles.menuItemDisabled)}
      >
        <div className={styles.iconContainer}>
          <SVGIcon glyph={glyph} className={styles.icon} />
        </div>
        <p>{fileName}</p>
      </div>
    </div>
  );
}

FileLevelMenu.propTypes = {
  fileName: PropTypes.string.isRequired,
  glyph: PropTypes.string.isRequired,
  type: PropTypes.string,
  resourceIdValue: PropTypes.string,
  subtitleResourceIdValue: PropTypes.string,
  itemId: PropTypes.string,
  cptContent: PropTypes.object,
  toggleMobileGamesError: PropTypes.func,
  toggleResourceModalAction: PropTypes.func,
  setResourceModalDataAction: PropTypes.func
};

const mapStateToProps = ({ structuredContentPlayer: { xApiLaunchToken, isMobileGamePopupOpen } }) => ({
  xApiLaunchToken,
  isMobileGamePopupOpen
});

const mapDispatchToProps = {
  setCurrentContentData: setCurrentContent,
  closeMobileMenuModalAction: closeMobileMenuModal,
  toggleModal: toggleContentsSubmenuModal,
  toggleMobileGamesError: toggleMobileGamesErrorPopup,
  toggleResourceModalAction: toggleResourceModal,
  setResourceModalDataAction: setResourceModalData
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FileLevelMenu);
