import { put } from 'redux-saga/effects';
import { getContentPreviewSuccess, getContentPreviewFailure } from '../../../../actions/contentPreview';
import getEltGamesAPI from '../../../apiCalls/eltGames/getEltGamesApi';

function* getEltGames(payload) {
  console.log('[getEltGames Saga] Start Request');
  const result = yield getEltGamesAPI(payload);

  if (result) {
    yield put(getContentPreviewSuccess(result));
  } else {
    yield put(getContentPreviewFailure(result.error));
  }
}

export default getEltGames;
