import PropTypes from 'prop-types';
import React from 'react';
import DOMPurify from 'dompurify';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { URL_HIERARCHY_DELIMITER } from '@oup/shared-node-browser/constants';
import { TextLink } from '@oup/shared-front-end';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Breadcrumbs.scss';

const HIERARCHY_DELIMITER = '~';

/**
 * Returns the location minus `n` levels up the tree.
 *
 * @param {Number} n Number of levels to remove from slug.
 */
export const pathnameUpNLevels = n =>
  window.location.pathname
    .split('/')
    .slice(0, -n)
    .join('/');

/**
 * Builds the path array for the Breadcrumbs component.
 *
 * @param {Object} navigation The navigation object
 */
export const breadcrumbPathsFromNav = navigation => {
  const currentUrl = window.location.pathname.replace(/\/$/, '');
  const baseUrl = currentUrl.slice(0, currentUrl.indexOf(navigation[0]));

  return navigation
    .filter((item, i) => i !== navigation.length - 1)
    .map((item, i) => ({
      pathname: baseUrl + navigation.slice(0, i + 1).join('/'),
      text: navigation[i]
    }));
};

/**
 * Builds the path array for the Breadcrumbs component from the
 * navigation hash.
 */
export const breadcrumbPathsFromHash = () => {
  const hash = window.location.hash.split('/').slice(1);

  return hash.map((item, i) => ({
    pathname: window.location.pathname,
    hash: i === 0 ? '' : `#/${hash.slice(0, i).join('/')}`,
    text: i === 0 ? 'Class' : decodeURIComponent(hash[i - 1])
  }));
};

/**
 * Builds the path array from the hierarchy property that comes in with
 * some data.
 *
 * @param {String} hierarchy The hierarchy string representation.
 * @param {String} productTitle The title of the roduct
 */
export const breadcrumbPathsFromHierarchy = (hierarchy, productTitle) =>
  hierarchy
    ? hierarchy
        .split(URL_HIERARCHY_DELIMITER)
        // .slice(0, -1)
        .map(level => {
          const currentLevel = level.split(HIERARCHY_DELIMITER);
          return {
            pathname: window.location.pathname,
            ...(currentLevel[1] ? { hash: `#${encodeURIComponent(currentLevel[0])}` } : {}),
            text: currentLevel[1] || productTitle || 'Workbook'
          };
        })
    : [];

/** The component renders the texts and link of the breadcrumb component. */
function Particle({ pathname, hash, text, active = false, disabled = false }) {
  return active ? (
    // eslint-disable-next-line react/no-danger
    <span className={classnames(styles.linkActive)} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
  ) : (
    <TextLink
      to={`${pathname}${hash || ''}`}
      component={RouterLink}
      className={classnames(styles.link, disabled && styles.disabled)}
    >
      {text}
    </TextLink>
  );
}

Particle.propTypes = {
  pathname: PropTypes.string.isRequired,
  hash: PropTypes.string,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

/** The component renders a set of breadcrumbs defined by an array config of links and names. */
function Breadcrumbs({ paths, disabled = false }) {
  return (
    <div className={styles.breadcrumbs}>
      {paths.map(({ pathname, hash, text }, iterator) =>
        // iterator === paths.length - 1 || iterator === 0 ? (
        iterator === 0 ? (
          <div className={styles.level} key={text}>
            <Particle
              active={iterator === paths.length - 1}
              pathname={pathname}
              hash={hash}
              text={text}
              disabled={disabled}
            />
          </div>
        ) : (
          <div className={styles.level} key={text}>
            <SVGIcon
              className={classnames(styles.chevron, disabled && styles.disabled)}
              glyph={GLYPHS.ICON_RIGHT_THICK}
            />
            <Particle
              active={iterator === paths.length - 1}
              pathname={pathname}
              hash={hash}
              text={text}
              disabled={disabled}
            />
          </div>
        )
      )}
    </div>
  );
}

Breadcrumbs.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      hash: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  disabled: PropTypes.bool
};

export default Breadcrumbs;
