import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import { Helmet } from 'react-helmet';

import withRouter from '../../../utils/withRouter';
import compose from '../../../utils/compose/compose.js';
import colors from '../../../globals/colors';
import withLocalizedContent from '../../../language/withLocalizedContent';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Card from '../../../components/Card/Card';
import Button, { GLYPHS, buttonTypes } from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput/TextInput';
import { PageWrapper, SVGIcon } from '../../../components';
import actions from '../../../redux/actions';
import { StatusEnum } from '../../../redux/sagas/appFunctions/authorisedFunctions/preseeding/preseedOrgData';
import Checkbox from '../../../components/Checkbox/Checkbox';
import styles from './PreSeedingOrganisation.scss';
import Tooltip from '../../../components/Tooltip/Tooltip';

const initialState = { organisationId: '', deleteClasses: false, deleteMembers: false };

class PreSeedingOrganisationPage extends Component {
  constructor() {
    super();
    this.state = initialState;
  }

  _createChangeHandler = name => value => {
    this.setState({ [name]: value });
  };

  _reset = () => {
    const { preSeedReset } = this.props;
    preSeedReset();
    this.setState(initialState);
  };

  _onSubmit = () => {
    const { organisationId, deleteClasses, deleteMembers } = this.state;
    const { status, preSeedingSubmit } = this.props;

    if (status !== StatusEnum.InProgress) {
      preSeedingSubmit({ orgId: organisationId, deleteClasses, deleteMembers });
    }
  };

  _goBackToDashboard = () => {
    const { redirect } = this.props;
    redirect('/dashboard');
  };

  render() {
    const { organisationId, deleteClasses, deleteMembers } = this.state;

    const {
      status,
      results,
      localizedContent: { preSeedingOrganisationPage: content }
    } = this.props;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        <div className="grid pad-top2 pad-bot4">
          <Button
            id="backButton"
            text={content.backButton}
            type={buttonTypes.SECONDARY}
            ashboa
            glyph={GLYPHS.ICON_LEFT}
            onClick={this._goBackToDashboard}
          />
          <div className="row gin-bot3">
            <div className="col sm10 pad-bot2">
              <PageHeading title={content.title} />
            </div>
            <div className="col md12">
              <Card headingText={content.title} headingColor={colors.PROFILE}>
                <TextInput
                  id="message"
                  label={content.textInputLabel}
                  placeholder={content.textInputPlaceholder}
                  value={organisationId}
                  name="organisationId"
                  onChange={this._createChangeHandler('organisationId')}
                />
                <div className={styles.container}>
                  <Checkbox
                    id="archivedClasses"
                    label={content.classesCheckboxLabel}
                    value={deleteClasses}
                    onChange={this._createChangeHandler('deleteClasses')}
                  />
                  <Tooltip title={content.classesCheckboxTooltip} className={styles.largeTooltip}>
                    <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
                  </Tooltip>
                </div>
                <div className={styles.container}>
                  <Checkbox
                    id="archivedMembers"
                    label={content.membersCheckboxLabel}
                    value={deleteMembers}
                    onChange={this._createChangeHandler('deleteMembers')}
                  />
                  <Tooltip title={content.membersCheckboxTooltip} className={styles.largeTooltip}>
                    <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
                  </Tooltip>
                </div>
                {status === StatusEnum.InProgress && <Message text={content.preSeedingInProgressText} color="black" />}
                {status === StatusEnum.Success && <Message text={content.preseedingFinishedText} color="green" />}
                {status === StatusEnum.Error && results !== '' && (
                  <Message error={results.errorResult} errorMessage={content.errorMessage} color="red" />
                )}
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div>
                <span className="gin-right2">
                  <Button
                    type={buttonTypes.PRIMARY}
                    text={content.submitButton}
                    onClick={this._onSubmit}
                    disabled={status === StatusEnum.InProgress || status === StatusEnum.Error || organisationId === ''}
                  />
                </span>
                <span className="gin-right2">
                  <Button
                    type={buttonTypes.SECONDARY}
                    text={content.clearButton}
                    onClick={this._reset}
                    disabled={status === StatusEnum.InProgress || organisationId === ''}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

function Message({ text, color, error, errorMessage }) {
  const style = {
    marginTop: '10px'
  };

  return (
    <div style={{ ...style, color }}>
      {text ||
        (error && (
          <div>
            {errorMessage}
            <div key={error.orgId}>{error.orgId}</div>
          </div>
        ))}
    </div>
  );
}

Message.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.array,
  errorMessage: PropTypes.string
};

PreSeedingOrganisationPage.propTypes = {
  status: PropTypes.string.isRequired,
  results: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  preSeedingSubmit: PropTypes.func.isRequired,
  preSeedReset: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('preSeedingOrganisationPage'),
  connect(
    state => pick(state.preseedOrgData, ['status', 'results']),
    (dispatch, props) => ({
      preSeedingSubmit: input => {
        dispatch(actions.preseedOrg(input));
      },
      preSeedReset: () => {
        dispatch(actions.preseedReset());
      },
      redirect: path => {
        dispatch(actions.preseedReset());
        props.history.push(path);
      }
    })
  )
)(PreSeedingOrganisationPage);
