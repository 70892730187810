import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ENTITY_TYPES from '../../globals/entityTypes.js';

// Action imports
import { closeForm } from '../../redux/reducers/classroomEdit.js';

// Component imports
import PopoutActionFooter from '../../components/PopoutActionFooter/PopoutActionFooter.js';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer.js';
import ClassRepresentation from '../../components/ClassRepresentation/ClassRepresentation.js';
import PopoutConfirmationListItem from '../../components/PopoutConfirmationListItem/PopoutConfirmationListItem.js';
import PopoutConfirmationList from '../../components/PopoutConfirmationList/PopoutConfirmationList.js';
import PanelNavigationLink from '../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import { SIZES as thumbnailSizes } from '../../components/Thumbnail/Thumbnail.js';
import { triggerSearch } from '../../redux/reducers/data/search.reducer.js';

import { isHubMode, isOrbMode } from '../../utils/platform';
import userRoles from '../../globals/userRoles';
import { featureIsEnabled } from '../../globals/envSettings';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import getClassLink from '../../structure/HubDashboardLayout/Services/getClassLink';

import cmsContent from '../../utils/cmsContent.js';

let CMS = {};
// Helper to decide which success/partial/error heading+icon to show:
const getIconHeading = (classroomName, requestFailed, failedIds) => {
  if (requestFailed) {
    // Class not updated successfully
    return {
      title: CMS.class_update_error_text,
      type: iconHeadingTypes.ERROR
    };
  }
  if (failedIds && failedIds.length) {
    // Class updated but had some problems
    return {
      title: (CMS.class_update_partial_error_text || '').replace('{className}', classroomName),
      type: iconHeadingTypes.WARNING
    };
  }
  // Class updated SUCCESSFULLY :)
  return {
    title: (CMS.class_update_success || '').replace('{className}', classroomName),
    type: iconHeadingTypes.VALID
  };
};

const getIllustrationHeading = (classroomName, requestFailed, failedIds) => {
  if (requestFailed) {
    // Class not updated successfully
    return {
      title: CMS.class_update_error_text,
      illustrationSrc: HubIllustrationConstants.ERROR,
      illustrationAltText: HubIllustrationAltText.ERROR
    };
  }
  if (failedIds && failedIds.length) {
    // Class updated but had some problems
    return {
      title: (CMS.class_update_partial_error_text || '').replace('{className}', classroomName),
      illustrationSrc: HubIllustrationConstants.WARNING,
      illustrationAltText: HubIllustrationAltText.WARNING
    };
  }
  // Class updated SUCCESSFULLY
  return {
    title: (CMS.class_update_success || '').replace('{className}', classroomName),
    illustrationSrc: HubIllustrationConstants.SUCCESS,
    illustrationAltText: HubIllustrationAltText.SUCCESS
  };
};

function EditClassroomConfirmation({
  userRole,
  orgId,
  classroomId,
  classroomName,
  initialAmountOfTeachers,
  numberOfRemainingTeachers,
  teachersAdded,
  studentsAdded,
  teachersRemoved,
  studentsRemoved,
  requestFailed,
  failedIds,
  editJoiningCodeData,
  closePopoutAction,
  closeFormAction,
  initialiseSearchAction
}) {
  const navigate = useNavigate();
  CMS = cmsContent.classEditPanel || {};
  return (
    <form>
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink
                isLhs={false}
                text={CMS.close_panel_text}
                action={() => {
                  // Initialise and trigger the search to refresh the list
                  initialiseSearchAction(classroomId, orgId);
                  // Close the Popout panel
                  closePopoutAction(false);
                }}
              />
            </div>
            {isHubMode() ? (
              <PopoutPanelIllustrationHeading {...getIllustrationHeading(classroomName, requestFailed, failedIds)} />
            ) : (
              <PopoutPanelIconHeading {...getIconHeading(classroomName, requestFailed, failedIds)} />
            )}
          </div>
        }
        footerContent={
          <PopoutActionFooter
            // secondaryButtonText={CMS.assign_learning_material_to_class_text}
            // secondaryButtonAction={() => console.log('TODO')}
            primaryButtonText={CMS.button_done_text}
            primaryButtonAction={() => {
              // Initialise and trigger the search to refresh the list
              initialiseSearchAction(classroomId, orgId);
              // Close the Popout panel
              closePopoutAction(false);

              if (
                isHubMode() &&
                (teachersAdded.length || studentsAdded.length || teachersRemoved.length || studentsRemoved.length)
              )
                navigate(`${getClassLink(classroomId)}/people`);
              // Once its closed then reset the form
              setTimeout(closeFormAction, 300);
            }}
          />
        }
      >
        <div>
          <div style={{ padding: '1rem', backgroundColor: '#E5E5E0' }}>
            <ClassRepresentation id="classroomName" thumbnailSize={thumbnailSizes.MEDIUM} name={classroomName} />
          </div>

          {!isOrbMode() &&
          !isHubMode() &&
          userRole === userRoles.ORG_ADMIN &&
          classroomId &&
          featureIsEnabled('class-joining-code') ? (
            <PopoutConfirmationList heading={CMS.page_heading_class_code} id="classroomClassCode">
              {!editJoiningCodeData ||
                (!(editJoiningCodeData.joiningCodeUpdate || 'joiningCodeEnableUpdateChange' in editJoiningCodeData) && (
                  <div className="gin-top2">
                    <em>{CMS.no_changes_to_class_code}</em>
                  </div>
                ))}

              {editJoiningCodeData && editJoiningCodeData.joiningCodeUpdate && (
                <div className="gin-top2">
                  <em>{CMS.joining_code_update}</em>
                </div>
              )}

              {editJoiningCodeData && 'joiningCodeEnableUpdateChange' in editJoiningCodeData && (
                <div className="gin-top2">
                  <em>
                    {editJoiningCodeData.joiningCodeEnableUpdateChange
                      ? CMS.joining_code_enable_on_update
                      : CMS.joining_code_enable_off_update}
                  </em>
                </div>
              )}
            </PopoutConfirmationList>
          ) : null}

          <PopoutConfirmationList heading={CMS.page_heading_teachers} id="classroomTeachers">
            {initialAmountOfTeachers > 0 && numberOfRemainingTeachers === 0 && (
              <div className="gin2">
                <em>{CMS.no_teachers_warning}</em>
              </div>
            )}
            {!teachersAdded.length && !teachersRemoved.length && (
              <div className="gin-top2">
                <em>{CMS.no_changes_to_teachers}</em>
              </div>
            )}
            {teachersAdded.map(userId => (
              <PopoutConfirmationListItem
                id={`classroomTeacher-${userId}`}
                key={userId}
                entityType={ENTITY_TYPES.PERSON}
                entityId={userId}
                isWarning={failedIds.includes(userId)}
              />
            ))}
            {teachersRemoved.map(userId => (
              <PopoutConfirmationListItem
                id={`classroomTeacher-${userId}`}
                key={userId}
                entityType={ENTITY_TYPES.PERSON}
                entityId={userId}
                isWarning={failedIds.includes(userId)}
                isRemoving
              />
            ))}
          </PopoutConfirmationList>

          <PopoutConfirmationList heading={CMS.page_heading_students} id="classroomStudents">
            {!studentsAdded.length && !studentsRemoved.length && (
              <div className="gin-top2">
                <em>{CMS.no_changes_to_students}</em>
              </div>
            )}
            {studentsAdded.map(userId => (
              <PopoutConfirmationListItem
                id={`classroomStudent-${userId}`}
                key={userId}
                entityType={ENTITY_TYPES.PERSON}
                entityId={userId}
                isWarning={failedIds.includes(userId)}
              />
            ))}
            {studentsRemoved.map(userId => (
              <PopoutConfirmationListItem
                id={`classroomStudent-${userId}`}
                key={userId}
                entityType={ENTITY_TYPES.PERSON}
                entityId={userId}
                isWarning={failedIds.includes(userId)}
                isRemoving
              />
            ))}
          </PopoutConfirmationList>
        </div>
      </ScrollContainer>
    </form>
  );
}

EditClassroomConfirmation.propTypes = {
  userRole: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  classroomName: PropTypes.string.isRequired,
  initialAmountOfTeachers: PropTypes.number.isRequired,
  numberOfRemainingTeachers: PropTypes.number.isRequired,
  teachersAdded: PropTypes.arrayOf(PropTypes.string),
  studentsAdded: PropTypes.arrayOf(PropTypes.string),
  teachersRemoved: PropTypes.arrayOf(PropTypes.string),
  studentsRemoved: PropTypes.arrayOf(PropTypes.string),
  requestFailed: PropTypes.bool.isRequired,
  failedIds: PropTypes.arrayOf(PropTypes.string),
  editJoiningCodeData: PropTypes.object,

  // Exit actions. One resets the state
  closePopoutAction: PropTypes.func.isRequired,
  closeFormAction: PropTypes.func.isRequired,
  initialiseSearchAction: PropTypes.func.isRequired,
  currentTab: PropTypes.string
};

export default connect(
  state => ({
    // Connect values
    userRole: state.identity.role,
    orgId: state.classroomPage.orgId,
    classroomId: state.classroomPage.classroomId,
    classroomName: state.classroomEdit.classroomNameValue,
    initialAmountOfTeachers: state.classrooms.data[state.classroomPage.classroomId].amountOfTeachers,
    numberOfRemainingTeachers: state.classrooms.data[state.classroomPage.classroomId].teacherIdList.length,
    teachersAdded: state.classroomEdit.teachersAdded,
    studentsAdded: state.classroomEdit.studentsAdded,
    teachersRemoved: state.classroomEdit.teachersRemoved,
    studentsRemoved: state.classroomEdit.studentsRemoved,
    requestFailed: state.classroomEdit.requestFailed,
    failedIds: state.classroomEdit.failedIds,
    editJoiningCodeData: state.classroomEdit.editJoiningCodeData
  }),
  (dispatch, props) => ({
    // Connect actions
    closeFormAction: () => dispatch(closeForm()),
    initialiseSearchAction: () => {
      if (props.currentTab === 'students') {
        dispatch(triggerSearch('classStudents'));
      } else if (props.currentTab === 'teachers') {
        dispatch(triggerSearch('classTeachers'));
      }
    }
  })
)(EditClassroomConfirmation);
