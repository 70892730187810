/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
// Redux
import { setSendEmailPreference } from '../../redux/actions/onlineTestActions';
// Services
import withLocalizedContent from '../../language/withLocalizedContent';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
// Syles
import styles from './OnlineTestCommPreference.scss';

class OnlineTestCommPreference extends Component {
  _renderBody = () => {
    const {
      localizedContent: { hubSetTestOnline: content },
      sendEmailToStudents,
      toggleSendEmailPreference
    } = this.props;
    return (
      <div className={styles.mainContent}>
        {/* Email notification toggle */}
        <div className={`${styles.sectionContainer} ${styles.preferencesRaw}`}>
          <span>{content.email_notification_text}</span>
          <div className={styles.toggleContainer}>
            <ToggleSwitch
              id="emailNotificationToggle"
              value={sendEmailToStudents}
              label={sendEmailToStudents ? content.yes : content.no}
              onChange={() => toggleSendEmailPreference({ sendEmailToStudents: !sendEmailToStudents })}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      test,
      assessmentTitle,
      next,
      prev,
      localizedContent: { hubSetTestOnline: content },
      onlyBody = false
    } = this.props;

    if (onlyBody) {
      return this._renderBody();
    }
    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_title}</h2>
              <div>{test ? `${assessmentTitle} ${getTestTitle(test)}` : ''}</div>
            </div>
          </header>
        }
        footerContent={
          <PopoutNavFooter
            backAction={data => prev(data)}
            nextAction={data => next(data)}
            nextButtonText={content.online_test_next}
          />
        }
      >
        {this._renderBody()}
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({ onlineTest: { sendEmailToStudents = false } = {} }) => ({
      sendEmailToStudents
    }),
    { toggleSendEmailPreference: setSendEmailPreference }
  )
)(OnlineTestCommPreference);

OnlineTestCommPreference.defaultProps = {
  assessmentTitle: ''
};

OnlineTestCommPreference.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  sendEmailToStudents: PropTypes.bool.isRequired,
  toggleSendEmailPreference: PropTypes.func.isRequired,
  onlyBody: PropTypes.bool
};
