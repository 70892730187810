import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../utils/withRouter';
import { orgRoles } from '../globals/orgRoles';

const removeLastPart = url => {
  // helper to return a URL without the last directory segment
  const urlParts = url.split('/');
  urlParts.pop();
  return urlParts.join('/');
};

const withRole = connect((state, ownProps) => ({
  identityRole: state.identity.role,
  ...ownProps,
  orgRole: state.organisations.data[state.identity.currentOrganisationId]?.role
}));

const hasDynamicParams = url => url.indexOf('/:') !== -1;

const replaceDynamicParams = (url, params) => {
  let finalUrl = url;

  Object.entries(params).forEach(([key, param]) => {
    if (finalUrl.indexOf(`:${key}`) !== -1) {
      finalUrl = finalUrl.replace(`:${key}`, param);
    }
  });

  return finalUrl;
};

export default function protectedRoute(
  Component,
  isWhiteList,
  roleList,
  redirectUrl,
  keepQueryStringParams = false,
  notifyUserIfHasSession = false,
  context = ''
) {
  let popupUserNeedToLogout = false;

  function ProtectedComponent(props) {
    const { identityRole, match, history } = props;

    useEffect(() => {
      const roleInList = roleList.includes(identityRole);
      let finalRedirectUrl = redirectUrl;

      if (match.params && hasDynamicParams(finalRedirectUrl)) {
        finalRedirectUrl = replaceDynamicParams(finalRedirectUrl, match.params);
      }

      // List can be blacklist or whitelist
      const allowed = identityRole !== undefined && (isWhiteList ? roleInList : !roleInList);

      console.log(
        `ROUTER. Attempting to access ${window.location.pathname} as role ${identityRole}. Allowed: ${allowed}`
      );

      if (!allowed) {
        if (notifyUserIfHasSession) {
          popupUserNeedToLogout = true;
        } else {
          console.log(`ROUTER. Redirecting to ${finalRedirectUrl || removeLastPart(window.location.pathname)}`);

          if (keepQueryStringParams) {
            finalRedirectUrl += window.location.search;
          }

          history.replace(finalRedirectUrl || removeLastPart(window.location.pathname));
        }
      }
    }, []);

    // Render only occurs if componentWillMount passed without redirect
    return <Component {...props} notifyUserIfHasSession={popupUserNeedToLogout} context={context} />;
  }

  ProtectedComponent.propTypes = {
    identityRole: PropTypes.string,
    orgRole: PropTypes.oneOf(Object.keys(orgRoles)),
    params: PropTypes.array,
    history: PropTypes.any,
    match: PropTypes.any
  };
  return React.createElement(withRouter(withRole(ProtectedComponent)));
}
