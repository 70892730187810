import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from '../../utils/compose/compose.js';
import ContentPlayerMenu from '../ContentPlayerMenu/ContentPlayerMenu';
import whiteSplashScreen from '../../../static/images/cpt/whiteSplashScreen.jpg';
import { MENU_ORIENTATION, MODAL_CLASSES, XAPI_ENDPOINT } from '../../globals/cptConstants';
import PopoutPanel from '../PopoutPanel/PopoutPanel';
import { closeMobileMenuModal, progressBarNavigationCheck } from '../../redux/actions/structuredContentPlayer';
import useMediaQuery from '../../utils/mediaQuery';
import styles from './ContentPlayerBody.scss';
import { addXapiParams } from '../../globals/urls';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import staticDesktopSplashScreen from '../../../static/images/placeholders/SplashScreenDesktop.jpg';
import staticMobileSplashScreen from '../../../static/images/placeholders/SplashScreenMobile.png';
import SolidColorBackground from '../SolidColorBackground/SolidColorBackground.js';
import { EPS_ASSETS_BASE_ELT_MISC, PROFILE_MAPPING } from '../../../sharedNodeBrowser/constants';
import { isLocal } from '../../globals/envSettings.js';
import { isOcpMode } from '../../utils/platform.js';
import ContentPlayerSwitchBook from '../ContentPlayerSwitchBook/ContentPlayerSwitchBook.js';

function ContentPlayerBody({
  isPresentation = true,
  mobileMenuOpen,
  closeMobileMenuModalAction,
  cptContent,
  menuOrientation,
  xApiLaunchToken,
  splashScreen,
  navigation,
  progressBarNavigation,
  progressBarNavigationCheckAction,
  parentRef,
  setMenuRendered,
  title,
  isSwitchBookOpen
}) {
  const { currentActivities, currentActivity } = navigation;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const landscapeSplashScreen = splashScreen?.landscape;
  const portraitSplashScreen = splashScreen?.portrait;
  const url = window.location.href;
  const [isDemoContent, setIsDemoContent] = useState(false);
  const iframeRef = useRef(null);
  const skipButtonRef = useRef(null);

  const currentActivityObj = currentActivity
    ? currentActivities.find(activity => activity.id === currentActivity)
    : null;

  useEffect(() => {
    setIsDemoContent(url.indexOf('demo-01') !== -1 || url.indexOf('demo-02') !== -1);
  }, [url]);

  useEffect(() => {
    if (currentActivityObj && currentActivityObj.url && !isOcpMode()) {
      const CONTENT_PATH = isLocal()
        ? `http://${window.location.hostname}:${window.location.port}`
        : `https://${window.location.hostname}`;

      progressBarNavigationCheckAction(
        CONTENT_PATH + currentActivityObj.url.replace('index.html', 'LearningObjectInfo.xml')
      );
    }
  }, [currentActivityObj]);

  const renderSplashScreenImage = () => {
    if (isDemoContent) {
      const staticMobileOrLandscapeImagePath = isMobile ? staticMobileSplashScreen : staticDesktopSplashScreen;

      return (
        <SolidColorBackground
          imageSrc={staticMobileOrLandscapeImagePath}
          imageAlt={`Hardcoded Splash Screen for ${title}`}
        />
      );
    }
    if (!splashScreen) {
      return <img src={`${whiteSplashScreen}`} alt="White Splash Screen" />;
    }

    const localPath = isLocal() ? `http://${window.location.hostname}:${window.location.port}` : '';
    const mobileOrLandscapeImagePath = isMobile
      ? `${localPath}${EPS_ASSETS_BASE_ELT_MISC}/${portraitSplashScreen}`
      : `${localPath}${EPS_ASSETS_BASE_ELT_MISC}/${landscapeSplashScreen}`;

    return <SolidColorBackground imageSrc={mobileOrLandscapeImagePath} imageAlt={`Splash Screen for ${title}`} />;
  };

  useEffect(() => {
    const handleTabPress = event => {
      if (event.keyCode === 9) {
        skipButtonRef.current.focus();
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleTabPress);

    return () => {
      document.removeEventListener('keydown', handleTabPress);
    };
  }, [isPresentation, xApiLaunchToken]);

  const handleSkipNavigation = event => {
    event.preventDefault();
    const iframe = iframeRef?.current;
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const targetFirstButton = iframeDocument.getElementsByTagName('button')[0];
      if (targetFirstButton) {
        targetFirstButton.scrollIntoView();
        targetFirstButton.focus();
      }
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleSkipNavigation);
      return () => {
        iframe.removeEventListener('load', handleSkipNavigation);
      };
    }
    return null;
  }, [currentActivityObj?.url]);

  let iframeUrl = '';
  if (currentActivityObj) {
    if (isOcpMode()) {
      const CONTENT_PATH = isLocal()
        ? `http://${window.location.hostname}:${window.location.port}`
        : `https://${window.location.hostname}`;
      iframeUrl = `${CONTENT_PATH + currentActivityObj.url}?a5_store=false&a5_restore=false`;
    } else if (xApiLaunchToken) {
      iframeUrl = addXapiParams(currentActivityObj.url, {
        token: xApiLaunchToken,
        endpoint: `${__API_BASE__}${XAPI_ENDPOINT}`,
        profile: window.btoa(PROFILE_MAPPING[currentActivityObj.type] || PROFILE_MAPPING.presentation)
      });
    }
  }

  return isOcpMode() || isPresentation || xApiLaunchToken ? (
    <div
      className={`${styles.cpBodyContainer} ${
        menuOrientation === MENU_ORIENTATION.LEFT ? styles.cpLeft : styles.cpRight
      }`}
    >
      <a
        className="a11y-hide-focusable"
        ref={skipButtonRef}
        tabIndex="0"
        href="#"
        onClick={e => handleSkipNavigation(e)}
      >
        Skip to main content
      </a>
      {!isMobile && (
        <div className={styles.cpBodyContainerMenu}>
          <ContentPlayerMenu cptContent={cptContent} parentRef={parentRef} onRender={() => setMenuRendered(true)} />
        </div>
      )}
      <div
        className={classNames(
          isMobile ? styles.cpBodyContainerWrapperMobile : styles.cpBodyContainerWrapper,
          styles[`progressBarNavigation${progressBarNavigation}`]
        )}
      >
        {isPresentation ? (
          <>
            <div
              className={
                menuOrientation === MENU_ORIENTATION.LEFT
                  ? styles.leftSwitchBookContainer
                  : styles.rightSwitchBookContainer
              }
            >
              {!isMobile && <ContentPlayerSwitchBook isSwitchBookOpen={isSwitchBookOpen} cptContent={cptContent} />}
            </div>
            {renderSplashScreenImage()}
          </>
        ) : (
          <>
            <div
              className={
                menuOrientation === MENU_ORIENTATION.LEFT
                  ? styles.leftSwitchBookContainer
                  : styles.rightSwitchBookContainer
              }
            >
              {!isMobile && <ContentPlayerSwitchBook isSwitchBookOpen={isSwitchBookOpen} cptContent={cptContent} />}
            </div>
            <iframe
              key={currentActivityObj.id}
              className={styles.cpBodyContainerIframe}
              src={iframeUrl}
              title="description"
              allowfullscreen="true"
              allow="microphone"
              ref={iframeRef}
              onLoad={handleSkipNavigation}
            />
          </>
        )}
      </div>
      {mobileMenuOpen && (
        <PopoutPanel
          id="mobileMenu"
          ariaLabel={`${cptContent.menu} ${mobileMenuOpen ? cptContent.open : cptContent.closed}`}
          isOpen={mobileMenuOpen}
          panelSize="fullWidth"
          className={MODAL_CLASSES.CP_MODAL_MENU}
        >
          <ContentPlayerMenu
            onRender={() => setMenuRendered(true)}
            orientation={MENU_ORIENTATION.LEFT}
            closePanel={closeMobileMenuModalAction}
            cptContent={cptContent}
          />
        </PopoutPanel>
      )}
    </div>
  ) : (
    <LoadingSpinner />
  );
}

ContentPlayerBody.propTypes = {
  isPresentation: PropTypes.bool,
  mobileMenuOpen: PropTypes.bool.isRequired,
  closeMobileMenuModalAction: PropTypes.func.isRequired,
  cptContent: PropTypes.object,
  menuOrientation: PropTypes.oneOf([MENU_ORIENTATION.LEFT, MENU_ORIENTATION.RIGHT]),
  xApiLaunchToken: PropTypes.object,
  splashScreen: PropTypes.object,
  navigation: PropTypes.object,
  progressBarNavigation: PropTypes.string,
  progressBarNavigationCheckAction: PropTypes.func,
  parentRef: PropTypes.object,
  setMenuRendered: PropTypes.func,
  title: PropTypes.string,
  isSwitchBookOpen: PropTypes.bool
};

const mapStateToProps = ({
  structuredContentPlayer: {
    mobileMenuOpen,
    menuOrientation,
    xApiLaunchToken,
    splashScreen,
    progressBarNavigation,
    isSwitchBookOpen
  }
}) => ({
  mobileMenuOpen,
  menuOrientation,
  xApiLaunchToken,
  splashScreen,
  progressBarNavigation,
  isSwitchBookOpen
});

const mapDispatchToProps = {
  closeMobileMenuModalAction: closeMobileMenuModal,
  progressBarNavigationCheckAction: progressBarNavigationCheck
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerBody);
