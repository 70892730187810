import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../../utils/compose/compose.js';
import Link from '../../../../components/Link/Link';

import ListPageControls from '../../../../components/ListPageControls/ListPageControls.js';
import { safePanelLink } from '../../../../utils/links/panelLinks.js';

import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../../../components/Button/Button.js';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons.js';
import Table, { columnTypes, rowTypes } from '../../../../components/Table/Table.js';
import Thumbnail, { SIZES as thumbnailSizes } from '../../../../components/Thumbnail/Thumbnail.js';
import Checkbox from '../../../../components/Checkbox/Checkbox.js';
import ConnectedClassRepresentation from '../../../../components/ClassRepresentation/ConnectedClassRepresentation.js';

import {
  triggerSearch,
  setTerm,
  setSort,
  setPage,
  setFilterClass,
  setPageClass,
  setSortClass,
  setFilter
} from '../../../../redux/reducers/data/search.reducer.js';
import { setClassroomSelected } from '../../../../redux/reducers/organisationPage.reducer.js';
import { roleCanCreateClass, canUserEditClass } from '../../../../globals/userRoles';
import TimeTag from '../../../../components/TimeTag/TimeTag.js';

import SearchStatus from '../../../../components/SearchStatus/SearchStatus.js';

import cmsContent from '../../../../utils/cmsContent.js';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import { featureIsEnabled } from '../../../../globals/envSettings';

function OrgClassSearch({
  orgId,
  selectedIds,
  disabledIds,
  classrooms,
  userRole,
  searchTerm,
  sort,
  page,
  filters,
  resultIds,
  totalResults,
  triggerSearchAction,
  setSearchTerm,
  setSortAction,
  setPageAction,
  setFilterAction,
  orgClassesDataRecency,
  setSelectionAction,
  setArchiveAction,
  paginatedClass,
  orgIsArchived,
  loadingResults,
  selectableIds
}) {
  const CMS = cmsContent.mySchoolOrganisationSearchTab || {};
  const row = featureIsEnabled('client-side-pagination') ? Object.keys(paginatedClass) : resultIds;
  const _getSelectedAll = () =>
    // if no items then select all will always be unchecked
    selectableIds.length ? selectableIds.every(id => selectedIds.includes(id)) : false;

  const _handleSelectAll = () => {
    const selectedAll = _getSelectedAll();
    selectableIds.forEach(id => {
      const isSelected = !(selectedIds || []).includes(id);
      // deselect all items if selectedAll is true or select item if not yet selected
      if (selectedAll || !selectedIds.includes(id)) {
        setSelectionAction(id, isSelected);
      }
    });
  };

  const columnsHeader = [
    {
      heading: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 16 }}>
            <Checkbox
              id="checkbox"
              label="Classes"
              labelHidden
              value={_getSelectedAll()}
              onChange={_handleSelectAll}
              disabled={!selectableIds.length}
            />
          </div>
          {CMS.table_heading_classes}
        </div>
      )
    },
    {
      heading: CMS.table_heading_status
    },
    {
      heading: CMS.table_heading_created
    },
    {
      heading: CMS.table_heading_archive,
      type: columnTypes.BUTTON
    }
  ];

  return (
    <div>
      <ListPageControls
        idPrefix="orgClassesSearch"
        searchInputLabel={CMS.search_classes_label}
        searchInputPlaceholder={CMS.search_classes_label}
        searchInputValue={searchTerm}
        searchInputOnChange={setSearchTerm}
        searchInputOnSubmit={triggerSearchAction}
        newButtonText={CMS.button_add_class}
        disabled={orgIsArchived}
        newButtonTo={roleCanCreateClass(userRole) ? safePanelLink('addClass') : null}
        filterOptions={[
          {
            text: CMS.filter_active_classes,
            id: 'searchFilter-by-active-classes',
            name: 'searchFilter-by-active-classes',
            value: 'active',
            checked: filters.active,
            onChange: setFilterAction
          },
          {
            text: CMS.filter_archived_classes,
            id: 'searchFilter-by-archived-classes',
            name: 'searchFilter-by-archived-classes',
            value: 'archived',
            checked: filters.archived,
            onChange: setFilterAction
          }
        ]}
        sortOnChange={setSortAction}
        sortOptions={[
          {
            text: CMS.sort_by_name_ASC,
            id: 'searchOrder-by-name-ASC',
            name: 'searchOrder',
            value: 'name:asc',
            checked: sort === 'name:asc'
          },
          {
            text: CMS.sort_by_name_DESC,
            id: 'searchOrder-by-name-DESC',
            name: 'searchOrder',
            value: 'name:desc',
            checked: sort === 'name:desc'
          },
          {
            text: CMS.sort_newest_first,
            id: 'searchOrder-by-createdDate-DESC',
            name: 'searchOrder',
            value: 'date:desc',
            checked: sort === 'date:desc'
          },
          {
            text: CMS.sort_oldest_first,
            id: 'searchOrder-by-createdDate-ASC',
            name: 'searchOrder',
            value: 'date:asc',
            checked: sort === 'date:asc'
          }
        ]}
        ariaControls="searchResults"
        loading={orgClassesDataRecency.syncing}
        loadingMessage={CMS.loading_message}
        loaded={orgClassesDataRecency.synced}
        loadedMessage={CMS.loaded}
        showSkeletonLoader={loadingResults}
      />

      <SearchStatus
        searchSource="orgClasses"
        noResultsFoundContent={
          <div className="grid">
            <div className="row">
              <div
                id="searchResultsNone"
                role="region"
                aria-live="polite"
                aria-atomic="true"
                className="col"
                style={{ flex: '0 0 auto', alignItems: 'baseline' }}
              >
                <p id="searchResults-classes-noResults" className="gin-top1 gin-bot1">
                  {CMS.no_search_results}
                </p>
                {roleCanCreateClass(userRole) && !orgIsArchived && (
                  <Link to={safePanelLink('addClass')}>{CMS.add_class_text}</Link>
                )}
              </div>
            </div>
          </div>
        }
      />

      {loadingResults ? null : (
        <div className="grid">
          <div className="row">
            <div
              id="searchResults"
              className="col"
              role="region"
              aria-live="polite"
              aria-atomic="true"
              aria-label="Classrooms list"
            >
              {row.length > 0 && (
                <div className="gin-top2">
                  <Table
                    selectAll
                    columns={columnsHeader}
                    rows={row.map(id => {
                      const classroom = classrooms[id] || {};
                      const isProcessing = orgClassesDataRecency.syncing && orgClassesDataRecency.ids.includes(id);
                      const { icon: archiveStatusIcon, text: archiveStatusText } = isProcessing
                        ? {
                            icon: GLYPHS.ICON_LOADING,
                            text: 'Processing'
                          }
                        : {
                            icon: classroom.archived ? GLYPHS.ICON_REMOVED_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE,
                            text: `${classroom.archived ? CMS.archived_text : CMS.active_text}${' '}${CMS.class_text}`
                          };

                      const cells = [
                        <div key={0}>
                          <Checkbox
                            id={`searchResults-checkbox-select-${id}`}
                            label={classroom.name || ''}
                            labelHidden
                            value={(selectedIds || []).includes(id)}
                            onChange={() => {
                              const isSelected = !(selectedIds || []).includes(id);
                              setSelectionAction(id, isSelected);
                            }}
                            disabled={disabledIds.includes(id)}
                          />

                          <ConnectedClassRepresentation
                            id={`searchResults-item-${id}`}
                            entityId={id}
                            linkIdPrefix="linkToClassroom"
                            linkTo={`/org/${orgId}/class/${id}`}
                            thumbnailSize={thumbnailSizes.TABLE}
                            deletedStyle={classroom.archived}
                            userRole={userRole}
                            orgId={orgId}
                          />
                        </div>,

                        <div
                          key={1}
                          id={`searchResults-archive-status-${id}`}
                          title={archiveStatusText}
                          data-tooltip={archiveStatusText}
                        >
                          <div className="a11y-hide">{archiveStatusText}</div>
                          <Thumbnail size={thumbnailSizes.MEDIUM} glyph={archiveStatusIcon} />
                        </div>,

                        <div
                          key={2}
                          id={`searchResults-date-${id}`}
                          data-tooltip={classroom.createdDate ? CMS.table_heading_created : ''}
                        >
                          <TimeTag dateString={classroom.createdDate} />
                        </div>,

                        <Button
                          id={`searchResults-button-archive-${id}`}
                          key={6}
                          text={`${CMS.archive_text}${' '}${classroom.name}`}
                          title={`${CMS.archive_text}${' '}${classroom.name}`}
                          iconOnly
                          onClick={() => setArchiveAction(id, orgId)}
                          type={buttonTypes.NO_BORDER}
                          glyph={GLYPHS.ICON_REMOVE}
                          disabled={disabledIds.includes(id)}
                          preventDefault={false}
                          to={safePanelLink('archiveClass')}
                        />
                      ];

                      return {
                        id: `class${id}`,
                        cells,
                        ...(isProcessing ? { type: rowTypes.PROCESSING } : {})
                      };
                    })}
                  />
                </div>
              )}

              {totalResults > 10 && (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="orgClassesSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={setPageAction}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

OrgClassSearch.propTypes = {
  orgId: PropTypes.any,
  selectedIds: PropTypes.array.isRequired,
  disabledIds: PropTypes.array.isRequired,

  classrooms: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,

  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,

  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  orgClassesDataRecency: PropTypes.object.isRequired,
  setSelectionAction: PropTypes.func.isRequired,
  setArchiveAction: PropTypes.func.isRequired,
  paginatedClass: PropTypes.array.isRequired,
  orgIsArchived: PropTypes.bool.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  selectableIds: PropTypes.array.isRequired
};

export default compose(
  withDataRecency('orgClasses'),
  connect(
    (state, { orgId }) => ({
      selectedIds: state.organisationPage.selectedClassroomIds,
      userRole: state.identity.role,
      classrooms: state.classrooms.data,
      disabledIds: state.search.orgClasses.ids.filter(
        id => !canUserEditClass(state.identity.userId, state.identity.role, state.classrooms.data[id])
      ),

      classroomSearchTerm: state.organisationPage.classroomSearchTerm,
      selectedClassroomIds: state.organisationPage.selectedClassroomIds,

      searchTerm: state.search.orgClasses.term,
      sort: state.search.orgClasses.sort,
      page: state.search.orgClasses.page,
      paginatedClass: state.search.orgClasses.paginatedClassList,
      filters: state.search.orgClasses.filters,
      resultIds: state.search.orgClasses.ids,
      totalResults: state.search.orgClasses.totalResults,
      orgIsArchived: state.organisations.data[orgId].archived,
      loadingResults: state.search.orgClasses ? state.search.orgClasses.loading : false
    }),
    {
      setSelectionAction: setClassroomSelected,
      setSearchTerm: term => setTerm('orgClasses', term),
      setSortAction: sort =>
        featureIsEnabled('client-side-pagination')
          ? setSortClass('orgClasses', sort[0])
          : setSort('orgClasses', sort[0]),
      setPageAction: page =>
        featureIsEnabled('client-side-pagination') ? setPageClass('orgClasses', page) : setPage('orgClasses', page),
      setFilterAction: (valueName, value) =>
        featureIsEnabled('client-side-pagination')
          ? setFilterClass('orgClasses', valueName, value)
          : setFilter('orgClasses', valueName, value),
      triggerSearchAction: () => triggerSearch('orgClasses')
    }
  )
)(OrgClassSearch);
