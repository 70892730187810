import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import withRouter from '../../utils/withRouter';
import compose from '../../utils/compose/compose.js';
import { HubLayoutConstants } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';
import { formStates } from '../../redux/reducers/declineOrgInvitation.reducer';
import actions from '../../redux/actions';
import { userRoleFriendlyNames } from '../../globals/userRoles';
import DeclineOrgInvitationReview from './DeclineOrgInvitationReview';
import DeclineOrgInvitationConfirmation from './DeclineOrgInvitationConfirmation';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

class DeclineOrgInvitation extends React.Component {
  alreadyLoaded = false;

  componentDidMount() {
    this._fetchData();
  }

  componentDidUpdate() {
    this._fetchData();
  }

  _fetchData() {
    const { appReady, initializeAction, match } = this.props;
    if (appReady && !this.alreadyLoaded) {
      initializeAction(match.params.token);
      this.alreadyLoaded = true;
    }
  }

  _getRenderComponent() {
    const {
      formState,
      localizedContent,
      errorLoading,
      tokenStatus,
      currentInvitationStatus,
      invitationArchiveStatus,
      responseDate,
      platform,
      orgName,
      offeredRole,
      onContinueDecline,
      onDismiss
    } = this.props;
    switch (formState) {
      case formStates.LOADING:
        return <LoadingSpinner />;
      case formStates.SUBMITTING:
        return <LoadingSpinner text={localizedContent.invitationBanner.decline_invite_loading_title} />;
      case formStates.CONFIRMATION:
        return (
          <DeclineOrgInvitationConfirmation
            requestFailed={errorLoading}
            tokenStatus={tokenStatus}
            currentInvitationStatus={currentInvitationStatus}
            invitationArchiveStatus={invitationArchiveStatus}
            responseDate={responseDate}
            platformName={platform}
            orgName={orgName}
            offeredRole={offeredRole}
            offeredRoleName={localizedContent.sharedTerms[offeredRole]}
          />
        );
      case formStates.REVIEWING:
        return (
          <DeclineOrgInvitationReview
            orgName={orgName}
            offeredRole={userRoleFriendlyNames(offeredRole, true)}
            onContinueDecline={onContinueDecline}
            onDismissPage={onDismiss}
          />
        );
      default:
        return <DeclineOrgInvitationConfirmation requestFailed={false} />;
    }
  }

  render() {
    return (
      <div>
        <Helmet title="Decline Invitation" />
        <div className="grid">{this._getRenderComponent()}</div>
      </div>
    );
  }
}

DeclineOrgInvitation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  appReady: PropTypes.bool.isRequired,
  formState: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  offeredRole: PropTypes.string.isRequired,
  tokenStatus: PropTypes.string.isRequired,
  currentInvitationStatus: PropTypes.string.isRequired,
  invitationArchiveStatus: PropTypes.string.isRequired,
  responseDate: PropTypes.string.isRequired,
  errorLoading: PropTypes.bool.isRequired,
  initializeAction: PropTypes.func.isRequired,
  onContinueDecline: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  match: PropTypes.object
};

export default compose(
  withLocalizedContent('invitationBanner', 'sharedTerms'),
  connect(
    state => ({
      appReady: state.app.appReady,
      formState: state.declineOrgInvitation.formState,
      platform: state.declineOrgInvitation.platform,
      orgName: state.declineOrgInvitation.orgName,
      offeredRole: state.declineOrgInvitation.offeredRole,
      tokenStatus: state.declineOrgInvitation.tokenStatus,
      currentInvitationStatus: state.declineOrgInvitation.currentInvitationStatus,
      invitationArchiveStatus: state.declineOrgInvitation.invitationArchiveStatus,
      responseDate: state.declineOrgInvitation.responseDate,
      errorLoading: state.declineOrgInvitation.errorLoading
    }),
    dispatch => ({
      initializeAction: token => dispatch(actions.initDeclineOrgInvitePage(token)),
      onContinueDecline: () => dispatch(actions.updateDeclineOrgInviteSubmit()),
      onDismiss: () => {
        window.location.href = HubLayoutConstants.PATH_NAMES.HOME_PAGE;
      }
    })
  )
)(withRouter(DeclineOrgInvitation));
