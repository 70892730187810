import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Tooltip.scss';
import randomString from '../../utils/randomString';

/**
 * A caption which is displayed over an element on mouseover or keyboard focus.
 */
function Tooltip({ title, children, className, ...props }) {
  const id = randomString(8);
  return (
    <button
      aria-labelledby={id}
      aria-controls={id}
      className={classnames(styles.button, className)}
      type="button"
      {...props}
    >
      {children}
      {title ? (
        // eslint-disable-next-line jsx-a11y/role-supports-aria-props
        <span id={id} aria-haspopup="true" className={styles.caption} role="tooltip">
          {title}
        </span>
      ) : null}
    </button>
  );
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Tooltip;
