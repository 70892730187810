import { isEmpty, mapValues, pick, pickBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';

import withRouter from '../../../utils/withRouter';
import compose from '../../../utils/compose/compose.js';
import Button, { buttonTypes } from '../../../components/Button/Button';
import Badge from '../../../components/Badge/Badge';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import ListPageControls from '../../../components/ListPageControls/ListPageControls';
import PopoutPanel from '../../../components/PopoutPanel/PopoutPanel';
import ProductListing from '../../../components/ProductListing/ProductListing';
import SearchStatus from '../../../components/SearchStatus/SearchStatus';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { searchProductSortOptions } from '../../../globals/searchFilters';
import userRoles, { canUserEditClass, canAssignToUser, roleIsAtLeast } from '../../../globals/userRoles';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { bypassProductSelection } from '../../../redux/reducers/assignLearningMaterial.reducer';
import { setFilter, setPage, setSort, setTerm, triggerSearch } from '../../../redux/reducers/data/search.reducer';
import {
  resetState as resetRemoveLearningMaterialForm,
  resetStateForInstance as resetRemoveLearningMaterialFormForInstance,
  setContext,
  toggleRemoveMaterialModal
} from '../../../redux/reducers/removeLearningMaterial.reducer';
import { initialiseForm } from '../../../redux/reducers/user/licenceRecall.reducer';
import LicenceRecallPanel from '../../MyProfile/panels/licenceRecall/licenceRecallPanel';
import AssignLearningMaterial from '../../panels/AssignLearningMaterial/AssignLearningMaterial';
import ProductFinderPanel from '../../../components/ProductFinder/ProductFinderPanel';
import RemoveLearningMaterial from '../../panels/RemoveLearningMaterial/RemoveLearningMaterial';
import { safePanelLink, safePanelUnlink } from '../../../utils/links/panelLinks';
import getProductDates from '../../../utils/normalizations/getProductDates';
import { postGotoMessage } from '../../../redux/actions/postMessage';
import { embeddedByPlatform, isHubMode } from '../../../utils/platform';
import getAssignedMaterialsInCurrentOrg from '../../../redux/selectors/getAssignedMaterialsInCurrentOrg';
import withDataRecency from '../../../dataRecency/withDataRecency';
import { featureIsEnabled } from '../../../globals/envSettings';
import Modals from '../../../components/Modals/Modals';
import { sanitizeUrl } from '../../../utils/url';
import styles from './UserProfileContentSearch.scss';

class UserProfileContentSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleModal: false,
      modalTitle: '',
      modalCoverImage: null,
      iosAppLink: '',
      androidAppLink: ''
    };
  }

  componentDidMount() {
    const { resetRemoveLearningMaterialFormAction, setContextAction } = this.props;
    resetRemoveLearningMaterialFormAction();
    setContextAction('USER');
  }

  handleAssignMaterialClose = () => {
    const { history, resetRemoveLearningMaterialFormForInstanceAction } = this.props;
    history.push(safePanelUnlink('assignLicence'));
    resetRemoveLearningMaterialFormForInstanceAction();
  };

  handleRecallMaterialClose = () => {
    const { history, resetRemoveLearningMaterialFormForInstanceAction } = this.props;
    history.push(safePanelUnlink('recallLicence'));
    resetRemoveLearningMaterialFormForInstanceAction();
  };

  renderLicenceAction = (productId, { licenceDetails, orgLicenceDetails, licenceNotStartedDetails }) => {
    const {
      orgId,
      userId,
      initialiseAssignLicence,
      initialiseRecall,
      history,
      localizedContent: { userProfileContentSearchTab: content },
      assignedMaterialsInCurrentOrg,
      identity,
      classrooms,
      results
    } = this.props;

    const isOrgLicenceAvailable = orgLicenceDetails && orgLicenceDetails.available > 0;

    const assignmentsInThisOrg = assignedMaterialsInCurrentOrg[productId] || [];

    const noAssignments = assignmentsInThisOrg === undefined || assignmentsInThisOrg.length === 0;

    const assignedLicencesInthisOrg = results[productId]?.learningAssignments || [];

    // If we need to pick an existing assignment to add a licence to, first try to use
    // an individual assignment, else just use the first group one
    const individualAssignment = assignmentsInThisOrg.find(({ groupDetails: { type } }) => type === 'ASSIGNMENT_GROUP');
    const firstAssignment = assignmentsInThisOrg[0] || {};
    const assignment = individualAssignment || firstAssignment;

    const isUnreddemedProductAndTeacherSameClass = () => {
      if (identity.role === userRoles.TEACHER) {
        const groupId = assignmentsInThisOrg.length
          ? assignmentsInThisOrg.map(assign => assign.groupDetails.groupId)
          : null;
        const classroom = classrooms.data[groupId];
        return canUserEditClass(identity.userId, identity.role, classroom);
      }
      return true;
    };

    const isAssignedLicenceAndTeacherOfSameClass = () => {
      if (identity.role === userRoles.TEACHER) {
        const groupIds = assignedLicencesInthisOrg.length
          ? assignedLicencesInthisOrg.map(assign => assign.groupDetails.groupId)
          : null;
        const AssignedClassRooms = groupIds && groupIds.length ? groupIds.map(id => classrooms.data[id]) : [];
        return AssignedClassRooms.some(classroom => canUserEditClass(identity.userId, identity.role, classroom));
      }
      return true;
    };
    const customLicenceDetails = licenceDetails || licenceNotStartedDetails;
    const licenceHasExpired = customLicenceDetails && moment(customLicenceDetails.endDate).isBefore();
    const operationAllowedForUser = canAssignToUser(identity.role, identity.userId, userId);
    // If the user has an unredeemed not-expired licence from this org and checking role based recall permission
    if (
      !licenceHasExpired &&
      assignmentsInThisOrg.length &&
      isUnreddemedProductAndTeacherSameClass() &&
      operationAllowedForUser
    ) {
      return (
        <Button
          type={buttonTypes.GHOST_INVERTED}
          glyph={GLYPHS.ICON_RESTORE}
          text={content.button_recall_licence_text}
          onClick={() => {
            initialiseRecall(orgId, userId, customLicenceDetails.licenceId, assignment.activationCode);
            history.push(safePanelLink('recallLicence'));
          }}
        />
      );
    }
    const showAssignLicenceButton =
      isAssignedLicenceAndTeacherOfSameClass() && operationAllowedForUser && noAssignments;

    // If the user has an assignment from this org but no licence or an expired licence
    if ((showAssignLicenceButton && !assignment.activationCode && !licenceDetails) || licenceHasExpired) {
      return (
        <Button
          disabled={!isOrgLicenceAvailable}
          type={buttonTypes.GHOST_INVERTED}
          glyph={GLYPHS.ICON_PLUS}
          text={content.button_assign_licence_text}
          onClick={() => {
            initialiseAssignLicence('USER_PROFILE', orgId, [userId], productId, assignment.learningAssignmentId);
            history.push(safePanelLink('assignLicence'));
          }}
        />
      );
    }

    return null;
  };

  toggleModal = (toggleVarName, coverimage, title, iosAppLink, androidAppLink) => {
    const { [toggleVarName]: toggleVarValue } = this.state;

    if (toggleVarValue) {
      this.setState({
        [toggleVarName]: false
      });
    } else {
      this.setState({
        modalTitle: title,
        androidAppLink,
        iosAppLink,
        modalCoverImage: coverimage,
        [toggleVarName]: true
      });
    }
  };

  getProductFinderContextName = () => {
    const {
      selectedUsers,
      people,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedUsers.length === 1
      ? `${people[selectedUsers[0]].firstname || ''} ${people[selectedUsers[0]].lastname || ''}`
      : `${selectedUsers.length} ${productFinderContent.users_text}`;
  };

  render() {
    const {
      orgId,
      panelName,
      searchTerm,
      filters,
      sort,
      results,
      resultCount,
      loading,
      setSearchTerm,
      searchAction,
      setSortAction,
      setFilterAction,
      history,
      onProductUrlClick,
      isViewingOwnProfile,
      isEmbeddedApp,
      toggleRemoveMaterialModalAction,
      resetRemoveLearningMaterialFormForInstanceAction,
      showRemoveMaterialModal,
      localizedContent: {
        userProfileContentSearchTab: content,
        removeLearningMaterialModal: removeMaterialModalContent
      },
      assignedMaterialsInCurrentOrg,
      classAssignmentsDataRecency,
      selectedUsers,
      userRole
    } = this.props;

    const learningAssignments = pickBy(
      results,
      product =>
        !product.isSelfAssignedProduct &&
        isEmpty(product.subscriptions) &&
        (!product.existingLicenceDetails || product.existingLicenceDetails.orgId === orgId)
    );

    const subscriptions = mapValues(
      pickBy(results, product => !isEmpty(product.subscriptions)),
      product => ({
        ...product,
        ...getProductDates(product),
        names: [...new Set(product.subscriptions.map(subscription => subscription.groupDetails.name))]
      })
    );
    const { modalTitle, modalCoverImage, iosAppLink, androidAppLink, toggleModal } = this.state;
    return (
      <div>
        <ListPageControls
          idPrefix="product"
          searchInputLabel={content.search_organisation_label}
          searchInputPlaceholder={content.search_organisation_placeholder}
          searchInputValue={searchTerm}
          searchInputOnChange={setSearchTerm}
          searchInputOnSubmit={searchAction}
          filterOptions={[
            {
              text: content.filter_not_started,
              id: 'userProductSearch-filterNotStarted',
              name: 'userProductSearch-filterNotStarted',
              value: 'notStarted',
              checked: filters.notStarted,
              onChange: setFilterAction
            },
            {
              text: content.filter_active,
              id: 'userProductSearch-filterActive',
              name: 'userProductSearch-filterActive',
              value: 'active',
              checked: filters.active,
              onChange: setFilterAction
            },
            {
              text: content.filter_expiring,
              id: 'userProductSearch-filterExpiring',
              name: 'userProductSearch-filterExpiring',
              value: 'expiring',
              checked: filters.expiring,
              onChange: setFilterAction
            },
            {
              text: content.filter_expired,
              id: 'userProductSearch-filterExpired',
              name: 'userProductSearch-filterExpired',
              value: 'expired',
              checked: filters.expired,
              onChange: setFilterAction
            },
            {
              text: content.filter_no_licence,
              id: 'userProductSearch-filterNoLicence',
              name: 'userProductSearch-filterNoLicence',
              value: 'noLicence',
              checked: filters.noLicence,
              onChange: setFilterAction
            }
          ]}
          sortOnChange={setSortAction}
          sortOptions={searchProductSortOptions('libraryProductSearch', sort)}
          ariaControls="searchResults"
          loading={classAssignmentsDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={classAssignmentsDataRecency.synced}
          showSkeletonLoader={loading}
        />

        <SearchStatus
          searchSource="userAssignments"
          noResultsFoundContent={
            <div className="grid">
              <div className="row">
                <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                  <p className="gin-top1 gin-bot1">{content.no_search_results_text}</p>
                </div>
              </div>
            </div>
          }
        />

        {!loading && resultCount > 0 ? (
          <div id="searchResults" className="grid">
            {Object.keys(subscriptions).length ? (
              <div className="row">
                <div className="col gin-top2">
                  <ProductListing
                    items={subscriptions}
                    productTypeHeading={content.subscriptions_heading}
                    enableExternalLinks={isViewingOwnProfile}
                    onProductUrlClick={isViewingOwnProfile && isEmbeddedApp ? onProductUrlClick : null}
                  />
                </div>
              </div>
            ) : null}

            {Object.keys(learningAssignments).length ? (
              <div className="row">
                <div className="col gin-top2">
                  <ProductListing
                    items={learningAssignments}
                    assignedMaterialsInCurrentOrg={assignedMaterialsInCurrentOrg}
                    productTypeHeading={content.learning_assignments_heading}
                    renderAction={this.renderLicenceAction}
                    showLicenceDetailContent
                    enableExternalLinks={isViewingOwnProfile}
                    onProductUrlClick={isViewingOwnProfile && isEmbeddedApp ? onProductUrlClick : null}
                    type={featureIsEnabled('oald-redeem-code') ? APP_CONSTANTS.COLLECTION_LIST : null}
                    toggleModal={this.toggleModal}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <PopoutPanel
          id="licenceRecall"
          ariaLabel={content.aria_label_recall_licence}
          isOpen={panelName === 'recallLicence'}
        >
          <LicenceRecallPanel closePanel={this.handleRecallMaterialClose} />
        </PopoutPanel>

        <PopoutPanel
          id="assignLicence"
          ariaLabel={content.aria_label_asign_licence}
          isOpen={panelName === 'assignLicence'}
        >
          {isHubMode() ? (
            <ProductFinderPanel
              orgId={orgId}
              selectedUsers={
                roleIsAtLeast(userRoles.TEACHER, userRole)
                  ? { teacherIdList: [...selectedUsers] }
                  : { studentIdList: [...selectedUsers] }
              }
              contextName={this.getProductFinderContextName()}
              onClose={this.handleAssignMaterialClose}
              onComplete={this.handleAssignMaterialClose}
              // needed for polling. will be removed when polling is removed
              context="USER_PROFILE"
            />
          ) : (
            <AssignLearningMaterial
              context="USER_PROFILE"
              orgId={orgId}
              closePopoutAction={this.handleAssignMaterialClose}
              onComplete={this.handleAssignMaterialClose}
            />
          )}
        </PopoutPanel>

        <PopoutPanel
          id="removeLearningMaterialPopout"
          ariaLabel={content.aria_label_remove_material_from_organisation}
          isOpen={panelName === 'archiveAssignment'}
        >
          <RemoveLearningMaterial
            context="USER"
            onComplete={() => history.push(safePanelUnlink('archiveAssignment'))}
            closePanel={toggleRemoveMaterialModalAction}
          />
        </PopoutPanel>

        {showRemoveMaterialModal ? (
          <ConfirmationModal
            title={removeMaterialModalContent.title}
            body={removeMaterialModalContent.body}
            positiveClickText={removeMaterialModalContent.positiveClickText}
            negativeClickText={removeMaterialModalContent.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              toggleRemoveMaterialModalAction();
              // Once its closed then reset the removeLearningMaterial form
              setTimeout(resetRemoveLearningMaterialFormForInstanceAction, 300);
              history.push(safePanelUnlink('archiveAssignment'));
            }}
            negativeClick={() => toggleRemoveMaterialModalAction()}
          />
        ) : null}
        <Modals
          visibilityChecker={toggleModal}
          noCustomStyles="false"
          closeCallBack={() => {
            this.toggleModal('toggleModal');
          }}
          bottomBorderForClose={false}
          bodyHtml={
            <div className={styles.body}>
              <div className={styles.coverImage}>
                {modalCoverImage && <img src={sanitizeUrl(modalCoverImage)} alt={modalTitle} />}
              </div>
              {modalTitle && <h1 className={styles.title}>{modalTitle}</h1>}
              <div className={styles.listItems}>
                <ul>
                  <li className="gin-top1">
                    <Badge noCustomStyles="false" value="1" />
                    &nbsp;&nbsp;
                    <span
                      // Because content source controlled wholly by trusted source:
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: content.app_guideline1
                      }}
                    />
                  </li>
                  <li className="gin-top1">
                    <Badge noCustomStyles="false" value="2" />
                    &nbsp;&nbsp;
                    <span
                      // Because content source controlled wholly by trusted source:
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: content.app_guideline2
                      }}
                    />
                  </li>
                  <li className="gin-top1">
                    <Badge noCustomStyles="false" value="3" />
                    &nbsp;&nbsp;
                    <span
                      // Because content source controlled wholly by trusted source:
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: content.app_guideline3
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className={styles.images}>
                <a href={sanitizeUrl(androidAppLink)} target="blank">
                  <img src={content.google_play_badge_img_src} alt={content.google_play_badge_img_alt} />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href={sanitizeUrl(iosAppLink)} target="_blank" rel="noreferrer">
                  <img src={content.app_store_badge_img_src} alt={content.app_store_badge_img_alt} />
                </a>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withLocalizedContent('userProfileContentSearchTab', 'removeLearningMaterialModal'),
  withDataRecency('classAssignments'),
  connect(
    (state, { userId }) => ({
      user: state.people.data[userId],
      searchTerm: state.search.userAssignments.term,
      ...pick(state.search.userAssignments, ['filters', 'sort', 'page', 'loading']),
      results: state.search.userAssignments.data,
      resultCount: state.search.userAssignments.totalResults,
      assignedMaterialsInCurrentOrg: getAssignedMaterialsInCurrentOrg(state),
      showRemoveMaterialModal: state.removeLearningMaterial.showRemoveMaterialModal,
      isViewingOwnProfile: state.userProfile.userId === state.identity.userId,
      isEmbeddedApp: !!embeddedByPlatform(),
      identity: state.identity,
      classrooms: state.classrooms,
      selectedUsers: state.assignLearningMaterial.userIds,
      people: state.people.data
    }),
    {
      setSearchTerm: term => setTerm('userAssignments', term),
      setSortAction: sort => setSort('userAssignments', sort[0]),
      setPageAction: page => setPage('userAssignments', page),
      setFilterAction: (valueName, value) => setFilter('userAssignments', valueName, value),
      searchAction: triggerSearch.bind(null, 'userAssignments'),
      initialiseRecall: initialiseForm,
      initialiseAssignLicence: bypassProductSelection,
      toggleRemoveMaterialModalAction: toggleRemoveMaterialModal,
      resetRemoveLearningMaterialFormAction: resetRemoveLearningMaterialForm,
      resetRemoveLearningMaterialFormForInstanceAction: resetRemoveLearningMaterialFormForInstance,
      setContextAction: setContext,
      onProductUrlClick: url => postGotoMessage(url)
    }
  )
)(UserProfileContentSearch);

UserProfileContentSearch.propTypes = {
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }).isRequired,
  panelName: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  sort: PropTypes.string,
  results: PropTypes.object.isRequired,
  resultCount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  isViewingOwnProfile: PropTypes.bool.isRequired,
  isEmbeddedApp: PropTypes.bool.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  searchAction: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialiseRecall: PropTypes.func.isRequired,
  initialiseAssignLicence: PropTypes.func.isRequired,
  toggleRemoveMaterialModalAction: PropTypes.func.isRequired,
  resetRemoveLearningMaterialFormAction: PropTypes.func.isRequired,
  resetRemoveLearningMaterialFormForInstanceAction: PropTypes.func.isRequired,
  showRemoveMaterialModal: PropTypes.bool.isRequired,
  setContextAction: PropTypes.func.isRequired,
  onProductUrlClick: PropTypes.func.isRequired,
  localizedContent: PropTypes.object,
  assignedMaterialsInCurrentOrg: PropTypes.object,
  classAssignmentsDataRecency: PropTypes.object.isRequired,
  identity: PropTypes.object.isRequired,
  classrooms: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array,
  people: PropTypes.object,
  userRole: PropTypes.string
};
