/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import { Button } from '@oup/shared-front-end';
import colors from '../../globals/colors';
import markdown from '../../utils/markdown/markdown';
import { SIZES as thumbnailSizes } from '../EntityRepresentation/EntityRepresentation';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './BannerInvitation.scss';
import Carousel from '../Carousel/Carousel';
import Thumbnail from '../Thumbnail/Thumbnail';
import { isHubMode } from '../../utils/platform';
import Illustration from '../Illustration/Illustration';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../globals/hubConstants';

function Invitation({
  bannerHeading,
  inviteHeading,
  inviteHeadingExpired,
  inviteSubheadingExpired,
  inviteSentOn,
  currentInvite,
  invitationBannerContent,
  userId,
  orgId,
  onAccept,
  onReviewing,
  onDismiss,
  onNext,
  onPrev,
  onSetPage,
  page,
  pages
}) {
  const renderBannerHeading = () =>
    currentInvite.expired ? null : (
      <div className="col cols-center">
        {/* TODO: Consider how to make the "n invitations" text bold in multiple languages */}
        <h4 id="invitationHeading">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: markdown(bannerHeading) }} />
        </h4>
      </div>
    );

  const renderInviteThumbnail = () =>
    currentInvite.expired ? (
      <Thumbnail size={thumbnailSizes.SECTION} glyph={GLYPHS.ICON_ERROR_CIRCLE} />
    ) : (
      <Thumbnail size={thumbnailSizes.TABLE} glyph={GLYPHS.ICON_ADD_USER} backgroundColor={colors.PROFILE} />
    );

  const renderInviteIllustrationlHub = () =>
    currentInvite.expired ? (
      <Illustration
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
    ) : (
      <Illustration
        illustrationSrc={HubIllustrationConstants.CLASS_STUDENTS}
        illustrationAltText={HubIllustrationAltText.CLASS_STUDENTS}
      />
    );

  const renderInviteHeading = () => (
    <div>
      <span>{currentInvite.expired ? inviteHeadingExpired : inviteHeading}</span>
    </div>
  );

  const renderInviteSubheading = () => (
    <div>
      <span>{currentInvite.expired ? inviteSubheadingExpired : inviteSentOn}</span>
    </div>
  );

  return (
    <div className="grid">
      <div className={classnames('row', styles.inviteHeading)}>{renderBannerHeading()}</div>

      <div className={styles.inviteSummary}>
        <div className="col sm-cols-center">
          <div id="inviteSummary" className={styles.inviteSummaryThumbnail}>
            {isHubMode() ? renderInviteIllustrationlHub() : renderInviteThumbnail()}
            <div className={styles.inviteSummaryText}>
              {renderInviteHeading()}
              {renderInviteSubheading()}
            </div>
          </div>

          <div className="col">
            <div className="row cols-center">
              {!currentInvite.expired && (
                <div className="col">
                  <Button
                    id="invitationAcceptButton"
                    variant="filled"
                    onClick={() => onAccept(userId, orgId)}
                    text={invitationBannerContent.button_text_join}
                  />
                </div>
              )}
              {!currentInvite.expired && (
                <div className="col">
                  <Button
                    id="invitationDeclineButton"
                    variant="outline"
                    onClick={() => onReviewing()}
                    text={invitationBannerContent.button_text_no_thanks}
                  />
                </div>
              )}
              {currentInvite.expired && (
                <div className="col">
                  <Button
                    id="invitationDismissButton"
                    variant="outline"
                    onClick={() => onDismiss(userId, orgId)}
                    text={invitationBannerContent.button_text_dismiss}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <Carousel
          bullet={page}
          onNext={onNext}
          onPrev={onPrev}
          bulletsLength={pages}
          onSetPage={onSetPage}
          invitationBannerContent={invitationBannerContent}
        />
      </div>
    </div>
  );
}
export default Invitation;
