import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import compose from '../../../utils/compose/compose.js';
import withRouter from '../../../utils/withRouter';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper } from '../../../components';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ProductUpload from './ProductUpload.js';
import CsvProductStructureUploadSuccess from '../../../components/CsvProductStructureUpload/CsvProductStructureUploadSuccess';
import { resetForm } from '../../../redux/reducers/productUpload.reducer.js';
import { resetForm as resetFormLogs } from '../../../redux/reducers/productUploadLogs.reducer.js';
import { SOURCES } from '../../../globals/contentPreviewConstants.js';

class ProductUploadPage extends Component {
  componentWillUnmount() {
    const { redirect, selectedPlatformCode } = this.props;
    if (selectedPlatformCode?.toLowerCase() === SOURCES.ELTCORE) {
      redirect('/eltcore-builder');
    } else {
      redirect('/products');
    }
  }

  render() {
    const {
      inProgress,
      loading,
      errorOccurred,
      success,
      localizedContent: { productUploadPage: content },
      redirect,
      errorMessage,
      productUploadResult,
      selectedPlatformCode
    } = this.props;

    const warnings = productUploadResult;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        {inProgress ? (
          <div>
            <ProductUpload content={content} selectedPlatformCode={selectedPlatformCode} />
          </div>
        ) : null}
        {loading ? <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} /> : null}
        {errorOccurred ? (
          <ErrorStatus
            title={errorMessage}
            buttonText={content.backButton}
            buttonOnClickHandler={() => redirect('/products')}
          />
        ) : null}
        {success ? (
          <CsvProductStructureUploadSuccess
            content={content}
            goBackToDashboard={() => redirect('/products')}
            warnings={warnings}
          />
        ) : null}
      </PageWrapper>
    );
  }
}

ProductUploadPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  inProgress: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  productUploadResult: PropTypes.string,
  selectedPlatformCode: PropTypes.string
};

export default compose(
  withRouter,
  withLocalizedContent('productUploadPage'),
  connect(
    state => ({
      inProgress: state.productUpload.inProgress,
      loading: state.productUpload.loading,
      errorOccurred: state.productUpload.errorOccurred,
      errorMessage: state.productUpload.errorMessage,
      success: state.productUpload.success,
      productUploadResult: state.productUpload.productUploadResult
    }),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(resetForm());
        dispatch(resetFormLogs());
        props.history.push(path);
      }
    })
  )
)(ProductUploadPage);
