import { groupBy, pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../../../utils/withRouter';
import compose from '../../../../utils/compose/compose.js';
import Badge from '../../../../components/Badge/Badge';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Link from '../../../../components/Link/Link';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel';
import SearchStatus from '../../../../components/SearchStatus/SearchStatus';
import SlideupPanel from '../../../../components/SlideupPanel/SlideupPanel';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import UserListing, { ListingTypes } from '../../../../components/UserListing/UserListing';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import colors from '../../../../globals/colors';
import { searchUserSortOptions } from '../../../../globals/searchFilters';
import UserRoles from '../../../../globals/userRoles';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import { closeForm, showClosePanelModal } from '../../../../redux/reducers/archiveUsers.reducer';
import { setTeacherSelected } from '../../../../redux/reducers/classroomPage';
import { setFilter, setPage, setSort, setTerm, triggerSearch } from '../../../../redux/reducers/data/search.reducer';
import { clearForm as clearAddTeachersForm } from '../../../../redux/reducers/addTeachersToClassroom';
import {
  clearForm as clearRemoveTeachersForm,
  setTeachersToRemove
} from '../../../../redux/reducers/removeTeachersFromClassroom';
import { USER_ARCHIVED_STATUS, CLASS_ARCHIVED_STATUS } from '../../../../utils/archiveStatuses';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks';
import AssignLearningMaterial from '../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import AddTeachersPanel from './panels/AddTeachers/AddTeachers';
import RemoveTeachersPanel from './panels/RemoveTeachers/RemoveTeachers';
import { isOrbMode, isHubMode } from '../../../../utils/platform';
import ProductFinderPanel from '../../../../components/ProductFinder/ProductFinderPanel';

class TeachersTab extends Component {
  _handleItemLabelClick = userId => {
    const { history, orgId } = this.props;
    history.push(`/org/${orgId}/user/${userId}`);
  };

  _handleItemSelect = id => {
    const { selectedTeacherIds, setTeacherSelectedAction } = this.props;
    const isSelected = !(selectedTeacherIds || []).includes(id);
    setTeacherSelectedAction(id, isSelected);
  };

  _handleRemoveUser = userId => {
    const { setTeachersToRemoveAction, history } = this.props;
    setTeachersToRemoveAction([userId]);
    history.push(safePanelLink('removeTeachers'));
  };

  _getProductFinderContextName = () => {
    const {
      people,
      selectedTeacherIds,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedTeacherIds.length === 1
      ? `${people[selectedTeacherIds[0]].firstname || ''} ${people[selectedTeacherIds[0]].lastname || ''}`
      : `${selectedTeacherIds.length} ${productFinderContent.teachers_text}`;
  };

  render() {
    const {
      orgId,
      classroomId,
      currentUserId,
      tabName,
      panelName,
      people,
      searchTerm,
      sort,
      page,
      filters,
      resultIds,
      totalResults,
      selectedTeacherIds,
      currentUserCanEditClass,
      showConfirmModal,
      classTeachersDataRecency,
      history,
      triggerSearchAction,
      setSearchTerm,
      setSortAction,
      setPageAction,
      setFilterAction,
      setTeachersToRemoveAction,
      showModalAction,
      closeModalAction,
      canManageAssignments = true,
      authUserId,
      teacherIds,
      orgAdminIds,
      teacherAdminIds,
      loading,
      clearAddTeachersFormAction,
      clearRemoveTeachersFormAction,
      localizedContent: { classPageTeachersTab: content, removeLearningMaterialModal: removeMaterialModalContent }
    } = this.props;

    const selectableUserIds = Object.keys(
      pickBy(
        pick(people, resultIds),
        user =>
          user.orgArchiveStatus !== USER_ARCHIVED_STATUS &&
          !user.isDeleted &&
          user[`classArchiveStatus-${classroomId}`] !== CLASS_ARCHIVED_STATUS
      )
    );

    return (
      <div>
        <ListPageControls
          idPrefix="classTeachersSearch"
          searchInputLabel={content.search_teachers_label}
          searchInputPlaceholder={content.search_teachers_placeholder}
          searchInputValue={searchTerm}
          searchInputOnChange={setSearchTerm}
          searchInputOnSubmit={triggerSearchAction}
          newButtonText={content.add_teachers_text}
          newButtonTo={currentUserCanEditClass ? safePanelLink('addTeachers') : null}
          filterOptions={[
            {
              text: content.active_teachers_filter_text,
              id: 'classTeachersSearch-filterActive',
              name: 'classTeachersSearch-filterActive',
              value: 'active',
              checked: filters.active,
              onChange: setFilterAction
            },
            {
              text: content.removed_teachers_filter_text,
              id: 'classTeachersSearch-filterArchived',
              name: 'classTeachersSearch-filterArchived',
              value: 'archived',
              checked: filters.archived,
              onChange: setFilterAction
            }
          ]}
          sortOnChange={setSortAction}
          sortOptions={searchUserSortOptions('classTeachersSearch', sort)}
          ariaControls="searchResults"
          loading={classTeachersDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={classTeachersDataRecency.synced}
          loadedMessage={content.loaded_message}
          showSkeletonLoader={loading}
        />

        <SearchStatus
          searchSource="classTeachers"
          noResultsFoundContent={
            <div className="grid">
              <div className="row">
                <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                  <p className="gin-top1 gin-bot1">{content.no_teacher_search_results_text}</p>
                  {currentUserCanEditClass ? (
                    <Link to={safePanelLink('addTeachers')}>{content.add_teachers_text}</Link>
                  ) : null}
                </div>
              </div>
            </div>
          }
        />

        {loading ? null : (
          <div className="grid horizantal-scroll-mobile">
            <div className="row">
              <div
                id="searchResults"
                className="col"
                role="region"
                aria-live="polite"
                aria-atomic="true"
                aria-label="Classroom teachers list"
              >
                {[
                  { ids: orgAdminIds, heading: 'Organization Administrators' },
                  { ids: teacherAdminIds, heading: 'Teacher Admins' },
                  { ids: teacherIds, heading: 'Teachers' }
                ].map(({ ids, heading }) =>
                  ids.length ? (
                    <UserListing
                      className="gin-top2"
                      classroomId={classroomId}
                      heading={heading}
                      items={pick(people, ids)}
                      key={heading}
                      listingType={ListingTypes.JOIN}
                      onItemLabelClick={this._handleItemLabelClick}
                      onItemSelect={this._handleItemSelect}
                      onRemoveClick={currentUserCanEditClass ? this._handleRemoveUser : null}
                      processingItems={classTeachersDataRecency.syncing ? classTeachersDataRecency.ids : []}
                      selectable={selectableUserIds}
                      selectedItems={selectedTeacherIds}
                      selectAllEnabled
                      authUserId={authUserId}
                    />
                  ) : null
                )}
                {totalResults > 10 ? (
                  <div className="gin-top2">
                    <PaginationButtons
                      idPrefix="classTeacherSearch"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / 10)}
                      onClick={setPageAction}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <SlideupPanel isOpen={!!selectedTeacherIds.length}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '2rem' }}>
              <Badge backgroundColor={colors.DASHBOARD} value={selectedTeacherIds.length} />
              <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{content.selected_text}</span>
            </div>
            {currentUserCanEditClass && !selectedTeacherIds.includes(String(currentUserId)) ? (
              <LinkWithIcon
                id="openRemoveTeachers"
                to={safePanelLink('removeTeachers')}
                text={content.remove_teachers_text}
                glyph={GLYPHS.ICON_RIGHT}
                action={() => setTeachersToRemoveAction(selectedTeacherIds)}
              />
            ) : null}
            {!isOrbMode() && currentUserCanEditClass && canManageAssignments ? (
              <LinkWithIcon
                id="openAssignToTeachers"
                to={safePanelLink('assignMaterial')}
                text={content.assign_material_text}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}
          </div>
        </SlideupPanel>

        {currentUserCanEditClass && (
          <div>
            <AddTeachersPanel
              isOpen={panelName === 'addTeachers'}
              orgId={orgId}
              closePanel={() => showModalAction(true)}
              onComplete={() => {
                history.push(safePanelUnlink('addTeachers'));
              }}
            />

            <RemoveTeachersPanel
              isOpen={panelName === 'removeTeachers'}
              closePanel={() => showModalAction(true)}
              onComplete={() => {
                history.push(safePanelUnlink('removeTeachers'));
              }}
            />

            {canManageAssignments && !isHubMode() && (
              <PopoutPanel
                id="assignToTeachersPopout"
                ariaLabel={content.assign_material_to_teachers_text}
                isOpen={tabName === 'teachers' && panelName === 'assignMaterial'}
              >
                {isHubMode() ? (
                  <ProductFinderPanel
                    orgId={orgId}
                    selectedUsers={{ teacherIdList: [...selectedTeacherIds] }}
                    contextName={this._getProductFinderContextName()}
                    onClose={() => showModalAction(true)}
                    onComplete={() => {
                      history.push(safePanelUnlink('assignMaterial'));
                    }}
                    // needed for polling. will be removed when polling is removed
                    context="CLASS_TEACHERS"
                  />
                ) : (
                  <AssignLearningMaterial
                    context="CLASS_TEACHERS"
                    orgId={orgId}
                    closePopoutAction={() => showModalAction(true)}
                    onComplete={() => {
                      history.push(safePanelUnlink('assignMaterial'));
                    }}
                  />
                )}
              </PopoutPanel>
            )}
          </div>
        )}
        {showConfirmModal && (
          <ConfirmationModal
            title={removeMaterialModalContent.title}
            body={removeMaterialModalContent.body}
            positiveClickText={removeMaterialModalContent.positiveClickText}
            negativeClickText={removeMaterialModalContent.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              showModalAction(false);
              clearAddTeachersFormAction();
              clearRemoveTeachersFormAction();

              // Once its closed then reset the archiveUsers form
              setTimeout(closeModalAction, 300);
              history.push(safePanelUnlink('assignMaterial'));
              history.push(safePanelUnlink('removeTeachers'));
              history.push(safePanelUnlink('addTeachers'));
            }}
            negativeClick={() => showModalAction(false)}
          />
        )}
      </div>
    );
  }
}

TeachersTab.propTypes = {
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string,
  tabName: PropTypes.string,
  panelName: PropTypes.string,
  people: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  selectedTeacherIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  showConfirmModal: PropTypes.bool.isRequired,
  classTeachersDataRecency: PropTypes.object.isRequired,
  currentUserCanEditClass: PropTypes.bool.isRequired,
  setTeacherSelectedAction: PropTypes.func.isRequired,
  clearAddTeachersFormAction: PropTypes.func.isRequired,
  clearRemoveTeachersFormAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setTeachersToRemoveAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  closeModalAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  canManageAssignments: PropTypes.bool,
  authUserId: PropTypes.string.isRequired,
  teacherIds: PropTypes.array.isRequired,
  orgAdminIds: PropTypes.array.isRequired,
  teacherAdminIds: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default compose(
  withRouter,
  withLocalizedContent('classPageTeachersTab', 'removeLearningMaterialModal', 'productFinder'),
  withDataRecency('classTeachers'),
  connect(
    state => {
      const {
        [UserRoles.ORG_ADMIN]: orgAdminIds = [],
        [UserRoles.TEACHER_ADMIN]: teacherAdminIds = [],
        [UserRoles.TEACHER]: teacherIds = []
      } = groupBy(state.search.classTeachers.ids, id => state.people.data[id].roleName);

      return {
        userRole: state.identity.role,
        authUserId: state.identity.userId,
        people: state.people.data,
        currentUserId: state.identity.role === 'TEACHER' ? state.identity.userId : null,
        selectedTeacherIds: state.classroomPage.selectedTeacherIds,
        ...pick(state.search.classTeachers, ['sort', 'page', 'filters', 'totalResults', 'loading', 'error']),
        searchTerm: state.search.classTeachers.term,
        resultIds: state.search.classTeachers.ids,
        showConfirmModal: state.archiveUsers.showModal,
        orgAdminIds,
        teacherAdminIds,
        teacherIds
      };
    },
    {
      clearAddTeachersFormAction: clearAddTeachersForm,
      clearRemoveTeachersFormAction: clearRemoveTeachersForm,
      setTeacherSelectedAction: setTeacherSelected,
      setSearchTerm: term => setTerm('classTeachers', term),
      setSortAction: sort => setSort('classTeachers', sort[0]),
      setPageAction: page => setPage('classTeachers', page),
      setFilterAction: (valueName, value) => setFilter('classTeachers', valueName, value),
      triggerSearchAction: triggerSearch.bind(null, 'classTeachers'),
      setTeachersToRemoveAction: setTeachersToRemove,
      showModalAction: showClosePanelModal,
      closeModalAction: closeForm
    }
  )
)(TeachersTab);
