/** Registration saga
 *  This saga handles the sequence of events triggered during a registration process
 */

// UI Flow:
//   1. Submit detail in main form
//   2. Submit the form
//   3. Wait for API response
//   4. Show confirmation page

import { put, take, throttle, select, takeLatest } from 'redux-saga/effects';
import { omit } from 'lodash';
// Used for importing Regex
import { REDEEM_CODE } from '@oup/shared-node-browser/constants';
import { shouldStoreMarketingRecord } from '@oup/shared-node-browser/marketingPrefUtils.js';
import appSettings from '../../../../../globals/appSettings.js';

import { isHubMode, getCurrentPlatform, isEbookSampleMode } from '../../../../../utils/platform.js';
import {
  formStates,
  VALIDATE_USERNAME,
  SET_PARTIAL_VIA_TOKEN,
  SUBMIT_FORM,
  SEND_EMAIL_INVITATION,
  RESET_REGISTRATION,
  setPartialRegUserLookedUp,
  setUserNameExists,
  registrationComplete,
  registrationRetry,
  sendEmailInvitationComplete
} from '../../../../reducers/registration/registration.reducer.js';

// Helpers for calling lambdas:
import checkGlobalUserEmailApi from '../../../apiCalls/users/checkGlobalUserEmail.api.js';
import sendEmailInvitationApi from '../../../apiCalls/users/sendEmailInvitation.api.js';

// Helper for FULL registration:
import registerUserApi from '../../../apiCalls/users/registerUser.api.js';
// Helper for PARTIAL registration:
import getInvitedUserDetailsApi from '../../../apiCalls/users/getInvitedUserDetails.api.js';
import updateInvitedUserDetailsApi from '../../../apiCalls/users/updateInvitedUserDetails.api.js';
import updatePartialUserRegistrationApi from '../../../apiCalls/users/updatePartialUserRegistration.api.js';

const moduleName = '[registration Saga]';

const omitPassword = params => omit(params, ['password']);
const getRedeemCodeValueFromLS = redeemCodeKey => localStorage.getItem(redeemCodeKey);

// Returns true if userName already registered:
function* validateUserName() {
  const { userNameValue: userName } = yield select(state => state.registration);

  // Don't bother validating if the userName doesn't look like an email address:
  if (userName && userName.trim() && appSettings.EMAIL_REGEX.test(userName.trim())) {
    console.log(moduleName, `checkGlobalUserEmailApi '${userName}'`);
    // eslint-disable-next-line prefer-const
    let { data, statusCode } = yield checkGlobalUserEmailApi(userName.trim());
    let exists = false;
    let registrationStatus;
    if (statusCode && statusCode !== 403 && data) {
      ({ exists, registrationStatus } = data);
    }

    // If exists is null then the second parameter to this action indicates an API error
    yield put(setUserNameExists({ ...{ exists }, ...{ registrationStatus } }, exists === null));
    return exists;
  }
  return true;
}

// GET PARTIAL registration only: (Lookup user for specified invitation token)
function* getUserDetailsViaToken() {
  const { token: userToken } = yield select(state => state.registration);
  console.log(moduleName, 'getUserDetailsViaToken', userToken);
  const response = yield getInvitedUserDetailsApi({ userToken });
  const userId = response && response.data && response.data.userId;

  // If not found then the second parameter to this action indicates an API error
  yield put(setPartialRegUserLookedUp((response && response.data) || {}, !userId));
}

// FULL: Self-registration:
function* registerUser() {
  // Read details from state:
  const {
    firstNameValue: firstName,
    lastNameValue: lastName,
    userNameValue: userName,
    passwordValue: password,
    termsAndConditionsValue: termsAccepted,
    underAgeAcceptedValue: underAgeAccepted,
    marketingEmailAcceptedValue: marketingEmailAccepted,
    platformCode,
    selfSelectedRoleValue: selfSelectedRole,
    claimedSchoolValue: claimedSchool,
    countryCodeValue: countryCode,
    townCityValue: townCity,
    zipCodeValue: zipCode,
    stateProvinceValue: stateProvince
  } = yield select(state => state.registration);
  const platformCodeValue = platformCode || getCurrentPlatform();

  const apiParams = {
    userName,
    firstName,
    lastName,
    password,
    privacyPolicyAccepted: true,
    platformCode: platformCodeValue
  };

  if (shouldStoreMarketingRecord(platformCodeValue, marketingEmailAccepted, underAgeAccepted)) {
    apiParams.marketingEmailAccepted = marketingEmailAccepted;
    apiParams.underAgeAccepted = underAgeAccepted;
  }

  if (isHubMode()) {
    apiParams.hubTermsAccepted = termsAccepted;
    apiParams.termsAccepted = false;
  } else {
    apiParams.hubTermsAccepted = false;
    apiParams.termsAccepted = termsAccepted;
  }

  if (isEbookSampleMode()) {
    apiParams.selfSelectedRole = selfSelectedRole;
    apiParams.claimedSchool = claimedSchool;
    apiParams.countryCode = countryCode;
    apiParams.townCity = townCity;
    apiParams.zipCode = zipCode;
    apiParams.stateProvince = stateProvince;
  }

  console.log(moduleName, 'FULL Registration request:', omitPassword(apiParams));
  const data = yield registerUserApi(apiParams);

  console.log(moduleName, 'FULL Registration response:', data);

  return {
    errorOccurred: data.status !== 'success',
    userId: (data && data.data && data.data.userId) || null,
    errorMessage: data.message
  };
}

// Send email invitation already invited user
function* sendEmailInvitation() {
  const { userNameValue: userName } = yield select(state => state.registration);

  if (userName && userName.trim() && appSettings.EMAIL_REGEX.test(userName.trim())) {
    const isRedeemCodeAvailable = !!getRedeemCodeValueFromLS(REDEEM_CODE);
    console.log(moduleName, `Send email invitation to '${userName}'`);
    const data = yield sendEmailInvitationApi({ userName: userName.trim(), isRedeemCodeAvailable });
    const errorOccurred = data.status !== 'success';
    yield put(sendEmailInvitationComplete(errorOccurred));
  }
  return true;
}

// TODO: ***** AWAITING LAMBDA *****
// PARTIAL: Finalise TOKEN registration:
function* registerPartialUserViaToken() {
  // Read details from state:
  const {
    userIdValue: userId,
    token: userToken,
    firstNameValue: firstName,
    lastNameValue: lastName,
    userNameValue: email,
    passwordValue: password,
    termsAndConditionsValue: tsAndCsAccepted,
    underAgeAcceptedValue: underAgeAccepted,
    marketingEmailAcceptedValue: marketingEmailAccepted,
    platformCode
  } = yield select(state => state.registration);
  const platformCodeValue = platformCode || getCurrentPlatform();

  const apiParams = {
    userToken,
    firstName,
    lastName,
    email,
    password,
    privacyPolicyAccepted: true,
    platformCode: platformCodeValue
  };

  if (shouldStoreMarketingRecord(platformCodeValue, marketingEmailAccepted, underAgeAccepted)) {
    apiParams.marketingEmailAccepted = marketingEmailAccepted;
    apiParams.underAgeAccepted = underAgeAccepted;
  }

  if (isHubMode()) {
    apiParams.hubTsAndCsAccepted = tsAndCsAccepted;
  } else {
    apiParams.tsAndCsAccepted = tsAndCsAccepted;
  }

  console.log(moduleName, 'PARTIAL Registration request:', omitPassword(apiParams));
  const data = yield updateInvitedUserDetailsApi(userId, apiParams);
  console.log(moduleName, 'PARTIAL Registration response:', data);

  return {
    errorOccurred: data.status !== 'success',
    userId
  };
}

// PARTIAL: Finalise EXISTING/LGACY registration:
function* registerPartialUserViaFinalise() {
  // Read details from state:
  const platformCode = getCurrentPlatform();
  const { userId } = yield select(state => state.identity);
  const {
    registrationType,
    userNameValue: email,
    termsAndConditionsValue: tsAndCsAccepted,
    underAgeAcceptedValue: underAgeAccepted,
    marketingEmailAcceptedValue: marketingEmailAccepted
  } = yield select(state => state.registration);

  const apiParams = {
    email: registrationType === formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY ? email : undefined,
    privacyPolicyAccepted: true,
    platformCode
  };

  if (shouldStoreMarketingRecord(platformCode, marketingEmailAccepted, underAgeAccepted)) {
    apiParams.marketingEmailAccepted = marketingEmailAccepted;
    apiParams.underAgeAccepted = underAgeAccepted;
  }

  if (isHubMode()) {
    apiParams.hubTsAndCsAccepted = tsAndCsAccepted;
  } else {
    apiParams.tsAndCsAccepted = tsAndCsAccepted;
  }

  console.log(moduleName, 'PARTIAL Registration request:', apiParams);
  const data = yield updatePartialUserRegistrationApi(userId, apiParams);
  console.log(moduleName, 'PARTIAL Registration response:', data);

  return {
    errorOccurred: data.status !== 'success',
    userId: (data && data.userId) || null
  };
}

export default function* registrationSaga() {
  console.log('[registration Saga] Beginning');

  yield throttle(500, VALIDATE_USERNAME, validateUserName);

  // send email invitation for already invited user
  yield takeLatest(SEND_EMAIL_INVITATION, sendEmailInvitation);

  // For PARTIAL registration via token only:
  // (This ends up triggering action "SET_PARTIAL_USER_LOOKED_UP")
  yield takeLatest(SET_PARTIAL_VIA_TOKEN, getUserDetailsViaToken);

  // This will loop continually
  while (true) {
    console.log(moduleName, 'Waiting for user to submit registration form');
    yield take(SUBMIT_FORM);
    const { registrationType } = yield select(state => state.registration);
    let result;
    console.log(moduleName, 'Submitting registration for', registrationType);
    if (registrationType === formStates.INPUTTING_PARTIAL_VIA_TOKEN) {
      // PARTIAL registration via TOKEN:
      result = yield registerPartialUserViaToken();
    } else if (registrationType === formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING) {
      // PARTIAL registration via FINISH EXISTING USER:
      result = yield registerPartialUserViaFinalise();
    } else if (registrationType === formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY) {
      // PARTIAL registration via FINISH LEGACY USER:
      result = yield registerPartialUserViaFinalise();
    } else {
      // FULL REGISTRATION:
      result = yield registerUser();
    }

    if (result.errorMessage && result.errorMessage.indexOf('Login Credential name already exists') > 0) {
      // If exists is null then the second parameter to this action indicates an API error
      yield put(
        setUserNameExists(
          {
            ...{ exists: true },
            ...{ formState: formStates.INPUTTING_NEW },
            ...{ registrationStatus: 'REGISTRATION_EXISTS' }
          },
          false
        )
      );
    } else {
      yield put(registrationComplete(result.errorOccurred, result.userId, 'TOKEN_INVALID'));
      console.log(moduleName, 'Complete');
      if (isHubMode() && result.errorOccurred) {
        yield registrationRetry();
        console.log(moduleName, 'Registration reset for retry');
      } else {
        yield take(RESET_REGISTRATION);
        console.log(moduleName, 'Starting another registration');
      }
    }
  }
}
