import { pick } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { subscriptionTypes } from '@oup/shared-node-browser/subscriptions';
import compose from '../utils/compose/compose.js';

import { mode as opMode } from '../redux/reducers/orgSubscription.reducer.js';
import withLocalizedContent from '../language/withLocalizedContent.js';
import actions from '../redux/actions/index.js';
import { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import { PopoutPanelIconHeading, ErrorStatus } from '../components/index.js';
import CreateOrgSubscriptionForm from '../components/CreateOrgSubscriptionForm/CreateOrgSubscriptionForm.js';
import OrgSubscriptionSuccess, { operationTypes } from '../components/OrgSubscriptionSuccess/OrgSubscriptionSuccess.js';

function CreateOrgSubscriptionPage({
  localizedContent: { createOrgSubscriptionForm: localizedContent },
  formData = {},
  products = [],
  orgProducts = [],
  getProducts,
  getOrgProducts,
  mode,
  input,
  validate,
  submit,
  reset,
  repeat,
  errors
}) {
  const params = useParams();

  const {
    orgId = '',
    orgName = '',
    productId = '',
    subscriptionId = '',
    subscriptionType = subscriptionTypes.SUBSTYPE_ORB, // the first one in dropdown
    startDate = '',
    endDate = ''
  } = formData;

  const submitForm = () => submit(formData);

  const onChangeHandler = field => value => input({ field, value });

  const onBlurHandler = field => () => validate(formData, field);

  const addAnotherLicence = () => {
    repeat({ productId: '', subscriptionId: '' });
    if (subscriptionType === 'oic') {
      getOrgProducts(orgId);
    }
    getProducts(subscriptionType);
  };

  useEffect(() => {
    reset();

    if (params) {
      Object.keys(params).forEach(field => {
        const value = params[field];
        return input({ field, value });
      });

      validate(params, 'orgId');
      getProducts(subscriptionType);
    }

    return () => reset();
  }, [params]);

  useEffect(() => {
    getProducts(subscriptionType);
  }, [subscriptionType]);

  useEffect(() => {
    if (orgId) {
      if (subscriptionType === 'oic') {
        getOrgProducts(orgId);
      }
      getProducts(subscriptionType);
    }
  }, [orgId, subscriptionType]);

  const formComponent = (
    <CreateOrgSubscriptionForm
      localizedContent={localizedContent}
      orgId={orgId}
      orgName={orgName}
      subscriptionType={subscriptionType}
      products={products}
      orgProducts={orgProducts}
      productId={productId}
      startDate={startDate}
      endDate={endDate}
      errors={errors}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      onSubmit={submitForm}
    />
  );

  const successRespComponent = (
    <OrgSubscriptionSuccess
      operation={operationTypes.CREATE}
      orgName={orgName}
      productName={products.find(product => product.productId === productId)?.productName ?? ''}
      subscriptionId={subscriptionId}
      subscriptionType={subscriptionType}
      startDate={startDate}
      endDate={endDate}
      nextUrl={`/dashboard/org/${orgId}/test-credits`}
      addAnotherLicence={addAnotherLicence}
      backUrl="/dashboard"
    />
  );

  const Component = {
    [opMode.LOADING]: <PopoutPanelIconHeading type={types.LOADING} title={localizedContent.waiting_message} />,
    [opMode.INPUT]: formComponent,
    [opMode.EMPTY]: formComponent,
    [opMode.SUCCESS]: successRespComponent,
    [opMode.ERROR]: (
      <ErrorStatus
        title={localizedContent.error_title}
        subtitle={typeof errors === 'object' ? Object.values(errors)[0] : errors}
        buttonText={localizedContent.error_button}
        buttonOnClickHandler={reset}
      />
    )
  }[mode];

  return (
    <>
      <Helmet title={localizedContent.page_title} />
      {Component}
    </>
  );
}

CreateOrgSubscriptionPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  formData: PropTypes.object,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired
    })
  ).isRequired,
  orgProducts: PropTypes.array,
  getProducts: PropTypes.func.isRequired,
  getOrgProducts: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(opMode)).isRequired,
  input: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  repeat: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default compose(
  withLocalizedContent('createOrgSubscriptionForm'),
  connect(
    state => ({
      ...pick(state.orgSubscription, ['mode', 'errors', 'formData']),
      ...pick(state.subscriptionProducts, ['products', 'orgProducts'])
    }),
    dispatch => ({
      input: input => dispatch(actions.createOrgSubscriptionInput(input)),
      validate: (formData, field) => dispatch(actions.createOrgSubscriptionValidate({ formData, field })),
      submit: input => dispatch(actions.createOrgSubscriptionRequest(input)),
      reset: () => dispatch(actions.createOrgSubscriptionReset()),
      repeat: input => dispatch(actions.createOrgSubscriptionRepeat(input)),
      getProducts: (platform = 'orb') => dispatch(actions.getProductsRequest(platform)),
      getOrgProducts: orgId => dispatch(actions.getOrgProductsRequest(orgId))
    })
  )
)(CreateOrgSubscriptionPage);
