import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import { featureIsEnabled, isDev } from '../../globals/envSettings';
import {
  formStates,
  setFullRegistration,
  setPartialRegViaToken,
  setPartialRegViaFinalise,
  setExtendRegistration
} from '../../redux/reducers/registration/registration.reducer';
import withLocalizedContent from '../../language/withLocalizedContent';

import RegisterFormFetching from './RegisterFormFetching';
import RegisterFormInputPage from './RegisterFormInputPage';
import RegisterFormSubmitting from './RegisterFormSubmitting';
import PreAuthConfirmationPage from './PreAuthConfirmationPage';
import RegisterEmailSendComplete from './RegisterEmailSendComplete';
import PartialRegisterFormInputPage from './PartialRegisterFormInputPage';
import RegisterStep from './RegisterStep';

const FINALISE = 'finish';
const isRegisterPage = true;
class RegisterForm extends React.Component {
  // INITIALISE registraiton by triggering action for Full reg, Partial reg via token, or Partial reg finalise:
  componentWillMount() {
    const {
      token,
      setFullRegistrationAction,
      setPartialRegViaTokenAction,
      setPartialRegViaFinaliseAction,
      setExtendRegistrationAction,
      location
    } = this.props;

    const query = new URLSearchParams(location.search);
    const isOteMode = query.get('returnUrl') ? query.get('returnUrl').includes('oxfordtestofenglish.oup.com') : null;
    const isExtRegFeature = featureIsEnabled('ote-extend-registration');

    const devMode = isDev();
    // Trigger desired action: (Plus 2 special shortcuts in DEV only)
    if (devMode && token === 'LEGACY') setPartialRegViaFinaliseAction('LEGACY');
    else if (devMode && token === 'EXISTING') setPartialRegViaFinaliseAction('EXISTING');
    else if (token === FINALISE) {
      /*  Do nothing */
    } else if (token) {
      if (isOteMode && isExtRegFeature) {
        setExtendRegistrationAction(token);
      } else {
        setPartialRegViaTokenAction(token);
      }
    } else setFullRegistrationAction();
  }

  render() {
    const {
      formState,
      token,
      location,
      platform,
      platformCode,
      offeredRole,
      localizedContent: { registrationPage: content },
      setPartialRegViaTokenAction
    } = this.props;

    const query = new URLSearchParams(location.search);

    const handleRegisterStepAction = () => setPartialRegViaTokenAction(token);

    switch (formState) {
      case formStates.FETCHING_PARTIAL_USER:
        return <RegisterFormFetching />;
      case formStates.INPUTTING_NEW:
        // Full registration
        return <RegisterFormInputPage platform={platformCode} />;
      case formStates.INPUTTING_PARTIAL_VIA_TOKEN:
      case formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY:
      case formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING:
        // Partial registration
        return (
          <PartialRegisterFormInputPage
            returnUrl={query.get('returnUrl')}
            platform={platformCode}
            formState={formState}
            token={token}
          />
        );
      case formStates.SUBMITTING:
        return <RegisterFormSubmitting />;
      case formStates.COMPLETE:
        return (
          <PreAuthConfirmationPage
            platform={platform}
            offeredRole={offeredRole}
            isRegister={isRegisterPage}
            location={location}
          />
        );
      // Email sending
      case formStates.EMAILSENDING:
        return <RegisterFormSubmitting emailSending />;
      case formStates.EMAILSENDCOMPLETE:
        return <RegisterEmailSendComplete />;
      case formStates.EXTEND_REGISTRATION:
        return (
          <RegisterStep
            title={content.register_step1_title_text}
            subtitle={content.register_step1_subtitle_text}
            btnText={content.register_step_button_text}
            btnAction={handleRegisterStepAction}
          />
        );
      default:
        return <p>{`${content.form_state_error_text} ${formState}.`}</p>;
    }
  }
}

RegisterForm.propTypes = {
  formState: PropTypes.oneOf(Object.values(formStates)).isRequired,
  // The token prop can be one of:
  // - Blank string for Full Registration.
  // - "finish" for completing a Partial Registration of existing or legacy user.
  // - Any userToken string for completing a Partial Registration via an email link with a token.
  platform: PropTypes.string,
  offeredRole: PropTypes.string,
  token: PropTypes.string.isRequired,
  location: PropTypes.object,
  // Actions
  setFullRegistrationAction: PropTypes.func.isRequired,
  setPartialRegViaTokenAction: PropTypes.func.isRequired,
  setPartialRegViaFinaliseAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  platformCode: PropTypes.string,
  setExtendRegistrationAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(
    state => ({
      formState: state.registration.formState,
      platform: state.registration.platformValue,
      offeredRole: state.registration.offeredRoleValue,
      oteRegistrationActiveStep: state.registration.oteRegistrationActiveStep
    }),
    {
      setFullRegistrationAction: setFullRegistration,
      setPartialRegViaTokenAction: setPartialRegViaToken,
      setPartialRegViaFinaliseAction: setPartialRegViaFinalise,
      setExtendRegistrationAction: setExtendRegistration
    }
  )
)(RegisterForm);
