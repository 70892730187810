/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
// Redux
import { toggleAssessmentLicenceTab } from '../../redux/actions/hubUi';
// Services
import withLocalizedContent from '../../language/withLocalizedContent';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ActionList, { Action } from '../ActionList/ActionList';
// Styles
import styles from './OnlineTestConfirmationPanel.scss';
// Utils
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

class OnlineTestConfirmationPanel extends Component {
  getParameters = () => {
    const {
      classroom,
      test,
      nrOfSelectedStudents,
      totalNumberOfStudents,
      isUpdateTestFlow = false,
      localizedContent: { hubSetTestOnline: content },
      selectedStudentIds = [],
      endDate
    } = this.props;

    if (isUpdateTestFlow) {
      return {
        className: classroom.name,
        numberOfSelectedStudents: test.selectedStudentIds?.length,
        selectedStudentIds: test.selectedStudentIds,
        numberOfStudents: classroom.amountOfLearners,
        confirmationSuccessTitle: content.confirmation_success_update_title,
        confirmationSuccessSubtitle: content.confirmation_success_update_subtitle,
        endDate: test.endDate
      };
    }

    return {
      className: classroom.name,
      numberOfSelectedStudents: nrOfSelectedStudents,
      numberOfStudents: totalNumberOfStudents,
      selectedStudentIds,
      confirmationSuccessTitle: content.confirmation_success_title,
      confirmationSuccessSubtitle: content.confirmation_success_subtitle,
      endDate
    };
  };

  render() {
    const {
      test,
      assessmentTitle,
      localizedContent: { hubSetTestOnline: content },
      closePanel,
      prev,
      errorOccurred
      // toggleAssessmentLicenceCodeTab
    } = this.props;

    const parametersToShow = this.getParameters();

    const headingText = `${
      errorOccurred ? content.confirmation_error_title : parametersToShow.confirmationSuccessTitle
    }`
      .replace('[testTitle]', `${assessmentTitle} ${getTestTitle(test)}`)
      .replace('[classroomName]', parametersToShow.className)
      .replace('[nrOfSelectedStudents]', parametersToShow.numberOfSelectedStudents)
      .replace('[nrOfClassStudents]', parametersToShow.numberOfStudents);

    return (
      <ScrollContainer
        headerContent={
          <PopoutPanelIllustrationHeading
            title={headingText}
            illustrationSrc={errorOccurred ? HubIllustrationConstants.ERROR : HubIllustrationConstants.SUCCESS}
            illustrationAltText={errorOccurred ? HubIllustrationAltText.ERROR : HubIllustrationAltText.SUCCESS}
          />
        }
        formState="CONFIRMATION"
        footerContent={
          <ActionList>
            {errorOccurred ? (
              <Action label={content.online_test_try_again} onClick={prev} customClassName={styles.tryAgainButton} />
            ) : (
              <Action label={content.done} primary onClick={closePanel} />
            )}
          </ActionList>
        }
      >
        <div className={styles.midContainer}>
          {!errorOccurred &&
            parametersToShow.confirmationSuccessSubtitle.replace('[classroomName]', parametersToShow.className)}
        </div>
        {/*
        The info label is temporarily disabled (EPS-10154).
        The function everyStudentHasLicence() relies on 'licenceStudentsList' state which was previously mocked.
        We will have an implementation for setting the correct data to that field but until then this label is disabled
        {!everyStudentHasLicence(test, parametersToShow.selectedStudentIds, parametersToShow.endDate) &&
          getInfoLabel(
            content.online_test_info_label,
            content.online_test_button_text_students_without_licence,
            styles.infoLabelContainer,
            GLYPHS.ICON_WARNING_CIRCLE,
            toggleAssessmentLicenceCodeTab
          )}
        */}
      </ScrollContainer>
    );
  }
}

OnlineTestConfirmationPanel.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  closePanel: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  classroom: PropTypes.object,
  nrOfSelectedStudents: PropTypes.number.isRequired,
  totalNumberOfStudents: PropTypes.number.isRequired,
  errorOccurred: PropTypes.bool.isRequired,
  endDate: PropTypes.any,
  selectedStudentIds: PropTypes.array,
  // toggleAssessmentLicenceCodeTab: PropTypes.func,
  isUpdateTestFlow: PropTypes.bool
};

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({
      search: { profileClasses: { currentClassList = {} } = {}, userSelection: { totalResults = 0 } = {} } = {},
      onlineTest: { classId = '', selectedStudentIds = [], error = false, endDate } = {}
    }) => ({
      classroom: currentClassList[classId],
      totalNumberOfStudents: totalResults,
      nrOfSelectedStudents: selectedStudentIds.length,
      errorOccurred: error,
      endDate,
      selectedStudentIds
    }),
    { toggleAssessmentLicenceCodeTab: toggleAssessmentLicenceTab }
  )
)(OnlineTestConfirmationPanel);

OnlineTestConfirmationPanel.defaultProps = {
  assessmentTitle: ''
};
