import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../../utils/compose/compose.js';
import { hubDownloadResource } from '../../../../redux/actions/hubResource';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function ContentPreviewLaunch({ item, key, content, hubDownloadResourceAction }) {
  if (item.objectTitle === false) {
    item.objectTitle = '.';
  }
  const fileExt = item.objectTitle?.split('.').pop();
  const previewableResources = ['mp3', 'mp4'];
  const isPreviewable = previewableResources.includes(fileExt);

  return (
    <Button
      key={`id${key}`}
      text={isPreviewable ? content.preview : content.download}
      onClick={() => {
        let resourceAction = 'download';
        if (isPreviewable) {
          resourceAction = 'play';
        }

        const resource = {
          resourceId: item.objectResourceID,
          subtitleResourceId: '',
          itemId: item.objectResourceID,
          title: item.objectTitle,
          format: fileExt
        };

        hubDownloadResourceAction(resource, resourceAction);
      }}
      type={buttonTypes.NO_BORDER}
    />
  );
}

ContentPreviewLaunch.propTypes = {
  item: PropTypes.object,
  key: PropTypes.number,
  content: PropTypes.object,
  hubDownloadResourceAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('contentPreview'),
  connect(null, dispatch => ({
    hubDownloadResourceAction: (resource = {}, resourceAction = 'play') => {
      dispatch(hubDownloadResource(resource, resourceAction));
    }
  }))
)(ContentPreviewLaunch);
