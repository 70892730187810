import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import classnames from 'classnames';
import compose from '../../utils/compose/compose.js';
import { GlyphIcon, EmblemSize, EmblemShape } from '../IconEmblem/IconEmblem';
import Link from '../Link/Link';

import styles from './ContentPlayerFooter.scss';
import SVGIcon from '../SVGIcon/SVGIcon';

function ContentPlayerFooter({
  cptContent,
  prevLink,
  nextLink,
  isDisabledLeft = false,
  isDisabledRight = false,
  handlePageChange,
  isFirstActivityInLesson = false,
  isLastActivityInLesson = false,
  progressBarNavigation
}) {
  return (
    <div className={classnames(styles.cpButtonRow, styles[`progressBarNavigation${progressBarNavigation}`])}>
      <div className={styles.cpLeft}>
        <Link to={prevLink}>
          <div
            className={classnames(
              EmblemSize.MEDIUM,
              EmblemShape.SQUARE,
              styles.cpNextPrevButton,
              `${isDisabledLeft && styles.cpDisabled}`
            )}
            role="button"
            aria-label={cptContent.previous_title}
            {...(!isDisabledLeft && handlePageChange && { onClick: handlePageChange })}
          >
            <SVGIcon
              glyph={isFirstActivityInLesson ? GlyphIcon.ICON_DOUBLE_LEFT_THICK : GlyphIcon.ICON_LEFT_THICK_16}
            />
          </div>
        </Link>
      </div>
      <div className={styles.cpRight}>
        <Link to={nextLink}>
          <div
            className={classnames(
              EmblemSize.MEDIUM,
              EmblemShape.SQUARE,
              styles.cpNextPrevButton,
              `${isDisabledRight && styles.cpDisabled}`
            )}
            role="button"
            aria-label={cptContent.next_title}
            {...(!isDisabledRight && handlePageChange && { onClick: handlePageChange })}
          >
            <SVGIcon
              glyph={isLastActivityInLesson ? GlyphIcon.ICON_DOUBLE_RIGHT_THICK : GlyphIcon.ICON_RIGHT_THICK_16}
            />
          </div>
        </Link>
      </div>
    </div>
  );
}

ContentPlayerFooter.propTypes = {
  cptContent: PropTypes.object,
  prevLink: PropTypes.string,
  nextLink: PropTypes.string,
  isDisabledLeft: PropTypes.bool,
  isDisabledRight: PropTypes.bool,
  handlePageChange: PropTypes.func,
  isFirstActivityInLesson: PropTypes.bool,
  isLastActivityInLesson: PropTypes.bool,
  progressBarNavigation: PropTypes.string
};

const mapStateToProps = ({ structuredContentPlayer: { progressBarNavigation } }) => ({
  progressBarNavigation
});

export default compose(connect(mapStateToProps))(ContentPlayerFooter);
