import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InvitationUpdateForm from './InvitationUpdateForm.js';

function InvitationUpdatePage({ context }) {
  const navigate = useNavigate();
  const invitationPageValid = useSelector(
    state => state.invites.formState === 'SUBMITTING' || state.invites.formState === 'CONFIRMATION'
  );
  const inviteStatus = useSelector(state =>
    state.invites.invites.reduce((result, curr) => (curr.orgId === state.invites.orgId ? curr.newStatus : result), null)
  );

  useEffect(() => {
    if (!invitationPageValid) {
      navigate(context === 'NGS' ? '/ngs' : '/');
    }
  }, []);

  return (
    inviteStatus === 'ACCEPTED' && (
      <div>
        {/* TODO: move this title to a glossary */}
        <Helmet title="Invitation" />
        <div className="grid">
          <div className="row">
            <div className="col pad-top2 pad-bot4">
              <InvitationUpdateForm />
            </div>
          </div>
        </div>
      </div>
    )
  );
}

InvitationUpdatePage.propTypes = {
  context: PropTypes.string
};

export default InvitationUpdatePage;
