import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import TextInput from '../../../../../components/TextInput/TextInput';
import Card from '../../../../../components/Card/Card';
import SVGIcon, { GLYPHS } from '../../../../../components/SVGIcon/SVGIcon';
import styles from '../css/ProductCreationPage.scss';

function UrlTab({ localizedContent: { productCreate: content }, productDetails, setProductDetails }) {
  const { protocol, host, path, query, fragment, expression } = productDetails;
  const setDetails = setProductDetails();
  const [showForm, setShowForm] = useState(false);
  const closeForm = () => {
    setShowForm(false);
    setDetails.resetUrlFields();
  };

  return (
    <Card>
      <div className={styles.urlButtonsContainer}>
        <Button
          size="small"
          variant="transparent"
          text={content.add_new_url}
          icon={{ component: <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />, placement: 'right' }}
          onClick={() => setShowForm(true)}
        />
        <Button
          size="small"
          variant="transparent"
          icon={{ component: <SVGIcon glyph={GLYPHS.ICON_CLOSE} />, placement: 'right' }}
          onClick={() => closeForm()}
        />
      </div>
      {showForm && (
        <div className={styles.formContainer}>
          <TextInput
            id="protocol"
            label={content.protocol}
            value={protocol}
            onChange={value => {
              setDetails.setProtocolFunction(value);
            }}
          />
          <TextInput
            id="host"
            label={content.host}
            value={host}
            onChange={value => {
              setDetails.setHostFunction(value);
            }}
          />
          <TextInput
            id="path"
            label={content.path}
            value={path}
            onChange={value => {
              setDetails.setPathFunction(value);
            }}
          />
          <TextInput
            id="query"
            label={content.query}
            value={query}
            onChange={value => {
              setDetails.setQueryFunction(value);
            }}
          />
          <TextInput
            id="fragment"
            label={content.fragment}
            value={fragment}
            onChange={value => {
              setDetails.setFragmentFunction(value);
            }}
          />
          <TextInput
            id="expression"
            label={content.expression}
            value={expression}
            onChange={value => {
              setDetails.setExpressionFunction(value);
            }}
          />
        </div>
      )}
    </Card>
  );
}

UrlTab.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  productDetails: PropTypes.object.isRequired,
  setProductDetails: PropTypes.func.isRequired
};

export default withLocalizedContent('productCreate')(UrlTab);
