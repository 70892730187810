import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import compose from '../../../../../../utils/compose/compose.js';
import EntityRepresentation from '../../../../../../components/EntityRepresentation/EntityRepresentation';
import MaterialRepresentation from '../../../../../../components/MaterialRepresentation/MaterialRepresentation';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer';
import { sanitizeUrl } from '../../../../../../utils/url.js';
import { isHubMode } from '../../../../../../utils/platform';
import SVGIcon, { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../../../../components/Thumbnail/Thumbnail';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import withLocalizedErrors from '../../../../../../language/withLocalizedErrors';
import { ERROR_CONSTANTS } from '../../../../../../globals/errorConstants';
import styles from './AddToLibraryConfirmation.scss';
import { DATE_LONG } from '../../../../../../globals/dateFormats';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';

class AddToLibraryConfirmation extends Component {
  static getLicenceErrorMessage = (errorMessageData, getLocalizedErrorMessage) => {
    let errorMessage = '';
    if (errorMessageData) {
      errorMessage = getLocalizedErrorMessage(errorMessageData);
      if (typeof errorMessageData === 'object' && errorMessageData.activationDate) {
        errorMessage = errorMessage.replace('{1}', moment(errorMessageData.activationDate).format(DATE_LONG));
      }
    }
    return errorMessage;
  };

  static handleSubmit = event => {
    event.preventDefault();
  };

  getSubTitleForErrorCode = errorMessage => {
    const {
      localizedContent: { addToLibrary: content }
    } = this.props;

    if (errorMessage?.code === ERROR_CONSTANTS.ADD_ORG_LICENCE_ERROR_3157) {
      return content.multi_activation_code_not_redeemable_subtitle;
    }
    if (typeof errorMessage === 'object') {
      return content.try_again_subtitle;
    }

    return '';
  };

  getFooterContentForErrorCode = errorMessage => {
    const {
      localizedContent: { addToLibrary: content },
      renderAddLicencesPanel
    } = this.props;
    const checkContentErrors =
      Object.keys(content).indexOf(errorMessage) > -1 ? content[errorMessage] : content.add_to_library_error_message;

    if (
      errorMessage?.code === ERROR_CONSTANTS.ADD_ORG_LICENCE_ERROR_3157 ||
      typeof errorMessage === 'object' ||
      checkContentErrors
    ) {
      return (
        <PopoutActionFooter
          primaryButtonAction={renderAddLicencesPanel}
          primaryButtonText={content.button_try_different_code}
        />
      );
    }

    return '';
  };

  getTitleForErrorCode = errorMessage => {
    const {
      localizedContent: { addToLibrary: content },
      getLocalizedErrorMessage
    } = this.props;

    if (errorMessage?.code === ERROR_CONSTANTS.ADD_ORG_LICENCE_ERROR_3157) {
      // 3157 - error code for multi activation access code
      return getLocalizedErrorMessage({ code: errorMessage.code ? errorMessage.code : errorMessage });
    }

    if (
      (typeof errorMessage === 'object' && typeof errorMessage.code !== 'string') ||
      errorMessage?.code === ERROR_CONSTANTS.ADD_ORG_LICENCE_ERROR_2007
    ) {
      return AddToLibraryConfirmation.getLicenceErrorMessage(errorMessage, getLocalizedErrorMessage);
    }
    const errorMessageValue = errorMessage.code ? errorMessage.code : errorMessage;
    const checkContentErrors =
      Object.keys(content).indexOf(errorMessageValue) > -1
        ? content[errorMessageValue]
        : content.add_to_library_error_message;

    return (checkContentErrors || '').replace('{errorMessage}', errorMessageValue ? content[errorMessageValue] : '');
  };

  renderContentForMutliActivationCode = () => {
    const {
      localizedContent: { addToLibrary: content },
      accessCodeFormatted
    } = this.props;
    return (
      <div className="grid">
        <div className="row cols-center gin-top4 text-center">
          <div className={styles.accessCodeWrapper}>{accessCodeFormatted}</div>
        </div>
        <div className="row cols-center gin-top2">
          <p>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={
                isHubMode() ? APP_CONSTANTS.HUB_SUPPORT_IMPORT_ACCESS_CODES_URL : APP_CONSTANTS.REDEEM_CODE_HELP_LINK
              }
              className={styles.link}
            >
              {`${' '}${content.need_help_with_access_code}${' '}`}
              <SVGIcon glyph={GLYPHS.ICON_LINK} />
            </a>
          </p>
        </div>
      </div>
    );
  };

  render() {
    const {
      localizedContent: { addToLibrary: content },
      errorOccurred = false,
      errorMessage = '',
      productData = {},
      productFetchError = false,
      productDetailsLoading = false,
      closePanel,
      onComplete,
      renderAddLicencesPanel
    } = this.props;

    return (
      <form onSubmit={AddToLibraryConfirmation.handleSubmit} className={styles.addToLibraryConfirmation}>
        <ScrollContainer
          headerContent={
            <div>
              <div className={classnames('text-right', { [styles.buttonCloseConfirmationHub]: isHubMode() })}>
                <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
              </div>
              {isHubMode() ? (
                <PopoutPanelIllustrationHeading
                  title={
                    errorOccurred ? this.getTitleForErrorCode(errorMessage) : content.add_to_library_success_message
                  }
                  subtitle={
                    errorOccurred
                      ? this.getSubTitleForErrorCode(errorMessage)
                      : content.add_to_library_success_subtitle_message
                  }
                  illustrationSrc={errorOccurred ? HubIllustrationConstants.ERROR : HubIllustrationConstants.SUCCESS}
                  illustrationAltText={errorOccurred ? HubIllustrationAltText.ERROR : HubIllustrationAltText.SUCCESS}
                />
              ) : (
                <PopoutPanelIconHeading
                  type={errorOccurred ? 'ERROR' : 'VALID'}
                  title={
                    errorOccurred ? this.getTitleForErrorCode(errorMessage) : content.add_to_library_success_message
                  }
                  subtitle={
                    errorOccurred
                      ? this.getSubTitleForErrorCode(errorMessage)
                      : content.add_to_library_success_subtitle_message
                  }
                />
              )}
            </div>
          }
          footerContent={
            errorOccurred ? (
              this.getFooterContentForErrorCode(errorMessage)
            ) : (
              <PopoutActionFooter
                primaryButtonAction={onComplete}
                primaryButtonText={content.complete_panel_text}
                secondaryButtonText={content.add_more_licences}
                secondaryButtonAction={renderAddLicencesPanel}
              />
            )
          }
        >
          {errorOccurred &&
            errorMessage?.code === ERROR_CONSTANTS.ADD_ORG_LICENCE_ERROR_3157 &&
            this.renderContentForMutliActivationCode()}

          {!errorOccurred && (
            <div>
              {productDetailsLoading && (
                <PopoutPanelIconHeading
                  type={types.LOADING}
                  title={content.loading_products_title}
                  subtitle={content.loading_products_subtitle}
                />
              )}

              {!productDetailsLoading &&
                !!Object.keys(productData).length &&
                Object.values(productData).map((product, i) => (
                  <div style={{ margin: '1rem' }} key={i}>
                    <MaterialRepresentation
                      thumbnailSize={thumbnailSizes.TABLE}
                      imageSrc={sanitizeUrl(product.coverImage)}
                      name={product.title}
                      subtext={(content.avalible_licences_text || '').replace(
                        '{availableLicences}',
                        product.availableCount
                      )}
                    />
                  </div>
                ))}

              {!productDetailsLoading && productFetchError && (
                <EntityRepresentation
                  thumbnailGlyph={GLYPHS.ICON_ERROR_CIRCLE}
                  thumbnailSize={thumbnailSizes.TABLE}
                  line1Text={content.product_details_error}
                />
              )}
            </div>
          )}
        </ScrollContainer>
      </form>
    );
  }
}

AddToLibraryConfirmation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  getLocalizedErrorMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  productData: PropTypes.object,
  accessCodeFormatted: PropTypes.string.isRequired,
  errorOccurred: PropTypes.bool,
  productFetchError: PropTypes.bool,
  productDetailsLoading: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  renderAddLicencesPanel: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('addToLibrary'),
  withLocalizedErrors('eps-add-library-redeem-code')
)(AddToLibraryConfirmation);
