import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { TEST_BUILDER_CONSTANTS, getAdaptedTestStatus } from '@oup/shared-node-browser/testBuilder';
import compose from '../../utils/compose/compose.js';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import getAdaptedTestStatusIcon from '../../structure/HubDashboardLayout/Utils/getAdaptedTestStatusIcon';
import HubDropdownMenu from '../HubDropdownMenu/HubDropdownMenu';
import breakpoints from '../../globals/breakpoints';
import getDropdownItems from '../HubDropdownMenu/Services/getDropdownItems';
import { updateAdaptedTest } from '../../redux/actions/adaptedTests';
import styles from './AssessmentTest.scss';

class AssessmentTest extends PureComponent {
  handleClick = () => {
    const { assessmentId, openExpiredModal, openModal, locked, assessmentTest, isAdapted } = this.props;
    if (locked) {
      openExpiredModal();
    } else {
      if (!openModal) return;
      openModal(assessmentId, assessmentTest, isAdapted);
    }
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleClick();
    }
  };

  dropdownActions = () => {
    const actions = {
      previewTest: e => {
        const { assessmentId, openModal, assessmentTest, isAdapted } = this.props;
        e.stopPropagation();
        openModal(assessmentId, assessmentTest, isAdapted);
      },
      downloadPrintVersionAction: e => {
        const { assessmentTest, downloadAssessment, isAdapted } = this.props;
        e.stopPropagation();
        downloadAssessment(assessmentTest, isAdapted);
      },
      mockAction: e => {
        e.stopPropagation();
      },
      adaptInTestBuilderAction: e => {
        const { openTestBuilderModal, assessmentId } = this.props;
        e.stopPropagation();
        openTestBuilderModal(assessmentId);
      },
      setTestAction: e => {
        const { openOnlineTest, assessmentId, assessmentTest } = this.props;
        e.stopPropagation();
        openOnlineTest(assessmentId, assessmentTest);
      },
      deleteAdaptedTest: async e => {
        const { assessmentId, updateAdaptedTestAction } = this.props;
        e.stopPropagation();
        updateAdaptedTestAction({ status: TEST_BUILDER_CONSTANTS.STATUSES.DELETED }, assessmentId);
      }
    };

    return actions;
  };

  render() {
    const {
      breakpoint,
      assessmentTest,
      isAdapted,
      assessmentId,
      content,
      downloadAssessment,
      locked,
      dropdownTop,
      title,
      displayDropdown = true,
      hideDownloadButtonText = false.valueOf,
      selectOnlineTest,
      updateAdaptedTestAction
    } = this.props;
    let svgIcon = locked ? GLYPHS.ICON_LOCK_24 : GLYPHS.ICON_ASSESSMENT;
    const hasPrint = assessmentTest && assessmentTest.printableResourceId;
    const hasDigital = assessmentTest && assessmentTest.digitalResourceId;
    const hasAdaptedDigitalId = assessmentTest?.quadAssessment?.resource_id;
    let adaptedTestStatus = null;

    if (isAdapted && assessmentTest.actionCode && assessmentTest.status) {
      adaptedTestStatus = getAdaptedTestStatus(assessmentTest);
      svgIcon = getAdaptedTestStatusIcon(adaptedTestStatus);
    }

    return (
      (assessmentTest.title || assessmentTest.resource_title || title) && (
        <li
          className={`${styles.assessmentTest} ${
            locked || adaptedTestStatus === TEST_BUILDER_CONSTANTS.STATUSES.ERROR ? styles.unavailableTest : ''
          }`}
        >
          <div
            className={
              locked || adaptedTestStatus === TEST_BUILDER_CONSTANTS.STATUSES.ERROR
                ? styles.testUnavailableItemContainer
                : styles.testItemContainer
            }
            onClick={this.handleClick}
            onKeyDown={this.handleKeyDown}
            tabIndex={0}
            role="link"
          >
            <div className={styles.testDetailsContainer}>
              <div className={styles.svgIconContainer}>
                <SVGIcon glyph={svgIcon} />
              </div>
              <div className={styles.testDescriptionContainer}>
                <span className={styles.testLabelTitle}>
                  {assessmentTest.title || assessmentTest.resource_title || title}
                </span>
                {!(!locked && hasPrint && !hasDigital) ? (
                  ''
                ) : (
                  <span className={styles.testSubLabelTitle}>{content.assessment_sub_label}</span>
                )}
                {adaptedTestStatus === TEST_BUILDER_CONSTANTS.STATUSES.INPROGRESS ? (
                  <span className={styles.testSubLabelTitle}>{content.adapted_test_in_progress_text}</span>
                ) : (
                  ''
                )}
                {adaptedTestStatus === TEST_BUILDER_CONSTANTS.STATUSES.ERROR ? (
                  <span className={styles.testSubLabelTitle}>{content.adapted_test_error_text}</span>
                ) : (
                  ''
                )}
              </div>
            </div>
            {adaptedTestStatus === TEST_BUILDER_CONSTANTS.STATUSES.ERROR && (
              <Button
                type={buttonTypes.THERE_BE_DRAGONS}
                glyph={GLYPHS.ICON_DELETE}
                customClassName={styles.deleteAdaptedTestButton}
                onClick={async e => {
                  e.stopPropagation();
                  e.preventDefault();
                  await updateAdaptedTestAction({ status: TEST_BUILDER_CONSTANTS.STATUSES.DELETED }, assessmentId);
                }}
              />
            )}
            {!locked &&
              displayDropdown &&
              assessmentTest &&
              (hasDigital || (isAdapted && adaptedTestStatus === TEST_BUILDER_CONSTANTS.STATUSES.SUCCESS)) && (
                <HubDropdownMenu
                  customClassname={styles.simpleEllipsis}
                  parentId={isAdapted ? [assessmentId, uuid.v4()].join('-') : assessmentId}
                  dropdownData={getDropdownItems(
                    'assessmentTests',
                    content,
                    { isAdapted, hasPrint },
                    this.dropdownActions()
                  )}
                  panelCustomClassname={styles.menuPanel}
                  dropdownTop={dropdownTop}
                />
              )}
            {!locked && hasPrint && !hasDigital && (
              <div className={styles.buttonContainer}>
                <Button
                  text={breakpoint !== breakpoints.XXS && !hideDownloadButtonText ? content.button_download_text : ''}
                  glyph={GLYPHS.ICON_DOWNLOAD}
                  hasGlyphContainer
                  type={buttonTypes.DOWNLOAD_RESOURCE}
                  onClick={e => {
                    e.stopPropagation();
                    downloadAssessment(assessmentTest, isAdapted);
                  }}
                />
              </div>
            )}
            {selectOnlineTest && !locked && (hasDigital || hasAdaptedDigitalId) && (
              <div className={styles.selectTestButtonContainer}>
                <Button
                  glyph={GLYPHS.ICON_RIGHT_THICK}
                  iconOnly
                  onClick={selectOnlineTest}
                  ariaLabel={content.select_test}
                />
              </div>
            )}
          </div>
        </li>
      )
    );
  }
}

AssessmentTest.propTypes = {
  content: PropTypes.object,
  title: PropTypes.string,
  locked: PropTypes.bool,
  openModal: PropTypes.func,
  assessmentId: PropTypes.string,
  openOnlineTest: PropTypes.func,
  openTestBuilderModal: PropTypes.func,
  isAdapted: PropTypes.bool,
  assessmentTest: PropTypes.object,
  openExpiredModal: PropTypes.func,
  downloadAssessment: PropTypes.func,
  breakpoint: PropTypes.string,
  dropdownTop: PropTypes.bool,
  displayDropdown: PropTypes.bool,
  hideDownloadButtonText: PropTypes.bool,
  selectOnlineTest: PropTypes.func,
  updateAdaptedTestAction: PropTypes.func
};

export default compose(
  connect(() => ({}), {
    updateAdaptedTestAction: updateAdaptedTest
  })
)(AssessmentTest);
