import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import withLocalizedContent from '../../language/withLocalizedContent';
import { setPartialRegViaToken } from '../../redux/reducers/registration/registration.reducer';
import styles from './RegisterStep.scss';

function RegisterStep({
  totalSteps = 2,
  currentStep = 1,
  title,
  subtitle,
  btnText,
  btnAction,
  pageSubtitle,
  isRegistrationForm = false
}) {
  return (
    <div className={isRegistrationForm ? styles.containerRegistrationForm : styles.container}>
      <div className="row">
        <div className="col pad-top4">
          <ProgressStepper totalSteps={totalSteps} currentStep={currentStep} />
          <div className={styles.header}>
            <h3 className="pad-top2">{title}</h3>
            <p size="large">{subtitle}</p>
          </div>
          <p className={styles.pageSubtitle}>{pageSubtitle}</p>
          {btnText && (
            <div>
              <Button text={btnText} onClick={btnAction} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

RegisterStep.propTypes = {
  totalSteps: PropTypes.number,
  currentStep: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btnText: PropTypes.string,
  btnAction: PropTypes.func,
  pageSubtitle: PropTypes.string,
  isRegistrationForm: PropTypes.bool
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(
    state => ({
      token: state.registration.token
    }),
    {
      setPartialRegViaTokenAction: setPartialRegViaToken
    }
  )
)(RegisterStep);
