import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import compose from '../../../utils/compose/compose.js';
import Link from '../../../components/Link/Link';
import styles from './SubHeader.scss';
import USER_ROLES, { roleIsAtLeast } from '../../../globals/userRoles.js';
import Button, { GLYPHS } from '../../../components/Button/Button.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import {
  isAdminOrSupport as isAdminOrSupportSelector,
  isAdminSupportOrContent as isAdminSupportOrContentSelector,
  isELTReviewer as isELTReviewerSelector
} from '../../../redux/selectors/authorization/user';
import { isOrbMode, isCesMode, isEltAssessmentMode } from '../../../utils/platform.js';
import { featureIsEnabled, getEnvType, isProd } from '../../../globals/envSettings';
import TopBanner from '../TopBanner/TopBanner';
import { redirectToAm } from '../../../utils/url';
import { displayHeaderOrFooter } from '../../../services/cptHelperMethods.js';

const orbReportingUrlRegex = /^\/teacherHome\/reporting.*$/;
const orbResourcesUrlRegex = /^\/teacherHome\/library.*$/;
const orbOfflineAppUrlRegex = /^\/teacherHome\/offlineApp.*$/;
const profileUrlRegex = /^\/myProfile.*$/;
const schoolUrlRegex = /^\/(mySchool|org).*$/;
const dashboardUrlRegex = /^\/dashboard.*$/;
const productsUrlRegex = /^\/products.*$/;
const coursesUrlRegex = /^\/courses.*$/;
const lorCoursesLink = /^\/open\/lor\/courses.*$/;
const eltCoreProductBuilderLink = /^\/eltcore-builder.*$/;
const sebDownloadRegex = /^\/seb-download.*$/;
const contentPreviewUrlRegex = /^\/oup\/content\/resources.*$/;
const assetsUrlRegex = /^\/oup\/content\/assets.*$/;

function SubHeader({
  localizedContent: { siteHeaders: content },
  pathname,
  isMemberOfOrg,
  menuOpen,
  isAdminOrSupport,
  isAdminSupportOrContent,
  isELTReviewer,
  isAtLeastTeacher,
  orgSubscriptions
}) {
  const isOupSuperUserOutsideCes = !isCesMode() && isAdminOrSupport;
  if (!isMemberOfOrg || sebDownloadRegex.test(pathname)) return null;

  const hasOfflinePage =
    Object.values(orgSubscriptions).length > 0 &&
    Object.values(orgSubscriptions).find(subscription => subscription.productDetails.market === 'ASIA');

  const [displaySubHeader, setDisplaySubHeader] = useState(true);
  const location = useLocation();

  useEffect(() => displayHeaderOrFooter(location, setDisplaySubHeader), [location]);

  return (
    <>
      <div id="subNavMenu">
        {displaySubHeader && (
          <nav className={classnames(styles.quickNavLinks, menuOpen && styles.open)} aria-label="Secondary">
            <div className="grid">
              <div className="row">
                <div className="col">
                  <ul aria-busy="true">
                    {/* Only ORB Teachers can see this link */}
                    {isAtLeastTeacher && isOrbMode() ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="orbReportingLink"
                          to="/teacherHome/reporting"
                          className={orbReportingUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.orb_reporting_text}
                        </Link>
                      </li>
                    ) : null}

                    {/* Only ORB Teachers can see this link */}
                    {isAtLeastTeacher && isOrbMode() ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="orbResourcesLink"
                          to="/teacherHome/library"
                          className={orbResourcesUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.orb_resources_text}
                        </Link>
                      </li>
                    ) : null}

                    {isEltAssessmentMode() ? null : (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="myProfileLink"
                          to="/myProfile"
                          className={profileUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.my_profile_text}
                        </Link>
                      </li>
                    )}

                    {/* Everyone except learners, noobies and super users outside CES can see this link: */}
                    {isAtLeastTeacher && !isOupSuperUserOutsideCes && !redirectToAm() ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="mySchoolLink"
                          to="/mySchool"
                          className={schoolUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.my_school_text}
                        </Link>
                      </li>
                    ) : null}

                    {isAdminOrSupport && !isEltAssessmentMode() ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="dashboardLink"
                          to="/dashboard"
                          className={
                            dashboardUrlRegex.test(pathname) || orbReportingUrlRegex.test(pathname)
                              ? styles.selected
                              : null
                          }
                        >
                          {content.dashboard_text}
                        </Link>
                      </li>
                    ) : null}

                    {isAdminSupportOrContent ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="productsLink"
                          to="/products"
                          className={productsUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.products_tab_text}
                        </Link>
                      </li>
                    ) : null}

                    {isAdminSupportOrContent ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="contentPreviewLink"
                          to="/oup/content/resources"
                          className={contentPreviewUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.content_preview_tab_text}
                        </Link>
                      </li>
                    ) : null}

                    {isAdminSupportOrContent ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="assetsLink"
                          to="/oup/content/assets"
                          className={assetsUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.eps_assets_text}
                        </Link>
                      </li>
                    ) : null}

                    {isELTReviewer ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="epsCoursesLink"
                          to={isProd() ? '/courses/publish' : '/courses'}
                          className={coursesUrlRegex.test(pathname) ? styles.selected : null}
                        >
                          {content.eps_courses_tab_text}
                        </Link>
                      </li>
                    ) : null}

                    {isAdminSupportOrContent ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="lorCoursesLink"
                          to={`https://${getEnvType()}.account.oup.com/api/edu/open/lor/courses`}
                          className={lorCoursesLink.test(pathname) ? styles.selected : null}
                        >
                          {content.lor_courses_tab_text}
                        </Link>
                      </li>
                    ) : null}

                    {isELTReviewer ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="eltCoreBuilder"
                          to={isProd() ? '/eltcore-builder/preview' : '/elt-builder'}
                          className={eltCoreProductBuilderLink.test(pathname) ? styles.selected : null}
                        >
                          {content.elt_core_builder_tab_text}
                        </Link>
                      </li>
                    ) : null}

                    {/* Only ORB User outside ANZ and UK can see this link */}
                    {!featureIsEnabled('disable-offline-app-page') &&
                    isAtLeastTeacher &&
                    isOrbMode() &&
                    hasOfflinePage ? (
                      <li>
                        <Link
                          aria-controls="maincontent"
                          id="orbOfflinkAppLink"
                          to="/teacherHome/offlineApp"
                          className={classnames(
                            styles.offlineAppLink,
                            orbOfflineAppUrlRegex.test(pathname) && styles.selected
                          )}
                        >
                          {content.orb_offline_app_text}
                        </Link>
                      </li>
                    ) : null}

                    {/* Support link (Note this is replicated in SiteHeader menu for sm+ devices */}

                    <li role="list" className="sm-hide" aria-busy="true">
                      <div className="pad2">
                        <Button
                          id="supportLink"
                          to="/support"
                          glyph={isCesMode() ? GLYPHS.ICON_HELP : null}
                          fullWidth
                          text={content.help_and_support}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        )}
      </div>
      {featureIsEnabled('show-hub-redirect-banner') ? <TopBanner /> : null}
    </>
  );
}

SubHeader.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  isAdminOrSupport: PropTypes.bool.isRequired,
  isAdminSupportOrContent: PropTypes.bool.isRequired,
  isELTReviewer: PropTypes.bool.isRequired,
  isAtLeastTeacher: PropTypes.bool.isRequired,
  isMemberOfOrg: PropTypes.bool.isRequired,
  orgSubscriptions: PropTypes.object
};

export default compose(
  connect(state => ({
    pathname: window.location.pathname,
    isMemberOfOrg: !!state.identity.role,
    isAtLeastTeacher:
      roleIsAtLeast(USER_ROLES.TEACHER, state.identity.role) && USER_ROLES.OUP_CONTENT !== state.identity.role,
    isAdminOrSupport: isAdminOrSupportSelector(state),
    isAdminSupportOrContent: isAdminSupportOrContentSelector(state),
    isELTReviewer: isELTReviewerSelector(state),
    orgSubscriptions: state.subscriptions
  })),
  withLocalizedContent('siteHeaders')
)(SubHeader);
