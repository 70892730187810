import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';

import { toggleOrgUsagePanel } from '../../redux/actions/hubUi';
import { isHubMode } from '../../utils/platform';

import appSettings from '../../globals/appSettings';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import ControlledForm from '../ControlledForm/ControlledForm';
import OrgFormBody from '../OrgFormBody/OrgFormBody';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import EditOrgHubForm from '../EditOrgHubForm/EditOrgHubForm';

function EditOrg({
  localizedContent: { editOrg: localizedContent, curriculumTypeNames },
  roles = {},
  countries = {},
  name = '',
  role = '',
  countryCode = '',
  orgRegion = '',
  orgPostcode = '',
  sapNumber = '',
  curriculumType = '',
  orgUniqueId = '',
  primaryEmail = '',
  webAddress = '',
  isLmsLtiTool = false,
  disabled = [],
  hidden = [],
  errors = {},
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  onSubmit = () => {},
  toggleOrgUsagePanelAction
}) {
  const [isFormValid, setIsFormValid] = useState(!isHubMode());

  return (
    <ScrollContainer
      headerContent={
        <header className="gin-top2 gin-bot2">
          <h2>{name}</h2>
          <div>{orgUniqueId}</div>
        </header>
      }
      footerContent={
        <ActionList>
          {isHubMode() && (
            <Action
              label={localizedContent.action_view_organization_usage}
              secondary
              onClick={toggleOrgUsagePanelAction}
            />
          )}
          <Action
            label={localizedContent.submit_button}
            primary
            disabled={Object.values(errors).some(Boolean) || !isFormValid}
            onClick={onSubmit}
          />
        </ActionList>
      }
    >
      <ControlledForm className="pad2">
        {isHubMode() ? (
          <EditOrgHubForm
            name={name}
            countryCode={countryCode}
            primaryEmail={primaryEmail}
            errors={errors}
            createChangeHandler={createChangeHandler}
            createBlurHandler={createBlurHandler}
            content={localizedContent}
            countries={countries}
            disabled={disabled}
            onSetFormValidState={setIsFormValid}
          />
        ) : (
          <OrgFormBody
            localizedContent={localizedContent}
            localizedCurriculumTypeNames={curriculumTypeNames}
            roles={roles}
            countries={countries}
            name={name}
            role={role}
            countryCode={countryCode}
            orgRegion={orgRegion}
            orgPostcode={orgPostcode}
            sapNumber={sapNumber}
            curriculumType={curriculumType}
            curriculumTypes={appSettings.curriculumTypes}
            orgUniqueId={orgUniqueId}
            primaryEmail={primaryEmail}
            webAddress={webAddress}
            isLmsLtiTool={isLmsLtiTool}
            disabled={disabled}
            hidden={hidden}
            errors={errors}
            createChangeHandler={createChangeHandler}
            createBlurHandler={createBlurHandler}
          />
        )}
      </ControlledForm>
    </ScrollContainer>
  );
}

EditOrg.propTypes = {
  localizedContent: PropTypes.object,
  roles: PropTypes.object,
  countries: PropTypes.object,
  name: PropTypes.string,
  role: PropTypes.string,
  countryCode: PropTypes.string,
  orgRegion: PropTypes.string,
  orgPostcode: PropTypes.string,
  sapNumber: PropTypes.string,
  curriculumType: PropTypes.string,
  orgUniqueId: PropTypes.string,
  primaryEmail: PropTypes.string,
  webAddress: PropTypes.string,
  isLmsLtiTool: PropTypes.bool,
  disabled: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func,
  createBlurHandler: PropTypes.func,
  onSubmit: PropTypes.func,
  toggleOrgUsagePanelAction: PropTypes.func
};

export default compose(
  withLocalizedContent('editOrg', 'curriculumTypeNames'),
  connect(null, { toggleOrgUsagePanelAction: toggleOrgUsagePanel })
)(EditOrg);
