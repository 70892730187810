import { columnTypes } from '../../../../components/TableAccordion/TableAccordion';

export const sourceOptions = [
  {
    value: 'prod',
    text: 'LOR PROD'
  },
  {
    value: 'test',
    text: 'LOR TEST'
  },
  {
    value: 'uat',
    text: 'LOR UAT'
  },
  {
    value: 'dev',
    text: 'LOR DEV'
  }
];

export const contentAreaPlatformOptions = [
  {
    value: 'eltcore',
    text: 'ELTCORE'
  },
  {
    value: 'eltbook',
    text: 'ELTBOOK'
  },
  {
    value: 'vst',
    text: 'VST'
  },
  {
    value: 'olb',
    text: 'OLB'
  },
  {
    value: 'cba',
    text: 'CBA'
  }
];

export const themeOptions = [
  {
    value: 'default',
    text: 'default'
  },
  {
    value: 'elt',
    text: 'elt'
  },
  {
    value: 'he',
    text: 'he'
  },
  {
    value: 'hemini',
    text: 'hemini'
  },
  {
    value: 'nextgen',
    text: 'nextgen'
  },
  {
    value: 'orb',
    text: 'orb'
  },
  {
    value: 'theme2',
    text: 'theme2'
  },
  {
    value: 'oe',
    text: 'oe'
  }
];

export const getProductsPreviewColumns = content => [
  {
    heading: 'Platform',
    type: columnTypes.TEXT
  },
  {
    heading: 'Title',
    type: columnTypes.TEXT
  },
  {
    heading: 'CEFR level',
    type: columnTypes.TEXT
  },
  {
    heading: 'ISBN',
    type: columnTypes.TEXT
  },
  {
    heading: 'Created',
    type: columnTypes.TEXT
  },
  {
    heading: 'Created By',
    type: columnTypes.TEXT
  },
  {
    heading: 'Modified',
    type: columnTypes.TEXT
  },
  {
    heading: 'Modified By',
    type: columnTypes.TEXT
  },
  {
    heading: content.column_preview,
    type: columnTypes.BUTTON
  },
  {
    heading: content.column_action,
    type: columnTypes.BUTTON
  }
];

export const getEltGamesPreviewColumns = content => [
  {
    heading: 'Title',
    type: columnTypes.TEXT
  },
  {
    heading: 'Content Code',
    type: columnTypes.TEXT
  },
  {
    heading: 'Gitana ID',
    type: columnTypes.TEXT
  },
  {
    heading: 'Template',
    type: columnTypes.TEXT
  },
  {
    heading: 'Created',
    type: columnTypes.TEXT
  },
  {
    heading: 'Updated',
    type: columnTypes.TEXT
  },
  {
    heading: content.column_preview,
    type: columnTypes.BUTTON
  }
];

export const getEReaderProductsPreviewColumns = content => [
  {
    heading: 'Book Path',
    type: columnTypes.TEXT
  },
  {
    heading: 'Title',
    type: columnTypes.TEXT
  },
  {
    heading: 'Author',
    type: columnTypes.TEXT
  },
  {
    heading: 'Created',
    type: columnTypes.TEXT
  },
  {
    heading: 'Updated',
    type: columnTypes.TEXT
  },
  {
    heading: 'Details',
    type: columnTypes.TEXT
  },
  {
    heading: 'Theme',
    type: columnTypes.TEXT
  },
  {
    heading: content.column_preview,
    type: columnTypes.BUTTON
  }
];

export const getContentPreviewColumns = content => [
  {
    heading: content.column_domain,
    type: columnTypes.TEXT
  },
  {
    heading: content.column_filename,
    type: columnTypes.TEXT
  },
  {
    heading: content.column_resource_id,
    type: columnTypes.TEXT
  },
  {
    heading: content.column_content_type,
    type: columnTypes.TEXT
  },
  {
    heading: content.column_first_published,
    type: columnTypes.TEXT
  },
  {
    heading: content.column_last_published,
    type: columnTypes.TEXT
  },
  {
    heading: content.column_preview,
    type: columnTypes.BUTTON
  }
];
