import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './AverageBanner.scss';
import formatFraction from './formatFraction';
import sumOf from '../../utils/array/sumOf';
import PendingMarkIcon from '../PendingMarkIcon/PendingMarkIcon';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import { featureIsEnabled } from '../../globals/envSettings';
import { isHubMode } from '../../utils/platform';

function AverageBanner({
  averageTotals: {
    totalAverageLevelsCompleted,
    totalNumberOfActivities,
    totalLevelScoreAchieved,
    totalLevelScoreAvailable
  },
  usePercentages = false,
  isActivityLevel = false,
  levels = [],
  recordsLength,
  pendingMark = false,
  localizedContent: CONTENT,
  role
}) {
  let formattedTotalAverageCompleted;

  if (isActivityLevel) {
    formattedTotalAverageCompleted = formatFraction(
      sumOf(levels, 'totalStudentsThatSubmitted') / recordsLength,
      levels.length,
      usePercentages
    );
  } else {
    formattedTotalAverageCompleted =
      typeof totalAverageLevelsCompleted === 'number'
        ? totalAverageLevelsCompleted.toFixed(2)
        : totalAverageLevelsCompleted;
  }

  const applyGradebookUiUpdates = isHubMode() && featureIsEnabled('gradebook-ui-updates');

  return (
    <div className={classnames(styles.AverageBanner, { [styles.averageBannerRestyle]: applyGradebookUiUpdates })}>
      <div className={classnames(styles.AverageBanner__title, { [styles.titleRestyle]: applyGradebookUiUpdates })}>
        {CONTENT.class_average}:
      </div>
      <div className={styles.AverageBanner__totals}>
        <div className={styles.AverageBanner__total}>
          {isActivityLevel
            ? formattedTotalAverageCompleted
            : formatFraction(formattedTotalAverageCompleted, totalNumberOfActivities, usePercentages)}
          <div className={styles.AverageBanner__totalLabel}>{CONTENT.completed}</div>
        </div>
        <div className={styles.scoreMainContainer}>
          {featureIsEnabled('hub-gradebook-teacher-marked-icons') &&
          pendingMark &&
          roleIsAtLeast(userRoles.TEACHER, role) ? (
            <PendingMarkIcon customClassName={styles.pendingIconContainer} />
          ) : null}
          <div className={styles.AverageBanner__total}>
            {totalAverageLevelsCompleted === 0
              ? '-'
              : formatFraction(totalLevelScoreAchieved, totalLevelScoreAvailable, usePercentages)}
            <div className={styles.AverageBanner__totalLabel}>{CONTENT.score}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
AverageBanner.propTypes = {
  averageTotals: PropTypes.shape({
    totalAverageLevelsCompleted: PropTypes.number,
    totalNumberOfActivities: PropTypes.number,
    totalLevelScoreAchieved: PropTypes.number,
    totalLevelScoreAvailable: PropTypes.number
  }),
  usePercentages: PropTypes.bool,
  isActivityLevel: PropTypes.bool,
  recordsLength: PropTypes.number,
  levels: PropTypes.array,
  localizedContent: PropTypes.object.isRequired,
  pendingMark: PropTypes.bool,
  role: PropTypes.string
};
export default AverageBanner;
