import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../utils/compose/compose.js';
import { ConfirmationModal } from '../components';
import { MAX_POLL_USER_ATTEMPTS } from '../globals/dataRecency';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';
import { GLYPHS } from '../components/SVGIcon/SVGIcon';

const withDataRecency = (...collections) => Component => {
  function WithDataRecency({
    localizedContent: { dataRecencyRetryConfirmationModal, dataRecencyTimeoutConfirmationModal, ...localizedContent },
    dataRecency,
    retry,
    cancel,
    ...props
  }) {
    const dataRecencyProps = collections.reduce(
      (carry, collection) => ({
        ...carry,
        [`${collection}DataRecency`]: {
          ...pick(dataRecency.pendingItems[collection], ['ids', 'synced', 'syncing', 'timeout', 'attempt']),
          collection
        }
      }),
      {}
    );
    const dataRecencyTimeout = Object.values(dataRecencyProps).find(({ timeout }) => timeout);
    const canRetryPollEntitiesSynced = dataRecencyTimeout && dataRecencyTimeout.attempt >= MAX_POLL_USER_ATTEMPTS;
    const content = canRetryPollEntitiesSynced
      ? dataRecencyRetryConfirmationModal
      : dataRecencyTimeoutConfirmationModal;

    return (
      <div>
        <Component {...{ ...props, localizedContent, ...dataRecencyProps }} />
        {dataRecencyTimeout ? (
          <ConfirmationModal
            {...(canRetryPollEntitiesSynced
              ? {
                  iconType: GLYPHS.ICON_ERROR_CIRCLE,
                  positiveClickText: content.cancel_button,
                  positiveClick: () => cancel(dataRecencyTimeout.collection)
                }
              : {
                  iconType: GLYPHS.ICON_WARNING_CIRCLE,
                  positiveClickText: content.try_again_button,
                  negativeClickText: content.check_later_button,
                  positiveClick: () => retry(dataRecencyTimeout.collection),
                  negativeClick: () => cancel(dataRecencyTimeout.collection)
                })}
            title={content.title}
            body={content.body}
          />
        ) : null}
      </div>
    );
  }

  WithDataRecency.propTypes = {
    localizedContent: PropTypes.object.isRequired,
    dataRecency: PropTypes.object.isRequired,
    retry: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired
  };

  return compose(
    withLocalizedContent('dataRecencyRetryConfirmationModal', 'dataRecencyTimeoutConfirmationModal'),
    connect(state => pick(state, 'dataRecency'), {
      retry: actions.pollEntitiesSyncedRetry,
      cancel: actions.pollEntitiesSyncedCancel
    })
  )(WithDataRecency);
};

export default withDataRecency;
