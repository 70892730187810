import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import compose from '../utils/compose/compose.js';
import withLocalizedContent from '../language/withLocalizedContent.js';
import PopoutPanelIconHeading, { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import env from '../globals/envSettings';

function RedirectToJanisonPage({ localizedContent: { oicRedirectToJanison: localizedContent = {} } }) {
  const redirectTo = `${env.integrations.oic_janison.baseUrl}/auth/login?ignore-domain=true"`;

  const redirect = () => window.location.replace(redirectTo);

  React.useEffect(() => {
    redirect();
  }, []);

  return (
    <>
      <Helmet>
        <title>{localizedContent.page_title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <PopoutPanelIconHeading
          type={types.LOADING}
          title={localizedContent.headline}
          subtitle={localizedContent.info_label}
          link={redirectTo}
          linkText={localizedContent.call_to_action}
        />
      </div>
    </>
  );
}

RedirectToJanisonPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('oicRedirectToJanison'),
  connect(() => {}, {})
)(RedirectToJanisonPage);
