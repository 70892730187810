import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../utils/withRouter';
import { orgRoles } from '../globals/orgRoles';

const withRole = connect((state, ownProps) => ({
  identityRole: state.identity.role,
  ...ownProps,
  orgRole: state.organisations.data[state.identity.currentOrganisationId]?.role
}));

function restrictByOrgUser(Component) {
  function ProtectedComponent({ identityRole, ...props }) {
    const { orgRole, history } = props;
    useEffect(() => {
      if (orgRole === orgRoles.TEST_CENTER) {
        history.replace('/');
      }
    }, [orgRole, history]);

    // Render only occurs if componentWillMount passed without redirect
    return <Component {...props} />;
  }

  ProtectedComponent.propTypes = {
    history: PropTypes.any,
    identityRole: PropTypes.string,
    orgRole: PropTypes.oneOf(Object.keys(orgRoles))
  };

  return React.createElement(withRouter(withRole(ProtectedComponent)));
}

export default restrictByOrgUser;
