import classnames from 'classnames';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TextLink } from '@oup/shared-front-end';
import TextWithData from '../TextWithData/TextWithData';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ContentWithThumbnail from '../ContentWithThumbnail/ContentWithThumbnail';
import SortLabel from '../GradebookTable/SortLabel';
import styles from './GradedReadersLearnerView.scss';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import SegmentBar from '../SegmentBar/SegmentBar';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ReadersTable from '../ReadersTable/ReadersTable';
import ReadersTableStyles from '../ReadersTable/ReadersTable.scss';
import { tableSort } from '../SortHeader/SortHeader';
import SpacingOptions, { OPTIONS as SPACING_OPTIONS } from '../SpacingOptions/SpacingOptions';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as ThumbnailSizes } from '../Thumbnail/Thumbnail';
import { toInitials } from '../../utils/string';
import formatLastAccessed from '../../utils/date/formatLastAccessed';
import LevelJumpDropdown from './LevelJumpDropdown';
import content from '../../utils/cmsContent';
import { sanitizeUrl } from '../../utils/url.js';
import { featureIsEnabled } from '../../globals/envSettings';

momentDurationFormatSetup(moment);

export const formatSummaryTableRows = (summaryData, classroomName = 'Class') => {
  const returnObj = [];

  if (Object.values(summaryData).length) {
    returnObj.push({
      id: 'summaryRow1',
      cells: [
        <div
          className={styles.flexRowThumb}
          key={`cell${summaryData.mostReadLevel}${summaryData.averageProductsCompleted}`}
        >
          <img className={styles.thumbNailFormat} src="https://unsplash.it/203" alt="Summary thumbnail" />
          <div className={styles.flexColumn}>
            <p className="ReadersTable__jumboBook__3nru">
              <TextLink to={`${window.location.pathname}/reader`} component={RouterLink}>
                Graded Readers
              </TextLink>
            </p>
            <p className={styles.subtitleText}>{classroomName}</p>
            <div className={styles.responsiveData}>
              <div className={styles.flexRowText}>
                <p>Average books&nbsp;&nbsp;</p>
                <h2 className={styles.emphasisedText}>{parseFloat(summaryData.averageProductsCompleted).toFixed(1)}</h2>
              </div>

              <div className={styles.flexRowText}>
                <p>Average level&nbsp;&nbsp;</p>
                <h2 className={styles.emphasisedText}>{summaryData.mostReadLevel}</h2>
              </div>
            </div>
          </div>
        </div>,
        <div
          className={styles.barSizer}
          key={`barSizer${summaryData.mostReadLevel}${summaryData.averageProductsCompleted}`}
        >
          <SegmentBar barSize={summaryData.averageProductsCompleted} />
        </div>,
        <div
          className={styles.flexRowThumb}
          key={`tumbnail${summaryData.mostReadLevel}${summaryData.averageProductsCompleted}`}
        >
          <h2 className={styles.emphasisedText}>{parseFloat(summaryData.averageProductsCompleted).toFixed(1)}</h2>
          <p className="gin-top3 gin-left1">books</p>
        </div>,
        <h2
          className={styles.emphasisedText}
          key={`cefrLevel${summaryData.mostReadLevel}${summaryData.averageProductsCompleted}`}
        >
          {summaryData.mostReadLevel}
        </h2>
      ]
    });
  }
  return returnObj;
};

const _rowHeightToImageSize = rowHeight => {
  if (rowHeight === 'wide') {
    return ThumbnailSizes.LARGE;
  }
  if (rowHeight === 'narrow') {
    return ThumbnailSizes.HEADING;
  }
  return ThumbnailSizes.SECTION;
};

export const formatTableRows = (data, tableSpacing) => {
  const products = Object.values(data)[0].products;

  return Object.entries(products).map(([id, product]) => {
    const wordCount = numeral(product.wordCount).format('0,0');
    const percentage = numeral(product.pagesRead / product.pagesAvailable).format('0.[0]%');
    const readingTime = moment.duration(product.readingTime).format('h [hrs] m [min]');
    const productImage = sanitizeUrl(product.url);
    const lastAccessedOrOpened =
      featureIsEnabled('replacing-last-accessed-with-last-opened') && product.lastOpened
        ? product.lastOpened
        : product.lastAccessed;

    return {
      id: `id${id}`,
      lastAccessed: lastAccessedOrOpened,
      cells: [
        <ContentWithThumbnail
          size={_rowHeightToImageSize(tableSpacing)}
          imageSrc={product.url ? productImage : null}
          squareImg
          key={`productThumb${lastAccessedOrOpened}${product.wordCount}`}
        >
          <p className={ReadersTableStyles.jumboBook}>{product.name ? product.name : 'No book title'}</p>
          <p className="sm-hide">
            <span className="pad-right2">{product.level}</span> {wordCount} words
          </p>
          <p className="sm-hide">
            <span className="pad-right2">{readingTime}</span> {percentage}
          </p>
        </ContentWithThumbnail>,
        formatLastAccessed(lastAccessedOrOpened),
        product.level,
        wordCount,
        percentage,
        readingTime
      ]
    };
  });
};
class GradedReadersLearnerView extends Component {
  constructor() {
    super();
    this.state = {
      sortConfig: null,
      columns: [
        { heading: 'Title' },
        { heading: featureIsEnabled('replacing-last-accessed-with-last-opened') ? 'Last opened' : 'Last accessed' },
        { heading: 'Level' },
        { heading: 'Words' },
        { heading: 'Reading completion' },
        { heading: 'Reading time' }
      ]
    };
  }

  _handleSort = (colHeading, descending) => {
    const { columns } = this.state;
    this.setState({
      sortConfig: {
        accessor: `cells.${columns.findIndex(column => column.heading === colHeading)}`,
        descending,
        cellAccessor: {}
      }
    });
  };

  render() {
    const CMS = content.gradedReadersLearnerView || {};
    const {
      tableLoading,
      switchSpacing,
      tableSpacing,
      data,
      learners,
      sortKey,
      sortDirection,
      sortOnChange
    } = this.props;

    if (tableLoading) {
      return <LoadingSpinner text={CMS.grade_reader_loading_text} />;
    }
    const currentUserData = Object.values(data)[0];
    const currentUserId = Object.keys(data)[0];
    const wordsRead = currentUserData.wordsRead.toLocaleString();
    const formattedHours = moment.duration(currentUserData.readingTime).format('h [Hours] m [Minutes]');
    const { lastOpened, lastAccessed } = currentUserData;
    const gradebookUrl = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/reader'));
    const { columns, sortConfig } = this.state;

    return (
      <div className="grid gin-top2">
        <div className="row">
          <div className="col">
            <span className={styles.hide}>
              <Breadcrumbs
                paths={[
                  {
                    pathname: `${gradebookUrl}`,
                    text: CMS.button_back_to_text
                  }
                ]}
              />
            </span>
            <span className={styles.showInline}>
              <Breadcrumbs
                paths={[
                  {
                    pathname: `${gradebookUrl}`,
                    text: CMS.button_back_to_text
                  }
                ]}
              />
            </span>
            <div className={styles.divFlex}>
              <h1 className={styles.flexMargin}>{CMS.grade_reader_title_text}</h1>
              <SpacingOptions onSwitch={switchSpacing} selected={tableSpacing} customClassName="gin-top1" />
            </div>
            <div className={styles.dropdownResponsive}>
              <LevelJumpDropdown levels={learners} selected={currentUserId} />
            </div>
            <div className={styles.borders}>
              <div className="row cols-left">
                <div className={classnames(styles.students__list, styles.hider__responsive)}>
                  <div className={classnames('col', styles.responsiveBorder)}>
                    <div className="row">
                      <div className="col">
                        <SortLabel
                          group="class-progress"
                          name="name"
                          customClassName={styles.boxFormat}
                          direction={sortKey === 'name' ? sortDirection : 'none'}
                          onClick={() => sortOnChange('name')}
                        >
                          Student
                        </SortLabel>
                      </div>
                      <div className="col">
                        {Object.keys(learners)
                          .sort(
                            (first, second) =>
                              (learners[first].firstname > learners[second].firstname ? 1 : -1) *
                              (sortDirection === 'desc' ? -1 : 1)
                          )
                          .map((learnerId, index) => (
                            <div key={index} role="link" className={styles.bottomBorder}>
                              <LinkWithIcon
                                id={learnerId}
                                key={learnerId}
                                text={`${learners[learnerId].firstname.toString()} ${learners[
                                  learnerId
                                ].lastname.toString()}`}
                                to={{
                                  pathname: `${gradebookUrl}/reader/${learnerId}`,
                                  search: window.location.search,
                                  hash: window.location.hash
                                }}
                                glyph={GLYPHS.ICON_RIGHT_THICK}
                                customClassName={styles.linkFormat}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classnames('grid gin-left1', styles.setTableWidth)}>
                  <div className="row">
                    <div className="col">
                      <div className={styles.flexTitle}>
                        <div className={styles.backgroundCenter}>
                          <h2>{toInitials(currentUserData.firstname, currentUserData.lastname)}</h2>
                        </div>
                        <div className={styles.iconLinkFormat}>
                          <LinkWithIcon
                            id="close"
                            text="Close"
                            glyph={GLYPHS.ICON_CLOSE_BOLD}
                            customClassName={styles.linkFormat}
                            to={`${gradebookUrl}/reader${window.location.search}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col gin-left2 gin-top2">
                      <dt>
                        {currentUserData.firstname} {currentUserData.lastname}
                      </dt>
                      <dd>{currentUserData.email}</dd>
                    </div>
                  </div>
                  <div className="row gin-top1 test">
                    <div className="col">
                      <dd>
                        {featureIsEnabled('replacing-last-accessed-with-last-opened') && lastOpened ? (
                          <div>
                            Last opened <em className={styles.blackText}>{formatLastAccessed(lastOpened)}</em>
                          </div>
                        ) : (
                          <div>
                            Last accessed <em className={styles.blackText}>{formatLastAccessed(lastAccessed)}</em>
                          </div>
                        )}
                      </dd>
                    </div>
                  </div>
                  <div className={classnames('row', 'cols-left', styles.largerWidth)}>
                    <div className={styles.hide}>
                      <TextWithData header="Books finished" subText={currentUserData.productsCompleted} />
                      <TextWithData header="Current level" subText={currentUserData.currentLevel} />
                      <TextWithData header="Words read" subText={wordsRead} />
                      <TextWithData header="Total reading time" subText={formattedHours} />
                    </div>
                    <div className={styles.show}>
                      <div>
                        <TextWithData header="Books finished" subText={currentUserData.productsCompleted} boldHeader />
                        <TextWithData header="Current level" subText={currentUserData.currentLevel} boldHeader />
                      </div>
                      <div>
                        <TextWithData header="Words read" subText={wordsRead} boldHeader />
                        <TextWithData header="Total reading time" subText={formattedHours} boldHeader />
                      </div>
                    </div>
                  </div>
                  <div className={classnames(styles.addScroll, 'row gin-top3')}>
                    <ReadersTable
                      columns={columns}
                      rows={tableSort(formatTableRows(data, tableSpacing), sortConfig)}
                      sortTable={this._handleSort}
                      sortConfig={sortConfig}
                      tableSpacing={tableSpacing}
                      isBreakpointAtTablet
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GradedReadersLearnerView.propTypes = {
  switchSpacing: PropTypes.func.isRequired,
  tableSpacing: PropTypes.oneOf(Object.values(SPACING_OPTIONS)),
  data: PropTypes.object.isRequired,
  learners: PropTypes.object,
  tableLoading: PropTypes.bool.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortOnChange: PropTypes.func.isRequired
};

export default GradedReadersLearnerView;
