import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import compose from '../../utils/compose/compose.js';
import withRouter from '../../utils/withRouter';
import { formStates, resetForm } from '../../redux/reducers/joinClass.reducer';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { postNotificationMessage } from '../../redux/actions/postMessage';
import { featureIsEnabled } from '../../globals/envSettings';
import JoinClassCodeInput from './JoinClassCodeInput';
import JoinPlacementTestCodeInput from './JoinPlacementTestCodeInput';
import JoinClassCodeSubmitting from './JoinClassCodeSubmitting';
import JoinClassCodeConfirmation from './JoinClassCodeConfirmation';
import withLocalizedContent from '../../language/withLocalizedContent';

class JoinClass extends React.Component {
  handleClose = () => {
    const { resetAction, closePanel, postMessageAction } = this.props;

    // sends post message when the class-joining user journey ends and the panel should be closed
    postMessageAction({ eventName: 'INTERACTION_COMPLETE', viewId: '*' });
    resetAction();
    closePanel();
  };

  handlePlacementCodeComplete = () => {
    const { joinedPlacementStatus, closePanel, errorOccurred, history } = this.props;

    closePanel();
    if (!errorOccurred) {
      history.push(`/myTasks/${joinedPlacementStatus.toLowerCase()}`);
    }
  };

  handleOnComplete = () => {
    const {
      resetAction,
      onComplete,
      closePanel,
      postMessageAction,
      initialiseSearch,
      errorOccurred,
      userId,
      currentOrganisationId
    } = this.props;

    // sends post message when the class-joining user journey ends and the panel should be closed
    postMessageAction({ eventName: 'INTERACTION_COMPLETE', viewId: '*' });
    resetAction();
    closePanel();
    initialiseSearch('profileClasses', false, {
      active: true,
      orgId: currentOrganisationId,
      userId
    });

    if (!errorOccurred && !!onComplete) {
      onComplete();
    }
  };

  handleOnValidationError = () => {
    const { resetAction } = this.props;
    resetAction();
  };

  render() {
    const {
      errorOccurred,
      errorMessage,
      formState,
      classroomName,
      localizedContent: { joinClass: content, joiningAndProductCodes },
      userRole,
      joinPlacementTestWithCodeModalOpen,
      joinedPlacementStatus
    } = this.props;
    let viewContent;
    const joiningAndProductCodesContent = featureIsEnabled('opt-main-features') ? joiningAndProductCodes : {};

    switch (formState) {
      case formStates.INPUTTING:
        viewContent = joinPlacementTestWithCodeModalOpen ? (
          <JoinPlacementTestCodeInput
            closePanel={this.handleClose}
            content={{
              ...content,
              ...joiningAndProductCodesContent
            }}
            userRole={userRole}
          />
        ) : (
          <JoinClassCodeInput
            closePanel={this.handleClose}
            content={{
              ...content,
              ...joiningAndProductCodesContent,
              enter_a_code_text: content.enter_a_code_text,
              primary_button_text: content.primary_button_text,
              enter_a_code_placeholder: content.enter_a_code_placeholder
            }}
            userRole={userRole}
          />
        );
        break;
      case formStates.SUBMITTING:
        viewContent = (
          <JoinClassCodeSubmitting
            content={{ ...content, ...(joinPlacementTestWithCodeModalOpen && joiningAndProductCodesContent) }}
          />
        );
        break;
      case formStates.CONFIRMATION:
        viewContent = (
          <JoinClassCodeConfirmation
            errorOccurred={errorOccurred}
            errorMessage={errorMessage}
            closePanel={this.handleClose}
            onComplete={joinedPlacementStatus ? this.handlePlacementCodeComplete : this.handleOnComplete}
            onValidationError={this.handleOnValidationError}
            classroomName={classroomName}
            content={{ ...content, ...joiningAndProductCodesContent }}
          />
        );
        break;
      default:
        viewContent = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return <div>{viewContent}</div>;
  }
}

export default compose(
  withRouter,
  withLocalizedContent('joinClass', 'joiningAndProductCodes'),
  connect(
    ({
      joinClass: {
        formState,
        classroomName,
        organisationName,
        groupType,
        errorOccurred,
        errorMessage,
        joinedPlacementStatus
      },
      identity: { userId, currentOrganisationId, role },
      hubUi: { joinPlacementTestWithCodeModalOpen }
    }) => ({
      formState,
      classroomName,
      organisationName,
      groupType,
      errorOccurred,
      errorMessage,
      userId,
      currentOrganisationId,
      userRole: role,
      joinPlacementTestWithCodeModalOpen,
      joinedPlacementStatus
    }),
    {
      resetAction: resetForm,
      postMessageAction: postNotificationMessage,
      initialiseSearch: initialiseInstance
    }
  )
)(JoinClass);

JoinClass.propTypes = {
  formState: PropTypes.string.isRequired,
  errorOccurred: PropTypes.bool,
  errorMessage: PropTypes.object,
  classroomName: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  resetAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  postMessageAction: PropTypes.func,
  initialiseSearch: PropTypes.func,
  userId: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  userRole: PropTypes.string,
  joinPlacementTestWithCodeModalOpen: PropTypes.bool,
  joinedPlacementStatus: PropTypes.string,
  history: PropTypes.object
};
