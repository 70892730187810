/* eslint-disable import/default */
/* eslint-disable import/namespace */
import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';

import userRoles from '../globals/userRoles';

import AppLayout from './AppLayout/AppLayout';
import OLBLayout from './OLBLayout/OLBLayout';
import IndexRedirectHandler from '../routes/IndexRedirectHandler';
import NotFoundView from '../views/errors/NotFoundView';

// List Pages
import MySchoolPage from '../routes/MySchool/MySchool';
import ClassroomPage from '../routes/ClassroomPage/ClassroomPage';
import MyProfilePage from '../routes/MyProfile/MyProfile';

import UserProfileInit from '../routes/UserProfile/UserProfileInitialising';

import CreateOrganisation from '../routes/CreateOrganisation/CreateOrganisation';

// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
import RegisterChoice from '../routes/RegisterChoice/RegisterChoice';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from '../routes/ResetPassword/ResetPassword';
import InvitationUpdatePage from '../routes/Register/Invites/InvitationUpdatePage';
import OfflineAppSuccessPage from '../routes/OfflineAppSuccessPage/OfflineAppSuccessPage';

// Change username
import ChangeUsernamePage from '../routes/ChangeUsername/ChangeUsernamePage';

// Support pages
import SupportHomePage from '../routes/Support/SupportHomePage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';
import GeneralTermsAndConditions from '../routes/GeneralTermsAndConditions/GeneralTermsAndConditions';

// Product Download Links Page
import GenerateDownloadLinksPage from '../routes/ProductPage/GenerateDownloadLinksPage.js';

// ORB pages
import ORBLayout from './ORBLayout/ORBLayout';
import OrbClassReportPage from '../routes/development/ORB/OrbClassReportPage';
import OrbTeacherResourcesPage from '../pages/OrbTeacherResourcesPage';
import OrbOfflineAppPage from '../pages/OrbOfflineAppPage';
import OrbTeacherContactPreferencesPage from '../pages/OrbTeacherContactPreferencesPage/OrbTeacherContactPreferencesPage';

// Example pages
import ExampleHomePage from '../routes/Example/ExamplePage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';

// Test page
import TestPage from '../routes/TestPage/TestPage';

// OALD Redeem-code pages
import RedeemCodePage from '../routes/RedeemCode/RedeemCodePage';
import RedeemCodeSuccessPage from '../routes/RedeemCode/RedeemCodeSuccessPage';

import StudentAnswerViewPage from '../routes/Gradebook/StudentAnswerViewPage';

import PlatformRegistrationPage from '../routes/PlatformRegistration/PlatformRegistrationPage';

import protectedRoute from './protectedRoute';
import restrictByOrgUser from './restrictByOrgUser';
import withAuthorization from '../authorization/withAuthorization';
import {
  isAdminOrSupport,
  isAdminSupportOrContent,
  isELTReviewer,
  isLoggedIn
} from '../redux/selectors/authorization/user';
import { limitToOrgRoles } from '../redux/selectors/authorization/organization';
import ProductUploadPage from '../pages/dashboard/ProductUpload/ProductUploadPage';
import ProductPublishPage from '../pages/dashboard/ProductPublish/ProductPublishPage';
import GenerateReportPage from '../pages/dashboard/Reports/GenerateReportPage';
import InvoiceReportPage from '../pages/dashboard/Reports/InvoiceReportPage';
import AdminProductsPage from '../components/OrganisationAdmin/AdminProductsPage.js';
import AdminCoursesPage from '../components/OrganisationAdmin/AdminCoursesPage.js';
import ContentPreviewPage from '../pages/dashboard/content/ContentPreviewPage/ContentPreviewPage.js';
import LorContentPage from '../pages/dashboard/content/LorContentPage/LorContentPage';
import EltGamesContentPage from '../pages/dashboard/content/EltGamesContent/EltGamesContentPage.js';
import EpsContentPage from '../pages/dashboard/content/EpsContentPage/EpsContentPage';
import EpsContentArea from '../pages/dashboard/content/EpsContentArea/EpsContentArea';
import ActivationCodeContentPage from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeContentPage.js';

import ActivationCodeSearchArea from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeSearchArea.js';
import ActivationCodeCreateArea from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeCreateArea.js';
import ActivationCodeDeleteArea from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeDeleteArea.js';

import ProductGroupSearchPage from '../pages/dashboard/content/ProductGroupSearchPage/ProductGroupSearchPage.js';
import ActivationCodeDetailPage from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeDetailPage.js';
import StructuredContentPlayerBlankPage from '../routes/BlankPage/StructuredContentPlayerBlankPage.js';
import CourseContentPreviewLayout from './CourseContentLayout/CourseContentPreviewLayout.js';
import CourseUploadPage from '../pages/dashboard/content/CourseUpload/CourseUploadPage.js';
import CoursePreviewPage from '../pages/dashboard/content/CoursePreview/CoursePreviewPage.js';
import CourseStagePage from '../pages/dashboard/content/CourseStage/CourseStagePage.js';
import CoursePublishPage from '../pages/dashboard/content/CoursePublish/CoursePublishPage.js';
import AssetsPage from '../pages/dashboard/content/AssetsPage/AssetsPage.js';
import EltCoreProductBuilder from '../pages/dashboard/content/EltCoreProductBuilder/EltCoreProductBuilder.js';
import PreviewELTCoreProduct from '../pages/dashboard/content/PreviewELTCoreProduct/PreviewELTCoreProduct.js';
import PreviewELTBookProduct from '../pages/dashboard/content/PreviewELTBookProduct/PreviewELTBookProduct.js';
import UploadProductStructure from '../pages/dashboard/content/UploadProductStructure/UploadProductStructure.js';
import ActivationCodeBatchPage from '../pages/dashboard/content/ActivationCodeBatchPage/ActivationCodeBatchPage.js';
import ProductCreatePage from '../pages/dashboard/content/ProductCreationPage/ProductCreatePage.js';
import UserAdminDashboardPage from '../pages/dashboard/content/UsersAdminDashboardPage/UserAdminDashboardPage.js';
import UserAdminSearch from '../pages/dashboard/content/UsersAdminDashboardPage/UserAdminSearch';
import PreSeedingOrganisationPage from '../pages/dashboard/PreSeedingOrganisation/PreSeedingOrganisationPage';
import EpsAdminOPS from '../pages/dashboard/EpsAdminOPS/EpsAdminOPS';
import RegisterOrgDashboardPage from '../pages/dashboard/RegisterOrg/RegisterOrgPage';
import ActCodesBatchDashboard from '../pages/dashboard/content/ActivationCodeBatchPage/components/ActCodesBatchDashboard';
import ActivationCodeBatchSearch from '../pages/dashboard/EpsAdminOPS/ActivationCodeBatchSearch/ActivationCodeBatchSearch';
import CourseMaterial from './HubProductLayout/CourseMaterial/CourseMaterial';
import ReCAPTCHAExample from '../routes/ReCAPTCHAExample/ReCAPTCHAExample';
import EltBuilder from '../pages/dashboard/content/EltBuilder/EltBuilder.js';
import EltBookBuilder from '../pages/dashboard/content/EltBookBuilder/EltBookBuilder.js';

import {
  ActivityPage,
  ManagedUserLoginPage,
  OrganisationAdminPage,
  RegisterOrgAdminPage,
  ItalyTeacherResourcesPage,
  CreateOrgSubscriptionPage,
  UpdateOrgSubscriptionPage,
  CsvUserUploadPage,
  CsvProductStructureUploadPage,
  EmailToolsPage,
  OicEmailToolsPage,
  SystemNotificationPage,
  RemoveUserLicencePage,
  OicCreateTestCreditsPage,
  OicLinkOrgsToJanisonPage,
  UserImpersonationPage,
  OrganizationsPage,
  OrganizationPage,
  RegisterOrgPage,
  LegacyRegisterOrgPage
} from '../pages';

import DownloadSigninCardProcess from '../components/DownloadSigninCardProcess/DownloadSigninCardProcess';
// show invitations
import ConnectedBannerInvitation from '../components/BannerInvitation/ConnectedBannerInvitation';

// Decline Org Invitation from Email
import DeclineOrgInvitation from '../routes/DeclineOrgInvitation/DeclineOrgInvitation';
import OrbRegionDemoPage from '../routes/development/ORB/OrbRegionDemoPage';
import OrbRegionDemoRoot from '../routes/development/ORB/OrbRegionDemoRoot';
// start up pages
import StartTips from '../routes/ORBFirstTimeVisitPages/StartTips';
// Join a class page
import JoinClassPage from '../routes/JoinClass/JoinClassPage';
// Edit Panel page for iframe
import MyProfileEditPanel from '../routes/EditAccount/MyProfileEditPanelPage';
// Redirect to Auth0
import LoginPage from '../routes/LoginPage/LoginPage';

import LaunchTokenLogoutPage from '../routes/LaunchTokenLogoutPage/LaunchTokenLogoutPage';

import SEBSignedOut from '../routes/SEB/SEBSignedOut';
import SEBSignedIn from '../routes/SEB/SEBSignedIn';
import { featureIsEnabled } from '../globals/envSettings';
import ProductAdminDashboardPage from '../pages/dashboard/content/ProductsAdminDashboardPage/ProductAdminDashboardPage.js';
import ProductSearchPage from '../pages/dashboard/content/ProductSearchPage/ProductSearchPage.js';
import EReaderContentPage from '../pages/dashboard/content/EReaderContentPage/EReaderContentPage';
import EreaderContentPlayerBlankPage from '../routes/BlankPage/EreaderContentPlayerBlankPage';
import LtiLicenceCreditsPage from '../pages/LtiLicenceCreditsPage.js';

const hasToken = () => {
  const result = window.location.pathname.match(/register.(\S*)/);
  if (result && result.length > 1 && result[1].length > 30) {
    return true;
  }

  return false;
};
// console.debug('In routeManagerEdu.js - showDevelopmentPages is set to <%s>', showDevelopmentPages);

function RouteManager() {
  const getRegisterOrgPageComponent = () =>
    featureIsEnabled('register-org-restyle') ? <RegisterOrgPage /> : <LegacyRegisterOrgPage />;

  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Routes>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank" element={<BlankPage />} />

          {/* OLB home page */}

          <Route
            path="/embedded/olb-homepage"
            element={
              <OLBLayout>
                <TestPage />
              </OLBLayout>
            }
          />

          {/* Logged out status page for LTI Tokens and Impersonation tokens */}
          <Route
            path="/logged-out"
            element={
              <AppLayout>
                <LaunchTokenLogoutPage />
              </AppLayout>
            }
          />

          {/* redirect to Auth0 */}
          <Route path="/login" element={<LoginPage />} />

          {/* ORB Demo Pages */}
          <Route
            path="/orb-region"
            element={
              <OrbRegionDemoRoot>
                <OrbRegionDemoPage />
              </OrbRegionDemoRoot>
            }
          />

          <Route
            path="/"
            element={
              <AppLayout>
                <IndexRedirectHandler />
              </AppLayout>
            }
          />

          <Route
            path="/recaptcha-example"
            element={
              <AppLayout>{withAuthorization(ReCAPTCHAExample, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/terms-and-conditions/all"
            element={
              <AppLayout>
                <GeneralTermsAndConditions />
              </AppLayout>
            }
          />

          {/* ORB routes */}
          <Route
            path="/dashboard/org/register"
            element={
              <AppLayout>{withAuthorization(RegisterOrgDashboardPage, isAdminOrSupport, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard/org/:orgId?/licence"
            element={
              <AppLayout>{withAuthorization(CreateOrgSubscriptionPage, isAdminOrSupport, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard/org/:orgId?/update-licence/:licenceId?"
            element={
              <AppLayout>{withAuthorization(UpdateOrgSubscriptionPage, isAdminOrSupport, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard/org/:orgId?/test-credits"
            element={
              <AppLayout>{withAuthorization(OicCreateTestCreditsPage, isAdminOrSupport, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard/org/:orgId?/licence-credits"
            element={<AppLayout>{withAuthorization(LtiLicenceCreditsPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/org/link-oic-orgs-to-janison"
            element={
              <AppLayout>{withAuthorization(OicLinkOrgsToJanisonPage, isAdminOrSupport, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard/org/organizations"
            element={<AppLayout>{withAuthorization(OrganizationsPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/org/:orgId?/organizations"
            element={<AppLayout>{withAuthorization(OrganizationPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/org/admin/register"
            element={<AppLayout>{withAuthorization(RegisterOrgAdminPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/user/impersonate"
            element={<AppLayout>{withAuthorization(UserImpersonationPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/org/pre-seeding-organisations"
            element={
              <AppLayout>{withAuthorization(PreSeedingOrganisationPage, isAdminOrSupport, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard/user/remove-licence"
            element={<AppLayout>{withAuthorization(RemoveUserLicencePage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/csv-user-data-upload"
            element={<AppLayout>{withAuthorization(CsvUserUploadPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/email-tools"
            element={<AppLayout>{withAuthorization(EmailToolsPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/oic-email-tools"
            element={<AppLayout>{withAuthorization(OicEmailToolsPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard/system-notification"
            element={<AppLayout>{withAuthorization(SystemNotificationPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/dashboard"
            element={<AppLayout>{withAuthorization(OrganisationAdminPage, isAdminOrSupport, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/products/oup-data-publish"
            element={
              <AppLayout>{withAuthorization(ProductPublishPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/eltcore-builder/oup-data-upload"
            element={
              <AppLayout>
                <ProductUploadPage selectedPlatformCode="ELTCORE" />
              </AppLayout>
            }
          />
          <Route
            path="/reports/generate-report"
            element={
              <AppLayout>{withAuthorization(GenerateReportPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/reports/invoice-report"
            element={
              <AppLayout>{withAuthorization(InvoiceReportPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/products/oup-data-upload"
            element={
              <AppLayout>{withAuthorization(ProductUploadPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/courses/course-preview/:courseId/resourceFolder/:folder/:subFolder"
            element={
              <AppLayout>{withAuthorization(CourseContentPreviewLayout, isELTReviewer, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/courses/course-preview/:courseId/courseMaterial"
            element={
              <AppLayout>
                <CourseContentPreviewLayout>
                  {withAuthorization(CourseMaterial, isELTReviewer, '/myProfile')}
                </CourseContentPreviewLayout>
              </AppLayout>
            }
          />
          <Route
            path="/courses/course-preview/:courseId"
            element={
              <AppLayout>{withAuthorization(CourseContentPreviewLayout, isELTReviewer, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/courses/oup-data-upload"
            element={
              <AppLayout>{withAuthorization(CourseUploadPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/courses/preview"
            element={<AppLayout>{withAuthorization(CoursePreviewPage, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/courses/stage"
            element={<AppLayout>{withAuthorization(CourseStagePage, isAdminSupportOrContent, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/courses/publish"
            element={
              <AppLayout>{withAuthorization(CoursePublishPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/products/csv-product-structure-upload"
            element={
              <AppLayout>
                {withAuthorization(CsvProductStructureUploadPage, isAdminSupportOrContent, '/myProfile')}
              </AppLayout>
            }
          />
          <Route
            path="/products"
            element={
              <AppLayout>{withAuthorization(AdminProductsPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/courses"
            element={<AppLayout>{withAuthorization(AdminCoursesPage, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/eltcore-builder/uploadProductStructure"
            element={
              <AppLayout>{withAuthorization(UploadProductStructure, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/eltcore-builder/preview"
            element={<AppLayout>{withAuthorization(PreviewELTCoreProduct, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/eltcore-builder"
            element={<AppLayout>{withAuthorization(EltCoreProductBuilder, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/eltbook-builder/uploadProductStructure"
            element={
              <AppLayout>{withAuthorization(UploadProductStructure, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/eltbook-builder/preview"
            element={<AppLayout>{withAuthorization(PreviewELTBookProduct, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/eltbook-builder"
            element={<AppLayout>{withAuthorization(EltBookBuilder, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/elt-builder"
            element={<AppLayout>{withAuthorization(EltBuilder, isELTReviewer, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/oup/content/resources/lor"
            element={<AppLayout>{withAuthorization(LorContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/oup/content/resources/eps"
            element={<AppLayout>{withAuthorization(EpsContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/oup/content/resources/elt_games"
            element={
              <AppLayout>{withAuthorization(EltGamesContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/oup/content/resources/ereader"
            element={
              <AppLayout>{withAuthorization(EReaderContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_codes"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeContentPage,
                  state => isAdminSupportOrContent(state) && featureIsEnabled('enable-eps-admin-console'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_code_batch"
            element={
              <AppLayout>
                {withAuthorization(
                  ActCodesBatchDashboard,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-batch'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_code_batch_create"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeBatchPage,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-batch'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_code_batch_search"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeBatchSearch,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-batch'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_codes_search"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeSearchArea,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-search'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_codes_create"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeCreateArea,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-create'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/act_codes_delete"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeDeleteArea,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-delete'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/ac_detail/:actCode"
            element={
              <AppLayout>
                {withAuthorization(
                  ActivationCodeDetailPage,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-act-code-detail'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />

          <Route
            path="/dashboard_eps/product_groups"
            element={
              <AppLayout>
                {withAuthorization(
                  ProductGroupSearchPage,
                  state => isAdminSupportOrContent(state) && featureIsEnabled('eps-admin-product-group-search'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/user_dashboard"
            element={
              <AppLayout>
                {withAuthorization(
                  UserAdminDashboardPage,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('eps-admin-user-console'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/user_dashboard_search"
            element={
              <AppLayout>
                {withAuthorization(
                  UserAdminSearch,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('eps-admin-user-console') &&
                    featureIsEnabled('eps-admin-user-search'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/product_dashboard"
            element={
              <AppLayout>
                {withAuthorization(
                  ProductAdminDashboardPage,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-product-creation'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/product_dashboard_create"
            element={
              <AppLayout>
                {withAuthorization(
                  ProductCreatePage,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-product-creation'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/product_dashboard_search"
            element={
              <AppLayout>
                {withAuthorization(
                  ProductSearchPage,
                  state =>
                    isAdminSupportOrContent(state) &&
                    featureIsEnabled('enable-eps-admin-console') &&
                    featureIsEnabled('enable-eps-admin-product-creation'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/dashboard_eps/eps_ops"
            element={
              <AppLayout>
                {withAuthorization(
                  EpsAdminOPS,
                  state => isAdminSupportOrContent(state) && featureIsEnabled('enable-eps-admin-console'),
                  '/myProfile'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/oup/content/resources/eps-content/area/products"
            element={<AppLayout>{withAuthorization(EpsContentArea, isAdminSupportOrContent, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/oup/content/resources/navigator/:contentCode"
            element={
              <AppLayout>{withAuthorization(StructuredContentPlayerBlankPage, isELTReviewer, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/launch/:role/:contentCode"
            element={
              <AppLayout>{withAuthorization(StructuredContentPlayerBlankPage, isELTReviewer, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/launch-ereader/book/:book/theme/:theme"
            element={
              <AppLayout>{withAuthorization(EreaderContentPlayerBlankPage, isELTReviewer, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/oup/content/resources"
            element={
              <AppLayout>{withAuthorization(ContentPreviewPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/oup/content/assets"
            element={<AppLayout>{withAuthorization(AssetsPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>}
          />
          <Route
            path="/org/register"
            element={<AppLayout>{restrictByOrgUser(getRegisterOrgPageComponent)}</AppLayout>}
          />
          <Route
            path="/org/:orgId/complete-registration"
            element={
              <AppLayout>
                <LegacyRegisterOrgPage />
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId/activity"
            element={
              <AppLayout>
                <ActivityPage />
              </AppLayout>
            }
          />
          <Route
            path="/auth/managedUser"
            element={
              <AppLayout>
                <ManagedUserLoginPage />
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId?/classroom/:classroomId?/student/:studentId?"
            element={
              <AppLayout embedded>
                <OrbClassReportPage />
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId?/classroom/:classroomId?"
            element={
              <AppLayout embedded>
                <OrbClassReportPage />
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId?/school"
            element={
              <AppLayout embedded>
                <OrbClassReportPage />
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId?/multi-school-report"
            element={
              <AppLayout embedded>
                <OrbClassReportPage />
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/reporting/:orgId?/classroom/:classroomId?/student/:studentId?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/reporting/:orgId?/classroom/:classroomId?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/reporting/:orgId?/multi-school-report?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/reporting/:orgId?/school?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/library"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbTeacherResourcesPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/offlineApp"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbOfflineAppPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/teacherHome/contactPreferences"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbTeacherContactPreferencesPage />
                </ORBLayout>
              </AppLayout>
            }
          />
          <Route
            path="/orb/teacher/reports/:orgId?/classroom/:classroomId?/:tabName?/:objectId?"
            element={
              <AppLayout embedded>
                <OrbClassReportPage />
              </AppLayout>
            }
          />
          <Route
            path="/orb/teacher/reports/:orgId?/:tabName?/:objectId?"
            element={
              <AppLayout embedded>
                <OrbClassReportPage />
              </AppLayout>
            }
          />
          <Route
            path="/register/:token?"
            element={
              <AppLayout>
                {protectedRoute(RegisterPage, false, Object.keys(userRoles), '/myProfile', false, hasToken())}
              </AppLayout>
            }
          />
          <Route
            path="/register-choice"
            element={
              <AppLayout>
                <RegisterChoice />
              </AppLayout>
            }
          />
          <Route
            path="/offlineApp/:actionType"
            element={
              <AppLayout>
                <OfflineAppSuccessPage />
              </AppLayout>
            }
          />
          <Route
            path="/invitation"
            element={
              <AppLayout>
                <InvitationUpdatePage />
              </AppLayout>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <AppLayout>
                <ResetPassword />
              </AppLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AppLayout>
                <RequestPasswordReset />
              </AppLayout>
            }
          />
          <Route
            path="/change-username"
            element={
              <AppLayout>
                <ChangeUsernamePage />
              </AppLayout>
            }
          />
          <Route
            path="/mySchool/:tabName?/:panelName?"
            element={
              <AppLayout>
                {protectedRoute(
                  MySchoolPage,
                  false,
                  [userRoles.USER, userRoles.LEARNER],
                  '/myProfile',
                  false,
                  false,
                  'MY_ORG'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId/user/:userId/:tabName?/:panelName?"
            element={
              <AppLayout>
                {protectedRoute(UserProfileInit, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
              </AppLayout>
            }
          />

          {/* Download Signin Card Process */}
          <Route
            path="/org/:orgId/batchId/:batchId/records/:records"
            element={
              <AppLayout>
                <DownloadSigninCardProcess />
              </AppLayout>
            }
          />

          <Route
            path="/org/:orgId/class/:classroomId/:tabName?/:panelName?/:itemId?/:learnerId?"
            element={
              <AppLayout>
                {protectedRoute(ClassroomPage, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
              </AppLayout>
            }
          />

          <Route
            path="/org/:orgId/:tabName?/:panelName?"
            element={<AppLayout>{withAuthorization(MySchoolPage, limitToOrgRoles(), '/')}</AppLayout>}
          />

          <Route
            path="/myProfile/:tabName?/:panelName?/:itemId?"
            element={
              <AppLayout>
                <MyProfilePage />
              </AppLayout>
            }
          />

          {/* Create Org Page */}
          <Route
            path="/createOrganisation"
            element={
              <AppLayout>
                <CreateOrganisation />
              </AppLayout>
            }
          />

          {/* Support pages */}
          <Route
            path="/support/articles/:pageName?"
            element={
              <AppLayout>
                <SupportArticlePage />
              </AppLayout>
            }
          />

          <Route
            path="/support/:tabName?"
            element={
              <AppLayout>
                <SupportHomePage />
              </AppLayout>
            }
          />

          {/* /:platform/:token? */}
          <Route
            path="/download/:platform/:token?"
            element={
              <AppLayout>
                <GenerateDownloadLinksPage />
              </AppLayout>
            }
          />

          {/* Italy Teacher Resource Panel */}
          <Route
            path="/premium/resources/:titleId"
            element={
              <AppLayout embedded>
                <ItalyTeacherResourcesPage />
              </AppLayout>
            }
          />

          {/* Example page */}
          <Route path="/example/:tabName?" element={<ExampleHomePage />} />

          <Route
            path="/redeem/code"
            element={
              <AppLayout>
                <RedeemCodeSuccessPage />
              </AppLayout>
            }
          />

          <Route
            path="/redeem"
            element={
              <AppLayout>
                {protectedRoute(RedeemCodePage, false, Object.keys(userRoles), '/myProfile/learningMaterial/addCode')}
              </AppLayout>
            }
          />

          {/* Decline Org Invitation */}
          <Route
            path="/decline-invite/:token"
            element={
              <AppLayout>
                <DeclineOrgInvitation />
              </AppLayout>
            }
          />

          {/* Show Invitations */}
          <Route
            path="/showInvitations"
            element={
              <AppLayout>
                <ConnectedBannerInvitation />
              </AppLayout>
            }
          />

          <Route
            path="/studentAnswerView/orgId/:orgId/class/:classroomId/:itemId/:learnerId/:bid/:uId/:activityId/:parentUId?"
            element={
              <AppLayout>
                <StudentAnswerViewPage />
              </AppLayout>
            }
          />

          <Route
            path="/orb-start-tips/:step?"
            element={
              <AppLayout>
                <StartTips />
              </AppLayout>
            }
          />

          <Route
            path="/join-class"
            element={<AppLayout>{protectedRoute(JoinClassPage, false, [userRoles.LEARNER], '/join-class')}</AppLayout>}
          />

          <Route
            path="/edit-account"
            element={
              <AppLayout>
                {protectedRoute(MyProfileEditPanel, false, Object.keys(userRoles), '/edit-account')}
              </AppLayout>
            }
          />

          <Route
            path="/seb-login"
            element={
              <AppLayout>
                {protectedRoute(SEBSignedOut, false, Object.keys(userRoles), '/seb-download', false, hasToken())}
              </AppLayout>
            }
          />

          <Route
            path="/seb-download"
            element={
              <AppLayout>
                <SEBSignedIn />
              </AppLayout>
            }
          />

          <Route
            path="/platform-registration/:platform"
            element={
              <AppLayout>
                {withAuthorization(
                  PlatformRegistrationPage,
                  () => isLoggedIn && featureIsEnabled('otc-registration'),
                  '/platform-registration'
                )}
              </AppLayout>
            }
          />

          {/* 404 */}
          <Route
            path="*"
            element={
              <AppLayout>
                <NotFoundView />
              </AppLayout>
            }
          />

          {/* Deprecated NGS Pages - On hold - Will require updating to RR 5.x to work again */}
          {/* <Route path="ngs" component={SmartIndexRedirectHandler} />
          <Route component={NGSRoot}>
            <Route path="ngs-demo" component={NGSDemoLoginPage} />

            <Route path="ngs/invitation" context="NGS" component={InvitationUpdatePage} />
            <Route
              path="ngs/myProfile(/:tabName(/:panelName(/:itemId)))"
              component={withAuthorization(isAtLeastManagedUser, '/')(MyProfilePage)}
            />
            <Route
              path="ngs/mySchool(/:tabName(/:panelName))"
              context="MY_ORG"
              component={withAuthorization(isAtLeastTeacher, '/')(MySchoolPage)}
            />

            <Route path="ngs/org/:orgId/activity" component={ActivityPage} />

            <Route
              path="ngs/teacher/assignments"
              component={withAuthorization(isAtLeastTeacher, '/ngs')(NGSTeacherAssignmentsPage)}
            />
            <Route
              path="ngs/teacher/reports"
              component={withAuthorization(isAtLeastTeacher, '/ngs')(NGSTeacherReportsPage)}
            />
            <Route
              path="ngs/student/homework"
              component={withAuthorization(isManagedUserOrLearner, '/ngs')(NGSStudentHomeworkPage)}
            />
            <Route
              path="ngs/student/marks"
              component={withAuthorization(isManagedUserOrLearner, '/ngs')(NGSStudentMarksPage)}
            />
            <Route path="ngs/courses" component={NGSCourseLayout}>
              <Route path=":id" component={withAuthorization(isAtLeastManagedUser, '/')(NGSCourseHomePage)} />
              <Route
                path=":id/resources"
                component={withAuthorization(isAtLeastManagedUser, '/')(NGSCourseResourcesPage)}
              />

              <Route path=":id/reports" component={withAuthorization(isAtLeastTeacher, '/')(NGSCourseReportsPage)} />
              <Route
                path=":id/homework"
                component={withAuthorization(isManagedUserOrLearner, '/')(NGSCourseHomeworkPage)}
              />
              <Route path=":id/marks" component={withAuthorization(isManagedUserOrLearner, '/')(NGSCourseMarksPage)} />

              <Route path=":id/books/:bookId" component={NGSCourseBookPage} />
              <Route path=":id/resources/:resourceId" component={NGSCourseResourcePage} />
            </Route>
          </Route> */}
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
