import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import actions from '../../redux/actions';
import { mode as opMode } from '../../redux/reducers/oicTestCredits.reducer.js';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Badge from '../Badge/Badge';
import colors from '../../globals/colors';
import styles from './ShowOicTestCredits.scss';

function ShowOicTestCredits({ orgId, productId, getOicTestCredits, products = {} }) {
  const { formData, mode } = products[productId] || {};

  useEffect(() => {
    getOicTestCredits({ orgId, productId });
  }, []);

  return mode === opMode.LOADING ? (
    <SVGIcon className={styles.spinner} glyph={GLYPHS.ICON_LOADING} />
  ) : (
    <Badge backgroundColor={colors.PRIMARY_BLUE} value={formData?.testCredits ?? 0} />
  );
  //
}

ShowOicTestCredits.propTypes = {
  orgId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  products: PropTypes.object.isRequired,
  getOicTestCredits: PropTypes.func.isRequired
};

export default compose(
  connect(
    state => ({
      ...pick(state.oicTestCredits, ['products'])
    }),
    dispatch => ({
      getOicTestCredits: input => dispatch(actions.getOicTestCreditsRequest(input))
    })
  )
)(ShowOicTestCredits);
