import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ICON_LEVEL_ONE_UP } from '@oup/shared-front-end/svg/oup';
import { Button } from '@oup/shared-front-end';
import withLocalizedContent from '../../language/withLocalizedContent';
import useMediaQuery from '../../utils/mediaQuery';

function GradebookBackButton({ localizedContent: { classProgress: localizedContent }, pathname = undefined, paths }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const goBack = () => {
    if (pathname) {
      navigate(pathname);
      return;
    }

    const newUrl = `${paths[paths.length - 2].pathname}${paths[paths.length - 2].hash || ''}`;
    navigate(newUrl);
  };

  return (
    <Button
      text={isMobile ? '' : localizedContent.back_button}
      variant="outline"
      size="base"
      icon={{ component: <ICON_LEVEL_ONE_UP /> }}
      onClick={() => goBack()}
    />
  );
}

GradebookBackButton.propTypes = {
  pathname: PropTypes.string,
  paths: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('classProgress')(GradebookBackButton);
