import { takeLatest } from 'redux-saga/effects';
import { GET_ELT_GAMES_REQUEST } from '../../../../actionTypes';

import getEltGames from './getEltGames.js';

function* root() {
  yield takeLatest(GET_ELT_GAMES_REQUEST, ({ payload }) => getEltGames(payload));
}

export default root;
