import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../../../../utils/compose/compose.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';

function EnrolUserBulkConfirmation({
  onComplete,
  closePanel,
  email,
  localizedContent: { enrolUserBulkPage: content }
}) {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
            </div>
            <PopoutPanelIconHeading type="PROCESSING" title={content.processing_heading_text} />
          </div>
        }
        footerContent={
          <PopoutActionFooter primaryButtonAction={onComplete} primaryButtonText={content.complete_panel_text} />
        }
      >
        <div>
          <div className="gin-top2 gin-left4 gin-right4" style={{ textAlign: 'center' }}>
            <p className="gin-top4">{content.processing_message_info_intro_text}</p>
            <p id="user-email" className="gin-top4">
              {content.processing_message_email_intro_text}
            </p>
            <p style={{ padding: '1rem', backgroundColor: '#b3aaaa' }}>
              <em>{email}</em>
            </p>
            <p>{content.processing_message_email_outro_text}</p>
            <p className="gin-top4">{content.processing_message_info_outro_text}</p>
          </div>
        </div>
      </ScrollContainer>
    </form>
  );
}

EnrolUserBulkConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('enrolUserBulkPage'),
  connect(state => ({
    email: state.identity.email
  }))
)(EnrolUserBulkConfirmation);
