import { pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import withRouter from '../../../../utils/withRouter';
import compose from '../../../../utils/compose/compose.js';
import Badge from '../../../../components/Badge/Badge';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel';
import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import SearchStatus from '../../../../components/SearchStatus/SearchStatus';
import SlideupPanel from '../../../../components/SlideupPanel/SlideupPanel';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import colors from '../../../../globals/colors';
import { searchUserSortOptions } from '../../../../globals/searchFilters';
import userRoles, { roleCanArchiveOupUser, roleCanRegisterOupUser } from '../../../../globals/userRoles';
import UserArchivePanel from '../../../../panels/UserArchivePanel';
import { closeForm, setStaffToArchive, showClosePanelModal } from '../../../../redux/reducers/archiveUsers.reducer';
import { resetJourney } from '../../../../redux/reducers/assignLearningMaterial.reducer';
import { setFilter, setPage, setSort, setTerm, triggerSearch } from '../../../../redux/reducers/data/search.reducer';
import {
  setOupAdminSelected,
  setOupSupportSelected,
  setOupContentSelected,
  setOupContentReviewerSelected,
  setExternalContentReviewerSelected
} from '../../../../redux/reducers/institutionPage.reducer';
import { USER_ARCHIVED_STATUS } from '../../../../utils/archiveStatuses';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks';
import Link from '../../../../components/Link/Link';
import UserListing, { ListingTypes } from '../../../../components/UserListing/UserListing';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import EnrolUser from './panels/EnrolUser/EnrolUser';

class OupStaffTab extends React.Component {
  _handleRemoveClick = userId => {
    const { history, setStaffToArchiveAction } = this.props;
    history.push(safePanelLink('archiveUsers'));
    setStaffToArchiveAction([userId]);
  };

  _handleOupAdminSelect = userId => {
    const { setOupAdminSelectedAction, selectedOupAdminIds } = this.props;
    setOupAdminSelectedAction(userId, !selectedOupAdminIds.includes(userId));
  };

  _handleOupSupportSelect = userId => {
    const { setOupSupportSelectedAction, selectedOupSupportIds } = this.props;
    setOupSupportSelectedAction(userId, !selectedOupSupportIds.includes(userId));
  };

  _handleOupContentSelect = userId => {
    const { setOupContentSelectedAction, selectedOupContentIds } = this.props;
    setOupContentSelectedAction(userId, !selectedOupContentIds.includes(userId));
  };

  _handleOupContentReviewerSelect = userId => {
    const { setOupContentReviewerSelectedAction, selectedOupContentReviewerIds } = this.props;
    setOupContentReviewerSelectedAction(userId, !selectedOupContentReviewerIds.includes(userId));
  };

  _handleExternalContentSelect = userId => {
    const { setExternalReviewerSelectedAction, selectedExternalReviewerIds } = this.props;
    setExternalReviewerSelectedAction(userId, !selectedExternalReviewerIds.includes(userId));
  };

  _handleItemLabelClick = userId => {
    const { history, orgId } = this.props;
    history.push(`/org/${orgId}/user/${userId}`);
  };

  _renderItems = () => {
    const {
      people,
      authUserId,
      oupStaffDataRecency,
      authUserRole,
      oupAdminIds,
      oupSupportIds,
      oupContentIds,
      oupContentReviewerIds,
      externalContentIds,
      selectedOupAdminIds,
      selectedOupSupportIds,
      selectedOupContentIds,
      selectedOupContentReviewerIds,
      selectedExternalReviewerIds,
      localizedContent: { mySchoolOupStaffTab: content }
    } = this.props;

    return [
      {
        ids: oupAdminIds,
        heading: content.heading_admin_staff,
        selected: selectedOupAdminIds,
        onItemSelect: this._handleOupAdminSelect
      },
      {
        ids: oupSupportIds,
        heading: content.heading_support_staff,
        selected: selectedOupSupportIds,
        onItemSelect: this._handleOupSupportSelect
      },
      {
        ids: oupContentIds,
        heading: content.heading_content_staff,
        selected: selectedOupContentIds,
        onItemSelect: this._handleOupContentSelect
      },
      {
        ids: oupContentReviewerIds,
        heading: content.heading_content_reviewer_staff,
        selected: selectedOupContentReviewerIds,
        onItemSelect: this._handleOupContentReviewerSelect
      },
      {
        ids: externalContentIds,
        heading: content.heading_external_content_reviewer_staff,
        selected: selectedExternalReviewerIds,
        onItemSelect: this._handleExternalContentSelect
      }
    ].map(({ ids, heading, selected, onItemSelect }) =>
      ids.length ? (
        <UserListing
          key={heading}
          className="gin-top2"
          heading={heading}
          authUserId={authUserId}
          listingType={ListingTypes.REGISTRATION}
          items={pick(people, ids)}
          selectedItems={selected}
          processingItems={oupStaffDataRecency.syncing ? oupStaffDataRecency.ids : []}
          selectable={Object.keys(
            pickBy(pick(people, ids), user => user.orgArchiveStatus !== USER_ARCHIVED_STATUS && !user.isDeleted)
          )}
          onItemSelect={onItemSelect}
          onItemLabelClick={this._handleItemLabelClick}
          onRemoveClick={roleCanArchiveOupUser(authUserRole) ? this._handleRemoveClick : undefined}
        />
      ) : null
    );
  };

  render() {
    const {
      localizedContent: { mySchoolOupStaffTab: content, removeLearningMaterialModal: rlmModalContent },
      tabName,
      orgId,
      panelName,
      authUserRole,
      authUserId,
      searchTerm,
      sort,
      page,
      filters,
      totalResults,
      showArchiveUsersModal,
      selectedOupAdminIds,
      selectedOupSupportIds,
      selectedOupContentIds,
      selectedOupContentReviewerIds,
      selectedExternalReviewerIds,
      onlyPendingUsersSelected,
      history,
      triggerSearchAction,
      setSearchTerm,
      setSortAction,
      setPageAction,
      setFilterAction,
      setStaffToArchiveAction,
      closeModalAction,
      showModalAction,
      resetJourneyAction,
      oupStaffDataRecency
    } = this.props;

    const allSelectedIds = [].concat(
      selectedOupAdminIds,
      selectedOupSupportIds,
      selectedOupContentIds,
      selectedOupContentReviewerIds,
      selectedExternalReviewerIds
    );

    const hasSelectedRemovableUsers = roleCanArchiveOupUser(authUserRole) && !allSelectedIds.includes(authUserId);

    return (
      <div>
        <ListPageControls
          idPrefix="oupStaffSearch"
          searchInputLabel={content.search_staff_label}
          searchInputPlaceholder={`${content.search_text} ${content.search_all_oup}`}
          searchInputValue={searchTerm}
          searchInputOnChange={setSearchTerm}
          searchInputOnSubmit={triggerSearchAction}
          newButtonText={content.button_add_oup_staff}
          newButtonTo={roleCanRegisterOupUser(authUserRole) ? safePanelLink('addStaff') : null}
          filterOptions={[
            {
              text: content.filter_options_active,
              id: 'oupStaffSearch-filterActive',
              name: 'oupStaffSearch-filterActive',
              value: 'active',
              checked: filters.active,
              onChange: setFilterAction
            },
            {
              text: content.filter_options_invited_staff,
              id: 'oupStaffSearch-filterInvited',
              name: 'oupStaffSearch-filterInvited',
              value: 'invited',
              checked: filters.invited,
              onChange: setFilterAction
            },
            {
              text: content.filter_options_archived_staff,
              id: 'oupStaffSearch-filterArchived',
              name: 'oupStaffSearch-filterArchived',
              value: 'archived',
              checked: filters.archived,
              onChange: setFilterAction
            }
          ]}
          sortOnChange={setSortAction}
          sortOptions={searchUserSortOptions('oupStaffSearch', sort)}
          ariaControls="searchResults"
          loading={oupStaffDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={oupStaffDataRecency.synced}
          loadedMessage={content.loaded}
        />

        <SearchStatus
          searchSource="oupStaff"
          noResultsFoundContent={
            <div className="grid">
              <div className="row">
                <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                  <p className="gin-top1 gin-bot1">{content.no_search_results}</p>
                  {roleCanRegisterOupUser(authUserRole) ? (
                    <Link to={safePanelLink('addStaff')}>{content.add_oup_staff_link_text}</Link>
                  ) : null}
                </div>
              </div>
            </div>
          }
        />

        <div className="grid">
          <div className="row">
            <div
              id="searchResults"
              className="col"
              role="region"
              aria-live="polite"
              aria-atomic="true"
              aria-label="OUP staff list"
            >
              {this._renderItems()}
              {totalResults > 10 ? (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="oupStaffSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={setPageAction}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <SlideupPanel
          isOpen={
            !!selectedOupAdminIds.length ||
            !!selectedOupSupportIds.length ||
            !!selectedOupContentIds.length ||
            !!selectedOupContentReviewerIds.length ||
            !!selectedExternalReviewerIds.length
          }
        >
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '2rem' }}>
              <Badge
                backgroundColor={colors.DASHBOARD}
                value={
                  selectedOupAdminIds.length +
                  selectedOupSupportIds.length +
                  selectedOupContentIds.length +
                  selectedOupContentReviewerIds.length +
                  selectedExternalReviewerIds.length
                }
              />
              <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{content.selected_text}</span>
            </div>
            {/* We assume the user cannot select disabled items: */}
            <LinkWithIcon
              id="assignLearningMaterial"
              to={safePanelLink('assignMaterial')}
              action={() => {}}
              preventDefault={false}
              text={content.assign_learning_material_text}
              glyph={GLYPHS.ICON_RIGHT}
            />
            {hasSelectedRemovableUsers ? (
              <LinkWithIcon
                id="removeFromInstitution"
                to={safePanelLink('archiveUsers')}
                action={() => setStaffToArchiveAction(allSelectedIds)}
                preventDefault={false}
                text={content.remove_staff_from_organisation}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}

            {onlyPendingUsersSelected ? (
              <div>
                <LinkWithIcon
                  id="sendReminder"
                  to={safePanelLink('remind')}
                  action={() => {}}
                  preventDefault={false}
                  text={content.send_staff_reminder_text}
                  glyph={GLYPHS.ICON_RIGHT}
                />
                <LinkWithIcon
                  id="cancelInvitation"
                  to={safePanelLink('cancel')}
                  action={() => {}}
                  preventDefault={false}
                  text={content.cancel_invitation_text}
                  glyph={GLYPHS.ICON_RIGHT}
                />
              </div>
            ) : null}
          </div>
        </SlideupPanel>

        {roleCanRegisterOupUser(authUserRole) ? (
          <PopoutPanel id="enrolUserPopout" ariaLabel={content.aria_label_add_staff} isOpen={panelName === 'addStaff'}>
            <EnrolUser
              orgId={orgId}
              context="OUP_STAFF"
              closePanel={() => showModalAction(true)}
              onComplete={() => history.push(safePanelUnlink('addStaff'))}
            />
          </PopoutPanel>
        ) : null}

        {roleCanArchiveOupUser(authUserRole) ? (
          <UserArchivePanel
            id="archiveUsersPopout"
            ariaLabel={content.aria_label_archive_users}
            orgId={orgId}
            userType="staff"
            open={tabName === 'oupStaff' && panelName === 'archiveUsers'}
            onClosePanel={() => showModalAction(true)}
            onComplete={() => history.push(safePanelUnlink('archiveUsers'))}
          />
        ) : null}

        {showArchiveUsersModal ? (
          <ConfirmationModal
            title={rlmModalContent.title}
            body={rlmModalContent.body}
            positiveClickText={rlmModalContent.positiveClickText}
            negativeClickText={rlmModalContent.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              showModalAction(false);
              // Once its closed then reset the archiveUsers form
              setTimeout(closeModalAction, 300);
              setTimeout(resetJourneyAction, 300);
              history.push(safePanelUnlink('addStaff'));
              history.push(safePanelUnlink('archiveUsers'));
            }}
            negativeClick={() => showModalAction(false)}
          />
        ) : null}
      </div>
    );
  }
}

OupStaffTab.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  tabName: PropTypes.string.isRequired,
  orgId: PropTypes.any,
  panelName: PropTypes.string,

  people: PropTypes.object.isRequired,
  authUserRole: PropTypes.string.isRequired,
  authUserId: PropTypes.string.isRequired,

  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  selectedOupAdminIds: PropTypes.array.isRequired,
  selectedOupSupportIds: PropTypes.array.isRequired,
  selectedOupContentIds: PropTypes.array.isRequired,
  onlyPendingUsersSelected: PropTypes.bool.isRequired,
  oupAdminIds: PropTypes.array.isRequired,
  oupSupportIds: PropTypes.array.isRequired,
  oupContentIds: PropTypes.array.isRequired,
  oupContentReviewerIds: PropTypes.array.isRequired,
  externalContentIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  showArchiveUsersModal: PropTypes.bool.isRequired,

  history: PropTypes.object.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  oupStaffDataRecency: PropTypes.object.isRequired,
  setOupAdminSelectedAction: PropTypes.func.isRequired,
  setOupSupportSelectedAction: PropTypes.func.isRequired,
  setOupContentSelectedAction: PropTypes.func.isRequired,
  setOupContentReviewerSelectedAction: PropTypes.func.isRequired,
  setExternalReviewerSelectedAction: PropTypes.func.isRequired,
  selectedOupContentReviewerIds: PropTypes.func.isRequired,
  selectedExternalReviewerIds: PropTypes.func.isRequired,
  setStaffToArchiveAction: PropTypes.func.isRequired,
  closeModalAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  resetJourneyAction: PropTypes.func.isRequired
};

export default compose(
  connect(state => ({
    orgId: state.organisationPage.orgId
  })),
  withSearchInitialiser({
    searchSource: 'oupStaff',
    initialFilters: {
      roles: [
        userRoles.OUP_ADMIN,
        userRoles.OUP_SUPPORT,
        userRoles.OUP_CONTENT,
        userRoles.OUP_CONTENT_REVIEWER,
        userRoles.EXTERNAL_CONTENT
      ],
      active: true,
      invited: true
    }
  }),
  withRouter,
  withLocalizedContent('mySchoolOupStaffTab', 'removeLearningMaterialModal'),
  withDataRecency('oupStaff'),
  connect(
    state => ({
      authUserRole: state.identity.role,
      authUserId: state.identity.userId,
      people: state.people.data,
      selectedOupAdminIds: state.institutionPage.selectedOupAdminIds,
      selectedOupSupportIds: state.institutionPage.selectedOupSupportIds,
      selectedOupContentIds: state.institutionPage.selectedOupContentIds,
      selectedOupContentReviewerIds: state.institutionPage.selectedOupContentReviewerIds,
      selectedExternalReviewerIds: state.institutionPage.selectedExternalContentReviewerIds,
      onlyPendingUsersSelected: (() => {
        const allSelectedOupUsers = state.institutionPage.selectedOupAdminIds.concat(
          state.institutionPage.selectedOupSupportIds
        );

        return (
          allSelectedOupUsers.every(id => state.people.data[id].orgInviteStatus === 'PENDING') &&
          allSelectedOupUsers.length > 0
        );
      })(),
      searchTerm: state.search.oupStaff.term,
      sort: state.search.oupStaff.sort,
      page: state.search.oupStaff.page,
      filters: state.search.oupStaff.filters,
      oupAdminIds: state.search.oupStaff.ids.filter(id => state.people.data[id].roleName === userRoles.OUP_ADMIN),
      oupSupportIds: state.search.oupStaff.ids.filter(id => state.people.data[id].roleName === userRoles.OUP_SUPPORT),
      oupContentIds: state.search.oupStaff.ids.filter(id => state.people.data[id].roleName === userRoles.OUP_CONTENT),
      oupContentReviewerIds: state.search.oupStaff.ids.filter(
        id => state.people.data[id].roleName === userRoles.OUP_CONTENT_REVIEWER
      ),
      externalContentIds: state.search.oupStaff.ids.filter(
        id => state.people.data[id].roleName === userRoles.EXTERNAL_CONTENT
      ),
      totalResults: state.search.oupStaff.totalResults,
      showArchiveUsersModal: state.archiveUsers.showModal
    }),
    {
      setSearchTerm: term => setTerm('oupStaff', term),
      setSortAction: sort => setSort('oupStaff', sort[0]),
      setPageAction: page => setPage('oupStaff', page),
      setFilterAction: (valueName, value) => setFilter('oupStaff', valueName, value),
      triggerSearchAction: triggerSearch.bind(null, 'oupStaff'),
      setOupAdminSelectedAction: setOupAdminSelected,
      setOupSupportSelectedAction: setOupSupportSelected,
      setOupContentSelectedAction: setOupContentSelected,
      setOupContentReviewerSelectedAction: setOupContentReviewerSelected,
      setExternalReviewerSelectedAction: setExternalContentReviewerSelected,
      setStaffToArchiveAction: userIds => setStaffToArchive(userIds, true),
      closeModalAction: closeForm,
      showModalAction: showClosePanelModal,
      resetJourneyAction: resetJourney
    }
  )
)(OupStaffTab);
