import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import USER_ROLES, { validUserRoles } from '../globals/userRoles.js';
import {
  isEmbeddedInIframe,
  isOrbMode,
  isOteMode,
  isOicMode,
  isEltAssessmentMode,
  getCurrentPlatform,
  getPlatformFromQueryString
} from '../utils/platform';
import redirectToUrl from '../utils/redirectToUrl';
import {
  getPlatformAmStudentUrl,
  getPlatformAmStaffUrl,
  getIntegrationPlatformKey,
  featureIsEnabled
} from '../globals/envSettings';
import { orgRoles, eltAmRoles } from '../globals/orgRoles';
import LoadingPage from '../structure/StatePages/LoadingPage/LoadingPage';
import { formStates } from '../redux/reducers/invitationsBanner.reducer';
import { queryFilter, redirectToAm } from '../utils/url.js';
import isTestingUser from '../utils/verifyUser';

// Site homepage
import Homepage from './Homepage/Homepage.js';
import OteHomepage from './OteHomepage/OteHomepage.js';
import OicHomepage from './OicHomepage/OicHomepage.js';

function IndexRedirectHandler({ userRole, invitesFetchStatus, invites }) {
  const navigate = useNavigate();
  const location = useLocation();

  const getRedirectPath = redirectToAM => {
    let nextPage;
    let platform;
    switch (userRole) {
      case USER_ROLES.MANAGED_USER:
      case USER_ROLES.LEARNER:
        if (isEltAssessmentMode()) {
          platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
          nextPage = getPlatformAmStudentUrl(platform);
          redirectToUrl(nextPage);
        } else {
          nextPage = 'myProfile/learningMaterial';
        }
        break;
      case USER_ROLES.TEACHER:
        if (isOrbMode()) {
          nextPage = '/teacherHome/library';
        } else if (isEltAssessmentMode()) {
          platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
          nextPage = getPlatformAmStaffUrl(platform);
          redirectToUrl(nextPage);
        } else {
          nextPage = 'myProfile/myClasses';
        }
        break;
      case USER_ROLES.TEACHER_ADMIN:
        nextPage = isOrbMode() ? '/teacherHome/library' : 'mySchool/students';
        break;
      case USER_ROLES.ORG_ADMIN:
        if (isOrbMode()) {
          nextPage = '/teacherHome/library';
        } else if (isEltAssessmentMode()) {
          if (redirectToAM) {
            platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
            nextPage = getPlatformAmStaffUrl(platform);
            redirectToUrl(nextPage);
          } else {
            nextPage = 'mySchool';
          }
        } else {
          nextPage = 'mySchool';
        }
        break;
      case USER_ROLES.OUP_SUPPORT:
      case USER_ROLES.OUP_ADMIN:
        nextPage = isOrbMode() ? '/teacherHome/library' : 'mySchool/organisations';
        break;
      default:
        nextPage = 'myProfile';
    }
    return nextPage;
  };

  useEffect(() => {
    let nextPage;
    const urlParams = location.search;
    const returnTo = urlParams && (urlParams.match(/returnTo=([^&$]+)/) || [])[1];
    const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');

    if (nextPathFromSessionStorage && nextPathFromSessionStorage !== '/login') {
      nextPage = nextPathFromSessionStorage;
    } else if (returnTo && returnTo !== encodeURIComponent('/') && returnTo !== encodeURIComponent('/login')) {
      nextPage = decodeURIComponent(returnTo);
    } else if (!userRole) {
      nextPage = '/';
    } else if (invitesFetchStatus) {
      return;
    } else if (!invitesFetchStatus && invites && invites.length > 0) {
      nextPage = 'showInvitations';
    } else {
      nextPage = getRedirectPath(redirectToAm());
    }

    if (nextPathFromSessionStorage) sessionStorage.removeItem('goto-path');

    const filteredParams = queryFilter(urlParams, ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']);
    const newParams = filteredParams ? `?${filteredParams}` : '';

    if (nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
      window.location.href = `${nextPage}${newParams}`;
    } else {
      navigate(`${nextPage}${newParams}`, { replace: true });
    }
  }, [userRole, invitesFetchStatus, invites, location.search, navigate]);

  useEffect(() => {
    const isImpersonationMode = !!localStorage.getItem('impersonation-token');
    if (isImpersonationMode) {
      invites.length = 0;
    }
    if (!invitesFetchStatus && invites.length > 0) {
      navigate('showInvitations', { replace: true });
    } else if (invites.length === 0 && !invitesFetchStatus && !!userRole) {
      let redirectPath = getRedirectPath(redirectToAm());
      if (localStorage.getItem('showStartUpPages') === 'true' && isOrbMode()) {
        redirectPath = 'orb-start-tips';
      }
      if (redirectPath.startsWith('http://') || redirectPath.startsWith('https://')) {
        if (!isOteMode()) {
          window.location.href = redirectPath;
        } else {
          redirectToUrl(redirectPath);
          if (redirectToAm() && userRole === USER_ROLES.ORG_ADMIN) {
            sessionStorage.setItem('goto-path', '/mySchool');
          }
        }
      } else {
        navigate(redirectPath, { replace: true });
      }
    }
  }, [invites, invitesFetchStatus, userRole, navigate]);

  const hideHeader = isEmbeddedInIframe() || sessionStorage.getItem('embedded-by-url') === 'true';

  if (invitesFetchStatus) {
    return <LoadingPage showHeader={!hideHeader} customLogoComponent={isOrbMode() ? <div /> : null} />;
  }

  if (isOteMode() && redirectToAm()) {
    return <LoadingPage showHeader={!hideHeader} />;
  }
  if (isOteMode()) {
    return <OteHomepage />;
  }
  if (isOicMode()) {
    return <OicHomepage />;
  }
  return <Homepage />;
}

const filteredInvites = (unfilteredInvites = [], email = '') => {
  if (isOteMode()) {
    return featureIsEnabled('opt-and-ote-emails-disabled') && isTestingUser(email)
      ? []
      : unfilteredInvites.filter(invite => invite.groupRole === orgRoles.TEST_CENTER);
  }
  return unfilteredInvites.filter(invite => !eltAmRoles.includes(invite.groupRole));
};

IndexRedirectHandler.propTypes = {
  userRole: PropTypes.oneOf(validUserRoles),
  invitesFetchStatus: PropTypes.bool,
  invites: PropTypes.array
};

export default connect(state => ({
  userRole: state.identity.role,
  invitesFetchStatus: state.invites.formState !== formStates.INPUTTING && !!state.identity.role,
  invites: filteredInvites(state.invites.invites, state.identity.email ? state.identity.email : '')
}))(IndexRedirectHandler);
