import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import compose from '../../../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import { isHubMode } from '../../../../../../utils/platform';
import { SidePanel } from '../../../../../../components';
import ClassUserAddReview from '../../../../../../components/ClassUserAddReview/ClassUserAddReview';
import ClassUserConfirmation from '../../../../../../components/ClassUserConfirmation/ClassUserConfirmation';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import USER_ROLES from '../../../../../../globals/userRoles';
import TeacherSelectionPanel from '../../../../../../panels/TeacherSelectionPanel';
import {
  assignMaterial,
  clearForm,
  formStates,
  setSelectedTeachers,
  removeTeacher,
  gotoTeacherSelection,
  submitForm
} from '../../../../../../redux/reducers/addTeachersToClassroom';
import AssignLearningMaterial from '../../../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import { CONTEXT_OPTIONS } from '../../../../../../views/UserSelectionView';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';
import ProductFinderPanel from '../../../../../../components/ProductFinder/ProductFinderPanel';

class AddTeachers extends React.Component {
  _getConfirmationHeading = () => {
    const {
      localizedContent: { addTeachersPanel: content },
      requestFailed,
      failedIds,
      numberOfTeachers,
      classroom
    } = this.props;

    if (requestFailed) {
      return {
        title: content.add_teachers_error_text,
        type: iconHeadingTypes.ERROR,
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
    }
    if (failedIds.length) {
      return {
        title: (content.add_teachers_partial_error_text || '').replace('{className}', classroom.name),
        type: iconHeadingTypes.WARNING,
        illustrationSrc: HubIllustrationConstants.WARNING,
        illustrationAltText: HubIllustrationAltText.WARNING
      };
    }
    return {
      title: (content.add_teachers_success_text || '')
        .replace('{numberOfTeachers}', numberOfTeachers)
        .replace('{className}', classroom.name),
      type: iconHeadingTypes.VALID,
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  };

  reset = () => {
    const { resetForm } = this.props;
    setTimeout(resetForm, 500);
  };

  _handleClosePanel = () => {
    const { closePanel } = this.props;

    closePanel();
  };

  _handleOnComplete = () => {
    const { onComplete } = this.props;

    this.reset();
    onComplete();
  };

  _handleOnSubmit = () => {
    const { onTeacherAddClick, onSubmit } = this.props;
    onTeacherAddClick();

    if (isHubMode()) onSubmit();
  };

  _getProductFinderContextName = () => {
    const {
      people,
      selectedTeacherIds,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedTeacherIds.length === 1
      ? `${people[selectedTeacherIds[0]].firstname || ''} ${people[selectedTeacherIds[0]].lastname || ''}`
      : `${selectedTeacherIds.length} ${productFinderContent.teachers_text}`;
  };

  _renderContent() {
    const {
      orgId,
      classroomId,
      formState,
      classroom,
      classroomEditingState,
      teachers,
      numberOfTeachers,
      failedIds,
      onAssignMaterial,
      closePanel,
      setSelectedTeachersAction,
      onTeacherRemoveClick,
      gotoTeacherSelectionAction,
      localizedContent: { addTeachersPanel: content },
      selectedTeacherIds
    } = this.props;

    switch (formState) {
      case formStates.SELECTING:
        return (
          <TeacherSelectionPanel
            orgId={orgId}
            closePopoutAction={closePanel}
            existingClassroom={classroom}
            classroomEditingState={classroomEditingState}
            backAction={closePanel}
            submitAction={setSelectedTeachersAction}
            context={CONTEXT_OPTIONS.ADD_TEACHERS}
            initialFilter={[USER_ROLES.TEACHER, USER_ROLES.TEACHER_ADMIN, USER_ROLES.ORG_ADMIN]}
          />
        );

      case formStates.REVIEW_SELECTIONS:
        return (
          <ClassUserAddReview
            localizedContent={content}
            classroomName={classroom.name}
            users={teachers}
            submitDisabled={!numberOfTeachers}
            onSectionLinkClick={gotoTeacherSelectionAction}
            onRemoveUserClick={onTeacherRemoveClick}
            onBackClick={gotoTeacherSelectionAction}
            onSubmitClick={this._handleOnSubmit}
          />
        );

      case formStates.SUBMITTING:
        return (
          <div className="flex-column align-items-center">
            <PopoutPanelIconHeading
              title={content.loading_page_title}
              subtitle={content.loading_page_subtitle}
              type={iconHeadingTypes.LOADING}
            />
          </div>
        );

      case formStates.ASSIGNMATERIAL:
        return isHubMode() ? (
          <ProductFinderPanel
            orgId={orgId}
            selectedUsers={{ teacherIdList: [...selectedTeacherIds] }}
            contextName={this._getProductFinderContextName()}
            onClose={this._handleClosePanel}
            onComplete={this._handleOnComplete}
            // needed for polling. will be removed when polling is removed
            context="CLASS_ADD_TEACHERS"
          />
        ) : (
          <AssignLearningMaterial
            orgId={orgId}
            context="CLASS_ADD_TEACHERS"
            closePopoutAction={this._handleClosePanel}
            onComplete={this._handleOnComplete}
          />
        );
      case formStates.CONFIRMATION:
        return (
          <ClassUserConfirmation
            localizedContent={content}
            {...this._getConfirmationHeading()}
            failedIds={failedIds}
            onAddAnother={() => onAssignMaterial(classroomId)}
            onDoneClick={this._handleOnComplete}
            users={teachers}
          />
        );

      default:
        return (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }
  }

  render() {
    const {
      isOpen,
      localizedContent: { addTeachersPanel: content }
    } = this.props;

    return (
      <SidePanel
        id="addTeachersPanel"
        ariaLabel={content.aria_label_add_teachers_to_class}
        isOpen={isOpen}
        onClose={this._handleClosePanel}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

AddTeachers.propTypes = {
  classroom: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  classroomEditingState: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  teachers: PropTypes.object.isRequired,
  requestFailed: PropTypes.string.isRequired,
  failedIds: PropTypes.string.isRequired,
  numberOfTeachers: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setSelectedTeachersAction: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onAssignMaterial: PropTypes.func.isRequired,
  onTeacherAddClick: PropTypes.func.isRequired,
  onTeacherRemoveClick: PropTypes.func.isRequired,
  gotoTeacherSelectionAction: PropTypes.func.isRequired,
  people: PropTypes.object,
  selectedTeacherIds: PropTypes.array
};

export default compose(
  withLocalizedContent('addTeachersPanel'),
  connect(
    state => {
      const { addTeachersToClassroom, classrooms, classroomPage, people } = state;
      const classroomId = classroomPage.classroomId;
      const selectedTeacherIds = addTeachersToClassroom.selectedTeacherIds;
      const teachers = pick(people.data, selectedTeacherIds);
      const numberOfTeachers = (Object.keys(teachers) || []).length;

      return {
        ...pick(addTeachersToClassroom, ['formState', 'selectedTeacherIds', 'requestFailed', 'failedIds']),
        classroomId,
        classroom: classrooms.data[classroomId] || {},
        classroomEditingState: addTeachersToClassroom,
        teachers,
        selectedTeacherIds,
        numberOfTeachers
      };
    },
    {
      setSelectedTeachersAction: setSelectedTeachers,
      onAssignMaterial: assignMaterial,
      resetForm: clearForm,
      gotoTeacherSelectionAction: gotoTeacherSelection,
      onTeacherRemoveClick: removeTeacher,
      onTeacherAddClick: submitForm
    }
  )
)(AddTeachers);

AddTeachers.propTypes = {
  classroom: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  classroomEditingState: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string,
  teachers: PropTypes.object.isRequired,
  requestFailed: PropTypes.string,
  failedIds: PropTypes.array.isRequired,
  numberOfTeachers: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setSelectedTeachersAction: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onAssignMaterial: PropTypes.func.isRequired,
  onTeacherAddClick: PropTypes.func.isRequired,
  onTeacherRemoveClick: PropTypes.func.isRequired,
  gotoTeacherSelectionAction: PropTypes.func.isRequired
};
