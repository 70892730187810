import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import compose from '../utils/compose/compose.js';
import withLocalizedContent from '../language/withLocalizedContent';
import UserRestoreReviewPane from '../components/UserRestore/UserRestoreReviewPane';
import UserRestoreSuccessPane from '../components/UserRestore/UserRestoreSuccessPane';
import ErrorStatus from '../components/ErrorStatus/ErrorStatus';
import PopoutPanel from '../components/PopoutPanel/PopoutPanel';
import PopoutPanelIconHeading, {
  types as iconTypes
} from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { restoreStudentsReset, restoreStudentsRequest } from '../redux/actions/restoreUsers';
import getCurrentUser from '../redux/selectors/getCurrentUser';

class UserRestorePanel extends React.Component {
  get activeSection() {
    const {
      reviewing,
      submitting,
      success,
      error,
      currentUserEmail,
      orgId,
      userIds,
      localizedContent: { userRestorePanel: content }
    } = this.props;
    const loadingText = submitting ? content.panel_submitting_text : content.panel_loading_text;

    switch (true) {
      case reviewing:
        return (
          <UserRestoreReviewPane
            orgId={orgId}
            userIds={userIds}
            onClickClose={this._handleClose}
            onClickRestore={this._handleRestore}
          />
        );
      case success:
        return <UserRestoreSuccessPane onClosePanel={this._handleClose} email={currentUserEmail} />;
      case error:
        return (
          <ErrorStatus
            title={content.panel_error_text}
            buttonText={content.primary_button_text}
            buttonFullWidth
            buttonOnClickHandler={this._handleClose}
          />
        );
      case submitting:
      default:
        return <PopoutPanelIconHeading type={iconTypes.LOADING} title={loadingText} />;
    }
  }

  _handleRestore = () => {
    const { restoreStudentsAction } = this.props;
    restoreStudentsAction();
  };

  _handleClose = () => {
    const { onClosePanel, onComplete, restoreStudentsResetAction, success, error } = this.props;

    if (onComplete && (success || error)) {
      restoreStudentsResetAction();
      onComplete();
    } else {
      onClosePanel();
    }
  };

  render() {
    const {
      isOpen,
      localizedContent: { userRestorePanel: content }
    } = this.props;
    return (
      <PopoutPanel
        id="restoreUsersLoadingPopout"
        ariaLabel={content.panel_aria_label}
        isOpen={isOpen}
        onClosePanel={this._handleClose}
      >
        {this.activeSection}
      </PopoutPanel>
    );
  }
}

UserRestorePanel.propTypes = {
  isOpen: PropTypes.bool,
  reviewing: PropTypes.bool,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  currentUserEmail: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  userIds: PropTypes.array.isRequired,
  onClosePanel: PropTypes.func,
  onComplete: PropTypes.func,
  restoreStudentsAction: PropTypes.func,
  restoreStudentsResetAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('userRestorePanel'),
  connect(
    state => ({
      ...pick(state.restoreUsers, ['userIds', 'orgId', 'reviewing', 'success', 'error']),
      currentUserEmail: getCurrentUser(state).email,
      submitting: state.restoreUsers.submitting
    }),
    {
      restoreStudentsAction: restoreStudentsRequest,
      restoreStudentsResetAction: restoreStudentsReset
    }
  )
)(UserRestorePanel);
