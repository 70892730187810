import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import { Button } from '@oup/shared-front-end';
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';
import { clearMissingFields } from '../../redux/reducers/sharedActions.js';

// Component imports
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

import styles from './IncompleteAccountConfirmation.scss';

import cmsContent from '../../utils/cmsContent.js';
import { isHubMode } from '../../utils/platform.js';

function IncompleteAccountConfirmation({ failed, buttonAction, firstNameValue }) {
  const CMS = cmsContent.registrationPage || {};

  const variables = {};

  if (failed) {
    variables.type = iconHeadingTypes.ERROR;
    variables.title = CMS.registration_error_message;
    variables.subtitle = (CMS.account_create_fail_text || '').replace(
      '{subtitle}',
      CMS.registration_error_subtitle_message
    );
    variables.link = `${window.location.origin}/support/contact-us`;
    variables.linkText = CMS.contact_us;
    variables.buttonText = 'Retry';
    variables.illustrationSrc = HubIllustrationConstants.ERROR;
    variables.illustrationAltText = HubIllustrationAltText.ERROR;
  } else {
    variables.type = iconHeadingTypes.VALID;
    variables.title = (CMS.account_create_success_text || '').replace('{firstName}', firstNameValue);
    variables.subtitle = null;
    variables.link = null;
    variables.linkText = null;
    variables.buttonText = CMS.button_enter_application_text;
    variables.illustrationSrc = HubIllustrationConstants.SUCCESS;
    variables.illustrationAltText = HubIllustrationAltText.SUCCESS;
  }

  return (
    <div className="row">
      <div className="col sm3" />
      <div className="col sm6">
        {isHubMode() ? (
          <PopoutPanelIllustrationHeading
            type={variables?.type}
            title={variables?.title}
            subtitle={variables?.subtitle}
            link={variables?.link}
            linkText={variables?.linkText}
            illustrationSrc={variables?.illustrationSrc}
            illustrationAltText={variables?.illustrationAltText}
          />
        ) : (
          <PopoutPanelIconHeading
            type={variables?.type}
            title={variables?.title}
            subtitle={variables?.subtitle}
            link={variables?.link}
            linkText={variables?.linkText}
          />
        )}
        <div className={`text-center gin-top2 ${styles.buttonContainerCenter}`}>
          <Button id="link-to-login" text={variables.buttonText} variant="filled" onClick={buttonAction} />
        </div>
      </div>
    </div>
  );
}

IncompleteAccountConfirmation.propTypes = {
  failed: PropTypes.bool.isRequired,
  buttonAction: PropTypes.func.isRequired,
  firstNameValue: PropTypes.string.isRequired
};

export default connect(
  state => ({
    failed: state.forms.incompleteAccount.failed,
    firstNameValue: state.people.data[state.identity.userId].firstname,
    userId: state.identity.userId,
    newEmailValue: (state.fields['incompleteAccount.email'] || {}).value
  }),
  dispatch => ({
    buttonAction: (failed, userId, newEmailValue) => {
      if (failed) {
        dispatch(setFormState('incompleteAccount', 'EDITING'));
      } else {
        dispatch(clearMissingFields(userId, newEmailValue));
      }
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    dispatchProps,
    buttonAction: () => dispatchProps.buttonAction(stateProps.failed, stateProps.userId, stateProps.newEmailValue)
  })
)(IncompleteAccountConfirmation);
