import { pick, upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import withRouter from '../../utils/withRouter';
import compose from '../../utils/compose/compose.js';
import {
  createAuthorizeUpdateUser,
  createAuthorizeRemoveUser,
  isAtLeastTeacherAdmin,
  isAtLeastTeacher,
  createAuthorizeEditUser
} from '../../redux/selectors/authorization/user';
import BannerNotification from '../../components/BannerNotification/BannerNotification';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ENTITY_TYPES from '../../globals/entityTypes';
import EntityPageHeader from '../../components/EntityPageHeader/EntityPageHeader';
import ProfileBannerInvitation from '../../components/BannerInvitation/ProfileBannerInvitation';
import SearchInitialiser from '../../components/SearchInitialiser/SearchInitialiser';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs';
import colors from '../../globals/colors';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import ManagedUserChangePasswordPanel from '../../panels/ManagedUserChangePasswordPanel';
import ManagedUserChangeUsernamePanel from '../../panels/ManagedUserChangeUsernamePanel';
import ManagedUserEditPanel from '../../panels/ManagedUserEditPanel';
import OrgUserEditPanel from '../../panels/OrgUserEditPanel';
import UserArchivePanel from '../../panels/UserArchivePanel';
import { setManagedUser } from '../../redux/actions/managedUser';
import { setManagedUserChangeUsernameRecord } from '../../redux/actions/managedUserChangeUsername';
import { setManagedUserEditRecord, setManagedUserApiStatus } from '../../redux/actions/managedUserEdit';
import {
  showClosePanelModal as showArchiveUserModal,
  closeForm as closeArchiveUserModal,
  setStudentsToArchive,
  setStaffToArchive
} from '../../redux/reducers/archiveUsers.reducer';
import { setActiveOrgId } from '../../redux/reducers/organisationPage.reducer';
import {
  sendReminder,
  displayModal,
  hideModal,
  cancelInvitation,
  sendDone
} from '../../redux/reducers/user/userProfile.reducer';
import { openEditMyAccountModal } from '../../redux/actions/editUserAccountModal';
import content from '../../utils/cmsContent';
import { safePanelLink, safePanelUnlink } from '../../utils/links/panelLinks';
import UserProfileClassSearch from './tabs/UserProfileClassSearch';
import UserProfileContentSearch from './tabs/UserProfileContentSearch';
import UserProfileTeacherArchived from './UserProfileTeacherArchived';
import { orgRoles } from '../../globals/orgRoles';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import ContentWithThumbnail from '../../components/ContentWithThumbnail/ContentWithThumbnail';
import { SIZES as ThumbnailSizes } from '../../components/Thumbnail/Thumbnail';
import withDataRecency from '../../dataRecency/withDataRecency';
import { isEltAssessmentMode, isOicMode } from '../../utils/platform';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    const { canEditOtherUser } = this.props;
    if (canEditOtherUser) {
      if (this._panelName === 'editManagedUser') {
        this._setManagedUserEditRecord();
      } else if (this._panelName === 'changeUsername') {
        this._setManagedUserChangeUsernameRecord();
      } else if (this._panelName === 'changePassword') {
        this._setManagedUserRecord();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      params: { panelName },
      canEditOtherUser,
      history
    } = this.props;
    if (prevProps.canEditOtherUser !== canEditOtherUser || prevProps.params.panelName !== panelName) {
      if (!canEditOtherUser) {
        history.push(safePanelUnlink(panelName));
      }
    }
  }

  get _panelName() {
    const {
      invitationPending,
      params: { panelName, tabName }
    } = this.props;

    // ELT assessment and OIC screens do not show classes because class creation is not currently encouraged for these orgs
    const noProductsTabAvailable = (invitationPending && !panelName) || isEltAssessmentMode() || isOicMode();
    return noProductsTabAvailable ? tabName : panelName;
  }

  get _isManagedUser() {
    const { role } = this.props;
    return role === userRoles.MANAGED_USER;
  }

  _handleEditButtonClick = e => {
    const {
      history,
      authUserId,
      onEditAccountClick,
      params: { userId }
    } = this.props;
    const loadPanelV2 = e.target.getAttribute('id') === 'entityEditButtonV2';
    const { setManagedUserApiStatusAction } = this.props;

    if (userId === authUserId) {
      if (isOicMode()) {
        onEditAccountClick();
      } else {
        history.push('/myProfile/myClasses/editUser');
      }
    } else if (this._isManagedUser) {
      setManagedUserApiStatusAction(loadPanelV2);
      this._setManagedUserEditRecord();
      history.push(safePanelLink('editManagedUser'));
    } else {
      history.push(safePanelLink('editUser'));
    }
  };

  _handleArchiveComplete = () => {
    const {
      params: { orgId },
      history
    } = this.props;

    history.push(`/org/${orgId}`);
  };

  _navigateChangeUsername = () => {
    const { history } = this.props;

    this._setManagedUserChangeUsernameRecord();
    history.push(safePanelUnlink('editManagedUser'));
    history.push(safePanelLink('changeUsername'));
  };

  _navigateChangePassword = () => {
    const { history } = this.props;

    this._setManagedUserRecord();
    history.push(safePanelUnlink('editManagedUser'));
    history.push(safePanelUnlink('changeUsername'));
    history.push(safePanelLink('changePassword'));
  };

  _navigateArchiveManagedUser = () => {
    const {
      params: { userId },
      setStudentsToArchiveAction,
      history
    } = this.props;

    setStudentsToArchiveAction([userId]);
    history.push(safePanelUnlink('editManagedUser'));
    history.push(safePanelLink('archiveUser'));
  };

  _navigateArchiveOrgUser = () => {
    const {
      params: { userId },
      setStaffToArchiveAction,
      history
    } = this.props;

    setStaffToArchiveAction([userId]);
    history.push(safePanelUnlink('editUser'));
    history.push(safePanelLink('archiveUser'));
  };

  _setManagedUserChangeUsernameRecord() {
    const {
      username,
      firstName,
      lastName,
      editableUserName,
      password,
      setManagedUserChangeUsernameRecordAction
    } = this.props;

    setManagedUserChangeUsernameRecordAction({ username, firstName, lastName, editableUserName, password });
  }

  _setManagedUserEditRecord() {
    const {
      username,
      firstName,
      lastName,
      yearGroup,
      editableUserName,
      password,
      role,
      orgUniqueId,
      setManagedUserEditRecordAction
    } = this.props;

    setManagedUserEditRecordAction({
      username,
      firstName,
      lastName,
      yearGroup,
      editableUserName,
      password,
      role,
      orgUniqueId
    });
  }

  _setManagedUserRecord() {
    const {
      params: { orgId, userId },
      username,
      editableUserName,
      firstName,
      lastName,
      password,
      orgUniqueId,
      setManagedUserRecordAction
    } = this.props;

    setManagedUserRecordAction({
      orgId,
      userId,
      user: { username, firstName, lastName, editableUserName, password, orgUniqueId }
    });
  }

  _renderBannerNotification() {
    const {
      params: { orgId, userId },
      history,
      componentState,
      firstName,
      email,
      role,
      archived,
      invitationPending,
      invitationExpiryTimestamp,
      showDoneButton,
      invitationTimestamp,
      reminderTimestamps,
      cancelInvitationState,
      errorMessage,
      orgName,
      sendReminderAction,
      displayModalAction,
      sendDoneAction,
      canEditOtherUser
    } = this.props;

    const expired = moment(invitationExpiryTimestamp).isSameOrBefore();

    if (!archived && canEditOtherUser) {
      if (invitationPending && !showDoneButton) {
        return (
          <BannerNotification isPopup backgroundColor={colors.PROFILE}>
            <ProfileBannerInvitation
              userId={userId}
              invite={{
                firstName,
                email,
                orgId,
                orgName,
                status: 'PENDING',
                sentDate: invitationTimestamp,
                roleName: role,
                expiryDate: invitationExpiryTimestamp,
                expired,
                cancelInvitationState,
                errorMessage
              }}
              onRemind={() => sendReminderAction(history)}
              reminderDisabled={
                reminderTimestamps[userId] &&
                !moment
                  .utc(reminderTimestamps[userId])
                  .local()
                  .add(60, 'minutes')
                  .isBefore()
              }
              onCancel={displayModalAction}
            />
          </BannerNotification>
        );
      }

      if (componentState === 'PROFILE_COMPLETE' && showDoneButton) {
        return (
          <BannerNotification isPopup backgroundColor={colors.PROFILE}>
            <ProfileBannerInvitation
              userId={userId}
              showDoneButton
              invite={{
                firstName,
                email,
                orgId,
                orgName,
                status: 'PENDING',
                sentDate: invitationTimestamp,
                roleName: role,
                expiryDate: invitationExpiryTimestamp,
                expired,
                cancelInvitationState,
                errorMessage
              }}
              onDone={sendDoneAction}
            />
          </BannerNotification>
        );
      }
    }

    return null;
  }

  _renderMyClassesTab() {
    const {
      params: { orgId, userId, tabName },
      searchClassesInAllChildOrgs,
      componentState
    } = this.props;
    return tabName && componentState === 'PROFILE_COMPLETE' ? (
      <SearchInitialiser
        searchSource="userClasses"
        initialFilters={{
          orgId: searchClassesInAllChildOrgs ? undefined : orgId,
          userId,
          active: true,
          isUserProfileClassTab: true
        }}
      >
        <UserProfileClassSearch userId={userId} orgId={orgId} />
      </SearchInitialiser>
    ) : null;
  }

  render() {
    const {
      params: { orgId, userId, tabName },
      authUserId,
      location,
      componentState,
      firstName,
      lastName,
      username,
      role,
      archived,
      invitationPending,
      canEditOtherUser,
      canRemoveOtherUser,
      canOnlyUnlockUser,
      showArchiveUsersModal,
      hideModalAction,
      cancelInvitationAction,
      history,
      showArchiveUserModalAction,
      userType,
      orgUniqueId,
      isPrimarySchool,
      classAssignmentsDataRecency,
      userDetails,
      canEditOtherClassUser,
      currentUserRole
    } = this.props;

    const CMS = content.userProfilePage || {};
    const modalTitle = (CMS.modalTitle || '').replace('{firstName}', firstName);
    const editActionEnabled = !archived && (authUserId === userId || canEditOtherUser);
    const displayUsername = orgUniqueId ? username.replace(`${orgUniqueId}_`, '') : username;
    const capitalisedFirstName = firstName ? upperFirst(firstName) : 'User';
    const displayBackButton = isOicMode() && roleIsAtLeast(userRoles.ORG_ADMIN, currentUserRole);
    const getBackButtonUrl = () => {
      if (!displayBackButton) return null;

      if (isOicMode()) {
        // This route is the only way to get on the User profile page from
        return '/mySchool/schoolStaff';
      }

      return null;
    };

    const backButtonTo = displayBackButton ? getBackButtonUrl() : null;
    const backButtonText = displayBackButton ? CMS.back_button : null;

    return (
      <div style={{ padding: '0 0 4rem' }}>
        <Helmet title={`${firstName} ${lastName}`} />
        <div className="color-profile-dark">
          <EntityPageHeader
            userRole={role}
            entityType={archived ? ENTITY_TYPES.ARCHIVED_PROFILE : ENTITY_TYPES.PROFILE}
            entityTitle={`${firstName} ${lastName}`}
            entitySubtitle={displayUsername}
            editButtonId="myProfileEditButton"
            editButtonOnClick={editActionEnabled && canEditOtherClassUser ? this._handleEditButtonClick : null}
            editButtonText={CMS.editUser_trigger_button_text}
            displayEditButton={!!(editActionEnabled && canEditOtherClassUser)}
            backButtonTo={backButtonTo}
            backButtonText={backButtonText}
          />
        </div>
        {this._renderBannerNotification()}
        {(archived || !invitationPending) && (
          <UrlControlledTabs
            backgroundColor={archived ? colors.ARCHIVED : colors.PROFILE}
            tabName={tabName}
            pathname={location.pathname}
            items={
              isEltAssessmentMode() || isOicMode()
                ? []
                : [
                    {
                      urlName: 'myClasses',
                      color: colors.LEARNERS,
                      tabText: CMS.classesTabTitle,
                      panelContent: archived ? (
                        <UserProfileTeacherArchived
                          text={CMS.classes_error.replace('{firstName}', capitalisedFirstName)}
                          thumbnail={
                            <ContentWithThumbnail
                              size={ThumbnailSizes.LARGE}
                              glyph={GLYPHS.ICON_CLASS}
                              backgroundColor={colors.CLASSROOM}
                            />
                          }
                        />
                      ) : (
                        this._renderMyClassesTab()
                      )
                    },
                    {
                      urlName: 'myContent',
                      color: colors.LEARNERS,
                      tabText: CMS.contentTabTitle,
                      isContentLoading: classAssignmentsDataRecency.syncing,
                      isContentLoaded: classAssignmentsDataRecency.synced,
                      panelContent: archived ? (
                        <UserProfileTeacherArchived
                          text={CMS.learning_material_error.replace('{firstName}', capitalisedFirstName)}
                          thumbnail={
                            <ContentWithThumbnail
                              size={ThumbnailSizes.LARGE}
                              glyph={GLYPHS.ICON_LEARNING_MATERIAL}
                              backgroundColor={colors.COURSE}
                            />
                          }
                        />
                      ) : (
                        <SearchInitialiser
                          searchSource="userAssignments"
                          initialFilters={{
                            orgId,
                            userId,
                            ...(isPrimarySchool
                              ? {
                                  active: true,
                                  expiring: true,
                                  expired: false,
                                  notStarted: true,
                                  noLicence: false
                                }
                              : {
                                  active: true,
                                  expiring: true,
                                  expired: true,
                                  notStarted: true,
                                  noLicence: true
                                })
                          }}
                        >
                          <UserProfileContentSearch
                            panelName={this._panelName}
                            userId={userId}
                            orgId={orgId}
                            userRole={role}
                          />
                        </SearchInitialiser>
                      )
                    }
                  ]
            }
          />
        )}
        {componentState === 'DISPLAY_MODAL' ? (
          <ConfirmationModal
            // This is for removing a users invitation, not the usual default confirm close
            title={modalTitle}
            body={isEltAssessmentMode() ? '' : CMS.modalBody}
            positiveClickText={CMS.modalPositiveClickText}
            negativeClickText={CMS.modalNegativeClickText}
            positiveClick={() => cancelInvitationAction(history)}
            negativeClick={hideModalAction}
          />
        ) : null}
        {showArchiveUsersModal ? (
          <ConfirmationModal
            title={CMS.unsaved_dialog_title_text}
            body={CMS.unsaved_dialog_body_text}
            positiveClickText={CMS.unsaved_dialog_positive_click}
            negativeClickText={CMS.unsaved_dialog_negative_click}
            positiveClick={() => {
              // Close the Popout panel
              showArchiveUserModalAction(false);
              history.push(safePanelUnlink('editUser'));
            }}
            negativeClick={() => showArchiveUserModalAction(false)}
          />
        ) : null}
        <ManagedUserEditPanel
          userId={userId}
          orgId={orgId}
          open={this._panelName === 'editManagedUser'}
          onClosePanel={() => history.push(safePanelUnlink('editManagedUser'))}
          navigateChangeUsername={this._navigateChangeUsername}
          navigateChangePassword={this._navigateChangePassword}
          navigateArchive={this._navigateArchiveManagedUser}
          canRemoveUser={canRemoveOtherUser}
          canOnlyUnlockUser={canOnlyUnlockUser}
          orgUniqueId={orgUniqueId}
          userDetails={userDetails}
        />
        <ManagedUserChangeUsernamePanel
          userId={userId}
          orgId={orgId}
          open={this._panelName === 'changeUsername'}
          navigateChangePassword={this._navigateChangePassword}
          onClosePanel={() => history.push(safePanelUnlink('changeUsername'))}
        />
        <ManagedUserChangePasswordPanel
          userId={userId}
          orgId={orgId}
          open={this._panelName === 'changePassword'}
          onClosePanel={() => history.push(safePanelUnlink('changePassword'))}
        />
        <OrgUserEditPanel
          orgId={orgId}
          userId={userId}
          isOpen={this._panelName === 'editUser'}
          closePanel={() => history.push(safePanelUnlink('editUser'))}
          canRemoveUser={canRemoveOtherUser}
          archive={this._navigateArchiveOrgUser}
        />
        <UserArchivePanel
          id="archiveUserPopout"
          orgId={orgId}
          userType={userType}
          open={this._panelName === 'archiveUser'}
          onClosePanel={() => history.push(safePanelUnlink('archiveUser'))}
          onComplete={this._handleArchiveComplete}
          hideNotifyOption
          isPrimarySchool={isPrimarySchool}
        />
      </div>
    );
  }
}

UserProfile.propTypes = {
  // Routing
  params: PropTypes.shape({
    orgId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    panelName: PropTypes.string,
    tabName: PropTypes.string
  }),
  authUserId: PropTypes.string.isRequired,
  location: PropTypes.any,
  componentState: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  editableUserName: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  yearGroup: PropTypes.number,
  role: PropTypes.string,
  archived: PropTypes.bool.isRequired,
  invitationPending: PropTypes.bool.isRequired,
  showDoneButton: PropTypes.bool.isRequired,
  invitationTimestamp: PropTypes.string,
  reminderTimestamps: PropTypes.object,
  cancelInvitationState: PropTypes.string,
  errorMessage: PropTypes.string,
  invitationExpiryTimestamp: PropTypes.string,
  orgName: PropTypes.string,
  canEditOtherUser: PropTypes.bool.isRequired,
  canRemoveOtherUser: PropTypes.bool.isRequired,
  canOnlyUnlockUser: PropTypes.bool.isRequired,
  showArchiveUsersModal: PropTypes.bool.isRequired,
  searchClassesInAllChildOrgs: PropTypes.bool.isRequired,
  sendReminderAction: PropTypes.func,
  displayModalAction: PropTypes.func,
  hideModalAction: PropTypes.func,
  cancelInvitationAction: PropTypes.func,
  sendDoneAction: PropTypes.func,
  history: PropTypes.object.isRequired,
  setManagedUserRecordAction: PropTypes.func.isRequired,
  setManagedUserEditRecordAction: PropTypes.func.isRequired,
  setManagedUserChangeUsernameRecordAction: PropTypes.func.isRequired,
  setStudentsToArchiveAction: PropTypes.func.isRequired,
  setStaffToArchiveAction: PropTypes.func.isRequired,
  showArchiveUserModalAction: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  orgUniqueId: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string,
  isPrimarySchool: PropTypes.bool.isRequired,
  classAssignmentsDataRecency: PropTypes.object.isRequired,
  userDetails: PropTypes.object,
  setManagedUserApiStatusAction: PropTypes.func,
  canEditOtherClassUser: PropTypes.bool,
  onEditAccountClick: PropTypes.func
};

export default compose(
  withRouter,
  withDataRecency('classAssignments'),
  connect(
    (state, { params = {} }) => {
      const { name: orgName, type: orgType, role: orgRole, customId: orgUniqueId } =
        state.organisations.data[state.organisationPage.orgId || state.userProfile.orgId] || {};

      return {
        ...pick(state.userProfile, [
          'componentState',
          'username',
          'firstName',
          'lastName',
          'email',
          'yearGroup',
          'role',
          'archived',
          'invitationPending',
          'showDoneButton',
          'invitationTimestamp',
          'invitationExpiryTimestamp',
          'reminderTimestamps',
          'cancelInvitationState',
          'errorMessage'
        ]),
        editableUserName: orgUniqueId
          ? state.userProfile.username.replace(`${orgUniqueId}_`, '')
          : state.userProfile.username,
        password:
          state.userProfile.userDetails.password && state.userProfile.userDetails.password !== undefined
            ? state.userProfile.userDetails.password
            : '',
        authUserId: state.identity.userId,
        isPrimarySchool: orgRole === orgRoles.PRIMARY_SCHOOL,
        orgName,
        canEditOtherClassUser: createAuthorizeEditUser(state)({
          orgId: params.orgId,
          userId: state.identity.userId
        }),
        canEditOtherUser: createAuthorizeUpdateUser(state)({ orgId: params.orgId }),
        canRemoveOtherUser: createAuthorizeRemoveUser(state)({ orgId: params.orgId }),
        canOnlyUnlockUser: isAtLeastTeacher(state) && !isAtLeastTeacherAdmin(state),
        showArchiveUsersModal: state.archiveUsers.showModal,
        searchClassesInAllChildOrgs: orgType === 'OUP_MASTER_GROUP',
        userType: [userRoles.MANAGED_USER, userRoles.LEARNER].includes(state.userProfile.role) ? 'students' : 'staff',
        userDetails: state.userProfile.userDetails,
        orgUniqueId,
        currentUserRole: state.identity.role
      };
    },
    {
      sendReminderAction: sendReminder,
      displayModalAction: displayModal,
      hideModalAction: hideModal,
      cancelInvitationAction: cancelInvitation,
      sendDoneAction: sendDone,
      setActiveOrgIdAction: setActiveOrgId,
      setManagedUserRecordAction: setManagedUser,
      setManagedUserEditRecordAction: setManagedUserEditRecord,
      setManagedUserChangeUsernameRecordAction: setManagedUserChangeUsernameRecord,
      setStudentsToArchiveAction: setStudentsToArchive,
      setStaffToArchiveAction: setStaffToArchive,
      showArchiveUserModalAction: showArchiveUserModal,
      closeArchiveUserModalAction: closeArchiveUserModal,
      setManagedUserApiStatusAction: setManagedUserApiStatus,
      onEditAccountClick: openEditMyAccountModal
    }
  )
)(UserProfile);
