import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Footer, Button as ButtonRestyled } from '@oup/shared-front-end';
import { ICON_LEFT } from '@oup/shared-front-end/svg/oup';
import { redeemCodeErrorCodes } from '@oup/shared-node-browser/redeemCodeErrorCodes';

import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer';
import Button, { buttonTypes } from '../../components/Button/Button';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import { featureIsEnabled } from '../../globals/envSettings';
import styles from './JoinClass.scss';
import { isHubMode } from '../../utils/platform';

const USER_ALREADY_IN_CLASS = 'User already in class';
class JoinClassCodeConfirmation extends React.Component {
  displayErrorMessage = errorMessage => {
    const { content } = this.props;

    if (errorMessage?.message === USER_ALREADY_IN_CLASS) {
      return content.user_already_in_class;
    }
    return content.class_code_validation_error;
  };

  getTitle = error => {
    const { content } = this.props;

    switch (error?.code) {
      case redeemCodeErrorCodes.invalidCode:
        return content.code_not_working;

      case redeemCodeErrorCodes.userIsAlreadyMember:
        return content.user_already_joined_class;

      case redeemCodeErrorCodes.invalidUserRole:
        return `${error?.message} ${content.invalid_user_role}`;

      case redeemCodeErrorCodes.placementTestSessionError:
        return `${error.message.split('.')[0]}.`;

      default:
        return content.system_error;
    }
  };

  getSubtitle = error => {
    const { content, classroomName } = this.props;

    switch (error?.code) {
      case redeemCodeErrorCodes.invalidCode:
        return content.code_not_working_subtitle;

      case redeemCodeErrorCodes.userIsAlreadyMember:
        return `${content.user_already_joined_class_subtitle} ${classroomName}.`;

      case redeemCodeErrorCodes.invalidUserRole:
        return content.invalid_user_role_subtitle;

      case redeemCodeErrorCodes.placementTestSessionError:
        return `${error.message.split('.')[1]}.`;

      default:
        return content.system_error_subtitle;
    }
  };

  _getConfirmationHeading = errorOccurred => {
    const { content, classroomName, errorMessage } = this.props;

    if (errorOccurred) {
      return {
        type: iconHeadingTypes.ERROR,
        title: featureIsEnabled('opt-main-features') ? this.getTitle(errorMessage) : content.code_not_working,
        subtitle: featureIsEnabled('opt-main-features')
          ? this.getSubtitle(errorMessage)
          : this.displayErrorMessage(errorMessage),
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
    }

    return {
      type: iconHeadingTypes.VALID,
      title: content.you_joined_the_class?.replace('[className]', classroomName),
      subtitle: null,
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  };

  _getConfirmationFooter = errorOccurred => {
    const { content, onComplete, onValidationError, errorMessage } = this.props;

    if (errorOccurred) {
      const text = [redeemCodeErrorCodes.userIsAlreadyMember, redeemCodeErrorCodes.invalidUserRole].includes(
        errorMessage?.code
      )
        ? content.try_again_with_different_code_button_text
        : content.button_back_text;

      return {
        text:
          featureIsEnabled('opt-main-features') &&
          ![redeemCodeErrorCodes.invalidCode, redeemCodeErrorCodes.placementTestSessionError].includes(
            errorMessage?.code
          )
            ? text
            : content.try_again_button_text,
        onClick: onValidationError
      };
    }

    return {
      text: content.confirm_button_text,
      onClick: onComplete
    };
  };

  _getOupFooterContent = () => {
    const { errorOccurred = false, closePanel, errorMessage, content, onComplete, classroomName } = this.props;
    const userIsAlreadyMember = redeemCodeErrorCodes.userIsAlreadyMember === errorMessage?.code;

    return !errorMessage ? (
      <Footer
        primaryButtonLabel={content.confirm_button_text}
        primaryButtonAction={onComplete}
        secondaryButtonAction={closePanel}
        primaryButtonAttributes={
          featureIsEnabled('opt-main-features')
            ? {
                variant: 'filled',
                className: styles.fullWidth
              }
            : {
                className: styles.fullWidth
              }
        }
      />
    ) : (
      <div style={{ margin: '10px' }}>
        {userIsAlreadyMember && (
          <div style={{ 'margin-bottom': '10px' }}>
            <Button
              text={`Go to ${classroomName || 'class'}`}
              type={buttonTypes.SECONDARY}
              fullWidth
              disabled={false}
              onClick={() => {
                window.location.href = `/myClasses/singleClass/${JSON.parse(errorMessage.message).classId}/classwork`;
              }}
            />
          </div>
        )}
        {featureIsEnabled('opt-main-features') &&
        ![redeemCodeErrorCodes.invalidCode, redeemCodeErrorCodes.placementTestSessionError].includes(
          errorMessage?.code
        ) ? (
          <ButtonRestyled
            {...this._getConfirmationFooter(errorOccurred)}
            variant="outline"
            icon={{
              placement: 'left',
              component: <ICON_LEFT />
            }}
          />
        ) : (
          <Button
            {...this._getConfirmationFooter(errorOccurred)}
            type={buttonTypes.PRIMARY}
            fullWidth
            disabled={false}
          />
        )}
      </div>
    );
  };

  render() {
    const { errorOccurred = false, closePanel } = this.props;
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <ScrollContainer
          enhancedHeader
          headerContent={
            <div className={styles.header}>
              <div className={styles.close}>
                <Button
                  text="Close"
                  customClassName={classNames('panelNavLink')}
                  onClick={closePanel}
                  type={buttonTypes.CLOSE_BOLD}
                />
              </div>
              {isHubMode() ? (
                <PopoutPanelIllustrationHeading {...this._getConfirmationHeading(errorOccurred)} />
              ) : (
                <PopoutPanelIconHeading {...this._getConfirmationHeading(errorOccurred)} />
              )}
            </div>
          }
          footerContent={
            featureIsEnabled('opt-main-features') ? (
              this._getOupFooterContent()
            ) : (
              <div style={{ margin: '10px' }}>
                <Button
                  {...this._getConfirmationFooter(errorOccurred)}
                  type={buttonTypes.PRIMARY}
                  fullWidth
                  disabled={false}
                />
              </div>
            )
          }
        />
      </form>
    );
  }
}

JoinClassCodeConfirmation.propTypes = {
  content: PropTypes.object.isRequired,
  errorOccurred: PropTypes.bool,
  errorMessage: PropTypes.object,
  classroomName: PropTypes.string,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onValidationError: PropTypes.func
};

export default JoinClassCodeConfirmation;
