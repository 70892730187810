import PropTypes from 'prop-types';
import React from 'react';
import compose from '../../utils/compose/compose.js';
import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import ControlledForm from '../ControlledForm/ControlledForm';
import Dropdown from '../Dropdown/Dropdown';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import styles from './UserEnrolSelectCohortForm.scss';

function UserEnrolSelectCohortForm({
  localizedContent: { userEnrolSelectCohortFormComponent: content },
  orgName,
  orgCurriculumType,
  formInputChanges: { yearGroup = null } = {},
  onChange,
  getYearGroupOptions,
  onBack,
  onSubmit
}) {
  const yearGroups = getYearGroupOptions(orgCurriculumType);

  return (
    <ScrollContainer
      headerContent={
        <header className={styles.header}>
          <h2 className={styles.heading}>{content.title}</h2>
          <div className="gin-top1">
            {content.subtitle} {orgName}
          </div>
        </header>
      }
      footerContent={
        <PopoutActionFooter
          secondaryButtonText={content.back_button}
          secondaryButtonAction={onBack}
          primaryButtonText={content.submit_button}
          primaryButtonAction={onSubmit}
          wizardButtons
        />
      }
    >
      <div className="pad2">
        <ControlledForm>
          <Dropdown
            id="yearGroup"
            name="yearGroup"
            label={content.year_group_label}
            value={Object.keys(yearGroups).find(key => yearGroups[key].value === yearGroup)}
            options={Object.entries(yearGroups).map(([key, { name }]) => ({
              text: name,
              value: key
            }))}
            onChange={(key, name) => onChange(yearGroups[key].value, name)}
          />
        </ControlledForm>
        <div>
          <p className="gin-top2">{content.year_group_student_selected_guidance}</p>
          <p className="gin-top2">{content.year_group_student_progress_guidance}</p>
        </div>
      </div>
    </ScrollContainer>
  );
}

UserEnrolSelectCohortForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgName: PropTypes.string.isRequired,
  orgCurriculumType: PropTypes.string.isRequired,
  formInputChanges: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  getYearGroupOptions: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default compose(
  withGetYearGroupOptions,
  withLocalizedContent('userEnrolSelectCohortFormComponent')
)(UserEnrolSelectCohortForm);
