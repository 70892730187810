import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import styles from '../MyProfileEditForm/MyProfileEditForm.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import {
  getGoogleConnectionName,
  getMicrosoftConnectionName,
  getAppleConnectionName,
  featureIsEnabled
} from '../../globals/envSettings';
import initAuth0, { LINK_ACCOUNTS_CUSTOM_SCOPE } from '../../globals/oidcSettings';
import { isHubMode, isCesMode, getCurrentPlatform } from '../../utils/platform';

function LinkSocialAccounts({ isGoogle, isMicrosoft, isApple, localizedContent: content, userEmail, userName }) {
  const urlBase = window.location.origin;

  const linkAccount = async connectionId => {
    const socialAuth0Client = await initAuth0();
    const params = {
      authorizationParams: {
        max_age: 0,
        scope: LINK_ACCOUNTS_CUSTOM_SCOPE,
        connection: connectionId,
        primaryAccount: userName || userEmail,
        redirect_uri: window.location.href,
        ...((isHubMode() || (featureIsEnabled('supervised-users-ces') && isCesMode())) && {
          'oup-idp-ui': getCurrentPlatform()
        })
      }
    };

    localStorage.setItem('link-social-accounts-connection', connectionId);
    await socialAuth0Client.loginWithRedirect(params);

    return {};
  };

  return (
    <div>
      {isApple && featureIsEnabled('enable-apple-login') ? (
        <button
          id="registration-apple-social"
          type="button"
          className={styles.registerButtonApple}
          onClick={() => {
            linkAccount(getAppleConnectionName());
          }}
        >
          <img alt="apple_button" src={`${urlBase}/static/images/social-icons/apple.svg`} />
          {content.my_profile_edit_form_link_or_unlink_continue_apple}
        </button>
      ) : null}

      {isGoogle ? (
        <button
          id="registration-google-social"
          type="button"
          className={styles.registerButtonGoogle}
          onClick={() => {
            linkAccount(getGoogleConnectionName());
          }}
        >
          <img alt="google_button" src={`${urlBase}/static/images/social-icons/google.svg`} />
          {content.my_profile_edit_form_link_or_unlink_continue_google}
        </button>
      ) : null}

      {isMicrosoft ? (
        <button
          id="registration-microsoft-social"
          type="button"
          className={styles.registerButtonMicrosoft}
          onClick={() => {
            linkAccount(getMicrosoftConnectionName());
          }}
        >
          <img alt="microsoft_button" src={`${urlBase}/static/images/social-icons/microsoft.svg`} />
          {content.my_profile_edit_form_link_or_unlink_continue_microsoft}
        </button>
      ) : null}
    </div>
  );
}

LinkSocialAccounts.propTypes = {
  isGoogle: PropTypes.bool,
  localizedContent: PropTypes.object,
  isMicrosoft: PropTypes.bool,
  isApple: PropTypes.bool,
  userEmail: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired
};

export default compose(
  withLocalizedContent('myProfileEditFormComponent'),
  connect(state => ({
    // Connect values
    userEmail: state.identity.email,
    userName: state.identity.userName
  }))
)(LinkSocialAccounts);
