import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import styles from './ManagedUserUpdatePassword.scss';
import { editUserPasswordFragment, resetValidation } from '../../redux/actions/managedUserEdit';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import { generateManagedUserPasswordRequest } from '../../redux/actions/managedUser';

class ManagedUserUpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        ...this.props
      },
      status: null,
      showMessage: null,
      previousGeneratedPassword: null,
      specialCharactersEntered: false,
      invalidCharacterRegex: /[~`!#$%@^&*+=\-[\]\\';,_./{}|\\":<>?]/
    };
  }

  componentWillReceiveProps(newProps) {
    const { previousGeneratedPassword, user } = this.state;
    const { createChangeHandler, resetValidation: resetValidationAlias } = this.props;
    if (newProps.generatedPassword !== '' && newProps.generatedPassword !== previousGeneratedPassword) {
      this.setState({
        user: {
          ...user,
          password: newProps.generatedPassword
        },
        status: 'valid',
        showMessage: false,
        previousGeneratedPassword: newProps.generatedPassword
      });
      this._handleOnPasswordBlur();
      createChangeHandler('password')(newProps.generatedPassword);
      resetValidationAlias(newProps.generatedPassword);
    }
  }

  _handleOnPasswordFocus = () => {
    const { resetValidation: resetValidationAlias } = this.props;
    resetValidationAlias('data.password');
    setTimeout(() => {
      this.setState({
        status: 'notice',
        showMessage: true
      });
    }, 100);
  };

  _handleOnPasswordBlur = () => {
    const { resetValidation: resetValidationAlias } = this.props;
    resetValidationAlias('data.password');
    setTimeout(() => {
      const { editUserPasswordOnBlur } = this.props;
      const { user } = this.state;
      editUserPasswordOnBlur(user.password);
      this._populateValidationStatus();
    }, 100);
  };

  _populateValidationStatus = () => {
    const { errors } = this.props;
    if (errors && errors.data && Object.keys(errors.data).length) {
      this.setState({
        status: Object.keys(errors).length && errors.data.password ? 'error' : 'valid',
        showMessage: Object.keys(errors).length && errors ? errors.data.password : null
      });
    }
  };

  _handleOnPasswordChange = value => {
    const { user, invalidCharacterRegex } = this.state;
    this.setState({
      user: {
        ...user,
        password: value
      },
      specialCharactersEntered: value === '' ? false : invalidCharacterRegex.test(value)
    });
    this._handleOnPasswordBlur();
  };

  render() {
    const {
      localizedContent: { userUpdatePassword: content },
      firstName,
      lastName,
      username,
      backAction,
      onSubmit,
      errors = {},
      createChangeHandler,
      actionGeneratePassword,
      generating
    } = this.props;
    const { status, showMessage, user, specialCharactersEntered } = this.state;
    const nextButtonDisabled = !user.password || (errors.data && errors.data.password);
    const errorText = specialCharactersEntered
      ? content.password_input_error_special_character
      : content.password_input_error;
    const type = generating ? null : status;
    const isActive = generating ? null : showMessage;

    return (
      <ScrollContainer
        headerContent={
          <header className="gin-top2 gin-bot2">
            <PersonRepresentation name={`${firstName} ${lastName}`} email={username} headerSizeName />
          </header>
        }
        footerContent={
          <div>
            <PopoutNavFooter
              backAction={backAction}
              nextAction={onSubmit}
              nextButtonDisabled={nextButtonDisabled}
              nextButtonText={content.button_save}
            />
          </div>
        }
      >
        <div className={classnames('pad2', styles.updatePasswordContainer)}>
          <div className={classnames(styles.header)}>
            <h2>{content.subtitle_text}</h2>
            <p>{content.user_description}</p>
            <a href={content.find_out_more_link} target="_blank" id="find-out-more" rel="noreferrer">
              {content.find_out_more}
            </a>
          </div>
          <div className={classnames(styles.content)}>
            <ValidationStatus
              forId="password"
              type={type}
              isActive={isActive}
              message={status === 'notice' ? content.password_hint : errorText}
            >
              <TextInputWithButton
                id="password"
                name="password"
                placeholder=""
                label={content.password_input_label}
                value={user.password}
                onChange={this._handleOnPasswordChange}
                onBlur={() => this._handleOnPasswordBlur()}
                onFocus={() => this._handleOnPasswordFocus()}
                onTypeStop={createChangeHandler('password')}
                required
                buttonTitle={content.button_password_generate_title}
                buttonText={content.button_password_generate_text}
                buttonAction={actionGeneratePassword}
              />
            </ValidationStatus>
          </div>

          <div className={classnames(styles.footer)}>
            <p>{content.footer_text}</p>
          </div>
        </div>
      </ScrollContainer>
    );
  }
}

ManagedUserUpdatePassword.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  backAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  createChangeHandler: PropTypes.func,
  errors: PropTypes.object,
  editUserPasswordOnBlur: PropTypes.func,
  resetValidation: PropTypes.func,
  actionGeneratePassword: PropTypes.func,
  generating: PropTypes.bool
};

export default compose(
  withLocalizedContent('userUpdatePassword', 'managedUserSignInCardPanel'),
  connect(
    state => ({
      generating: state.managedUserChangePassword.generating,
      generatedPassword: state.managedUserChangePassword.password
    }),
    (dispatch, { orgId }) => ({
      editUserPasswordOnBlur: password => {
        dispatch(editUserPasswordFragment(password));
      },
      resetValidation,
      actionGeneratePassword: () => {
        dispatch(generateManagedUserPasswordRequest(orgId));
      }
    })
  )
)(ManagedUserUpdatePassword);
