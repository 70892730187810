import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import compose from '../../../utils/compose/compose.js';
import ENTITY_TYPES from '../../../globals/entityTypes';
import EntityPageHeader from '../../../components/EntityPageHeader/EntityPageHeader';
import UrlControlledTabs from '../../../components/UrlControlledTabs/UrlControlledTabs';
import withDataRecency from '../../../dataRecency/withDataRecency';
import colors from '../../../globals/colors';
import withLocalizedContent from '../../../language/withLocalizedContent';
import StaffTab from '../tabs/StaffTab/StaffTab';
import userRoles from '../../../globals/userRoles';

function PendingOrganisation({
  localizedContent: { mySchoolPage: content },
  orgStaffDataRecency,
  organisationCustomId,
  organisationId,
  organisationName,
  userRole
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (location.pathname.includes('students')) {
      navigate(location.pathname.replace('/students', ''));
    }
  }, []);

  const items = [
    {
      urlName: 'schoolStaff',
      color: colors.COURSES,
      tabText: content.tabs_schoolStaff_text,
      isContentLoading: orgStaffDataRecency.syncing,
      isContentLoaded: orgStaffDataRecency.synced,
      panelContent: <StaffTab orgId={organisationId} tabName={params.tabName} panelName={params.panelName} />
    }
  ];

  return (
    <div className="pad-bot8">
      <EntityPageHeader
        entityType={ENTITY_TYPES.PENDING_ORGANISATION}
        entityTitle={organisationName}
        entitySubtitle={organisationCustomId}
        displayEditButton={userRole && userRole !== userRoles.OUP_ADMIN && userRole !== userRoles.OUP_SUPPORT}
      />
      <UrlControlledTabs
        backgroundColor={colors.PRIMARY_BLUE}
        tabName={params.tabName}
        pathname={location.pathname}
        items={items}
      />
    </div>
  );
}

PendingOrganisation.propTypes = {
  orgStaffDataRecency: PropTypes.object,
  organisationCustomId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  userRole: PropTypes.string
};

export default compose(
  withDataRecency('orgStaff'),
  withLocalizedContent('mySchoolPage'),
  connect((state, props) => {
    const organisation = state.organisations.data[props.organisationId] || {};
    const userRole = state.identity.role;
    return {
      organisationName: organisation.name || '',
      organisationCustomId: organisation.customId,
      userRole
    };
  })
)(PendingOrganisation);
