import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { validColors } from '../../globals/colors';

import Tabs from '../Tabs/Tabs';

// This function scans the list of items to find which matches the current url tabName. Defaults to 0 if non matched
const deriveCurrent = (items, tabName) =>
  items.reduce((last, curr, index) => (curr.urlName === tabName ? index : last), 0);

// Take the varying current URL pathname and normalise it
const deriveBaseUrl = (pathname, tabName) => {
  let baseUrl;

  // If there is a `tabName`, strip it off along with anything after it
  if (tabName) {
    baseUrl = pathname.slice(0, pathname.indexOf(tabName) - 1);
  } else {
    baseUrl = pathname;
  }

  // If the first character isn't a "/" add one
  if (baseUrl.charAt(0) !== '/') {
    baseUrl = `/${baseUrl}`;
  }

  // If the last character isn't a "/" add one
  if (baseUrl.charAt(baseUrl.length - 1) !== '/') {
    baseUrl = `${baseUrl}/`;
  }

  return baseUrl;
};

/** This component hooks the URL up to either a Tabs or Carousel component depending on the current screen size */
function UrlControlledTabs({ items, pathname, tabName, backgroundColor, truncate }) {
  // Derive currently selected item and current URL
  const current = deriveCurrent(items, tabName);
  const baseUrl = deriveBaseUrl(pathname, tabName);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!tabName && items.length) {
      // No classname set, navigate to default (first)
      navigate(baseUrl + items[0].urlName + location.search + location.hash, { replace: true });
    }

    if (pathname.endsWith('/')) {
      // Save ourselves a lot of routing grief
      navigate(baseUrl.slice(0, -1) + location.search, { replace: true });
    }
  }, [tabName, items, baseUrl, location.search, location.hash, pathname, navigate]);

  // Dynamically set the routing base actions for each item
  const itemsWithActions = items.map(item => ({
    ...item,
    action: () => {
      navigate(baseUrl + item.urlName + location.search);
      return item.action ? item.action() : false;
    }
  }));

  // Display the content as either a Carousel of Tabs component
  return <Tabs current={current} items={itemsWithActions} backgroundColor={backgroundColor} truncate={truncate} />;
}

UrlControlledTabs.propTypes = {
  /** The list of content items to be displayed */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** The parameter part of the url for the item */
      urlName: PropTypes.string.isRequired,
      /** Content to displayed in the tab label for this item */
      tabText: PropTypes.string.isRequired,
      /** A second line of content to displayed in the tab label */
      tabText2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** The actual content for the item to be displayed under the tab/carousel controls */
      panelContent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]).isRequired,
      /** By default, each panel will be given a <h2 className="a11y-hide"> */
      skipA11yPanelHeading: PropTypes.bool
    })
  ).isRequired,
  /** This is the current URL location path without the tabName parameter value */
  pathname: PropTypes.string.isRequired,
  /** This is the current tabName url parameter given using React Router to the parent component */
  tabName: PropTypes.string,
  /** The color to be used for the background of the tab list */
  backgroundColor: PropTypes.oneOf(validColors).isRequired,
  /** The styling will change if this is set  to true, the tab buttons will be smaller and title will be truncated */
  truncate: PropTypes.bool
};

export default UrlControlledTabs;
