import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import compose from '../../utils/compose/compose.js';

import UserListing, { ListingTypes } from '../UserListing/UserListing';

function UserSelectionResultGroup({
  title,
  selectedIds,
  disabledIds,
  updateSelection,
  items,
  showYearGroup = false,
  organisation,
  licenceStatusList = null
}) {
  return (
    <UserListing
      className="gin-top2"
      heading={title}
      listingType={ListingTypes.PANEL}
      items={items}
      selectedItems={selectedIds}
      selectable={Object.keys(items).filter(id => !disabledIds.includes(id))}
      onItemSelect={userId => updateSelection(userId, !selectedIds.includes(userId))}
      showYearGroups={showYearGroup}
      orgCurriculumType={organisation.curriculumType}
      selectAllEnabled
      licenceStatusList={licenceStatusList}
    />
  );
}

UserSelectionResultGroup.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledIds: PropTypes.arrayOf(PropTypes.string),
  updateSelection: PropTypes.func.isRequired,
  showYearGroup: PropTypes.bool,
  organisation: PropTypes.object.isRequired,
  licenceStatusList: PropTypes.object
};

export default compose(
  connect(
    state => ({
      organisation: state.organisations.data[state.identity.currentOrganisationId]
    }),
    {}
  )
)(UserSelectionResultGroup);
