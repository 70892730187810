import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import copyToClipboard from '../../structure/HubOrganizationLayout/Utils/copyToClipboard';
import styles from './CopyToClipboardButton.scss';

// This is a temporary solution, shared-front-end utils are private.
// @Andrei O: Please refactor this component so that it only uses shared-front-end buttons
function prefixDataAttributes(dataAttributes = {}) {
  return Object.entries(dataAttributes).reduce((result, [key, value]) => {
    result[`data-${key.toLowerCase()}`] = value;
    return result;
  }, {});
}

function CopyToClipboardButton({
  classCode = '',
  customClassname = '',
  label = 'Copy class code',
  dataAttributes = {},
  customCopiedLabel = 'Code copied!',
  customButtonColor = '',
  tabIndex = 0,
  buttonLabel = '',
  disabled = false,
  size = 'base'
}) {
  const copyText = copyToClipboard();

  const updateTheTooltipText = (buttonId, text) => {
    const copyButton = document.getElementById(buttonId);
    copyButton.setAttribute('data-tooltip', text);
    copyButton.setAttribute('aria-label', text);
    copyButton.blur();
  };

  const buttonId = `copyCodeId-${classCode}`;

  const handleClick = e => {
    e.stopPropagation();
    copyText(classCode);
    updateTheTooltipText(buttonId, customCopiedLabel);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleClick(e);
    }
  };

  if (buttonLabel)
    return (
      <div className={`${customClassname || ''}`} onMouseLeave={() => updateTheTooltipText(buttonId, label)}>
        <Button
          id={buttonId}
          variant="outline"
          text={buttonLabel}
          icon={{ component: <SVGIcon glyph={GLYPHS.ICON_COPY} /> }}
          onClick={e => handleClick(e)}
          onKeyDown={e => handleKeyDown(e)}
          dataAttributes={{ ...dataAttributes, tooltip: label }}
          ariaAttributes={{ label }}
          disabled={disabled}
          size={size}
        />
      </div>
    );

  return (
    <div
      className={`${styles.copyButtonContainer} ${customClassname || ''}`}
      onMouseLeave={() => updateTheTooltipText(buttonId, label)}
    >
      <button
        id={buttonId}
        type="button"
        className={styles.buttonContainer}
        onClick={e => handleClick(e)}
        onKeyDown={e => handleKeyDown(e)}
        tabIndex={tabIndex}
        data-tooltip={label}
        aria-label={label}
        {...prefixDataAttributes(dataAttributes)}
      >
        <div className={styles.svgContainer}>
          <SVGIcon glyph={GLYPHS.ICON_COPY} fill={customButtonColor} />
        </div>
      </button>
    </div>
  );
}

CopyToClipboardButton.propTypes = {
  classCode: PropTypes.string.isRequired,
  customClassname: PropTypes.string,
  label: PropTypes.string,
  customCopiedLabel: PropTypes.string,
  customButtonColor: PropTypes.string,
  tabIndex: PropTypes.number,
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  dataAttributes: PropTypes.object,
  size: Button.propTypes.size
};

export default CopyToClipboardButton;
