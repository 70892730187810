/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import compose from '../../utils/compose/compose.js';
import Link from '../../components/Link/Link';
import PopoutPanel from '../../components/PopoutPanel/PopoutPanel';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';

import withAuthorization from '../../authorization/withAuthorization';
import { isELTReviewer } from '../../redux/selectors/authorization/user';

import CourseComponentsMenu from '../HubProductLayout/HubSubSideNavbar/CourseComponentsMenu';
import ResourceHome from '../HubProductLayout/Resources/ResourceHome';
import ResourceCategory from '../HubProductLayout/Resources/ResourceCategory';
import CourseMaterial from '../HubProductLayout/CourseMaterial/CourseMaterial';
import styles from './CourseContentPreviewLayout.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../../components/Button/Button';
import {
  openCoursePreviewModal,
  setCourseModalCloseLink,
  loadCourseContentRequest
} from '../../redux/actions/coursePreview';
import { COURSE_MODAL_CLOSE_LINK_KEY } from '../../globals/cptConstants';

function CourseContentPreviewLayout({
  children,
  coursePreviewModalOpen,
  localizedContent: { hubGlossary: content },
  openCoursePreviewModalAction,
  setCourseModalCloseLinkAction,
  courseModalCloseLink,
  loadCourseContentRequestData,
  loading,
  courses,
  currentPlatform
}) {
  const params = useParams();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const modalCloseLinkParam = sessionStorage.getItem(COURSE_MODAL_CLOSE_LINK_KEY);
    if (modalCloseLinkParam) {
      setCourseModalCloseLinkAction(modalCloseLinkParam);
    }
  });

  useEffect(() => {
    loadCourseContentRequestData({ courseCode: params.courseId, platform: currentPlatform });
    openCoursePreviewModalAction();
  }, []);

  const handleCloseModal = () => {
    if (courseModalCloseLink) {
      setCourseModalCloseLinkAction('');
    } else {
      window.open('about:blank', '_self');
      window.close();
    }
  };

  if (!children && params.subFolder && params.subFolder.indexOf('Home') !== -1) {
    children = withAuthorization(ResourceHome, isELTReviewer, '/myProfile');
  } else if (!children && params.folder) {
    children = withAuthorization(ResourceCategory, isELTReviewer, '/myProfile');
  } else {
    children = withAuthorization(CourseMaterial, isELTReviewer, '/myProfile');
  }

  return (
    <div>
      <Helmet title="Course Preview" />
      <PopoutPanel
        id="coursePreviewPage"
        ariaLabel="coursePreviewPage"
        isOpen={coursePreviewModalOpen}
        panelSize="fullWidth"
      >
        <div className={styles.courseContentLayout}>
          <div className={styles.courseContentPreviewMenu}>
            <CourseComponentsMenu
              params={params}
              course={courses[params.courseId]}
              hubContent={content}
              pathname={pathname}
              isPreview
            />
          </div>
          <div className={styles.resourceSection}>
            <div className={styles.closeHeader}>
              <Link to={courseModalCloseLink} onClick={handleCloseModal}>
                <Button text={content.close_panel_text} type={buttonTypes.CLOSE_BOLD} />
              </Link>
            </div>
            <div className={styles.courseContentPreviewResources}>
              {loading && <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} />}
              {!loading &&
                children &&
                React.cloneElement(children, {
                  courses,
                  courseId: params.courseId || '',
                  hubContent: content,
                  isPreview: true
                })}
            </div>
          </div>
        </div>
      </PopoutPanel>
    </div>
  );
}

CourseContentPreviewLayout.propTypes = {
  children: PropTypes.object,
  courseModalCloseLink: PropTypes.string,
  coursePreviewModalOpen: PropTypes.bool,
  openCoursePreviewModalAction: PropTypes.func,
  setCourseModalCloseLinkAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  loadCourseContentRequestData: PropTypes.func,
  courses: PropTypes.object,
  loading: PropTypes.bool,
  currentPlatform: PropTypes.string
};

const mapStateToProps = state => ({
  coursePreviewModalOpen: state.coursePreview.coursePreviewModalOpen,
  courseModalCloseLink: state.coursePreview.courseModalCloseLink,
  courses: state.coursePreview.courses,
  loading: state.coursePreview.loading,
  currentPlatform: state.coursePreview.currentPlatform
});

const mapDispatchToProps = {
  openCoursePreviewModalAction: openCoursePreviewModal,
  setCourseModalCloseLinkAction: setCourseModalCloseLink,
  loadCourseContentRequestData: loadCourseContentRequest
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(mapStateToProps, mapDispatchToProps)
)(CourseContentPreviewLayout);
