import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import { toInitials } from '../../utils/string';
import { COLOR_FOR_ROLE, validColors } from '../../globals/colors';
import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import { validSizes } from '../Thumbnail/Thumbnail';
import PersonRepresentation from './PersonRepresentation';

/** This component consists of a persons initials in a thumbnail, their name and their email. The name can be a link */
function ConnectedPersonRepresentation({
  id,
  entityId,
  hideEmail = false,
  thumbnailColor,
  thumbnailSize,
  secondaryThumbnailSize,
  secondaryThumbnailGlyph,
  initials,
  linkTo,
  name, // name & email are derived automatically during connect()
  email, //
  statusText,
  status,
  deletedStyle,
  locked,
  yearGroup,
  organisation = {},
  getYearGroupOptions,
  getOptionNameByValue
}) {
  return (
    <PersonRepresentation
      thumbnailColor={thumbnailColor || COLOR_FOR_ROLE.USER}
      thumbnailSize={thumbnailSize}
      initials={initials}
      secondaryThumbnailSize={secondaryThumbnailSize}
      secondaryThumbnailGlyph={secondaryThumbnailGlyph}
      id={id || `personEntity-${entityId}`}
      linkTo={linkTo}
      name={name}
      email={hideEmail ? '' : email}
      statusText={statusText}
      status={status}
      deletedStyle={deletedStyle}
      locked={locked}
      yearGroup={getOptionNameByValue(getYearGroupOptions(organisation.curriculumType), yearGroup)}
    />
  );
}

ConnectedPersonRepresentation.propTypes = {
  /** User Id to look up in store  */
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired, // eslint-disable-line react/no-unused-prop-types
  hideEmail: PropTypes.bool,
  /** The color of the thumbnail. Only required if not an image */
  thumbnailColor: PropTypes.oneOf(validColors),
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes),
  /** The size of the secondary thumbnail. */
  secondaryThumbnailSize: PropTypes.oneOf(validSizes),
  /** The glyph of the secondary thumbnail. */
  secondaryThumbnailGlyph: PropTypes.oneOf(validGlyphs),
  /** Person's initials to be displayed in the thumbnail. Will be derived from name if omitted */
  initials: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Person's name */
  name: PropTypes.string.isRequired,
  /** Person's email */
  email: PropTypes.string,
  /** Person's status text */
  statusText: PropTypes.string,
  /** Person's status. SUCCESS, WARNING or ERROR */
  status: PropTypes.string,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,
  /** The html ID of the element. */
  id: PropTypes.string,
  locked: PropTypes.bool,
  yearGroup: PropTypes.number,
  organisation: PropTypes.shape({
    curriculumType: PropTypes.string.isRequired
  }),
  getYearGroupOptions: PropTypes.func.isRequired,
  getOptionNameByValue: PropTypes.func.isRequired
};

export default compose(
  withGetYearGroupOptions,
  connect((state, { entityId, orgId }) => {
    const person = state.people.data[entityId] || {};

    // TODO avoid needing this
    const firstName = person.firstname || person.firstName || '';
    const lastName = person.lastname || person.lastName || '';

    return {
      organisation: state.organisations && state.organisations.data[orgId],
      // No need to specify initials explicitly because Thumbnail will derive them for us.
      name: `${firstName} ${lastName}`,
      initials: toInitials(firstName, lastName),
      email: person.email,
      thumbnailColor: COLOR_FOR_ROLE[person.roleName] || COLOR_FOR_ROLE.USER,
      locked: person.isLocked,
      yearGroup: person.yearGroup
    };
  })
)(ConnectedPersonRepresentation);
