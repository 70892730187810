import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
// Redux
import { setPageClass } from '../../redux/reducers/data/search.reducer.js';
// Utils
import withLocalizedContent from '../../language/withLocalizedContent';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Constants
import { HubLayoutConstants } from '../../globals/hubConstants';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import HubEmptyState from '../HubEmptyState/HubEmptyState';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import Button from '../Button/Button.js';
import PaginationButtons from '../PaginationButtons/PaginationButtons.js';
// Style
import colors from '../../globals/colors';
import styles from './OnlineTestClass.scss';

class OnlineTestClass extends Component {
  getClassList = () => {
    const {
      next,
      localizedContent: { hubSetTestOnline: content },
      paginatedClasses,
      totalResults
    } = this.props;

    if (totalResults <= 0) {
      return (
        <div className={styles.chooseClassEmptyState}>
          <HubEmptyState
            glyphIcon={GLYPHS.ICON_CLASS}
            glyphSize={thumbnailSizes.HEADING}
            glyphBackgroundColour={colors.TEACHER}
            title={content.list_classes_panel_empty_text}
            subTitle={content.list_classes_panel_empty_text_subtitle}
          />
        </div>
      );
    }

    const paginatedClassIds = Object.keys(paginatedClasses);
    return (
      <ol>
        {paginatedClassIds.map(id => {
          const classroom = paginatedClasses?.[id];
          const steps = HubLayoutConstants.ONLINE_TEST_STEPS;
          return (
            <li className={styles.classroomContainer} key={id}>
              <div className={styles.classroomInfo}>
                <EntityRepresentation
                  id={id}
                  thumbnailColor={colors.TEACHER}
                  thumbnailGlyph={GLYPHS.ICON_CLASS}
                  thumbnailSize={thumbnailSizes.MEDIUM}
                  line1Text={classroom.name}
                />
                <div className={styles.buttonContainer}>
                  <Button
                    glyph={GLYPHS.ICON_RIGHT_THICK}
                    iconOnly
                    onClick={() => next({ page: steps.CHOOSE_STUDENTS_PAGE, classId: id })}
                    ariaLabel={`Choose ${classroom.name} classroom`}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    );
  };

  render() {
    const {
      test,
      assessmentTitle,
      localizedContent: { hubSetTestOnline: content },
      totalResults,
      page,
      setPageAction
    } = this.props;

    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_title}</h2>
              <div>{test ? `${assessmentTitle} ${getTestTitle(test)}` : ''}</div>
            </div>
          </header>
        }
      >
        <div className={styles.mainContentContainer}>
          {!!totalResults && <div className={styles.panelTitle}>{content.choose_class}</div>}
          {this.getClassList()}
          {totalResults > HubLayoutConstants.MAX_ORGANIZATION_CLASSES_PER_PAGE ? (
            <PaginationButtons
              idPrefix="setTestClassList"
              value={page}
              numberOfPages={Math.ceil(totalResults / HubLayoutConstants.MAX_ORGANIZATION_CLASSES_PER_PAGE)}
              onClick={setPageAction}
              aria={{ 'aria-controls': 'searchResults' }}
            />
          ) : null}
        </div>
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({ search: { profileClasses: { ids = [], page = 1, paginatedClassList = {}, totalResults = 0 } = {} } = {} }) => ({
      paginatedClasses: paginatedClassList,
      classesIds: ids,
      totalResults,
      page
    }),
    {
      setPageAction: page => setPageClass('profileClasses', page)
    }
  )
)(OnlineTestClass);

OnlineTestClass.defaultProps = {
  paginatedClasses: {},
  totalResults: 0,
  assessmentTitle: ''
};

OnlineTestClass.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  page: PropTypes.number.isRequired,
  setPageAction: PropTypes.func.isRequired,
  paginatedClasses: PropTypes.object,
  totalResults: PropTypes.number
};
