import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import CheckboxOld from '../Checkbox/Checkbox';

function MarketingPreferencesConsentCheckbox({
  id,
  testId,
  name,
  state,
  update,
  localizedContent: { myProfileInitialEditPanel: content },
  useOldCheckboxComponent = false,
  className
}) {
  const label = content.my_profile_initial_consent_emails_products_services;

  return useOldCheckboxComponent ? (
    <CheckboxOld name={name} id={id} value={state} onChange={() => update(!state, id)} dataTestId={testId}>
      <label htmlFor={id} className={className}>
        {label}
      </label>
    </CheckboxOld>
  ) : (
    <Checkbox
      className={className}
      checked={!state}
      onChange={() => update(!state)}
      dataAttributes={{ testId }}
      label={label}
    />
  );
}

MarketingPreferencesConsentCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  name: PropTypes.string,
  state: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  localizedContent: PropTypes.any,
  useOldCheckboxComponent: PropTypes.bool,
  className: PropTypes.any
};

export default compose(withLocalizedContent('myProfileInitialEditPanel'))(MarketingPreferencesConsentCheckbox);
