import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextLink } from '@oup/shared-front-end';
import colors from '../../globals/colors';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { validSizes } from '../Thumbnail/Thumbnail';
import userRoles from '../../globals/userRoles.js';
import { featureIsEnabled, isLocal } from '../../globals/envSettings';
import withLocalizedContent from '../../language/withLocalizedContent';
import { isCesMode } from '../../utils/platform';

/** This component consists of a class thumbnail, name and some subtext. The name can be a link */
function ClassRepresentation({
  thumbnailSize,
  linkId,
  linkTo,
  name,
  subtext,
  statusText,
  status,
  id,
  deletedStyle,
  isSelected,
  isNewEntity,
  userRole,
  joiningCode,
  customOrgId,
  localizedContent: { classCodeComponent: content },
  isPrimarySchool
}) {
  const handleOnClick = e => {
    e.stopPropagation();
  };
  const toolTipText = (
    <>
      <p>{content.copy_share_code}</p>
      <p>{content.you_can_edit}</p>
      <p>{content.your_students_need_to}</p>
      <ul>
        <li>
          {content.go_to_text}
          <TextLink to={content.hub_url} onClick={handleOnClick} target="_blank">
            www.englishhub.oup.com
          </TextLink>
        </li>
        <li>{content.register_or_sign_in}</li>
        <li>{content.go_to_my_class}</li>
        <li>{content.select_join_class}</li>
        <li>{content.join}</li>
      </ul>
    </>
  );
  const navigate = useNavigate();

  return (
    <EntityRepresentation
      id={id}
      thumbnailColor={isSelected ? colors.PRIMARY : colors.TEACHER}
      thumbnailGlyph={GLYPHS.ICON_CLASS}
      thumbnailSize={thumbnailSize}
      linkId={linkId}
      linkTo={linkTo}
      line1Text={name}
      line2Text={subtext}
      copyClipboard={
        featureIsEnabled('class-joining-code') &&
        isCesMode() &&
        userRole === userRoles.ORG_ADMIN &&
        joiningCode &&
        !isPrimarySchool &&
        joiningCode.enabled
      }
      toolTipText={
        featureIsEnabled('class-joining-code') && isCesMode() && userRole === userRoles.ORG_ADMIN && !isPrimarySchool
          ? toolTipText
          : null
      }
      joiningCode={joiningCode}
      customOrgId={customOrgId}
      line3Text={statusText}
      status={status}
      deletedStyle={deletedStyle}
      isNewEntity={isNewEntity}
      onClick={event => {
        event.preventDefault();
        if (!isLocal()) {
          window.newrelic.interaction().actionText('Open class details');
        }
        navigate(event.currentTarget.pathname);
      }}
    />
  );
}

ClassRepresentation.propTypes = {
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes).isRequired,
  /** Link Id for testing */
  linkId: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Classroom name */
  name: PropTypes.string.isRequired,
  /** Second line text */
  subtext: PropTypes.string,
  /** Third line text */
  statusText: PropTypes.string,
  /** Class's status, eg SUCCESS, WARNING, ERROR */
  status: PropTypes.string,
  /** Html id for this component */
  id: PropTypes.string.isRequired,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,
  /** Entity is selected */
  isSelected: PropTypes.bool,
  /** Entity is new */
  isNewEntity: PropTypes.bool,
  /** The role of the user */
  userRole: PropTypes.string.isRequired,
  /** Class joining code */
  joiningCode: PropTypes.object,
  /** Short org id */
  customOrgId: PropTypes.string,
  /** Localized content */
  localizedContent: PropTypes.object.isRequired,
  /** ClassCode display in impersonation mode */
  isPrimarySchool: PropTypes.bool
};

export default withLocalizedContent('classCodeComponent')(ClassRepresentation);
