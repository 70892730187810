import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { TextLink, RadioButtonGroup, Checkbox } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import MarketingPreferencesConsentCheckbox from './MarketingPreferencesConsentCheckbox';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from '../../routes/MyProfile/MyProfile.scss';
import {
  epsPlatformOlbOffline,
  epsPlatformOlb,
  epsPlatformEltHub,
  epsPlatformDefault,
  epsPlatformOte,
  epsPlatformOpt,
  epsPlatformInsp,
  isHubMode
} from '../../utils/platform';

class MarketingPreferencesConsentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ageSelectedOption: '',
      termsAndConditionsAccepted: false
    };
  }

  componentDidMount() {
    const { platform, setMarketPreference, setUnderAgeAccepted } = this.props;
    if (platform === epsPlatformInsp) {
      setUnderAgeAccepted(false);
      setMarketPreference(true);
      this.setState({ ageSelectedOption: 'over' });
    }
  }

  _renderContent = () => {
    const {
      setMarketPreference,
      setMarketTermsAndConditions,
      setUnderAgeAccepted,
      platform,
      missingFields,
      userId,
      localizedContent: { myProfileInitialEditPanel: content },
      marketingEmailAcceptedValue,
      isTandCaccepted
    } = this.props;

    const { ageSelectedOption, termsAndConditionsAccepted } = this.state;

    return (
      <div className={styles.consentContainerInput}>
        {platform === epsPlatformInsp ? (
          <div className={styles.consentContainerHeader}>
            {content.my_profile_initial_consent_terms_and_conditions_header}
          </div>
        ) : (
          <>
            <div className={styles.consentContainerHeader}>
              {content.my_profile_initial_consent_information_select_option}
            </div>
            <div className="gin-top2">
              <RadioButtonGroup
                variant="outline"
                name="first_age_selection"
                legend={content.my_profile_initial_consent_information_select_option}
                checkedValue={[ageSelectedOption]}
                radioGroup={[
                  {
                    id: 'under_age',
                    label: (
                      <>
                        {content.my_profile_initial_consent_under_age}{' '}
                        <strong>{content.my_profile_initial_consent_under}</strong>
                      </>
                    ),
                    value: 'under'
                  },
                  {
                    id: 'over_age',
                    label: (
                      <>
                        {content.my_profile_initial_consent_over_age_start}{' '}
                        <strong>{content.my_profile_initial_consent_over}</strong>{' '}
                        {content.my_profile_initial_consent_over_age_end}
                      </>
                    ),
                    value: 'over'
                  }
                ]}
                onChange={({ target: { value = '' } }) => {
                  setUnderAgeAccepted(value === 'under');
                  setMarketPreference(value === 'over');
                  this.setState({ ageSelectedOption: value });
                }}
              />
            </div>
          </>
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {ageSelectedOption === 'under' ? (
          <div className={styles.consentOptionsContainer}>
            <div>
              <h2>{content.my_profile_initial_consent_parent_agreement}</h2>
              {!isTandCaccepted && (
                <div>
                  <p>{content.my_profile_initial_consent_parent_terms_conditions}</p>
                  <div>
                    <Checkbox
                      checked={termsAndConditionsAccepted}
                      onChange={() => {
                        setMarketTermsAndConditions(!termsAndConditionsAccepted);
                        this.setState({ termsAndConditionsAccepted: !termsAndConditionsAccepted });
                      }}
                      id="terms_and_conditions_consent"
                      label={
                        <>
                          {content.my_profile_initial_consent_agreement}
                          {platform === epsPlatformOlbOffline || platform === epsPlatformOlb ? (
                            <TextLink
                              to={content.my_profile_initial_consent_agreement_link_olb}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {content.my_profile_initial_consent_terms_and_conditions}
                            </TextLink>
                          ) : null}
                          {platform === epsPlatformDefault ? (
                            <TextLink
                              component={RouterLink}
                              to={content.my_profile_initial_consent_agreement_link_ces}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {content.my_profile_initial_consent_terms_and_conditions}
                            </TextLink>
                          ) : null}
                          {platform === epsPlatformEltHub ? (
                            <TextLink
                              component={RouterLink}
                              to={content.my_profile_initial_consent_agreement_link_hub}
                              target="_blank"
                              rel="noreferrer"
                              className={styles.link}
                            >
                              {content.my_profile_initial_consent_terms_and_conditions}
                            </TextLink>
                          ) : null}
                          {platform === epsPlatformOte ? (
                            <TextLink
                              to={content.my_profile_initial_consent_agreement_link_ote}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {content.my_profile_initial_consent_terms_and_conditions}
                            </TextLink>
                          ) : null}
                          {platform === epsPlatformOpt ? (
                            <TextLink
                              to={content.my_profile_initial_consent_agreement_link_opt}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {content.my_profile_initial_consent_terms_and_conditions}
                            </TextLink>
                          ) : null}
                        </>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : ageSelectedOption === 'over' ? (
          <div className={styles.consentOptionsContainer}>
            <div>
              {!isTandCaccepted && (
                <Checkbox
                  className={isHubMode() ? '' : styles.marketingPreferencesConsentCheckBox}
                  checked={termsAndConditionsAccepted}
                  onChange={() => {
                    setMarketTermsAndConditions(!termsAndConditionsAccepted);
                    this.setState({ termsAndConditionsAccepted: !termsAndConditionsAccepted });
                  }}
                  id="terms_and_conditions_consent"
                  label={
                    <>
                      {content.my_profile_initial_consent_agreement}
                      {platform === epsPlatformOlbOffline || platform === epsPlatformOlb ? (
                        <TextLink
                          to={content.my_profile_initial_consent_agreement_link_olb}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {content.my_profile_initial_consent_terms_and_conditions}
                        </TextLink>
                      ) : null}
                      {platform === epsPlatformDefault ? (
                        <TextLink
                          component={RouterLink}
                          to={content.my_profile_initial_consent_agreement_link_ces}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {content.my_profile_initial_consent_terms_and_conditions}
                        </TextLink>
                      ) : null}
                      {platform === epsPlatformEltHub ? (
                        <TextLink
                          component={RouterLink}
                          to={content.my_profile_initial_consent_agreement_link_hub}
                          target="_blank"
                          rel="noreferrer"
                          className={styles.link}
                        >
                          {content.my_profile_initial_consent_terms_and_conditions}
                        </TextLink>
                      ) : null}
                      {platform === epsPlatformOte ? (
                        <TextLink
                          to={content.my_profile_initial_consent_agreement_link_ote}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {content.my_profile_initial_consent_terms_and_conditions}
                        </TextLink>
                      ) : null}
                      {platform === epsPlatformOpt ? (
                        <TextLink
                          to={content.my_profile_initial_consent_agreement_link_opt}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {content.my_profile_initial_consent_terms_and_conditions}
                        </TextLink>
                      ) : null}
                      {platform === epsPlatformInsp ? (
                        <>
                          <TextLink
                            to={content.my_profile_initial_consent_agreement_link_insp}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {content.my_profile_initial_consent_terms_and_conditions}
                          </TextLink>
                          {content.my_profile_initial_consent_agreement_age}
                        </>
                      ) : null}
                    </>
                  }
                />
              )}
            </div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {missingFields.includes('marketingPref') || !userId ? (
              <>
                <h2>{content.my_profile_initial_consent_products_services}</h2>
                <MarketingPreferencesConsentCheckbox
                  id="marketing_pref"
                  testId="marketing_pref"
                  update={setMarketPreference}
                  state={marketingEmailAcceptedValue}
                  className={isHubMode() ? '' : styles.marketingPreferencesConsentCheckBox}
                />
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return this._renderContent();
  }
}

MarketingPreferencesConsentForm.propTypes = {
  missingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  userId: PropTypes.string,
  setMarketPreference: PropTypes.func.isRequired,
  setMarketTermsAndConditions: PropTypes.func.isRequired,
  setUnderAgeAccepted: PropTypes.func.isRequired,
  localizedContent: PropTypes.any,
  platform: PropTypes.string,
  isTandCaccepted: PropTypes.bool,
  marketingEmailAcceptedValue: PropTypes.bool.isRequired
};

export default compose(
  withLocalizedContent('myProfileInitialEditPanel'),
  connect(
    (state, ownProps) => ({
      missingFields: state.identity.missingFields,
      userId: state.identity.userId,
      platform: state.registration.platformCode || ownProps.platform,
      marketingEmailAcceptedValue: state.registration.marketingEmailAcceptedValue
    }),
    {}
  )
)(MarketingPreferencesConsentForm);
