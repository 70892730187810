import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { connect } from 'react-redux';
import { getOrgMaxCreditsLimit } from '@oup/shared-node-browser/org.js';
import compose from '../../utils/compose/compose.js';
import actions from '../../redux/actions';
import withLocalizedContent from '../../language/withLocalizedContent';
import ValidationStatus, { validationStatusCodes } from '../ValidationStatus/ValidationStatus';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { mode as opMode } from '../../redux/reducers/oicTestCredits.reducer.js';
import styles from './OicManageTestCreditsForm.scss';

const { ERROR_STATUS, VALID_STATUS, WAITING_STATUS } = validationStatusCodes;

function EditOicTestCredits({
  localizedContent: { oicCreateTestCreditsForm: localizedContent },
  orgId,
  subscription,
  products,
  getOicTestCredits,
  input,
  updateOicTestCredits,
  createOicTestCredits,
  invalidValue
}) {
  const {
    productId,
    subscriptionId,
    productDetails: { title, isbn }
  } = subscription;

  const { mode = opMode.EMPTY, formData, errors = '' } = products[productId] || {};
  const hasTestCredits = () => !!formData?.productId;

  const handleGetOicTestCredits = () => getOicTestCredits({ orgId, productId });
  const handleForm = () => {
    if (!formData?.testCredits) return;
    if (parseInt(formData.testCredits, 10) > getOrgMaxCreditsLimit()) {
      // eslint-disable-next-line consistent-return
      return invalidValue({
        productId,
        errors: 'This product cannot have more than 1 million credits for an organization'
      });
    }
    // eslint-disable-next-line consistent-return
    return hasTestCredits() ? updateOicTestCredits(formData) : createOicTestCredits({ orgId, productId, ...formData });
  };
  const handleInput = (value, field) => input({ productId, field, value });

  const isInputMode = mode === opMode.INPUT || mode === opMode.EMPTY;
  const isLoadingMode = mode === opMode.LOADING;

  useEffect(() => {
    handleGetOicTestCredits();
  }, []);

  const status = {
    [opMode.LOADING]: WAITING_STATUS,
    [opMode.ERROR]: ERROR_STATUS,
    [opMode.SUCCESS]: VALID_STATUS,
    [opMode.INPUT]: VALID_STATUS,
    [opMode.EMPTY]: VALID_STATUS
  }[mode];

  return (
    <ValidationStatus
      containerClassName={styles.testCredits}
      forId={`testCredits_${subscriptionId}`}
      type={status}
      message={errors}
      hideIcon={isInputMode}
      isActive
    >
      <TextInputWithButton
        id={`testCredits_${subscriptionId}`}
        name="testCredits"
        type="number"
        label={
          <>
            {title}
            <br />
            <small className={styles.isbn}>
              {localizedContent.product_isbn_label}: {isbn}
            </small>
          </>
        }
        placeholder={localizedContent.test_credits_label}
        disabled={isLoadingMode}
        buttonDisabled={isLoadingMode}
        buttonIcon={GLYPHS.ICON_TICK}
        value={formData?.testCredits}
        buttonAction={handleForm}
        onChange={handleInput}
        onBlur={handleForm}
      />
    </ValidationStatus>
  );
}

EditOicTestCredits.propTypes = {
  orgId: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  input: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  getOicTestCredits: PropTypes.func.isRequired,
  updateOicTestCredits: PropTypes.func.isRequired,
  createOicTestCredits: PropTypes.func.isRequired,
  invalidValue: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('oicCreateTestCreditsForm'),
  connect(
    state => ({
      ...pick(state.oicTestCredits, ['products'])
    }),
    dispatch => ({
      invalidValue: input => dispatch(actions.updateOicTestCreditsFailure(input)),
      input: input => dispatch(actions.createOicTestCreditsInput(input)),
      getOicTestCredits: input => dispatch(actions.getOicTestCreditsRequest(input)),
      updateOicTestCredits: input => dispatch(actions.updateOicTestCreditsRequest(input)),
      createOicTestCredits: input => dispatch(actions.createOicTestCreditsRequest(input))
    })
  )
)(EditOicTestCredits);
