import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../utils/withRouter';
import compose from '../utils/compose/compose.js';
import withLocalizedContent from '../language/withLocalizedContent';
import OrbTeacherAppDownload from '../components/OrbTeacherAppDownload/OrbTeacherAppDownload.jsx';
import { appDownloadRequest } from '../redux/actions/orbAppDownloadUrl';
import { arch, osName } from '../utils/device';
import { featureIsEnabled } from '../globals/envSettings';
import NotFoundView from '../views/errors/NotFoundView';

class OrbOfflineAppPage extends Component {
  componentDidMount() {
    const { loadDownloadUrl, userId } = this.props;
    loadDownloadUrl(userId, osName(), arch());
  }

  onDownloadClicked() {
    const { loadDownloadUrl, userId, downloadInfo } = this.props;
    if (downloadInfo.success) {
      window.location = downloadInfo.data.url;
    } else if (downloadInfo.error) {
      // eslint-disable-next-line no-alert
      alert('Error downloading app');
    } else {
      loadDownloadUrl(userId, osName(), arch());
    }
  }

  render() {
    if (featureIsEnabled('disable-offline-app-page')) return <NotFoundView />;

    return <OrbTeacherAppDownload osName={osName()} onDownloadClicked={() => this.onDownloadClicked()} />;
  }
}

OrbOfflineAppPage.propTypes = {
  userId: PropTypes.string.isRequired,
  downloadInfo: PropTypes.object.isRequired,
  loadDownloadUrl: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('orbOfflineAppPage', 'sharedTerms'),
  connect(
    state => ({
      userId: state.identity.userId,
      userPreferences: state.orbUserPreferences,
      downloadInfo: state.orbDownloadAppUrl
    }),
    dispatch => ({
      // eslint-disable-next-line no-shadow
      loadDownloadUrl: (userId, os, arch) => dispatch(appDownloadRequest(userId, os, arch))
    })
  )
)(OrbOfflineAppPage);
